<template>
  <div>
    <div>
      <div class="mt-5">
        <div class="flex flex-row align-center justify-between">
          <p class="text-lg">
            {{
              order.customer.name ||
                (order.customer.information?.first_name + " " + order.customer.information?.last_name)
            }}
          </p>
          <p
            class="text-primary text-sm underline ml-4 cursor-pointer"
            @click="redirectToPatient(order.customer)"
          >
            Afficher la fiche patient
          </p>
        </div>
        <div class="flex flex-row">
          <icon
            name="mdi-file-document-outline"
            :size="24"
            :color="'#3513e2'"
          />
          <p class="underline ml-2">
            {{ order.content_type === OrderContentType.CART ? "Hors ordonnance" : "Avec ordonnance" }}
          </p>
        </div>
        <div v-if="order.status !== OrderStatusType.PENDING">
          <div class="flex flex-row align-center">
            <v-chip
              class="my-4 mr-2"
              :color="OrderService.statusColor(order)"
              small
            >
              <p class="text-sm">
                {{ OrderService.statusLabel(order) }}
              </p>
            </v-chip>
            <p v-if="order.operator_code">
              traité par {{ order.operator_code }}
            </p>
          </div>
          <p v-if="order.operator_comment">
            L'opérateur a indiqué le commentaire suivant : {{ order.operator_comment }}
          </p>
        </div>
        <div
          v-if="!!order.content?.prescription"
          class="mt-4"
        >
          <p>
            Ordonnance envoyée {{ order?.content_type === OrderContentType.RENEWAL ? "POUR RENOUVELLEMENT" : "" }}
            le {{ dayjs(order?.created_at).format("DD/MM/YYYY [à] HH[H]MM") }}
          </p>
          <p v-if="order.content.prescription?.professional?.last_name">
            Du DR {{ order.content.prescription.professional.last_name.toUpperCase() }}
          </p>
          <secure-image
            :urls="order.content.prescription.files"
            :print-button="true"
          />
        </div>
      </div>
      <order-products :order="order" />
      <div
        v-if="!order?.accepted_at && !order?.rejected_at"
        class="flex flex-col mt-10 items-center"
      >
        <div class="w-64">
          <base-button
            primary
            class="my-2"
            @click="acceptOrder"
          >
            Je traite la commande
          </base-button>
          <base-button
            class="my-2"
            @click="rejectOrder"
          >
            Je refuse la commande
          </base-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import BaseButton from '@/core/components/base/BaseButton.vue';
import { OrderContentType, OrderStatusType } from '@/order/interfaces/Order';
import dayjs from 'dayjs';
import SecureImage from '@/core/components/secure-image/SecureImage.vue';
import OrderProducts from '@/order/components/OrderProducts.vue';
import OrderService from '@/order/services/OrderService';
import Icon from '@/core/components/icon/Icon.vue';
import Customer from "@/customer/interfaces/Customer";

export default defineComponent({
    name: 'OrderDetails',
    components: { SecureImage, BaseButton, OrderProducts, Icon },
    props: {
        order: {
            type: Object,
            required: true
        }
    },
    emits: ['onPatientPress', 'handleOrder'],
    data () {
        return {
            OrderContentType
        };
    },
    computed: {
        OrderService () {
            return OrderService;
        },
        OrderStatusType () {
            return OrderStatusType;
        },
        dayjs () {
            return dayjs;
        }
    },
    methods: {
    /**
     * Redirect to patient details.
     *
     * @param customer
     */
        redirectToPatient (customer: Customer) {
            this.$emit('onPatientPress', customer);
        },
        /**
     * Accept the order.
     */
        acceptOrder () {
            this.$emit('handleOrder', true);
        },
        /**
     * Reject the order.
     */
        rejectOrder () {
            this.$emit('handleOrder', false);
        }
    }
});
</script>
