<template>
  <div v-if="!healthcenter" />
  <div
    v-else
    class="flex flex-col gap-2"
  >
    <div class="flex flex-col gap-1">
      <p class="font-bold">
        {{ healthcenter.name }}
      </p>
      <template
        v-if="healthcenter.address"
      >
        <div class="flex flex-col">
          <span>{{ healthcenter.address.street }}</span>
          <span>{{ healthcenter.address.postal_code }} {{ healthcenter.address.city }}</span>
        </div>
      </template>
      <p
        v-for="contact in healthcenter.contacts"
        :key="contact.type.slug"
      >
        {{ contact.type.name }}: {{ contact.value }}
      </p>
    </div>
    <div
      v-for="group in content.groups"
      :key="group.id"
      class="flex gap-1 items-center"
    >
      <icon
        name="mdi-account-group"
        :size="15"
        color="black"
      />
      <p>{{ group.name }}</p>
    </div>
    <span><strong>{{ stockQuantity }}</strong> stock</span>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';
import Icon from "@/core/components/icon/Icon.vue";
import {
    HealthcenterGroupMember
} from "@/disruption-logistics/interfaces/availability-providers";
import {HealthcenterGroup} from "@/disruption-logistics/interfaces/healthcenter-groups";

export default defineComponent({
    name: "HealthcenterGroupAvailabilityInfoWindow",
    components: {Icon},
    props: {
        content: {
            type: Object as PropType<{ healthcenter: HealthcenterGroupMember; groups: HealthcenterGroup[]; }>,
            required: false,
            default: null
        },
    },
    computed: {
    /**
     * Get the healthcenter from the content.
     */
        healthcenter() {
            return this.content?.healthcenter;
        },
        /**
     * Get the stock quantity of the healthcenter.
     *
     * @return {number}
     */
        stockQuantity() {
            if (this.healthcenter.stocks.length) {
                return this.healthcenter.stocks[0].quantity;
            }

            return 0;
        }
    }
});
</script>

<style scoped>

</style>
