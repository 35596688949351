<template>
  <interview-short-cut-container title="Questionnaires">
    <p class="-mt-3">
      Lancez un questionnaire rapide
    </p>
    <div class="flex flex-wrap gap-3 pt-3 pb-10">
      <div
        v-for="survey in surveys"
        :key="survey.id"
        class="bg-white rounded-md px-3 py-2 cursor-pointer"
        @click="startSurvey(survey)"
      >
        <span
          v-if="survey.emoji"
          class="mr-2"
        >{{ survey.emoji }}</span> <span>{{ survey.theme ?? survey.name }}</span>
      </div>
    </div>
    <div class="mt-4">
      <h2 class="text-lg mb-4">
        Résultats {{ meta?.total ? `(${meta.total})` : "" }}
      </h2>
      <div
        v-if="loading"
        class="mx-auto"
      >
        <base-spinner size="medium-small" />
      </div>
      <div
        v-else
        class="flex flex-col gap-3"
      >
        <p
          v-if="!instances?.length"
          class="text-center"
        >
          Aucun résultats de questionnaire
        </p>
        <div
          v-else
          class="flex flex-col gap-3"
        >
          <div
            v-for="instance in instances"
            :key="instance.id"
            class="cursor-pointer"
            @click="exportInstance(instance.id)"
          >
            <div class="bg-white rounded-lg px-3 py-2 shadow-md">
              <p class="line-clamp-2">
                <span
                  v-if="instance.survey.emoji"
                  class="mr-2"
                >{{ instance.survey.emoji }}</span>{{
                  instance.survey.name
                }}
              </p>
              <p class="text-sm mt-1">
                {{ $filters.date(instance.completed_at) }}
              </p>
              <div
                v-if="instance.verdict"
                class="flex mt-2"
              >
                <div
                  v-if="instance.verdict.color"
                  class="w-1 mr-2 rounded-sm"
                  :style="{
                    backgroundColor: instance.verdict.color
                  }"
                />
                <p
                  v-if="instance.verdict"
                  class="text-sm flex flex-1 line-clamp-3"
                >
                  {{ instance.verdict.title }}
                </p>
              </div>
            </div>
          </div>
          <base-button
            v-if="meta?.current_page < meta?.last_page"
            class="font-medium"
            :disabled="loadingMore"
            @click="loadMore"
          >
            <base-spinner
              v-if="loadingMore"
              size="button"
            />
            <span v-else>Voir plus</span>
          </base-button>
        </div>
      </div>
    </div>
  </interview-short-cut-container>
</template>

<script lang="ts">
import {defineComponent, inject} from "vue";
import InterviewShortCutContainer from '@/interview/components/instance-modal/short-cuts/InterviewShortCutContainer.vue';
import {surveyAnswerInstanceApi} from '@/container';
import BaseSpinner from '@/core/components/base/spinner/BaseSpinner.vue';
import SurveyAnswerInstanceService from '@/survey/services/SurveyAnswerInstanceService';
import BaseButton from '@/core/components/base/BaseButton.vue';
import {mapState} from "pinia";
import {useInterviewStore} from "@/stores/interview-store";

export default defineComponent({
    name: 'InterviewSurveys',
    components: {BaseButton, BaseSpinner, InterviewShortCutContainer},
    setup() {
        const openSurveyModal = inject<any>('openSurveyModal');

        return {
            openSurveyModal
        };
    },
    data() {
        return {
            instances: null as any[] | null,
            loading: false,
            loadingMore: false,
            exporting: false,
            meta: null as any
        };
    },
    computed: {
        ...mapState(useInterviewStore, ['surveys', 'consumerKey'])
    },
    mounted() {
        this.loading = true;
        this.loadConsumerInstances()
            .finally(() => {
                this.loading = false;
            });
    },
    methods: {
    /**
     * Load the consumer instances.
     *
     * @returns {Promise<void>|*}
     */
        loadConsumerInstances(page = 1) {
            if (!this.consumerKey) {
                return Promise.resolve();
            }

            return surveyAnswerInstanceApi()
                .answeredInstance({consumer_key: this.consumerKey, page})
                .then((response) => {
                    this.meta = response.meta;
                    this.instances = [...(this.instances ?? []), ...response.data];
                });
        },
        /**
     * Start a new survey instance.
     *
     * @param {Object} survey The survey to start.
     */
        startSurvey(survey) {
            this.openSurveyModal(
                survey.id,
                this.consumerKey,
                null,
                null,
                null,
                {
                    showVerdict: true,
                    consent: survey.consent,
                    onClose: () => {
                        this.instances = null;
                        this.loading = true;
                        this.loadConsumerInstances()
                            .finally(() => {
                                this.loading = false;
                            });
                    }
                }
            );
        },
        /**
     * Export the survey instance.
     *
     * @param {Number} instanceId The instance id.
     */
        exportInstance(instanceId) {
            if (this.exporting) {
                return;
            }

            this.exporting = true;
            SurveyAnswerInstanceService.exportAndOpen(instanceId)
                .finally(() => {
                    this.exporting = false;
                });
        },
        /**
     * Load next page of consumer instances.
     */
        loadMore() {
            if (!this.meta) {
                return;
            }

            this.loadingMore = true;
            this.loadConsumerInstances(this.meta?.current_page + 1)
                .finally(() => {
                    this.loadingMore = false;
                });
        }
    }
});
</script>
