import defaultSettings from '../../assets/files/preference.json';

/**
 * The storage service.
 * @author Tony Laurent <t.laurent@apodispharma.com>
 * @returns {Object} The public object.
 */
export default function () {
    return {
        /**
         * Store the item.
         * @param {string} key - The key of the item.
         * @param {string} value - The value of the item.
         * @returns {void}
         */
        store(key: string, value: string) {
            localStorage.setItem(key, value);
        },

        /**
         * Read the item.
         * @param {string} key - The key of the item.
         * @returns {string} The value of the item.
         */
        read(key: string) {
            return localStorage.getItem(key);
        },

        /**
         * Destroy the item.
         * @param {string} key - The key of the item.
         * @return {void}
         */
        destroy(key: string) {
            localStorage.removeItem(key);
        },

        loadSettings() {
            let settings;

            if (localStorage.getItem('preference')) {
                settings = JSON.parse(localStorage.getItem('preference') ?? "");

                const mergedSettings = this.deepMerge(defaultSettings, settings);

                localStorage.setItem('preference', JSON.stringify(mergedSettings));

                return mergedSettings;
            }

            return defaultSettings;
        },

        /**
         * Deep merge two objects.
         *
         * @param target
         * @param source
         */
        deepMerge(target: any, source: any) {
            const output = {...target};

            if (this.isObject(target) && this.isObject(source)) {
                Object.keys(source).forEach(key => {
                    if (this.isObject(source[key])) {
                        if (!(key in target)) Object.assign(output, {[key]: source[key]});
                        else output[key] = this.deepMerge(target[key], source[key]);
                    } else {
                        Object.assign(output, {[key]: source[key]});
                    }
                });
            }

            return output;
        },

        /**
         * Check if the value is an object.
         * @param {any} item - The item to check.
         * @returns {boolean} True if the item is an object, false otherwise.
         */
        isObject(item: any): boolean {
            return (item && typeof item === 'object' && !Array.isArray(item));
        }
    };
}
