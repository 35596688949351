import {LiveItemType} from "../interfaces/live/LiveItem";
import Order from "@/order/interfaces/Order";
import {HomeInterviewInstanceItem, HomeOrderItem, OrderCreatedBroadcastEvent} from "@/home/interfaces";
import {InterviewInstance} from "@/interview/interfaces/interview";

/**
 * Live item mapper service.
 */
export default function () {
    return {
        newOrder (order: Order | OrderCreatedBroadcastEvent): HomeOrderItem {
            return {
                type: LiveItemType.ORDER_CREATED,
                data: order
            };
        },
        newInterviewInstance(instance: InterviewInstance): HomeInterviewInstanceItem {
            return {
                type: LiveItemType.INTERVIEW_INSTANCE,
                data: instance
            };
        }
    };
}
