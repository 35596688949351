<template>
  <div class="flex flex-row">
    <div class="flex flex-row items-center">
      <img
        class="h-7 md:h-14 w-auto shadow"
        src="@/assets/images/logo-apodis.png"
        alt="Apodis"
      >
      <div class="flex flex-col ml-5">
        <span class="text-2xl text-primary font-heading font-bold">Equiper le patient de l'application Apodis</span>
        <span class="text-xl font-content">{{ customer.information.name }}</span>
      </div>
    </div>
    <span
      class="relative z-3 text-primary text-sm underline cursor-pointer mt-1 ml-4"
      @click="open"
    >
      <Icon name="mdi-help-circle-outline" class="mr-1" /> Qu'est-ce que c'est ?
    </span>
    <BaseModal
      v-show="isModalVisible"
      ref="modal"
      name="toto"
      @close="isModalVisible = false"
    >
      <BaseVideo reference="F3yA79GfLFA" />
    </BaseModal>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from "vue";
import BaseVideo from '@/core/components/base/BaseVideo.vue';
import BaseModal from '@/core/components/base/BaseModal.vue';
import Icon from "@/core/components/icon/Icon.vue";
import Customer from "@/customer/interfaces/Customer";

export default defineComponent({
    name: 'CustomerOnboardingHeader',
    components: {
        Icon,
        BaseVideo,
        BaseModal
    },
    props: {
        customer: {
            type: Object as PropType<Customer>,
            required: true
        }
    },
    data() {
        return {
            isModalVisible: false
        };
    },
    methods: {
        open() {
            this.isModalVisible = true;
        }
    }
});
</script>
