<template>
  <div class="flex space-x-4">
    <img
      class="h-14 rounded-full"
      :src="avatar()"
    >
    <div>
      <div
        v-if="customer.information.name"
        class="text-xl flex flex-row py-1"
        :class="active ? 'font-semibold': ''"
      >
        <div>{{ customer.information.name }}</div>
        <button @click="refresh">
          <icon
            class="pl-2"
            name="mdi-sync"
            :size="12"
            color="#FFFFFF"
          />
        </button>
      </div>
      <div class="flex flex-col sm:flex-row sm:flex-wrap sm:mt-0 sm:space-x-6">
        <div v-if="customer.information.birth_date">
          <div class="text-sm">
            né le {{ customer.information.formatted_birth_date }}
          </div>
          <div class="text-sm">
            {{ age }} ans
          </div>
        </div>
        <div
          v-else
          class="text-sm"
        >
          Date de naissance non renseignée.
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import dayjs from 'dayjs';
import userMaleIcon from '@/assets/icons/user-male.png';
import userFemaleIcon from '@/assets/icons/user-female.png';
import Icon from '@/core/components/icon/Icon.vue';

export default defineComponent({
    name: 'CustomerSummary',
    components: { Icon },
    props: {
        customer: {
            type: Object,
            required: true
        },
        active: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            userMaleIcon,
            userFemaleIcon
        };
    },

    computed: {
        age () {
            const birthdate = dayjs(this.customer.information.birth_date);

            return Math.floor(dayjs().diff(birthdate, 'year'));
        }
    },
    methods: {
        avatar () {
            return this.customer.information.gender === 1
                ? this.userMaleIcon
                : this.userFemaleIcon;
        },
        refresh () {
            window.dispatchEvent(new Event('refresh-customer'));
        }
    }
});
</script>
