<template>
  <div v-if="!loading">
    <header-back-button :title="category.name" />
    <v-tabs grow>
      <v-tab :to="{name: 'program-bundle.detail.category.attachments'}">
        Ressources
      </v-tab>
      <v-tab :to="{name: 'program-bundle.detail.category.programs'}">
        Programmes
      </v-tab>
      <v-tab :to="{name: 'program-bundle.detail.category.consumers'}">
        Patients
      </v-tab>
    </v-tabs>
    <router-view
      v-if="category"
      :category="category"
    />
  </div>
  <base-flex-spinner v-else />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { programApi } from '@/container';
import BaseFlexSpinner from '@/core/components/base/spinner/BaseFlexSpinner.vue';
import HeaderBackButton from "@/core/components/header-back-button/HeaderBackButton.vue";

/**
 * Display a program category details for a bundle
 */
export default defineComponent({
    name: 'ProgramBundleProgramCategoryDetails',
    components: {HeaderBackButton, BaseFlexSpinner },
    data () {
        return {
            category: null as any,
            loading: false
        };
    },
    watch: {
        '$route.params': {
            immediate: true,
            handler (value) {
                this.category = null;
                this.loading = true;
                this.readCategory(value.programBundleId, value.programCategoryId)
                    .finally(() => this.loading = false);
            }
        }
    },
    methods: {
    /**
     * Read the bundle category.
     *
     * @param {number} bundleId - The bundle id.
     * @param {number} categoryId - The category id.
     *
     * @return {Promise<void>}
     */
        readCategory (bundleId, categoryId) {
            return programApi()
                .bundleCategory(bundleId, categoryId)
                .then(category => {
                    this.category = category;
                });
        }
    }
});
</script>
