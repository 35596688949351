import {AxiosResponse} from "axios";
import ImageFile from "@/core/interfaces/laboratory/ImageFile";

/**
 * The filemanager file resource.
 *
 * @param {Object} client - The injected client.
 * @returns {Object} The public object.
 */
export default function (client) {
    return {
        /**
         * Upload file to filemanager.
         *
         * @return {Promise<any>}
         * @param blob
         */
        upload(blob: Blob): Promise<ImageFile> {
            const formData = new FormData();
            formData.append('file', blob);

            return client.post("/filemanager/upload?location=%2F", formData)
                .then((response: AxiosResponse) => response.data.file);
        },

        /**
         * Get the file already uploaded to the filemanager.
         *
         * @return {Promise<any[]>}
         */
        index(): Promise<ImageFile[]> {
            const params = {
                type: "image",
                location: "/"
            };

            return client.get("filemanager", {params})
                .then((response: AxiosResponse) => response.data.files);
        },

        /**
         * Read the file already uploaded to the filemanager.
         *
         * @return {Promise<any[]>}
         */
        read(id: number): Promise<ImageFile> {
            return client.get(`filemanager/${id}`)
                .then((response: AxiosResponse) => response.data.file);
        }
    };
};
