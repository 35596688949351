<template>
  <div class="space-y-3">
    <BaseRadio
      v-model="localeValue"
      :value="value"
      :label="label"
    />
    <NotificationSimpleLayout v-if="value === 'simple'" />
    <NotificationAdvancedLayout v-if="value === 'advanced'" />
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import BaseRadio from '@/core/components/base/BaseRadio.vue';
import NotificationSimpleLayout from '@/settings/components/NotificationSimpleLayout.vue';
import NotificationAdvancedLayout from '@/settings/components/NotificationAdvancedLayout.vue';

export default defineComponent({
    name: 'NotificationLayoutRadioButton',
    components: {NotificationAdvancedLayout, NotificationSimpleLayout, BaseRadio},
    model: {
        prop: 'model',
        event: 'change'
    },
    props: {
        value: {
            type: String,
            required: true
        },
        label: {
            type: String,
            required: true
        },
        modelValue: {
            type: null,
            required: true
        }
    },
    emits: ['update:modelValue'],
    computed: {
        localeValue: {
            get() {
                return this.modelValue;
            },
            set(value: any) {
                this.$emit('update:modelValue', value);
            }
        }
    }
});
</script>
