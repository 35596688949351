<script setup lang="ts">
import {NotificationDisruptionAlertProduct} from "@/core/interfaces/disruption/NotificationDisruptionAlertProduct";

defineProps<{ product: NotificationDisruptionAlertProduct}>();
</script>

<template>
  <div class="flex flex-col">
    <p>
      {{ product.product.name }}
    </p>
    <div class="flex flex-col pt-3">
      <p>
        Stock au jour : {{ product.stockQuantity }}
      </p>
      <p>
        Vente / jour : {{ product.dailySales }}
      </p>
      <p>
        Quantité commandée : {{ product.orderedQuantity }}
      </p>
      <p>
        Quantité disponible : {{ product.deliveredQuantity }}
      </p>
    </div>
  </div>
</template>

<style scoped>

</style>