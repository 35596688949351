<template>
  <v-expansion-panels flat bg-color="transparent">
    <SupportProgramGDPRMention />
    <v-expansion-panel
      v-for="mention in mentions"
      :key="mention.id"
    >
      <v-expansion-panel-title color="bg-primary-lightest">
        <div class="flex flex-row align-center">
          <span class="font-weight-bold mr-10">{{ mention.name }}</span>
          <span>Voir les informations</span>
        </div>
      </v-expansion-panel-title>
      <v-expansion-panel-text color="bg-primary-lightest">
        <div class="flex flex-1 flex-col justify-center">
          <markdown
            :content="mention.markdown"
            class="text-sm"
            inherit-styles
          />
        </div>
      </v-expansion-panel-text>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script lang="ts">
import {defineComponent, PropType} from "vue";
import Markdown from '@/core/components/markdown/Markdown.vue';
import SupportProgramGDPRMention from '@/program/components/support-program/SupportProgramGDPRMention.vue';

export default defineComponent({
    name: 'SupportProgramMention',
    components: {
        SupportProgramGDPRMention,
        Markdown
    },
    props: {
        mentions: {
            type: Array as PropType<{ id: number; name: string; markdown: string }[]>,
            required: true
        }
    }
});
</script>
