<script setup lang="ts">

import BaseContainer from "@/core/components/base/BaseContainer.vue";
import {useInterviewStore} from "@/stores/interview-store";
import {storeToRefs} from "pinia";
import BaseButton from "@/core/components/base/BaseButton.vue";
import {computed, inject, onMounted, onUnmounted, ref} from "vue";
import dayjs from "dayjs";
import InterviewAudioService from "@/interview/services/InterviewAudioService";
import InterviewContainer from "@/interview/components/instance-modal/InterviewContainer.vue";
import {DotLottieVue} from "@lottiefiles/dotlottie-vue";
import {useRoute, useRouter} from "vue-router";
import {programApi} from "@/container";
import {Backing} from "@/backing/interfaces/backing";
import InterviewInstanceBacking from "@/interview/components/instance-modal/InterviewInstanceBacking.vue";
import ProgramAppointment from "@/program/components/program-appointment/ProgramAppointment.vue";
import {usePatientStore} from "@/stores/patient.store";
import CustomerCertification from "@/customer/components/customer-certification/CustomerCertification.vue";

const interviewStore = useInterviewStore();
const {customer} = usePatientStore();
const router = useRouter();
const route = useRoute();

const {audio, interview, interviewInstance} = storeToRefs(interviewStore);
const downloadingAudio = ref(false);
const isReady = ref(false);
const backing = ref<Backing>();
const loadingBacking = ref(false);

let removeSocketListener: (() => void) | null = null;

/**
 * On mount, run the prefill survey job and listen to the success event.
 */
onMounted(() => {
    if (!interviewInstance.value?.id || !interviewStore.interview || !interviewStore.consumerKey) {
        return;
    }

    if(route.query.backingId) {
        loadBacking(Number(route.query.backingId), interviewStore.consumerKey);
    }

    if (!!interviewInstance.value.prefill_job_started_at && !interviewInstance.value.prefill_job_ended_at) {
        return;
    }

    if (interviewInstance.value.survey_answer_instance_id) {
        isReady.value = true;
    } else {
        interviewStore.runPrefillSurveyJob();
        listenSuccessEvent();
    }
});

/**
 * On unmount, remove the socket listener.
 */
onUnmounted(() => {
    if (removeSocketListener) {
        removeSocketListener();
    }
});

/**
 * Listen to the success event.
 */
const listenSuccessEvent = () => {
    removeSocketListener = window.ipcRenderer.socketListenEvent('interview-instance.survey.prefilled', (event, {data}) => {
        if (data.instance?.id === interviewInstance.value?.id) {
            isReady.value = true;
        }
    });
};

/**
 * Get program linked to the interview.
 */
const program = computed(() => {
    return customer?.programs.find(program => {
        if (program.backings?.length) {
            return program.backings.find(backing => {
                return backing.interviews.find(backingInterview => {
                    if (interview.value) {
                        return backingInterview.id === interview.value.id;
                    }

                    return false;
                });
            });
        }
    });
});

/**
 * Compute the audio file name.
 */
const fileName = computed(() => {
    const name = interviewStore.consumerName?.replace(/\s/g, '_') ?? '';
    const date = dayjs().format("DD_MM_YYYY");

    return `${interviewStore.interview?.name}_${name}_${date}`;
});

/**
 * Download the audio file.
 */
const downloadAudio = () => {
    if (!audio.value || !interview.value) {
        return;
    }

    downloadingAudio.value = true;
    InterviewAudioService.downloadAudio(
        interview.value.id,
        audio.value,
        fileName.value
    )
        .finally(() => {
            downloadingAudio.value = false;
        });
};

/**
 * Navigate to the survey page.
 */
const goToSurvey = () => {
    router.replace({
        name: 'interview-instances.survey',
        query: {...route.query}
    });
};

/**
 * Load the backing attached to the store.
 *
 * @param backingId
 * @param consumerKey
 */
const loadBacking = async (backingId: number, consumerKey: string) => {
    loadingBacking.value = true;
    return programApi().backing(backingId, consumerKey)
        .then((response) => {
            backing.value = response;
        })
        .finally(() => {
            loadingBacking.value = false;
        });
};

/**
 * Close function injected from InterviewInstanceLayoutView
 */
const close = inject('close') as () => void;
</script>

<template>
  <BaseContainer class="flex flex-row gap-x-6 pl-5 py-5 h-full">
    <div class="flex-1 space-y-6">
      <div v-if="audio" class="flex-1 flex gap-x-6">
        <div class="flex-1">
          <h1>Enregistrement terminé</h1>
          <p>Votre entretien a bien été enregistré. Vous pouvez enregistrer l'audio sur votre ordinateur</p>
        </div>
        <BaseButton
          :loading="downloadingAudio"
          prepend-icon="mdi-volume-high"
          :primary="true" @click="downloadAudio"
        >
          Télécharger l'audio
        </BaseButton>
      </div>
      <InterviewContainer class="gap-y-6">
        <div class="flex gap-6 items-center">
          <template v-if="!isReady">
            <img
              src="@/assets/images/let-the-magic-happens.png" alt="let-the-magic-append"
              class="w-[120px]"
            >
            <div class="space-y-2">
              <h2 class="text-black">
                Vérifiez et validez le compte-rendu d'entretien
              </h2>
              <p>
                Notre IA est en train de préparer un compte-rendu d'entretien que vous pourrez finaliser dans moins de 2 minutes.
                En attendant, vous pouvez lancer un autre entretien, ou revenir plus tard pour valider le compte rendu.
              </p>
            </div>
          </template>
          <template v-else>
            <DotLottieVue
              src="animations/success.json"
              class="w-[120px] h-[120px]"
              :autoplay="true"
            />
            <div class="space-y-2">
              <h2 class="text-black">
                Votre compte-rendu est prêt !
              </h2>
              <p>
                Cliquez pour vérifier et finaliser le compte-rendu
              </p>
            </div>
          </template>
        </div>
        <div class="flex justify-end gap-x-6 w-full">
          <BaseButton @click="close">
            Finaliser plus tard
          </BaseButton>
          <BaseButton :primary="true" :disabled="!isReady" @click="goToSurvey">
            Finaliser le compte-rendu
          </BaseButton>
        </div>
      </InterviewContainer>
      <div class="flex flex-row">
        <ProgramAppointment
          v-if="program?.bookable"
          :bookable="program.bookable"
          :programmable-id="program.programmable_id"
          :programmable-type="program.programmable_type"
          class="w-[50%]"
        />
        <CustomerCertification
          v-if="program && customer && program?.onboarding_communication"
          :customer="customer"
          :program-id="program.id"
          :communication-text="program.onboarding_communication"
          class="pl-3 w-[50%]"
        />
      </div>
      <InterviewInstanceBacking
        v-if="backing && interview"
        :backing="backing"
        :interview-id="interview.id"
        :loading="loadingBacking"
      />
    </div>
    <slot name="shortcuts" />
  </BaseContainer>
</template>

<style scoped>

</style>
