<script setup lang="ts">
import {ref, watch, onMounted} from "vue";
import {offerApi} from "@/container";
import {useRouter} from "vue-router";
import Offer from "@/core/interfaces/Offer";
import BaseCard from "@/core/components/base/BaseCard.vue";
import BaseCardBody from "@/core/components/base/BaseCardBody.vue";
import BaseFlexSpinner from "@/core/components/base/spinner/BaseFlexSpinner.vue";
import InformationPanel from "@/core/components/information-panel/InformationPanel.vue";
import Icon from "@/core/components/icon/Icon.vue";

const page = ref(1);
const lastPage = ref(1);
const loading = ref(true);
const offers = ref<Offer[]>([]);
const router = useRouter();

onMounted(() => {
    loadOffers();
});

watch(page, async () => {
    loadOffers();
});

/**
 * Load offers.
 */
const loadOffers = () => {
    loading.value = true;
    offerApi().index(page.value)
        .then(response => {
            offers.value = response.data;
            lastPage.value = response.meta.last_page;
        })
        .finally(() => loading.value = false);
};

/**
 * On offer press.
 * @param offerId
 */
const onOfferPress = (offerId: number) => {
    router.push({
        name: 'patient-administration.offers.detail',
        params: {
            offerId
        }
    });
};

</script>

<template>
  <div>
    <base-flex-spinner v-if="loading" />
    <div
      v-else
    >
      <information-panel class="p-6">
        <div class="flex flex-col items-start">
          <h2 class="text-white">
            Vos promotions
          </h2>
          <p class="font-bold text-white">
            Vous pouvez publier les promotions récupérées dans votre base de données
            sur l'application Apodis afin que vos patients en bénéficient dans l'expérience Click & Collect.
          </p>
        </div>
      </information-panel>
      <base-card
        v-for="offer in offers"
        :key="offer.id"
        class="relative overflow-visible m-5 cursor-pointer hover:opacity-70"
      >
        <base-card-body>
          <div
            class="flex flex-row justify-between"
            @click="onOfferPress(offer.id)"
          >
            <div class="flex justify-between align-center ml-4">
              <div>
                <p>{{ offer.name }}</p>
                <p class="text-primary">
                  {{ $filters.date(offer.start_at, 'short') }} - {{ $filters.date(offer.end_at, 'short') }}
                </p>
              </div>
            </div>
            <div class="flex flex-row items-center">
              <p :class="[{'text-primary': offer.is_published}]">
                {{ offer.is_published ? 'Publiée !' : 'Non publiée' }}
              </p>
              <div class="bg-primary p-3 ml-4 rounded-full hover:opacity-50">
                <icon name="mdi-tag-edit-outline" :color="'#fff'" />
              </div>
            </div>
          </div>
        </base-card-body>
      </base-card>
      <div class="text-center mb-4">
        <v-pagination
          v-model="page"
          :length="lastPage"
          :total-visible="7"
          circle
        />
      </div>
    </div>
  </div>
</template>
