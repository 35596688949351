<template>
  <div class="flex flex-col gap-1">
    <p class="italic">
      À nouveau disponible {{ dateLabel }}
    </p>
    <base-card>
      <base-card-body class="flex flex-col gap-4">
        <div
          v-for="(warehouse, index) in sortedWarehouses()"
          :key="warehouse"
          class="flex flex-col gap-2"
        >
          <p class="text-primary font-bold">
            {{ warehouse }}
          </p>
          <div
            v-for="(availability, _index) in sortedWarehouseAvailabilities(warehouse)"
            :key="_index"
          >
            <p>{{ availability.product.name }}</p>
          </div>
          <hr
            v-if="index !== Object.keys(availabilitiesBySource).length - 1"
            class="mt-2"
          >
        </div>
      </base-card-body>
    </base-card>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';
import dateHelper from "@/core/helpers/dateHelper";
import BaseCard from "@/core/components/base/BaseCard.vue";
import BaseCardBody from "@/core/components/base/BaseCardBody.vue";
import {ProductAvailabilityBySource} from "@/disruption-logistics/views/ProductAvailableAgainView.vue";
import {ProductAvailability} from "@/disruption-logistics/interfaces";

export default defineComponent({
    name: "ProductAvailableAgainCard",
    components: {BaseCardBody, BaseCard},
    props: {
        availabilitiesBySource: {
            type: Object as PropType<ProductAvailabilityBySource>,
            required: true
        },
        date: {
            type: Date,
            required: true
        }
    },
    computed: {
        /**
         * Check if the date is today.
         */
        isToday(): boolean {
            return this.date.toDateString() === new Date().toDateString();
        },
        /**
         * Get the label for the date.
         */
        dateLabel(): string {
            return this.isToday ? 'Aujourd\'hui' : 'le ' + dateHelper.shortFormat(this.date.toDateString());
        }
    },
    methods: {
        /**
         * Get the availabilities sorted by warehouse.
         *
         * @param {string} warehouse The warehouse name.
         */
        sortedWarehouseAvailabilities(warehouse: string): ProductAvailability[] {
            const warehouseAvailabilities = this.availabilitiesBySource[warehouse];

            return warehouseAvailabilities.sort((a, b) => {
                return a.product.name.localeCompare(b.product.name, undefined, {numeric: true});
            });
        },
        /**
         * Sort keys by date.
         * */
        sortedWarehouses(): string[] {
            return Object.keys(this.availabilitiesBySource).sort();
        }
    }
});
</script>

<style scoped>

</style>
