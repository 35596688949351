<template>
  <base-container class="p-6">
    <div
      v-if="loading"
      class="flex flex-1"
    >
      <base-flex-spinner size="medium-small" />
    </div>
    <div
      v-else-if="customer && instances"
    >
      <div class="space-y-2">
        <h2 class="text-lg">
          Questionnaires disponibles
        </h2>
        <p class="text-sm">
          Remplissez un questionnaire avec votre patient pour mieux l'accompagner
        </p>
        <div class="flex flex-wrap gap-3 py-2">
          <survey-card
            v-for="survey in surveys"
            :key="`survey-${survey.id}`"
            :survey="survey"
            :consumer-key="customer.consumer_key"
            @survey-completed="refreshInstances"
          />
        </div>
      </div>
      <div class="flex flex-1 flex-col mt-4 space-y-2">
        <div class="flex items-center">
          <h2 class="text-lg">
            Résultats
          </h2>
          <div
            v-if="refreshing"
            class="ml-2"
          >
            <base-spinner size="button" />
          </div>
        </div>
        <div
          v-if="instances.length > 0"
          class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 flex-1 gap-5 pb-2"
        >
          <customer-survey-card
            v-for="instance in instances"
            :key="`instance-${instance.id}`"
            :instance="instance"
          />
        </div>
        <div
          v-else
          class="text-sm ml-6"
        >
          Aucun résultat disponible pour le moment.
        </div>
      </div>
    </div>
  </base-container>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import { surveyAnswerInstanceApi, surveyApi } from '@/container';
import BaseSpinner from '@/core/components/base/spinner/BaseSpinner.vue';
import CustomerSurveyCard from '@/customer/components/customer-surveys/CustomerSurveyCard.vue';
import SurveyCard from '@/survey/components/SurveyCard.vue';
import BaseContainer from '@/core/components/base/BaseContainer.vue';
import BaseFlexSpinner from '@/core/components/base/spinner/BaseFlexSpinner.vue';
import { mapState } from 'pinia';
import { usePatientStore } from '@/stores/patient.store';
import {Survey, SurveyAnswerInstance} from "@/core/interfaces/survey/Survey";

export default defineComponent({
    name: 'CustomerSurveys',
    components: { BaseFlexSpinner, BaseContainer, SurveyCard, CustomerSurveyCard, BaseSpinner },
    data () {
        return {
            /** The available surveys. */
            surveys: [] as Survey[],
            /** The answered instances. */
            instances: null as null | SurveyAnswerInstance[],
            /** The instance meta data */
            meta: null,
            /** The loading state. */
            loading: true,
            /** The refreshing state. */
            refreshing: false
        };
    },
    computed: {
        ...mapState(usePatientStore, ['customer'])
    },
    /**
   * Load the instances and the surveys when the component is mounted.
   */
    mounted () {
        this.loading = true;
        Promise.all([this.loadInstances(), this.loadSurveys()])
            .finally(() => {
                this.loading = false;
            });
    },
    methods: {
    /**
     * Load the survey answer instances of the customer.
     *
     * @param {Number} page The page number.
     *
     * @return {Promise<void>}
     */
        loadInstances (page = 1) {
            if (this.customer?.consumer_key) {
                return surveyAnswerInstanceApi()
                    .answeredInstance({ consumer_key: this.customer.consumer_key, page })
                    .then(response => {
                        this.meta = response.meta;
                        this.instances = (!this.instances || page === 1)
                            ? response.data
                            : [...this.instances, ...response.data];
                    });
            } else {
                return Promise.resolve();
            }
        },
        /**
     * Load all the survey available that are not linked to a pathway.
     *
     * @returns {Promise<void>}
     */
        loadSurveys () {
            return surveyApi()
                .index({ pathway: false })
                .then(response => {
                    this.surveys = response;
                });
        },
        /**
     * Refresh the consumer instances.
     */
        refreshInstances () {
            this.refreshing = true;
            this.loadInstances()
                .finally(() => {
                    this.refreshing = false;
                });
        }
    }
});
</script>
