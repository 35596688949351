<script lang="ts" setup>
import Customer from "@/customer/interfaces/Customer";
import Icon from "@/core/components/icon/Icon.vue";
import {useSystemStore} from "@/stores/system.store";
import NotificationPreview from "@/core/components/notification-preview/NotificationPreview.vue";

const {environment} = useSystemStore();

defineProps<{
    customer: Customer;
    dates: Date[];
}>();

const customerPhone = defineModel<string | null>("customerPhone", {required: true});
</script>

<template>
  <div class="flex flex-col gap-y-4">
    <div class="step-card">
      <div class="flex flex-col gap-y-2">
        <h3>
          {{ customer.information.first_name }} n'a pas l'application Apodis
          <Icon name="mdi-close" class="text-red" />
        </h3>
        <div class="flex gap-x-2">
          <p>
            Les rappels seront envoyés par SMS et un autre SMS sera envoyé pour équiper le patient d'Apodis afin de
            lui envoyer les futurs rappels par notification.
          </p>
          <img class="w-16 relative -top-2" src="@/assets/images/iphone-mockup.png" alt="Phone">
        </div>
        <div class="flex text-primary justify-between items-center gap-x-3">
          <div>Numéro de {{ customer.information.first_name }}</div>
          <input v-model="customerPhone" size="18" placeholder="Numéro de mobile" class="bg-gray-100 rounded">
        </div>
      </div>
    </div>
    <div>
      <h2 class="text-gray-dark mb-2">
        Le patient recevra :
      </h2>
      <div class="flex flex-col gap-y-6">
        <NotificationPreview
          type="sms"
          :count="dates.length"
          when="3 jours avant chaque renouvellement"
          :content="`Message de la ${(environment as any)?.entity.name || 'pharmacie'}: n'oubliez pas de renouveler votre ordonnance. Retrouvez vos ordonnances sur https://www.apodis-sante.com/apodis/`"
        />
        <NotificationPreview
          type="sms"
          :count="1"
          when="À la création du plan"
          :content="`Message de la ${(environment as any)?.entity.name || 'pharmacie'}: n'oubliez pas de renouveler votre ordonnance. Retrouvez vos ordonnances sur https://www.apodis-sante.com/apodis/`"
        />
      </div>
    </div>
  </div>
</template>
