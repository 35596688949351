<script setup lang="ts">
import categories from "@/core/factories/search-bar/CustomerQuickFilters";
import QuickFilters from "@/core/components/filters/QuickFilters.vue";
import {QuickFilter, SearchItem} from "@/core/interfaces/search-bar";
import SearchBarService from "@/core/services/SearchBarService";
import {ref} from 'vue';

const {selected} = defineProps<{selected: SearchItem[]}>();
const emits = defineEmits(['onFilterChange']);
const filters = ref(selected);

/**
 * Check if the item is a customer quick filter.
 *
 * @param {SearchItem} item - The search item.
 *
 * @return {item is QuickFilter<any>}
 */
const isCustomerQuickFilter = (item: SearchItem): item is QuickFilter<any> => {
    return SearchBarService.isQuickFilter(item, categories);
};

const selectedFilters = ref<QuickFilter<any>[]>(filters.value.filter(isCustomerQuickFilter));

/**
 * Handle the quick filter press.
 *
 * @param {SearchItem} item - The pressed item.
 */
const handleItemPress = (item: QuickFilter<any>) => {
    const elementIndex = SearchBarService.getItemIndex(item, selectedFilters.value);
    const copy = [...selectedFilters.value];

    if (elementIndex === -1) {
        copy.push(item);
    } else {
        copy.splice(elementIndex, 1);
    }

    selectedFilters.value = copy;
    emits('onFilterChange', copy);
};
</script>

<template>
  <div>
    <QuickFilters
      :categories="categories"
      :selected-filters="selectedFilters"
      @on-filter-press="handleItemPress"
    />
  </div>
</template>
