<script setup lang="ts">
import ExtensionGrid from "@/program/components/program-grid/ExtensionGrid.vue";
import Extension from "@/core/interfaces/extension/Extension";
import {useRouter} from "vue-router";
import {useExtensionStore} from "@/stores/extension.store";
import {storeToRefs} from "pinia";
import {computed} from "vue";
import hospitalCityVideoThumbnail from "@/assets/images/hospital_city_video_thumbnail.png";
import ExtensionNavigationService from "@/extension/services/ExtensionNavigationService";

const extensionStore = useExtensionStore();
const {extensions} = storeToRefs(extensionStore);
const router = useRouter();

/**
 * The hospital extensions.
 */
const hospitalExtensions = computed(() => {
    return extensions.value?.filter((extension) => {
        return extension.organization.entity_type === 'hospital';
    }) ?? [];
});


/**
 * Navigate to the extension details.
 */
const navigateToExtensionDetails = (extension: Extension) => {
    return ExtensionNavigationService.navigate(extension, router);
};

const show = () => {
    window.open('https://www.youtube.com/watch?v=23kNDrPCdaI', '_blank');
};
</script>

<template>
  <div class="p-4">
    <div class="flex flex-1 flex-row">
      <div class="flex flex-1 flex-col pr-5">
        <h1>Suivez tous vos patients en traitement à domicile</h1>
        <p>Retrouvez les patients faisant partie d'un programme d'accompagnement renforcé ville-hopital.</p>
      </div>
      <img
        :src="hospitalCityVideoThumbnail"
        class="cursor-pointer shadow-xl hover:opacity-75"
        @click="show"
      >
    </div>
    <ExtensionGrid
      title="Hôpitaux"
      :extensions="hospitalExtensions"
      @extension-click="(extension) => navigateToExtensionDetails(extension)"
    />
  </div>
</template>
