<template>
  <BaseCard>
    <BaseCardBody>
      <p class="font-bold">
        {{ title }}
      </p>
      <p class="text-sm">
        {{ description }}
      </p>
      <program-attachment-grid
        :attachments="attachments"
        class="p-5"
        :attachable-id="Number($route.params.programId)"
        attachable-type="programs"
      />
    </BaseCardBody>
  </BaseCard>
</template>

<script lang="ts">
import {defineComponent, PropType} from "vue";
import BaseCard from '@/core/components/base/BaseCard.vue';
import BaseCardBody from '@/core/components/base/BaseCardBody.vue';
import ProgramAttachmentGrid from '@/program/components/program-attachment/ProgramAttachmentGrid.vue';
import {ProgramAttachment} from "@/program/interfaces/Program";

export default defineComponent({
    name: 'SupportProgramDocumentGrid',
    components: {
        ProgramAttachmentGrid,
        BaseCard,
        BaseCardBody
    },
    props: {
        attachments: {
            type: Array as PropType<ProgramAttachment[]>,
            required: true
        },
        title: {
            type: String,
            default: 'Fiches conseils'
        },
        description: {
            type: String,
            default: 'Plusieurs documents informatifs sont à votre disposition ci-dessous.' +
        'Imprimez-les et remettez les à vos patients'
        }
    }
});
</script>
