import {RouteRecordRaw} from "vue-router";
import ExtensionStoreView from "@/extension/views/ExtensionStoreView.vue";
import ExtensionStoreDetailsView from "@/extension/views/ExtensionStoreDetailsView.vue";

const routes: RouteRecordRaw[] = [
    {
        path: '/extension-store',
        name: 'extension-store',
        component: ExtensionStoreView
    },
    {
        path: '/extensions/:extensionId',
        name: 'extension-details',
        component: ExtensionStoreDetailsView
    }
];

export default routes;
