import {QuickFilterCategory} from "@/core/interfaces/search-bar";
import {CUSTOMER_SEARCH_QUERY_PARAM_KEYS} from "@/core/interfaces/search-bar/ICustomerSearchBar";

/**
 * The available customer quick filters items.
 *
 * @type {QuickFilterCategory[]}
 */
const categories: QuickFilterCategory[] = [
    {
        name: "Sexe",
        filters: [
            {
                label: "Homme",
                value: 1,
                queryParam: CUSTOMER_SEARCH_QUERY_PARAM_KEYS.GENDER
            },
            {
                label: "Femme",
                value: 2,
                queryParam: CUSTOMER_SEARCH_QUERY_PARAM_KEYS.GENDER
            }
        ]
    },
    {
        name: "Âge",
        filters: [
            {
                label: "18-24 ans",
                value: {
                    from: 18,
                    to: 24
                },
                queryParam: CUSTOMER_SEARCH_QUERY_PARAM_KEYS.AGE
            },
            {
                label: "25-39 ans",
                value: {
                    from: 25,
                    to: 39
                },
                queryParam: CUSTOMER_SEARCH_QUERY_PARAM_KEYS.AGE
            },
            {
                label: "40-59 ans",
                value: {
                    from: 40,
                    to: 59
                },
                queryParam: CUSTOMER_SEARCH_QUERY_PARAM_KEYS.AGE
            },
            {
                label: "60-69 ans",
                value: {
                    from: 60,
                    to: 69
                },
                queryParam: CUSTOMER_SEARCH_QUERY_PARAM_KEYS.AGE
            },
            {
                label: "70 ans et +",
                value: {
                    from: 70,
                    to: 1000
                },
                queryParam: CUSTOMER_SEARCH_QUERY_PARAM_KEYS.AGE
            }
        ]
    },
    {
        name: "Application Apodis",
        filters: [
            {
                label: "Patients équipés",
                value: true,
                queryParam: CUSTOMER_SEARCH_QUERY_PARAM_KEYS.CERTIFIED
            },
            {
                label: "Patients non équipés",
                value: false,
                queryParam: CUSTOMER_SEARCH_QUERY_PARAM_KEYS.CERTIFIED
            }
        ]
    }
];

export default categories;
