<script setup lang="ts">
import {BackingStep} from "@/backing/interfaces/backing";
import BaseCard from "@/core/components/base/BaseCard.vue";
import Icon from "@/core/components/icon/Icon.vue";
import {computed} from "vue";

const props = defineProps<{
  /** The step */
  step: BackingStep
}>();

/**
 * Get the color of the step
 */
const color = computed(() => props.step.completed_at ? 'secondary' : 'primary');
</script>

<template>
  <BaseCard class="py-2 px-4 h-min cursor-pointer">
    <div class="min-w-28">
      <icon :size="20" name="mdi-chat" :class="'mr-1 text-' + color " />
      <span class="">{{ step.name }}</span>
    </div>
  </BaseCard>
</template>
