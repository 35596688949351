import {LegacySellinOffer} from "@/core/interfaces/SellinOffer";
import noProductImage from '@/assets/images/no-product-image.png';

export default {
    /**
     * Get the image uri from imagePath (basically append imagePath to API_ROOTURL).
     *
     * @param  {string} imagePath
     *
     * @returns string | undefined
     */
    getURLFromPath(imagePath: string): string | undefined {
        const apiBase = import.meta.env.VITE_API_URL;

        if (apiBase !== undefined) {
            return `${apiBase}/${imagePath}`;
        }

        return undefined;
    },

    /**
     * Get the image uri from a calendar event.
     *
     * @param {Offer} offer
     *
     * @returns string | undefined
     */
    getOfferImage(offer: LegacySellinOffer): string | undefined {
        if (offer.imagePath) {
            return this.getURLFromPath("images/" + offer.imagePath);
        }
    },

    /**
     * Get the product image uri from a LaboratoryProduct or SellinOfferProduct object.
     *
     * @param  {LaboratoryProduct | SellinOfferProduct} product
     *
     * @returns string
     */
    getLaboratoryProductImage(product: { photoPath: string } | undefined): string {
        const path = product?.photoPath;
        const apiBase = import.meta.env.VITE_API_URL;

        if ((path !== undefined && path?.length !== 0) && apiBase !== undefined) {
            return `${apiBase}/images/${path}`;
        }

        return noProductImage;
    }
};
