import {AxiosResponse} from "axios";
import DeprecatedGrouping from "@/core/interfaces/laboratory/Grouping";
import {Healthcenter} from "@/core/interfaces/healthcenter/Healthcenter";

/**
 * HealthcenterGroupingApiResponse interface.
 *
 * @property {DeprecatedGrouping} grouping - The healthcenter's grouping.
 */
interface HealthcenterGroupingApiResponse {
    grouping: DeprecatedGrouping;
}

/**
 * The healthcenters product resource.
 *
 * @param {Object} client - The injected client.
 * @returns {Object} The public object.
 */
export default function (client) {
    return {
        /**
         * Get healthCenter information.
         *
         * @param {number} id - The healthcenter id.
         *
         * @return {Promise<LegacyHealthcenter>}
         */
        read(id: number): Promise<Healthcenter> {
            return client.get(`/healthcenters/${id}`)
                .then((response: AxiosResponse) => response.data.data);
        },

        /**
         * Update healthCenter information.
         *
         * @param {number} healthCenterId - HealthCenter id.
         * @param {object} data - New healthCenter information.
         *
         * @return {Promise<any>}
         */
        update(healthCenterId: number, data: any): Promise<any> {
            return client.put(`/healthcenters/${healthCenterId}`, data)
                .then((response: AxiosResponse) => response.data);
        },

        /**
         * Get the healthcenter grouping.
         *
         * @param {number} healthcenterId - The healthcenter id.
         *
         * @returns {Promise<DeprecatedGrouping>}
         */
        healthcenterGrouping(healthcenterId: number): Promise<DeprecatedGrouping> {
            const params = {
                include: "branding.logo"
            };

            return client.get(`healthcenters/${healthcenterId}/branding`, {params})
                .then((response: AxiosResponse<HealthcenterGroupingApiResponse | null>) => response.data?.grouping);
        }
    };
};
