<template>
  <v-carousel
    :height="height"
    hide-delimiter-background
  >
    <template #prev="{props}">
      <button
        class="z-10"
        @click="props.onClick"
      >
        Précédent
      </button>
    </template>
    <template #next="{props}">
      <button class="z-10" @click="props.onClick">
        Suivant
      </button>
    </template>
    <v-carousel-item
      v-for="(image, index) in images"
      :key="image"
      :src="image"
      @click="$emit('item-click', index)"
    />
  </v-carousel>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';

export default defineComponent({
    name: "SupportProgramAdviceImagesCarousel",
    props: {
        images: {
            type: Array as PropType<string[]>,
            required: true
        },
        height: {
            type: String,
            default: '400'
        },
        color: {
            type: String,
            default: 'black'
        }
    },
    emits: ['item-click']
});
</script>

<style scoped>
:deep(.v-window__controls) {
    align-items: flex-end;
    color: v-bind(color);
    @apply text-sm pb-1 font-bold;
}

:deep(.v-window__controls button) {
    @apply hover:underline !cursor-pointer;
}

:deep(.v-carousel__controls) {
    height: 25px;
}

:deep(.v-carousel__controls__item) {
    color: v-bind(color);
}

:deep(.v-carousel__controls__item.v-btn.v-btn--icon) {
    width: 7px;
    height: 7px;
    border-radius: 100%;
    background-color: v-bind(color);
    opacity: 0.5;
}

:deep(.v-carousel-item img:hover) {
    cursor: pointer;
}

:deep(.v-carousel__controls__item.v-btn.v-btn--icon.v-btn--active) {
    opacity: 1;
}

:deep(.v-carousel__controls__item.v-btn.v-btn--icon:hover) {
    opacity: 1;
}

:deep(.v-btn__content .v-icon) {
    display: none; /* Removes the default icon */
}

:deep(.v-carousel__controls) {
    width: auto;
    left: 50% !important;
    transform: translateX(-50%);
}
</style>
