<template>
  <div
    class="flex flex-1 cursor-pointer bg-white"
    @click="redirect"
  >
    <div
      :class="item.data.type === 'order-created'
        ? 'flex bg-orange-light items-center rounded-l-2xl pl-3 pr-1'
        : 'flex bg-white items-center rounded-l-2xl pl-3 pr-1'"
      :style="{ height: containerHeight + 'px'}"
    >
      <div class="mr-3 flex-shrink-0">
        <img
          class="h-10 w-10"
          src="@/assets/images/logos/apodis.svg"
          alt="Logo Apodis"
        >
      </div>
      <div
        class="flex flex-col"
      >
        <div class="text-primary text-lg font-black break-all line-clamp-1">
          {{ item.data.title }}
        </div>
        <div class="text-sm font-bold text-gray-800 break-words line-clamp-2">
          {{ item.data.description }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from "vue";
import {LiveItemType} from "@/core/interfaces/live/LiveItem";
import {UserSettings} from "@/settings/interfaces";

export const BROADCAST_NOTIFICATION_HEIGHT = 100;

export default defineComponent({
    name: 'NotificationBroadcast',
    props: {
        item: {
            type: Object as PropType<{
                data: any,
                settings: UserSettings
            }>,
            required: true
        }
    },

    data () {
        return {
            containerHeight: BROADCAST_NOTIFICATION_HEIGHT,
            timeout: null as null | NodeJS.Timeout
        };
    },

    watch: {
        item: {
            immediate: true,
            handler() {
                if(!this.item.settings.notification.time) {
                    return;
                }

                if(this.timeout) {
                    clearTimeout(this.timeout);
                }

                this.timeout = setTimeout(() => {
                    window.ipcRenderer.send('hide-notification');
                }, this.item.settings.notification.time);
            }
        }
    },

    methods: {
        redirect () {
            if (this.item.data.type && this.item.data.type === LiveItemType.ORDER_CREATED) {
                window.ipcRenderer.send(
                    'navigate',
                    `/notification/history?orderId=${this.item.data.resource_id}`,
                    true
                );
            } else {
                window.ipcRenderer.send(
                    'navigate',
                    '/notification/history',
                    true
                );
            }
        }
    }
});
</script>
