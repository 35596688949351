<template>
  <div
    :class="[ inlineStyle === true
      ? 'bg-white cursor-pointer rounded-full p-2 px-4 border border-gray-300 shadow-md mr-5 hover:text-gray-500 hover:bg-gray-100 hover:border-gray-100'
      : 'bg-white cursor-pointer rounded-md p-5 border border-gray-300 shadow-md mr-5 hover:text-gray-500 hover:bg-gray-100 hover:border-gray-100'
    ]"
  >
    <slot />
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";

export default defineComponent({
    name: 'SurveyAnswerButton',
    props: {
        inlineStyle: {
            type: Boolean,
            default: false
        }
    }
});
</script>
