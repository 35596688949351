<script setup lang="ts">
import {SubscriptionPlan} from "@/subscription-plan/interfaces";
import Logo from "@/core/components/logo/BaseLogo.vue";

defineProps<{
    plan: SubscriptionPlan
}>();
</script>

<template>
  <Logo :alt="plan.name" :logo="plan.logo" />
</template>
