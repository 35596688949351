<template>
  <base-container>
    <header-back-button title="Parrainage" />
    <div class="flex flex-col p-6 gap-6">
      <div class="flex justify-between items-center gap-3">
        <h1 class="text-2xl text-primary">
          Gagne 1 mois d'utilisation gratuite !
        </h1>
        <div class="flex flex-col items-center">
          <span class="text-sm">Code parrain</span>
          <div
            class="bg-white text-primary px-4 py-1 border-black"
            style="border-width: 1px;"
          >
            <p>{{ couponCode }}</p>
          </div>
        </div>
      </div>
      <div class="card">
        <h1>1. Partage ton code parrain</h1>
        <div class="flex flex-col md:flex-row justify-between items-center gap-2">
          <p>Partage ton code <strong>{{ couponCode }}</strong> à une pharmacie</p>
          <form
            class="flex gap-2 items-end"
            @submit.prevent="sendEmail"
          >
            <input
              v-model="email"
              type="email"
              class="bg-gray-200 rounded-t-md"
              required
              size="30"
              placeholder="email"
            >
            <button
              type="submit"
              class="btn btn-primary w-full text-primary"
              :disabled="loading"
            >
              Envoyer une invitation
            </button>
          </form>
        </div>
      </div>
      <div class="card">
        <h1>2. Ton filleul gagne 1 mois gratuit... et toi aussi 🥳</h1>
        <p>
          Lorsque ton filleul utilise ton code parrain en activant un service Apodis Premium, son premier mois est
          offert et tu remportes le montant équivalent en Apodis Coins.
        </p>
      </div>
      <div class="card">
        <h1>3. Dépense tes Apodis Coins dans le store !</h1>
        <div class="flex flex-col md:flex-row items-center justify-between gap-6">
          <p>
            Il ne te reste plus qu'à aller dans le menu Store sur l'application Apodis Pro pour dépenser tes Apodis
            Coins
            et obtenir des mois d'utilisation gratuits !
          </p>
          <img
            src="@/assets/images/apodis-coin.png"
            alt="apodis-coin"
            class="w-24 h-24"
          >
        </div>
      </div>
      <p class="text-center font-content-bold text-primary text-xl">
        Facile, non ? 😉
      </p>
    </div>
  </base-container>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import BaseContainer from "@/core/components/base/BaseContainer.vue";
import HeaderBackButton from "@/core/components/header-back-button/HeaderBackButton.vue";
import SponsorshipService from "@/sponsorship/services/sponsorshipService";
import {sponsorshipApi} from "@/container";
import {useToastStore} from "@/stores/toast.store";

export default defineComponent({
    name: "SponsorshipView",
    components: {HeaderBackButton, BaseContainer},
    data() {
        return {
            email: '',
            loading: false
        };
    },
    computed: {
        couponCode(): string {
            return SponsorshipService.couponCode();
        }
    },
    methods: {
        sendEmail() {
            if(this.loading) {
                return;
            }

            this.loading = true;
            sponsorshipApi()
                .invite(this.email)
                .then(() => {
                    const toastStore = useToastStore();
                    this.email = '';

                    toastStore.showSuccess({
                        content: 'Invitation envoyée avec succès'
                    });
                })
                .finally(() => {
                    this.loading = false;
                });
        }
    }
});
</script>

<style scoped>
.card {
  @apply bg-white rounded-lg p-4
}

.card h1 {
  @apply text-xl font-content-bold
}
</style>
