<script setup lang="ts">
import BaseContainer from "@/core/components/base/BaseContainer.vue";
import ExtensionGrid from "@/program/components/program-grid/ExtensionGrid.vue";
import {useExtensionStore} from "@/stores/extension.store";
import {useRouter} from "vue-router";
import {computed} from "vue";
import SubscriptionPlanExtensionGrid from "@/home/components/SubscriptionPlanExtensionGrid.vue";
import {useSubscriptionPlanStore} from "@/stores/subscription-plan.store";
import {Extension, ExtensionType} from "@/core/interfaces/extension/Extension";
import ProgramExtensionGrid from "@/home/components/ProgramExtensionGrid.vue";
import {storeToRefs} from "pinia";

const extensionStore = useExtensionStore();
const {extensions} = storeToRefs(extensionStore);
const {subscriptionPlans} = useSubscriptionPlanStore();
const router = useRouter();

/**
 * The partner extensions.
 */
const partnerExtensions = computed(() => {
    return extensions.value?.filter(extension => {
        return extension.is_partner;
    }) ?? [];
});

/**
 * The loyalty card extensions.
 */
const loyaltyCardExtensions = computed(() => {
    return extensions.value?.filter(extension => {
        return extension.extendable_type === ExtensionType.PROGRAM_BUNDLE && extension.sub_type === 'loyalty-card';
    }) ?? [];
});

/**
 * The hospital extensions.
 */
const hospitalExtensions = computed(() => {
    return extensions.value?.filter((extension) => {
        return extension.organization.entity_type === 'hospital';
    }) ?? [];
});

/**
 * Get the program extensions without a subscription plan and not displayed in other sections.
 */
const orphanProgramExtensions = computed(() => {
    return extensionStore.programExtensions.filter(extension => {
        const extensionNotToDisplays = [
            ...partnerExtensions.value,
            ...loyaltyCardExtensions.value,
            ...hospitalExtensions.value
        ].map(extension => extension.id);

        return !extension.subscription_plan && !extensionNotToDisplays.includes(extension.id);
    }) ?? [];
});

/**
 * Navigate to the extension details.
 */
const navigateToExtensionDetails = (extension: Extension) => {
    router.push({
        name: 'extension-details',
        params: {
            extensionId: extension.id
        }
    });
};
</script>

<template>
  <BaseContainer class="p-6 space-y-3">
    <div>
      <h1>Découvrez les modules disponibles</h1>
      <label>Et boostez votre expérience Apodis !</label>
    </div>
    <div class="space-y-4">
      <ExtensionGrid
        title="Partenaires"
        :extensions="partnerExtensions"
        @extension-click="(extension) => navigateToExtensionDetails(extension)"
      />
      <div v-for="plan in subscriptionPlans" :key="plan.id">
        <SubscriptionPlanExtensionGrid
          :plan="plan"
          :enabled="false"
          @extension-click="(extension) => navigateToExtensionDetails(extension)"
        />
      </div>
      <ExtensionGrid
        title="Carte de fidélité"
        :extensions="loyaltyCardExtensions"
        @extension-click="(extension) => navigateToExtensionDetails(extension)"
      />
      <ExtensionGrid
        title="Lien ville-hôpital"
        :extensions="hospitalExtensions"
        @extension-click="(extension) => navigateToExtensionDetails(extension)"
      />
      <ProgramExtensionGrid
        :program-extensions="orphanProgramExtensions"
        @extension-click="(extension) => navigateToExtensionDetails(extension)"
      />
    </div>
  </BaseContainer>
</template>

<style scoped>

</style>
