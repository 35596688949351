<template>
  <base-container class="p-6">
    <div v-if="loading">
      <base-flex-spinner />
    </div>
    <div v-else>
      <div v-if="customer">
        <interview-grid
          :interviews="interviews ?? []"
          :consumer-key="customer.consumer_key"
          :consumer-name="customer.information.name"
        />
        <customer-interview-grid
          class="mt-3"
          :customer="customer"
          :instances="consumerInstances ?? []"
        />
      </div>
    </div>
  </base-container>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import BaseContainer from '@/core/components/base/BaseContainer.vue';
import InterviewGrid from '@/interview/components/InterviewGrid.vue';
import CustomerInterviewGrid from '@/interview/components/CustomerInterviewGrid.vue';
import {interviewApi} from '@/container';
import {mapState} from "pinia";
import {usePatientStore} from "@/stores/patient.store";
import BaseFlexSpinner from "@/core/components/base/spinner/BaseFlexSpinner.vue";

export default defineComponent({
    name: 'CustomerInterviews',
    components: {BaseFlexSpinner, CustomerInterviewGrid, InterviewGrid, BaseContainer},
    data() {
        return {
            loading: false,
            interviews: null as any[] | null,
            consumerInstances: null as any[] | null
        };
    },
    computed: {
        ...mapState(usePatientStore, ['customer'])
    },
    mounted() {
        this.loading = true;

        Promise.all([
            this.loadInterviews(),
            this.loadInstances()
        ])
            .finally(() => {
                this.loading = false;
            });
    },
    methods: {
    /**
     * Load the interviews.
     *
     * @returns {Promise<void>}
     */
        loadInterviews() {
            return interviewApi()
                .index()
                .then(interviews => {
                    this.interviews = interviews;
                });
        },
        /**
     * Load the interview instances.
     *
     * @returns {Promise<void>}
     */
        loadInstances() {
            if (this.customer) {
                return interviewApi()
                    .indexConsumerInstances(this.customer.consumer_key)
                    .then(consumerInstances => {
                        this.consumerInstances = consumerInstances;
                    });
            }
        }
    }
});
</script>
