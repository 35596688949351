<template>
  <div>
    <BaseRadio
      v-model="localeValue"
      :value="value"
      :label="label"
    />
    <div class="ml-7 mt-1 h-16 w-28 bg-gray-300 rounded-md relative">
      <div
        class="rounded-md w-5 h-2 bg-gray-50 absolute"
        :style="positionStyleObject"
      />
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import BaseRadio from '@/core/components/base/BaseRadio.vue';

export default defineComponent({
    name: 'NotificationLocationRadioButton',
    components: {BaseRadio},
    props: {
        value: {
            type: String,
            required: true
        },
        label: {
            type: String,
            required: true
        },
        modelValue: {
            type: null,
            required: true
        }
    },
    emits: ['update:modelValue'],
    computed: {
        positionStyleObject() {
            return {
                bottom: this.bottomPosition,
                right: '5px'
            };
        },
        bottomPosition() {
            switch (this.value) {
            case 'bottom-edge':
                return '10%';
            case 'bottom-right':
                return '20%';
            case 'middle-right':
                return '40%';
            case 'top-right':
                return '60%';
            case 'top-edge':
                return '80%';
            default:
                return '10%';
            }
        },
        localeValue: {
            get() {
                return this.modelValue;
            },
            set(value: any) {
                this.$emit('update:modelValue', value);
            }
        }
    }
});
</script>
