<template>
  <glowing-container>
    <interview-container class="bg-white z-20 h-full border-none w-full">
      <slot />
    </interview-container>
  </glowing-container>
</template>

<script lang="ts">
import InterviewContainer from '@/interview/components/instance-modal/InterviewContainer.vue';
import { defineComponent } from 'vue';
import GlowingContainer from '@/core/components/glowing-container/GlowingContainer.vue';

export default defineComponent({
    components: { GlowingContainer, InterviewContainer }
});

</script>
