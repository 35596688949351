<template>
  <BaseContainer class="relative">
    <div v-if="error">
      <p class="p-5">
        Nous avons rencontré un problème lors du chargement des informations du patient.
      </p>
    </div>
    <div v-if="!loading && !error && customer">
      <CustomerHeader :customer="customer" />
      <CustomerInformationModal
        v-if="showInformation"
        :customer="customer"
      />
      <div class="mt-1">
        <div class="border-b border-gray-200">
          <nav
            class="flex space-x-2 overflow-auto"
            aria-label="Tabs"
          >
            <router-link
              :to="{ name: 'customer.home' }"
              active-class="nav-item-active"
              class="nav-item"
            >
              Fiche patient
            </router-link>
            <router-link
              :to="{ name: 'customer.prescriptions' }"
              active-class="nav-item-active"
              class="nav-item"
            >
              Ordonnances
            </router-link>
            <router-link
              :to="{ name: 'customer.loyalty' }"
              active-class="nav-item-active"
              class="nav-item"
            >
              Cartes de fidélité
            </router-link>

            <router-link
              :to="{ name: 'customer.survey.list' }"
              active-class="nav-item-active"
              class="nav-item"
            >
              Questionnaires
            </router-link>
            <router-link
              :to="{ name: 'customer.hospital-file.list' }"
              active-class="nav-item-active"
              class="nav-item"
            >
              Documents hospitaliers
            </router-link>
            <router-link
              :to="{ name: 'customer.click-collect' }"
              active-class="nav-item-active"
              class="nav-item"
            >
              Click & collect
            </router-link>
            <template v-if="experimentalFeatures">
              <router-link
                :to="{name: 'customer.interviews'}"
                active-class="nav-item-active"
                class="nav-item"
              >
                Entretiens
              </router-link>
            </template>
          </nav>
        </div>
      </div>
      <router-view
        :key="$route.path"
        :customer="customer"
      />
    </div>
    <div
      v-else
      class="flex flex-1 flex-row justify-center"
    >
      <BaseSpinner />
    </div>
  </BaseContainer>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import BaseContainer from '@/core/components/base/BaseContainer.vue';
import CustomerHeader from '@/customer/components/customer-header/CustomerHeader.vue';
import BaseSpinner from '@/core/components/base/spinner/BaseSpinner.vue';
import CustomerInformationModal from '@/customer/components/customer-information/CustomerInformationModal.vue';
import {usePatientStore} from '@/stores/patient.store';
import {mapActions, mapState} from 'pinia';

export default defineComponent({
    name: 'CustomerView',
    components: {
        CustomerInformationModal,
        BaseSpinner,
        CustomerHeader,
        BaseContainer
    },
    data() {
        return {
            showInformation: false,
            error: false,
            experimentalFeatures: false
        };
    },
    computed: {
        ...mapState(usePatientStore, ['customer', 'loading']),
    },
    watch: {
        '$route.params.customerId': {
            immediate: true,

            handler() {
                const customerId = Number(this.$route.params.customerId);
                this.load(customerId).catch(() => {
                    this.error = true;
                });
            }
        }
    },

    created() {
        window.addEventListener('keydown', this.handleKeypress);
        window.addEventListener('insured-clicked', this.toggleInformationModal);
        window.addEventListener('information-closed', this.toggleInformationModal);
        window.addEventListener('refresh-customer', this.refresh);
    },
    unmounted() {
        window.removeEventListener('keydown', this.handleKeypress);
        window.removeEventListener('insured-clicked', this.toggleInformationModal);
        window.removeEventListener('information-closed', this.toggleInformationModal);
        window.removeEventListener('refresh-customer', this.refresh);
    },
    methods: {
        ...mapActions(usePatientStore, ['load']),
        /**
         * Refresh the customer data.
         *
         * @return {Promise<unknown>}
         */
        async refresh(): Promise<void> {
            try {
                return await this.load(this.customer!.id, true);
            } catch (e) {
                this.error = true;
            }
        },
        toggleInformationModal() {
            this.showInformation = !this.showInformation;
        },
        handleKeypress(event: KeyboardEvent) {
            if (event.key === "d" && event.ctrlKey) {  // CTRL + d
                window.ipcRenderer.send('emulate-detection', JSON.parse(JSON.stringify(this.customer)));
            } else if (event.key === "x" && event.ctrlKey) { // CTRL + x
                this.experimentalFeatures = !this.experimentalFeatures;
            }
        },
    }
});
</script>

<style scoped>
.nav-item {
    @apply border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap py-2 px-3 border-b-2 font-medium
}

.nav-item-active {
    @apply border-primary text-primary
}
</style>
