<template>
  <base-container>
    <header-back-button title="Création d'un groupe" />
    <div class="p-6">
      <p>
        Saisissez un nom pour votre groupe
      </p>
      <create-healthcenter-group-form
        class="mt-2"
        :loading="loading"
        @submit="handleSubmit"
      />
    </div>
  </base-container>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import BaseContainer from "@/core/components/base/BaseContainer.vue";
import HeaderBackButton from "@/core/components/header-back-button/HeaderBackButton.vue";
import CreateHealthcenterGroupForm
    from "@/disruption-logistics/components/healthcenter-groups/CreateHealthcenterGroupForm.vue";
import {disruptionLogisticsApi} from "@/container";
import {useToastStore} from "@/stores/toast.store";

export default defineComponent({
    name: "CreateHealthcenterGroupView",
    components: {CreateHealthcenterGroupForm, HeaderBackButton, BaseContainer},
    data() {
        return {
            loading: false
        };
    },
    methods: {
    /**
     * On submit, create the healthcenter group, and redirect to the group details page.
     */
        handleSubmit(data: { name: string; description: string }) {
            this.loading = true;
            disruptionLogisticsApi()
                .createHealthcenterGroup(data)
                .then((data) => {
                    const toast = useToastStore();
                    toast.showSuccess({content: 'Le groupe a été créé avec succès'});
                    this.$router.replace({
                        name: 'disruption-logistics.healthcenter-group',
                        params: {healthcenterGroupId: data.id.toString()}
                    });
                })
                .finally(() => {
                    this.loading = false;
                });
        }
    }
});
</script>

<style scoped>

</style>
