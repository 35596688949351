<template>
  <div>
    <information-panel>
      <div class="flex flex-row justify-space-between">
        <div class="flex flex-col">
          <h2 class="text-white">
            Simplifiez la gestion des renouvellements d’ordonnance
          </h2>
          <p class="text-white">
            En remplissant un plan de renouvellement, le patient reçoit une notification lorsqu'il peut renouveler son
            traitement, et
            lorsque l'ordonnance arrive à échéance
          </p>
          <div class="flex flex-row align-items pt-2">
            <icon
              name="mdi-information"
              class="mr-2"
              color="white"
            />
            <p class="text-sm text-white">
              En cas de stock insuffisant pour un traitement, vous recevez également une notification.
            </p>
          </div>
        </div>
        <div class="relative w-28 ml-6 hidden lg:block">
          <img
            alt="illustration"
            :src="pharmacistRenewal"
            class="w-32 object-contain hidden sm:block"
          >
        </div>
      </div>
    </information-panel>
    <div class="flex flex-col gap-y-2 mb-8">
      <div class="flex flex-row justify-between">
        <h1 class="ml-6 text-2xl">
          Ordonnances à renouveler
        </h1>
        <div class="flex flex-1 flex-row gap-x-2 mr-5 w-64 justify-end">
          <BaseCard v-if="meta?.renewal_plan_count" class="text-primary font-bold text-sm p-2">
            {{ meta?.renewal_plan_count }} Plans de renouvellement
          </BaseCard>
          <BaseSelect
            v-model:modelValue="planFilter"
            default-value="all"
            trigger-class="w-58"
            placeholder="Toutes les ordonnances"
            :options="[
              {value: 'all', label: 'Toutes les ordonnances'},
              {value: 'to-renew', label: 'Ordonnances à renouveler'},
              {value: 'without-plan', label: 'Ordonnances sans plan'},
              {value: 'with-plan', label: 'Ordonnances avec plan'},
              {value: 'non-renewable', label: 'Ordonnances non renouvelables'},
            ]"
          />
        </div>
      </div>
      <PrescriptionTagSelector v-if="meta" v-model="selectedTags" :tags="meta.tags" />
      <base-flex-spinner v-if="isLoading" />
      <div v-else>
        <prescription-list :prescriptions="prescriptions" />
        <div class="flex justify-center">
          <base-button
            v-if="!!meta?.after_key && !endReached"
            class="font-medium"
            :disabled="isLoadingMore"
            @click="loadMore"
          >
            <base-spinner v-if="isLoadingMore" size="button" />
            <span v-else>Voir plus</span>
          </base-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import {prescriptionApi} from '@/container';
import PrescriptionList from '@/prescription/components/PrescriptionList.vue';
import InformationPanel from '@/core/components/information-panel/InformationPanel.vue';
import Icon from '@/core/components/icon/Icon.vue';
import pharmacistRenewal from '@/assets/images/pharmacist-renewal.png';
import {Prescription, PrescriptionIndexParams, Tag} from "@/prescription/interfaces/Prescription";
import BaseFlexSpinner from "@/core/components/base/spinner/BaseFlexSpinner.vue";
import BaseSpinner from "@/core/components/base/spinner/BaseSpinner.vue";
import BaseButton from "@/core/components/base/BaseButton.vue";
import PrescriptionTagSelector from "@/prescription/components/PrescriptionTagSelector.vue";
import BaseSelect from "@/core/components/base/BaseSelect.vue";
import BaseCard from "@/core/components/base/BaseCard.vue";

interface Filters {
    tags: string[];
    certified: boolean;
    children: boolean;
}

export default defineComponent({
    name: 'PrescriptionRenewalScreen',
    components: {
        BaseCard,
        BaseSelect,
        PrescriptionTagSelector,
        BaseButton, BaseSpinner, Icon, InformationPanel, PrescriptionList, BaseFlexSpinner
    },
    data: () => {
        return {
            page: 1,
            isLoading: false,
            selectedTags: {tags: [], certified: false, children: false} as Filters,
            meta: null as { after_key: string | null, total: number, tags: Tag[], renewal_plan_count?: number } | null,
            prescriptions: [] as Prescription[],
            pharmacistRenewal,
            isLoadingMore: false,
            endReached: false,
            planFilter: 'all' as 'without-plan' | 'with-plan' | 'non-renewable' | 'all' | 'to-renew',
            age_from: null as number | null,
            age_to: null as number | null
        };
    },
    watch: {
        selectedTags: {
            handler() {
                this.refresh();
            },
            deep: true
        },
        planFilter: {
            handler() {
                this.refresh();
            },
            deep: true
        }
    },
    mounted() {
        this.refresh();
    },
    methods: {
        refresh() {
            this.isLoading = true;
            this.prescriptions = [];
            this.isLoadingMore = false;
            this.endReached = false;
            if (this.meta) {
                this.meta.after_key = null;
            }
            this.fetch().finally(() => this.isLoading = false);
        },
        async fetch() {
            const params: PrescriptionIndexParams = {
                with_renewal_plan_count: true,
                limit: 20,
                tags: this.selectedTags.tags,
                tags_operator: 'AND',
                after: this.meta?.after_key ? JSON.stringify(this.meta.after_key) : '',
                ...this.selectedTags.children && {age_to: '18 years'},
                ...this.selectedTags.certified && {certified: true}
            };

            switch (this.planFilter) {
            case 'with-plan':
                params.with_renewal_plan = true;
                break;
            case 'without-plan':
                params.with_renewal_plan = false;
                break;
            case 'non-renewable':
                params.renewable = false;
                break;
            case 'to-renew':
                params.renewable = true;
                params.with_renewal_plan = false;
                break;
            }

            return await prescriptionApi()
                .index(params)
                .then(response => {
                    this.prescriptions = [...this.prescriptions, ...response.data];
                    this.endReached = this.meta?.after_key === response.meta.after_key;
                    this.meta = response.meta;
                });
        },
        loadMore() {
            this.isLoadingMore = true;
            this.fetch().finally(() => this.isLoadingMore = false);
        }
    }
});
</script>
