<template>
  <div>
    <ul class="leading-6">
      <CustomerCertificationStatusRow
        v-if="customer.certification?.phone"
        :label="`Mobile certifié : ${phone}`"
      />
      <CustomerCertificationStatusRow
        v-if="customer.certification_notifications.length > 0"
        :label="`SMS envoyé le
        ${formatHelper.readableDate(customer.certification_notifications[0].created_at)}`"
      />
      <CustomerCertificationStatusRow
        v-if="!customer.certification?.phone"
        :label="`Patient certifié via Qr-code`"
      />
      <CustomerCertificationStatusRow
        v-if="!customer.certification?.phone"
        :label="`Mobile associé : ${phone}`"
      />
      <CustomerCertificationStatusRow
        v-if="customer.certification?.validated_at"
        :label="`Application installée`"
      />
      <CustomerCertificationStatusRow
        v-else
        :label="`Application non-installée`"
        icon="android-phone-slash"
        icon-color="#CCCCCC"
      />
    </ul>
    <CustomerCertificationStatusActions :customer="customer" />
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from "vue";
import formatHelper from '@/core/helpers/formatHelper';
import CustomerCertificationStatusRow
    from '@/customer-certification/components/CustomerCertificationStatusRow.vue';
import CustomerCertificationStatusActions
    from '@/customer-certification/components/CustomerCertificationStatusActions.vue';
import Customer from "@/customer/interfaces/Customer";

export default defineComponent({
    components: { CustomerCertificationStatusActions, CustomerCertificationStatusRow },
    props: {
        customer: {
            type: Object as PropType<Customer>,
            required: true
        }
    },
    computed: {
        formatHelper () {
            return formatHelper;
        },
        phone () {
            return formatHelper
                .readablePhone(this.customer.certification?.phone || this.customer.information?.phone);
        }
    }
});
</script>
