<template>
  <div class="p-5 bg-white rounded-md flex justify-between gap-10 m-4">
    <div class="flex flex-1 flex-col">
      <h2 class="text-black">
        Objectif
      </h2>
      <markdown :content="description" />
    </div>
    <div>
      <span
        v-if="duration"
        class="text-sm"
      >Durée : {{ duration }}min</span>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import Markdown from "@/core/components/markdown/Markdown.vue";

export default defineComponent({
    name: 'InterviewDescription',
    components: {Markdown},
    props: {
        /** The interview description */
        description: {
            type: String,
            required: true
        },
        /** The interview duration */
        duration: {
            type: Number,
            required: false,
            default: null
        }
    }
});
</script>
