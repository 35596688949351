<script setup lang="ts">
import {useInterviewStore} from "@/stores/interview-store";
import InterviewDescription from "@/interview/components/instance-modal/InterviewDescription.vue";
import {storeToRefs} from "pinia";
import InterviewRecording from "@/interview/components/instance-modal/InterviewRecording.vue";
import {useRoute, useRouter} from "vue-router";
import InterviewGuidelines from "@/interview/components/instance-modal/InterviewGuidelines.vue";

const interviewStore = useInterviewStore();
const {interview} = storeToRefs(interviewStore);
const router = useRouter();
const route = useRoute();

/**
 * Create a new interview instance.
 */
const startInstance = async () => {
    await interviewStore.startInterviewInstance();
};

/**
 * Skip the record, create the instance and navigate to next page.
 */
const recordSkipped = async () => {
    if (!interview.value) {
        return;
    }

    await startInstance();
    navigateToSurvey();
};

const navigateToSurvey = () => {
    router.replace({
        name: 'interview-instances.survey',
        query: {...route.query}
    });
};

/**
 * Navigate to the transcript loading page.
 */
const navigateToTranscriptLoading = () => {
    router.replace({
        name: 'interview-instances.transcript-loading',
        query: {...route.query}
    });
};

/**
 * When record is finished, set the audio to the store and navigate to the next page.
 */
const recordFinished = (audio: Blob) => {
    interviewStore.audio = audio;

    navigateToTranscriptLoading();
};

/**
 * Set the uploaded audio file to the store and start the instance.
 *
 * @param file
 */
const handleImport = async (file: Blob) => {
    interviewStore.audio = file;
    await startInstance();
    navigateToTranscriptLoading();
};
</script>

<template>
  <div v-if="!interview">
    <p>Oups...</p>
  </div>
  <div v-else class="flex flex-col w-full gap-6">
    <InterviewDescription
      :description="interview.description"
      :duration="interview.duration"
    />
    <InterviewRecording
      @start="startInstance"
      @skip="recordSkipped"
      @finish="recordFinished"
      @finish-upload="handleImport"
    />
    <div class="flex gap-x-6">
      <div class="flex-1 self-start">
        <InterviewGuidelines
          :guidelines="interview.guidelines"
        />
        <p class="flex flex-1 py-4 text-primary justify-center text-center">
          💡N’oubliez pas de réaliser une analyse pharmaceutique afin de détecter d’éventuelles interactions
          médicamenteuses
        </p>
      </div>
      <slot name="shortcuts" />
    </div>
  </div>
</template>

<style scoped>

</style>
