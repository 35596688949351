<template>
  <base-container class="p-6 flex flex-col gap-3">
    <h1 class="text-primary">
      Vérifiez la disponibilité d'un produit
    </h1>
    <product-search-bar />

    <div class="flex flex-col lg:flex-row gap-8">
      <healthcenter-warehouses-card class="flex-1" />
      <healthcenter-group-card class="flex-1" />
    </div>
    <div class="flex-1" />
    <div class="flex gap-3 justify-center">
      <div class="flex justify-center items-center max-w-2xl text-center">
        <div class="hidden lg:flex flex-col items-center text-center">
          <span class="text-3xl">
            😉
          </span>
          <p
            class="text-sm font-bold"
            style="transform: rotate(-10deg)"
          >
            Hey pssst!
          </p>
        </div>
        <p class="text-primary flex-1">
          <strong>Gagnez 1 mois d'utilisation gratuite</strong> pour chaque pharmacie parrainée avec
          votre code <span class="bg-white px-2">{{ sponsorshipCouponCode }}</span>{{ ' ' }}
          <router-link
            :to="{name: 'sponsorship'}"
            class="underline"
          >
            Comment ça marche ?
          </router-link>
        </p>
      </div>
    </div>
  </base-container>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import BaseContainer from "@/core/components/base/BaseContainer.vue";
import ProductSearchBar from "@/disruption-logistics/components/product-search-bar/ProductSearchBar.vue";
import HealthcenterWarehousesCard from "@/disruption-logistics/components/home/HealthcenterWarehousesCard.vue";
import HealthcenterGroupCard from "@/disruption-logistics/components/home/HealthcenterGroupCard.vue";
import {mapState} from "pinia";
import {useSystemStore} from "@/stores/system.store";

export default defineComponent({
    name: "DisruptionLogisticsHomeView",
    components: {HealthcenterGroupCard, HealthcenterWarehousesCard, ProductSearchBar, BaseContainer},
    computed: {
        ...mapState(useSystemStore, ['environment']),
        /**
     * Get the partnership coupon code
     */
        sponsorshipCouponCode(): string {
            return this.environment?.entity.cip ?? '';
        }
    }
});
</script>

<style scoped>

</style>
