<script setup lang="ts">
import {SubscriptionPlan} from "@/subscription-plan/interfaces";
import EmbedVideo from "@/core/components/video/EmbedVideo.vue";
import {ref} from "vue";
import BaseModal from "@/core/components/base/BaseModal.vue";
import videoThumbnail from "@/assets/images/thumbnail_video_default.png";

const {plan} = defineProps<{ plan: SubscriptionPlan }>();
const modalVisible = ref(false);

</script>

<template>
  <v-carousel show-arrows="hover" cycle :interval="4000" :height="100" hide-delimiters class="cursor-pointer" @click="modalVisible = true">
    <v-carousel-item v-if="plan.video_url" key="0" :src="videoThumbnail" class="cursor-pointer" />
    <div v-for="extension in plan.extensions" :key="extension.id">
      <div v-for="preview in extension.previews" :key="preview.id">
        <v-carousel-item
          v-if="preview?.url"
          :key="preview.id"
          :src="preview.url"
          cover
        />
      </div>
    </div>
  </v-carousel>
  <base-modal v-if="modalVisible" :title="plan.name" @close="modalVisible = false">
    <v-carousel :interval="4000" :height="500" hide-delimiters>
      <v-carousel-item v-if="plan.video_url" key="0">
        <embed-video class="w-full" :url="plan.video_url" />
      </v-carousel-item>
      <div v-for="extension in plan.extensions" :key="extension.id">
        <div v-for="preview in extension.previews" :key="preview.id">
          <v-carousel-item
            v-if="preview?.url"
            :key="preview.id"
            :src="preview.url"
            cover
          />
        </div>
      </div>
    </v-carousel>
  </base-modal>
</template>

<style scoped>
:deep(.v-btn--icon) {
    width: 25px;
    height: 25px;
}
</style>
