<template>
  <BaseCard
    class="mb-4 p-0 flex flex-grow items-center p-4"
    style="width: 240px"
  >
    <div class="flex items-center gap-5">
      <div
        class="rounded-full p-2 flex items-center w-14"
        :class="[
          bgColor === 'white' ? 'bg-tranparent' : '',
          bgColor === 'pink' ? 'bg-pink-200' : '',
          bgColor === 'blue' ? 'bg-blue-300' : '',
          bgColor === 'yellow' ? 'bg-yellow-300' : '',
          shadow ? 'shadow' : ''
        ]"
      >
        <StarSolid
          v-if="icon === 'star'"
          width="48"
          :class="[
            fillColor === 'yellow' ? 'icon-yellow' : '',
            fillColor === 'pink' ? 'fill-pink-200' : '',
            fillColor === 'blue' ? 'fill-blue-300' : '',
            fillColor === 'white' ? 'icon-white' : '',
          ]"
        />
        <UserSolid
          v-else-if="icon === 'user'"
          width="48"
          :class="[
            fillColor === 'yellow' ? 'icon-yellow' : '',
            fillColor === 'pink' ? 'icon-pink' : '',
            fillColor === 'blue' ? 'fill-blue-300' : '',
            fillColor === 'white' ? 'icon-white' : ''
          ]"
        />
        <ShopbagSolid
          v-else-if="icon === 'shopping-bag'"
          width="48"
          :class="[
            fillColor === 'yellow' ? 'icon-yellow' : '',
            fillColor === 'pink' ? 'fill-pink-200' : '',
            fillColor === 'blue' ? 'fill-blue-300' : '',
            fillColor === 'white' ? 'icon-white' : ''
          ]"
        />
      </div>
      <div class="flex flex-col">
        <div class="self-start">
          {{ label }}
        </div>
        <div class="self-start font-bold text-lg">
          {{ processedValue }}
        </div>
      </div>
    </div>
  </BaseCard>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import BaseCard from '@/core/components/base/BaseCard.vue';
import StarSolid from '@/assets/images/icons-vue/star-solid.vue';
import ShopbagSolid from '@/assets/images/icons-vue/shopbag-solid.vue';
import UserSolid from '@/assets/images/icons-vue/user-solid.vue';

export default defineComponent({
    components: {
        BaseCard,
        StarSolid,
        UserSolid,
        ShopbagSolid
    },
    props: {
        label: {
            type: String,
            required: true
        },
        value: {
            type: undefined,
            required: true
        },
        cardType: {
            type: String,
            required: true
        },
        icon: {
            type: String,
            required: true
        },
        fillColor: {
            type: String,
            default: 'white'
        },
        bgColor: {
            type: String,
            default: 'blue'
        },
        shadow: {
            type: Boolean,
            default: false
        },
        fractionDigit: {
            type: Number,
            default: 0
        }
    },
    computed: {
        processedValue () {
            switch (this.cardType) {
            case 'points': {
                return Math.ceil(Number(this.value));
            }
            case 'currency': {
                return `${Number(this.value).toFixed(2)} €`;
            }
            case 'number': {
                return Number(this.value).toFixed(this.fractionDigit);
            }
            }
            return this.value;
        }
    }
});
</script>

<style>
.icon-yellow {
  fill: #fde047;
}
.icon-white {
  fill: #fff;
}

.icon-pink {
  fill: #fbcfe8;
}
</style>
