import {Appointment} from "@/core/interfaces/Appointment";

interface FindQuickAppointmentParams {
    customer_id: number;
    appointable_id: number;
    appointable_type: string;
    beneficiary_id?: number;
}

interface UpdateQuickAppointmentParams {
    customer_id: number;
    appointable_id: number;
    appointable_type: string;
    beneficiary_id?: number;
    start_at: string;
    duration: number;
}

interface CreateQuickAppointmentParams {
    customer_id: number;
    appointable_id: number;
    appointable_type: string;
    beneficiary_id?: number;
    operator_id?: number;
    start_at: string;
    duration: number;
    title: string;
    description: string;
}

export default function (hdsClient) {
    return {
        /**
         * Find quick appointment of a consumer.
         *
         * @returns {Promise<Object>}
         */
        find (organizationId: number, params: FindQuickAppointmentParams): Promise<Appointment> {
            return hdsClient.get(`/organizations/${organizationId}/quick-appointments`, { params })
                .then(response => response.data.data);
        },

        /**
         * Create a quick appointment of a consumer.
         *
         * @returns {Promise<Object>}
         */
        create (organizationId: number, data: CreateQuickAppointmentParams): Promise<Appointment> {
            return hdsClient.post(`/organizations/${organizationId}/quick-appointments`, { data })
                .then(response => response.data.data);
        },

        /**
         * Update a quick appointment of a consumer.
         *
         * @returns {Promise<Object>}
         */
        update (appointmentId: number, data: UpdateQuickAppointmentParams): Promise<Appointment> {
            return hdsClient.patch(`quick-appointments/${appointmentId}`, { data })
                .then(response => response.data.data);
        }
    };
};
