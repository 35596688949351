<template>
  <div class="flex flex-1 items-center justify-center">
    <div v-if="!simple">
      <div class="bg-white shadow rounded-lg p-0.5 h-32 w-32 flex items-center">
        <BaseSpinner
          v-if="!qrCode"
          size="small"
        />
        <img
          v-else
          :src="qrCode"
          alt="qr-code"
          class="w-full p-2 cursor-pointer"
          @click="($refs.modal as any).open()"
        >
      </div>
    </div>
    <div
      v-else
      class="flex flex-1 justify-end"
    >
      <img
        alt="Certifier"
        width="50"
        :src="qrcodeIcon"
        class="cursor-pointer"
        @click="($refs.modal as any).open()"
      >
    </div>
    <CustomerCertificationQrCodeModal
      ref="modal"
      :qr-code="qrCode"
      :customer="customer"
    />
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import { customerCertificationApi } from '@/container';
import BaseSpinner from '@/core/components/base/spinner/BaseSpinner.vue';
import CustomerCertificationQrCodeModal from '@/customer-certification/components/CustomerCertificationQrCodeModal.vue';
import qrcodeIcon from '@/assets/icons/qrcode.svg';

export default defineComponent({
    components: {
        CustomerCertificationQrCodeModal,
        BaseSpinner
    },
    props: {
        customer: {
            type: Object,
            required: true
        },
        simple: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            qrCode: null as any,
            qrcodeIcon
        };
    },
    watch: {
        'customer.certification' () {
            this.load();
        }
    },
    mounted () {
        this.load();
    },
    methods: {
        load () {
            customerCertificationApi()
                .readQrCode(this.customer.id)
                .then(qrcode => {
                    const reader = new FileReader();

                    reader.readAsDataURL(qrcode);

                    reader.onload = () => {
                        this.qrCode = reader.result;
                    };
                });
        }
    }
});
</script>
