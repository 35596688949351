<template>
  <BaseCard>
    <BaseSpinner
      v-if="!advices"
      :size="'small'"
    />
    <BaseSlider
      v-if="!!advices && mergedAdvices && !!mergedAdvices.length"
      :title="'Recommandé pour ce patient'"
      :slides="{
        component: AdviceComponent,
        prop: 'advice',
        data: mergedAdvices
      }"
    />
  </BaseCard>
</template>

<script lang="ts">
import {defineComponent, PropType} from "vue";
import BaseCard from '@/core/components/base/BaseCard.vue';
import BaseSlider from '@/core/components/base/BaseSlider.vue';
import BaseSpinner from '@/core/components/base/spinner/BaseSpinner.vue';
import AdviceComponent from "@/program/components/support-program/SupportProgramAdvice.vue";
import type {ProductAdvice, SupportProgramAdvice} from "@/program/interfaces/Program";

export default defineComponent({
    components: { BaseSpinner, BaseSlider, BaseCard },
    props: {
        advices: {
            type: Array as PropType<SupportProgramAdvice[]>,
            default: () => null
        }
    },
    computed: {
        AdviceComponent() {
            return AdviceComponent;
        },
        mergedAdvices (): (ProductAdvice & {description: string})[] {
            const mergedAdvices: (ProductAdvice & {description: string})[] = [];

            if (this.advices) {
                for (const item of this.advices) {
                    for (const advice of item.advices) {
                        mergedAdvices.push({
                            ...advice,
                            description: item.description
                        });
                    }
                }
            }

            return mergedAdvices;
        }
    }
});
</script>
