<template>
  <div class="flex w-full items-center justify-center">
    <div
      v-if="step === 'authorizing'"
      class="text-white text-xl"
    >
      Authentification e-CPS...
    </div>
    <div
      v-else-if="step === 'loading'"
      class="text-white text-xl"
    >
      Récupération des infos e-CPS...
    </div>
    <div
      v-else-if="step === 'form'"
    >
      <RegisterForm
        :ecps="ecpsInfos"
        @validate="setUserInfos($event)"
      />
    </div>
    <div
      v-else-if="step === 'map'"
      class="flex h-screen w-full"
    >
      <RegisterMapView
        v-if="postalCode"
        :postal-code="postalCode"
        @validate="healthcenterSelected"
      />
    </div>
    <div
      v-else-if="step === 'success'"
      class="text-white text-xl"
    >
      <RegisterSuccess
        :user="user"
        :healthcenter="healthcenter"
        @login="login"
      />
    </div>
    <div v-else>
      <RegisterBefore />
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import {proSanteConnectApi, authenticationService, storageService, registrationService} from '@/container';
import RegisterBefore from '@/register/views/RegisterBefore.vue';
import RegisterForm from '@/register/views//RegisterForm.vue';
import RegisterMapView from '@/register/views//RegisterMapView.vue';
import RegisterSuccess from '@/register/views//RegisterSuccess.vue';
import {PSC_STATES} from '@/helpers/pscHelper';
import electronHelper from "@/core/services/electronHelper";

const PHARMACIST_CODE = '21';

export default defineComponent({
    name: 'RegisterView',

    components: {
        RegisterBefore,
        RegisterForm,
        RegisterMapView,
        RegisterSuccess
    },

    data() {
        return {
            step: undefined as undefined|string,
            ecpsInfos: null as any,
            user: null as any,
            healthcenter: null as any,
            postalCode: null as string | null
        };
    },

    watch: {
        $route: {
            immediate: true,
            handler() {
                this.handlePCSAuth();
            }
        }
    },

    methods: {
    /**
     * Handle action after pcs authentication.
     */
        handlePCSAuth() {
            if (this.$route.query && this.$route.query.code) {
                const redirect = import.meta.env.VITE_PROSANTECONNECT_CALLBACK_URL;
                this.step = 'authorizing';

                if (this.$route.query.state === 'chu_login') {
                    window.location.href = import.meta.env.VITE_CHU_PSC_CALLBACK_URL + '?code=' + this.$route.query.code;
                    return;
                } else if (this.$route.query.state === PSC_STATES.deciphera) {
                    window.location.href = import.meta.env.VITE_DECIPHERA_REGISTER_URL + '?code=' + this.$route.query.code;
                    return;
                }

                if (this.$route.query.state === PSC_STATES.linkcps) {
                    this.linkPSC(redirect);
                } else {
                    this.loginRegisterPSC(redirect);
                }
            }
        },

        setUserInfos(event) {
            this.user = event;
            this.postalCode = event.postalCode;
            this.step = 'map';
        },

        healthcenterSelected(healthcenter) {
            this.healthcenter = healthcenter;
            const healthceterAddress = healthcenter.geo.city.address1 + ' ' + healthcenter.geo.city.postal_code + ' ' + healthcenter.geo.city.name;

            const registerUser = {
                access: {
                    accessPointType: 2,
                    active: 1,
                    address: healthceterAddress,
                    entityId: healthcenter.id,
                    name: healthcenter.name,
                    rankId: 1,
                    cps: this.ecpsInfos && this.ecpsInfos.sub
                },
                email: this.user.email,
                firstName: this.user.firstname,
                gender: this.user.gender,
                lgo: this.user.lgo,
                name: this.user.lastname,
                parrainage: this.user.referral,
                pwd: this.user.password,
                groupmentId: this.user.groupingId,
                type: 'pharmacist'
            };
            registrationService()
                .register(registerUser)
                .then(() => {
                    this.step = 'success';
                });
        },

        login() {
            if (this.user) {
                authenticationService()
                    .login(this.user.email, this.user.password)
                    .then(() => {
                        this.start();
                    });
            }
        },

        start() {
            this.$router.push({
                name: electronHelper.isElectron() ? 'home' : 'onboarding'
            });

            if (window.ipcRenderer) {
                window.ipcRenderer.authenticated(storageService().loadSettings());
            }
        },
        /**
     * Function called to handle the link cps after going through the psc dedicated authentication window.
     *
     * @param redirect
     */
        linkPSC(redirect) {
            setTimeout(() => { // apparently PSC is not fast enough to handle code directly
                proSanteConnectApi()
                    .linkCPS(this.$route.query.code, redirect)
                    .then(response => {
                        if (response.token) {
                            storageService().store('token', response.token);
                            this.start();
                        } else {
                            this.$router.push({
                                name: 'login',
                                query: {unknownECPS: "true"}
                            });
                        }
                    })
                    .catch(() => {
                        this.$router.push({
                            name: 'login',
                            query: {unknownECPS: "true"}
                        });
                    });
            }, 800);
        },
        /**
     * Function called to handle login and register process after going through the psc dedicated authentication window.
     *
     * @param redirect
     */
        loginRegisterPSC(redirect) {
            setTimeout(() => { // apparently PSC is not fast enough to handle code directly
                this.step = 'loading';
                proSanteConnectApi()
                    .getToken(this.$route.query.code, redirect)
                    .then(response => {
                        if (response.access_token) {
                            proSanteConnectApi()
                                .authenticate(response.access_token)
                                .then(response => {
                                    storageService().store('token', response.token);
                                    this.start();
                                })
                                .catch(() => {
                                    proSanteConnectApi()
                                        .getUserInfo(response.access_token)
                                        .then(infos => {
                                            this.ecpsInfos = infos;
                                            if (this.ecpsInfos.SubjectRefPro.exercices[0].codeProfession !== PHARMACIST_CODE) {
                                                this.$router.push({
                                                    name: 'login',
                                                    query: {
                                                        noAccount: "true",
                                                        noPharmacist: "true"
                                                    }
                                                });
                                            }
                                        })
                                        .finally(() => {
                                            this.step = 'form';
                                        });
                                });
                        }
                    })
                    .catch(() => {
                        this.backToHome();
                    });
            }, 800);
        },
        backToHome() {
            storageService().destroy('token');
            this.$router.push({name: 'login'});
        }
    }
});
</script>
