<template>
  <div v-if="!warehouse" />
  <div
    v-else
  >
    <div class="flex flex-col gap-1 p-3">
      <p class="font-bold">
        {{ warehouse.name }}
      </p>
      <div
        v-if="warehouse.street_address_line1"
        class="flex flex-col text-gray-500"
      >
        <span>{{ warehouse.street_address_line1 }}</span>
        <span>{{ warehouse.postal_code }} {{ warehouse.city_name }}</span>
      </div>
      <div>
        <div class="flex gap-2 items-center">
          <div
            class="w-3 h-3 rounded-full"
            :class="{
              'bg-green-500': !warehouse.available !== null && warehouse.available === true,
              'bg-red-500': warehouse.available === false,
              'bg-gray-500': warehouse.available === null,
            }"
          />
          <span v-if="warehouse.available === null">
            Information non disponible
          </span>
          <span v-else>Produit {{ warehouse.available ? 'disponible !' : 'indisponible' }}</span>
        </div>
        <label
          v-if="warehouse.available === false && warehouse.message"
          class="pl-5"
        >{{ warehouse.message }}</label>
      </div>
    </div>
    <div>
      <div
        v-if="members.length && warehouse.available"
        class="flex flex-col gap-1.5 relative bg-gray-100 p-3"
      >
        <label class="text-primary">Les pharmacies suivantes peuvent commander :</label>
        <div
          v-for="healthcenter in members"
          :key="healthcenter.id"
          class="flex gap-1 items-start relative"
        >
          <healthcenter-group-icon
            :size="20"
            color="primary"
          />
          <div class="top-0.5 relative flex flex-col gap-0.5">
            {{ healthcenter.name }}{{ healthcenter.address?.postal_code || healthcenter.address?.city ? ',' : '' }}
            <span v-if="healthcenter.address?.postal_code"> {{ healthcenter.address.postal_code }}</span>
            <span v-if="healthcenter.address?.city_name"> {{ healthcenter.address.city_name }}</span>
            <span class="text-sm">
              {{
                getHealthcenterWarehouse(healthcenter)?.rank
                  ? `Grossiste n°${getHealthcenterWarehouse(healthcenter)?.rank}`
                  : 'Rank grossiste non communiqué'
              }}
            </span>
            <span
              v-for="contact in healthcenter.contacts"
              :key="healthcenter.id + '_' +contact.type_id"
              class="text-sm"
            >
              {{ contact.type.name }}: {{ contact.value }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">

import HealthcenterGroupIcon
    from '@/disruption-logistics/components/healthcenter-groups/HealthcenterGroupIcon.vue';
import {PropType, defineComponent} from "vue";
import {Warehouse} from "@/core/interfaces/warehouses";
import {HealthcenterGroupMember} from "@/disruption-logistics/interfaces/availability-providers";

export default defineComponent({
    name: 'DistributorAvailabilityInfoWindow',
    components: {HealthcenterGroupIcon},
    props: {
        content: {
            type: Object as PropType<{
        warehouse: Warehouse,
        available: boolean | null,
        message: string | null
      }>,
            required: false,
            default: null
        },
        members: {
            type: Array as PropType<HealthcenterGroupMember[]>,
            required: false,
            default: () => []
        }
    },
    computed: {
    /**
     * Get the warehouse merged with the product availability.
     *
     * @return {Object}
     */
        warehouse(): (Warehouse & { available: boolean | null, message: string | null }) | null {
            return this.content?.warehouse ? {
                ...this.content.warehouse,
                message: this.content.message,
                available: this.content.available
            } : null;
        }
    },
    methods: {
    /**
     * Get the healthcenter warehouse of a member.
     * @param member
     * @return {Object}
     */
        getHealthcenterWarehouse(member: HealthcenterGroupMember): {id: number; rank?: number} | undefined {
            return member.wholesaler_warehouses?.find(healthcenterWarehouse => healthcenterWarehouse.id === this.warehouse?.id);
        }
    }
});
</script>

<style scoped>

</style>
