<template>
  <div>
    <CustomerMedicineCabinet
      v-if="medicineCabinetProgram && customer"
      :program="medicineCabinetProgram"
      :customer="customer"
      class="flex"
    />
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import {programApi} from '@/container';
import CustomerMedicineCabinet from '@/customer/components/customer-medicine-cabinet/CustomerMedicineCabinet.vue';
import {Program} from "@/program/interfaces/Program";
import Customer from "@/customer/interfaces/Customer";
import {usePatientStore} from "@/stores/patient.store";
import {mapState} from "pinia";

export default defineComponent({
    name: 'CustomerFeatureProgram',
    components: {CustomerMedicineCabinet},
    data() {
        return {
            program: null as Program | null,
            customer: null as Customer | null
        };
    },
    computed: {
        ...mapState(usePatientStore, {storeCustomer: "customer"}),
        medicineCabinetProgram() {
            if (this.program && this.program.programmable_type === 'feature') {
                return this.program;
            }
            return null;
        }
    },
    watch: {
        '$route.params': {
            immediate: true,

            handler(params) {
                if (params.program) {
                    this.load(params);
                } else if (params.programId) {
                    programApi()
                        .read(params.programId)
                        .then((program) => this.load({
                            program,
                            customer: this.storeCustomer
                        }));
                }
            }
        }
    },
    methods: {
        load(params) {
            this.program = params.program;
            this.customer = params.customer;
        }
    }
});
</script>
