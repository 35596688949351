<template>
  <div
    class="h-screen overflow-hidden bg-primary-lightest flex flex-col"
    @click="click()"
    @mouseenter="windowHoverChange(true)"
    @mouseleave="windowHoverChange(false)"
  >
    <TheTopBar />
    <div class="min-h-0 flex-1 flex overflow-hidden">
      <TheSideBar />

      <main class="min-w-0 flex-1 lg:flex">
        <section
          aria-labelledby="primary-heading"
          class="min-w-0 flex-1 h-full flex flex-col overflow-auto lg:order-last"
        >
          <router-view />
        </section>
      </main>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";

import TheSideBar from '@/core/components/navigation-bar/TheSideBar.vue';
import TheTopBar from '@/core/components/navigation-bar/TheTopBar.vue';
import {mapActions} from "pinia";
import {useSystemStore} from "@/stores/system.store";

export default defineComponent({
    components: {
        TheTopBar,
        TheSideBar
    },
    mounted() {
        this.refresh();
    },
    methods: {
        ...mapActions(useSystemStore, ['refresh']),
        /**
         * Notify the main process that the window is hover state changed.
         */
        windowHoverChange(hovered: boolean) {
            window.ipcRenderer.windowHoveredChange("main", hovered);
        },
        /**
         * Notify the main process that the window is clicked.
         */
        click() {
            window.ipcRenderer.click();
        }
    }
});
</script>
