<script setup lang="ts">
import Offer, {OfferProduct} from "@/core/interfaces/Offer";
import BaseCard from "@/core/components/base/BaseCard.vue";
import Icon from "@/core/components/icon/Icon.vue";
import BaseModal from "@/core/components/base/BaseModal.vue";
import {ref, watch} from "vue";
import LaboratoryProduct from "@/core/interfaces/laboratory/LaboratoryProduct";
import {healthcenterProductApi, productApi} from "@/container";
import {useSystemStore} from "@/stores/system.store";
import BaseFlexSpinner from "@/core/components/base/spinner/BaseFlexSpinner.vue";
import ProductImage from "@/product/components/ProductImage.vue";
import BaseButton from "@/core/components/base/BaseButton.vue";
import {useToastStore} from "@/stores/toast.store";

defineProps<{ offer: Offer }>();

const {environment} = useSystemStore();
const {showInfo} = useToastStore();
const healthcenterId = environment?.entityId;
const visible = ref(false);
const visibleProduct = ref<OfferProduct>();
const productRef = ref<LaboratoryProduct>();
const isFeatured = ref<boolean>();
const loading = ref<boolean>(false);

watch(visibleProduct, async () => {
    if (visibleProduct.value && healthcenterId) {
        loading.value = true;
        productApi().getPresentation(visibleProduct.value.id)
            .then(response => {
                productRef.value = response;
                return healthcenterProductApi().read(healthcenterId);
            })
            .then(featuredProducts => {
                if (productRef.value) {
                    const productId = productRef.value.id;
                    isFeatured.value = featuredProducts.find(
                        (featuredProduct: LaboratoryProduct) => featuredProduct.id === productId
                    );
                }
            })
            .finally(() => loading.value = false);
    }
});

/**
 * Update featured product selection.
 */
const updateSelection = () => {
    if (healthcenterId) {
        if (productRef.value) {
            if (isFeatured.value) {
                healthcenterProductApi().delete(healthcenterId, productRef.value.id)
                    .then(() => {
                        isFeatured.value = false;
                        showInfo({
                            content: "Le produit a bien été supprimé de votre liste de produits mis en avant."
                        });
                    });
            } else {
                healthcenterProductApi().create(healthcenterId, productRef.value.id)
                    .then(() => {
                        isFeatured.value = true;
                        showInfo({
                            content: "Le produit a bien été ajouté à votre liste de produits mis en avant."
                        });
                    });
            }
        }
    }
};
</script>

<template>
  <div>
    <BaseCard class="my-4 p-4">
      <h2 class="pb-2">
        Produits
      </h2>
      <div
        v-for="product in offer.products"
        :key="product.id"
        class="flex flex-row items-center p-2 hover:opacity-50 cursor-pointer"
        @click="visible = true;visibleProduct=product"
      >
        <icon
          name="mdi-pill"
          :size="24"
          :color="'#3513e2'"
        />
        <div class="pl-2">
          <p>
            {{ product.name }}
          </p>
          <span class="text-gray-500">{{ product.cip }}</span>
        </div>
      </div>
    </BaseCard>
    <BaseModal
      v-if="visibleProduct"
      v-show="visible"
      :title="visibleProduct.name"
      @close="visible=false;visibleProduct=undefined"
    >
      <BaseFlexSpinner v-if="loading" />
      <div v-else>
        <div v-if="productRef">
          <div class="flex flex-1 justify-center">
            <ProductImage :product="productRef" :size="56" />
          </div>
          <p><span class="font-bold">CIP: </span>{{ productRef.code }}</p>
          <p>
            <span class="font-bold">Prix sur Apodis Santé: </span>
            {{ $filters.currencyze(productRef.price?.ttc || productRef.buyingPrice) }}
          </p>
          <div class="flex flex-1 pt-4 justify-center">
            <BaseButton :primary="!isFeatured" @click="updateSelection()">
              {{ isFeatured ? "Supprimer de" : "Ajouter à" }} ma sélection
            </BaseButton>
          </div>
        </div>
      </div>
    </BaseModal>
  </div>
</template>
