import apiClient from './core/http/clients/apiClient';
import hdsClient from "./core/http/clients/hdsClient";
import StorageService from "./core/services/StorageService";
import AuthenticationService from "./core/services/AuthenticationService";
import HostResource from "./core/http/resources/HostResource";
import EnvironmentResource from "./core/http/resources/EnvironmentResource";
import NotificationResource from "./core/http/resources/NotificationResource";
import {createClient as contentfulCreateClient} from "contentful";
import ContentfulService from "./core/services/ContentfulService";
import HubspotResource from "./core/http/resources/HubspotResource";
import ExperienceResource from "./core/http/resources/ExperienceResource";
import CustomerResource from "./core/http/resources/customer/CustomerResource";
import ExtensionResource from "./core/http/resources/ExtensionResource";
import OrderResource from "./core/http/resources/OrderResource";
import TeamResource from "./core/http/resources/TeamResource";
import SecureFileResource from "./core/http/resources/SecureFileResource";
import ProgramResource from "./core/http/resources/ProgramResource";
import LoyaltyCardMembershipResource from "./core/http/resources/loyalty-card/LoyaltyCardMembershipResource";
import CustomerProgramResource from "./core/http/resources/customer/CustomerProgramResource";
import CustomerQrCodeResource from "./core/http/resources/customer/CustomerQrCodeResource";
import MedicineCabinetResource from "./core/http/resources/MedicineCabinetResource";
import PrescriptionResource from "./core/http/resources/PrescriptionResource";
import LoyaltyCardResource from "./core/http/resources/loyalty-card/LoyaltyCardResource";
import LoyaltyCardProductResource from "./core/http/resources/loyalty-card/LoyaltyCardProductResource";
import LoyaltyCardMembershipTransactionResource
    from "./core/http/resources/loyalty-card/LoyaltyCardMembershipTransactionResource";
import LoyaltyCardMembershipRewardResource
    from "./core/http/resources/loyalty-card/LoyaltyCardMembershipRewardResource";
import LoyaltyCardMembershipCreditResource
    from "./core/http/resources/loyalty-card/LoyaltyCardMembershipCreditResource";
import SurveyResource from "./core/http/resources/survey/SurveyResource";
import SurveyBundleResource from "./core/http/resources/survey/SurveyBundleResource";
import SurveyAnswerInstanceResource from "./core/http/resources/survey/SurveyAnswerInstanceResource";
import NotificationTemplateResource from "./core/http/resources/NotificationTemplateResource";
import OrganizationCreditResource from "./core/http/resources/OrganizationCreditResource";
import ProgramAttachmentResource from "./core/http/resources/ProgramAttachmentResource";
import GuidanceResource from "./core/http/resources/GuidanceResource";
import PathwayResource from "./core/http/resources/PathwayResource";
import DocumentResource from "./core/http/resources/DocumentResource";
import ProductResource from "./core/http/resources/ProductResource";
import HospitalFileResource from "./core/http/resources/HospitalFileResource";
import HealthcenterUserResource from "@/core/http/resources/healthcenter/HealthcenterUserResource";
import InstantDiscountVoucherResource from "@/core/http/resources/InstantDiscountVoucherResource";
import DetectionResource from "@/core/http/resources/DetectionResource";
import NotificationService from "@/core/services/notificationService";
import InterviewResource from "@/core/http/resources/InterviewResource";
import ConsumerCartResource from "@/core/http/resources/ConsumerCartResource";
import DrugInteractionResource from "@/core/http/resources/DrugInteractionResource";
import ConsumerProductResource from "@/core/http/resources/ConsumerProductResource";
import DisruptionLogisticsResource from "@/core/http/resources/DisruptionLogisticsResource";
import WholesalerResource from "@/core/http/resources/WholesalerResource";
import SponsorshipResource from "@/core/http/resources/SponsorshipResource";
import SanteSecureResource from "@/core/http/resources/SanteSecureResource";
import UsersResource from "@/core/http/resources/UsersResource";
import ProSanteConnectResource from "@/core/http/resources/ProSanteConnectResource";
import RegistrationService from "@/core/services/RegistrationService";
import GroupingsResource from "@/core/http/resources/GroupingsResource";
import LgoResource from "@/core/http/resources/LgoResource";
import HealthcenterSearchResource from "@/core/http/resources/healthcenter/HealthcenterSearchResource";
import OfferResource from "@/core/http/resources/OfferResource";
import OfferTeaserResource from "@/core/http/resources/OfferTeaserResource";
import HealthcenterProductResource from "@/core/http/resources/healthcenter/HealthcenterProductResource";
import FilemanagerFileResource from "@/core/http/resources/FilemanagerFileResource";
import SubscriptionPlanResource from "@/core/http/resources/subscription-plan/SubscriptionPlanResource";
import HealthcenterResource from "@/core/http/resources/healthcenter/HealthcenterResource";
import MassCertificationResource from "@/core/http/resources/MassCertificationResource";
import CreditResource from "@/core/http/resources/CreditResource";
import StripeResource from "@/core/http/resources/stripe/StripeResource";
import QuickAppointmentResource from "@/core/http/resources/QuickAppointmentResource";
import PrescriptionRenewalPlanResource, {
    PrescriptionRenewalPlanResourceInterface
} from "@/core/http/resources/PrescriptionRenewalPlanResourceInterface";
import DisruptionResource from "@/core/http/resources/disruptionResource";
import ShopResource from "@/core/http/resources/ShopResource";

/**
 * Bind the storage service.
 * @returns {Object} The storage service.
 */
export function storageService() {
    return StorageService(); // Ensure that StorageService has a constructor
}

/**
 * Bind the authentication service.
 * @returns {Object}.
 */
export function authenticationService() {
    return AuthenticationService(
        apiClient,
        storageService()
    );
}


/**
 * Bind the host resource.
 * @returns {Object} The host resource.
 */
export function hostApi() {
    return HostResource(
        apiClient
    );
}

/**
 * Bind the environment resource.
 * @returns {Object} The environment resource.
 */
export function environmentApi() {
    return EnvironmentResource(
        apiClient
    );
}

/**
 * Bind the notification resource.
 * @returns {Object} The notification resource.
 */
export function notificationApi() {
    return NotificationResource(
        apiClient
    );
}

/**
 * Bind the contentful service.
 * @returns {Object} The contentful service.
 */
export function contentfulService() {
    return ContentfulService(
        contentfulCreateClient
    );
}

/**
 * Bind the hubspot resource.
 * @returns {Object} The hubspot resource.
 */
export function hubspotApi() {
    return HubspotResource(
        apiClient
    );
}

/**
 * Bind the Experience resource.
 *
 * @return {Object}
 */
export function experienceApi() {
    return ExperienceResource(
        apiClient
    );
}

/**
 * Bind the customer resource.
 * @returns {Object} The customer resource.
 */
export function customerApi() {
    return CustomerResource(
        hdsClient
    );
}

/**
 * Bind the customer program resource.
 * @returns {Object} The customer program resource.
 */
export function customerProgramApi() {
    return CustomerProgramResource(
        apiClient
    );
}

/**
 * Bind the customer QR code resource.
 * @returns {Object} The customer QR code resource.
 */
export function customerCertificationApi() {
    return CustomerQrCodeResource(
        hdsClient
    );
}

/**
 * Bind the extension resource.
 *
 * @returns {Object} The  program resource.
 */
export function extensionApi() {
    return ExtensionResource(
        apiClient,
        hdsClient
    );
}

/**
 * Bind the order resource.
 *
 * @returns {Object} The  program resource.
 */
export function orderApi() {
    return OrderResource(
        hdsClient
    );
}

/**
 * Bind the team resource.
 *
 * @return {Object}
 */
export function teamApi() {
    return TeamResource(apiClient);
}

/**
 * Bind the order resource.
 *
 * @return {Object}
 */
export function secureFileApi() {
    return SecureFileResource(hdsClient);
}

/**
 * Bind the program resource.
 * @returns {Object} The program resource.
 */
export function programApi() {
    return ProgramResource(
        apiClient,
        hdsClient
    );
}

/**
 * Bind the hospital file resource.
 *
 * @return {Object}
 */
export function hospitalFileApi() {
    return HospitalFileResource(hdsClient);
}

/**
 * Bind the loyalty card membership resource.
 * @returns {Object} The loyalty card membership resource.
 */
export function loyaltyCardMembershipApi() {
    return LoyaltyCardMembershipResource(hdsClient);
}

/**
 * Bind the medicine cabinet resource.
 *
 * @returns {Object} The survey resource.
 */
export function medicineCabinetApi() {
    return MedicineCabinetResource(hdsClient);
}

/**
 * Bind the prescription resource.
 *
 * @return {Object}
 */
export function prescriptionApi() {
    return PrescriptionResource(hdsClient);
}

/**
 * Bind the prescription resource.
 *
 * @return {Object}
 */
export function prescriptionRenewalPlanApi(): PrescriptionRenewalPlanResourceInterface {
    return PrescriptionRenewalPlanResource(hdsClient);
}

/**
 * Bind the loyalty card resource.
 * @returns {Object} The loyalty card resource.
 */
export function loyaltyCardApi() {
    return LoyaltyCardResource(
        apiClient,
        hdsClient
    );
}

/**
 * Bind the loyalty card product resource.
 * @returns {Object} The loyalty card product resource.
 */
export function loyaltyCardProductApi() {
    return LoyaltyCardProductResource(
        apiClient
    );
}

/**
 * Bind the loyalty card membership transaction resource.
 * @returns {Object} The loyalty card membership transaction resource.
 */
export function loyaltyCardMembershipTransactionApi() {
    return LoyaltyCardMembershipTransactionResource(
        hdsClient
    );
}

/**
 * Bind the loyalty card membership reward resource.
 * @returns {Object} The loyalty card membership reward resource.
 */
export function loyaltyCardMembershipRewardApi() {
    return LoyaltyCardMembershipRewardResource(
        hdsClient
    );
}

/**
 * Bind the loyalty card membership credit resource.
 * @returns {Object} The loyalty card membership credit resource.
 */
export function loyaltyCardMembershipCreditApi() {
    return LoyaltyCardMembershipCreditResource(
        hdsClient
    );
}

/**
 * Bind the survey resource.
 *
 * @returns {Object} The survey resource.
 */
export function surveyApi() {
    return SurveyResource(apiClient);
}

/**
 * Bind the survey resource.
 *
 * @returns {Object} The survey resource.
 */
export function surveyBundleApi() {
    return SurveyBundleResource(apiClient);
}

/**
 * Bind the survey answer instance resource.
 * As some endpoint need authorization check we need to use the hds client.
 * Often, the request is forwarded to the api.
 *
 * @return {Object}
 */
export function surveyAnswerInstanceApi() {
    return SurveyAnswerInstanceResource(hdsClient);
}

/**
 * Bind the message template resource.
 * @return {Object}
 */
export function notificationTemplateApi() {
    return NotificationTemplateResource(
        hdsClient
    );
}

/**
 * Bind the organization credit resources.
 *
 * @return {Object}
 */
export function organizationCreditApi() {
    return OrganizationCreditResource(apiClient);
}

/**
 * Bind the program attachment resource.
 *
 * @return {Object}
 */
export function programAttachmentApi() {
    return ProgramAttachmentResource(
        apiClient
    );
}

/**
 * Bind the guidance api.
 *
 * @returns {Object} The guidance resource.
 */
export function guidanceApi() {
    return GuidanceResource(
        apiClient,
        hdsClient
    );
}

/**
 * Bind the pathway api.
 *
 * @returns {Object} The guidance resource.
 */
export function pathwayApi() {
    return PathwayResource(apiClient);
}

/**
 * Bind the guidance api.
 *
 * @returns {Object} The guidance resource.
 */
export function documentApi() {
    return DocumentResource(apiClient);
}

/**
 * Bind the product resource.
 *
 * @returns {Object} The product resource.
 */
export function productApi() {
    return ProductResource(apiClient);
}

/**
 * Bind the healthcenter user resource.
 *
 * @return {Object}
 */
export function healthcenterUserApi() {
    return HealthcenterUserResource(hdsClient);
}

/**
 * Bind the instant discount voucher resource.
 * @returns {Object} The instant discount voucher resource.
 */
export function instantDiscountVoucherApi() {
    return InstantDiscountVoucherResource(
        apiClient,
        hdsClient
    );
}

/**
 * Bind the detection resource.
 * @returns {Object} The detection resource.
 */
export function detectionApi() {
    return DetectionResource(
        hdsClient
    );
}

/**
 * Bind the notification service.
 * @returns {Object} The notification service.
 */
export function notificationService() {
    return NotificationService();
}

/**
 * Bind the interview resource
 */
export function interviewApi() {
    return InterviewResource(apiClient, hdsClient);
}

/**
 * Bind the interview resource
 */
export function filemanagerFileApi() {
    return FilemanagerFileResource(apiClient);
}

/**
 * Bind the customer cart resource.
 *
 * @returns {Object}
 */
export function consumerCartApi() {
    return ConsumerCartResource(hdsClient);
}

/**
 * Bind the customer product resource.
 *
 * @return {Object}
 */
export function consumerProductApi() {
    return ConsumerProductResource(apiClient, hdsClient);
}

/**
 * Bind the drug interaction resource.
 *
 * @return {Object}
 */
export function drugInteractionApi() {
    return DrugInteractionResource(apiClient, hdsClient);
}

/**
 * Bind the disruption logistics resource.
 */
export const disruptionLogisticsApi = () => {
    return DisruptionLogisticsResource(apiClient);
};

/**
 * Bind the wholesaler resource.
 */
export const wholesalerApi = () => {
    return WholesalerResource(apiClient);
};

/**
 * Bind the sponsorship resource.
 */
export const sponsorshipApi = () => {
    return SponsorshipResource(apiClient);
};


/**
 * Bind the sante-secure resource.
 * @returns {Object} The sante secure resource.
 */
export function santeSecureApi() {
    return SanteSecureResource(
        apiClient
    );
}

/**
 * Bind the users resource.
 * @returns {Object} The users resource.
 */
export function usersApi() {
    return UsersResource(
        apiClient
    );
}

/**
 * Bind the pro sante connect resource.
 * @returns {Object} The pro sante connect operator resource.
 */
export function proSanteConnectApi() {
    return ProSanteConnectResource(
        apiClient
    );
}

/**
 * Bind the registration service.
 * @returns {Object} The registration service.
 */
export function registrationService() {
    return RegistrationService(
        apiClient
    );
}

/**
 * Bind the groupings resource.
 * @returns {Object} The groupings resource.
 */
export function groupingsApi() {
    return GroupingsResource(
        apiClient
    );
}

/**
 * Bind the lgo resource.
 * @returns {Object} The lgo operator resource.
 */
export function lgoApi() {
    return LgoResource(
        apiClient
    );
}

/**
 * Bind the healthcenter search resource.
 * @returns {Object} The healthcenter serach resource.
 */
export function healthcenterSearchApi() {
    return HealthcenterSearchResource(
        apiClient
    );
}

/**
 * Bind the healthcenter product resource.
 * @returns {Object} The healthcenter product resource.
 */
export function healthcenterProductApi() {
    return HealthcenterProductResource(
        apiClient
    );
}

/**
 * Bind the offer resource.
 * @returns {Object} The offer resource.
 */
export function offerApi() {
    return OfferResource(apiClient);
}

/**
 * Bind the offer teaser resource.
 * @returns {Object} The offer resource.
 */
export function offerTeaserApi() {
    return OfferTeaserResource(apiClient);
}

/**
 * Bind the subscription plan resource.
 */
export function subscriptionPlanApi() {
    return SubscriptionPlanResource(apiClient);
}

/**
 * Bind the subscription plan resource.
 */
export function healthcenterApi() {
    return HealthcenterResource(apiClient);
}

/**
 * Bind the mass certification resource.
 */
export function massCertificationApi() {
    return MassCertificationResource(hdsClient);
}

/**
 * Bind the credit resource.
 */
export function creditApi() {
    return CreditResource(apiClient);
}

/**
 * Bind the stripe resource.
 */
export function stripeApi() {
    return StripeResource(apiClient);
}

/**
 * Bind the quick appointment resource.
 */
export function quickAppointmentApi() {
    return QuickAppointmentResource(hdsClient);
}

/**
 * Bind the disruption resource.
 */
export function disruptionApi() {
    return DisruptionResource(apiClient);
}

/**
 * Bind the shop resource.
 */
export function shopApi() {
    return ShopResource(apiClient);
}
