<script setup lang="ts">
import {SellinProduct} from "@/core/interfaces/SellinProduct";
import BaseCard from "@/core/components/base/BaseCard.vue";
import {LegacySellinOffer} from "@/core/interfaces/SellinOffer";
import productHelper from "../../core/helpers/productHelper";
import {computed, onMounted, ref} from "vue";
import {shopApi} from "@/container";
import LaboratoryProduct from "@/core/interfaces/laboratory/LaboratoryProduct";
import ProductPriceLine from "@/sellin-offer/components/ProductPriceLine.vue";
import imagesHelper from "@/core/helpers/imagesHelper";

const {product, offer} = defineProps<{ product: SellinProduct, offer: LegacySellinOffer }>();
const emits = defineEmits(['onQuantityChanged']);
const quantity = ref(product.quantity);
const laboratoryProduct = ref<LaboratoryProduct>();
const loading = ref(true);

onMounted(() => {
    shopApi().getLaboratoryProductPrices(offer.laboratoryId, product.productId, quantity.value)
        .then(labProduct => {
            laboratoryProduct.value = labProduct;
            emits('onQuantityChanged', labProduct, quantity.value);
        })
        .finally(() => loading.value = false);
});

const totalPrice = computed(() => {
    if (laboratoryProduct.value) {
        return laboratoryProduct.value.laboratoryShopPrice.totalDiscountedWithoutVat;
    }

    return null;
});

const minimumQuantity = computed(() => {
    if (laboratoryProduct.value) {
        return laboratoryProduct.value.laboratoryShopPrice.minimumQuantity;
    }

    return null;
});
</script>

<template>
  <base-card class="p-4 w-1/2 lg:w-1/4">
    <div v-if="loading">
      <v-skeleton-loader type="card" />
    </div>
    <div v-else>
      <div v-if="product?.product" class="flex flex-row ">
        <img :src="imagesHelper.getLaboratoryProductImage(product.product)" class="w-24 h-24 mr-4">
        <div class="flex flex-1 flex-col">
          <p>{{ product.product.name }}</p>
          <p class="text-gray-500">
            {{ productHelper.getSellinOfferProductCode(product.product, true) }}
          </p>
          <p v-if="!!minimumQuantity">
            Quantité minimum : {{ minimumQuantity }}
          </p>
          <product-price-line v-if="laboratoryProduct" :product="laboratoryProduct" unit="unité" />
          <div class="flex flex-row items-center justify-between">
            <span>x{{ quantity }}</span>
            <span v-if="totalPrice">{{ totalPrice.toFixed(2) }}€ HT</span>
          </div>
        </div>
      </div>
    </div>
  </base-card>
</template>
