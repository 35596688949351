<template>
  <base-card>
    <base-card-body>
      <div class="flex flex-col gap-1">
        <div class="flex justify-between">
          <div class="flex flex-col">
            <label class="font-bold mb-2">{{ healthcenter.name }} ({{ healthcenter.cip }})</label>
            <label v-if="healthcenter.holder?.name">
              {{ healthcenter.holder.name }}
            </label>
            <label
              v-if="healthcenter.holder"
              class="text-sm"
            >{{ healthcenter.holder?.email }}</label>
          </div>
          <div v-if="canDeleteMember && !healthcenterIsGroupOwner">
            <button @click="confirmDelete">
              <icon
                name="mdi-delete"
                color="red"
                :size="25"
              />
            </button>
          </div>
          <div v-if="canLeave && !healthcenterIsGroupOwner">
            <button @click="confirmLeave">
              <v-icon
                color="red"
                :size="25"
              >
                mdi-exit-to-app
              </v-icon>
            </button>
          </div>
        </div>
        <div class="flex flex-row justify-between text-sm mt-2">
          <label>
            {{ address ?? '' }}
          </label>
          <label
            v-if="healthcenter.last_lgo_import"
            class="self-end text-gray-500"
          >
            Dernière transmission: {{ $filters.date(healthcenter.last_lgo_import,'DD/MM/YYYY') }}
          </label>
        </div>
      </div>
    </base-card-body>
  </base-card>
</template>

<script lang="ts">
import {defineComponent, inject, PropType} from 'vue';
import {HealthcenterGroupMember} from "@/disruption-logistics/interfaces/healthcenter-groups";
import HealthcenterGroupService from "@/disruption-logistics/services/HealthcenterGroupService";
import {disruptionLogisticsApi} from "@/container";
import BaseCard from "@/core/components/base/BaseCard.vue";
import BaseCardBody from "@/core/components/base/BaseCardBody.vue";
import Icon from "@/core/components/icon/Icon.vue";

export default defineComponent({
    name: "HealthcenterGroupHealthcenterRow",
    components: {Icon, BaseCardBody, BaseCard},
    inject: ['openConfirmationModal'],
    props: {
        healthcenter: {
            type: Object as PropType<HealthcenterGroupMember>,
            required: true
        },
        ownerAccessId: {
            type: Number,
            required: true
        },
        groupId: {
            type: Number,
            required: true
        }
    },
    emits: ['member-deleted'],
    setup() {
        const openConfirmationModal = inject<any>('openConfirmationModal');

        return {
            openConfirmationModal
        };
    },
    computed: {
    /**
     * Get the healthcenter address.
     */
        address(): string | null {
            if (this.healthcenter.address) {
                return this.healthcenter.address.formatted;
            }

            return null;
        },
        /**
     * Check if the current user can delete the member.
     */
        canDeleteMember() {
            return HealthcenterGroupService.isOwner(this.ownerAccessId);
        },
        /**
     * Determine if the user can leave the group.
     */
        canLeave() {
            return HealthcenterGroupService.healthcenterIsAuth(this.healthcenter.id);
        },
        /**
     * Check if the healthcenter is the owner of the group.
     */
        healthcenterIsGroupOwner(): boolean {
            return this.healthcenter.owners?.some(owner => owner.id === this.ownerAccessId) ?? false;
        }
    },
    methods: {
    /**
     * Confirm the deletion of the member.
     */
        confirmDelete() {
            this.openConfirmationModal(
                'Suppression d\'un membre',
                'Êtes-vous sûr de vouloir supprimer ce membre ?',
                this.deleteMember
            );
        },
        /**
     * Confirm leaving the group.
     */
        confirmLeave() {
            this.openConfirmationModal(
                'Quitter le groupe',
                'En quittant le groupe, vous ne pourrez plus accéder aux données partagées par les membres du groupe, et vos données ne seront plus accessibles par les membres du groupe.',
                this.leaveGroup
            );
        },
        /**
     * Delete the member, then emit the member-deleted event.
     */
        async deleteMember() {
            return disruptionLogisticsApi()
                .deleteHealthcenterGroupMember(this.groupId, this.healthcenter.id)
                .then(() => {
                    this.$emit('member-deleted', this.healthcenter.id);
                });
        },
        /**
     * Leave the group.
     */
        async leaveGroup() {
            return disruptionLogisticsApi()
                .leaveHealthcenterGroup(this.groupId)
                .then(() => {
                    this.$router.replace({name: 'disruption-logistics.healthcenter-groups'});
                });
        }
    }
});
</script>

<style scoped>

</style>
