import {AxiosResponse} from "axios";

/**
 * The healthcenters product resource.
 *
 * @param {Object} client - The injected client.
 * @returns {Object} The public object.
 */
export default function (client) {
    return {
        /**
         * Add a feature products to a healthcenter.
         *
         * @param {number} healthcenterId - The healthcenter id.
         * @param {number} productId - The product id.
         *
         * @returns {Promise<any>}
         */
        create(healthcenterId: number, productId: number): Promise<any> {
            return client.post(`healthcenters/${healthcenterId}/products/${productId}`)
                .then((response: AxiosResponse) => response.data);
        },

        /**
         * Read all the featured products of a healthcenter.
         *
         * @param {number} healthcenterId - The healthcenter id.
         *
         * @returns {Promise<any>}
         */
        read(healthcenterId: number): Promise<any> {
            return client.get(`healthcenters/${healthcenterId}/products`, {params: {include: "price"}})
                .then((response: AxiosResponse) => response.data);
        },

        /**
         * Delete a featured products from a healthcenter.
         *
         * @param {number} healthcenterId - The healthcenter id.
         * @param {number} productId - The product id.
         *
         * @returns {Promise<any>}
         */
        delete(healthcenterId: number, productId: number): Promise<any> {
            return client.delete(`healthcenters/${healthcenterId}/products/${productId}`)
                .then((response: AxiosResponse) => response.data);
        }
    };
};