<template>
  <v-data-table
    :headers="headers"
    :items="transformedConsumers"
    :items-per-page="Number(meta.per_page)"
    :loading="loading"
    :disable-sort="true"
    :server-items-length="meta.total"
    :loading-text="'Chargement en cours...'"
    no-data-text="Aucun patients n'a activé cette carte de fidélité"
    :footer-props="{
      'items-per-page-text': 'Éléments par page',
      'page-text': '{0}-{1} sur {2}',
      'total-items': 'Total des éléments'
    }"
    @update:page="onPageChange"
    @click:row="handleClick"
  />
</template>

<script lang="ts">
import {defineComponent} from "vue";
import dateHelper from '@/core/helpers/dateHelper';
import { loyaltyCardApi } from '@/container';

export default defineComponent({
    name: 'LoyaltyCardConsumersTable',
    props: {
    /** The loyalty card id. */
        resourceId: {
            type: Number,
            required: true
        }
    },
    data: function () {
        return {
            /** The loading state of the table. */
            loading: true,
            /** The loyalty card consumers */
            consumers: [] as any[],
            /** The pagination meta data. */
            meta: {
                total: undefined,
                per_page: undefined
            }
        };
    },
    computed: {
    /**
     * The table header configuration.
     *
     * @return {Array}
     */
        headers () {
            return [
                {
                    title: 'Nom',
                    key: 'name'
                },
                {
                    title: 'Dernière visite',
                    key: 'lastPurchase'
                },
                {
                    title: 'Panier moyen',
                    key: 'averageBasket'
                },
                {
                    title: 'Achats',
                    key: 'purchases'
                },
                {
                    title: 'Points débités / crédités',
                    key: 'points'
                },
                {
                    title: 'Solde',
                    key: 'balance'
                },
                {
                    title: 'Récompenses',
                    key: 'rewards'
                }
            ];
        },
        /**
     * Transform the customers for the table.
     *
     * @return {Array}
     */
        transformedConsumers () {
            if (!this.consumers) {
                return undefined;
            }

            return this.consumers.map((consumer) => {
                return {
                    customerId: consumer.customer.id,
                    membershipId: consumer.customer.membership_id,
                    name: consumer.customer.name,
                    lastPurchase: consumer.statistics.last_purchase ? dateHelper.shortFormat(consumer.statistics.last_purchase) : '-',
                    averageBasket: consumer.total.quantity
                        ? String((consumer.total.revenue / consumer.total.quantity).toFixed(2)) + '€'
                        : '-',
                    balance: `${consumer.total.balance} point${consumer.total.balance > 1 ? 's' : ''}`,
                    purchases: `${consumer.total.quantity} (${consumer.total.revenue.toFixed(2)}€)`,
                    points: `${consumer.total.value}/${consumer.total.value + consumer.total.balance}`,
                    rewards: `${consumer.statistics.reward.discounts.toFixed(2)}€`
                };
            });
        }
    },
    /**
   * On mount, load first customer page.
   */
    mounted () {
        this.load(1);
    },
    methods: {
    /**
     * Load the given page.
     *
     * @param {Number} page
     */
        onPageChange (page: number) {
            this.load(page);
        },
        /**
     * Load the given page.
     *
     * @param page
     */
        load (page: number) {
            this.loading = true;
            loyaltyCardApi()
                .getMembership(this.resourceId, page, 20)
                .then((response) => {
                    this.consumers = response.data;
                    this.meta = response.meta;
                }).finally(() => {
                    this.loading = false;
                });
        },
        /**
     * Redirect to customer details.
     *
     * @param row
     */
        handleClick (event, row) {
            this.$router.push({
                name: 'customer.loyalty.membership',
                params: {
                    customerId: row.item.customerId,
                    membershipId: row.item.membershipId
                }
            });
        }
    }
});
</script>
