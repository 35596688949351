<template>
  <img
    :src="`data:image;base64,${base64}`"
    :alt="alt"
  >
</template>

<script lang="ts">
import {defineComponent} from "vue";
export default defineComponent({
    name: 'Base64Image',
    props: {
        base64: {
            type: String,
            required: true
        },
        alt: {
            type: String,
            required: true
        }
    }
});
</script>
