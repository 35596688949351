<template>
  <div>
    <header-back-button
      :title="`Historique de disponibilité de ${productName}`"
    />
    <div class="flex flex-col p-3">
      <base-flex-spinner v-if="loading" />
      <div
        v-else-if="availability"
        class="flex flex-col gap-2"
      >
        <div class="flex gap-2 items-center">
          <div
            class="w-4 h-4 rounded-full"
            :class="{
              'bg-green-500': availability.available,
              'bg-red-500': !availability.available
            }"
          />
          <p class="font-semibold text-primary">
            Produit {{ availability.available ? 'disponible' : 'indisponible' }} chez le dépositaire en direct
          </p>
        </div>
        <h3>
          Historique de disponibilité :
        </h3>
        <p v-if="!histories?.length">
          Aucun historique de disponibilité n'est disponible
        </p>
        <div
          v-for="(history, index) in histories"
          :key="index"
          class="flex gap-2 items-center bg-white rounded-lg shadow p-3 text-md"
        >
          <div
            class="w-4 h-4 rounded-full"
            :class="{
              'bg-green-500': history.was,
              'bg-red-500': !history.was
            }"
          />
          <p>
            Était {{ history.was ? 'disponible' : 'indisponible' }} avant le {{ $filters.date(history.updated_at, 'short') }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import {disruptionLogisticsApi} from "@/container";
import {ProductAvailability, ProductAvailabilityHistory} from "@/disruption-logistics/interfaces";
import HeaderBackButton from "@/core/components/header-back-button/HeaderBackButton.vue";
import BaseFlexSpinner from "@/core/components/base/spinner/BaseFlexSpinner.vue";

export default defineComponent({
    name: "AvailabilityHistoryView",
    components: {BaseFlexSpinner, HeaderBackButton},
    data() {
        return {
            availability: undefined as undefined | ProductAvailability,
            loading: false
        };
    },
    computed: {
    /**
     * The product name.
     */
        productName() {
            return this.$route.query.productName;
        },
        /**
     * The availability ID.
     */
        availabilityId() {
            return this.$route.params.availabilityId;
        },
        /**
     * Reverse the history order.
     *
     * @return {Array}
     */
        histories(): ProductAvailabilityHistory[] {
            const availability = this.availability;

            if (!availability) {
                return [];
            }

            return availability?.history?.reverse();
        }
    },
    watch: {
        availabilityId: {
            handler() {
                this.loading = true;
                this.loadHistory()
                    .finally(() => {
                        this.loading = false;
                    });
            },
            immediate: true
        }
    },
    methods: {
    /**
     * Load the availability history.
     */
        async loadHistory() {
            return disruptionLogisticsApi()
                .availabilityHistory(Number(this.availabilityId))
                .then((response) => {
                    this.availability = response;
                });
        }
    }
});
</script>

<style scoped>

</style>
