<template>
  <div class="grid grid-cols-5 p-2 gap-4 align-center">
    <div class="flex-shrink-0 w-24">
      <BaseImage
        v-if="!!getImageUrl()"
        rendered
        class="h-20 w-20 rounded-lg"
        :text="item.product.name"
        :path="getImageUrl()"
      />
      <BaseImage
        v-else
        rendered
        class="h-20 w-20 rounded-lg"
        :text="item.product.name"
        :path="noProductImage"
      />
    </div>
    <div class="col-span-2">
      <p>{{ item.product.name }}</p>
    </div>
    <p> x{{ item.quantity }}</p>
    <p
      v-if="item.expired"
      class="text-primary text-sm"
    >
      dont {{ item.expired }} {{ $filters.pluralize('périmé', item.expired) }}
    </p>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import BaseImage from '@/core/components/base/BaseImage.vue';
import noProductImage from '@/assets/images/no-product-image.png';

export default defineComponent({
    name: 'CustomerMedicineCabinetItem',
    components: { BaseImage },
    props: {
        item: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            noProductImage
        };
    },
    methods: {
        getImageUrl () {
            const path = this.item.product?.image_url;

            if (path) {
                return `${import.meta.env.VITE_API_URL}/images/${path}`;
            }

            return undefined;
        }
    }
});
</script>
