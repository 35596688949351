<template>
  <base-card
    class="border-orange-default"
    style="border-width: 1px"
  >
    <base-card-body class="flex flex-col gap-3">
      <p class="font-bold">
        Invitations reçues
      </p>
      <healthcenter-group-received-invitation-row
        v-for="invitation in invitations"
        :key="invitation.id"
        :invitation="invitation"
        @invitation-handled="invitationHandled"
      />
    </base-card-body>
  </base-card>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';
import BaseCard from "@/core/components/base/BaseCard.vue";
import BaseCardBody from "@/core/components/base/BaseCardBody.vue";

import {HealthcenterGroupInvitation} from "@/disruption-logistics/interfaces/healthcenter-groups";
import HealthcenterGroupReceivedInvitationRow
    from "@/disruption-logistics/components/healthcenter-groups/HealthcenterGroupReceivedInvitationRow.vue";

export default defineComponent({
    name: "ReceivedHealthcenterGroupInvitationsCard",
    components: {HealthcenterGroupReceivedInvitationRow, BaseCardBody, BaseCard},
    props: {
    /**
     * The pending invitations to display.
     */
        invitations: {
            type: Array as PropType<HealthcenterGroupInvitation[]>,
            required: true
        }
    },
    emits: ['invitation-handled'],
    methods: {
    /**
     * Methods to handle the invitation
     */
        invitationHandled(invitation: HealthcenterGroupInvitation, accepted: boolean) {
            this.$emit('invitation-handled', invitation, accepted);
        }
    }
});
</script>

<style scoped>

</style>
