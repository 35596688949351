<template>
  <div class="flex flex-col gap-2">
    <h3 class="font-content-bold text-xl">
      Taux de disponibilité chez les grossistes
    </h3>
    <line-chart
      :data="lineChartData"
      :options="options"
    />
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';
import dayjs from "dayjs";
import LineChart from "@/core/components/charts/LineChart.vue";

export default defineComponent({
    name: "WholesalersAvailabilityLineChart",
    components: {LineChart},
    props: {
        data: {
            type: Array as PropType<{ date: string; rate: number }[]>,
            required: false,
            default: () => []
        }
    },
    computed: {
    /**
     * Convert the data for line chart.
     */
        lineChartData(): { labels: string[], datasets: [{ label: string, data: number[] }] } {
            return this.data.reduce(
                (
                    prev: { labels: string[], datasets: [{ label: string; data: number[] }] },
                    currentValue: { date: string; rate: number }
                ) => {
                    prev.labels.push(dayjs(currentValue.date).format('MM/YYYY'));
                    prev.datasets[0].data.push(currentValue.rate * 100);

                    return prev;
                },
                {labels: [], datasets: [{label: 'Taux de disponibilité', data: []}]}
            );
        },
        /**
     * The chart options.
     *
     * @return {Object}
     */
        options() {
            return {
                scales: {
                    y: {
                        ticks: {
                            callback: function (value) {
                                return value + '%';
                            }
                        }
                    }
                },
                plugins: {
                    tooltip: {
                        callbacks: {
                            label: function (context) {
                                return context.dataset.label + ': ' + context.parsed.y.toFixed(2) + '%';
                            }
                        }
                    },
                    legend: {
                        display: false
                    }
                }
            };
        }
    }
});
</script>

<style scoped>

</style>
