import {RouteRecordRaw} from "vue-router";
import CommunicationCustomerView from "@/communication/views/CommunicationCustomerView.vue";
import CommunicationMessageView from "@/communication/views/CommunicationMessageView.vue";
import CommunicationSummaryView from "@/communication/views/CommunicationSummaryView.vue";
import CommunicationMessageStoreView from "@/communication/views/CommunicationMessageStoreView.vue";

const routes: RouteRecordRaw[] = [
    {
        path: 'communication',
        name: 'patient-administration.communication',
        component: CommunicationCustomerView
    },
    {
        path: 'communication/message',
        name: 'patient-administration.communication.message',
        component: CommunicationMessageView,

    },
    {
        path: 'communication/summary',
        name: 'patient-administration.communication.summary',
        component: CommunicationSummaryView
    },
    {
        path: 'communication/message/store',
        name: 'patient-administration.communication.message.store',
        component: CommunicationMessageStoreView
    }
];

export default routes;
