<script setup lang="ts">
import customerAppLogo from "@/assets/images/customer-app-logo.png";
import {MassCertificationNotificationTemplate} from "@/core/interfaces/MassCertification";
import {useCommunicationStore} from "@/communication/stores/communication.store";

defineProps<{ template: MassCertificationNotificationTemplate }>();

const {mode} = useCommunicationStore();
</script>

<template>
  <div v-if="mode==='sms'" class="flex flex-row pr-4 py-2">
    <div class="flex items-end">
      <img :src="customerAppLogo" class="w-12 h-12">
    </div>
    <div class="flex flex-1 flex-col bg-apodisPink rounded-lg p-4 ml-4">
      <span class="text-primary">{{ template.parsed_content }}</span>
    </div>
  </div>
  <div v-else class="flex flex-col rounded-lg shadow">
    <div class="flex flex-row bg-gray-100 rounded-t-lg py-2 px-4">
      <img :src="customerAppLogo" class="w-6">
      <span class="text-primary pl-2">Apodis</span>
    </div>
    <div class="p-4">
      <span>{{ template.parsed_content }}</span>
    </div>
  </div>
</template>
