<script setup lang="ts">
import BaseModal from "@/core/components/base/BaseModal.vue";
import CustomerFilters from "@/customer/components/customer-filters/CustomerFilters.vue";
import {ref} from "vue";
import BaseButton from "@/core/components/base/BaseButton.vue";
import {storeToRefs} from "pinia";
import {useCommunicationStore} from "@/communication/stores/communication.store";
import Icon from "@/core/components/icon/Icon.vue";
import QuickItemPicker from "@/core/components/filters/QuickItemPicker.vue";
import {QuickFilter, SearchItem} from "@/core/interfaces/search-bar";
import SearchBarService from "@/core/services/SearchBarService";

const {selectedFilters} = storeToRefs(useCommunicationStore());

const visible = ref(false);
const selectionVisible = ref(false);

/**
 * Action when a filter is deleted.
 * @param item
 */
const onDelete = (item: SearchItem) => {
    const elementIndex = SearchBarService.getItemIndex(item, selectedFilters.value);
    let copy = [...selectedFilters.value];

    if (elementIndex) {
        copy.splice(elementIndex, 1);
    }

    selectedFilters.value = copy;
};
</script>

<template>
  <div>
    <BaseModal v-if="visible" title="Filtrer" @close="visible=false">
      <CustomerFilters :selected="selectedFilters" @on-filter-change="(value) => selectedFilters = value" />
    </BaseModal>
    <div
      v-else
      class="flex flex-1 flex-row px-4 pt-2 align-start"
      :class="[{'justify-between': !!selectedFilters.length, 'justify-end': !selectedFilters.length}]"
    >
      <div v-if="selectedFilters.length" class="flex flex-col justify-start">
        <div
          class="flex flex-row py-1 align-center hover:opacity-75 hover:cursor-pointer"
          @click="selectionVisible = !selectionVisible"
        >
          <p>Filtres <span class="text-xl px-1">{{ selectedFilters.length }}</span></p>
          <Icon :name="selectionVisible ? 'mdi-chevron-up' : 'mdi-chevron-down'" class="px-2" />
        </div>
        <div v-if="selectionVisible" class="flex flex-row flex-wrap">
          <QuickItemPicker
            v-for="(selectedFilter, index) in selectedFilters"
            :key="index"
            :label="(selectedFilter as QuickFilter<any>).label"
            selected
            closable
            @on-selected="onDelete(selectedFilter)"
          />
        </div>
      </div>
      <BaseButton @click="visible=true">
        Filtrer
      </BaseButton>
    </div>
  </div>
</template>
