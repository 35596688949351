<template>
  <VueGoogleMap
    ref="ref"
    api-key="AIzaSyD9YjvZsx6b11ANy-TE2kB5_oT2BguQ9o8"
    :center="center"
    :zoom="zoom"
    disable-default-ui
    :clickable-icons="false"
  >
    <slot />
  </VueGoogleMap>
</template>

<script lang="ts">
import {defineComponent, PropType, defineExpose} from 'vue';
import {GoogleMap as VueGoogleMap} from 'vue3-google-map';

export default defineComponent({
    name: "GoogleMap",
    components: {VueGoogleMap},
    props: {
        center: {
            type: Object as PropType<{ lat: number; lng: number }>,
            default: () => ({lat: 48.856613, lng: 2.352222}),
        },
        zoom: {
            type: Number,
            default: 12
        }
    },
    setup() {
        defineExpose(['fitBounds']);
    },
    methods: {
        fitBounds(bounds: { lat: number; lng: number }[], padding: number | null = null) {
            const mapRef = this.$refs.ref as InstanceType<typeof VueGoogleMap>;

            if (mapRef.ready && bounds.length) {
                const googleMaps = mapRef.map;
                const latLngBounds = new google.maps.LatLngBounds();
                bounds.forEach(({lat, lng}) => {
                    latLngBounds.extend(new google.maps.LatLng(lat, lng));
                });

                googleMaps?.fitBounds(latLngBounds, padding || 0);
            }
        }
    }
});
</script>
