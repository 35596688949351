<script setup lang="ts">
import {DotLottieVue} from "@lottiefiles/dotlottie-vue";
</script>

<template>
  <dot-lottie-vue
    src="animations/not-found.json"
    style="width: 100%; height: 100%; max-width: 500px; margin: auto"
    autoplay
    loop
  />
</template>
