<template>
  <product-availabity-card
    :title="title"
    class="cursor-pointer"
    loading-message="Interrogation des stocks des pharmacies partenaires"
    :loading="data.loading"
    :is-available="data.availability"
    :messages="message"
    :success="!!memberCounts"
    @click="scrollToGroupMap"
  >
    <template #icon>
      <healthcenter-group-icon />
    </template>
  </product-availabity-card>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';
import {
    AvailabilityState
} from "@/disruption-logistics/components/product-availability-card/ProductAvailabilityCardGrid.vue";
import ProductAvailabityCard
    from "@/disruption-logistics/components/product-availability-card/ProductAvailabilityCard.vue";
import HealthcenterGroupIcon from "@/disruption-logistics/components/healthcenter-groups/HealthcenterGroupIcon.vue";
import {HealthcenterGroup} from "@/disruption-logistics/interfaces/healthcenter-groups";

export default defineComponent({
    name: "HealthcenterGroupProductAvailabilityCard",
    components: {HealthcenterGroupIcon, ProductAvailabityCard},
    props: {
        data: {
            type: Object as PropType<AvailabilityState<HealthcenterGroup[]>>,
            required: true
        }
    },
    computed: {
    /**
     * Get the title.
     */
        title (): string {
            if (!this.memberCounts) {
                return 'Vous n\'avez pas de pharmacie partenaire';
            }

            return `${this.availabilityLabel(this.data)} dans une pharmacie partenaire`;
        },
        /**
     * Get the message.
     */
        message (): string[] {
            if (!this.memberCounts) {
                return ['Invitez des pharmacies et accédez à leurs stock ainsi qu\'à leurs disponibilité en agences'];
            }

            return [];
        },
        /**
     * Get the member counts.
     */
        memberCounts (): number {
            return this.data?.details?.length ?? 0;
        }
    },
    methods: {
    /**
     * Get the availability label.
     */
        availabilityLabel (provider: AvailabilityState<unknown>): string {
            if (provider.availability) {
                return 'Disponible';
            } else if (!provider.availability) {
                return 'Indisponible';
            }

            return '';
        },
        /**
     * Scroll to the group map.
     */
        scrollToGroupMap () {
            const element = document.getElementById('group-map');
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }
});
</script>

<style scoped>

</style>
