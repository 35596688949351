<template>
  <div
    class="rounded-lg m-5 p-3"
    :class="secondary ? 'bg-orange-light': 'gradient'"
  >
    <slot />
  </div>
</template>

<script>
export default {
    name: 'InformationPanel',
    props: {
        secondary: {
            type: Boolean,
            default: false
        }
    }
};
</script>
