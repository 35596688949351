<script setup lang="ts">
defineProps<{
    bgColorClass: string,
    timeAgo: string
}>();
</script>

<template>
  <div class="relative flex flex-col drop-shadow-lg w-52">
    <div class="absolute -top-3 -right-3">
      <slot name="image" />
    </div>
    <div class="py-2 px-4 rounded-t-lg" :class="bgColorClass">
      <p class="text-sm pt-1">
        {{ timeAgo }}
      </p>
    </div>
    <div class="p-5 text-sm bg-white rounded-b-lg">
      <slot />
    </div>
  </div>
</template>
