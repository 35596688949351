<template>
  <div
    class="flex flex-col"
  >
    <div class="flex flex-row gradient px-3 pt-3">
      <img src="@/assets/images/contact-marion.png" class="h-20">
      <div class="flex flex-col justify-end pl-3 pb-2">
        <p class="text-white">
          Bonjour, je suis Marion du service client d'Apodis
        </p>
        <h2 class="text-white">
          Si vous avez besoin d'aide ou une question, je suis là !
        </h2>
      </div>
    </div>
    <div class="px-6">
      <p class="font-content my-5">
        👉 Décrivez au mieux le problème rencontré, indiquez un numéro de téléphone puis cliquez sur envoyer et nous vous
        rappellerons au plus vite 😊
      </p>
      <div class="flex flex-row">
        <textarea
          v-model="message"
          rows="3"
          placeholder="Quel est votre problème ?"
          class="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md bg-white w-full"
        />
        <div
          class="flex items-center pl-4"
        >
          <base-button
            primary
            @click="onSend"
          >
            <div class="gap-x-2">
              Envoyer
            </div>
          </base-button>
        </div>
      </div>
      <div class="flex flex-row items-center pt-8">
        <div
          class="flex text-l text-primary items-center"
        >
          Pour prendre rendez-vous avec le service client, c'est ici
        </div>
        <div class="flex items-center pl-4">
          <base-button
            @click="onContact"
          >
            <div
              class="flex text-l text-primary font-bold items-center"
            >
              Prendre rendez-vous
              <icon name="mdi-phone" color="primary" class="pl-2" />
            </div>
          </base-button>
        </div>
      </div>
      <div class="flex flex-row items-center pt-4">
        <div
          class="rounded-md text-primary"
        >
          Pour télécharger notre module de maintenance, c'est là
        </div>
        <div
          class="flex justify-center pl-4"
        >
          <base-button
            class="!bg-orange-light hover:!bg-orange-light/80"
            @click="onDownload"
          >
            <div
              class="flex text-l text-primary font-bold items-center"
            >
              Télémaintenance
              <img
                class="ml-2 w-8 h-8"
                :src="pictoDownloadBlue"
              >
            </div>
          </base-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import {
    hubspotApi
} from '@/container';
import BaseButton from '@/core/components/base/BaseButton.vue';
import pictoDownloadBlue from '@/assets/icons/picto-download-blue.svg';
import {mapActions} from "pinia";
import {useToastStore} from "@/stores/toast.store";
import Icon from "@/core/components/icon/Icon.vue";

const linkScreenConnect = 'http://files.santesecure.com/ConnectWiseControl.ClientSetup.exe';

export default defineComponent({
    components: {Icon, BaseButton},
    data() {
        return {
            pictoDownloadBlue: pictoDownloadBlue,
            message: null
        };
    },
    methods: {
        ...mapActions(useToastStore, ['showSuccess', 'showError']),
        onSend() {
            if (!this.message) return;

            return hubspotApi().createTicket(this.message).then(response => {
                if (response.success) {
                    this.message = null;
                    this.showSuccess({content: 'Votre message a bien été envoyé à notre équipe, nous regardons au plus vite 🙂'});
                } else {
                    this.showError({content: 'Une erreur s\'est produite lors de l\'envoi du message'});
                }
            });
        },
        /**
         * On download press.
         */
        onDownload() {
            window.open(linkScreenConnect, '_self');
        },
        /**
         * On contact press.
         */
        onContact() {
            window.open(import.meta.env.VITE_SUPPORT_URL);
        }
    }
});
</script>
