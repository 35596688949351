<template>
  <div
    class="absolute top-0 p-2 text-white bg-gradient-to-r from-darkPurple-default to-darkBlue-default"
    style="width: 80%; height: auto; z-index: 99"
  >
    <section class="flex flex-row cursor-pointer">
      <button
        class="flex w-8 items-start justify-start"
        @click="close"
      >
        <icon
          :name="'mdi-close'"
          :size="20"
          :color="'#ffffff'"
        />
      </button>
      <div class="pt-3">
        <CustomerSummary
          :customer="customer"
          :active="true"
        />
      </div>
      <div class="flex flex-1 justify-end p-3 items-center pr-5">
        <CustomerCertificationHeader
          :customer="customer"
          class="self-end"
        />
        <div
          v-if="customer.certification?.validated_at"
          class="text-white text-sm ml-3"
        >
          <div>Patient Apodis</div>
          <div>Depuis le {{ validatedAt }}</div>
          <div v-if="customer.certification.phone">
            ({{ $filters.phoneFormat(customer.certification.phone) }})
          </div>
        </div>
      </div>
    </section>
    <section class="px-7 py-5 flex flex-col space-y-3">
      <div class="flex flex-row space-x-3">
        <CustomerInformationBlock
          label="Adresse"
          :value="address"
        />
        <CustomerInformationBlock
          label="Téléphone"
          :value="customer.information.phone || customer.information.mobile"
        />
        <CustomerInformationBlock
          label="Email"
          :value="customer.information.email"
        />
      </div>
      <div class="flex flex-row space-x-3">
        <div class="flex flex-1">
          <CustomerInformationBlock
            label="Caisse primaire"
            :value="customer.information.fund_primary"
          />
        </div>
        <div class="flex flex-2">
          <CustomerInformationBlock
            label="Caisse complémentaire"
            :value="customer.information.fund_complementary"
          />
        </div>
      </div>
      <div class="flex flex-row space-x-3">
        <CustomerInformationBlock
          label="Médecin traitant"
          :value="customer.last_prescriptor && customer.last_prescriptor.name"
        />
      </div>
      <div class="flex flex-row space-x-3">
        <CustomerInformationBlock
          label="Groupe sanguin"
          :value="customer.user_information && customer.user_information.blood_group"
        />
        <CustomerInformationBlock
          label="Poids"
          :value="customer.user_information && customer.user_information.weight"
        />
        <CustomerInformationBlock
          label="Taille"
          :value="customer.user_information && customer.user_information.height"
        />
      </div>
    </section>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from "vue";
import CustomerSummary from '@/customer/components/customer-summary/CustomerSummary.vue';
import CustomerCertificationHeader from '@/customer-certification/components/CustomerCertificationHeader.vue';
import dateHelper from '@/core/helpers/dateHelper';
import CustomerInformationBlock from '@/customer/components/customer-information/CustomerInformationBlock.vue';
import Icon from '@/core/components/icon/Icon.vue';

export default defineComponent({
    name: 'CustomerInformationModal',
    components: { CustomerInformationBlock, CustomerCertificationHeader, CustomerSummary, Icon },
    props: {
        customer: {
            type: Object as PropType<any>,
            required: true
        }
    },

    computed: {
        validatedAt () {
            return this.customer.certification && dateHelper.shortFormat(new Date(this.customer.certification.validated_at));
        },
        address () {
            const address = (this.customer.information.address_street || '') + ', ' +
        ((this.customer.information.postal_code && this.customer.information.postal_code.replace(' ', '')) || '') +
        ' ' + (this.customer.information.city || '');

            return address.replace(' ', '').length > 2 ? address : undefined;
        }
    },

    methods: {
        close () {
            window.dispatchEvent(new Event('information-closed'));
        }
    }
});
</script>
