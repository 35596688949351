<script setup lang="ts">
import {usePatientStore} from "@/stores/patient.store";
import DrugInteractionPatientSummary from "@/drug-interaction/components/DrugInteractionPatientSummary.vue";
import {ref} from 'vue';
import DrugInteractionPatientForm from "@/drug-interaction/components/DrugInteractionPatientForm.vue";
import BaseSpinner from "@/core/components/base/spinner/BaseSpinner.vue";
import CustomerInformation from "@/core/interfaces/CustomerInformation";
import {VidalAllergies, VidalPathologies} from "@/customer/interfaces/Patient";
import {CustomerDeclaredInformation} from "@/customer/interfaces/CustomerDeclaredInformation";

const {customer, patchCustomer} = usePatientStore();
const editProfile = ref(false);
const loading = ref<boolean>(false);
const profile = ref<Profile>();

interface Profile extends Partial<CustomerDeclaredInformation> {
    pathologies: Omit<VidalPathologies[], 'id'>;
    allergies: Omit<VidalAllergies[], 'id'>;
}

/**
 * Get patient profile.
 */
const readProfile = () => {
    if (customer?.id && profile) {
        profile.value = {
            ...customer.declared_information,
            allergies: customer.vidal_allergies,
            pathologies: customer.vidal_pathologies
        };
    }
};

/**
 * Update patient profile.
 * @param newProfile
 */
const updateProfile = (newProfile: any) => {
    profile.value = newProfile;
    editProfile.value = false;
    if (customer?.id) {
        const {pathologies, allergies, ...declaredInformation} = newProfile;
        let information: Partial<CustomerInformation> = {};

        Object.keys(declaredInformation).forEach(key => {
            information[key] = declaredInformation[key].value;
        });

        loading.value = true;
        patchCustomer(customer.id, information, allergies.value, pathologies.value)
            .finally(() => loading.value = false);
    }
};

readProfile();
</script>

<template>
  <div class="flex flex-col">
    <div class="flex flex-row">
      <p>Profil du patient</p>
      <BaseSpinner v-if="loading" size="small" class="ml-4" />
      <drug-interaction-patient-summary
        v-if="editProfile && customer && !loading"
        class="pl-2"
        :customer="customer"
        :profile="profile"
        :editable="false"
      />
    </div>
    <div v-if="customer && !loading">
      <drug-interaction-patient-summary
        v-if="!editProfile"
        :customer="customer"
        :profile="profile"
        @edit-profile="() => editProfile=true"
      />
      <drug-interaction-patient-form
        v-if="editProfile"
        :customer="customer"
        :profile="profile"
        @on-finish="updateProfile"
      />
    </div>
  </div>
</template>
