<template>
  <div class="h-full flex flex-col">
    <nav class="flex space-x-2 bg-white">
      <router-link
        :to="{name: 'disruption-logistics.home'}"
        active-class="nav-item-active"
        class="nav-item"
      >
        Info dispo
      </router-link>
      <router-link
        :to="{name: 'disruption-logistics.direct-availability-sources'}"
        active-class="nav-item-active"
        class="nav-item"
      >
        Disponibilités ventes directes
      </router-link>
      <router-link
        :to="{name: 'disruption-logistics.disruptions'}"
        active-class="nav-item-active"
        class="nav-item"
      >
        Retours de dispo
      </router-link>
      <router-link
        :to="{name: 'disruption-logistics.disruption-alerts'}"
        active-class="nav-item-active"
        class="nav-item"
      >
        Alertes et dépannages
      </router-link>
    </nav>
    <router-view :key="$route.path" />
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import {useDisruptionLogisticsRequestStore} from "@/stores/disruptionLogisticsRequest.store";

export default defineComponent({
    name: "DisruptionLogisticsNavigationView",
    /**
   * On mount, load the disruption logistics requests
   */
    mounted() {
        const disruptionLogisticsStore = useDisruptionLogisticsRequestStore();

        disruptionLogisticsStore.loadRemainingRequests();
    },
});
</script>

<style scoped>
.nav-item {
  @apply border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap py-2 px-3 border-b-2 font-medium
}

.nav-item-active {
  @apply border-primary text-primary
}
</style>
