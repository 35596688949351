import {AxiosInstance} from "axios";

import {Warehouse, WholesalerWarehouseHealthcenter} from "@/disruption-logistics/interfaces/wholesalers";

/**
 * The wholesaler resource.
 *
 * @param {Object} client - The injected client.
 * @returns {Object} The public object.
 */
export default function (client: AxiosInstance) {
    return {
        /**
         * Get the current healthcenter warehouses.
         */
        async getCurrentHealthcenterWarehouses(params = {}): Promise<WholesalerWarehouseHealthcenter[]> {
            return client.get('wholesalers/warehouses/healthcenters', {params})
                .then(response => response.data);
        },

        /**
         * Get the wholesaler warehouses.
         */
        async getWholesalerWarehouses(params = {}): Promise<Warehouse[]> {
            return client.get('wholesalers/warehouses/all', {params})
                .then(response => response.data.wholesalerWarehouses);
        },

        /**
         * Path a healthcenter warehouse.
         */
        async patchHealthcenterWarehouse(
            healthcenterWarehouseId: number,
            wholesalerId: number,
            warehouseId: number,
            rank: number
        ): Promise<WholesalerWarehouseHealthcenter> {
            return client.put(`/wholesalers/${wholesalerId}/warehouses/${warehouseId}/healthcenters/${healthcenterWarehouseId}?rank=${rank}&include=wholesaler`)
                .then((response) => response.data);
        },

        /**
         * Test a credential.
         */
        async testCredential (credentialId: number, warehouseId: number): Promise<{success: boolean}> {
            return client.get(`wholesalers/credentials/${credentialId}/warehouses/${warehouseId}/test`)
                .then(response => response.data.data);
        }
    };
}
