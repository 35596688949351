<template>
  <div class="flex flex-row">
    <div
      class="flex flex-1 gap-x-3 items-center"
    >
      <img
        v-if="loyaltyCard.file"
        class="rounded-md"
        :src="`${loyaltyCard.file.url}&h=${100}`"
        :alt="`${loyaltyCard.file.name}`"
        @click="$emit('click')"
      >
      <div class="flex flex-col">
        <p class="text-primary text-xl font-bold">
          {{ loyaltyCard.name }}
        </p>
        <p>
          {{ loyaltyCard.organization.name }}
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
export default defineComponent({
    props: {
        loyaltyCard: {
            type: Object,
            required: true
        }
    }
});
</script>
