<script setup lang="ts">
import {onMounted, ref} from "vue";
import {stripeApi} from "@/container";
import BaseFlexSpinner from "@/core/components/base/spinner/BaseFlexSpinner.vue";
import BaseButton from "@/core/components/base/BaseButton.vue";

const stripeUrl = ref<string>();
const loading = ref(true);

/**
 * On mount fetch billing portal.
 */
onMounted(() => {
    stripeApi().billingPortal()
        .then(portal => {
            stripeUrl.value = portal.url;
            openBillingPage(portal.url);
        })
        .finally(() => loading.value = false);
});

/**
 * Open billing page.
 *
 * @param url Billing url.
 */
const openBillingPage = (url: string) => {
    window.open(url, '_blank', 'height=750,width=1000');
};
</script>

<template>
  <div class="flex flex-1">
    <base-flex-spinner v-if="loading" />
    <div v-else class="flex flex-1 justify-center items-center">
      <div v-if="stripeUrl">
        <base-button primary @click="openBillingPage(stripeUrl)">
          Voir les factures
        </base-button>
      </div>
      <p v-else class="p-6">
        Vos factures n'ont pas pu être chargés correctement.
      </p>
    </div>
  </div>
</template>
