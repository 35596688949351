<template>
  <installation-step-layout @open-guide="$emit('openGuide')">
    <template #title>
      Installer Apodis Secure
    </template>
    <template #subtitle>
      La sécurité des données avant tout !
    </template>
    <template #image>
      <img
        :src="cloudDataIcon"
        alt="image cloud"
        class="h-24 xl:h-32"
      >
    </template>
    <template #content>
      <p class="text-primary">
        Votre LGO est <strong>{{ lgoName }}</strong>,
      </p>
      <p v-if="lgoInstall === 'client'">
        Veuillez télécharger et installer Santé Secure sur un seul <strong>poste comptoir</strong>.
      </p>
      <p v-if="lgoInstall === 'server'">
        Veuillez télécharger et installer Santé Secure sur votre <strong>serveur</strong>.
      </p>
      <p v-if="lgoInstall === 'support'">
        Contactez-nous pour prendre rendez-vous avec <strong>Marion</strong> qui vous accompagnera ->
        <a
          target="_blank"
          :href="supportUrl"
          style="color: blue!important"
        >
          Prendre rendez-vous
        </a>
      </p>
    </template>
    <template
      v-if="lgoInstall !== 'support'"
      #download
    >
      <DownloadButton
        title="Apodis Secure"
        :icon="logoSanteSecure"
        @click="$emit('downloadApodisSecure')"
      />
    </template>
  </installation-step-layout>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import DownloadButton from '@/onboarding/components/download-button/DownloadButton.vue';
import InstallationStepLayout from '@/onboarding/components/installation-process/InstallationStepLayout.vue';
import logoSanteSecure from '@/assets/icons/logo_sante_secure.png';
import cloudDataIcon from '@/assets/icons/cloud-data.svg';

export default defineComponent({
    name: 'ApodisSecureStep',
    components: {InstallationStepLayout, DownloadButton},
    props: {
        lgoName: {
            type: String,
            required: true
        },
        lgoInstall: {
            type: String,
            required: true
        }
    },
    emits: ['openGuide', 'downloadApodisSecure'],
    data() {
        return {
            logoSanteSecure,
            cloudDataIcon,
            supportUrl: import.meta.env.VITE_SUPPORT_URL
        };
    }
});
</script>
