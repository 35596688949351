<template>
  <div class="mt-4 container mx-auto">
    <v-data-table
      :headers="headers"
      :items="items"
      :items-per-page="meta.size"
      :disable-sort="true"
      :server-items-length="meta.total"
      :loading-text="'Chargement en cours...'"
      :loading="loading"
      no-data-text="Aucun patients"
      :footer-props="{
        'items-per-page-text': 'Éléments par page',
        'page-text': '{0}-{1} sur {2}',
        'total-items': 'Total des éléments'
      }"
      class="elevation-1 cursor-pointer"
      @update:page="onPageChange"
      @click:row="handleClick"
    />
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
/**
 * Simple table to display the consumers of a program
 */
export default defineComponent({
    name: 'ProgramConsumerTable',
    props: {
        consumers: {
            type: Array<any>,
            required: true
        },
        meta: {
            type: Object,
            required: true
        },
        loading: {
            type: Boolean,
            required: true
        }
    },
    emits: ['click', 'page-change'],
    computed: {
    /**
     * The table header configuration.
     *
     * @return {[{text: string, value: string}]}
     */
        headers () {
            return [{
                text: `Patients ${this.meta.total ? '(' + this.meta.total + ')' : ''}`,
                value: 'name'
            }];
        },
        /**
     * The table items.
     *
     * @return {Array}
     */
        items () {
            return this.consumers.map((consumer) => {
                return {
                    name: consumer.name,
                    key: consumer.key
                };
            });
        }
    },
    methods: {
    /** Emit the page change event to the parent */
        onPageChange (page) {
            this.$emit('page-change', page);
        },
        /**
     * Handle when consumer is clicked.
     *
     * @param row The consumer row.
     */
        handleClick (row) {
            this.$emit('click', row.key);
        }
    }
});
</script>
