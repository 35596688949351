<template>
  <program-consumer-table
    :meta="consumers.meta"
    :loading="loading"
    :consumers="consumers.data"
    @page-change="handlePageChange"
    @click="handleClick"
  />
</template>

<script lang="ts">
import {defineComponent} from "vue";
import { programApi } from '@/container';
import ProgramConsumerTable from '@/program/components/program-consumers/ProgramConsumerTable.vue';

export default defineComponent({
    name: 'ProgramBundleProgramCategoryConsumers',
    components: { ProgramConsumerTable },
    data () {
        return {
            consumers: {
                meta: {},
                data: []
            },
            loading: false
        };
    },
    watch: {
        '$route.params': {
            immediate: true,
            handler (value) {
                this.indexConsumers(value.programBundleId, value.programCategoryId);
            }
        }
    },
    methods: {
    /**
     * Index consumers for a bundle category.
     *
     * @param {string} bundleId - The bundle id.
     * @param {string} programId - The program id.
     * @param {number} page - The page number.
     * @param {number} limit - The limit.
     *
     * @return {*}
     */
        indexConsumers (bundleId, programId, page = 0, limit = 20) {
            this.loading = true;
            return programApi()
                .bundleCategoryConsumers(bundleId, programId, page, limit)
                .then(consumers => {
                    this.consumers = consumers;
                })
                .finally(() => this.loading = false);
        },
        /**
     * Handle page change.
     *
     * @param {number} page - The page number to load.
     */
        handlePageChange (page) {
            this.indexConsumers(this.$route.params.programBundleId, this.$route.params.programCategoryId, page - 1);
        },
        /**
     * Handle when a consumer row is pressed.
     *
     * @param {string} key - The consumer key.
     */
        handleClick (key) {
            if (key.startsWith('b_')) {
                // We need the customer belonging the beneficiary to navigate to beneficiary page.
                return;
            }

            this.$router.push({
                name: 'customer.home',
                params: {
                    customerId: key.slice(2)
                }
            });
        }
    }
});
</script>
