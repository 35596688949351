<template>
  <Select>
    <SelectTrigger class="rounded-lg bg-gray-50 w-max-content" :class="triggerClass">
      <SelectValue :placeholder="placeholder" />
    </SelectTrigger>
    <SelectContent>
      <SelectGroup>
        <SelectItem v-for="option in options" :key="option.value" :value="option.value">
          {{ option.label }}
        </SelectItem>
      </SelectGroup>
    </SelectContent>
  </Select>
</template>

<script lang="ts" setup>
import {Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue} from "@/core/components/ui/select";
import {PropType} from "vue";

defineProps({
    options: {
        type: Array as PropType<Array<{ value: string, label: string }>>,
        required: true
    },
    placeholder: {
        type: String,
        default: 'Choisir ...'
    },
    triggerClass: {
        type: String,
        default: ''
    },
});
</script>
