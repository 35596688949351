import {AxiosInstance} from "axios";

export default function (client: AxiosInstance) {
    return {
    /**
     * Get the credit balance for the current organization.
     *
     * @return {Promise<Object>}
     */
        getBalance () {
            return client
                .get('/organization/credit')
                .then(response => response.data.data);
        }
    };
}
