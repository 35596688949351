<template>
  <div class="flex flex-col lg:flex-row gap-x-14 gap-y-6">
    <div class="flex flex-col">
      <label
        class="font-content-bold text-4xl"
      >{{ availabilityCount ?? '0' }}</label>
      <label>{{ $filters.pluralize('agence', availabilityCount) }} ont ce produit</label>
    </div>
    <div class="flex flex-col text-primary">
      <label class="font-content-bold text-4xl">{{ membersAvailabilityCount ?? '0' }}</label>
      <label>
        {{ $filters.pluralize('pharmacie', membersAvailabilityCount) }} {{ $filters.pluralize('partenaire', membersAvailabilityCount) }}
        peuvent commander ce produit
      </label>
    </div>
  </div>
</template>

<script lang="ts">
import {PropType, defineComponent} from "vue";
import {HealthcenterGroupDistributorDetails} from "@/disruption-logistics/interfaces/availability-providers";

export default defineComponent({
    name: 'DistributorMapStatistics',
    props: {
        availabilities: {
            type: Array as PropType<HealthcenterGroupDistributorDetails[]>,
            required: false,
            default: () => null
        }
    },
    computed: {
    /**
     * Get the count of the available warehouses.
     */
        availabilityCount (): number {
            return this.availableAvailabilities?.length ?? 0;
        },
        /**
     * Count the members who can order the product to his warehouses.
     */
        membersAvailabilityCount (): number {
            if (!this.availabilities?.length) {
                return 0;
            }

            const members = this.availableAvailabilities.map(warehouse => warehouse.members).flat();

            const uniqueMembers = members.filter((member, index, self) => {
                return index === self.findIndex(healthcenter => (
                    healthcenter.id === member.id
                ));
            });

            return uniqueMembers.length;
        },
        /**
     * Get the available availabilities.
     */
        availableAvailabilities (): HealthcenterGroupDistributorDetails[] {
            return this.availabilities?.filter(availability => {
                return availability.products?.some(product => product.isAvailable);
            }) ?? [];
        }
    }
});
</script>

<style scoped>

</style>
