<template>
  <div class="flex flex-col gap-4">
    <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
      <direct-product-availability-card
        :data="directAvailability"
        class="md:col-span-2"
      />
      <healthcenter-group-product-availability-card :data="groups" />
      <healthcenter-group-distributor-availability-card
        :data="groupWarehouses"
      />
    </div>
    <div class="flex flex-col md:flex-row items-start md:items-center gap-4 text-lg flex-wrap">
      <div class="flex gap-3">
        <v-icon
          size="30"
          color="black"
        >
          mdi-cart
        </v-icon>
        <label>
          Marketplaces
        </label>
      </div>
      <div class="flex bg-white rounded-md px-4 py-2 items-center gap-4">
        <img
          src="@/assets/medidestock.png"
          alt="medidestock"
          style="height: 35px"
        >
        <div class="flex flex-col justify-center relative top-0.5">
          <p class="text-sm leading-none">
            {{
              medidestock.loading ? 'Interrogation de Medidestock' : `${availabilityLabel(medidestock)} chez Medidestock`
            }}
          </p>
          <div class="text-primary">
            <a
              class="text-sm"
              target="_blank"
              href="https://medi-destock.com/"
            >
              Devenir client
            </a>
          </div>
        </div>
      </div>
      <div class="flex bg-white rounded-md px-4 py-2 items-center gap-4">
        <le-comptoir-des-pharmacies />
        <div class="relative top-0.5">
          <p class="text-sm leading-none">
            {{
              comptoirDesPharmacies.loading ? 'Interrogation de Le comptoir des pharmacies' : `${availabilityLabel(medidestock)} chez Le comptoir des pharmacies`
            }}
          </p>
          <div class="text-primary">
            <a
              class="text-sm"
              target="_blank"
              href="https://medi-destock.com/"
            >
              Devenir client
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import LeComptoirDesPharmacies from "@/disruption-logistics/components/LeComptoirDesPharmaciesIcon.vue";
import {disruptionLogisticsApi} from "@/container";
import {AVAILABILITY_PROVIDERS} from "@/core/http/resources/DisruptionLogisticsResource";
import {defineComponent} from "vue";

import {
    DepositaryDetails,
    HealthcenterGroupDistributorDetails
} from "@/disruption-logistics/interfaces/availability-providers";
import DirectProductAvailabilityCard
    from "@/disruption-logistics/components/product-availability-card/DirectProductAvailabilityCard.vue";
import HealthcenterGroupProductAvailabilityCard
    from "@/disruption-logistics/components/product-availability-card/HealthcenterGroupProductAvailabilityCard.vue";
import HealthcenterGroupDistributorAvailabilityCard
    from "@/disruption-logistics/components/product-availability-card/HealthcenterGroupDistributorAvailabilityCard.vue";
import {HealthcenterGroup} from "@/disruption-logistics/interfaces/healthcenter-groups";

export type AvailabilityState<T> = {
  loading: boolean,
  availability: boolean | null,
  messages: string[] | null
  details: T
}

export default defineComponent({
    name: 'ProductAvailabilityCardGrid',
    components: {
        HealthcenterGroupDistributorAvailabilityCard,
        HealthcenterGroupProductAvailabilityCard,
        DirectProductAvailabilityCard, LeComptoirDesPharmacies
    },
    props: {
        productId: {
            type: Number,
            required: true
        },
        requestToken: {
            type: String,
            required: true
        }
    },
    emits: ['availability-loaded'],
    data() {
        return {
            medidestock: {
                loading: true,
                availability: null,
                messages: null
            } as AvailabilityState<undefined>,
            groups: {
                loading: true,
                availability: null,
                messages: null
            } as AvailabilityState<HealthcenterGroup[]>,
            directAvailability: {
                loading: true,
                availability: null,
                messages: null
            } as AvailabilityState<DepositaryDetails>,
            comptoirDesPharmacies: {
                loading: true,
                availability: null,
                messages: null
            } as AvailabilityState<undefined>,
            groupWarehouses: {
                loading: true,
                availability: null,
                messages: null
            } as AvailabilityState<HealthcenterGroupDistributorDetails[]>
        };
    },
    watch: {
        productId: {
            immediate: true,
            handler(productId) {
                this.loadAvailabilityAndEmit(AVAILABILITY_PROVIDERS.medidestock, productId);
                this.loadAvailabilityAndEmit(AVAILABILITY_PROVIDERS.leComptoirDesPharmacies, productId, 'comptoirDesPharmacies');
                this.loadAvailabilityAndEmit(AVAILABILITY_PROVIDERS.healthcenterGroups, productId, 'groups');
                this.loadAvailabilityAndEmit(AVAILABILITY_PROVIDERS.direct, productId, 'directAvailability');
                this.loadAvailabilityAndEmit(AVAILABILITY_PROVIDERS.healthcenterGroupsDistributor, productId, 'groupWarehouses');
            }
        }
    },
    methods: {
        async loadAvailabilityAndEmit(provider: string, productId: number, alias: string | null = null): Promise<void> {
            return this.loadAvailability(provider, productId, alias)
                .then((data) => {
                    this.$emit('availability-loaded', {provider, data});
                });
        },

        /**
     * Load the availability of the product from the provider.
     *
     * @param provider - The provider name
     * @param productId - The product id
     * @param alias - The state alias
     *
     * @returns The availability data
     */
        async loadAvailability(provider: string, productId: number, alias: null | string = null) {
            const key = alias ?? provider;
            this[key].loading = true;
            return disruptionLogisticsApi()
                .productAvailability(provider, productId, this.requestToken)
                .then((availability) => {
                    this[key].availability = availability.is_available;
                    this[key].messages = availability.messages ?? null;
                    this[key].details = availability.details ?? null;

                    return availability;
                })
                .finally(() => {
                    this[key].loading = false;
                });
        },
        /**
     * Get the availability label.
     */
        availabilityLabel(provider: AvailabilityState<unknown>) {
            if (provider.availability) {
                return 'disponible';
            } else if (!provider.availability) {
                return 'indisponible';
            }
        }
    },
});
</script>
