<template>
  <svg
    width="40px"
    height="40px"
    viewBox="0 0 54 53"
    xmlns="http://www.w3.org/2000/svg"
  >
    <!-- Generator: Sketch 60.1 (88133) - https://sketch.com -->
    <title>lcdp-logotype-violet</title>
    <desc>Created with Sketch.</desc>
    <g
      id="Symbols"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="Atoms-/-Logos-/-LCDP-dark"
        fill-rule="nonzero"
      >
        <g id="lcdp-logotype-violet">
          <path
            id="Path"
            d="M0.75,2 L0.75,50.8999718 C0.75,51.5903278 1.30964406,52.1499718 2,52.1499718 L52,52.1499718 C52.6903559,52.1499718 53.25,51.5903278 53.25,50.8999718 L53.25,2 C53.25,1.30964406 52.6903559,0.75 52,0.75 L2,0.75 C1.30964406,0.75 0.75,1.30964406 0.75,2 Z"
            stroke="#1E266D"
            stroke-width="1.5"
            fill="transparent"
          />
          <g
            id="Group"
            transform="translate(7.144869, 9.171026)"
            fill="#1E266D"
          >
            <polygon
              id="Path"
              points="0.379851107 5.46891751 0.379851107 0.0938430584 1.18615493 0.0938430584 1.18615493 4.67807646 3.86601408 4.67807646 3.86601408 5.46891751"
            />
            <path
              id="Shape"
              d="M7.9090503,3.7335674 L5.24465392,3.7335674 C5.32911268,4.50915895 5.76676258,4.8393159 6.25826559,4.8393159 C6.5961006,4.8393159 6.86493964,4.7164668 7.13367203,4.51694366 L7.60214085,5.02369618 C7.2489497,5.36153119 6.8265493,5.55358954 6.21219718,5.55358954 C5.26779477,5.55358954 4.46906237,4.79335412 4.46906237,3.45726358 C4.46906237,2.0905674 5.19090744,1.35325956 6.23523139,1.35325956 C7.37947686,1.35325956 7.93229779,2.28241247 7.93229779,3.37291147 C7.93219115,3.51858149 7.91672837,3.64921529 7.9090503,3.7335674 Z M6.18137827,2.06731992 C5.65159155,2.06731992 5.32911268,2.43597384 5.25990342,3.08103823 L7.13356539,3.08103823 C7.09528169,2.52800402 6.83412072,2.06731992 6.18137827,2.06731992 Z"
            />
            <path
              id="Path"
              d="M11.5949497,11.7681328 C11.2647928,11.4302978 10.9730262,11.2766298 10.6120503,11.2766298 C10.0361952,11.2766298 9.66775453,11.7912736 9.66775453,12.6127203 C9.66775453,13.4037746 10.0055895,13.9719517 10.6351911,13.9719517 C11.0113099,13.9719517 11.2341871,13.8030342 11.5488813,13.472664 L12.0556338,13.9719517 C11.7409396,14.3712113 11.3416801,14.7245091 10.6120503,14.7245091 C9.62915091,14.7245091 8.86891549,13.895171 8.86891549,12.6358612 C8.86891549,11.2997706 9.65229175,10.5240724 10.6350845,10.5240724 C11.2264024,10.5240724 11.6870865,10.7620926 12.0633119,11.2076338 L11.5949497,11.7681328 Z"
            />
            <path
              id="Shape"
              d="M14.392006,14.7245091 C13.3782877,14.7245091 12.6180523,13.8873863 12.6180523,12.6281831 C12.6180523,11.3074487 13.4014286,10.5241791 14.4149336,10.5241791 C15.444008,10.5241791 16.2118149,11.345839 16.2118149,12.6051489 C16.2117082,13.9411328 15.4286519,14.7245091 14.392006,14.7245091 Z M14.384328,11.2766298 C13.785332,11.2766298 13.4168913,11.8065231 13.4168913,12.6050423 C13.4168913,13.4881268 13.823829,13.9719517 14.4227183,13.9719517 C15.0217143,13.9719517 15.4132958,13.3652777 15.4132958,12.6280765 C15.4131891,11.8064165 15.0216076,11.2766298 14.384328,11.2766298 Z"
            />
            <path
              id="Path"
              d="M21.7838531,14.6399437 L21.7838531,12.3594507 C21.7838531,11.5454688 21.5305835,11.2537022 21.0084748,11.2537022 C20.4785815,11.2537022 20.2328833,11.6298209 20.2328833,12.2825634 L20.2328833,14.639837 L19.4420423,14.639837 L19.4420423,12.3593441 C19.4420423,11.5453622 19.1885594,11.2535956 18.6664507,11.2535956 C18.1365573,11.2535956 17.8908592,11.6297143 17.8908592,12.2824567 L17.8908592,14.6397304 L17.1000181,14.6397304 L17.1000181,10.6084245 L17.8908592,10.6084245 L17.8908592,10.992328 C18.0905956,10.7005614 18.4361087,10.5238592 18.850831,10.5238592 C19.3958672,10.5238592 19.7413803,10.7312736 19.9565795,11.1151771 C20.2483461,10.7542012 20.5631469,10.5238592 21.1927485,10.5238592 C22.1295795,10.5238592 22.5749074,11.1383179 22.5749074,12.3284185 L22.5749074,14.6397304 L21.7838531,14.6397304 L21.7838531,14.6399437 Z"
            />
            <path
              id="Shape"
              d="M25.4375473,14.7245091 C25.0306097,14.7245091 24.6467062,14.5095231 24.4240423,14.2638249 L24.4240423,15.7150865 L23.640666,16.1220241 L23.640666,10.6086378 L24.4240423,10.6086378 L24.4240423,10.9925412 C24.7158089,10.7238089 25.0536439,10.5240724 25.475831,10.5240724 C26.3435594,10.5240724 27.0271207,11.1538873 27.0271207,12.6896076 C27.0271207,14.0717666 26.2745634,14.7245091 25.4375473,14.7245091 Z M25.3300543,11.2537022 C24.9537223,11.2537022 24.6235654,11.5069718 24.4163642,11.7681328 L24.4163642,13.4651992 C24.5929598,13.703006 24.9614004,13.9948793 25.36066,13.9948793 C25.9290503,13.9948793 26.2360664,13.5650141 26.2360664,12.7127485 C26.2360664,11.6990302 25.9135875,11.2537022 25.3300543,11.2537022 Z"
            />
            <path
              id="Path"
              d="M29.8161791,14.5401288 C29.624334,14.6476217 29.355495,14.7245091 29.0408008,14.7245091 C28.4800885,14.7245091 28.1345755,14.378996 28.1345755,13.6570443 L28.1345755,11.3303763 L27.5587203,11.3303763 L27.5587203,10.6086378 L28.1345755,10.6086378 L28.1345755,9.45682093 L28.910167,9.04209859 L28.910167,10.6085312 L29.9084225,10.6085312 L29.9084225,11.3302696 L28.910167,11.3302696 L28.910167,13.518839 C28.910167,13.8949577 29.0330161,14.0024507 29.3248893,14.0024507 C29.5320905,14.0024507 29.7625392,13.9257767 29.9085292,13.8412113 L29.8161791,14.5401288 Z"
            />
            <path
              id="Shape"
              d="M32.3016338,14.7245091 C31.2881288,14.7245091 30.5278934,13.8873863 30.5278934,12.6281831 C30.5278934,11.3074487 31.3110563,10.5241791 32.3247746,10.5241791 C33.3536358,10.5241791 34.1216559,11.345839 34.1216559,12.6051489 C34.1215493,13.9411328 33.3382797,14.7245091 32.3016338,14.7245091 Z M32.2939557,11.2766298 C31.695173,11.2766298 31.3265191,11.8065231 31.3265191,12.6050423 C31.3265191,13.4881268 31.7334567,13.9719517 32.3325594,13.9719517 C32.9313421,13.9719517 33.3231368,13.3652777 33.3231368,12.6280765 C33.3230302,11.8064165 32.9312354,11.2766298 32.2939557,11.2766298 Z"
            />
            <path
              id="Shape"
              d="M35.4118913,10.0864225 C35.1278028,10.0864225 34.8974608,9.85608048 34.8974608,9.57967002 C34.8974608,9.29558149 35.1278028,9.06523944 35.4118913,9.06523944 C35.6883018,9.06523944 35.9186439,9.29558149 35.9186439,9.57967002 C35.9186439,9.85608048 35.6883018,10.0864225 35.4118913,10.0864225 Z M35.0126318,14.6399437 L35.0126318,10.6086378 L35.8034728,10.6086378 L35.8034728,14.6399437 L35.0126318,14.6399437 Z"
            />
            <path
              id="Path"
              d="M39.0859537,11.4457606 C38.9170362,11.3535171 38.7404406,11.2997706 38.4793863,11.2997706 C37.9800986,11.2997706 37.688332,11.6529618 37.688332,12.3364165 L37.688332,14.6400503 L36.8974909,14.6400503 L36.8974909,10.6087445 L37.688332,10.6087445 L37.688332,10.9926479 C37.8727123,10.7239155 38.2104406,10.5241791 38.6100201,10.5241791 C38.9323924,10.5241791 39.1321288,10.5856036 39.2933682,10.6933099 L39.0859537,11.4457606 Z"
            />
            <path
              id="Shape"
              d="M2.69105634,23.8109698 L2.69105634,23.4270664 C2.39928974,23.6957988 2.06145473,23.8955352 1.63905433,23.8955352 C0.771325956,23.8955352 0.0879778672,23.2659336 0.0879778672,21.73 C0.0879778672,20.347841 0.840535211,19.6950986 1.67744467,19.6950986 C2.08438229,19.6950986 2.46839235,19.9102978 2.69105634,20.1557827 L2.69105634,18.620169 L3.47432596,18.2132314 L3.47432596,23.8109698 L2.69105634,23.8109698 Z M2.69884105,20.9546217 C2.52224547,20.7166016 2.15359155,20.4247284 1.75433199,20.4247284 C1.18615493,20.4247284 0.879032193,20.8545936 0.879032193,21.7070724 C0.879032193,22.7205775 1.20140443,23.1659054 1.78504427,23.1659054 C2.16126962,23.1659054 2.4915332,22.9126358 2.69884105,22.6514748 L2.69884105,20.9546217 Z"
            />
            <path
              id="Shape"
              d="M7.81552716,22.0756197 L5.15102414,22.0756197 C5.2354829,22.8512113 5.6731328,23.1813682 6.16463581,23.1813682 C6.50257746,23.1813682 6.77120322,23.0585191 7.03993561,22.858996 L7.50840443,23.3657485 C7.15521328,23.7035835 6.73302616,23.8956419 6.1185674,23.8956419 C5.17416499,23.8956419 4.3754326,23.1354064 4.3754326,21.7993159 C4.3754326,20.4326197 5.09727767,19.6953119 6.14160161,19.6953119 C7.28574044,19.6953119 7.83877465,20.6244648 7.83877465,21.7149638 C7.83866801,21.8606338 7.82320523,21.9912676 7.81552716,22.0756197 Z M6.08774849,20.4093722 C5.55796177,20.4093722 5.2354829,20.7780262 5.16627364,21.4230905 L7.03982897,21.4230905 C7.00154527,20.8700563 6.74059759,20.4093722 6.08774849,20.4093722 Z"
            />
            <path
              id="Path"
              d="M9.95205634,23.8955352 C9.40680684,23.8955352 8.81548893,23.6805493 8.40844467,23.3811046 L8.76942052,22.7821087 C9.14575252,23.0432696 9.56793964,23.1813682 9.94437827,23.1813682 C10.4434527,23.1813682 10.6814728,23.0201288 10.6814728,22.7360402 C10.6814728,22.4288109 10.3282817,22.2830342 9.74464185,22.0603702 C8.87691348,21.7223219 8.56221932,21.4305553 8.56221932,20.793169 C8.56221932,20.0714306 9.13039638,19.6950986 9.9367002,19.6950986 C10.4434527,19.6950986 10.9425272,19.8794789 11.3033964,20.1406398 L10.9578833,20.7472072 C10.6277264,20.5168652 10.2976761,20.4016942 9.91355936,20.4016942 C9.52965594,20.4016942 9.36073843,20.5553622 9.36073843,20.7703481 C9.36073843,20.9698712 9.49883702,21.1158612 10.1055111,21.3539879 C11.0038451,21.699501 11.4645292,21.9453058 11.4645292,22.705328 C11.4646358,23.5960905 10.7428974,23.8955352 9.95205634,23.8955352 Z"
            />
            <path
              id="Shape"
              d="M2.06145473,33.0665614 C1.6545171,33.0665614 1.27050704,32.8515755 1.04784306,32.6058773 L1.04784306,34.0571388 L0.264573441,34.4640765 L0.264573441,28.9506901 L1.04784306,28.9506901 L1.04784306,29.3345936 C1.33960966,29.0658612 1.67744467,28.8661247 2.09973843,28.8661247 C2.9674668,28.8661247 3.65092153,29.4959396 3.65092153,31.03166 C3.65092153,32.4138189 2.89847082,33.0665614 2.06145473,33.0665614 Z M1.95385513,29.5957545 C1.57762978,29.5957545 1.24747284,29.8490241 1.04016499,30.1101851 L1.04016499,31.8072515 C1.21676056,32.0450584 1.58530785,32.3369316 1.9845674,32.3369316 C2.55285111,32.3369316 2.85997384,31.9070664 2.85997384,31.0548008 C2.85997384,30.0410825 2.53749497,29.5957545 1.95385513,29.5957545 Z"
            />
            <path
              id="Path"
              d="M7.02074044,32.981996 L7.02074044,30.701503 C7.02074044,29.8875211 6.71351107,29.5957545 6.19140241,29.5957545 C5.66150905,29.5957545 5.36206439,29.9718732 5.36206439,30.6246157 L5.36206439,32.9818893 L4.57122334,32.9818893 L4.57122334,27.7987666 L5.36206439,27.3840443 L5.36206439,29.3343803 C5.5618008,29.0426137 5.96106036,28.8659115 6.3757827,28.8659115 C7.31261368,28.8659115 7.81168813,29.4803702 7.81168813,30.6704708 L7.81168813,32.9817827 L7.02074044,32.9817827 L7.02074044,32.981996 Z"
            />
            <path
              id="Shape"
              d="M11.1284004,32.981996 L11.1284004,32.5827364 C10.8517767,32.8591469 10.4601952,33.0665614 10.0226519,33.0665614 C9.36990946,33.0665614 8.62513682,32.6979074 8.62513682,31.70733 C8.62513682,30.808996 9.31616298,30.4020584 10.2299598,30.4020584 C10.6062918,30.4020584 10.9058431,30.4558048 11.128507,30.5555131 L11.128507,30.2559618 C11.128507,29.8183119 10.859668,29.5726137 10.3682716,29.5726137 C9.9535493,29.5726137 9.63117706,29.649501 9.31626962,29.8260966 L9.00904024,29.2271006 C9.39315694,28.9890805 9.82302213,28.8661247 10.3911992,28.8661247 C11.2897465,28.8661247 11.903992,29.3038813 11.903992,30.2176781 L11.903992,32.981996 L11.1284004,32.981996 L11.1284004,32.981996 Z M11.1284004,31.262002 C10.9132012,31.1545091 10.6368974,31.0854064 10.2069256,31.0854064 C9.69995976,31.0854064 9.37758753,31.3157485 9.37758753,31.6844024 C9.37758753,32.083662 9.63107042,32.3523944 10.1531791,32.3523944 C10.5753662,32.3523944 10.9440201,32.0914467 11.1284004,31.8379638 L11.1284004,31.262002 L11.1284004,31.262002 Z"
            />
            <path
              id="Path"
              d="M15.1539477,29.7878129 C14.9850302,29.6955694 14.8084346,29.6418229 14.5473803,29.6418229 C14.0480926,29.6418229 13.756326,29.9950141 13.756326,30.6784688 L13.756326,32.9821026 L12.9654849,32.9821026 L12.9654849,28.9507968 L13.756326,28.9507968 L13.756326,29.3347002 C13.9407062,29.0659678 14.2784346,28.8662314 14.6780141,28.8662314 C15.0003863,28.8662314 15.2001227,28.9276559 15.3613622,29.0353622 L15.1539477,29.7878129 Z"
            />
            <path
              id="Path"
              d="M20.7605372,32.981996 L20.7605372,30.701503 C20.7605372,29.8875211 20.5072676,29.5957545 19.985159,29.5957545 C19.4553722,29.5957545 19.2095674,29.9718732 19.2095674,30.6246157 L19.2095674,32.9818893 L18.4187264,32.9818893 L18.4187264,30.7013964 C18.4187264,29.8874145 18.1652435,29.5956479 17.6431348,29.5956479 C17.1132414,29.5956479 16.8675433,29.9717666 16.8675433,30.6245091 L16.8675433,32.9817827 L16.0767022,32.9817827 L16.0767022,28.9504769 L16.8675433,28.9504769 L16.8675433,29.3343803 C17.0672797,29.0426137 17.4127928,28.8659115 17.8275151,28.8659115 C18.3725513,28.8659115 18.718171,29.073326 18.9332636,29.4572294 C19.2250302,29.0962535 19.539831,28.8659115 20.169326,28.8659115 C21.1061569,28.8659115 21.5514849,29.4803702 21.5514849,30.6704708 L21.5514849,32.9817827 L20.7605372,32.9817827 L20.7605372,32.981996 Z"
            />
            <path
              id="Shape"
              d="M24.8766217,32.981996 L24.8766217,32.5827364 C24.599998,32.8591469 24.2084165,33.0665614 23.7708732,33.0665614 C23.1181308,33.0665614 22.3733581,32.6979074 22.3733581,31.70733 C22.3733581,30.808996 23.0643843,30.4020584 23.9781811,30.4020584 C24.3545131,30.4020584 24.6540644,30.4558048 24.8767284,30.5555131 L24.8767284,30.2559618 C24.8767284,29.8183119 24.6078893,29.5726137 24.116493,29.5726137 C23.7017706,29.5726137 23.3793984,29.649501 23.0644909,29.8260966 L22.7572616,29.2271006 C23.1413783,28.9890805 23.5712435,28.8661247 24.1394205,28.8661247 C25.0379678,28.8661247 25.6522133,29.3038813 25.6522133,30.2176781 L25.6522133,32.981996 L24.8766217,32.981996 L24.8766217,32.981996 Z M24.8766217,31.262002 C24.6614225,31.1545091 24.3851187,31.0854064 23.9551469,31.0854064 C23.4481811,31.0854064 23.1258089,31.3157485 23.1258089,31.6844024 C23.1258089,32.083662 23.3792918,32.3523944 23.9014004,32.3523944 C24.3235875,32.3523944 24.6922414,32.0914467 24.8766217,31.8379638 L24.8766217,31.262002 Z"
            />
            <path
              id="Path"
              d="M29.2384044,30.1101851 C28.9082475,29.7723501 28.6164809,29.6186821 28.255505,29.6186821 C27.6796499,29.6186821 27.310996,30.133326 27.310996,30.9547726 C27.310996,31.745827 27.648831,32.314004 28.2784326,32.314004 C28.6547646,32.314004 28.8774286,32.1450865 29.192336,31.8147163 L29.6990885,32.314004 C29.3841811,32.7132636 28.9849215,33.0665614 28.255505,33.0665614 C27.2726056,33.0665614 26.5123702,32.2372233 26.5123702,30.9779135 C26.5123702,29.6418229 27.2955332,28.8661247 28.2784326,28.8661247 C28.8697505,28.8661247 29.3304346,29.1041449 29.7068732,29.5496861 L29.2384044,30.1101851 Z"
            />
            <path
              id="Shape"
              d="M30.9297123,28.4285815 C30.6454105,28.4285815 30.4150684,28.1982394 30.4150684,27.921829 C30.4150684,27.6377404 30.6454105,27.4073984 30.9297123,27.4073984 C31.2061227,27.4073984 31.4364648,27.6377404 31.4364648,27.921829 C31.4364648,28.1982394 31.2060161,28.4285815 30.9297123,28.4285815 Z M30.5301328,32.981996 L30.5301328,28.9506901 L31.3211871,28.9506901 L31.3211871,32.981996 L30.5301328,32.981996 Z"
            />
            <path
              id="Shape"
              d="M35.6441529,31.2466459 L32.9798632,31.2466459 C33.0642153,32.0222374 33.5019718,32.3523944 33.9933682,32.3523944 C34.3312032,32.3523944 34.6000423,32.2295453 34.8687746,32.0300221 L35.3372435,32.5367746 C34.9840523,32.8746097 34.5616519,33.066668 33.9474064,33.066668 C33.0028974,33.066668 32.2042716,32.3064326 32.2042716,30.9703421 C32.2042716,29.6036459 32.9260101,28.866338 33.970334,28.866338 C35.1145795,28.866338 35.6674004,29.7954909 35.6674004,30.8859899 C35.6671871,31.03166 35.651831,31.1622938 35.6441529,31.2466459 Z M33.9163742,29.5803984 C33.3866942,29.5803984 33.0641087,29.9490523 32.9948994,30.5941167 L34.8685614,30.5941167 C34.8303843,30.0410825 34.5691167,29.5803984 33.9163742,29.5803984 Z"
            />
            <path
              id="Path"
              d="M37.7806821,33.0665614 C37.2356459,33.0665614 36.644328,32.8515755 36.2372837,32.5521308 L36.5982596,31.9531348 C36.9743783,32.2142958 37.3967787,32.3523944 37.773004,32.3523944 C38.2722918,32.3523944 38.5100986,32.1911549 38.5100986,31.9070664 C38.5100986,31.599837 38.1570141,31.4540604 37.5734809,31.2313964 C36.7057525,30.8933481 36.3908451,30.6015815 36.3908451,29.9641952 C36.3908451,29.2424567 36.9590221,28.8661247 37.765326,28.8661247 C38.2722918,28.8661247 38.7713662,29.050505 39.1322354,29.311666 L38.7867223,29.9182334 C38.4563521,29.6878913 38.1263018,29.5727203 37.7423984,29.5727203 C37.3582817,29.5727203 37.1893642,29.7263883 37.1893642,29.9413742 C37.1893642,30.1408974 37.3276761,30.2868873 37.9341368,30.5250141 C38.8326841,30.8705272 39.2933682,31.116332 39.2933682,31.8763541 C39.2933682,32.7671167 38.5715231,33.0665614 37.7806821,33.0665614 Z"
            />
            <rect
              id="Rectangle"
              x="8.35896378"
              y="4.67786318"
              width="30.9344044"
              height="1"
            />
            <rect
              id="Rectangle"
              x="0.0726217304"
              y="13.8488893"
              width="8.43691751"
              height="1"
            />
            <rect
              id="Rectangle"
              x="12.056167"
              y="23.0200221"
              width="27.2373078"
              height="1"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script lang="ts">
import {defineComponent} from "vue";

export default defineComponent({
    name: 'LeComptoirDesPharmacies',
});
</script>
