<template>
  <div class="bg-neutral p-4 h-full">
    <h2
      v-if="title"
      class="mb-4"
    >
      {{ title }}
    </h2>
    <slot />
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
export default defineComponent({
    name: 'InterviewShortCutContainer',
    props: {
        title: {
            type: String,
            required: false,
            default: null
        }
    }
});
</script>
