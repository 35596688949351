<script setup lang="ts">

import BaseContainer from "@/core/components/base/BaseContainer.vue";
import IndividualSubscriptionPlansColumn from "@/subscription-plan/components/IndividualSubscriptionPlansColumn.vue";
import GlobalSubscriptionPlanColumn from "@/subscription-plan/components/GlobalSubscriptionPlanColumn.vue";
import {onMounted, onUnmounted} from "vue";
import {useSubscriptionPlanStore} from "@/stores/subscription-plan.store";
import {useRouter} from "vue-router";

const subscriptionPlansStore = useSubscriptionPlanStore();
const router = useRouter();

/**
 * Refresh the subscriptions plans when stripe checkout success event is received.
 */
onMounted(() => {
    window.ipcRenderer.on("stripe-checkout-success", () => {
        subscriptionPlansStore.loadSubscriptionPlans();

        router.replace({name: 'home'});
    });
});

/**
 * Remove the stripe checkout success event listener when the component is unmounted.
 */
onUnmounted(() => {
    window.ipcRenderer.removeAllListeners("stripe-checkout-success");
});
</script>

<template>
  <BaseContainer>
    <div class="gradient flex px-4 py-3 items-center justify-between gap-16">
      <h1 class="text-white">
        Libérez tout le potentiel de votre pharmacie et facilitez la vie de vos équipes
      </h1>
      <div>
        <img
          class="h-16 w-auto"
          src="@/assets/images/logos/apodis-white.svg"
          alt="Apodis"
        >
        <span class="text-white">Premium</span>
      </div>
    </div>
    <div class="flex p-6 gap-16 flex-wrap-reverse">
      <IndividualSubscriptionPlansColumn class="flex-2 min-w-[500px]" />
      <GlobalSubscriptionPlanColumn class="flex-1 min-w-[500px]" />
    </div>
  </BaseContainer>
</template>

<style scoped>

</style>
