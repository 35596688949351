<template>
  <header class="flex-shrink-0 relative h-12 bg-white flex items-center border-b-2 border-primary-lightest">
    <div class="absolute inset-y-0 left-0">
      <div
        class="hidden md:block h-28 w-24 rounded-b-3xl"
        :class="[{
          'bg-gradient-to-r from-primary-dark to-primary': !groupingLogo,
          'bg-white shadow': groupingLogo
        }]"
      >
        <div class="flex items-center justify-center h-28">
          <img
            v-if="!detecting"
            class="h-12 w-auto"
            :src="groupingLogo ? groupingLogo : apodisLogoWhite"
            alt="Apodis"
          >
          <BaseSpinner
            v-else
            :color="groupingLogo ? 'primary' : 'white'"
            size="medium-small"
          />
        </div>
      </div>
    </div>

    <div class="text-primary font-semibold ml-20 md:ml-28">
      {{ title }}
    </div>

    <div class="hidden md:min-w-0 md:flex-1 md:flex md:items-center md:justify-between">
      <div class="min-w-0 flex-1" />

      <div class="ml-10 pr-4 flex-shrink-0 flex items-center space-x-10">
        <div class="flex items-center space-x-8">
          <div
            v-show="false"
            class="flex space-x-4"
          >
            <button class="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6 text-primary"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                />
              </svg>
            </button>

            <button class="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6 text-primary"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
                />
              </svg>
            </button>
          </div>
          <ApodisPremiumButton v-if="!globalPlan?.subscribed" />
          <version-button />
          <div class="relative flex flex-row space-x-3 align-center text-left">
            <router-link
              :to="{ name: 'tutorial' }"
              class="button"
              active-class="button-active"
            >
              <span class="sr-only">
                Tutoriels
              </span>

              <span class="relative inline-block">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  class="h-8 w-8 stroke-current text-primary"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    stroke-width="2"
                    d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.348a1.125 1.125 0 010 1.971l-11.54 6.347a1.125 1.125 0 01-1.667-.985V5.653z"
                  />
                </svg>

              </span>
            </router-link>
            <router-link
              :to="{ name: 'notification.history' }"
              class="button"
              active-class="button-active"
            >
              <span class="sr-only">
                Notifications
              </span>

              <span class="relative inline-block">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-8 w-8 stroke-current text-primary"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                  />
                </svg>
                <span
                  v-if="notificationCountLabel !== null"
                  class="absolute top-2 right-2.5 h-4 w-4 inline-flex items-center justify-center px-2 py-1 text-sm font-bold leading-none text-white transform translate-x-1/2 -translate-y-1/2 bg-red-600 rounded-full"
                >{{ notificationCountLabel }}</span>
              </span>
            </router-link>
            <TheUserMenu />
          </div>
        </div>
      </div>
    </div>

    <TheMobileMenu />
  </header>
</template>

<script setup lang="ts">
import {onMounted, computed, ref} from "vue";
import TheUserMenu from '@/core/components/navigation-bar/TheUserMenu.vue';
import TheMobileMenu from '@/core/components/navigation-bar/TheMobileMenu.vue';
import BaseSpinner from '@/core/components/base/spinner/BaseSpinner.vue';
import VersionButton from '@/core/components/version-button/VersionButton.vue';
import {useSystemStore} from '@/stores/system.store';
import ApodisPremiumButton from "@/core/components/apodis-premium/ApodisPremiumButton.vue";
import {useRoute, useRouter} from "vue-router";
import {storeToRefs} from "pinia";
import {useSubscriptionPlanStore} from "@/stores/subscription-plan.store";
import apodisLogoWhite from '@/assets/images/logos/apodis-white.svg';
import {usePatientStore} from "@/stores/patient.store";
import {NotificationEventParams} from "@/core/interfaces/Event";

const {notificationCount, environment, groupingLogo} = storeToRefs(useSystemStore());
const {globalPlan} = storeToRefs(useSubscriptionPlanStore());
const patientStore = usePatientStore();

const router = useRouter();
const route = useRoute();
const detecting = ref(false);
const title = computed(() => {
    if (groupingLogo.value) {
        return environment.value?.entity?.grouping?.name;
    }

    return route.meta.title || 'Apodis Connect';
});

const notificationCountLabel = computed(() => {
    const count = notificationCount.value;

    if (!count) {
        return null;
    }

    return count > 9 ? '9+' : count;
});

/**
 * Show a support program.
 */
const showSupportProgram = (event: never, params: NotificationEventParams) => {
    patientStore
        .load(params.customerId)
        .then(() => router.push({
            name: 'consumer.support-program',
            params: {customerId: params.customerId, programId: params.programId, consumerKey: params.consumerKey}
        }));
};

/**
 * Show a customer.
 */
const showCustomer = (event: never, customerId: number) => {
    patientStore
        .load(customerId)
        .then(() => router.push({
            name: 'customer.home',
            params: {customerId}
        }));
};

onMounted(() => {
    window.ipcRenderer.on('detection-start', () => {
        detecting.value = true;
    });

    window.ipcRenderer.on('detection-completed', () => {
        detecting.value = false;
    });


    window.ipcRenderer.off('show-support-program', showSupportProgram);
    window.ipcRenderer.on('show-support-program', showSupportProgram);

    window.ipcRenderer.off('show-customer', showCustomer);
    window.ipcRenderer.on('show-customer', showCustomer);
});
</script>

<style scoped>
.button {
    @apply flex-shrink-0 inline-flex items-center justify-center h-8 w-8;
}

.button:hover,
.button-active span > svg {
    @apply stroke-current text-secondary;
}
</style>
