import {RouteRecordRaw} from "vue-router";
import SponsorshipView from "@/sponsorship/views/SponsorshipView.vue";

const routes: RouteRecordRaw[] = [
    {
        path: '/sponsorship',
        name: 'sponsorship',
        component: SponsorshipView
    }
];

export default routes;
