import {AUTO_COMPLETE_SECTION} from "@/core/interfaces/search-bar";
import sections from "@/core/factories/search-bar/AutoCompleteConfig";
import {
    AUTO_COMPLETE_REQUEST_TYPE, ISearchBarProductCategoryItem,
    SearchBarAutoCompleteItem
} from "@/core/interfaces/search-bar/ISearchBarAutoComplete";

const SearchBarAutoCompleteService = () => {
    /**
     * Determine if the given item is an auto complete item.
     *
     * @param item
     * @return {item is SearchBarAutoCompleteItem}
     */
    const isAutoCompleteItem = (item: any): item is SearchBarAutoCompleteItem => {
        return item.type !== undefined && item.id !== undefined;
    };

    /**
     * Get the key from the given request type.
     *
     * @param {AUTO_COMPLETE_REQUEST_TYPE} type
     *
     * @return {AUTO_COMPLETE_SECTION | undefined}
     */
    const getSectionKeyFromRequestType = (type: AUTO_COMPLETE_REQUEST_TYPE): AUTO_COMPLETE_SECTION | undefined => {
        return (Object.keys(sections) as AUTO_COMPLETE_SECTION[])
            .find((key: AUTO_COMPLETE_SECTION) => {
                return sections[key].requestType === type;
            });
    };

    /**
     * Get the position for a given section key.
     *
     * @param {AUTO_COMPLETE_SECTION} section
     * @return {number}
     */
    const getSectionPosition = (section: AUTO_COMPLETE_SECTION): number => {
        return sections[section].position;
    };

    /**
     * Get the request type from the given section key.
     *
     * @param {AUTO_COMPLETE_SECTION} section
     *
     * @return {AUTO_COMPLETE_REQUEST_TYPE}
     */
    const getRequestTypeFromSection = (section: AUTO_COMPLETE_SECTION) => {
        return sections[section].requestType;
    };

    /**
     * Get the color for a given request type.
     *
     * @param {AUTO_COMPLETE_SECTION} section
     *
     * @return {string | undefined}
     */
    const getColorForSection = (section: AUTO_COMPLETE_SECTION): string | undefined => {
        if (!sections[section]) {
            return;
        }

        return sections[section].color;
    };

    /**
     * Get the name for a given section key.
     *
     * @param {AUTO_COMPLETE_SECTION} section
     * @return {string | undefined}
     */
    const getNameForSection = (section: AUTO_COMPLETE_SECTION): string | undefined => {
        if (!sections[section]) {
            return;
        }

        return sections[section].name;
    };

    const isGeographicSection = (section: AUTO_COMPLETE_SECTION): boolean => {
        if (!sections[section]) {
            return false;
        }

        return sections[section].isGeoFilter ?? false;
    };

    /**
     * Determine if the given auto complete item is a category item.
     *
     * @param {SearchBarAutoCompleteItem} item
     * @return {item is ISearchBarProductCategoryItem}
     */
    const isCategoryItem = (item: SearchBarAutoCompleteItem): item is ISearchBarProductCategoryItem => {
        return item.type === AUTO_COMPLETE_SECTION.CATEGORY;
    };

    /**
     * Transform the given items to query params.
     *
     * @param {SearchBarAutoCompleteItem[]} items
     * @return {Record<string, (number | string)[]>}
     */
    const transformToQueryParams = (items: SearchBarAutoCompleteItem[]): Record<string, (number | string)[]> => {
        return items.reduce((params: Record<string, (number | string)[]>, item: SearchBarAutoCompleteItem) => {
            const queryParams = sections[item.type]?.queryParam;

            // As IDs of category are not indexed, we need to use the code instead.
            const key = isCategoryItem(item) ? item.code : item.id;

            if (queryParams && params[queryParams]) {
                params[queryParams].push(key);
            } else if (queryParams) {
                params[queryParams] = [key];
            }

            return params;
        }, {});
    };

    return {
        isAutoCompleteItem,
        getSectionKeyFromRequestType,
        getColorForSection,
        transformToQueryParams,
        getNameForSection,
        isGeographicSection,
        getRequestTypeFromSection,
        getSectionPosition
    };
};

export default SearchBarAutoCompleteService();
