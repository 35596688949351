<script setup lang="ts">
import {ref, computed} from "vue";
import {ProgramAIAdvice} from "@/program/interfaces/Program";
import Icon from "@/core/components/icon/Icon.vue";
import GlowingContainer from "@/core/components/glowing-container/GlowingContainer.vue";
import BaseButton from "@/core/components/base/BaseButton.vue";
import {programApi} from "@/container";
import MarkdownView from "@/core/components/markdown/Markdown.vue";

const props = defineProps<{ advices: ProgramAIAdvice[], programId: number }>();
const selectedAdvice = ref<ProgramAIAdvice | null>(null);
const prompt = ref<string | null>(null);
const loading = ref(false);
const result = ref<string | null>(null);

/**
 * Handle advice click.
 */
const handleAdviceClick = (advice: ProgramAIAdvice) => {
    if (!selectedAdvice.value) {
        return;
    }

    result.value = null;
    prompt.value = null;
    if (selectedAdvice.value === advice) {
        selectedAdvice.value = null;
    } else {
        selectedAdvice.value = advice;
    }
};

/**
 * Handle advice button click.
 */
const handleButtonClick = (advice: ProgramAIAdvice) => {
    result.value = null;
    prompt.value = null;
    selectedAdvice.value = advice;
};

const placeholder = computed(() => selectedAdvice.value?.default_prompt);

const getAdvice = async (advice: ProgramAIAdvice) => {
    loading.value = true;

    programApi()
        .aiAdvice(props.programId, advice.id, prompt.value ?? advice.default_prompt)
        .then(data => {
            result.value = data.response;
        })
        .finally(() => loading.value = false);

    prompt.value = null;
};
</script>

<template>
  <div class="bg-white shadow-md p-4 rounded-lg">
    <h1>
      Demandez conseil à l'IA
      <Icon name="mdi-creation" color="primary" />
    </h1>
    <div class="flex flex-col gap-4 mt-3">
      <div class="flex items-center gap-x-12 gap-y-4 flex-wrap">
        <div
          v-for="(advice, index) in advices"
          :key="advice.id"
          class="flex items-center gap-2"
        >
          <div
            v-if="advice.logo"
            :key="advice.id"
            class="min-w-24 h-12 max-w-44 mr-2"
            :class="{
              'border-b-2 border-b-primary': selectedAdvice === advice,
              'cursor-pointer': !!selectedAdvice,
            }"
            @click="handleAdviceClick(advice)"
          >
            <img :src="advice.logo.url" :alt="`logo_${index}`" class="object-fill h-full">
          </div>
          <BaseButton
            v-if="!selectedAdvice" class="text-primary" density="comfortable"
            @click="handleButtonClick(advice)"
          >
            Poser une question
            <Icon name="mdi-creation" class="ml-1" color="primary" />
          </BaseButton>
        </div>
      </div>
      <GlowingContainer v-if="selectedAdvice">
        <div class="flex p-1">
          <v-text-field
            v-model="prompt"
            :placeholder="placeholder"
            class="prompt rounded-md pl-2" hide-details density="compact" variant="plain"
            @keydown.enter.prevent="getAdvice(selectedAdvice)"
          />
          <BaseButton :loading="loading" class="text-primary" variant="flat" @click="getAdvice(selectedAdvice)">
            Demander
            <Icon name="mdi-creation" color="primary" class="ml-1" />
          </BaseButton>
        </div>
      </GlowingContainer>
      <blockquote v-if="result">
        <MarkdownView class="p-2" :content="result" />
      </blockquote>
    </div>
  </div>
</template>

<style scoped>
:deep(.prompt input[type="text"]:focus) {
    --tw-ring-shadow: none;
}
</style>
