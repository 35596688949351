<script setup lang="ts">
import HeaderBackButton from "@/core/components/header-back-button/HeaderBackButton.vue";
import BaseCard from "@/core/components/base/BaseCard.vue";
import {onMounted, onUnmounted, ref} from "vue";
import {creditApi} from "@/container";
import {StripePrice} from "@/stripe/interfaces";
import StripeCheckoutService from "@/stripe/services/stripeCheckoutService";
import {Credit} from "@/core/interfaces/Credit";
import BaseButton from "@/core/components/base/BaseButton.vue";
import BaseCardBody from "@/core/components/base/BaseCardBody.vue";
import BaseSpinner from "@/core/components/base/spinner/BaseSpinner.vue";

const smsCreditCount = ref<number>();
const smsPackPrices = ref<StripePrice[]>();
const loadingBalance = ref<boolean>(false);
const loadingPrices = ref<boolean>(false);
const loadingStripeCheckout = ref<string | boolean>(false);

/** Load user balance */
const loadBalance = async () => {
    loadingBalance.value = true;
    const credit: Credit = await creditApi().read();
    smsCreditCount.value = credit.balance;
    loadingBalance.value = false;
};

/** Load sms pack prices. */
const loadPrices = async () => {
    loadingPrices.value = true;
    smsPackPrices.value = await creditApi().prices();
    loadingPrices.value = false;
};

/** Launch stripe checkout to buy credits. */
const buyCredits = async (price: StripePrice) => {
    loadingStripeCheckout.value = price.id;
    await StripeCheckoutService.checkout(price);
    loadingStripeCheckout.value = false;
};

/** When component is mounted */
onMounted(async () => {
    await loadPrices();
    await loadBalance();

    window.ipcRenderer.on("stripe-checkout-success", () => {
        loadBalance();
    });
});

/** When component is unmounted */
onUnmounted(() => {
    window.ipcRenderer.removeAllListeners("stripe-checkout-success");
});
</script>

<template>
  <div>
    <HeaderBackButton title="Achat de crédits SMS" />
    <BaseSpinner v-if="loadingBalance || loadingPrices" class="h-full v-full" />
    <div v-else class="p-4 flex flex-col gap-4">
      <BaseCard>
        <BaseCardBody>
          Vous avez actuellement <span class="text-primary">{{ smsCreditCount }}</span> crédits SMS.
        </BaseCardBody>
      </BaseCard>
      <BaseCard>
        <BaseCardBody>
          <h2>Sélectionnez un produit</h2>
          <ul v-if="smsPackPrices" class="flex flex-col gap-y-4 mt-4">
            <li
              v-for="price in smsPackPrices"
              :key="price.id"
              class="flex border-b border-gray-50 items-center gap-2 border-dotted justify-between cursor-pointer py-2"
            >
              <div class="">
                Pack {{ price.transform_quantity?.divide_by || 1 }} crédits
              </div>
              <BaseButton primary :loading="loadingStripeCheckout === price.id" @click="buyCredits(price)">
                <span class="text-white">Acheter pour {{ price.unit_amount / 100 }} €</span>
              </BaseButton>
            </li>
          </ul>
        </BaseCardBody>
      </BaseCard>
    </div>
  </div>
</template>
