<template>
  <div>
    <div v-if="pathway.steps.length > 1" class="flex flew-row flex-start flex-wrap gap-4 my-3">
      <div
        v-for="(step, index) in pathway.steps"
        :key="index"
        class="px-4 py-2 rounded-lg flex flex-row"
        :class="index + 1 === currentStep ? 'bg-pink-lighter' : index + 1 !== currentStep ? 'bg-white text-gray-400' : 'bg-white'"
      >
        <img
          v-if="stepCompleted(index)"
          alt="Green check"
          class="w-6 mr-2"
          :src="greenCheck"
        >
        <div
          v-else
          class="text-2xl font-bold mr-2"
        >
          {{ index + 1 }}
        </div>
        <div style="margin-top: 5px">
          {{ step.title }}
        </div>
      </div>
    </div>

    <v-stepper
      v-model="currentStep"
      class="rounded-lg"
      style="border: 1px solid rgba(53,19,226, 0.5); box-shadow: 0 3px 1px -2px rgb(53 19 226 / 20%), 0 2px 2px 0 rgb(53 19 226 / 14%), 0 1px 5px 0 rgb(53 19 226 / 12%);;"
      alt-labels
    >
      <v-stepper-window
        :model-value="currentStep"
        style="margin: 0;"
      >
        <v-stepper-window-item
          v-for="(step, index) in pathway.steps"
          :key="index"
          :value="step.index"
          style="padding: 0;"
        >
          <component
            :is="stepComponent(step)"
            :step="step"
            :consumer-key="consumerKey"
            :active="currentStep === step.index"
            @start="() => $emit('start')"
            @finish="() => finish(step)"
            @skip-delay="$emit('skipDelay')"
          />
        </v-stepper-window-item>
      </v-stepper-window>
    </v-stepper>
  </div>
</template>

<script lang="ts">
import {defineComponent, inject} from "vue";
import pathwayService from '@/pathway/services/PathwayService';
import PathwayAftercareStep from '@/pathway/components/PathwayAftercareStep.vue';
import PathwayLoopStep from '@/pathway/components/PathwayLoopStep.vue';
import PathwayGuidanceStep from '@/pathway/components/PathwayGuidanceStep.vue';
import PathwayOnboardingStep from '@/pathway/components/PathwayOnboardingStep.vue';
import PathwayAssistedSaleStep from "@/pathway/components/PathwayAssistedSaleStep.vue";
import PathwayEnd from '@/pathway/components/PathwayEnd.vue';
import greenCheck from '@/assets/icons/green-check.svg';

export default defineComponent({
    name: 'PathwayStepper',
    components: {
        PathwayLoopStep,
        PathwayAftercareStep,
        PathwayGuidanceStep,
        PathwayOnboardingStep,
        PathwayAssistedSaleStep,
        PathwayEnd
    },
    props: {
        pathway: {
            type: Object,
            required: true
        },
        consumerKey: {
            type: String,
            required: true
        }
    },
    emits: ['skipDelay', 'start'],
    setup() {
        const openSurveyModal = inject<any>('openSurveyModal');

        return {
            openSurveyModal
        };
    },
    data() {
        return {
            greenCheck
        };
    },
    computed: {
        currentStep() {
            return this.pathway.next_step?.index ?? null;
        }
    },
    methods: {
        skipDelay() {
            this.$emit('skipDelay');
        },
        stepCompleted(index: number) {
            return this.currentStep > index + 1;
        },
        finish(step: any) {
            return pathwayService().finish(this.pathway, step, this.consumerKey);
        },
        isPathwayEnd() {
            const isRepeating = this.pathway.steps.some((step: any) => !!step.repeating);
            const isLastStep = this.currentStep === this.pathway.steps.length;

            return isLastStep && !isRepeating;
        },
        stepComponent(step: { type: string }) {
            switch (step.type) {
            case 'aftercare':
                return 'PathwayAftercareStep';
            case 'guidance':
                return 'PathwayGuidanceStep';
            case 'onboarding':
                return 'PathwayOnboardingStep';
            case 'assisted-sale':
                return 'PathwayAssistedSaleStep';
            default:
                return this.isPathwayEnd() ? 'PathwayEnd' : 'PathwayLoopStep';
            }
        }
    }
});
</script>
