<template>
  <div class="flex flex-col flex-1 gap-y-4">
    <BaseCard class="p-5">
      <div class="font-heading text-3xl font-bold text-primary mb-4 mt-1">
        Par QR code
      </div>
      <div class="my-5">
        <CustomerCertificationQrCode :customer="customer" />
      </div>
      <ul class="space-y-3">
        <li class="flex flex-row items-center">
          <span
            class="text-5xl text-lightPurple-darkest text-center font-content font-bold w-7"
            :style="{'filter': 'drop-shadow(0 3px 6px rgb(0 0 0 / 0.16))'}"
          >
            1
          </span>
          <span class="text-black font-content font-normal ml-3">
            Votre patient télécharge l'application Apodis sur son mobile.
          </span>
        </li>
        <li class="flex flex-row items-center">
          <span
            class="text-5xl text-lightPurple-darkest text-center font-content font-bold w-7"
            :style="{'filter': 'drop-shadow(0 3px 6px rgb(0 0 0 / 0.16))'}"
          >
            2
          </span>
          <span class="text-black font-content font-normal ml-2">
            Il s'inscrit et suit les instructions.
          </span>
        </li>
        <li class="flex flex-row items-center">
          <span
            class="text-5xl text-lightPurple-darkest text-center font-content font-bold w-7"
            :style="{'filter': 'drop-shadow(0 3px 6px rgb(0 0 0 / 0.16))'}"
          >
            3
          </span>
          <span class="text-black font-content font-normal ml-2">
            Lorsqu'il doit scanner le QR code, présentez lui.
          </span>
        </li>
      </ul>
    </BaseCard>
    <div class="flex flex-row justify-between">
      <div class="flex flex-col">
        <span class="font-bold">Ou</span>
        <span>Remettez-lui les instructions Qrcode</span>
      </div>
      <v-btn
        rounded="xl"
        prepend-icon="mdi-printer"
        :disabled="downloading"
        @click="print('qrcode')"
      >
        Imprimer
      </v-btn>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from "vue";
import BaseCard from '@/core/components/base/BaseCard.vue';
import CustomerCertificationQrCode from '@/customer-certification/components/CustomerCertificationQrCode.vue';
import printIcon from '@/assets/icons/print.svg';

export default defineComponent({
    name: 'CustomerOnboardingQrCodeCard',
    components: {
        CustomerCertificationQrCode,
        BaseCard
    },
    props: {
        downloading: {
            type: Boolean,
            required: true
        },
        print: {
            type: Function as PropType<((type: string) => void)>,
            required: true
        },
        customer: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            printIcon
        };
    }
});
</script>
