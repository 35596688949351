<script setup lang="ts">

import Icon from "@/core/components/icon/Icon.vue";
import BaseButton from "@/core/components/base/BaseButton.vue";
import {useSubscriptionPlanStore} from "@/stores/subscription-plan.store";
import {computed, ref} from "vue";
import StripePriceService from "@/stripe/services/stripePriceService";
import StripeCheckoutService from "@/stripe/services/stripeCheckoutService";
import {storeToRefs} from "pinia";

const iconProps = {
    name: 'mdi-check-bold',
    color: 'secondary'
};

const {globalPlan} = storeToRefs(useSubscriptionPlanStore());
const loadingCheckout = ref(false);

/**
 * The human readable price of the global plan.
 */
const price = computed(() => {
    const lowestPrice = StripePriceService.lowestPrice(globalPlan.value?.prices);

    if (!lowestPrice) {
        return null;
    }

    return StripePriceService.formatPrice(lowestPrice);
});

/**
 * Start the checkout stripe payment process.
 */
const checkout = () => {
    const lowestPrice = StripePriceService.lowestPrice(globalPlan.value?.prices);

    if (!lowestPrice) {
        return;
    }

    loadingCheckout.value = true;
    StripeCheckoutService.checkout(lowestPrice)
        .finally(() => {
            loadingCheckout.value = false;
        });
};
</script>

<template>
  <div class="flex flex-col">
    <div class="text-center">
      <h2>Apodis Premium</h2>
      <p>Le meilleur pour votre officine</p>
    </div>
    <div class="relative bg-white rounded-md border-primary border-[1px] shadow-[3px_3px_0_0_#3513e2]">
      <div class="bg-secondary absolute -right-5 rotate-[16deg] px-1">
        <label class="text-sm text-primary font-semibold">Le plus populaire</label>
      </div>
      <div class="py-5 px-3">
        <div class="flex flex-col gap-6">
          <p>Tous les packs Individuels, plus :</p>
          <div class="feature">
            <icon v-bind="iconProps" />
            <span>
              <strong>25% de réduction</strong>, soit 75€ d'économisés par mois
            </span>
          </div>
          <div class="feature">
            <icon v-bind="iconProps" />
            <span>
              Support client <strong>prioritaire</strong>
            </span>
          </div>
          <div class="feature">
            <icon v-bind="iconProps" />
            <span>
              Faites partie du programme beta-testeur et accédez à des programmes <strong>en avant première</strong>
            </span>
          </div>
        </div>
        <h1 class="mt-14">
          N'attendez plus et passez au niveau supérieur
        </h1>
        <div class="flex items-center gap-10 mt-5">
          <h3 class="text-primary">
            {{ price }}
          </h3>
          <BaseButton class="bg-secondary text-primary" :loading="loadingCheckout" @click="checkout">
            Passer premium
          </BaseButton>
        </div>
      </div>
    </div>
    <h3 class="text-center text-primary mt-2">
      Économisez encore plus en optant pour le paiement annuel
    </h3>
  </div>
</template>

<style scoped>
.feature {
    @apply flex gap-2
}

.feature strong {
    @apply text-primary font-bold
}
</style>
