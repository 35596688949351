<template>
  <div class="flex flex-col md:flex-row justify-between items-center gap-6 md:gap-12">
    <div class="text-primary">
      <span class="text-3xl font-content-bold bold">
        {{ availabilityPercent }}%
      </span> des pharmacies partenaires ont ce produit
    </div>
    <div class="flex gap-12">
      <div class="flex flex-col">
        <span class="text-3xl font-content-bold">{{ stockAverage }}</span>
        <span>stock moyen</span>
      </div>
      <div class="flex flex-col">
        <span class="text-3xl font-content-bold">{{ stockAvailableDaysAverage }}</span>
        <span>jours de stock moyen</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';
import {mapState} from "pinia";
import {useSystemStore} from "@/stores/system.store";
import {HealthcenterGroupMember} from "@/disruption-logistics/interfaces/availability-providers";

export default defineComponent({
    name: "HealthcenterGroupMapStatistics",
    props: {
        healthcenters: {
            type: Array as PropType<HealthcenterGroupMember[]>,
            required: true
        }
    },
    computed: {
        ...mapState(useSystemStore, ['environment']),
        /**
     * Get the availability percent of the health centers.
     */
        availabilityPercent (): number {
            if (!this.healthcenters?.length) {
                return 0;
            }

            const currentHealthcenterId = this.environment?.entityId;
            const _healthcenters = this.healthcenters.filter((healthcenter) => {
                return healthcenter.id !== currentHealthcenterId;
            });

            const total = _healthcenters.reduce((acc, healthcenter) => {
                if (healthcenter.stocks?.length && healthcenter.stocks[0].quantity > 0) {
                    return acc + 1;
                }

                return acc;
            }, 0);

            const value = Math.round((total / _healthcenters.length) * 100);

            return isNaN(value) ? 0 : value;
        },
        /**
     * Get the stock average of the health centers.
     *
     * @return {number}
     */
        stockAverage (): number {
            if (!this.healthcenters?.length) {
                return 0;
            }

            const total = this.healthcenters.reduce((acc, healthcenter) => {
                if (healthcenter.stocks?.length) {
                    return acc + healthcenter.stocks[0].quantity;
                }

                return acc;
            }, 0);

            return Math.round(total / this.healthcenters.length);
        },
        /**
     * Get the stock available days average of the health centers.
     *
     * @return {number}
     */
        stockAvailableDaysAverage (): number {
            if (!this.healthcenters?.length) {
                return 0;
            }

            const healthCentersWithStockAvailableDays = this.healthcenters.filter((healthcenter) => {
                if (!healthcenter.stocks?.length || !healthcenter.stocks[0].stock_available_days === null) {
                    return false;
                }

                return true;
            });

            const total = healthCentersWithStockAvailableDays.reduce((acc, healthcenter) => {
                if (healthcenter.stocks?.length) {
                    return acc + (healthcenter.stocks[0].stock_available_days ?? 0);
                }

                return acc;
            }, 0);

            const average = total / healthCentersWithStockAvailableDays.length;

            if (isNaN(average)) {
                return 0;
            }

            return Math.round(total / healthCentersWithStockAvailableDays.length);
        }
    }
});
</script>

<style scoped>

</style>
