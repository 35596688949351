import {
    AUTO_COMPLETE_REQUEST_TYPE,
    PRODUCT_SEARCH_QUERY_PARAM_KEYS,
    SearchBarAutoCompleteItem
} from "./ISearchBarAutoComplete";

export interface QuickFilterCategory {
    /** The category name. */
    name: string;
    /** The category filters belonging the category. */
    filters: QuickFilter<any>[];
    /** Whether the category is experimental.
     * It can be added to the product search bar by setting `allowExperimentalCategories` props..
     */
    isExperimental?: boolean;
}

export interface QuickFilter<T> {
    /** The label to display. */
    label: string;
    /** The query param used by the server. */
    queryParam: string;
    /** The value to send to the server. */
    value: T;
}

export interface AutoCompleteSection {
    /** The section name. */
    name: string;
    /** The section color */
    color: string;
    /** The section request type. */
    requestType: AUTO_COMPLETE_REQUEST_TYPE;
    /** The section position. */
    position: number;
    /** The query param handled by the section. */
    queryParam: PRODUCT_SEARCH_QUERY_PARAM_KEYS;
    /** Whether it section for geographical filters. */
    isGeoFilter?: boolean;
}

export enum AUTO_COMPLETE_SECTION {
    CATEGORY = "category",
    PRODUCT = "product",
    LABORATORY = "laboratory",
    GENERIC = "generic",
    RANGES = "ranges",
    PHARMAS = "pharmas",
    CITY = "city",
    DEPARTMENT = "departement",
    REGION = "region",
    UGA = "uga",
    SEGMENT_PRODUCT = "segmentProduct"
}

export type SearchItem = SearchBarAutoCompleteItem | QuickFilter<any>;

/**
 * Enum for the product search bar output.
 *
 * RAW will return the raw search items.
 * PRODUCTS will return the product ids.
 */
export enum ProductSearchBarOutput {
    RAW,
    PRODUCTS
}
