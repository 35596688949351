import {AxiosInstance, AxiosResponse} from "axios";
import Offer from "@/core/interfaces/Offer";
import {LegacySellinOffer, SellinOfferResponse} from "@/core/interfaces/SellinOffer";

/**
 * Imported offer get request query params.
 *
 * @property {number} page - The page number.
 * @property {number} [size] - The page size.
 */
interface ImportedOfferGetRequest {
    page: number;
    size?: number;
}


/**
 * The order resource.
 *
 * @param {Object} client - The injected client.
 *
 * @returns {Object} The public object.
 */
export default function (client: AxiosInstance) {
    return {
        /**
         * Get offer index.
         *
         * @param {number} [page] - The page number.
         * @param {number|undefined} limit - The limit of returned element.
         * @returns {Promise} Promise object represents the HTTP request.
         */
        index (page = 1, limit?: number | undefined): Promise<{ data: Offer[], meta: any }> {
            const params: ImportedOfferGetRequest = {
                page
            };

            if (limit) {
                params.size = limit;
            }
            return client
                .get('/imported-offers', { params })
                .then((response: AxiosResponse) => response.data);
        },

        /**
         * Read an offer.
         *
         * @param {number} id - The offer id.
         *
         * @return {Promise<Program>}
         */
        read (id: number): Promise<Offer> {
            return client.get(`/imported-offers/${id}`)
                .then((response: AxiosResponse) => response.data.data);
        },

        /**
         * Update an offer.
         *
         * @param {number} id - The offer id.
         * @param {Offer} data - The data to update.
         *
         * @return {Promise<Offer>}
         */
        update(id: number, data: Partial<Offer>): Promise<Offer> {
            return client.patch(`/imported-offers/${id}`, data)
                .then((response: AxiosResponse) => response.data.data);
        },
        /**
         * Read a sellin offer.
         *
         * @param {number} sellinOfferId - The sellin challenge id.
         *
         * @returns {Promise<LegacySellinOffer>}
         */
        readSellinOffer(sellinOfferId: number): Promise<LegacySellinOffer> {
            const params = {
                include: "laboratory.organization.partnershipTypes,laboratory.disruptionAlert,notificationDisruptionAlert.notificationDisruptionAlertProducts.product"
            };
            return client.get(`/sellin-offers/${sellinOfferId}`, {params})
                .then((response: AxiosResponse<SellinOfferResponse>) => {
                    const {data} = response;
                    return data.sellinOffer;
                });
        }
    };
};
