<template>
  <div>
    <div>
      <div v-if="ready">
        <div class="flex flex-1 flex-row justify-between p-5">
          <markdown
            :content="description"
          />
        </div>
        <SurveyInline
          v-if="active && verdictIsPositive"
          ref="survey"
          :survey="step.steppable.survey"
          :consumer-key="consumerKey"
          :pathway-step-id="step.id"
          :stored-instance-id="storedInstanceId"
        />
        <div class="flex flex-row justify-end align-center p-5">
          <div
            v-if="error"
            class="text-red-500 font-italic text-sm mr-5"
          >
            {{ error }}
          </div>
          <BaseButton
            :primary="true"
            class="text-sm font-semibold"
            @click="start"
          >
            {{ step.action }}
          </BaseButton>
        </div>
      </div>
      <div v-else>
        <div class="flex flex-1 flex-row justify-between px-14 py-20">
          {{ delayMessage }}
        </div>
        <pathway-delay-label
          v-if="!ready"
          @on-skip-delay="skipDelayMethod()"
        />
      </div>
    </div>
    <PathwayStepInsert
      v-if="active"
      :step="step"
    />
  </div>
</template>

<script lang="ts">
import SurveyInline from '@/survey/components/SurveyInline.vue';
import Markdown from '@/core/components/markdown/Markdown.vue';
import BaseButton from '@/core/components/base/BaseButton.vue';
import PathwayStepInsert from '@/pathway/components/PathwayStepInsert.vue';
import PathwayDelayLabel from '@/pathway/components/PathwayDelayLabel.vue';
import {defineComponent} from "vue";

export default defineComponent({
    name: 'PathwayAftercareStep',
    components: {PathwayDelayLabel, PathwayStepInsert, BaseButton, Markdown, SurveyInline},
    props: {
        step: {
            type: Object,
            required: true
        },
        consumerKey: {
            type: String,
            required: true
        },
        active: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    emits: ['start', 'skipDelay'],
    data() {
        return {
            error: null as string | null
        };
    },
    computed: {
        ready() {
            if (!this.step.delay) {
                return true;
            }

            return this.step.elapsed >= this.step.delay;
        },
        verdictIsPositive() {
            if (Array.isArray(this.step.steppable.verdict)) {
                return this.step.steppable.verdict.length > 0;
            }
            return !!this.step.steppable.verdict?.verdict;
        },
        storedInstanceId() {
            return this.step.consumer_step?.steppable?.id;
        },
        description() {
            return this.step.resolved.description;
        },
        delayMessage() {
            return 'Merci 🙂 ' + this.step.resolved.description;
        }
    },
    methods: {
        start(event: unknown) {
            this.error = null;

            if (!this.verdictIsPositive || (this.verdictIsPositive && (this.$refs.survey as any).answered)) {
                //TODO: On a migré la propriété $listeners, il faut vérifier si on peut utiliser this.$emit('start', event) à la place
                return this.$emit('start', event);
            }

            this.error = 'Merci de répondre au questionnaire avant de continuer.';

            return Promise.resolve();
        },
        skipDelayMethod() {
            this.$emit('skipDelay');
        }
    }
});
</script>
