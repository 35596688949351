<template>
  <div
    class="bg-gradient-to-r from-darkPurple-default to-darkBlue-default text-white w-full border-b border-gray-400 m-0"
    style="margin:0;"
  >
    <div class="flex flex-row flex-1 align-start justify-space-between">
      <div class="flex flex-col">
        <div class="flex-1 pr-3 text-sm">
          {{ verdict?.survey.name ?? "" }}
        </div>
        <div class="font-weight-bold flex-1 pr-3 text-xl">
          {{ verdict?.title }}
        </div>
        <div class="flex flex-col flex-1 mt-2">
          <div
            v-if="!!verdict?.verdict && !!guidance.specialities"
            class="text-sm mt-3"
          >
            <div class="flex flex-row mb-4 ">
              <v-icon
                large
                color="white"
                class="mr-1"
              >
                mdi-map-marker
              </v-icon>
              <div>Conseiller un professionnel de santé</div>
            </div>
            <BaseButton
              class="h-8 text-primary justify-center font-bold w-full"
              @click="() => showPrescriptorMap()"
            >
              Afficher la carte
            </BaseButton>
          </div>
          <div
            v-else
            class="border-l-2 pl-2 py-1"
          >
            <div class="text-sm">
              {{ verdict?.description }}
            </div>
          </div>
        </div>
      </div>
      <div class="min-w-24">
        <div
          class="flex rounded-circle bg-white text-primary text-2xl font-content-bold w-16 h-16 justify-center align-center"
        >
          {{ verdict?.score }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import BaseButton from '@/core/components/base/BaseButton.vue';
import {defineComponent} from "vue";

export default defineComponent({
    name: 'PathwayGuidanceVerdict',
    components: { BaseButton },
    props: {
        guidance: {
            type: Object,
            required: true
        },
        verdict: {
            type: Object,
            required: true
        }
    },
    methods: {
        showPrescriptorMap () {
            // this.$prescriptorMap.start({ specialityIds: this.guidance.specialities })
        }
    }
});
</script>
