<script setup lang="ts">
defineProps<{
    url: string
}>();
</script>

<template>
  <iframe
    class="aspect-video"
    :src="url"
    allowfullscreen
  />
</template>
