<script setup lang="ts">
import MarkdownView from "@/core/components/markdown/Markdown.vue";

defineProps<{ content: string}>();
</script>

<template>
  <div class="flex bg-secondary/30 shadow-[3px_3px_0_0_#3513e2] p-4 text-primary rounded-lg items-center">
    <div class="flex-1 ">
      <markdown-view :content="content" />
    </div>
  </div>
</template>
