import {createApp} from 'vue';
import {createPinia} from 'pinia';
import './style.css';
import App from './App.vue';
import router from './router';
import vuetify from "./plugins/vuetify";
import filters from "@/core/filters";
import SentryService from "@/core/services/SentryService";

const app = createApp(App);
SentryService.initialize(app, router);

app.use(createPinia());
app.use(router);
app.use(vuetify);


app.config.globalProperties.$filters = filters;

app.mount('#app').$nextTick(() => {
    postMessage({payload: 'removeLoading'}, '*');
});
