<template>
  <div class="flex flex-col flex-1 p-2">
    <div class="flex flex-row flex-wrap gap-x-4 text-sm items-center">
      <div class="flex flex-row items-center">
        <icon
          :name="'mdi-account'"
          :size="15"
          class="pr-2"
        />
        <span class="font-bold text-no-wrap">{{ prescription.customer.information.name }}</span>
      </div>
      <div v-if="prescription?.professional" class="flex flex-row items-center">
        <icon
          :name="'mdi-medical-bag'"
          :size="15"
          class="pr-2"
        />
        <span>Dr. {{ prescription.professional.last_name }}</span>
      </div>
      <div class="flex flex-row items-center">
        <icon
          :name="'mdi-calendar-month'"
          :size="15"
          class="pr-2"
        />
        <span>{{ $filters.date(prescription.created_at, 'short') }}</span>
      </div>
    </div>
    <secure-image
      :urls="prescription.files"
    />
  </div>
</template>

<script setup lang="ts">
import Icon from '@/core/components/icon/Icon.vue';
import SecureImage from '@/core/components/secure-image/SecureImage.vue';
import {Prescription} from "@/prescription/interfaces/Prescription";

defineProps<{ prescription: Prescription }>();

</script>
