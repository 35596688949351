<template>
  <div class="flex flex-1 flex-col bg-orange-light h-min-max">
    <div v-if="customer" class="p-5 z-10">
      <div class="flex flex-row">
        <CustomerOnboardingHeader v-if="customer" class="flex-1 mr-6" :customer="customer" />
        <v-btn variant="flat" rounded="lg" @click="$emit('close')">
          Fermer
        </v-btn>
      </div>
      <div class="relative z-2 flex flex-1 flex-col justify-between">
        <div class="flex flex-row justify-between mt-4 gap-x-8">
          <CustomerOnboardingQrCodeCard
            v-if="customer"
            :downloading="downloading"
            :customer="customer"
            :print="print"
          />
          <div class="flex-1 flex-col">
            <CustomerOnboardingPhoneCard
              :customer="customer"
              :refresh="refresh"
              :downloading="downloading"
              :print="print"
            />
          </div>
        </div>
        <CustomerOnboardingFooter />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import {customerCertificationApi, storageService} from '@/container';
import CustomerOnboardingHeader from '@/customer-certification/components/CustomerOnboardingHeader.vue';
import CustomerOnboardingPhoneCard
    from '@/customer-certification/components/CustomerOnboardingPhoneCard.vue';
import CustomerOnboardingQrCodeCard
    from '@/customer-certification/components/CustomerOnboardingQrCodeCard.vue';
import CustomerOnboardingFooter from '@/customer-certification/components/CustomerOnboardingFooter.vue';
import printIcon from '@/assets/icons/print.svg';
import Customer, {CustomerCertification} from "@/customer/interfaces/Customer";

export default defineComponent({
    name: 'CustomerCertificationOnboarding',
    components: {
        CustomerOnboardingFooter,
        CustomerOnboardingPhoneCard,
        CustomerOnboardingHeader,
        CustomerOnboardingQrCodeCard,
    },
    emits: ['close'],
    setup(props, ctx) {
        const customer: Customer = (ctx.attrs.customer as Customer);

        return {
            customer
        };
    },
    data() {
        return {
            downloading: false,
            printIcon
        };
    },
    methods: {
        /**
         * Cancel the certification.
         */
        cancel() {
            this.$router.go(-1);
        },
        /**
         * Print customer certification.
         */
        print(type: string) {
            const customer = this.customer;
            this.downloading = true;

            if (customer?.id) {
                window.ipcRenderer
                    .invoke('print', {
                        url: customerCertificationApi().pdfUrl(customer.id, type),
                        token: storageService().read('token')
                    })
                    .finally(() => {
                        this.downloading = false;
                    });
            }
        },
        /**
         * Refresh customer certification.
         *
         * @param {Object} certification
         */
        refresh(certification: CustomerCertification) {
            this.customer.certification = certification;
        }
    }
});
</script>
