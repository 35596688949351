import {defineStore} from "pinia";
import {disruptionLogisticsApi} from "@/container";
import {useExtensionStore} from "@/stores/extension.store";
import {useSubscriptionPlanStore} from "@/stores/subscription-plan.store";

interface DisruptionLogisticsRequestStore {
    /** The remaining request for the disruption logistics */
    remainingRequests: number | null;
    /** The loading state */
    loading: boolean;
}

export const useDisruptionLogisticsRequestStore = defineStore('disruptionLogisticsRequest', {
    state(): DisruptionLogisticsRequestStore {
        return {
            remainingRequests: null,
            loading: false
        };
    },
    actions: {
        decreaseRemainingRequests() {
            if (this.remainingRequests) {
                this.remainingRequests -= 1;
            }
        },
        async loadRemainingRequests() {
            this.loading = true;

            try {
                const response = await disruptionLogisticsApi()
                    .remainingRequests();

                this.remainingRequests = response.remaining;
            } finally {
                this.loading = false;
            }
        }
    },
    getters: {
        /**
         * Returns the extension for the disruption logistics
         */
        disruptionLogisticsExtension()  {
            const extensionStore = useExtensionStore();

            return extensionStore.extensionBySlug('disruption-logistics');
        },
        /**
         * Returns the extension for the disruption logistics
         */
        disruptionLogisticsSubscriptionPlan()  {
            const subscriptionPlanStore = useSubscriptionPlanStore();

            return subscriptionPlanStore.subscriptionPlanBySlug('disruption-logistics');
        },
        /**
         * Returns if the user is subscribed to the disruption logistics
         */
        subscribed(): boolean {
            return this.disruptionLogisticsExtension?.enabled ?? false;
        }
    }
});
