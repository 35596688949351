<template>
  <interview-short-cut-container title="Historique de délivrances">
    <div v-if="loading">
      <base-flex-spinner />
    </div>
    <div v-else-if="carts">
      <div v-if="!carts.length">
        <p class="text-center">
          Aucun produit trouvé
        </p>
      </div>
      <div
        v-else
        class="flex flex-col gap-3"
      >
        <div
          v-for="cart in carts"
          :key="cart.id"
        >
          <interview-consumer-cart-card :cart="cart" />
        </div>
        <base-button
          v-if="meta.after_key"
          @click="loadMore"
        >
          Voir plus
        </base-button>
      </div>
    </div>
    <div v-else>
      <p class="text-center">
        Une erreur s'est produite
      </p>
    </div>
  </interview-short-cut-container>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import InterviewShortCutContainer from '@/interview/components/instance-modal/short-cuts/InterviewShortCutContainer.vue';
import {consumerCartApi} from '@/container';
import BaseFlexSpinner from '@/core/components/base/spinner/BaseFlexSpinner.vue';
import BaseButton from '@/core/components/base/BaseButton.vue';
import {mapState} from "pinia";
import {useInterviewStore} from "@/stores/interview-store";
import InterviewConsumerCartCard from "@/interview/components/instance-modal/InterviewConsumerCartCard.vue";

export default defineComponent({
    name: 'InterviewConsumerCarts',
    components: {
        InterviewConsumerCartCard,
        BaseButton,
        BaseFlexSpinner,
        InterviewShortCutContainer
    },
    data() {
        return {
            carts: null as any[] | null,
            loading: true,
            meta: null as any
        };
    },
    computed: {
        ...mapState(useInterviewStore, ['consumerKey'])
    },
    mounted() {
        this.loading = true;
        this.loadCarts()
            .finally(() => {
                this.loading = false;
            });
    },
    methods: {
    /**
     * Load consumer carts
     *
     * @param afterKey
     * @returns {Promise<void>}
     */
        loadCarts(afterKey = null) {
            return consumerCartApi()
                .index(this.consumerKey, afterKey)
                .then(response => {
                    this.carts = [...(this.carts ?? []), ...response.data];
                    this.meta = response.meta;
                });
        },
        /**
     * Load more carts
     *
     * @returns {Promise<void>}
     */
        loadMore() {
            return this.loadCarts(this.meta.after_key);
        }
    }
});
</script>
