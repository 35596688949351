<template>
  <div class="flex flex-wrap gap-5 justify-center">
    <survey-answer-button
      v-for="answer in question.answers"
      :key="answer.id"
      @click="onPress(answer.value)"
    >
      <p class="font-content">
        {{ answer.label }}
      </p>
    </survey-answer-button>
  </div>
</template>

<script lang="ts">
import SurveyAnswerButton from '@/survey/components/SurveyAnswerButton.vue';
import {defineComponent} from "vue";

export default defineComponent({
    name: 'SurveyAnswerYesNo',
    components: {SurveyAnswerButton},
    props: {
        question: {
            type: Object,
            required: true
        },
        inline: {
            type: Boolean,
            default: false
        }
    },
    emits: ['onPress'],
    methods: {
    /**
     * Emit an onPress event when an answer is pressed.
     *
     * @param {boolean} value The pressed answer value.
     */
        onPress(value: boolean) {
            this.$emit('onPress', value);
        }
    }
});
</script>
