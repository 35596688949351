export default {
    /**
   * Generate a route from a program.
   *
   * @param {Object} program - The program.
   * @param {Object} customer - The customer.
   * @param consumer
   *
   * @return {Object|null}
   */
    generateRoute (program, consumer, customer = null) {
        const _customer = customer ?? consumer;

        switch (program.programmable_type) {
        case 'discount':
            return {
                name: 'brand.offer.discount',

                params: {
                    discountId: program.programmable_id,
                    laboratoryId: program.organization.entity_id
                }
            };
        case 'support':
            return {
                name: 'consumer.support-program',
                params: {
                    programId: program.id,
                    consumerKey: consumer.consumer_key,
                }
            };
        case 'feature':
            return {
                name: 'customer.feature-program',
                params: {
                    program,
                    programId: program.id,
                    consumerKey: 'c_' + customer.id,
                    customer: _customer
                }
            };
        default:
            return null;
        }
    },
    userProgramRoute (program, customer) {
        switch (program.programmable_type) {
        case 'discount':
            return {
                name: 'customer.discount.list',

                params: {
                    customerId: customer.id
                }
            };
        case 'loyalty-card':
            return {
                name: 'customer.loyalty',

                params: {
                    customerId: customer.id
                }
            };
        }
    }
};
