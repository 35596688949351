<template>
  <div>
    <img
      v-if="program.logo"
      :src="`${program.logo.url}&h=60`"
      :alt="program.name"
      class="shadow-custom"
      style="height:60px; width:60px; border-radius: 10px;"
    >
    <div
      v-else
      class="items-center justify-center flex bg-gray-200 shadow custom"
      style="height:50px; width:50px; border-radius: 10px;"
    >
      {{ program.id }}
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import { pathwayApi } from '@/container';

export default defineComponent({
    name: 'NotificationProgramLogoSimple',
    props: {
        program: {
            type: Object,
            required: true
        },
        customer: {
            type: Object,
            required: true
        },
        consumer: {
            type: Object,
            required: true
        }
    },
    mounted () {
        if (this.program.next_step) {
            pathwayApi().track('notification-show', this.program.next_step.pathway_id, this.consumer.key);
        }
    }
});
</script>
