<template>
  <base-container>
    <base-flex-spinner v-if="isLoading" />
    <div
      v-else
      class="flex flex-1 flex-col"
    >
      <div class="flex flex-row w-full p-4">
        <program-logo
          class="mr-3"
          :program="bundle"
        />
        <p class="mt-6 ml-2 text-primary text-xl font-bold">
          {{ bundle.name }}
        </p>
      </div>
      <program-description
        class="mb-5"
        :program="bundle"
      />
      <program-bundle-categories
        v-if="bundle.categories.length"
        :bundle="bundle"
      />
      <div class="flex flex-col px-4">
        <span class="text-xl text-primary font-bold my-2">Programmes</span>
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          <program-card
            v-for="program in bundle.programs"
            :key="program.id"
            :program="program"
            @click="showProgram(program)"
          />
        </div>
      </div>
    </div>
  </base-container>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import { programApi } from '@/container';
import BaseContainer from '@/core/components/base/BaseContainer.vue';
import BaseFlexSpinner from '@/core/components/base/spinner/BaseFlexSpinner.vue';
import ProgramDescription from '@/program/components/program-description/ProgramDescription.vue';
import ProgramLogo from '@/program/components/program-logo/ProgramLogo.vue';
import ProgramBundleCategories from '@/program/components/program-bundle-categories/ProgramBundleCategories.vue';
import ProgramCard from '@/program/components/program-card/ProgramCard.vue';

export default defineComponent({
    name: 'ProgramDetails',
    components: {
        ProgramCard,
        ProgramBundleCategories,
        ProgramLogo,
        ProgramDescription,
        BaseFlexSpinner,
        BaseContainer
    },
    data () {
        return {
            isLoading: true,
            bundle: null as any
        };
    },
    watch: {
        '$route.params.programBundleId': {
            immediate: true,

            handler (value) {
                this.read(value);
            }
        }
    },
    methods: {
    /**
     * Read a bundle.
     *
     * @param {Number} id
     *
     * @return {void}
     */
        read (id) {
            this.isLoading = true;

            programApi()
                .readBundle(id)
                .then(bundle => {
                    this.bundle = bundle;
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        /**
     * Redirect to the program detail page.
     *
     * @param {Object} program
     */
        showProgram (program) {
            this.$router.push({
                name: 'program.detail.content',
                params: {
                    programId: program.id
                }
            });
        }
    }
});
</script>
