<template>
  <base-card class="relative overflow-visible m-5 !px-0">
    <base-card-body>
      <div class="flex flex-row justify-between items-center gap-4">
        <div>
          <secure-image
            :urls="prescription.files"
            :size="20"
          />
        </div>
        <div>
          <div class="font-bold min-w-64">
            {{ prescription.customer?.information.name || 'Anonyme' }}
            <span class="font-normal text-sm">({{ age }})</span>
          </div>
          <div>
            Dr. {{ prescription.professional?.last_name || 'Anonyme' }},
            {{ $filters.date(prescription.created_at, 'short') }}
          </div>
          <ul class="truncate">
            <li v-for="(product, index) in prescription.products" :key="index">
              <span class="text-sm"><Icon name="mdi-pill" :size="10" class="text-primary" /> {{ product.name }}</span>
            </li>
          </ul>
        </div>
        <div class="flex flex-1 flex-wrap gap-4">
          <v-chip v-for="tag in prescription.tags" :key="tag.label" variant="outlined">
            {{ tag.label }}
          </v-chip>
        </div>
        <prescription-renewal-button :prescription="prescription" />
      </div>
    </base-card-body>
    <div
      v-if="prescription.renewal_plan"
      class="flex bg-slate-100 rounded-b-lg py-3 px-6 justify-between"
    >
      <div>
        <div>Prochains renouvellements : <span class="text-primary">{{ renewalDates }}</span></div>
        <div>Expiration: <span class="text-primary">{{ expiresAt }}</span></div>
      </div>
    </div>
  </base-card>
</template>

<script lang="ts" setup>
import {computed, PropType} from "vue";
import SecureImage from '@/core/components/secure-image/SecureImage.vue';
import BaseCardBody from '@/core/components/base/BaseCardBody.vue';
import BaseCard from '@/core/components/base/BaseCard.vue';
import PrescriptionRenewalButton from '@/prescription/components/PrescriptionRenewalButton.vue';
import {Prescription} from "@/prescription/interfaces/Prescription";
import dayjs from "dayjs";
import Icon from "@/core/components/icon/Icon.vue";

const props = defineProps({
    prescription: {
        type: Object as PropType<Prescription>,
        required: true
    }
});

/**
 * Compute the renewal date labels.
 */
const renewalDates = computed(() => {
    const dates = props.prescription.renewal_plan.renewal_dates?.map(renewal => {
        const date = dayjs(renewal.renew_at);

        return dayjs().isBefore(date) ? new Date(renewal.renew_at).toLocaleDateString() : null;
    })
        .filter(value => !!value);

    return dates && dates.length > 0 ? dates.join(', ') : "terminé";
});

const age = computed(() => {
    const birthDate = props.prescription.customer?.information.birth_date
        || props.prescription.customer?.information.birth_date || null;

    if (!birthDate) {
        return 'age inconnu';
    }

    const age = dayjs().diff(birthDate, 'year');

    return `${age} ans`;
});

const expiresAt = computed(() => new Date(props.prescription.renewal_plan.end_at).toLocaleDateString());
</script>
