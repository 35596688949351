<template>
  <div>
    <div
      class="w-64 flex flex-col bg-white rounded-md relative overflow-hidden image-container cursor-pointer"
      :style="`box-shadow: 3px 3px 5px 3px ${tintColor}`"
      @click="onClick()"
    >
      <div class="flex h-64">
        <base64-image
          v-if="attachment.has_thumbnail && thumbnail"
          alt="Image thumbnail"
          class="flex flex-1 object-contain"
          :base64="thumbnail"
        />
        <div
          v-else
          class="flex flex-1 items-center justify-center"
        >
          <img
            :src="apodisLogo"
            alt="Default attachment thumbnail"
            class="object-contain w-32"
          >
        </div>
      </div>
      <div class="flex flex-row items-center justify-between overflow-hidden p-4">
        <span class="text-primary font-medium line-clamp-3">{{ attachment.title }}</span>
        <div class="flex justify-end pl-2">
          <icon name="mdi-eye" color="primary" />
        </div>
      </div>
    </div>
    <div v-if="attachment.related_sale" class="mt-2 w-64 flex flex-1 justify-center">
      <v-chip color="primary" variant="flat" prepend-icon="mdi-pill" density="compact">
        Patient sous {{ attachment.related_sale.item.name.split(' ').slice(0, 2).join(' ') }}
      </v-chip>
    </div>
    <base-modal
      v-if="showModal"
      :title="attachment.title"
      class="w-150 m-auto"
      @close="showModal=false"
    >
      <p v-if="attachment.description" class="-mt-4">
        {{ attachment.description }}
      </p>
      <video
        v-if="attachment.type === 'video'"
        :id="`program-attachment-video_${attachment.id}`"
        width="100%"
        height="500"
        preload="auto"
        controls
      />
      <div v-else-if="attachment.type === 'image' && url">
        <base-image
          :id="`program-attachment-video_${attachment.id}`"
          :src="url"
          class="w-[100%] h-[500px]"
        />
      </div>
      <div v-else>
        <iframe v-if="url" :src="url" class="w-[100%] h-[500px]" />
        <div v-else-if="attachment.external_link">
          <a
            :href="attachment.external_link"
            target="_blank"
            @click="openExternalLink(attachment.external_link)"
          >
            <p class="text-primary font-medium text-sm line-clamp-1 my-2">
              Voir sur le navigateur
              <icon name="mdi-open-in-new" color="primary"/>
            </p>
          </a>
          <iframe :src="attachment.external_link" class="w-[100%] h-[500px]" />
        </div>
      </div>
    </base-modal>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';
import {programAttachmentApi} from '@/container';
import Base64Image from '@/core/components/base-64-image/Base64Image.vue';
import ProgramAttachmentService from '@/program/services/ProgramAttachmentService';
import BaseModal from '@/core/components/base/BaseModal.vue';
import apodisLogo from '@/assets/images/logos/apodis.svg';
import ImageService from "@/core/services/ImageService";
import {ProgramAttachment} from "@/program/interfaces/Program";
import Icon from "@/core/components/icon/Icon.vue";
import BaseImage from "@/core/components/base/BaseImage.vue";

/**
 * A card to display an attachment.
 */
export default defineComponent({
    name: 'ProgramAttachmentCard',
    components: {BaseImage, Icon, BaseModal, Base64Image},
    props: {
        /**
         * The type of the attachable model linked with the attachment.
         *
         * @values programs, program-categories, program-bundles, interviews
         */
        attachableType: {
            type: String as PropType<"programs" | "program-bundles" | "program-categories" | "interviews">,
            required: true
        },
        /**
         * The attachment to display.
         */
        attachment: {
            type: Object as PropType<ProgramAttachment>,
            required: true
        },
        /**
         * The id of the attachable model linked with the attachment.
         */
        attachableId: {
            type: Number,
            required: true
        },
        /**
         * The tint color of the card.
         */
        tintColor: {
            type: String,
            default: '#000000'
        }
    },
    data() {
        return {
            thumbnail: null as null | string,
            video: null as HTMLVideoElement | null,
            url: null as string | null,
            showModal: false,
            apodisLogo
        };
    },
    /**
     * On mount, if attachment has thumbnail, render it.
     */
    mounted() {
        if (this.attachment.has_thumbnail) {
            programAttachmentApi()
                .renderThumbnail(this.attachableType, this.attachableId, this.attachment.id)
                .then(response => {
                    this.thumbnail = ImageService().arrayBufferToBase64(response);
                });
        }
    },
    methods: {
        /**
         * On click, open the attachment.
         * If no file is attached, open the external link.
         */
        onClick() {
            if (!this.attachment.type && this.attachment.external_link) {
                this.showModal = true;

                return;
            }

            switch (this.attachment.type) {
            case 'document':
            case 'image':
                ProgramAttachmentService.downloadAttachmentAndOpen(
                    this.attachableType,
                    this.attachableId,
                    this.attachment.id
                )
                    .then(response => {
                        this.url = window.URL.createObjectURL(new Blob([response], {type: response.type}));
                    })
                    .finally(() => this.showModal = true);
                break;
            case
                'video'
                :
                this.openVideo();
                break;
            }
        }
        ,
        /**
         * Open a video attachment in a modal.
         */
        openVideo() {
            programAttachmentApi()
                .renderAttachment(this.attachableType, this.attachableId, this.attachment.id)
                .then(response => {
                    this.video = document.getElementById(`program-attachment-video_${this.attachment.id}`) as HTMLVideoElement;

                    if (this.video) {
                        this.video.src = window.URL.createObjectURL(new Blob([response], {type: response.type}));
                        this.video.load();
                        this.video.onloadeddata = () => {
                            this.showModal = true;
                            this.video?.play();
                        };
                    }
                });
        },
        /**
         * Open external link.
         */
        openExternalLink(link: string) {
            window.open(link);
        }
    }
});
</script>

<style scoped>
.overlay {
    position: absolute;
    right: 0;
    left: 0;
    bottom: -100%;
    background-color: rgba(0, 0, 0, 0.8);
    overflow: hidden;
    width: 100%;
    height: 100%;
    transition: .5s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
}

.overlay p {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 13;
    overflow: hidden;
}

.image-container:hover .overlay {
    bottom: 0;
}

.image-container img {
    transition: transform .5s ease;
}

.image-container:hover img {
    transform: scale(1.05);
}
</style>
