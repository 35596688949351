<script setup lang="ts">
import AppointmentCard from "@/core/components/appointment-card/AppointmentCard.vue";
import {ref, onMounted} from "vue";
import {ProgramBookable} from "@/program/interfaces/ProgramBookable";
import {quickAppointmentApi} from "@/container";
import {useSystemStore} from "@/stores/system.store";
import {usePatientStore} from "@/stores/patient.store";
import {Appointment} from "@/core/interfaces/Appointment";
import BaseFlexSpinner from "@/core/components/base/spinner/BaseFlexSpinner.vue";

const props = defineProps<{ bookable: ProgramBookable, programmableId: number, programmableType: string }>();
const appointmentDate = ref<string | null>(null);
const loading = ref<boolean>(false);
const appointment = ref<Appointment | null>(null);
const {environment} = useSystemStore();
const {customer} = usePatientStore();

onMounted(() => {
    if (customer && environment?.organizationId) {
        loading.value = true;
        quickAppointmentApi().find(environment.organizationId, {
            customer_id: customer.id,
            appointable_id: props.programmableId,
            appointable_type: props.programmableType
        })
            .then(response => {
                if (response?.id) {
                    appointment.value = response;
                    appointmentDate.value = response.start_at;
                }
            })
            .finally(() => loading.value = false);
    }
});

/**
 * Update appointment date.
 * @param date
 */
const updateDate = (date: string) => {
    appointmentDate.value = date;
    handleUpdate(date);
};

const handleUpdate = (date: string) => {
    if (customer) {
        if (appointment.value) {
            quickAppointmentApi().update(appointment.value.id, {
                customer_id: customer.id,
                appointable_id: props.programmableId,
                appointable_type: props.programmableType,
                start_at: date,
                duration: props.bookable.appointment_duration
            })
                .catch(() => {
                    if (appointment.value?.start_at) {
                        appointmentDate.value = appointment.value.start_at;
                    }
                });
        } else if (environment?.organizationId) {
            quickAppointmentApi().create(environment.organizationId, {
                customer_id: customer.id,
                appointable_id: props.programmableId,
                appointable_type: props.programmableType,
                start_at: date,
                duration: props.bookable.appointment_duration,
                title: props.bookable.appointment_title,
                description: props.bookable.appointment_description
            })
                .catch(() => appointmentDate.value = null);
        }
    }
};

</script>

<template>
  <base-flex-spinner v-if="loading" />
  <AppointmentCard v-else :selected-date="appointmentDate" @on-date-change="updateDate" />
</template>
