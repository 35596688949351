<template>
  <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 flex-1 w-full gap-4 p-4">
    <program-card
      v-for="program in category.programs ?? []"
      :key="program.id"
      class="program-card"
      :program="program"
      @click="showProgram(program)"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import ProgramCard from '@/program/components/program-card/ProgramCard.vue';

/**
 * Display programs of the passed category.
 */
export default defineComponent({
    name: 'ProgramBundleProgramCategoryPrograms',
    components: { ProgramCard },
    props: {
    /**
     * The category to get the programs for.
     */
        category: {
            type: Object,
            required: false,
            default: null
        }
    },
    methods: {
    /**
     * Redirect to the program detail page.
     *
     * @param {Object} program
     */
        showProgram (program) {
            if (program) {
                this.$router.push({
                    name: 'program.detail.content',
                    params: {
                        programId: program.id
                    }
                });
            }
        }
    }
});
</script>
