<script setup lang="ts">
import {VBtn} from "vuetify/components";

type VBtnProps = VBtn["$props"];
type VBtnSlots = VBtn["$slots"];

export interface Props extends /* @vue-ignore */ VBtnProps {
    primary?: boolean;
}

const props = defineProps<Props>();
defineSlots<VBtnSlots>();

</script>

<template>
  <v-btn v-bind="props" rounded="xl" class="font-weight-bold" :color="props.primary ? 'primary' : props.color ">
    <template v-for="(_, slot) in $slots" #[slot]="slotProps">
      <slot :name="slot" :props="slotProps" :v-bind="$attrs['v-bind']" />
    </template>
  </v-btn>
</template>

<style scoped>
.v-btn {
    text-transform: inherit;
}
</style>
