<template>
  <div>
    <button
      class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      @click="open()"
    >
      {{ title }}
    </button>

    <base-modal
      v-show="opened"
      :title="title"
      @close="opened = false"
    >
      <div class="flex flex-row sm:justify-around">
        <BaseInput
          v-model="point"
          type="number"
          label="Nombre de points"
          :errors="error.point"
          :value="point"
          @input="point = $event"
        />

        <BaseInput
          v-model="euro"
          type="number"
          label="€"
          :errors="error.euro"
          :value="euro"
          @input="point = $event"
        />
      </div>
      <div
        v-show="credited"
        class="flex mt-4"
      >
        <div class="flex-shrink-0">
          <svg
            aria-hidden="true"
            viewBox="0 0 20 20"
            fill="currentColor"
            class="h-5 w-5 text-green-400"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
            />
          </svg>
        </div>

        <div class="ml-1">
          <h3 class="text-sm font-medium text-green-800">
            Carte créditée
          </h3>
        </div>
      </div>

      <template #footer>
        <base-button @click="close">
          Fermer
        </base-button>

        <base-button
          primary
          @click="submit"
        >
          Valider
        </base-button>
      </template>
    </base-modal>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import {
    loyaltyCardMembershipCreditApi
} from '@/container';

import BaseModal from '@/core/components/base/BaseModal.vue';
import BaseButton from '@/core/components/base/BaseButton.vue';
import BaseInput from '@/core/components/base/BaseInput.vue';

export default defineComponent({
    components: {
        BaseModal,
        BaseButton,
        BaseInput
    },

    props: {
        rate: {
            type: Number,
            required: true
        }
    },
    emits: ['credited'],
    data() {
        return {
            point: 0,
            euro: 0,
            error: {} as any,
            credited: false,
            title: 'Créditer',
            opened: false
        };
    },
    watch: {
        point(newPoint) {
            this.euro = newPoint * this.rate;
        },
        euro(newEuro) {
            this.point = newEuro / this.rate;
        }
    },
    methods: {
        open() {
            this.opened = true;
        },

        close() {
            this.opened = false;
        },
        submit() {
            this.error = {};
            this.credited = false;

            loyaltyCardMembershipCreditApi()
                .create(
                    Number(this.$route.params.customerId),
                    Number(this.$route.params.membershipId),
                    {
                        point: this.point
                    }
                )
                .then(() => {
                    this.point = 0;
                    this.credited = true;

                    this.$emit('credited');
                })
                .catch(error => {
                    this.error = error.response.data;
                });
        }
    }
});
</script>
