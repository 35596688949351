<template>
  <div>
    <div class="mt-4">
      <certification-notification-item
        :notification="notification"
        edit-mode
        @selected="$emit('onModify', notification)"
      />
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import CertificationNotificationItem from '@/customer-certification/components/CertificationNotificationItem.vue';

export default defineComponent({
    name: 'CustomerCertificationNotificationConfirmation',
    components: { CertificationNotificationItem },
    props: {
    /**
     * The message object.
     */
        notification: {
            type: Object,
            required: true
        }
    }
});
</script>
