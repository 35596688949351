<script setup lang="ts">
import LaboratoryProduct from "@/core/interfaces/laboratory/LaboratoryProduct";
import {computed} from "vue";

const {product, quantity} = defineProps<{
    product: LaboratoryProduct;
    quantity?: number;
    unit?: string;
    showOriginalPrice?: boolean;
}>();

const productsQuantity = computed(() => quantity || 1);

const discounted = computed(() => {
    return product.laboratoryShopPrice.priceDiscountedWithoutVat < parseFloat(product.laboratoryShopPrice.priceWithoutVat);
});
const discountPercentage = computed(() => {
    return parseFloat(product.laboratoryShopPrice.discountPercentage).toFixed(1);
});
const basePrice = computed(() =>parseFloat(product.laboratoryShopPrice.priceWithoutVat) * productsQuantity.value);
const currentPrice = computed(() => product.laboratoryShopPrice.priceDiscountedWithoutVat * productsQuantity.value);
</script>

<template>
  <div class="flex flex-row item-center justify-between">
    <span v-if="discounted && showOriginalPrice" class="line-through">{{ $filters.currencyze(basePrice) }}</span>
    <p v-if="discounted" class="font-bold text-green">
      -{{ discountPercentage }}%
    </p>
    <div class="flex flex-1 justify-end">
      <p class="text-primary">
        <span class="font-bold">{{ discounted ? " " : "" }}{{ $filters.currencyze(currentPrice) }}</span>
        <span class="font-bold"> HT{{ unit === undefined ? "" : "/" + unit }}</span>
      </p>
    </div>
  </div>
</template>

<style scoped>

</style>