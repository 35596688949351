<script setup lang="ts">
import Offer from "@/core/interfaces/Offer";
import Icon from "@/core/components/icon/Icon.vue";

defineProps<{ offer: Offer }>();
</script>

<template>
  <div class="my-2">
    <div
      v-for="(advantage, index) in offer.advantages"
      :key="index"
      class="flex flex-row items-center py-1"
    >
      <icon name="mdi-tag" />
      <p class="pl-1">
        {{ advantage.label }}
      </p>
    </div>
  </div>
</template>
