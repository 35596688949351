<template>
  <BaseContainer>
    <div
      class="flex flex-1"
    >
      <div class="flex w-full items-center justify-center">
        <div class="flex flex-col w-1/2 h-auto">
          <div class="flex flex-row justify-between items-center">
            <p class="text-white font-content text-xl w-3/4">
              {{ surveyVerdict.title }}
            </p>
            <div class="flex rounded-full bg-white w-16 h-16 items-center justify-center align-center">
              <span class="text-primary font-content-bold text-3xl">
                {{ surveyVerdict.score }}
              </span>
            </div>
          </div>
          <div
            v-if="surveyVerdict.description"
            class="flex flex-col bg-white p-5 my-5 rounded-lg"
          >
            <p class="font-content top-1">
              {{ surveyVerdict.description }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </BaseContainer>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import BaseContainer from '@/core/components/base/BaseContainer.vue';

export default defineComponent({
    name: 'SurveyVerdict',
    components: {
        BaseContainer
    },
    props: {
        surveyVerdict: {
            type: Object,
            required: true
        }
    }
});
</script>
