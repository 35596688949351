<template>
  <div class="flex flex-col gap-8">
    <distributor-availability-map-legend-item
      :warehouse="warehouse"
      :is-available="true"
    />
    <distributor-availability-map-legend-item
      :warehouse="warehouse"
      :is-available="true"
      :members="members"
    />
    <distributor-availability-map-legend-item
      :warehouse="warehouse"
      :is-available="false"
      :members="members"
      message="Manque rayon"
    />
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import DistributorAvailabilityMapLegendItem
    from '@/disruption-logistics/components/maps/distributors/DistributorAvailabilityMapLegendItem.vue';
import {Warehouse} from "@/core/interfaces/warehouses";
import {HealthcenterGroupMember} from "@/disruption-logistics/interfaces/availability-providers";

export default defineComponent({
    name: 'DistributorAvailabilityMapLegend',
    components: { DistributorAvailabilityMapLegendItem },
    computed: {
        warehouse (): Warehouse {
            return {
                name: 'Alliance Healthcare Blois',
                street_address_line1: '28 place Gaston Paillhou',
                postal_code: '37000',
                city_name: 'Tours',
                country: 'fr',
                geo_lon: 0,
                geo_lat: 0,
                id: 0,
                wholesaler_id: 0,
                warehouse_code: '',
                cip: '',
                street_number: '',
            };
        },
        members (): HealthcenterGroupMember[] {
            return [
        {
            id: 1,
            name: 'Pharmacie de la Gare',
            cip: '12345678',
            address: {
                postal_code: '37000',
                city_name: 'Tours',
                id: 0,
                formatted: '28 place de la gare, 37000 Tours',
            },
            wholesaler_warehouses: [{
                id: 0,
                rank: 1
            }]
        } as HealthcenterGroupMember,
        {
            id: 2,
            name: 'Pharmacie Centrale',
            cip: '2345678',
            address: {
                postal_code: '37000',
                city_name: 'Tours',
                id: 0,
                formatted: '28 place de la gare, 37000 Tours',
            }
        } as HealthcenterGroupMember
            ];
        }
    }
});
</script>
