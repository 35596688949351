import {AxiosInstance} from "axios";

export default function (client: AxiosInstance) {
    return {
    /**
     * Get the mass certification message template index.
     *
     * @return {Promise<Array>}
     */
        index (params = {}) {
            return client.get('mass-certification/notification-templates', { params })
                .then(response => response.data.data);
        }
    };
}
