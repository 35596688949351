import {programAttachmentApi} from '@/container';

export default {
    /**
     * Download the program attachment, and open it in a new browser window.
     *
     * @param {'programs' | 'program-bundles' | 'program-categories' | 'interviews'} attachableType - The attachment attachable type.
     * @param {Number} attachableId - The attachment attachable id.
     * @param {Number} attachmentId - The attachment id.
     *
     * @return {Promise<any>}
     */
    downloadAttachmentAndOpen(attachableType, attachableId, attachmentId) {
        return programAttachmentApi()
            .renderAttachment(attachableType, attachableId, attachmentId);
    }
};
