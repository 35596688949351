<template>
  <div>
    <h2 class="text-lg">
      Entretiens du patient
    </h2>
    <div class="mt-2">
      <div v-if="!instances?.length">
        <p>Aucun entretien n'a été effectué pour ce patient</p>
      </div>
      <div
        v-else
        class="grid grid-cols-1 md:grid-cols-3 gap-3"
      >
        <div
          v-for="instance in instances"
          :key="instance.id"
        >
          <button
            v-if="instance.interview"
            class="bg-white p-3 rounded-md shadow-md flex items-center"
            @click="show(instance)"
          >
            <span class="flex flex-1">{{ instance.interview.name }}</span>
            <chevron-right-icon
              :size="15"
              class="text-primary"
            />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import {surveyAnswerInstanceApi} from '@/container';
import ChevronRightIcon from '@/core/components/icons/ChevronRightIcon.vue';

export default defineComponent({
    name: 'CustomerInterviewGrid',
    components: {ChevronRightIcon},
    props: {
        customer: {
            type: Object,
            required: true
        },
        instances: {
            type: Array<any>,
            required: true
        }
    },
    methods: {
        /**
         * Export the survey instance of the interview.
         *
         * @param {Object} instance
         * @returns {Promise<void>}
         */
        show(instance) {
            return surveyAnswerInstanceApi()
                .export(instance.survey_answer_instance_id)
                .then(blob => {
                    const url = window.URL.createObjectURL(blob);
                    window.open(url);
                });
        }
    }
});
</script>
