<template>
  <div class="relative">
    <div class="absolute z-1 -bottom-7 right-4 inline-flex">
      <BaseButton
        v-if="!isLoading && !medicineCabinet"
        class="pt-2 pb-2 px-4 mr-5 rounded-full bg-white"
        @click="show"
      >
        <div class="flex flex-row items-center">
          <strong class="py-1 text-primary mr-2">
            Envoyer un sms
          </strong>
        </div>
      </BaseButton>
    </div>
    <v-skeleton-loader v-if="isLoading" />
    <div
      v-else
      class="flex flex-1 flex-row cursor-pointer border-green"
      @click="navigateToProgram"
    >
      <ProgramLogo
        :program="program"
        :size="75"
        :shadow="true"
        class="mr-5"
      />
      <div
        v-if="medicineCabinet"
        class="bg-white shadow rounded-md sm:rounded-lg sm:overflow-hidden min-w-max flex flex-1 flex-row align-center justify-space-between"
      >
        <div class="px-2 py-3 sm:px-6">
          <h3
            class="text-md font-medium text-primary"
          >
            Voir l'armoire à pharmacie
          </h3>
          <p class="mt-1 text-sm text-gray-900">
            {{ medicineCabinet.item_count }} produits dans l'armoire ({{ medicineCabinet.expired_count }} périmés)
          </p>
        </div>
        <p class="text-5xl text-primary mr-5">
          >
        </p>
      </div>
      <div
        v-else
        class="shadow rounded-md sm:rounded-lg sm:overflow-hidden min-w-max flex flex-1 flex-row align-start"
        style="background-color: #D7E5E2"
      >
        <div class="px-2 py-3 sm:px-6">
          <h3
            class="text-md font-small"
            style="color: #0B614D"
          >
            {{ program?.teaser || "" }}
          </h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import {medicineCabinetApi} from '@/container';
import ProgramLogo from '@/program/components/program-logo/ProgramLogo.vue';
import programHelper from '@/core/helpers/programHelper';
import BaseButton from '@/core/components/base/BaseButton.vue';
import {mapState} from "pinia";
import {useSystemStore} from "@/stores/system.store";
import loaderGif from '@/assets/loader.gif';

export default defineComponent({
    name: 'CustomerMedicineCabinetCard',
    components: {BaseButton, ProgramLogo},
    props: {
        medicineCabinetId: {
            type: Number,
            required: true
        },
        program: {
            type: Object,
            required: true
        },
        customer: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            isLoading: false,
            medicineCabinet: undefined as any,
            downloading: false,
            loaderGif
        };
    },
    mounted() {
        this.load();
    },
    computed: {
        ...mapState(useSystemStore, ['environment'])
    },
    methods: {
        load() {
            this.isLoading = true;
            if (this.medicineCabinetId && this.environment) {
                medicineCabinetApi()
                    .readMedicineCabinet(this.environment.entityId, this.medicineCabinetId)
                    .then(response => {
                        this.medicineCabinet = response;
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            } else {
                this.isLoading = false;
            }
        },
        navigateToProgram() {
            const route = programHelper.generateRoute(this.program, this.customer, this.customer);

            if (route) {
                this.$router.push(route);
            }
        },
        show() {
            this.$router.push({
                name: 'customer.certification',
                params: {customerId: this.customer.id}
            });
        }
    }
});
</script>
