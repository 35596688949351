<template>
  <div class="h-full">
    <section v-show="beneficiary?.programs">
      <ConsumerPrograms
        v-if="beneficiary"
        :customer="customer"
        :consumer="beneficiary"
        :consumers="[beneficiary]"
      />
    </section>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from "vue";
import ConsumerPrograms from '@/consumer/components/consumer-programs/ConsumerPrograms.vue';
import Beneficiary from "@/customer/interfaces/Beneficiary";
import Customer from "@/customer/interfaces/Customer";

export default defineComponent({
    components: {
        ConsumerPrograms
    },

    props: {
        customer: {
            type: Object as PropType<Customer>,
            required: true
        }
    },

    data() {
        return {
            qrcode: null,
            memberships: null,
            programs: null
        };
    },
    computed: {
        beneficiary(): Beneficiary | undefined {
            return this.customer.beneficiaries.find((beneficiary: Beneficiary) => beneficiary.id === Number(this.$route.params.id));
        }
    }
});
</script>
