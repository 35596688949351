<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20.125"
    height="18.578"
    viewBox="0 0 20.125 18.578"
    :class="`fill-current h-${size} w-${size}`"
  >
    <path
      id="print"
      d="M4.644,17.029H15.481v-3.1H4.644Zm0-7.74H15.481V4.644H13.546a1.157,1.157,0,0,1-1.161-1.161V1.548H4.644v7.74Zm13.933.774a.785.785,0,1,0-.23.545.744.744,0,0,0,.23-.545Zm1.548,0v5.031a.393.393,0,0,1-.387.387H17.029v1.935a1.157,1.157,0,0,1-1.161,1.161H4.257A1.157,1.157,0,0,1,3.1,17.417V15.482H.387a.372.372,0,0,1-.272-.115A.371.371,0,0,1,0,15.094V10.063A2.235,2.235,0,0,1,.683,8.424,2.237,2.237,0,0,1,2.322,7.74H3.1V1.161A1.12,1.12,0,0,1,3.435.339,1.12,1.12,0,0,1,4.258,0h8.127a2.792,2.792,0,0,1,1.064.242,2.814,2.814,0,0,1,.919.58L16.206,2.66a2.81,2.81,0,0,1,.58.919,2.8,2.8,0,0,1,.242,1.064v3.1H17.8a2.333,2.333,0,0,1,2.322,2.323Z"
      transform="translate(0)"
      fill="#"
    />
  </svg>
</template>

<script>
export default {
    name: 'Print',
    props: {
        size: {
            type: Number,
            required: true
        }
    }
};
</script>

<style scoped>

</style>
