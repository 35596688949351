<template>
  <div class="flex flex-1 flex-row flex-wrap gap-x-3 gap-y-2 px-6 py-2">
    <v-chip
      v-for="tag in props.tags"
      :key="tag.slug"
      :variant="tagVariant(tag)"
      :color="tagColor(tag)"
      @click="toggleTags(tag.slug)"
    >
      {{ tag.label }}
    </v-chip>
    <v-chip
      v-if="props.tags.length"
      :key="'certified'"
      :variant="activeVariant(selectedTags.certified)"
      :color="selectedTags.certified ? 'green' : 'default'"
      @click="toggleCertified"
    >
      <Icon icon="mdi-check-circle" start :color="selectedTags.certified ? 'white' : 'default'" />
      Certifié
    </v-chip>
    <v-chip
      v-if="props.tags.length"
      :key="'enfants'"
      :variant="activeVariant(selectedTags.children)"
      :color="selectedTags.children ? 'green' : 'default'"
      @click="toggleChildren"
    >
      <Icon icon="mdi-car-child-seat" start :color="selectedTags.children ? 'white' : 'default'" />
      Enfants
    </v-chip>
  </div>
</template>

<script lang="ts" setup>
import {Tag} from "@/prescription/interfaces/Prescription";
import {PropType, computed} from "vue";
import Icon from "@/core/components/icon/Icon.vue";

const props = defineProps({
    tags: {
        type: Array as PropType<Tag[]>,
        required: true
    }
});

/**
 * The bound model.
 */
const selectedTags = defineModel<{
    tags: string[];
    certified: boolean;
    children: boolean;
}>({default: {meta: [], certified: false}});

/**
 * Variant dynamic property of the tag.
 */
const tagVariant = computed(() => {
    return (tag: Tag): 'flat' | 'tonal' => {
        return selectedTags.value.tags.includes(tag.slug) ? 'flat' : 'tonal';
    };
});

/**
 * Active variant dynamic property of the tag.
 */
const activeVariant = computed(() => {
    return (state: boolean): 'flat' | 'tonal' => {
        return state ? 'flat' : 'tonal';
    };
});

/**
 * Color dynamic property of the tag.
 */
const tagColor = computed(() => {
    return (tag: Tag): 'default' | 'green' => {
        return selectedTags.value.tags.includes(tag.slug) ? 'green' : 'default';
    };
});

/**
 * Toggle Tags.
 * @param tag
 */
const toggleTags = (tag: string) => {
    const index = selectedTags.value.tags.indexOf(tag);

    if (index === -1) {
        selectedTags.value.tags.push(tag);
    } else {
        selectedTags.value.tags.splice(index, 1);
    }
};

/**
 * Toggle the certified tag.
 */
const toggleCertified = () => {
    selectedTags.value.certified = !selectedTags.value.certified;
};

/**
 * Toggle the certified tag.
 */
const toggleChildren = () => {
    selectedTags.value.children = !selectedTags.value.children;
};

</script>
