<script setup lang="ts">
import {HomeInterviewInstanceItem} from "@/home/interfaces";
import HomeLiveItem from "@/home/components/HomeLiveItem.vue";
import formatHelper from "@/core/helpers/formatHelper";
import Icon from "@/core/components/icon/Icon.vue";
import {InterviewInstance} from "@/interview/interfaces/interview";
import {useRouter} from "vue-router";

const router = useRouter();
defineProps<{
    item: HomeInterviewInstanceItem['data']
}>();

/**
 * Redirect to the interview instance view.
 */
const goToInstance = (instance: InterviewInstance) => {
    router.push({
        name: 'interview-instances',
        query: {
            interviewId: instance.interview.id.toString(),
            consumerKey: instance.consumer_key,
            interviewInstanceId: instance.id.toString(),
            interviewName: instance.interview.name,
            backingId: instance.backing_id?.toString()
        }
    });
};
</script>

<template>
  <HomeLiveItem
    :time-ago="formatHelper.readableDate(item.started_at)"
    bg-color-class="bg-gray-300"
    @click="goToInstance(item)"
  >
    <template #image>
      <Icon name="mdi-chat" :size="40" color="primary" />
    </template>
    <p>Compte rendu d'entretien à finaliser : <strong>{{ item.interview.name }}</strong></p>
  </HomeLiveItem>
</template>
