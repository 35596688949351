<template>
  <div class="flex w-full items-center justify-center">
    <div class="container max-h-screen overflow-y-auto mx-auto px-4 sm:px-6 lg:px-8 flex flex-col">
      <img
        class="w-56 absolute top-2 left-4 cursor-pointer"
        :src="apodisTextWhite"
        @click="$emit(backToHome())"
      >
      <div class="text-3xl text-white text-center">
        Veuillez vous connecter avec Pro Santé Connect afin que nous vérifions votre identité
      </div>
      <div class="mt-24 mb-4 text-md text-white text-center">
        M'identifier avec
      </div>
      <button
        type="button"
        :class="`self-center`"
        @click="launchProSanteConnect"
      >
        <img
          class="w-80"
          :src="pscButton"
        >
      </button>
    </div>
  </div>
</template>

<script type="ts">
import {defineComponent} from "vue";
import pscHelper, {PSC_STATES} from '@/core/helpers/pscHelper';
import {storageService} from '@/container';
import pscButton from '@/assets/images/logos/psc-button.svg';
import apodisTextWhite from '@/assets/images/logos/apodis-text-white.png';

export default defineComponent({
    name: 'LinkCPS',
    data() {
        return {
            pscButton,
            apodisTextWhite
        };
    },
    methods: {
        launchProSanteConnect() {
            if (window.ipcRenderer) {
                window.ipcRenderer.logWithProSanteConnect(PSC_STATES.linkcps);
            } else {
                window.location.href = pscHelper.pscLinkURL();
            }
        },
        backToHome() {
            storageService().destroy('token');
            this.$router.push({name: 'login'});
        }
    }
});
</script>
