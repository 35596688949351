<template>
  <BaseFlexSpinner v-if="loading" />
  <div
    v-else
    class="flex flex-column"
  >
    <div class="flex flex-row items-center p-5">
      <h1 class="text-primary text-5xl mr-2">
        {{ medicineCabinet.item_count }}
      </h1>
      <p>produits dans son armoire</p>
    </div>
    <div
      v-if="medicineCabinet.items.length > 0"
      class="bg-white rounded-md shadow m-2 grid xl:grid-cols-2"
    >
      <CustomerMedicineCabinetRow
        v-for="section in sections"
        :key="`${section.key}`"
        :row="section"
      />
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import {medicineCabinetApi} from '@/container';
import BaseFlexSpinner from '@/core/components/base/spinner/BaseFlexSpinner.vue';
import CustomerMedicineCabinetRow from '@/customer/components/customer-medicine-cabinet/CustomerMedicineCabinetRow.vue';
import {mapState} from "pinia";
import {useSystemStore} from "@/stores/system.store";

export default defineComponent({
    name: 'CustomerMedicineCabinet',
    components: {CustomerMedicineCabinetRow, BaseFlexSpinner},
    props: {
        program: {
            type: Object,
            required: true
        },
        customer: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            medicineCabinet: null as any,
            loading: true
        };
    },
    computed: {
        ...mapState(useSystemStore, ['environment']),
        sections() {
            if (this.medicineCabinet.items) {
                const items = this.medicineCabinet.items;
                const sortedItems = items.sort(
                    (a, b) => a.product.name.localeCompare(b.product.name)
                );

                return sortedItems.reduce((sections, item) => {
                    if (item.category) {
                        const categoryId = item.category.id;
                        const sectionIndex = sections.findIndex(section => section.key === categoryId);

                        if (sectionIndex !== -1) {
                            const itemIndex = sections[sectionIndex].data.findIndex(
                                sectionItem => sectionItem.product.id === item.product.id
                            );
                            if (itemIndex !== -1) {
                                sections[sectionIndex].data[itemIndex].quantity += item.quantity;
                            } else {
                                sections[sectionIndex].data.push(item);
                            }
                        } else {
                            sections.push({
                                title: item.category.name,
                                data: [item],
                                key: categoryId
                            });
                        }
                    }

                    return sections;
                }, []);
            }

            return [];
        }
    },
    mounted() {
        this.load();
    },
    methods: {
        load() {
            if (this.environment) {
                this.loading = true;
                medicineCabinetApi()
                    .readMedicineCabinet(this.environment.entityId, this.customer.medicine_cabinet_id)
                    .then(response => {
                        this.medicineCabinet = response;
                    })
                    .catch(() => {
                        this.$router.push({
                            name: 'customer.home',
                            params: {
                                customerId: this.customer.id
                            }
                        });
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }
        }
    }
});
</script>
