<template>
  <div
    v-if="extensions.length"
    class="flex flex-col gap-3"
  >
    <h3 class="text-lg leading-6 font-medium text-gray-900">
      {{ title }}
    </h3>
    <ul
      role="list"
      class="flex flex-row justify-start items-start flex-wrap gap-x-10 gap-y-6"
    >
      <li
        v-for="extension in extensions"
        :key="extension.id"
        class="flex flex-col cursor-pointer"
        @click="$emit('extensionClick', extension)"
      >
        <ExtensionLogo
          :extension="extension"
        />
        <p
          class="text-gray-900 text-sm font-medium text-start break-words mt-1 line-clamp-2"
          style="max-width: 100px;"
        >
          {{ extension.name }}
        </p>
      </li>
    </ul>
  </div>
</template>

<script lang="ts" setup>
import Extension from "@/core/interfaces/extension/Extension";
import ExtensionLogo from "@/extension/components/extension-logo/ExtensionLogo.vue";

defineEmits<{
    (e: 'extensionClick', extension: Extension): void;
}>();
defineProps<{
    title: string;
    extensions: Extension[];
}>();
</script>
