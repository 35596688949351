<script setup lang="ts">
import ExtensionGrid from "@/program/components/program-grid/ExtensionGrid.vue";
import {Extension, ProgramExtension} from "@/core/interfaces/extension/Extension";

defineEmits<{
    (e: 'extensionClick', extension: Extension): void;
}>();
const props = defineProps<{
    programExtensions: ProgramExtension[]
}>();

const filterProgramType = (type: string) => {
    return props.programExtensions.filter(extension => extension.sub_type === type);
};
</script>

<template>
  <ExtensionGrid
    :title="'Programmes'"
    :extensions="filterProgramType('support')"
    @extension-click="(extension) => $emit('extensionClick', extension)"
  />
  <ExtensionGrid
    :title="'Services'"
    :extensions="filterProgramType('feature')"
    @extension-click="(extension) => $emit('extensionClick', extension)"
  />
  <ExtensionGrid
    :title="'Offres Commerciales'"
    :extensions="filterProgramType('discount')"
    @extension-click="(extension) => $emit('extensionClick', extension)"
  />
  <ExtensionGrid
    :title="'Cartes de Fidélité'"
    :extensions="filterProgramType('loyalty-card')"
    @extension-click="(extension) => $emit('extensionClick', extension)"
  />
</template>
