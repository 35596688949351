<template>
  <base-card>
    <div class="flex flex-row w-full gap-x-4 p-2">
      <div class="flex w-2/3 min-h-[713px] bg-gray-100 rounded-lg">
        <secure-image :urls="urls" />
      </div>
      <div class="flex flex-col truncate pt-5 gap-y-2">
        <p class="font-bold">
          Le {{ $filters.date(prescription.prescribed_at || prescription.dispensed_at, 'short') }},
          Dr. {{ prescription.prescriptor?.last_name || 'Anonyme' }}
        </p>
        <ul class="truncate">
          <li v-for="(product, index) in prescription.products" :key="index">
            <span>{{ product.quantity }} x </span>
            <span class="text-sm">{{ product.name }}</span>
          </li>
        </ul>
        <prescription-renewal-button class="mt-4" @click="onRenewalClick" />
      </div>
    </div>
  </base-card>
</template>

<script lang="ts" setup>
import {PropType, computed} from "vue";
import SecureImage from '@/core/components/secure-image/SecureImage.vue';
import BaseCard from '@/core/components/base/BaseCard.vue';
import FileUrl from "@/core/interfaces/FileUrl";
import {ProgramPrescription} from "@/prescription/interfaces/Prescription";
import PrescriptionRenewalButton from "@/prescription/components/PrescriptionRenewalButton.vue";
import {usePatientStore} from "@/stores/patient.store";
import {useRouter} from "vue-router";

const router = useRouter();
const store = usePatientStore();

const props = defineProps({
    /** The prescription. */
    prescription: {
        type: Object as PropType<ProgramPrescription>,
        required: true
    },
    /** The consumer key. */
    consumerKey: {
        type: String,
        required: true
    }
});

/** The prescription media urls. */
const urls = computed((): FileUrl[] => {
    const route = `prescription-documents/${props.prescription.document_key}?consumer_key=${props.consumerKey}`;

    return [{
        'pdf': import.meta.env.VITE_HDS_URL + `/${route}`,
        'raw': `${route}&format=raw`,
        'thumbnail': {
            'large': `${route}&format=large`,
            'small': `${route}&format=small`
        }
    }];
});

/**
 * The renewal click event handler.
 */
const onRenewalClick = () => {
    router.push({
        name: 'patient-administration.renewal.edit',
        params: {prescriptionId: props.prescription.key},
        query: {
            customerId: store.customer!.id
        }
    });
};

</script>
