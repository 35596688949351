import {RouteRecordRaw} from "vue-router";
import StripeSuccessViews from "@/stripe/views/StripeSuccessViews.vue";

const routes: RouteRecordRaw[] = [
    {
        path: '/stripe/success',
        name: 'stripe-success',
        component: StripeSuccessViews,
        meta: {
            layout: 'TheBlankLayout'
        }
    }
];

export default routes;
