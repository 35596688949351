<template>
  <SupportProgramDocumentGrid
    v-if="consumerProtocol.unserializedOptions.protocol?.attachments?.length > 0"
    title="Ressources pour vous"
    description="Prenez connaissance des documents suivants pour conseiller au mieux votre patient"
    :attachments="consumerProtocol.unserializedOptions.protocol?.attachments"
  />
</template>

<script lang="ts">
import {defineComponent} from "vue";
import SupportProgramDocumentGrid from '@/program/components/support-program/SupportProgramDocumentGrid.vue';

export default defineComponent({
    name: 'SupportProgramProtocol',
    components: {
        SupportProgramDocumentGrid
    },
    props: {
        consumerProtocol: {
            type: Object,
            required: true
        }
    }
});
</script>
