import {AxiosResponse} from "axios";
import {ProductSummary} from "@/product/interfaces/ProductSummary";

/**
 * The customer product resource.
 *
 * @param {Object} client - The injected client.
 * @param {Object} hdsClient - The injected hdsClient.
 * @returns {Object} The public object.
 */
export default function (client, hdsClient) {
    return {
        /**
         * Index of sales products.
         *
         * @param consumerKey
         */
        index(consumerKey) {
            return hdsClient.get(`consumers/${consumerKey}/sales-products`)
                .then(response => response.data.data);
        },
        /**
         * Read customer products.
         *
         * @param {string} consumerKey - The consumerKey of the customer.
         * @returns {Promise} Promise object represents the HTTP request.
         */
        read(consumerKey: string): Promise<ProductSummary[]> {
            return client.get(`/consumers/${consumerKey}/products`)
                .then((response: AxiosResponse) => response.data.data);
        },

        /**
         * Patch customer products.
         *
         * @param consumerKey
         * @param productIds
         */
        patch(consumerKey: string, productIds: number[]) {
            const data = {'ids': productIds};
            return client.patch(`/consumers/${consumerKey}/products`, {data})
                .then((response: AxiosResponse) => response.data.data);
        },
    };
}
