<template>
  <div class="p-4">
    <span class="text-primary font-bold text-xl">Nouveautés</span>
    <div
      v-for="(release, index) in releases"
      :key="index"
      class="bg-white rounded-lg my-6 p-4"
    >
      <div class="flex flex-row justify-between pb-4">
        <span class="font-bold">Version {{ release.fields.version }}</span>
        <span>{{ $filters.date(release.fields.releaseDate, 'dayOnly') }}</span>
      </div>
      <RichTextRenderer
        :document="release.fields.content"
        :node-renderers="contentfulHelper.renderNodes()"
      />
    </div>
  </div>
</template>

<script>
import { contentfulService } from '@/container';
import contentfulHelper from '@/core/helpers/contentfulHelper.js';
import RichTextRenderer from 'contentful-rich-text-vue-renderer';

export default {
    name: 'PatchNotesScreen',
    components: {RichTextRenderer},
    data () {
        return {
            releases: []
        };
    },
    computed: {
        contentfulHelper () {
            return contentfulHelper;
        }
    },
    mounted () {
        this.getReleases();
    },
    methods: {
        getReleases () {
            contentfulService().releases()
                .then(response => {
                    this.releases = response;
                });
        }
    }
};

</script>
