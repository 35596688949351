import {defineStore} from "pinia";
import {Credit} from "@/core/interfaces/Credit";
import {creditApi} from "@/container";

export const useCreditStore = defineStore('credit', {
    state: () => ({
        credit: null as Credit | null
    }),
    actions: {
        refresh() {
            creditApi().read()
                .then(response => this.credit = response);
        }
    }
});
