import {surveyAnswerInstanceApi} from "@/container";

const SurveyAnswerInstanceService = () => {
    /**
   * Export the instance.
   * And open the exported file.
   *
   * @param instanceId
   * @returns {Promise<void>}
   */
    const exportAndOpen = (instanceId: number) => {
        return surveyAnswerInstanceApi()
            .export(instanceId)
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                window.open(url);
            });
    };

    return {
        exportAndOpen
    };
};

export default SurveyAnswerInstanceService();
