<template>
  <base-container>
    <header-back-button title="Info dispo" />
    <div class="p-6 flex flex-col gap-3">
      <product-search-bar replace-route />
      <h1 class="text-primary">
        {{ product.name }} <span class="text-sm text-black font-content">{{ product.code }}</span>
      </h1>
      <product-availability-card-grid
        :request-token="String($route.query.requestToken)"
        :product-id="Number(product.id)"
        @availability-loaded="handleAvailabilityData"
      />
      <healthcenter-group-availability-map
        :product="product"
        :data="healthcenterGroups?.details"
      />
      <distributor-availability-map
        :product="product"
        :data="groupWholesalers?.details"
      />
      <product-availability-statistics-card
        :product-id="Number($route.params.productId)"
        :request-token="$route.query.requestToken as string"
      />
    </div>
  </base-container>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import HeaderBackButton from "@/core/components/header-back-button/HeaderBackButton.vue";
import BaseContainer from "@/core/components/base/BaseContainer.vue";
import ProductSearchBar from "@/disruption-logistics/components/product-search-bar/ProductSearchBar.vue";
import ProductAvailabilityCardGrid
    from "@/disruption-logistics/components/product-availability-card/ProductAvailabilityCardGrid.vue";
import {AVAILABILITY_PROVIDERS} from "@/core/http/resources/DisruptionLogisticsResource";
import HealthcenterGroupAvailabilityMap
    from "@/disruption-logistics/components/maps/healthcenter-groups/HealthcenterGroupAvailabilityMap.vue";
import DistributorAvailabilityMap
    from "@/disruption-logistics/components/maps/distributors/DistributorAvailabilityMap.vue";
import {
    AvailabilityProviderData,
    HealthcenterGroup,
    HealthcenterGroupDistributor
} from "@/disruption-logistics/interfaces/availability-providers";
import ProductAvailabilityStatisticsCard
    from "@/disruption-logistics/components/availability-statistics/ProductAvailabilityStatisticsCard.vue";

export default defineComponent({
    name: "DisruptionLogisticsProductAvailabilityView",
    components: {
        ProductAvailabilityStatisticsCard,
        DistributorAvailabilityMap,
        HealthcenterGroupAvailabilityMap,
        ProductAvailabilityCardGrid, ProductSearchBar, BaseContainer, HeaderBackButton},
    data() {
        return {
            healthcenterGroups: null as null | HealthcenterGroup,
            groupWholesalers: null as null | HealthcenterGroupDistributor
        };
    },
    computed: {
        product() {
            return {
                id: this.$route.params.productId,
                name: this.$route.query.productName,
                code: this.$route.query.productCode
            };
        }
    },
    watch: {
        '$route.params.productId': {
            immediate: true,
            handler() {
                this.healthcenterGroups = null;
                this.groupWholesalers = null;
            }
        }
    },
    methods: {
        /**
       * Store loaded data in the component
       */
        handleAvailabilityData({provider, data}: { provider: string; data: AvailabilityProviderData }) {
            if (provider === AVAILABILITY_PROVIDERS.healthcenterGroups) {
                this.healthcenterGroups = data as HealthcenterGroup;
            }

            if (provider === AVAILABILITY_PROVIDERS.healthcenterGroupsDistributor) {
                this.groupWholesalers = data as HealthcenterGroupDistributor;
            }
        }
    }
});
</script>
