import {StripePrice} from "@/stripe/interfaces";

/**
 * Stripe price service.
 */
const StripePriceService = () => {
    return {
        /**
         * Get the lowest price from a list of prices.
         */
        lowestPrice: (prices?: StripePrice[]): StripePrice | null => {
            if (!prices || prices.length === 0) {
                return null;
            }

            return prices.find(price => {
                if (price?.price) {
                    return parseInt(price.price) === Math.min(...prices.map(price => parseInt(price?.price || "")));
                }

                return false;
            }) || prices[0];
        },
        /**
         * Make a human readable price.
         */
        formatPrice: (priceInfo: StripePrice): string => {
            const {price, recurring} = priceInfo;
            const intervalMapping = {
                "day": "jour",
                "week": "semaine",
                "month": "mois",
                "year": "an"
            };

            const interval = intervalMapping[recurring.interval] || recurring.interval;
            const intervalCount = recurring.interval_count > 1 ? recurring.interval_count : '';

            if (price) {
                const priceFormatted = `${parseFloat(price).toLocaleString('fr-FR', {
                    style: 'currency',
                    currency: 'EUR'
                })}`;

                return `${priceFormatted}/${intervalCount ? `${intervalCount} ` : ''}${interval}`;
            }

            return '';
        }
    };
};

export default StripePriceService();
