<template>
  <div class="bg-white flex items-center p-3 gap-3">
    <button
      class="flex items-center cursor-pointer"
      @click="$router.go(-1)"
    >
      <icon
        name="mdi-arrow-left"
        class="text-primary"
        :size="20"
      />
    </button>
    <p
      v-if="title"
      class="text-lg text-primary"
    >
      {{ title }}
    </p>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import Icon from "@/core/components/icon/Icon.vue";

export default defineComponent({
    name: "HeaderBackButton",
    components: {Icon},
    props: {
        title: {
            type: String,
            default: null
        }
    }
});
</script>

<style scoped>

</style>
