<template>
  <div>
    <div class="flex flex-row mt-10">
      <icon
        name="mdi-pill"
        :size="24"
        :color="'#3513e2'"
      />
      <p class="ml-2 text-primary">
        Produits commandés ({{ orderProducts.length }})
      </p>
    </div>
    <div
      v-for="(orderProduct, index) in orderProducts"
      :key="index"
      class="flex flex-row my-4"
    >
      <icon
        name="mdi-cart-outline"
        :size="24"
        :color="'#3513e2'"
      />
      <p class="mx-5">
        {{ orderProduct.quantity }}x
      </p>
      <p class="flex flex-1">
        {{ orderProduct.product.name }}
      </p>
      <p>{{ orderProduct.total_price }}€</p>
    </div>
    <div
      v-if="!!order?.user_comment"
      class="flex flex-row mt-4 items-center"
    >
      <p class="my-5">
        Commentaire du patient : {{ order.user_comment }}
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import Icon from '@/core/components/icon/Icon.vue';

export default defineComponent({
    name: 'OrderProducts',
    components: { Icon },
    props: {
        order: {
            type: Object,
            required: true
        }
    },
    computed: {
        orderProducts () {
            if (this.order) {
                return this.order?.content?.products || this.order?.content?.items || [];
            }

            return [];
        }
    }
});

</script>
