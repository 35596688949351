<template>
  <div
    v-if="!subscribed"
    class="flex flex-col gap-2"
  >
    <div class="flex justify-between items-center">
      <label
        class="text-sm font-bold request-state-label"
      >
        Il vous reste{{ " " }}
        <v-skeleton-loader
          :loading="loading"
          class="bg-transparent"
          :class="{ 'top-1': loading }"
          style="display: inline-block"
          min-width="50"
          type="text"
        >
          <span>{{ remainingRequests ?? 0 }}</span>
        </v-skeleton-loader>
        {{ $filters.pluralize('recherche', remainingRequests ?? 0) }} cette semaine
      </label>
      <router-link
        v-if="disruptionLogisticsSubscriptionPlan"
        :to="{name: 'subscription-plan', params: {'planId': disruptionLogisticsSubscriptionPlan.id}}"
        class="text-primary text-sm font-bold"
      >
        Passez en illimité !
      </router-link>
    </div>
    <div
      v-if="(remainingRequests ?? 0) <= 0 && !loading"
      class="gradient p-3 rounded-md text-white flex flex-col gap-3"
    >
      <h3 class="font-content-bold">
        Profitez d'un accès illimité dès maintenant 🚀
      </h3>
      <p>
        Vous avez atteint la limite de votre utilisation gratuite. Ne vous inquiétez pas, vos crédits de recherche
        seront disponibles lundi prochain.
      </p>
      <p>
        <span class="font-bold">Mais pourquoi attendre ?</span> Passez à l'illimité maintenant et continuez vos
        recherches sans attendre !
      </p>
      <router-link
        v-if="disruptionLogisticsSubscriptionPlan"
        :to="{name: 'subscription-plan', params: {'planId': disruptionLogisticsSubscriptionPlan.id}}"
        class="text-white text-sm font-bold"
      >
        Passez en illimité !
      </router-link>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import {mapState} from "pinia";
import {useDisruptionLogisticsRequestStore} from "@/stores/disruptionLogisticsRequest.store";

export default defineComponent({
    name: "RequestState",
    computed: {
        ...mapState(useDisruptionLogisticsRequestStore, [
            'remainingRequests',
            'loading',
            'subscribed',
            'disruptionLogisticsSubscriptionPlan'
        ])
    }
});
</script>

<style>
.request-state-label .v-skeleton-loader > .v-skeleton-loader__text {
  margin: 0;
}
</style>
