<template>
  <div class="bg-white shadow-md p-4 rounded-md flex flex-col gap-6">
    <div class="flex gap-2 items-end">
      <span>
        <v-icon
          color="orange"
          size="40"
        >
          mdi-warehouse
        </v-icon>
      </span>
      <p> Agence #{{ rank }}</p>
    </div>
    <div class="flex flex-col">
      <div class="flex flex-col md:flex-row gap-x-3 gap-y-2 md:items-center">
        <label class="text-sm">Grossiste</label>
        <v-select
          v-model="selectedWholesalerId"
          item-title="name"
          item-value="id"
          class="w-full"
          dense
          :items="availableWholesalers"
          placeholder="Sélectionnez un grossiste"
          @change="handleWholesalerSelected"
        />
      </div>
      <div class="flex flex-col md:flex-row gap-x-3 gap-y-2 md:items-center">
        <label class="text-sm">Agence</label>
        <v-select
          v-model="selectedWarehouseId"
          dense
          item-title="name"
          item-value="id"
          :items="availableWarehouses ?? []"
          placeholder="Sélectionnez un entrepôt"
          no-data-text="Veuillez d'abord sélectionner un grossiste"
        />
      </div>
      <div class="flex flex-col md:flex-row gap-x-3 gap-y-2 md:items-center">
        <label class="text-sm">Accès</label>
        <v-select
          v-model="selectedHealthcenterWarehouseId"
          item-title="name"
          item-value="id"
          dense
          :items="availableCredentials ?? []"
          placeholder="Sélectionnez des accès"
          no-data-text="Veuillez d'abord sélectionner un grossiste"
          hint="Sélectionnez l’accès sur lequel vous passez votre flux principal"
          persistent-hint
          @change="handleCredentialChange"
        />
      </div>
    </div>
    <v-divider v-if="selectedCredential" />
    <div
      v-if="selectedCredential"
      class="flex flex-col gap-2 text-sm"
    >
      <p class="text-base">
        Vérifiez les informations de l’accès, puis cliquez sur Enregistrer.
      </p>
      <p>
        Contactez nous via <a
          class="text-primary"
          href="mailto:contact@apodispharma.com"
        >contact@apodispharma.com</a> si les informations sont incorrectes
      </p>
      <p>
        <span class="font-bold">URL : </span> {{ selectedCredential.url }}
      </p>
      <p>
        <span class="font-bold">Code grossiste : </span> {{ selectedCredential.wholesaler.id }}
      </p>
      <p>
        <span class="font-bold">Code agence : </span> {{ selectedCredential.warehouseCode }}
      </p>
      <p>
        <span class="font-bold">Identifiant : </span> {{ selectedCredential.healthcenterCode }}
      </p>
      <p>
        <span class="font-bold">Clé : </span> {{ selectedCredential.healthcenterKey }}
      </p>
    </div>
    <div class="flex gap-4 justify-end">
      <div>
        <base-button @click="testCredential">
          <div class="flex gap-2 items-center">
            <span>
              {{
                credentialVerified === null
                  ? 'Tester les accès'
                  : credentialVerified ? 'Accès valides' : 'Accès invalides'
              }}
            </span>
            <v-icon
              v-if="credentialVerified !== null"
              :color="credentialVerified ? 'green': 'red'"
            >
              {{ credentialVerified ? 'mdi-check' : 'mdi-close' }}
            </v-icon>
          </div>
        </base-button>
      </div>
      <base-button
        primary
        class="self-end font-bold"
        :disabled="buttonDisabled"
        @click="save"
      >
        <span v-if="refreshing">
          <base-spinner
            color="white"
            size="button"
          />
        </span>
        <span v-else>Enregistrer</span>
      </base-button>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';
import {
    Wholesaler,
    Warehouse,
    WholesalerWarehouseHealthcenter
} from "@/disruption-logistics/interfaces/wholesalers";
import {wholesalerApi} from "@/container";
import {useToastStore} from "@/stores/toast.store";
import BaseButton from "@/core/components/base/BaseButton.vue";
import BaseSpinner from "@/core/components/base/spinner/BaseSpinner.vue";

export default defineComponent({
    name: "SelectWholesaler",
    components: {BaseSpinner, BaseButton},
    props: {
        warehouses: {
            type: Array as PropType<Warehouse[]>,
            required: false,
            default: () => []
        },
        healthcenterWarehouses: {
            type: Array as PropType<WholesalerWarehouseHealthcenter[]>,
            required: false,
            default: () => ([])
        },
        healthcenterWarehouse: {
            type: Object as PropType<WholesalerWarehouseHealthcenter>,
            required: false,
            default: () => null
        },
        rank: {
            type: Number,
            required: true
        },
        refreshing: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    emits: ['save'],
    data() {
        return {
            selectedHealthcenterWarehouseId: this.healthcenterWarehouse?.id as number | null,
            selectedWholesalerId: this.healthcenterWarehouse?.wholesaler?.id as number | null,
            selectedWarehouseId: this.healthcenterWarehouse?.wholesalerWarehouseId as number | null,
            credentialVerified: null as boolean | null
        };
    },
    computed: {
        /**
         * Get available wholesalers to display in the selector.
         */
        availableWholesalers(): Wholesaler[] | undefined {
            return this.healthcenterWarehouses?.map(warehouse => warehouse.wholesaler)
                .reduce((acc, wholesaler) => {
                    if (!acc.find(w => w.id === wholesaler.id)) {
                        acc.push(wholesaler);
                    }

                    return acc;
                }, [] as Wholesaler[]) ?? [];
        },
        /**
         * Get available warehouses to display in the selector.
         */
        availableWarehouses(): Warehouse[] {
            return this.warehouses
                .filter(warehouse => warehouse.wholesaler.id === this.selectedWholesalerId)
                .reduce((acc, warehouse) => {
                    if (!acc.find(w => w.id === warehouse.id)) {
                        acc.push(warehouse);
                    }

                    return acc;
                }, [] as Warehouse[]);
        },
        /**
         * Get available credentials to display in the selector.
         */
        availableCredentials(): WholesalerWarehouseHealthcenter[] {
            if (!this.selectedWholesalerId) {
                return [];
            }

            return this.healthcenterWarehouses
                .filter((healthcenterWarehouse) => {
                    return healthcenterWarehouse.wholesaler.id === this.selectedWholesalerId;
                });
        },
        /**
         * Get the selected credential.
         */
        selectedCredential(): WholesalerWarehouseHealthcenter | undefined {
            return this.availableCredentials
                .find(credential => credential.id === this.selectedHealthcenterWarehouseId);
        },
        /**
         * The save button disabled state.
         */
        buttonDisabled(): boolean {
            return !this.selectedHealthcenterWarehouseId || !this.selectedWarehouseId;
        }
    },
    watch: {
        healthcenterWarehouse: {
            handler(newValue) {
                this.selectedHealthcenterWarehouseId = newValue?.id;
                this.selectedWholesalerId = newValue?.wholesaler.id;
                this.selectedWarehouseId = newValue?.wholesalerWarehouseId;
            }
        }
    },
    methods: {
        /**
         * On wholesaler selected, reset the selected warehouse.
         */
        handleWholesalerSelected(): void {
            this.selectedWarehouseId = null;
        },
        /**
         * On credential change, reset the credential verification.
         */
        handleCredentialChange(): void {
            this.credentialVerified = null;
        },
        /**
         * Emit the save method.
         */
        save() {
            this.$emit('save', {
                healthcenterWarehouseId: this.selectedHealthcenterWarehouseId,
                warehouseId: this.selectedWarehouseId,
                wholesalerId: this.selectedWholesalerId,
                rank: this.rank
            });
        },
        /**
         * Make a HTTP call to test the credentials.
         */
        async testCredential(): Promise<void> {
            if (!this.selectedCredential?.id || !this.selectedWarehouseId) {
                return;
            }

            this.credentialVerified = null;

            return wholesalerApi()
                .testCredential(this.selectedCredential.id, this.selectedWarehouseId)
                .then(({success}) => {
                    this.credentialVerified = success;

                    if (!success) {
                        const toast = useToastStore();

                        toast.showError({content: 'La configuration de cet accès ne fonctionne pas, veuillez en sélectionner un autre ou contacter le support'});
                    }
                });
        }
    }
});
</script>
