<template>
  <product-availabity-card
    :title="`${availabilityLabel(data)} chez un grossiste d'une pharmacie partenaire`"
    loading-message="Interrogation des grossistes"
    :loading="data.loading"
    :is-available="data.availability"
    class="cursor-pointer"
    @click="scrollToDistributorMap"
  >
    <template #icon>
      <v-icon
        color="black"
        size="40"
        class="w-full"
      >
        mdi-human-dolly
      </v-icon>
    </template>
  </product-availabity-card>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';
import {
    AvailabilityState
} from "@/disruption-logistics/components/product-availability-card/ProductAvailabilityCardGrid.vue";
import {HealthcenterGroupDistributorDetails} from "@/disruption-logistics/interfaces/availability-providers";
import ProductAvailabityCard
    from "@/disruption-logistics/components/product-availability-card/ProductAvailabilityCard.vue";

export default defineComponent({
    name: "HealthcenterGroupDistributorAvailabilityCard",
    components: {ProductAvailabityCard},
    props: {
        data: {
            type: Object as PropType<AvailabilityState<HealthcenterGroupDistributorDetails[]>>,
            required: true
        }
    },
    methods: {
    /**
     * Get the availability label.
     *
     * @param {Object} provider
     */
        availabilityLabel (provider: AvailabilityState<unknown>) {
            if (provider.availability) {
                return 'Disponible';
            } else if (!provider.availability) {
                return 'Indisponible';
            }
        },
        /**
     * Scroll to the distributor map.
     */
        scrollToDistributorMap () {
            const element = document.getElementById('distributor-map');
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }
});
</script>
