<template>
  <nav
    aria-label="Barre latérale"
    class="hidden md:block md:flex-shrink-0 md:bg-white md:overflow-y-auto shadow-lg"
  >
    <div class="relative px-5 pb-4 pt-28 space-y-3 h-full flex flex-col justify-between w-24">
      <div class="flex flex-col space-y-3">
        <router-link
          :to="{ name: 'home' }"
          class="button"
          active-class="button-active"
        >
          <span class="sr-only">
            Accueil
          </span>

          <svg
            class="h-7 w-7"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
            />
          </svg>
        </router-link>
        <router-link
          :to="{ name: 'patient-administration.renewal.index' }"
          class="button"
          active-class="button-active"
        >
          <span class="sr-only">
            Relation Patients
          </span>

          <svg
            class="h-7 w-7"
            stroke="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <path
              d="M12,2C6.47,2 2,6.5 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M15.6,8.34C16.67,8.34 17.53,
              9.2 17.53,10.27C17.53,11.34 16.67,12.2 15.6,12.2A1.93,1.93 0 0,1 13.67,10.27C13.66,9.2 14.53,8.34 15.6,
              8.34M9.6,6.76C10.9,6.76 11.96,7.82 11.96,9.12C11.96,10.42 10.9,11.5 9.6,11.5C8.3,11.5 7.24,10.42 7.24,
              9.12C7.24,7.81 8.29,6.76 9.6,6.76M9.6,15.89V19.64C7.2,18.89 5.3,17.04 4.46,14.68C5.5,13.56 8.13,13 9.6,
              13C10.13,13 10.8,13.07 11.5,13.21C9.86,14.08 9.6,15.23 9.6,15.89M12,20C11.72,20 11.46,20 11.2,
              19.96V15.89C11.2,14.47 14.14,13.76 15.6,13.76C16.67,13.76 18.5,14.15 19.44,14.91C18.27,17.88 15.38,
              20 12,20Z"
            />
          </svg>
        </router-link>
        <router-link
          :to="{name: 'disruption-logistics'}"
          class="button"
          active-class="button-active"
        >
          <span class="sr-only">
            Rupture premium
          </span>

          <svg
            class="h-7 w-7"
            stroke="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M3 3C1.34315 3 0 4.34315 0 6V15C0 16.3121 0.842366 17.4275 2.01581 17.8348C2.18436 19.6108 3.67994 21 5.5 21C7.26324 21 8.72194 19.6961 8.96456 18H15.0354C15.2781 19.6961 16.7368 21 18.5 21C20.3201 21 21.8156 19.6108 21.9842 17.8348C23.1576 17.4275 24 16.3121 24 15V10.7515C24 10.0248 23.7362 9.32283 23.2577 8.77596L20.8502 6.02449C20.2805 5.37344 19.4576 5 18.5925 5H16.8293C16.4175 3.83481 15.3062 3 14 3H3ZM4 17.4361V17.5639C4.03348 18.3634 4.69224 19.0013 5.5 19.0013C6.30776 19.0013 6.96652 18.3634 7 17.5639V17.4361C6.96652 16.6366 6.30776 15.9987 5.5 15.9987C4.69224 15.9987 4.03348 16.6366 4 17.4361ZM5.5 14C6.8962 14 8.10145 14.8175 8.66318 16H15.3368C15.8985 14.8175 17.1038 14 18.5 14C19.8245 14 20.9771 14.7357 21.5716 15.8207C21.8306 15.64 22 15.3398 22 15V11H17C15.8954 11 15 10.1046 15 9V6C15 5.44772 14.5523 5 14 5H3C2.44772 5 2 5.44772 2 6V15C2 15.3398 2.16945 15.64 2.42845 15.8207C3.02292 14.7357 4.17555 14 5.5 14ZM17 7V8C17 8.55229 17.4477 9 18 9H20.7962L19.345 7.34149C19.1552 7.12448 18.8808 7 18.5925 7H17ZM17 17.4361V17.5639C17.0335 18.3634 17.6922 19.0013 18.5 19.0013C19.3078 19.0013 19.9665 18.3634 20 17.5639V17.4361C19.9665 16.6366 19.3078 15.9987 18.5 15.9987C17.6922 15.9987 17.0335 16.6366 17 17.4361Z"
              fill="#0F0F0F"
            />
          </svg>
        </router-link>
        <router-link :to="{name: 'extension-store'}" class="button" active-class="button-active">
          <span class="sr-only">
            Store
          </span>

          <StoreIcon size="7" />
        </router-link>
        <router-link :to="{name: 'hospital'}" class="button" active-class="button-active">
          <span class="sr-only">
            Liens Ville - Hôpital
          </span>

          <svg
            class="h-7 w-7"
            stroke="currentColor"
            width="17.228"
            height="22.372"
            viewBox="0 0 17.228 22.372"
          >
            <g
              id="hospital-sign-svgrepo-com_1_" data-name="hospital-sign-svgrepo-com (1)"
              transform="translate(-491.484 252.652)"
            >
              <path
                id="Tracé_1825" data-name="Tracé 1825"
                d="M8,16.936H22.728M8,16.936V7m0,9.936v9.936m14.728-9.936v9.493m0-9.493V7"
                transform="translate(484.734 -258.402)" stroke-linecap="round" stroke-linejoin="round"
                stroke-width="2.5"
              />
            </g>
          </svg>
        </router-link>

        <!-- HIDDEN FROM TICKET : CO-233 -->
        <!--<router-link
          :to="{ name: 'disruption' }"
          class="button"
          active-class="button-active"
        >
          <span class="sr-only">
            Rupture
          </span>

          <svg
            viewBox="0 0 30 30"
            stroke="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            class="h-8 w-8 fill-current"
          >
            <path
              class="cls-1"
              d="M13.14,6a.77.77,0,0,1,0,1.07l-4,4H20.6l-4-4A.76.76,0,0,1,17.67,6L22.74,11H27a.76.76,0,0,1,0,
                1.51h-.12L25,22.9a3,3,0,0,1-3,2.5H7.7a3,3,0,0,1-3-2.5L2.9,12.55H2.78a.76.76,0,1,1,0-1.51H7L12.07,
                6A.77.77,0,0,1,13.14,6ZM7.31,12.55H4.43L6.21,22.64A1.52,1.52,0,0,0,7.7,23.89H22a1.51,1.51,0,0,0,
                1.49-1.25L25.3,12.55h-18Z"
            />
            <path
              class="cls-2"
              d="M16.11,18l2-2a.89.89,0,0,0,0-1.25.88.88,0,0,0-1.24,0l-2,2-2-2a.88.88,0,1,0-1.24,1.25l2,2-2,2a.88.88,0,
              1,0,1.24,1.25l2-2,2,2a.88.88,0,0,0,1.24,0,.89.89,0,0,0,0-1.25Z"
            />
          </svg>
        </router-link> -->

        <!-- HIDDEN FROM TICKET : CO-233 -->
        <!--router-link
          :to="{ name: 'brand.list' }"
          class="button"
          active-class="button-active"
        >
          <span class="sr-only">
            Espaces marques
          </span>

          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-7 w-7"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"
            />
          </svg>
        </router-link>-->
      </div>
      <div class="flex flex-col space-y-3 border-t border-gray-300 pt-3">
        <router-link
          class="button"
          :to="{ name: 'notification.preference' }"
          active-class="button-active"
        >
          <span class="sr-only">
            Paramètres
          </span>

          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-7 w-7"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
            />

            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
            />
          </svg>
        </router-link>

        <router-link
          class="button"
          :to="{ name: 'support' }"
          active-class="button-active"
        >
          <span class="sr-only">
            Support
          </span>

          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-7 w-7"
            viewBox="0 0 28 33"
            fill="currentColor"
          >
            <g transform="translate(-50 531.962)">
              <path
                stroke="#000"
                stroke-width="0.5"
                d="M62.72-531.9a10.887,10.887,0,0,0-5.328,2.181,10.6,10.6,0,0,0-3.67,5.463,9.429,9.429,0,0,0-.375,3.077l-.035,1.612-.557.035a3.581,3.581,0,0,0-.821.141,3.15,3.15,0,0,0-1.846,2.122A20.45,20.45,0,0,0,50-514.08c0,2.673.006,2.878.117,3.224a2.917,2.917,0,0,0,1.923,1.929,7.008,7.008,0,0,0,2.1.117h1.729l.2-.2.2-.2V-514.1c0-4.871,0-4.883-.123-5.088-.193-.317-.27-.346-.838-.352h-.516l.023-1.483a10.594,10.594,0,0,1,.158-2.11,9.129,9.129,0,0,1,6.964-7.128,9.112,9.112,0,0,1,8.758,2.843,9.256,9.256,0,0,1,2.11,4.156,9.308,9.308,0,0,1,.152,2.122l.029,1.6H72.5c-.569,0-.709.064-.873.434-.106.229-.111.475-.111,5.006,0,5.229-.018,5.012.352,5.2a1.692,1.692,0,0,0,.674.088c.5,0,.5,0,.434.129A7.349,7.349,0,0,1,71.49-507.3a5.877,5.877,0,0,1-2.737.932l-.5.035-.07-.287a3.487,3.487,0,0,0-.973-1.577,3.344,3.344,0,0,0-1.753-.979,3.666,3.666,0,0,0-4.291,2.439A3.45,3.45,0,0,0,61.378-504a3.722,3.722,0,0,0,3.482,2.034,3.956,3.956,0,0,0,2.573-1.219,4.07,4.07,0,0,0,.768-1.454c.041-.234.047-.24.276-.24a8.776,8.776,0,0,0,2.081-.381,7.339,7.339,0,0,0,3.875-2.966l.422-.621.287-.041a2.868,2.868,0,0,0,1.764-.92,3.1,3.1,0,0,0,.34-.563l.158-.34v-6.155l-.182-.475a3.113,3.113,0,0,0-2.356-2.175l-.346-.035-.035-1.471a11.022,11.022,0,0,0-.358-3.083,10.74,10.74,0,0,0-4.666-6.278A10.781,10.781,0,0,0,62.72-531.9Zm-7.914,17.726v3.9h-1.1c-1.038,0-1.12-.012-1.4-.141a1.379,1.379,0,0,1-.727-.739c-.117-.252-.123-.375-.123-2.907,0-3,.006-3.036.422-3.511.4-.457.539-.5,1.823-.5h1.1Zm20.071-3.84a1.951,1.951,0,0,1,.862.95l.2.428.018,2.7c.012,1.805,0,2.767-.041,2.913a1.051,1.051,0,0,1-.27.393c-.322.281-.686.358-1.741.358h-.926v-7.8h.873A5.59,5.59,0,0,1,74.878-518.013Zm-9.332,10.387a2.482,2.482,0,0,1,1.09,1.09,1.709,1.709,0,0,1,.158.95,1.6,1.6,0,0,1-.147.909,2.472,2.472,0,0,1-.979.985,2.208,2.208,0,0,1-3.171-2.268,2.218,2.218,0,0,1,1.53-1.77A2.555,2.555,0,0,1,65.546-507.626Z"
              />
            </g>
          </svg>
        </router-link>
      </div>
    </div>
  </nav>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import {mapState} from "pinia";
import {useSystemStore} from "@/stores/system.store";
import StoreIcon from "@/core/components/store-icon/StoreIcon.vue";

export default defineComponent({
    components: {StoreIcon},
    computed: {
        ...mapState(useSystemStore, ['notificationCount', "environment"]),
    }
});
</script>

<style type="pcss" scoped>
.button {
    @apply flex-shrink-0 inline-flex items-center justify-center h-14 w-14 rounded-2xl;
    @apply bg-neutral/50;
}

.button:hover,
.button-active {
    @apply text-primary bg-secondary;
}
</style>
