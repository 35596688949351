<template>
  <div
    id="exclusion"
    ref="exclusion"
    class="text-sm bg-gray-200 w-full rounded-b-md -z-10 p-2"
  >
    <transition
      name="expand"
      @before-enter="beforeEnter"
      @enter="enter"
      @before-leave="beforeLeave"
      @leave="leave"
    >
      <div v-if="!isExcluded && !feedbackProvided">
        <button
          class="text-primary flex items-center p-1"
          @click.stop="createFeedbackDelay"
        >
          <v-icon
            size="14"
            class="mr-1"
            color="primary"
          >
            mdi-eye-off-outline
          </v-icon>
          <label>Masquer pendant 30 jours</label>
        </button>
        <button
          class="text-primary flex items-center p-1"
          @click.stop="createFeedbackReject"
        >
          <v-icon
            size="14"
            class="mr-1"
            color="primary"
          >
            mdi-eye-off
          </v-icon>
          <label>Masquer définitivement</label>
        </button>
        <button
          v-if="isExcluded === false"
          class="text-primary flex items-center p-1"
          @click.stop="toggleExclusion"
        >
          <v-icon
            size="14"
            class="mr-1"
            color="primary"
          >
            mdi-account-cancel
          </v-icon>
          <label>Retirer le patient du programme</label>
          <span v-if="loadingExclude">
            <base-spinner size="button" />
          </span>
        </button>
      </div>
      <div
        v-else
        class="mx-2 mb-1"
      >
        <div v-if="feedbackProvided">
          <v-icon
            size="14"
            color="green"
          >
            mdi-check
          </v-icon>
          Bien noté, le masquage sera pris en compte la prochaine fois.
        </div>
        <div v-if="isExcluded">
          <v-icon
            size="14"
            color="green"
          >
            mdi-check
          </v-icon>
          Patient retiré, il ne sera plus notifié pour ce programme.
          <button
            class="text-primary underline gap-2 items-center inline-block"
            @click.stop="toggleExclusion"
          >
            <label>Annuler</label>
            <span
              v-if="loadingExclude"
              class="inline-block ml-1"
            >
              <base-spinner size="button" />
            </span>
          </button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import BaseSpinner from '@/core/components/base/spinner/BaseSpinner.vue';
import {programApi} from '@/container';

export default defineComponent({
    name: 'NotificationProgramFeedback',
    components: {BaseSpinner},
    props: {
    /**
     * The program.
     */
        program: {
            type: Object,
            required: true
        },
        /**
     * The consumer key.
     */
        consumerKey: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            loadingExclude: false,
            isExcluded: false,
            feedbackProvided: false
        };
    },
    methods: {
    /**
     * Store a checkup action.
     */
        createFeedbackDelay() {
            this.feedbackProvided = true;

            return programApi()
                .createFeedback(this.program.id, {
                    consumer_key: this.consumerKey,
                    type: 'delay',
                    data: {
                        days: 30
                    }
                })
                .finally(() => {
                    this.contentChanged();
                });
        },
        /**
     * Store a checkup action.
     */
        createFeedbackReject() {
            this.feedbackProvided = true;

            return programApi()
                .createFeedback(this.program.id, {
                    consumer_key: this.consumerKey,
                    type: 'reject',
                    data: {
                        reason: ''
                    }
                })
                .finally(() => {
                    this.contentChanged();
                });
        },
        /**
     * Toggle the exclusion of the consumer from the program.
     */
        toggleExclusion() {
            this.loadingExclude = true;

            programApi()
                .toggleConsumerSettings(this.program.programmable_id, this.consumerKey)
                .then(() => {
                    this.isExcluded = !this.isExcluded;
                })
                .finally(() => {
                    this.loadingExclude = false;
                    this.contentChanged();
                });
        },
        /**
     * Prepare the element for the animation.
     *
     * @param {Object} element
     */
        beforeEnter(element) {
            element.style.height = '0';
            element.style.opacity = '0';
        },
        /**
     * On animation enter, set the height to the natural height of the element.
     *
     * @param {Object} element
     * @param {Function} done
     */
        enter(element, done) {
            element.style.height = `${element.scrollHeight}px`;
            element.style.opacity = '1';

            element.addEventListener('transitionend', done);
        },
        /**
     * Prepare the element before leaving the animation.
     *
     * @param {Object} element
     */
        beforeLeave(element) {
            element.style.height = `${element.scrollHeight}px`;
            element.style.opacity = '1';
        },
        /**
     * On animation leave, set the height to 0 and the opacity to 0.
     *
     * @param {Object} element
     * @param {Function} done
     */
        leave(element, done) {
            element.style.transition = 'opacity 0.5s ease, height 0.5s ease';
            element.style.height = '0';
            element.style.opacity = '0';
            element.addEventListener('transitionend', done);
        },
        /**
     * Update the height of the exclusion element after the content has changed.
     */
        contentChanged() {
            this.$nextTick(() => {
                const element: any = this.$refs.exclusion;

                if (element) {
                    element.style.height = `${element.scrollHeight}px`;
                }
            });
        }
    }
});
</script>

<style scoped>
.expand-enter-active, .expand-leave-active {
  transition: opacity 0.5s, height 0.5s ease;
  overflow: hidden;
}

.expand-enter, .expand-leave-to /* Starting and ending states */
{
  opacity: 0;
  height: 0;
}

button {
  @apply cursor-pointer hover:underline;
}
</style>
