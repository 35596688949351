<script setup lang="ts">
import BaseButton from "@/core/components/base/BaseButton.vue";
import {DotLottieVue} from "@lottiefiles/dotlottie-vue";

/**
 * Close the window.
 */
const close = () => {
    window.ipcRenderer.stripeCheckoutSuccess();
    window.close();
};
</script>

<template>
  <div class="gradient h-screen flex flex-col gap-2 items-center justify-center">
    <DotLottieVue
      src="animations/success_2.json"
      class="w-64 h-64"
      autoplay
    />
    <h2 class="text-white text-center">
      Votre paiement a bien été pris en compte, merci ! 🎉 <br>
      Vous pouvez fermer cette fenêtre et profiter de votre achat.
    </h2>
    <BaseButton rounded="md" @click="close">
      FERMER
    </BaseButton>
  </div>
</template>
