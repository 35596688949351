<script setup lang="ts">
import {Customer} from "@/customer/interfaces/Customer";
import {PropType} from "vue";
import CustomerCertificationOnboarding from "@/customer-certification/views/CustomerCertificationOnboarding.vue";
import apodisWhiteLogo from '@/assets/images/logos/apodis-white.svg';

const props = defineProps({
    customer: {
        type: Object as PropType<Customer>,
        required: true
    }
});

</script>

<template>
  <v-dialog fullscreen transition="dialog-bottom-transition">
    <template #activator="{ props: activatorProps }">
      <v-btn
        class="w-max !rounded-2xl !normal-case px-6"
        v-bind="activatorProps"
        color="primary"
        density="comfortable"
        prepend-icon="mdi-cellphone"
        :text="'Connecter ' + props.customer.information.first_name"
        variant="flat"
      >
        <template #prepend>
          <img :src="apodisWhiteLogo" alt="Apodis" class="h-4 w-4">
        </template>
      </v-btn>
    </template>

    <template #default="{ isActive }">
      <CustomerCertificationOnboarding :customer="props.customer" @close="isActive.value = false" />
    </template>
  </v-dialog>
</template>
