<template>
  <div class="flex flex-1 flex-col mt-2 gap-y-1.5">
    <BaseButton
      v-if="!customer.certification?.validated_at"
      primary
      class="h-9 flex items-center justify-center"
      @click="print"
    >
      <div
        v-if="!printing"
        class="text-small text-center w-full"
      >
        Imprimer le guide d'installation
      </div>
      <img
        v-else
        :src="loaderButton"
        alt="Loading"
        width="24"
      >
    </BaseButton>
    <CustomerCertificationNotificationButton
      v-if="customer.certification?.phone"
      :customer="customer"
    />
    <BaseButton
      class="h-9 flex items-center"
      :disabled="canceling"
      @click="cancel"
    >
      <div
        class="text-small text-center w-full"
      >
        Annuler la certification
      </div>
    </BaseButton>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from "vue";
import BaseButton from '@/core/components/base/BaseButton.vue';
import {customerCertificationApi, storageService} from '@/container';
import CustomerCertificationNotificationButton
    from '@/customer-certification/components/CustomerCertificationNotificationButton.vue';
import loaderButton from '@/assets/loader-button.gif';
import Customer from "@/customer/interfaces/Customer";

export default defineComponent({
    components: {CustomerCertificationNotificationButton, BaseButton},
    props: {
        customer: {
            type: Object as PropType<Customer>,
            required: true
        }
    },
    data() {
        return {
            canceling: false,
            printing: false,
            loaderButton
        };
    },
    methods: {
        print() {
            this.printing = true;

            window.ipcRenderer.invoke('print', {
                url: customerCertificationApi().pdfUrl(this.customer.id, 'phone'),
                token: storageService().read('token')
            })
                .finally(() => {
                    this.printing = false;
                });
        },
        cancel() {
            this.canceling = true;

            return customerCertificationApi()
                .cancel(this.customer.id)
                .then(() => {
                    // eslint-disable-next-line vue/no-mutating-props
                    this.customer.certification = undefined;
                })
                .finally(() => {
                    this.canceling = false;
                });
        }
    }
});
</script>
