import Unreachable from '@/assets/images/map-markers/availabilities/unreachable.svg';
import Available from '@/assets/images/map-markers/availabilities/available.svg';
import AvailableForFriends from '@/assets/images/map-markers/availabilities/available_for_friends.svg';
import Unavailable from '@/assets/images/map-markers/availabilities/unavailable.svg';
import Alliance from "@/assets/images/wholesalers/alliance.svg";
import CERPBretagne from "@/assets/images/wholesalers/cerp-bretagne.svg";
import CERPRhinRhoneMediterrannee from "@/assets/images/wholesalers/cerp-rrm.svg";
import CERPOuestAstera from "@/assets/images/wholesalers/cerp-astera.svg";
import Phoenix from "@/assets/images/wholesalers/phoenix.svg";
import OCP from "@/assets/images/wholesalers/ocp.png";

const DistributorAvailabilityMapService = () => {
    /**
     * Get the marker image based on the warehouse availability.
     *
     * 3 different state :
     *   1. Is available and has members
     *   2. Is available and has no members
     *   3. Is not available
     */
    const getMarkerImage = (isAvailable: boolean | null, warehouseMembers: any[] = []): string => {
        if (isAvailable === null) {
            return Unreachable;
        }

        if (isAvailable) {
            return warehouseMembers?.length
                ? AvailableForFriends
                : Available;
        }

        return Unavailable;
    };

    /**
     * Get the wholesaler image based on the wholesaler id.
     */
    const getWholesalerImage = (wholesalerId: number): string|null => {
        const images = {
            11: Alliance,
            12: CERPBretagne,
            17: CERPRhinRhoneMediterrannee,
            18: CERPOuestAstera,
            20: OCP,
            23: Phoenix,
        };

        return images[wholesalerId] ?? null;
    };

    return {
        getMarkerImage,
        getWholesalerImage
    };
};

export default DistributorAvailabilityMapService();
