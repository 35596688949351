<script setup lang="ts">
import {QuickFilter, QuickFilterCategory} from "@/core/interfaces/search-bar";
import QuickItemPicker from "@/core/components/filters/QuickItemPicker.vue";
import {ref, watch} from "vue";

const {selectedFilters} = defineProps<{
    /** Quick filter categories. */
    categories: QuickFilterCategory[];
    /** The array of selected filters */
    selectedFilters: QuickFilter<any>[];
}
>();
defineEmits<{ (e: 'onFilterPress', filter: QuickFilter<any>) }>();

const filters = ref<QuickFilter<any>[]>(selectedFilters);

watch(() => selectedFilters, (value) => {
    if (value) {
        filters.value = value;
    }
}, {immediate: true});

/**
 * Determine if a filter is selected.
 *
 * @param {QuickFilter<any>} filter
 */
const isSelected = (filter: QuickFilter<any>): boolean => {
    if (!filters.value) {
        return false;
    }
    return !!filters.value.find(selectedFilter => {
        if (selectedFilter.queryParam === 'age') {
            return selectedFilter.queryParam === filter.queryParam &&
                selectedFilter.value.from === filter.value.from &&
                selectedFilter.value.to === filter.value.to;
        }
        return selectedFilter.queryParam === filter.queryParam &&
            selectedFilter.value === filter.value;
    });
};
</script>

<template>
  <div class="bg-white rounded p-4">
    <div
      v-for="(category, index) in categories"
      :key="index"
    >
      <span class="font-bold">{{ category.name }}</span>
      <div class="flex flex-row">
        <QuickItemPicker
          v-for="(filter, categoryIndex) in category.filters"
          :key="`${category.name}-${categoryIndex}`"
          :label="filter.label"
          :selected="isSelected(filter)"
          @on-selected="$emit('onFilterPress', filter)"
        />
      </div>
    </div>
  </div>
</template>
