import {AxiosInstance} from "axios";

/**
 * The loyalty card product resource.
 * @author Tony Laurent <t.laurent@apodispharma.com>
 * @param {Object} client - The injected client.
 * @returns {Object} The public object.
 */
export default function (client: AxiosInstance) {
    return {
        /**
         * Get all loyalty card products.
         * @param {Object} loyaltyCard - The loyalty card.
         * @param {string} search - The search query.
         * @returns {Promise} Promise object represents the HTTP request.
         */
        get (loyaltyCard: {id: number}, search: string) {
            return client
                .get(`/loyalty-cards/${loyaltyCard.id}/products`, {
                    params: {
                        search,
                        include: 'product'
                    }
                })
                .then(response => response.data.data)
                .catch(() => []);
        }
    };
}
