import {defineStore} from "pinia";
import {ExperienceEvent} from "@/core/interfaces/experience/ExperienceEvent";
import {experienceApi} from "@/container";

export const useExperienceStore = defineStore('experience', {
    state: () => ({
        events: [] as ExperienceEvent[]
    }),
    getters: {
        eventByName(state: { events: ExperienceEvent[] }) {
            return (name: string) => state.events.find(event => event.name === name);
        }
    },
    actions: {
        fetchEvents() {
            return experienceApi().indexEvents()
                .then(events => {
                    this.events = events;
                    window.ipcRenderer.send('dispatch-experience-events', events);
                });
        }
    }
});
