<template>
  <div>
    <div v-if="isLoading">
      <base-flex-spinner />
    </div>
    <div v-else>
      <div class="flex flex-row align-center mb-4">
        <img
          :src="dialogIcon"
          alt="Dialog"
          class="mr-4"
          width="40"
          height="40"
        >
        <p class="text-primary mt-1">
          Qui prend en charge la demande ?
        </p>
      </div>
      <div class="flex flex-wrap">
        <div
          v-for="(operator, index) in operators"
          :key="index"
          :class="selected(index)
            ? 'p-2 m-4 bg-primary text-white rounded-lg cursor-pointer'
            : 'p-2 m-4 bg-primary-lightest rounded-lg cursor-pointer border-transparent border-2 hover:border-primary'"
          @click="selectCode(operator)"
        >
          <p>{{ itemLabel(operator) }}</p>
        </div>
      </div>
      <div class="flex mt-10">
        <p
          class="text-primary p-2 shadow rounded-lg cursor-pointer"
          @click="goBack"
        >
          Retour
        </p>
      </div>
    </div>
    <div v-if="loading">
      <BaseSpinner />
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import {teamApi} from '@/container';
import BaseSpinner from '@/core/components/base/spinner/BaseSpinner.vue';
import BaseFlexSpinner from "@/core/components/base/spinner/BaseFlexSpinner.vue";
import dialogIcon from '@/assets/icons/dialog.svg';

export default defineComponent({
    name: 'OrderOperator',
    components: {BaseFlexSpinner, BaseSpinner},
    props: {
        order: {
            type: Object,
            required: true
        },
        loading: {
            type: Boolean,
            required: true
        }
    },
    emits: ['back', 'updateOperatorCode'],
    data() {
        return {
            teamAccess: null as null | any[],
            operators: null as null | any[],
            isLoading: true,
            dialogIcon
        };
    },
    mounted() {
        this.load();
    },
    methods: {
    /**
     * Load team and operators data.
     *
     * @return {Promise}
     */
        load() {
            return teamApi().index()
                .then(response => {
                    this.teamAccess = response.team;

                    return teamApi().operatorCodes();
                })
                .then(response => {
                    this.operators = response;
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        goBack() {
            this.$emit('back');
        },
        /**
     * Get item label.
     *
     * @param {string} code - The operator code.
     * @return {string}
     */
        itemLabel(code: string) {
            const access = this.teamAccess?.find((access) => access.operatorCode === code);

            if (!access) {
                return code;
            }

            return `${access.user.name} (${code})`;
        },
        /**
     * Select operator code that will handle the order.
     *
     * @param code
     */
        selectCode(code: string) {
            if (!this.loading) {
                this.$emit('updateOperatorCode', code);
            }
        },
        /**
     * If the operator is selected.
     *
     * @param index
     *
     * @return {boolean}
     */
        selected(index: number) {
            const selectedIndex = this.operators?.findIndex(code => code === this.order?.operator_code);

            return Array.isArray(selectedIndex) ? selectedIndex.includes(index) : selectedIndex === index;
        }
    }
});
</script>
