<template>
  <div
    v-if="options"
    class="w-full relative"
  >
    <!-- Dropdown Input -->
    <input
      v-model="searchFilter"
      class="w-full px-3 py-2 cursor-pointer placeholder-gray-300 text-white border-0 focus:border-0 focus:ring-0 sm:text-sm rounded-md bg-lightPurple-default"
      :name="name"
      :disabled="disabled"
      autocomplete="off"
      :placeholder="placeholder"
      @focus="showOptions()"
      @blur="exit()"
      @keyup="keyMonitor"
    >

    <!-- Dropdown Menu -->
    <div
      v-show="optionsShown"
      class="dropdown-content absolute bg-white rounded-b-md max-h-56 overflow-y-auto w-full"
    >
      <div
        v-for="(option, index) in filteredOptions"
        :key="index"
        class="dropdown-item hover:bg-lightPurple-lightest px-2 py-3 cursor-pointer"
        @mousedown="selectOption(option)"
      >
        {{ option.name || option.id || "-" }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";

export default defineComponent({
    name: 'BaseDropdown',

    props: {
        name: {
            type: String,
            required: false,
            default: 'dropdown',
            note: 'Input name'
        },
        options: {
            type: Array<{id: number; name: string}>,
            required: true,
            default: () => [],
            note: 'Options of dropdown. An array of options with id and name'
        },
        placeholder: {
            type: String,
            required: false,
            default: 'Please select an option',
            note: 'Placeholder of dropdown'
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
            note: 'Disable the dropdown'
        },
        maxItem: {
            type: Number,
            required: false,
            default: 6,
            note: 'Max items showing'
        }
    },
    emits: ['selected', 'filter'],
    data() {
        return {
            selected: {} as any,
            optionsShown: false,
            searchFilter: ''
        };
    },
    computed: {
        filteredOptions() {
            const filtered: any[] = [];
            const regOption = new RegExp(this.searchFilter, 'ig');
            for (const option of this.options) {
                if (this.searchFilter.length < 1 || option.name.match(regOption)) {
                    if (filtered.length < this.maxItem) filtered.push(option);
                }
            }
            return filtered;
        }
    },
    watch: {
        searchFilter() {
            if (this.filteredOptions.length === 0) {
                this.selected = {};
            } else {
                this.selected = this.filteredOptions[0];
            }
            this.$emit('filter', this.searchFilter);
        }
    },
    created() {
        this.$emit('selected', this.selected);
    },
    methods: {
        selectOption(option: any) {
            this.selected = option;
            this.optionsShown = false;
            this.searchFilter = this.selected.name;
            this.$emit('selected', this.selected);
        },
        showOptions() {
            if (!this.disabled) {
                this.searchFilter = '';
                this.optionsShown = true;
            }
        },
        exit() {
            if (!this.selected.id) {
                this.selected = {};
                this.searchFilter = '';
            } else {
                this.searchFilter = this.selected.name;
            }
            this.$emit('selected', this.selected);
            this.optionsShown = false;
        },
        // Selecting when pressing Enter
        keyMonitor: function (event: any) {
            if (event.key === 'Enter' && this.filteredOptions[0]) {
                this.selectOption(this.filteredOptions[0]);
            }
        }
    }
});
</script>
