<template>
  <div class="bg-white w-full">
    <drug-interaction-patient-profile />
    <div class="bg-gray-100 -mx-4 my-2 p-4 flex flex-1 flex-col">
      <p>
        👉 Sélectionnez les produits pour analyser les interactions médicamenteuses.
      </p>
      <base-product-search-bar class="my-4" placeholder="Ajouter un produit" @select="updateImportedProducts" />
      <div v-if="importedProducts.length">
        <div
          v-for="product in importedProducts"
          :key="product.cip13"
          class="px-8"
        >
          <v-checkbox
            v-model="selectedImported"
            :value="product.cip13"
            density="comfortable"
            color="primary"
            class="h-12"
          >
            <template #label>
              <div class="flex flex-1 flex-row px-2">
                <div class="flex flew-wrap flex-row">
                  <p class="flex min-w-48">
                    {{ product.name }}
                  </p>
                  <p class="flex min-w-40 pl-2 text-gray-500">
                    {{ product.cip13 }}
                  </p>
                </div>
                <div class="flex flex-1 justify-end min-w-40">
                  <icon name="mdi-delete" :size="20" @click="updateImportedProducts(product)" />
                </div>
              </div>
            </template>
          </v-checkbox>
        </div>
      </div>
    </div>
    <BaseSpinner v-if="loading||loadingSelected" size="small" />
    <div v-else class="my-4">
      <div v-if="products" class="p-4 mb-10">
        <div class="flex flex-1 flex-row justify-between items-center">
          <span class="font-bold text-lg">Produits délivrés au patients depuis 2 ans</span>
          <span class="text-gray-400 text pr-6">Dernière délivrance</span>
        </div>
        <div
          v-for="(product, index) in products"
          :key="index"
        >
          <div v-if="product.id_13" class="py-1 px-4">
            <v-checkbox
              v-model="selected"
              :value="product.id_13"
              density="comfortable"
              color="primary"
              class="h-10"
            >
              <template #label>
                <div class="flex flex-1 flex-row flex-wrap px-2">
                  <div class="flex flew-wrap flex-row">
                    <p class="flex min-w-48">
                      {{ product.name }}
                    </p>
                    <p class="flex min-w-40 pl-2 text-gray-500">
                      {{ product.id_13 }}
                    </p>
                  </div>
                  <div class="flex flex-1 justify-end min-w-40">
                    <p class="text-gray-500">
                      {{ $filters.date(product.date, 'dayOnly') }}
                    </p>
                  </div>
                </div>
              </template>
            </v-checkbox>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="!!selected.length || !!selectedImported.length"
      class="fixed bottom-0 inset-x-0 flex flex-1 justify-center pb-4 bg-white/60"
    >
      <base-button
        primary
        class="my-2 w-60"
        @click="launchAnalysis"
      >
        Lancer l'analyse
      </base-button>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import BaseSpinner from '@/core/components/base/spinner/BaseSpinner.vue';
import BaseButton from '@/core/components/base/BaseButton.vue';
import DrugInteractionPatientProfile from "@/drug-interaction/components/DrugInteractionPatientProfile.vue";
import BaseProductSearchBar from "@/product/components/BaseProductSearchBar.vue";
import Icon from "@/core/components/icon/Icon.vue";
import {LegacyProduct} from "@/core/interfaces/Product";
import {mapState} from "pinia";
import {useInterviewStore} from "@/stores/interview-store";
import {consumerProductApi} from "@/container";
import {ProductSummary} from "@/product/interfaces/ProductSummary";

export default defineComponent({
    name: 'DrugSelection',
    components: {Icon, BaseProductSearchBar, DrugInteractionPatientProfile, BaseButton, BaseSpinner},
    props: {
        consumerKey: {
            type: String,
            required: true
        },
    },
    emits: ['launch-analysis'],
    data() {
        return {
            importedProducts: [] as LegacyProduct[],
            selected: [] as string[],
            selectedImported: [] as string[],
            loadingSelected: true
        };
    },
    computed: {
        ...mapState(useInterviewStore, {products: "products", loading: state => state.loading.products}),
    },
    watch: {
        selected: {
            handler() {
                this.updateCustomerProducts();
            }
        },
        "selectedImported.length": {
            handler() {
                this.updateCustomerProducts();
            }
        }
    },
    mounted() {
        if (!this.loading) {
            this.customerProducts();
        }
    },
    methods: {
        updateImportedProducts(product: LegacyProduct) {
            const productIndex = this.importedProducts.findIndex(imported => imported.cip13 === product.cip13);

            if (productIndex === -1) {
                this.importedProducts.push(product);
                this.selectedImported.push(product.cip13);
            } else {
                this.importedProducts.splice(productIndex, 1);
                const selectedIndex = this.selectedImported.findIndex(selected => selected === product.cip13);
                if (selectedIndex !== -1) {
                    this.selectedImported.splice(selectedIndex, 1);
                }
            }
        },
        launchAnalysis() {
            if (this.selected.length || this.selectedImported.length) {
                this.$emit('launch-analysis', [...this.selected, ...this.selectedImported]);
            }
        },
        updateSelected(customerProducts: ProductSummary[]) {
            if (this.products) {
                this.products.map(product => {
                    const index = customerProducts.findIndex(customerProduct => customerProduct.id == product.id);
                    if (index !== -1) {
                        this.selected.push(product.id_13);
                        customerProducts.splice(index, 1);
                    }
                });

                customerProducts.map(customerProduct => {
                    const legacyProduct = {
                        buyingPrice: 0,
                        cip7: "",
                        code: "",
                        createdAt: "",
                        ean13: "",
                        fullPhotoPath: "",
                        laboratoryId: 0,
                        onTheMarket: 0,
                        photoPath: "",
                        slug: "",
                        updatedAt: "",
                        id: customerProduct.id,
                        cip13: customerProduct.cip,
                        name: customerProduct.name
                    };
                    this.importedProducts.push(legacyProduct);
                    this.selectedImported.push(legacyProduct.cip13);
                });
            }
        },
        customerProducts() {
            consumerProductApi().read(this.consumerKey)
                .then(this.updateSelected)
                .finally(() => this.loadingSelected = false);

        },
        updateCustomerProducts() {
            const selectedIds = this.selected.map(item => {
                if (this.products) {
                    const product = this.products.find(product => product.id_13 === item);
                    if (product) {
                        return product.id;
                    }
                }

                return -1;
            })
                .filter(id => id !== -1);

            const importedIds = this.selectedImported.map(item => {
                const product = this.importedProducts.find(product => product.cip13 === item);
                if (product) {
                    return product.id;
                } else {
                    return -1;
                }
            })
                .filter(id => id !== -1);

            const ids: number[] = [];
            if (selectedIds?.length) {
                ids.push(...selectedIds);
            }
            if (importedIds?.length) {
                ids.push(...importedIds);
            }

            consumerProductApi().patch(this.consumerKey, ids);
        }
    }
});
</script>

<style scoped>
:deep(.v-selection-control .v-label) {
    flex: 1;
}
</style>
