<template>
  <div>
    <v-carousel
      v-model="model"
      :hide-delimiters="true"
      :show-arrows="false"
      :height="'auto'"
      class="relative"
    >
      <v-carousel-item
        v-for="(urls, index) in images"
        :key="index"
      >
        <secure-image
          :urls="urls"
          :size="size"
          :ratio="ratio"
        />
      </v-carousel-item>
    </v-carousel>
    <div class="flex justify-between mt-2">
      <button @click="model--">
        <icon :name="'mdi-arrow-left'" />
      </button>
      <span class="text-sm">{{ model + 1 }}/{{ images.length }}</span>
      <button @click="model++">
        <icon :name="'mdi-arrow-right'" />
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import SecureImage from '@/core/components/secure-image/SecureImage.vue';
import Icon from '@/core/components/icon/Icon.vue';

export default defineComponent({
    name: 'SecureImageSlider',
    components: { SecureImage, Icon },
    props: {
        images: {
            type: Array<any>,
            required: true
        },
        size: {
            type: Number,
            default: 84
        },
        ratio: {
            type: Number,
            default: 1
        }
    },
    data () {
        return {
            model: 0
        };
    }
});
</script>
