<script setup lang="ts">
import InterviewInstanceModalHeader from "@/interview/components/instance-modal/InterviewInstanceModalHeader.vue";
import {computed, onUnmounted, provide, watch} from "vue";
import {useInterviewStore} from "@/stores/interview-store";
import BaseContainer from "@/core/components/base/BaseContainer.vue";
import {DotLottieVue} from "@lottiefiles/dotlottie-vue";
import InterviewShortCuts from "@/interview/components/instance-modal/InterviewShortCuts.vue";
import {useRoute, useRouter} from "vue-router";
import {usePatientStore} from "@/stores/patient.store";
import {storeToRefs} from "pinia";
import {InterviewInstance} from "@/interview/interfaces/interview";

const interviewStore = useInterviewStore();
const router = useRouter();
const route = useRoute();
const patientStore = usePatientStore();
const {interviewInstance, consumerName} = storeToRefs(interviewStore);

const props = defineProps<{
    consumerKey: string,
    interviewId: string,
    interviewName: string,
    backingId?: string,
    interviewInstanceId?: number
}>();

/**
 * Watch the interview id and initialize the interview store accordingly.
 */
watch(() => props.interviewId, async (newId) => {
    await interviewStore.initialize(
        Number(newId),
        props.consumerKey,
        props.interviewInstanceId,
        props.backingId ? Number(props.backingId) : undefined
    );

    const routeName = getInterviewRedirectState(interviewInstance.value);

    if (routeName !== route.name) {
        return await router.replace({
            name: routeName,
            query: {...route.query}
        });
    }
}, {immediate: true});

/**
 * On unmounted, reset the interview store and refresh the patient store.
 */
onUnmounted(() => {
    interviewStore.$reset();
    patientStore.refresh();
});

/**
 * Determine if the store is loading before rendering any content.
 */
const storeIsLoading = computed(() => {
    return interviewStore.loading.interview
        || interviewStore.loading.attachments
        || interviewStore.loading.interviewInstance
        || interviewStore.loading.customer;
});

/**
 * Handle the close event.
 */
const handleClose = () => {
    router.back();
};

/**
 * Get the interview instance redirect state.
 */
const getInterviewRedirectState = (instance?: InterviewInstance | null): string => {
    if (!instance) {
        return 'interview-instances.record';
    }

    if (!!instance.ended_at) {
        return 'interview-instances.finished';
    }

    if (instance.prefill_job_started_at && !instance.prefill_job_ended_at) {
        return 'interview-instances.transcript-loading';
    }

    return 'interview-instances.survey';
};

provide('close', handleClose);
</script>

<template>
  <div class="flex-1 flex flex-col h-full">
    <InterviewInstanceModalHeader
      :consumer-name="consumerName"
      :interview-name="interviewName"
      @close="handleClose"
    />
    <BaseContainer class="flex-1 bg-gray-50 flex flex-col">
      <dot-lottie-vue
        v-if="storeIsLoading"
        src="animations/interview.json"
        class="mx-auto my-auto w-100"
        :autoplay="true"
        :loop="true"
      />
      <div v-else-if="interviewStore.interview" class="flex flex-1 flex-col">
        <div class="flex flex-1 gap-6">
          <router-view :key="$route.path" v-slot="{Component}" class="flex-1 flex">
            <component :is="Component">
              <template #shortcuts>
                <InterviewShortCuts />
              </template>
            </component>
          </router-view>
        </div>
      </div>
    </BaseContainer>
  </div>
</template>

<style scoped>

</style>
