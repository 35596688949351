import {AxiosInstance, AxiosResponse} from "axios";
import LaboratoryProduct, {LaboratoryProductDataResponse} from "@/core/interfaces/laboratory/LaboratoryProduct";
import {LaboratoryOrder} from "@/core/interfaces/disruption/LaboratoryOrder";

/**
 * The shop resource.
 *
 * @param {Object} client - The injected client.
 * @returns {Object} The public object.
 */
export default function (client: AxiosInstance) {
    return {
        /**
         * Get laboratory product prices
         *
         * @param {number} laboratoryId - The laboratory id.
         * @param {number} productId - The product id.
         * @param {number} quantity - The product quantity.
         *
         * @returns {Promise<LaboratoryProduct>}
         */
        async getLaboratoryProductPrices(laboratoryId: number, productId: number, quantity = 1): Promise<LaboratoryProduct> {
            return client.get(`/shop/laboratories/${laboratoryId}/products/${productId}/prices`, {
                params: {
                    include: "laboratoryPriceRanges.laboratoryPrice",
                    quantity
                }
            })
                .then((response: AxiosResponse<LaboratoryProductDataResponse>) => {
                    const {data} = response;
                    return data.data;
                });
        },

        /**
         * Read an order by sellin offer.
         * @param {number} laboratoryId - The laboratory id.
         * @param {number}  healthcenterId - The healthcenter id.
         * @param {number}  sellinOfferId - The sellin offer id.
         *
         * @return {Promise<LaboratoryOrder>}
         */
        readBySellinOffer(laboratoryId: number, healthcenterId: number, sellinOfferId: number): Promise<LaboratoryOrder> {
            const params = {
                include: "lines"
            };
            return client.get(`/shop/laboratories/${laboratoryId}/healthcenters/${healthcenterId}/sellinOffers/${sellinOfferId}/order`, {params})
                .then((response: AxiosResponse) => response.data.data);
        }
    };
};
