<template>
  <div class="flex flex-col gap-8">
    <div
      v-for="(utterance, index) in utterances"
      :key="index"
      class="max-w-3xl"
      :class="{
        'self-end': isPharmacistSpeaker(utterance),
        'self-start': !isPharmacistSpeaker(utterance),
      }"
    >
      <p
        class="text-sm mb-1 px-2"
        :class="{
          'text-left': !isPharmacistSpeaker(utterance),
          'text-right': isPharmacistSpeaker(utterance),
        }"
      >
        {{ utterance.speaker }}
      </p>
      <div
        class="bg-gray-200 p-2 rounded-xl px-4"
        :class="{
          'bg-primary text-white': isPharmacistSpeaker(utterance),
        }"
      >
        <p>{{ utterance.text }}</p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from "vue";
import {Utterance} from "@/interview/interfaces/interview";
export default defineComponent({
    name: 'InterviewTranscriptUtterances',
    props: {
    /**
     * The utterances of the interview.
     */
        utterances: {
            type: [] as PropType<Utterance[]>,
            required: true
        }
    },
    methods: {
    /**
     *  Determine if the speaker utterance is the pharmacist.
     *
     *  @param {Object} utterance - The utterance object.
     *
     *  @return {Boolean} - True if the speaker is the pharmacist, false otherwise.
     */
        isPharmacistSpeaker (utterance: Utterance): boolean {
            return utterance.speaker === 'Pharmacien';
        }
    }
});
</script>
