<template>
  <div class="glowing-container">
    <div class="content relative h-full w-full rounded bg-white">
      <slot />
    </div>
  </div>
</template>

<script setup>
</script>

<style scoped>
.glowing-container {
    position: relative;
    overflow: hidden;
    border-radius: 7px;
    padding: 2px;
}

.glowing-container::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    right: -50%;
    bottom: -50%;
    background: conic-gradient(from var(--angle), #3513e2, #E2C4FF, #65efca, #3513e2);
    animation: rotate 5s linear infinite;
}

@keyframes rotate {
    from {
        --angle: 0deg;
    }
    to {
        --angle: 360deg;
    }
}

@property --angle {
    syntax: '<angle>';
    initial-value: 0deg;
    inherits: false;
}

.content {
    position: relative;
    z-index: 1;
}
</style>
