export default {
    /**
   * Transform color with opacity.
   *
   * @param color Based color (rrggbb, or rgb(x, y, z) to append opacity.
   * @param opacity Opacity to apply to the based color (ex: 0.5, for 50% opacity).
   *
   * @returns {string}
   */
    opacity (color, opacity) {
        const intValue = Math.floor(opacity * 255);
        const alpha = this.intToHex(intValue);
        const isRgbFunction = color.search(/#/g) === -1;

        if (isRgbFunction) {
            const rgbValues = color.slice(4, -1).split(',');
            const rgbCode = rgbValues.reduce((a, b) => a + this.intToHex(Number(b)));

            return `#${rgbCode}${alpha}`;
        }

        return color + alpha;
    },

    /**
   * Convert an int into an hex value.
   *
   * @param {number} value
   *
   * @returns {string}
   */
    intToHex (value) {
        const hex = value.toString(16);

        return hex.padStart(2, '0');
    },

    /**
   * Darken a color.
   * More the amount is high, more the color will be dark.
   *
   * @param {string} color - The color to darken, hexadecimal value.
   * @param {number} amount - The amount to darken the color, between 0 and 1.
   *
   * @return {string}
   */
    darkenColor (color, amount = 0.1) {
        let r = parseInt(color.substring(1, 3), 16);
        let g = parseInt(color.substring(3, 5), 16);
        let b = parseInt(color.substring(5, 7), 16);

        r = Math.floor(r * (1 - amount));
        g = Math.floor(g * (1 - amount));
        b = Math.floor(b * (1 - amount));

        return '#' + r.toString(16).padStart(2, '0') + g.toString(16).padStart(2, '0') + b.toString(16).padStart(2, '0');
    }
};
