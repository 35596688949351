<script setup lang="ts">
import BaseCard from "@/core/components/base/BaseCard.vue";
import BaseCardBody from "@/core/components/base/BaseCardBody.vue";
import BaseButton from "@/core/components/base/BaseButton.vue";
import {ref} from "vue";
import BaseModal from "@/core/components/base/BaseModal.vue";
import dayjs from "dayjs";
import dateHelper from "../../../core/helpers/dateHelper";
import Icon from "@/core/components/icon/Icon.vue";

const {selectedDate} = defineProps<{ selectedDate: string | null, title?: string, subtitle?: string }>();
const emits = defineEmits(['onDateChange']);

const visible = ref(false);
const time = ref<string | null>(null);
const now = ref<string>(dayjs().format('YYYY-MM-DD'));
const date = ref(null);

/**
 * On date selection close.
 */
const onDateSelectionClose = () => {
    visible.value = false;
    if (!selectedDate) {
        date.value = null;
        time.value = null;
    }
};

/**
 * On date selection confirm.
 */
const onDateSelectionConfirm = () => {
    if (date.value && time.value) {
        let splitTime = time.value.split(":");
        let hours = parseInt(splitTime[0]);
        let minutes = parseInt(splitTime[1]);
        emits(
            'onDateChange',
            dayjs(date.value).add(hours, 'hour')
                .add(minutes, 'minute')
                .format('YYYY-MM-DD HH:mm')
        );

        visible.value = false;
    }
};
</script>

<template>
  <BaseCard>
    <BaseCardBody>
      <div v-if="!selectedDate" class="flex flex-row items-center justify-between">
        <div class="flex flex-col pr-3">
          <h2 class="text-black">
            {{ title ?? 'Planifiez un rendez-vous' }}
          </h2>
          <p class="text-sm">
            {{ subtitle ?? 'Le patient recevra un rappel 2 heures avant' }}
          </p>
        </div>
        <BaseButton append-icon="mdi-calendar-month" @click="visible=true">
          Ajouter
        </BaseButton>
      </div>
      <div v-else class="flex flex-row items-center justify-between">
        <div class="flex flex-col pr-3">
          <h2 class="text-black">
            Rendez-vous le
            <span class="text-primary text-base mr-1 cursor-pointer" @click="visible=true">
              {{ dateHelper.shortFormat(selectedDate, 'DD MMMM à HH:mm') }}</span>
            <Icon name="mdi-calendar-month" @click="visible=true" />
          </h2>
          <p class="text-sm">
            Le patient recevra un rappel 2 heures avant
          </p>
        </div>
      </div>
      <BaseModal v-if="visible" @close="onDateSelectionClose">
        <div class="flex flex-row justify-around">
          <v-date-picker v-model="date" color="primary" :min="now" />
          <div class="flex flex-col justify-between">
            <div class="flex items-start p-10">
              <v-text-field
                ref="picker"
                v-model="time"
                label="Saisir l'heure"
                type="time"
              />
            </div>
            <BaseButton primary :disabled="!date || !time" @click="onDateSelectionConfirm">
              Valider
            </BaseButton>
          </div>
        </div>
      </BaseModal>
    </BaseCardBody>
  </BaseCard>
</template>
