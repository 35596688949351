<template>
  <div class="w-full">
    <div class="flex flex-col md:flex-row">
      <div class="w-full">
        <div class="flex flex-1 flex-row justify-between p-5">
          <p>{{ step.description }}</p>
          <img
            alt="illustration"
            :src="step.image ? step.image.url : pharmacistBubble"
            class="w-32 object-contain hidden sm:block"
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import {defineComponent} from "vue";
import pharmacistBubble from '@/assets/images/pharmacist-bubble.png';

export default defineComponent({
    name: 'PathwayEnd',
    props: {
        step: {
            type: Object,
            required: true
        },
        consumerKey: {
            type: String,
            required: true
        },
        active: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            pharmacistBubble
        };
    }
});

</script>
