<template>
  <div class="flex flex-col gap-y-4">
    <h1>Identifiez-vous</h1>
    <div class="step-card">
      <p>Sélectionnez votre code vendeur</p>
      <v-chip-group
        v-model="selected"
        selected-class="bg-apodis text-white"
        column
      >
        <v-chip
          v-for="(code, index) in operatorCodes"
          :key="index"
          filter
          :text="itemLabel(code)"
          :value="code"
        />
      </v-chip-group>
    </div>
    <div class="flex flex-row justify-end">
      <base-button
        primary
        :disabled="!selected"
        @click="emits('next-step')"
      >
        Continuer
      </base-button>
    </div>
  </div>
</template>

<script setup lang="ts">
import BaseButton from '@/core/components/base/BaseButton.vue';
import LegacyAccess from "@/core/interfaces/Access";

const props = defineProps<{
    operatorCodes: string[];
    teamAccess: LegacyAccess[];
}>();

const selected = defineModel("selected", {required: false, type: String});

/**
 * Add the user name next to the label if available.
 */
const itemLabel = (code: string): string => {
    const access = props.teamAccess?.find((access) => access.operatorCode === code);

    if (!access) {
        return code;
    }

    return `${access.user.name} (${code})`;
};

const emits = defineEmits(['next-step']);
</script>
