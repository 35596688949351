<template>
  <div class="flex flex-1">
    <base-flex-spinner v-if="loading" />
    <div
      v-else
      class="flex flex-1 flex-col"
    >
      <div v-if="!selectProduct" class="-mt-2 mb-2">
        <span class="text-primary cursor-pointer" @click="selectProduct=true">
          Retour à la sélection
        </span>
        <p class="text-sm">
          À gauche, retrouvez les produits sélectionnés (les produits grisés sont exclus).
        </p>
        <p class="text-sm">
          À droite, examinez les interactions médicamenteuses détectées par Vidal. Les alertes sont classées : marron
          pour les contre-indications, rouge pour les associations déconseillées, orange pour les précautions d’emploi,
          et jaune pour celles à surveiller.
        </p>
      </div>
      <drug-selection
        v-if="selectProduct"
        :consumer-key="consumerKey"
        @launch-analysis="getInteractionWebsite"
      />
      <drug-interaction
        v-else
        :link="webpage"
      />
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import {drugInteractionApi} from '@/container';
import DrugInteraction from '@/drug-interaction/components/DrugInteraction.vue';
import DrugSelection from '@/drug-interaction/components/DrugSelection.vue';
import BaseFlexSpinner from "@/core/components/base/spinner/BaseFlexSpinner.vue";

export default defineComponent({
    name: 'DrugInteractionScreen',
    components: {BaseFlexSpinner, DrugSelection, DrugInteraction},
    props: {
        consumerKey: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            selectProduct: true,
            loading: false,
            webpage: null as any,
            selected: [] as any[]
        };
    },
    methods: {
        getInteractionWebsite(cips) {
            this.selectProduct = false;
            this.loading = true;
            const customerId = this.consumerKey.substring(2);
            drugInteractionApi()
                .read(cips, customerId)
                .then(response => {
                    this.webpage = response.prisca_path;
                })
                .finally(() => {
                    this.loading = false;
                });
        }
    }
});

</script>
