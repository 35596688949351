<template>
  <div class="flex flex-col gap-2">
    <div
      v-if="data"
      class="border-black rounded-lg p-3"
      style="border-width: 1px"
    >
      <div class="flex gap-12 items-center justify-between">
        <div class="flex-col flex">
          <label class="text-gray-500">
            <span class="font-content-bold text-black">{{ data.total_ordered_quantity }}</span>
            {{ $filters.pluralize('boîte', data.total_ordered_quantity) }}
            {{ $filters.pluralize('commandée', data.total_ordered_quantity) }}
          </label>
          <label class="text-gray-500">
            <span class="font-content-bold text-black">{{ data.total_delivered_quantity }}</span>
            {{ $filters.pluralize('boîte', data.total_delivered_quantity) }}
            {{ $filters.pluralize('livrée', data.total_delivered_quantity) }}
          </label>
          <label class="text-gray-500">
            <span class="font-content-bold text-black">{{ data.total_missing_quantity }}</span>
            {{ $filters.pluralize('boîte', data.total_missing_quantity) }} non
            {{ $filters.pluralize('livrée', data.total_missing_quantity) }}
          </label>
        </div>
        <div class="flex flex-col">
          <label class="font-content-bold text-primary text-3xl">{{ $filters.numberFormat(rate, 0) }}%</label>
          <label class="text-sm">Taux de disponibilité</label>
        </div>
      </div>
    </div>
    <label
      v-if="label"
      class="self-end"
    >
      {{ label }}
    </label>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

export default defineComponent({
    name: "PharmaMlStatisticsCard",
    props: {
        data: {
            required: false,
            type: Object,
            default: () => ({})
        },
        label: {
            required: false,
            type: String,
            default: null
        },
        loading: {
            required: false,
            type: Boolean,
            default: false
        }
    },
    computed: {
    /**
     * Get the rate of the delivered quantity.
     */
        rate(): number {
            const _rate = (this.data.total_delivered_quantity / this.data.total_ordered_quantity) * 100;

            return isNaN(_rate) ? 0 : _rate;
        }
    }
});
</script>
