<template>
  <div class="container max-h-screen overflow-y-auto mx-auto px-4 sm:px-6 lg:px-8 flex flex-col">
    <router-link
      to="/login"
    >
      <div
        class="w-70 absolute top-2 left-10 flex justify-center items-center"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="2"
          stroke="white"
          class="w-30 h-6"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M15.75 19.5L8.25 12l7.5-7.5"
          />
        </svg>
        <img
          class="w-56"
          :src="apodisTextWhite"
        >
      </div>
    </router-link>
    <div class="text-3xl text-white text-center">
      Pour démarrer votre inscription, connectez-vous à votre compte Pro Santé Connect
    </div>
    <div class="mt-24 mb-4 text-md text-white text-center">
      M'identifier avec
    </div>
    <button
      type="button"
      :class="`self-center`"
      @click="launchProSanteConnect"
    >
      <img
        class="w-80"
        :src="pscButton"
      >
    </button>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import {PSC_STATES, pscHelper} from '@/helpers/pscHelper';
import apodisTextWhite from '@/assets/images/logos/apodis-text-white.png';
import pscButton from '@/assets/images/logos/psc-button.svg';

export default defineComponent({
    name: 'RegisterBefore',
    data() {
        return {
            apodisTextWhite,
            pscButton
        };
    },
    methods: {
        launchProSanteConnect() {
            if (window.ipcRenderer) {
                window.ipcRenderer.logWithProSanteConnect(PSC_STATES.register);
            } else {
                window.location.href = pscHelper.pscAuthRegisterURL();
            }
        }
    }
});
</script>
