import {defineStore} from "pinia";
import {SubscriptionPlan} from "@/subscription-plan/interfaces";
import {subscriptionPlanApi} from "@/container";

interface SubscriptionPlanStore {
    subscriptionPlans?: SubscriptionPlan[];
    loading: boolean;
}

/**
 * Subscription plan store.
 */
export const useSubscriptionPlanStore = defineStore(
    'subscriptionPlan',
    {
        state: (): SubscriptionPlanStore => ({
            subscriptionPlans: undefined,
            loading: false,
        }),
        actions: {
            /**
             * Load the subscription plans from the API.
             */
            async loadSubscriptionPlans() {
                this.loading = true;
                try {
                    this.subscriptionPlans = await subscriptionPlanApi().index();
                } finally {
                    this.loading = false;
                }
            },
            /**
             * Refresh the subscription plans from the API.
             */
            async refreshSubscriptionPlans() {
                this.subscriptionPlans = await subscriptionPlanApi().index();
            },
        },
        getters: {
            /**
             * Get the global subscription plan.
             */
            globalPlan(): SubscriptionPlan | undefined {
                return this.subscriptionPlans?.find(plan => plan.is_global);
            },
            /**
             * Get the individual subscription plans.
             */
            individualPlans(): SubscriptionPlan[] {
                return this.subscriptionPlans?.filter(plan => !plan.is_global) || [];
            },
            /** Returns the subscription plan by slug. */
            subscriptionPlanBySlug(state): (slug: string) => SubscriptionPlan | undefined {
                return (slug: string): SubscriptionPlan | undefined => {
                    return state.subscriptionPlans?.find(subPlan => subPlan.slug === slug);
                };
            },
        }
    }
);
