<template>
  <div class="flex notification-drawer" :class="{'notification-open': isOpen, 'notification-close': !isOpen}">
    <NotificationDetectionStrip class="shrink-0" :item="item" :is-open="isOpen" @toggle="toggleNotification" />
    <div
      v-if="shouldShowPanel"
      class="flex-1 bg-white rounded-bl-xl border-l border-white"
      @mouseenter="windowHoverChange(true)"
      @mouseleave="windowHoverChange(false)"
    >
      <notification-detection-customer-header
        :item="item"
        @show-customer="showCustomer"
        @navigate-qr-code="showQRCode"
        @navigate-sms="navigateCertification"
        @navigate-surveys="navigateSurveys"
      />
      <notification-detection-programs
        v-if="item.customer"
        :item="item"
        @resize="handleNotificationResize"
      />
      <notification-detection-memberships
        v-if="item.customer"
        :item="item"
        @link-membership="attachLoyaltyCard"
        @show-membership="showMembership"
      />
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from "vue";
import '@/notification/components/notification-detection/notification-detection.css';
import {customerCertificationApi, loyaltyCardMembershipApi, storageService} from '@/container';
import NotificationDetectionPrograms
    from '@/notification/components/notification-detection/programs/NotificationDetectionPrograms.vue';
import NotificationDetectionMemberships
    from '@/notification/components/notification-detection/loyalty-cards/NotificationDetectionMemberships.vue';
import NotificationDetectionCustomerHeader
    from '@/notification/components/notification-detection/NotificationDetectionCustomerHeader.vue';
import NotificationDetectionStrip
    from "@/notification/components/notification-detection/NotificationDetectionStrip.vue";
import {UserSettings} from "@/settings/interfaces";
import {DetectionNotificationProps} from "@/notification/interfaces";
import Customer from "@/customer/interfaces/Customer";
import {LoyaltyCard} from "@/loyalty-card/interfaces/hds/LoyaltyCard";

export const DETECTION_HEADER_HEIGHT = 80;

export default defineComponent({
    components: {
        NotificationDetectionStrip,
        NotificationDetectionCustomerHeader,
        NotificationDetectionMemberships,
        NotificationDetectionPrograms,
    },
    props: {
        item: {
            type: Object as PropType<DetectionNotificationProps>,
            required: true
        }
    },
    emits: ['resize'],

    data() {
        return {
            headerHeight: DETECTION_HEADER_HEIGHT,
            printing: false,
            isOpen: true,
            shouldShowPanel: true,
            toggleTimeout: null as null | NodeJS.Timeout,
            closeOperationTimeout: null as null | NodeJS.Timeout,
            remainingTimeoutTime: 0,
            startTime: 0
        };
    },

    watch: {
        item: {
            immediate: true,
            handler() {
                if (this.toggleTimeout) {
                    clearTimeout(this.toggleTimeout);
                    this.toggleTimeout = null;
                }

                if (this.closeOperationTimeout) {
                    clearTimeout(this.closeOperationTimeout);
                    this.closeOperationTimeout = null;
                }

                this.isOpen = true;
                this.shouldShowPanel = true;

                this.initToggleTimeout(this.item.settings);
            }
        }
    },
    methods: {
        /**
         * Navigate to the customer page.
         *
         * @param customer
         */
        showCustomer(customer: Customer) {
            window.ipcRenderer.click();

            if (customer && customer.id) {
                window.ipcRenderer.send('to-main-window', 'show-customer', customer.id);
            }
        },
        /**
         * Hide the notification
         */
        ignore() {
            window.ipcRenderer.send('hide-notification');
        },
        /**
         * Open the membership page.
         *
         * @param {Object} loyaltyCard
         */
        showMembership(loyaltyCard: LoyaltyCard) {
            if (loyaltyCard.membership && this.item.customer?.id) {
                window.ipcRenderer.send(
                    'navigate',
                    `/customer/${this.item.customer.id}/loyalty/membership/${loyaltyCard.membership.id}`,
                    true
                );
            }
        },
        /**
         * Attach a loyalty card to the customer.
         *
         * @param {Object} loyaltyCard The loyalty card.
         *
         * @return {Promise<void>}
         */
        async attachLoyaltyCard(loyaltyCard: LoyaltyCard): Promise<void> {
            if (!this.item.customer?.id) {
                return Promise.resolve();
            }

            return await loyaltyCardMembershipApi()
                .create(loyaltyCard.id, this.item.customer.id)
                .then((membership) => {
                    const card = this.item.loyalty_cards.find((card) => card.id === loyaltyCard.id);

                    if (card) {
                        card.membership = membership;
                    }
                });
        },
        /**
         * Show onboarding QR code.
         *
         * @param {Customer} customer The customer.
         */
        showQRCode(customer: Customer) {
            if (!customer || !customer.id || this.printing) {
                return;
            }

            window.ipcRenderer.click();
            this.printing = true;
            window.ipcRenderer
                .invoke('print', {
                    url: customerCertificationApi().pdfUrl(customer.id, 'qrcode'),
                    token: storageService().read('token')
                })
                .finally(() => {
                    this.printing = false;
                });
        },
        /**
         * Navigate to the customer certification page.
         */
        navigateCertification(customer: Customer) {
            if (!customer || !customer.id || this.printing) {
                return;
            }

            window.ipcRenderer.click();
            window.ipcRenderer.send(
                'navigate',
                `/customer/${customer.id}/certification`,
                true
            );
        },
        /**
         * Navigate to the customer survey page.
         *
         * @param {Object} customer
         */
        navigateSurveys(customer: Customer) {
            if (!customer || !customer.id) {
                return;
            }

            window.ipcRenderer.click();
            window.ipcRenderer.send(
                'navigate',
                `/customer/${customer.id}/survey-instances`,
                true
            );
        },
        /**
         * Handle the notification resize.
         */
        handleNotificationResize() {
            this.$emit('resize');
        },
        /**
         * Send IPC event to change window hovered state.
         * @param hovered
         */
        windowHoverChange(hovered: boolean) {
            hovered ? this.pauseNotificationTimeout(this.item.settings) : this.resumeNotificationTimeout();

            window.ipcRenderer.windowHoveredChange("notification", hovered);
        },

        /**
         * Toggle the notification drawer.
         */
        toggleNotification() {
            if (this.isOpen) {
                this.closeDrawer();
            } else {
                this.openDrawer();
            }
        },

        /**
         * Clear the notification and open the drawer.
         */
        openDrawer() {
            if (this.toggleTimeout) {
                clearTimeout(this.toggleTimeout);
                this.toggleTimeout = null;
            }
            window.ipcRenderer.notificationToggle(true);
            this.isOpen = true;
            this.shouldShowPanel = true;
        },

        /**
         * Clear the toggle timeout, close the drawer and ask main process to resize the window once animation is done.
         */
        closeDrawer() {
            if (this.toggleTimeout) {
                clearTimeout(this.toggleTimeout);
                this.toggleTimeout = null;
            }

            this.isOpen = false;

            this.closeOperationTimeout = setTimeout(() => {
                this.shouldShowPanel = false;
                window.ipcRenderer.notificationToggle(false, {width: 35, height: this.headerHeight});
            }, 300); // timeout time must match the animation duration
        },

        /**
         * Initialize the toggle timeout with the user settings preferences.
         */
        initToggleTimeout(config: UserSettings) {
            if (config.notification.time) {
                this.toggleTimeout = setTimeout(this.closeDrawer, config.notification.time);
                this.startTime = Date.now();
                this.remainingTimeoutTime = config.notification.time;
            }
        },
        /**
         * Pause the notification timeout.
         */
        pauseNotificationTimeout(config: UserSettings) {
            if (this.toggleTimeout) {
                clearTimeout(this.toggleTimeout);
                this.remainingTimeoutTime = this.calculateRemainingTime(config);
            }
        },
        /**
         * Resume the notification timeout.
         */
        resumeNotificationTimeout() {
            if (this.remainingTimeoutTime > 0 && this.toggleTimeout) {
                this.toggleTimeout = setTimeout(this.closeDrawer, this.remainingTimeoutTime);
                this.startTime = Date.now();
            }
        },
        /**
         * Calculate the remaining time.
         */
        calculateRemainingTime(config: UserSettings) {
            const elapsedTime = this.startTime - this.startTime;

            return (config.notification.time || 0) - elapsedTime;
        },
    }
});
</script>

<style scoped>
.notification-drawer {
    transition: all 0.3s ease-out;
}

.notification-open {
    transform: translateX(0);
}

.notification-close {
    transform: translateX(calc(100% - 35px));
}
</style>
