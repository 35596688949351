export const PSC_STATES = {
    connect_login: 'connect_login',
    register: 'register',
    linkcps: 'linkcps',
    deciphera: 'deciphera'
} as const;

export type PSCState = typeof PSC_STATES[keyof typeof PSC_STATES];

export const pscHelper = {

    pscAuthLoginURL(): string {
        return this.pscAuthURL(PSC_STATES.connect_login);
    },


    pscAuthRegisterURL(): string {
        return this.pscAuthURL(PSC_STATES.register);
    },


    pscLinkURL(): string {
        return this.pscAuthURL(PSC_STATES.linkcps);
    },


    pscAuthURL(state: PSCState): string {
        const PROSANTECONNECT_CALLBACK_URL = import.meta.env.VITE_PROSANTECONNECT_CALLBACK_URL;
        const PROSANTECONNECT_CLIENT_ID = import.meta.env.VITE_PROSANTECONNECT_CLIENT_ID;
        const PROSANTECONNECT_WALLET_URL = import.meta.env.VITE_PROSANTECONNECT_WALLET_URL;
        const responseType = 'code';
        const scope = encodeURIComponent('openid scope_all');
        const clientId = PROSANTECONNECT_CLIENT_ID;
        const redirect = encodeURIComponent(PROSANTECONNECT_CALLBACK_URL);

        // Valeur générée aléatoirement par le FS, recopié dans le token d'authentification pour être vérifié par le FS.
        // Il permet de se prémunir contre les attaques de rejeu
        const nonce = [...Array(20)].map(() => (~~(Math.random() * 36)).toString(36)).join('');

        const acrValues = 'eidas1';
        const params = `response_type=${responseType}&scope=${scope}&client_id=${clientId}&state=${state}&redirect_uri=${redirect}&nonce=${nonce}&acr_values=${acrValues}`;
        return `${PROSANTECONNECT_WALLET_URL}/auth/?${params}`;
    }
};
