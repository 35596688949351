import dayjs from 'dayjs';
import { OrderContentType, OrderStatusType } from '@/order/interfaces/Order';

export const OrderStatusLabel = {
    [OrderStatusType.PENDING]: 'En attente de confirmation',
    [OrderStatusType.ACCEPTED]: 'En préparation',
    [OrderStatusType.AVAILABLE]: 'Prête',
    [OrderStatusType.DELIVERED]: 'Délivrée',
    [OrderStatusType.REJECTED]: 'Rejetée'
};

export default {
    /**
   * Retrieve the order type label.
   *
   * @param {OrderContentType} type - The content type.
   *
   * @returns {string}
   */
    orderTypeLabel (type) {
        switch (type) {
        case OrderContentType.RENEWAL:
            return 'Renouvellement';
        case OrderContentType.NEW_PRESCRIPTION:
            return 'Scan';
        default:
            return '';
        }
    },

    /**
   * Display the status of an order.
   *
   * @param {OrderStatusType} status - The order status.
   *
   * @return {string}
   */
    orderStatus (status) {
        switch (status) {
        case OrderStatusType.ACCEPTED:
            return 'Acceptée';
        case OrderStatusType.AVAILABLE:
            return 'Disponible';
        case OrderStatusType.DELIVERED:
            return 'Délivrée';
        case OrderStatusType.REJECTED:
            return 'Refusée';
        default:
            return 'En attente';
        }
    },
    /**
   * Get a color depending the order status.
   *
   * @param {Order} order
   * @return {string}
   */
    statusColor (order) {
        if (
            order.status === OrderStatusType.DELIVERED ||
      (order.status === OrderStatusType.AVAILABLE && this.isPast(order))
        ) {
            return 'success';
        } else if (order.status === OrderStatusType.REJECTED) {
            return 'error';
        } else {
            return 'warning';
        }
    },
    /**
   * Determine if the order is past.
   *
   * @param {Order} order
   *
   * @return {boolean}
   */
    isPast (order) {
        return dayjs(order.available_at).isBefore(dayjs());
    },
    /**
   * Get the order status label.
   *
   * @param {Order} order - The order.
   *
   * @return {string}
   */
    statusLabel (order) {
        if (!order.status) {
            return '';
        }

        if (order.status === OrderStatusType.ACCEPTED) {
            return OrderStatusLabel[OrderStatusType.ACCEPTED];
        } else if (order.status === OrderStatusType.AVAILABLE) {
            return this.isPast(order)
                ? OrderStatusLabel[OrderStatusType.AVAILABLE]
                : OrderStatusLabel[OrderStatusType.ACCEPTED];
        } else if (order.status === OrderStatusType.DELIVERED) {
            return OrderStatusLabel[OrderStatusType.DELIVERED];
        } else if (order.status === OrderStatusType.REJECTED) {
            return OrderStatusLabel[OrderStatusType.REJECTED];
        }

        return OrderStatusLabel[OrderStatusType.PENDING];
    }
};
