import {AxiosInstance, AxiosResponse} from "axios";

/**
 * The experience resource.
 * @author Thomas Denneulin <t.denneulin@apodispharma.com>
 *
 * @param {Object} client - The injected client.
 * @returns {Object} The public object.
 */
export default function (client: AxiosInstance) {
    return {
        /**
         * Get all experience events.
         *
         * @returns {Promise} Promise object represents the HTTP request.
         */
        indexEvents() {
            return client
                .get('/experience/events')
                .then((response: AxiosResponse) => response.data.data);
        }
    };
}
