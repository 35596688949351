<script setup lang="ts">
import {MassCertificationCustomer} from "@/core/interfaces/MassCertification";
import Icon from "@/core/components/icon/Icon.vue";
import {computed} from "vue";

const {customer, isNotification} = defineProps<{ customer: MassCertificationCustomer, isNotification?: boolean }>();

const phone = computed(() => {
    if (!customer?.phone) {
        return null;
    }

    let phoneNumber = customer.phone.replace(/\s+/g, "")
        .replace(/\(|\)/g, "")
        .replace(/\+33(0)?/, "0");

    if (!/^(0[1-9])/.test(phoneNumber)) {
        return phoneNumber;
    }

    return phoneNumber.replace(
        /(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/,
        "$1 $2 $3 $4 $5"
    );

});

const validPhoneFormat = computed(() => {
    if (!phone.value) {
        return false;
    }
    return phone.value.startsWith('06') || phone.value.startsWith('07');
});
</script>

<template>
  <div class="flex flex-2 lg:flex-1 flex-col">
    <p>
      {{ customer.name }}
      <span v-if="customer?.birthDate"> ({{ $filters.date(customer.birthDate, 'relative', true) }})</span>
    </p>
    <div v-if="!isNotification">
      <p v-if="phone">
        <v-tooltip
          v-if="!validPhoneFormat"
          text="06/07 préconisé"
          location="bottom"
        >
          <template #activator="{ props }">
            <icon v-bind="props" class="h-6 w-6 cursor-pointer" color="primary" name="mdi-information" />
          </template>
        </v-tooltip>
        {{ phone }}
      </p>
      <span v-else class="text-sm">Pas de numéro de téléphone</span>
    </div>
  </div>
</template>
