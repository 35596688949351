<script setup lang="ts">
import {Backing, BackingInterviewStep, BackingInvoiceStep, BackingStep} from "@/backing/interfaces/backing";
import BaseCard from "@/core/components/base/BaseCard.vue";
import BaseCardBody from "@/core/components/base/BaseCardBody.vue";
import BackingStepper from "@/backing/components/BackingStepper.vue";
import {computed} from "vue";
import BackingInterview from "@/backing/components/BackingInterview.vue";
import BackingInvoiced from "@/backing/components/BackingInvoiced.vue";
import InterviewImage from "@/assets/images/interview.png";
import {useRouter} from "vue-router";
import Customer from "@/customer/interfaces/Customer";
import BackingInterviewInstance from "@/backing/components/BackingInterviewInstance.vue";
import MarkdownView from "@/core/components/markdown/Markdown.vue";

const props = defineProps<{
    backing: Backing,
    customer: Customer;
}>();

const router = useRouter();

/**
 * Compute the billing placeholder replacement.
 */
const billing = computed(() =>
    [props.backing.first_year_billing_act?.code, props.backing.next_years_billing_act?.code]
        .filter(n => n)
        .join(' ou ')
);

/**
 * Compute the billing placeholder replacement.
 */
const billingExtended = computed(() => {
    if (props.backing.first_year_billing_act?.code) {
        if (props.backing.next_years_billing_act?.code) {
            return props.backing.first_year_billing_act.code
                + ' la première année pour un montant de '
                + (props.backing.first_year_billing_act?.price || '[non-renseigné]') + ' €'
                + ' ou ' + props.backing.next_years_billing_act.code
                + ' les suivantes pour un montant de '
                + (props.backing.next_years_billing_act?.price || '[non-renseigné]') + ' €';
        }

        return props.backing.first_year_billing_act.code
            + ' pour un montant de '
            + (props.backing.first_year_billing_act?.price || '[non-renseigné]') + '€';
    }

    return '';
});

/**
 * Check if the backing has completed steps
 */
const completedSteps = computed(() => props.backing.steps.filter((step: BackingStep) => step.completed_at));

/**
 * Get the interview steps
 */
const interviewSteps = computed(() => props.backing.steps.filter((step: BackingStep): step is BackingInterviewStep => {
    return step.resource_type === 'interview';
}));

/**
 * Flatten the interview instances.
 */
const interviewInstances = computed(() => {
    return interviewSteps.value.flatMap((step: BackingInterviewStep) => {
        return step.interview_instances?.map(instance => {
            return {
                instance,
                step: {
                    ...step,
                    interview_instances: undefined
                }
            };
        }) || [];
    });
});

/**
 * Get the completed invoice steps
 */
const completedInvoiceSteps = computed(() => completedSteps.value.filter((step: BackingStep): step is BackingInvoiceStep => {
    return step.resource_type === 'invoice';
}));

/**
 * Start the interview
 */
const startInterview = (step: BackingStep) => {
    router.push({
        name: 'interview-instances',
        query: {
            backingId: props.backing.id,
            interviewId: step.resource_id,
            consumerKey: props.customer.consumer_key,
            interviewName: step.name,
        }
    });
};
</script>

<template>
  <div>
    <BaseCard>
      <BaseCardBody>
        <div class="flex justify-space-between items-center mb-4">
          <h2 class="flex-1">
            {{ backing.name }}
          </h2>
          <BackingStepper :backing="backing" />
        </div>
        <markdown-view :content="backing.description?.replace('{billing_code}', billing)" />
        <div
          v-if="interviewInstances?.length"
          class="bg-secondary-lightest rounded p-4 mt-2"
        >
          <h3 class="mb-1">
            Avancement
          </h3>
          <div class="flex flex-row flex-wrap gap-4">
            <div v-for="instance in interviewInstances" :key="instance.instance.id">
              <BackingInterviewInstance
                :data="instance"
                :backing-id="backing.id"
                :consumer-name="customer.information.name"
                :consumer-key="customer.consumer_key"
              />
            </div>
          </div>
          <div class="flex flex-row flex-wrap gap-4 mt-3">
            <div v-for="(step, index) in completedInvoiceSteps" :key="'invoiced' + index">
              <BackingInvoiced :step="step" />
            </div>
          </div>
        </div>
        <div
          v-if="backing.billable"
          class="bg-primary rounded p-4 mt-2"
        >
          <h3 class="mb-1">
            Accompagnement terminé !
          </h3>
          <p class="mt-2">
            Vous avez réalisé tous les entretiens nécessaires à l’accompagnement de votre patient.
            Vous pouvez maintenant facturer le code acte <strong>{{ billingExtended }}</strong> depuis votre LGO.
            <br><br>
            <em>⚠️ Pour être valide, ce code acte doit être facturé seul, c’est-à-dire indépendamment de toute autre
              facturation
              (médicaments, LPP…).</em>
          </p>
        </div>
      </BaseCardBody>
    </BaseCard>
    <div class="p-4">
      <h3>Entretiens</h3>
      <div class="mb-2">
        Commencez par un entretien d'évaluation si nécessaire puis effectuez les entretiens thématiques
      </div>
      <div class="flex flex-row">
        <div class="min-w-24 mr-16">
          <img
            :src="InterviewImage"
            alt="Entretien"
          >
        </div>
        <div class="flex flex-row flex-wrap gap-2 self-start">
          <div
            v-for="step in interviewSteps"
            :key="'available' + step.resource_id"
            @click="() => startInterview(step)"
          >
            <BackingInterview :step="step" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
