<script setup lang="ts">
import BaseModal from "@/core/components/base/BaseModal.vue";
import BaseButton from "@/core/components/base/BaseButton.vue";
import {filemanagerFileApi, offerTeaserApi} from "@/container";
import {ref, onMounted} from "vue";
import DeprecatedFileManagerFile from "@/core/interfaces/FileManagerFile";
import BaseFlexSpinner from "@/core/components/base/spinner/BaseFlexSpinner.vue";
import {OfferTeaser} from "@/core/interfaces/Offer";
import BaseCard from "@/core/components/base/BaseCard.vue";

const {teaser} = defineProps<{ visible: boolean; teaser: OfferTeaser }>();
const emits = defineEmits(['on-close', 'on-import', 'on-edit']);

const images = ref<DeprecatedFileManagerFile[]>([]);
const loading = ref(true);
const defaults = {
    header: "-1€",
    text: "Personnalisez votre texte"
};
const content = ref({header: teaser?.header || defaults.header, text: teaser?.text || defaults.text});

onMounted(() => {
    offerTeaserApi().images()
        .then(response => {
            images.value = response;
        })
        .finally(() => loading.value = false);
});

/**
 * Open the file upload dialog
 */
const openFileUpload = () => {
    document.getElementById('input-file')?.click();
};

/**
 * Handle the file upload
 *
 * @param {File} file
 */
const handleFileUpload = (file: File | File[]) => {
    const blob = new Blob(Array.isArray(file) ? file : [file], {type: 'audio/mp3'});

    filemanagerFileApi().upload(blob)
        .then(file => emits('on-import', file));
};
</script>

<template>
  <BaseModal v-show="visible" title="Sélectionnez ou importez un visuel" @close="$emit('on-close')">
    <BaseCard class="p-4" color="bg-neutral">
      <div class="flex flex-row justify-between">
        <div class="flex flex-col pr-4">
          <h2>Personnalisation</h2>
          <div class="flex flex-row items-center">
            <h2>1.</h2>
            <p class="ml-2">
              Personnalisez votre visuel : adaptez le titre et la description à votre offre
            </p>
          </div>
          <div class="flex flex-row items-center">
            <h2>2.</h2>
            <span class="ml-1">Sélectionnez un visuel parmi ceux disponibles ci-dessous</span>
          </div>
          <span class="ml-3">💡Vous pouvez aussi importer directement votre propre visuel</span>
        </div>
        <BaseButton prepend-icon="mdi-upload" @click="openFileUpload()">
          {{ "Importer un visuel" }}
        </BaseButton>
        <v-file-input
          v-show="false"
          id="input-file"
          accept="image/*"
          @update:model-value="handleFileUpload"
        />
      </div>
      <div class="flex flex-row gap-4 py-4">
        <v-text-field
          v-model="content.header"
          :rules="[value => (value && value.length <= 6) || 'Maximum 6 caractères']"
          hide-details="auto"
          label="Titre"
          class="bg-white w-60"
        />
        <v-text-field
          v-model="content.text"
          :rules="[value => (value && value.length <= 30) || 'Maximum 30 caractères']"
          hide-details="auto"
          label="Description"
          class="bg-white w-60"
        />
      </div>
      <BaseFlexSpinner v-if="loading" />
      <div v-else class="grid grid-cols-3 gap-4">
        <div
          v-for="image in images"
          :key="image.id"
          class="cursor-pointer hover:opacity-50"
          @click="$emit('on-edit', image, content)"
        >
          <div class="relative">
            <img :src="image.url" class="w-[250px] h-[179px]">
            <p class="offer-text absolute top-12 left-5 text-center text-[35px] w-[150px] font-bold">
              {{ content.header ? content.header : defaults.header }}
            </p>
            <p class="offer-text absolute top-24 left-5 text-center w-[150px] font-bold">
              {{ content.text ? content.text : defaults.text }}
            </p>
          </div>
        </div>
      </div>
    </BaseCard>
  </BaseModal>
</template>

<style scoped>
.offer-text {
    color: #ffffff;
    text-shadow: rgba(0, 0, 0, 0.35) 2px 1px 10px;
}
</style>
