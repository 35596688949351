import formatHelper from '../helpers/formatHelper';

/**
 * Format phone.
 *
 * @param value - The value.
 *
 * @return The formatted phone.
 */
export const phoneFormat = (value: string) => {
    return formatHelper.readablePhone(value);
};
