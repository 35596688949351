<template>
  <div
    v-if="!loading"
    class="flex flex-col container mx-auto p-2"
  >
    <div
      v-if="!!fileSettings.length"
      class="mb-2"
    >
      <h2 class="text-xl mt-2">
        Fichiers liés aux programmes
      </h2>
      <program-consumer-setting-file-card
        v-for="setting in fileSettings"
        :key="setting.id"
        :setting="setting"
        class="mb-1"
        @click="(_setting) => onFileSettingClick(_setting)"
      />
    </div>
    <div v-if="!!files.length">
      <h2 class="text-xl mt-2">
        Fichiers hospitaliers
      </h2>
      <customer-hospital-file-card
        v-for="file in files"
        :key="file.id"
        :file="file"
        @click="(_file) => onFileClick(_file)"
      />
    </div>
    <div
      v-else
      class="my-4 p-4 bg-white rounded-lg"
    >
      <span>Ce patient ne possède aucun documents hospitaliers.</span>
    </div>
  </div>
  <div
    v-else
    class="mt-4"
  >
    <base-flex-spinner />
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import {hospitalFileApi, programApi} from '@/container';
import CustomerHospitalFileCard from '@/customer/components/CustomerHospitalFileCard.vue';
import ProgramConsumerSettingFileCard
    from '@/program/components/program-consumer-settings/ProgramConsumerSettingFileCard.vue';
import BaseFlexSpinner from "@/core/components/base/spinner/BaseFlexSpinner.vue";

export default defineComponent({
    name: 'CustomerHospitalFiles',
    components: {BaseFlexSpinner, ProgramConsumerSettingFileCard, CustomerHospitalFileCard},
    props: {
        customer: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            files: [] as any[],
            fileSettings: [] as any[],
            loading: false
        };
    },
    mounted() {
        this.loading = true;
        this.loadHospitalFiles(this.customer.consumer_key)
            .then(() => {
                return this.loadProgramFileSettings(this.customer.consumer_key);
            })
            .finally(() => {
                this.loading = false;
            });
    },
    methods: {
    /**
     * Download the file.
     *
     * @param {Object} file
     */
        onFileClick(file) {
            hospitalFileApi()
                .download(file)
                .then((response) => {
                    const url = URL.createObjectURL(response);
                    window.open(url);
                });
        },
        /**
     * Download the file behind the setting.
     *
     * @param setting
     */
        onFileSettingClick(setting) {
            programApi()
                .downloadProgramSettingFile(setting.id)
                .then((response) => {
                    const url = URL.createObjectURL(response);
                    window.open(url);
                });
        },
        /**
     * Load the hospital files.
     *
     * @param consumerKey
     * @return {Promise<unknown>}
     */
        loadHospitalFiles(consumerKey) {
            return hospitalFileApi()
                .index(consumerKey)
                .then((response) => {
                    this.files = response;
                });
        },
        /**
     * Load the consumer program settings.
     *
     * @param consumerKey
     * @return {Promise<Object>}
     */
        loadProgramFileSettings(consumerKey) {
            return programApi()
                .consumerSupportProgramSettings(consumerKey, {
                    secure_file: true
                })
                .then((response) => {
                    this.fileSettings = response;
                });
        }
    }
});
</script>
