import {RouteRecordRaw} from "vue-router";
import DisruptionLogisticsNavigationView from "@/disruption-logistics/views/DisruptionLogisticsNavigationView.vue";
import DisruptionLogisticsHomeView from "@/disruption-logistics/views/DisruptionLogisticsHomeView.vue";
import DisruptionLogisticsProductAvailabilityView
    from "@/disruption-logistics/views/DisruptionLogisticsProductAvailabilityView.vue";
import WholesalerWarehouseHealthcenterView from "@/disruption-logistics/views/WholesalerWarehouseHealthcenterView.vue";
import HealthcenterGroupsView from "@/disruption-logistics/views/HealthcenterGroupsView.vue";
import HealthcenterGroupDetailsView from "@/disruption-logistics/views/HealthcenterGroupDetailsView.vue";
import CreateHealthcenterGroupView from "@/disruption-logistics/views/CreateHealthcenterGroupView.vue";
import DirectAvailabilitySourceView from "@/disruption-logistics/views/DirectAvailabilitySourceView.vue";
import DirectAvailabilityView from "@/disruption-logistics/views/DirectAvailabilityView.vue";
import AvailabilityHistoryView from "@/disruption-logistics/views/AvailabilityHistoryView.vue";
import ProductAvailableAgainView from "@/disruption-logistics/views/ProductAvailableAgainView.vue";
import DisruptionAlertsView from "@/disruption-logistics/views/DisruptionAlertsView.vue";
import DisruptionAlertDetailsView from "@/sellin-offer/views/SellinOfferView.vue";

const routes: RouteRecordRaw[] = [
    {
        path: '/disruption-logistics',
        name: 'disruption-logistics',
        component: DisruptionLogisticsNavigationView,
        redirect() {
            return {name: 'disruption-logistics.home'};
        },

        children: [
            {
                path: 'availabilities',
                name: 'disruption-logistics.home',
                component: DisruptionLogisticsHomeView,
                meta: {
                    title: 'Info dispo'
                }
            },
            {
                path: 'direct-availability-sources',
                name: 'disruption-logistics.direct-availability-sources',
                component: DirectAvailabilitySourceView,
                meta: {
                    title: 'Disponibilités ventes directes'
                }
            },
            {
                path: 'disruptions',
                name: 'disruption-logistics.disruptions',
                component: ProductAvailableAgainView,
                meta: {
                    title: 'Retours de disponibilité'
                }
            },
            {
                path: 'disruption-alerts',
                name: 'disruption-logistics.disruption-alerts',
                component: DisruptionAlertsView,
                meta: {
                    title: 'Alertes et dépannages'
                },
            },
            {
                path: '/disruption-alerts/:offerId',
                name: 'disruption-logistics.disruption-alerts.detail',
                component: DisruptionAlertDetailsView,
                meta: {
                    title: 'Alertes et dépannages'
                },

            },
        ],

        meta: {
            title: 'Rupture premium'
        }
    },
    {
        path: '/disruption-logistics/products/:productId',
        name: 'disruption-logistics.product-availability',
        component: DisruptionLogisticsProductAvailabilityView
    },
    {
        path: '/disruption-logistics/wholesaler-warehouse-healthcenter',
        name: 'disruption-logistics.wholesaler-warehouse-healthcenter',
        component: WholesalerWarehouseHealthcenterView
    },
    {
        path: '/disruption-logistics/healthcenter-groups',
        name: 'disruption-logistics.healthcenter-groups',
        component: HealthcenterGroupsView
    },
    {
        path: '/disruption-logistics/healthcenter-groups/:healthcenterGroupId',
        name: 'disruption-logistics.healthcenter-group',
        component: HealthcenterGroupDetailsView
    },
    {
        path: '/disruption-logistics/healthcenter-groups/create',
        name: 'disruption-logistics.healthcenter-group-create',
        component: CreateHealthcenterGroupView
    },
    {
        path: '/disruption-logistics/direct-availability-sources/:sourceId',
        name: 'disruption-logistics.direct-availabilities',
        component: DirectAvailabilityView
    },
    {
        path: '/disruption-logistics/availabilities/:availabilityId/history',
        name: 'disruption-logistics.availability-history',
        component: AvailabilityHistoryView
    },
];

export default routes;
