<template>
  <div
    class="flex items-center gap-2"
  >
    <healthcenter-group-icon />
    <div class="flex flex-1">
      <span>
        <span class="font-bold">
          {{ invitation.group?.name }}
        </span> invité par {{ invitation.group?.owner?.healthcenter?.name }}<label v-if="address">,
          {{ addressLabel }}
        </label>
      </span>
    </div>
    <div>
      <div
        v-if="!loading"
        class="flex font-bold gap-5"
      >
        <button @click="handleInvitation(false)">
          <span>Refuser</span>
        </button>
        <button
          class="text-primary"
          @click="handleInvitation(true)"
        >
          <span>Accepter</span>
        </button>
      </div>
      <div v-else>
        <base-spinner size="button" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';
import HealthcenterGroupIcon from "@/disruption-logistics/components/healthcenter-groups/HealthcenterGroupIcon.vue";
import BaseSpinner from "@/core/components/base/spinner/BaseSpinner.vue";
import {HealthcenterGroupInvitation} from "@/disruption-logistics/interfaces/healthcenter-groups";
import {OrganizationAddress} from "@/core/interfaces/organization/Organization";
import {disruptionLogisticsApi} from "@/container";

export default defineComponent({
    name: "HealthcenterGroupReceivedInvitationRow",
    components: {BaseSpinner, HealthcenterGroupIcon},
    props: {
        invitation: {
            type: Object as PropType<HealthcenterGroupInvitation>,
            required: true
        }
    },
    emits: ['invitation-handled'],
    data() {
        return {
            loading: false
        };
    },
    computed: {
    /**
     * The address of the healthcenter owner of the invitation group.
     */
        address(): undefined | OrganizationAddress {
            return this.invitation.group?.owner?.healthcenter?.address;
        },
        /**
     * The label of the address.
     */
        addressLabel(): string {
            return `${this.address?.postal_code} ${this.address?.city}`;
        }
    },
    methods: {
    /**
     * Handle the invitation
     */
        async handleInvitation(accepted: boolean): Promise<void> {
            this.loading = true;
            return disruptionLogisticsApi()
                .handleInvitation(this.invitation.id, accepted)
                .then(() => {
                    this.$emit('invitation-handled', this.invitation, accepted);
                })
                .finally(() => {
                    this.loading = false;
                });
        }
    }
});
</script>

<style scoped>

</style>
