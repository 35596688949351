<template>
  <div class="m-6 p-5 rounded-lg bg-white">
    <strong>{{ section.title.length > 0 ? section.title : "" }}</strong>
    <SurveyBuiltQuestion
      v-for="question in section.questions"
      :key="`question-${question.id}`"
      :question="question"
      :survey-id="surveyId"
    />
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import SurveyBuiltQuestion from '@/survey/components/SurveyBuiltQuestion.vue';

export default defineComponent({
    name: 'SurveyBuiltSection',
    components: { SurveyBuiltQuestion },
    props: {
        surveyId: {
            type: Number,
            required: true
        },
        section: {
            type: Object,
            required: true
        }
    }
});
</script>
