<template>
  <GmapMarker
    :options="markerOptions"
    @click="$emit('click', healthcenter)"
  >
    <div class="relative flex items-center justify-center">
      <img
        alt="marker-icon"
        :src="isProductAvailable ? GroupAvailableIcon : GroupUnavailableIcon"
        width="45"
        height="45"
      >
      <p class="absolute text-white font-bold">
        {{ productStock }}
      </p>
    </div>
  </GmapMarker>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';
import {CustomMarker as GmapMarker} from "vue3-google-map";
import GroupAvailableIcon from '@/assets/images/map-markers/availabilities/group-available.svg';
import GroupUnavailableIcon from '@/assets/images/map-markers/availabilities/group-unavailable.svg';
import {HealthcenterGroupMember} from "@/disruption-logistics/interfaces/availability-providers";

export default defineComponent({
    name: "HealthcenterGroupAvailabilityMapMarker",
    components: {GmapMarker},
    props: {
        healthcenter: {
            type: Object as PropType<HealthcenterGroupMember>,
            required: true
        }
    },
    emits: ['click'],
    data() {
        return {
            GroupUnavailableIcon,
            GroupAvailableIcon
        };
    },
    computed: {
        markerOptions(): google.maps.CustomMarkerOptions {
            return {
                position: {
                    lat: this.healthcenter.address?.latitude || 0,
                    lng: this.healthcenter.address?.longitude || 0
                },
                anchorPoint: 'CENTER',
            };
        },
        /**
     * Get the healthcenter product stock.
     */
        productStock(): number {
            return this.healthcenter.stocks?.length ? this.healthcenter.stocks[0].quantity : 0;
        },
        /**
     * Check if the product is available.
     */
        isProductAvailable(): boolean {
            return !!(this.healthcenter.stocks?.length && this.healthcenter.stocks[0].quantity > 0);
        },

    }
});
</script>

<style scoped>

</style>
