<template>
  <base-card>
    <base-card-body class="flex flex-col gap-3">
      <p class="font-bold text-xl">
        Invitations en attentes
      </p>
      <healthcenter-group-sent-invitation-row
        v-for="invitation in invitations"
        :key="invitation.id"
        :invitation="invitation"
        @cancel-invitation="$emit('cancel-invitation', invitation)"
      />
    </base-card-body>
  </base-card>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';
import BaseCard from "@/core/components/base/BaseCard.vue";
import BaseCardBody from "@/core/components/base/BaseCardBody.vue";
import {HealthcenterGroupInvitation} from "@/disruption-logistics/interfaces/healthcenter-groups";
import HealthcenterGroupSentInvitationRow
    from "@/disruption-logistics/components/healthcenter-groups/HealthcenterGroupSentInvitationRow.vue";

export default defineComponent({
    name: "HealthcenterGroupSentInvitationCard",
    components: {HealthcenterGroupSentInvitationRow, BaseCardBody, BaseCard},
    props: {
        invitations: {
            type: Array as PropType<HealthcenterGroupInvitation[]>,
            required: true
        }
    },
    emits: ['cancel-invitation']
});
</script>

<style scoped>

</style>
