<template>
  <div class="relative">
    <div class="flex relative px-4 justify-between items-baseline">
      <img
        :src="program.logo.url"
        class="w-16 h-16 object-contain relative -bottom-2 rounded-md"
        alt="program-logo"
      >
      <label class="text-sm text-gray-700">Document d'inclusion</label>
    </div>
    <base-card class="card bg-white z-10 flex flex-col p-2">
      <span>
        {{ program.name }}
      </span>
      <button
        class="text-end text-sm mt-2 text-primary self-end"
        @click="onClick"
      >
        Voir le document
      </button>
    </base-card>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import BaseCard from '@/core/components/base/BaseCard.vue';

export default defineComponent({
    name: 'ProgramConsumerSettingFileCard',
    components: {BaseCard},
    props: {
        setting: {
            type: Object,
            required: true
        }
    },
    emits: ['click'],
    computed: {
        program() {
            return this.setting.support_program.program;
        }
    },
    methods: {
    /**
     * Emit the click event
     */
        onClick() {
            this.$emit('click', this.setting);
        }
    }
});
</script>
