<template>
  <div class="flex flex-1">
    <iframe
      height="100%"
      width="100%"
      loading="lazy"
      :src="link"
    />
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";

export default defineComponent({
    name: 'DrugInteraction',
    props: {
        link: {
            type: String,
            required: true
        }
    }
});
</script>
