<template>
  <div>
    <base-flex-spinner v-if="loading" />
    <div v-else>
      <div v-if="customers.length">
        <div class="relative flex flex-row gradient pt-4 pb-8">
          <div class="flex flex-col w-[90%] pl-4">
            <h2 class="text-white pl-1">
              Une application gratuite pour vos patients
            </h2>
            <p class="text-neutral pl-1">
              Sur l'application Apodis, votre patient retrouvera toute votre pharmacie dans sa poche ! Ses
              ordonnances,
              son suivi vaccinal, votre vitrine click&collect, et bien plus encore !
            </p>
          </div>
          <div
            class="absolute flex flex-row right-28 bottom-1 items-center cursor-pointer hover:opacity-80"
            @click="referenceId = 'A3MCtVC67Lw';isModalVisible = true; "
          >
            <icon name="mdi-play" color="secondary" :size="36" />
            <span class="font-bold text-secondary">Découvrir l'app</span>
          </div>
          <img
            class="absolute right-2 top-0 h-40"
            src="@/assets/images/iphone-mockup.png"
          >
        </div>
        <BaseModal
          v-show="isModalVisible"
          ref="modal"
          title=" "
          name="toto"
          @close="isModalVisible = false"
        >
          <BaseVideo :reference="referenceId" />
        </BaseModal>
        <div class="px-4 py-2 flex flex-col">
          <h1>
            {{ meta.total }} {{ $filters.pluralize('patient', meta.total) }}
            {{ $filters.pluralize('certifié', meta.total) }} 🥳
          </h1>
          <p class="text-sm">
            <span
              class="text-primary underline hover:cursor-pointer"
              @click="referenceId='uD95jZfG-Ik';isModalVisible=true;"
            >Visionnez notre tutoriel</span> pour connecter
            facilement vos patients par SMS, ou par QR code.
          </p>
        </div>
        <div class="px-4 pb-4">
          <v-expansion-panels
            v-if="uncertifiedCustomers.length"
          >
            <v-expansion-panel
              class="rounded-lg border-orange-default border-2"
            >
              <v-expansion-panel-title>
                Vous avez {{ uncertifiedCustomers.length }} {{
                  $filters.pluralize('patient', uncertifiedCustomers.length)
                }}
                en attente de certification
              </v-expansion-panel-title>
              <v-expansion-panel-text>
                <p class="text-primary">
                  Ces patients se sont inscrits et ont choisi votre pharmacie. En certifiant leur compte, ils pourront
                  accéder à leurs données (ordonnances, etc.) et bénéficier des services Apodis.
                </p>
                <div
                  v-for="(uncertifiedCustomer, index) in uncertifiedCustomers"
                  :key="index"
                  class="flex flex-row p-2 align-center justify-space-between"
                >
                  <div class="flex flex-1 flex-col">
                    <span class="font-bold">
                      {{ userName(uncertifiedCustomer) }}
                    </span>
                    <span>{{ $filters.phoneFormat(uncertifiedCustomer.phone) }}</span>
                  </div>
                  <div class="flex flex-grow">
                    <span>s'est inscrit le {{ $filters.date(uncertifiedCustomer.created_at, 'short') }}</span>
                  </div>
                  <div class="flex flex-2 justify-end ">
                    <base-button
                      primary
                      @click="onCertifyPress(uncertifiedCustomer)"
                    >
                      Certifier
                    </base-button>
                  </div>
                </div>
              </v-expansion-panel-text>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
        <customer-search
          class="mx-4"
          :input="searchInput"
        />
        <div v-if="loadingMore">
          <base-spinner size="small" />
        </div>
        <div v-else>
          <div class="flex flex-row px-10 pt-4">
            <span class="flex flex-1">Nom</span>
            <span class="flex flex-1">Date de certification</span>
            <span class="flex flex-1">Dernière visite</span>
          </div>
          <base-card
            v-for="customer in customers"
            :key="customer.id"
            class="m-4 hover:opacity-50 cursor-pointer"
          >
            <base-card-body>
              <div
                class="flex flex-row"
                @click="goToPatient(customer)"
              >
                <span class="flex flex-1">{{ customer.information.name }}</span>
                <span class="flex flex-1">{{ $filters.date(customer.validated_at, 'short') }}</span>
                <span
                  v-if="customer.last_visit?.date"
                  class="flex flex-1"
                >
                  {{ $filters.date(customer.last_visit.date, 'short') }}
                </span>
                <span
                  v-else
                  class="flex flex-1"
                >-</span>
              </div>
            </base-card-body>
          </base-card>
        </div>
        <customer-remote-certification
          v-if="modalVisible&&customerToCertify"
          :customer="customerToCertify"
          @close="modalVisible=false;customerToCertify=null"
        />
        <div
          v-if="meta.last_page !== 1"
          class="text-center mb-4"
        >
          <v-pagination
            v-model="page"
            :length="meta.last_page"
            :total-visible="7"
            circle
          />
        </div>
      </div>
      <div v-else>
        <span>Aucun patients certifiés</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import {customerApi, healthcenterUserApi} from '@/container';
import BaseCard from '@/core/components/base/BaseCard.vue';
import BaseCardBody from '@/core/components/base/BaseCardBody.vue';
import CustomerSearch from '@/customer/components/customer-search/CustomerSearch.vue';
import BaseButton from '@/core/components/base/BaseButton.vue';
import CustomerRemoteCertification
    from '@/customer/components/customer-remote-certification/CustomerRemoteCertification.vue';
import certifiedIcon from '@/assets/icons/certified.svg';
import Customer from "@/customer/interfaces/Customer";
import BaseFlexSpinner from "@/core/components/base/spinner/BaseFlexSpinner.vue";
import BaseModal from "@/core/components/base/BaseModal.vue";
import BaseVideo from "@/core/components/base/BaseVideo.vue";
import Icon from "@/core/components/icon/Icon.vue";

export default defineComponent({
    name: 'CustomersListScreen',
    components: {
        Icon,
        BaseVideo,
        BaseModal,
        CustomerRemoteCertification,
        BaseButton,
        CustomerSearch,
        BaseCardBody,
        BaseCard,
        BaseFlexSpinner
    },
    data() {
        return {
            customers: [] as Customer[],
            uncertifiedCustomers: [] as any[],
            searchInput: '',
            customerToCertify: null,
            loading: false,
            loadingMore: false,
            meta: null as any,
            page: 1,
            panel: [0, 1],
            modalVisible: false,
            certifiedIcon,
            isModalVisible: false,
            referenceId: 'A3MCtVC67Lw'
        };
    },
    watch: {
        page: {
            handler() {
                this.loadingMore = true;
                this.getCustomers()
                    .finally(() => {
                        this.loadingMore = false;
                    });
            }
        }
    },
    mounted() {
        this.loading = true;
        this.getCustomers()
            .then(() => this.getPendingCertificationUsers())
            .finally(() => {
                this.loading = false;
            });
    },
    methods: {
        /**
         * Get certified customers.
         *
         * @return {Promise}
         */
        getCustomers() {
            return customerApi().get({certified: true, page: this.page})
                .then(response => {
                    this.customers = response.data;
                    this.meta = response.meta;
                });
        },
        /**
         * Get user which have a pending certification.
         *
         * @return {Promise}
         */
        getPendingCertificationUsers() {
            return healthcenterUserApi().index({certification_status: 'pending'})
                .then(response => {
                    this.uncertifiedCustomers = response;
                });
        },
        /**
         * Get user full name.
         *
         * @param user
         *
         * @return {string}
         */
        userName(user) {
            return `${user.first_name} ${user.last_name}`;
        },
        /**
         * Go to patient details page.
         *
         * @param customer
         */
        goToPatient(customer) {
            this.$router.push({
                name: 'customer.home',
                params: {
                    customerId: customer.id
                }
            });
        },
        /**
         * On certify, show modal with customer name passed as an argument. Also clear; some eventual; previously searched
         * customers to avoid display issues.
         *
         * @param uncertifiedCustomer
         */
        onCertifyPress(uncertifiedCustomer) {
            this.searchInput = '';
            this.customerToCertify = uncertifiedCustomer;
            this.modalVisible = true;
        }
    }
});
</script>
