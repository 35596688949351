<template>
  <base-modal
    v-if="visible"
    title="Avant de commencer, vérifiez votre micro"
    @close="$emit('close')"
  >
    <div class="flex flex-col gap-3">
      <div>
        <h3 class="text-primary">
          Sélectionnez le micro à utiliser
        </h3>
        <v-select
          v-model="selectedMicrophoneId"
          dense
          :items="audioInputDevices"
          item-title="label"
          item-value="deviceId"
          @update:model-value="saveAudioInput"
        />
      </div>
      <div class="flex flex-col gap-2">
        <div>
          <h3 class="text text-primary">
            Testez votre micro
          </h3>
          <p class="text">
            Racontez-nous une blague pour voir !
          </p>
        </div>
        <div class="flex items-center gap-2">
          <button
            v-if="!testing"
            :disabled="!selectedMicrophoneId"
            class="bg-primary rounded-md text-white text-sm font-content-bold p-2"
            @click="testing ? stopTesting() : testMicrophone()"
          >
            Vérifions ça
          </button>
          <audio-vizualizer
            :stream="stream"
            class="flex-1"
          />
        </div>
        <div>
          <p class="text-sm text-primary">
            Si rien ne se passe, vérifiez que votre micro est bien configuré
          </p>
        </div>
        <div class="flex justify-between items-center">
          <p class="text-gray-500 text-sm">
            Vous pouvez aussi
            <button class="underline text-gray-dark" @click="$emit('importAudio')">
              importer un enregistrement
            </button>
            ou
            <button class="underline text-gray-dark" @click="$emit('skipRecording')">
              remplir le questionnaire
            </button>
          </p>
          <base-button :primary="true" class="font-bold text-lg relative" @click="finishConfiguration">
            <div class="flex items-center gap-3">
              <icon name="mdi-microphone" :size="20" :color="'#ffffff'" />
              <p class="flex flex-1 truncate font-bold">
                {{ 'Lancer l\'entretien' }}
              </p>
            </div>
          </base-button>
        </div>
      </div>
    </div>
  </base-modal>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import BaseModal from '@/core/components/base/BaseModal.vue';
import AudioVizualizer from '@/interview/components/microphone/AudioVizualizer.vue';
import InterviewAudioService from '@/interview/services/InterviewAudioService';
import {mapActions} from "pinia";
import {useToastStore} from "@/stores/toast.store";
import BaseButton from "@/core/components/base/BaseButton.vue";
import Icon from "@/core/components/icon/Icon.vue";

export default defineComponent({
    name: 'MicrophoneConfiguration',
    components: {Icon, BaseButton, AudioVizualizer, BaseModal},
    props: {
        visible: {
            type: Boolean,
            required: true
        }
    },
    emits: {
        close: () => true,
        finish: (selectedMicrophone: string) => !!selectedMicrophone && typeof selectedMicrophone === 'string',
        importAudio: () => true,
        skipRecording: () => true
    },
    data() {
        return {
            audioInputDevices: [] as any[],
            selectedMicrophoneId: null as any,
            testing: false,
            stream: null as any
        };
    },
    watch: {
        visible(newValue) {
            if (newValue) {
                this.selectedMicrophoneId = null;
                this.getAudioInputDevices();
            } else {
                this.stopTesting();
            }
        },
        selectedMicrophoneId(newId) {
            if (newId) {
                this.testMicrophone();
            }
        }
    },
    methods: {
        ...mapActions(useToastStore, ['showWarning']),
        /**
         * Get the audio input devices.
         */
        async getAudioInputDevices() {
            try {
                const devices = await navigator.mediaDevices.enumerateDevices();

                this.audioInputDevices = devices.filter(device => device.kind === 'audioinput');

                const savedAudioInput = InterviewAudioService.getSelectedAudioInput();
                if (savedAudioInput) {
                    this.selectedMicrophoneId = savedAudioInput;
                } else if (this.audioInputDevices.length) {
                    this.selectedMicrophoneId = this.audioInputDevices[0].deviceId;
                }
            } catch (error) {
                console.error(error);
            }
        },
        /**
         * Run the microphone test.
         *
         * @return {Promise<void>}
         */
        async testMicrophone() {
            this.stopTesting();

            try {
                this.stream = await navigator.mediaDevices.getUserMedia({
                    audio: {
                        deviceId: this.selectedMicrophoneId ? {exact: this.selectedMicrophoneId} : undefined
                    },
                    video: false
                });
                this.testing = true;
            } catch (error) {
                this.showWarning({content: 'Impossible de démarrer le test du micro'});
            }
        },

        /**
         * Finish the microphone configuration by emiting the selected microphone ID.
         */
        finishConfiguration() {
            if (this.selectedMicrophoneId) {
                this.$emit('finish', this.selectedMicrophoneId);
            }
        },

        /**
         * Stop the microphone test.
         */
        stopTesting() {
            if (this.stream) {
                this.stream.getTracks().forEach(track => track.stop());
                this.stream = null;
                this.testing = false;
            }
        },
        /**
         * Save the audio input configuration.
         */
        async saveAudioInput() {
            this.stopTesting();
            InterviewAudioService.setSelectedAudioInput(
                this.selectedMicrophoneId
            );
        }
    }
});
</script>
