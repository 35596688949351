<script setup lang="ts">
import Icon from "@/core/components/icon/Icon.vue";

defineProps<{
    label: string;
    selected: boolean;
    selectedBadge?: boolean;
    closable?: boolean
}>();

defineEmits(['onSelected']);
</script>

<template>
  <div
    class="flex flex-row p-2 m-2 rounded hover:cursor-pointer hover:opacity-75"
    :class="[{'bg-primary': selected}]"
    @click="$emit('onSelected')"
  >
    <p>{{ label }}</p>
    <Icon v-if="closable" name="mdi-close" color="white" class="pl-4 pr-2" />
  </div>
</template>
