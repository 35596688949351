<template>
  <div
    class="flex flex-col gap-2 cursor-pointer"
    @click="navigateToProgram"
  >
    <BaseCard class="p-3 w-min-64 flex flex-1 gap-x-4">
      <ProgramLogo
        :program="program"
        :size="75"
        :shadow="false"
      />
      <ProgramTeaser
        :program="program"
        class="flex-1 text-sm"
      />
    </BaseCard>
    <div class="ml-12 flex flex-row pt-2 w-64 whitespace-nowrap overflow-hidden">
      <div
        v-for="(patient, index) in program.patients"
        :key="index"
        class="flex text-sm text-gray-dark pr-1 mr-1 items-center border-gray-dark"
        :class="{ 'font-content-bold': !patient.beneficiary, 'border-r-2': index < program.patients.length - 1}"
      >
        <UserIcon
          v-if="patient.beneficiary"
          class="rounded-full border mr-2 bg-transparent border-gray-dark"
          :size="4"
        />
        {{ patient.name }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import BaseCard from '@/core/components/base/BaseCard.vue';
import programHelper from '@/core/helpers/programHelper';
import ProgramTeaser from '@/program/components/program-teaser/ProgramTeaser.vue';
import ProgramLogo from '@/program/components/program-logo/ProgramLogo.vue';
import UserIcon from '@/core/components/icons/User.vue';
import {defineComponent} from "vue";

export default defineComponent({
    components: {
        ProgramLogo,
        BaseCard,
        ProgramTeaser,
        UserIcon
    },
    props: {
        program: {
            type: Object,
            required: true
        },
        consumer: {
            type: Object,
            required: true
        },
        customer: {
            type: Object,
            required: true
        }
    },
    methods: {
        navigateToProgram() {
            const route = programHelper.generateRoute(this.program, this.consumer, this.customer);

            if (route) {
                this.$router.push(route);
            }
        }
    }
});
</script>
