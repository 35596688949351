import {init, setUser} from "@sentry/electron/renderer";
import {
    init as vueInit,
    browserTracingIntegration,
    replayIntegration,
    dedupeIntegration,
    attachErrorHandler
} from "@sentry/vue";
import {App} from "vue";
import {Router} from "vue-router";

const SentryService = () => {
    return {
        initialize(app: App, router: Router) {
            init({
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                /** @ts-expect-error */
                app,
                integrations: (integrations) => {
                    const _integrations = integrations.filter((integration => integration.name !== dedupeIntegration().name));
                    _integrations.push(browserTracingIntegration({router}));
                    _integrations.push(replayIntegration);

                    return _integrations;
                },
                tracesSampleRate: 1.0,
                replaysSessionSampleRate: 1,
                replaysOnErrorSampleRate: 1.0,
                trackComponents: true,
                tracePropagationTargets: [/^\/api\//],
                enabled: import.meta.env.PROD,
            }, vueInit);

            attachErrorHandler(app, {
                logErrors: true,
                attachProps: true,
                trackComponents: true,
                timeout: 2000,
                hooks: ['activate', 'mount', 'update']
            });
        },
        /**
         * Set the user in the sentry context.
         */
        setUser(email: string) {
            setUser({email});
        }
    };
};

export default SentryService();
