<script setup lang="ts">
import {Backing, BackingInterviewStep, BackingStep} from "@/backing/interfaces/backing";
import {computed} from "vue";
import BackingInterview from "@/backing/components/BackingInterview.vue";
import {useRoute, useRouter} from "vue-router";
import BaseFlexSpinner from "@/core/components/base/spinner/BaseFlexSpinner.vue";

const router = useRouter();
const route = useRoute();
const props = defineProps<{
    backing: Backing,
    interviewId: number,
    loading: boolean,
}>();

/**
 * Get the interview steps that are not the current interview.
 */
const interviewSteps = computed(() => props.backing.steps?.filter((step: BackingStep): step is BackingInterviewStep => {
    return step.resource_type === 'interview' && step.resource_id !== props.interviewId;
}) ?? []);

/**
 * Start a new interview instance.
 */
const startNewInstance = (step: BackingInterviewStep) => {
    router.replace({
        name: 'interview-instances',
        query: {
            ...route.query,
            interviewId: step.resource_id,
            interviewInstanceId: undefined,
            interviewName: step.name,
        }
    });
};
</script>

<template>
  <div class="space-y-2">
    <h3>Continuez avec un autre entretien</h3>
    <div v-if="loading">
      <BaseFlexSpinner />
    </div>
    <div v-else>
      <div v-if="interviewSteps.length" class="flex flex-row flex-wrap gap-2">
        <div
          v-for="step in interviewSteps"
          :key="step.resource_id"
          @click="startNewInstance(step)"
        >
          <div>
            <BackingInterview :step="step" />
          </div>
        </div>
      </div>
      <div v-else>
        <p>Aucun autre entretien n'est disponible</p>
      </div>
    </div>
  </div>
</template>
