import {AxiosInstance} from "axios";

/**
 * The environment resource.
 * @author Tony Laurent <t.laurent@apodispharma.com>
 * @param {Object} client - The injected client.
 * @returns {Object} The public object.
 */
export default function (client: AxiosInstance) {
    return {
        /**
         * Read the environment.
         * @returns {Promise} Promise object represents the HTTP request.
         */
        read(withIncludes: boolean = true) {
            const params = withIncludes ? {include: 'user,entity.grouping'} : {};


            return client
                .get('/profile/accesses/current', {
                    params
                })
                .then(response => response.data.access)
                .catch(() => null);
        }
    };
}
