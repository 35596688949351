<template>
  <base-modal
    v-if="visible"
    title="Patients manuellement exclus du programme"
    @close="$emit('close')"
  >
    <base-container class="mt-4 space-y-6">
      <program-excluded-consumer-row
        v-for="consumer in consumers"
        :key="consumer.key"
        :support-program-id="supportProgramId"
        :consumer="consumer"
      />
    </base-container>
  </base-modal>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import BaseModal from '@/core/components/base/BaseModal.vue';
import BaseContainer from '@/core/components/base/BaseContainer.vue';
import ProgramExcludedConsumerRow from '@/program/components/program-consumers/ProgramExcludedConsumerRow.vue';
import Consumer from "@/core/interfaces/consumers/Consumer";

export default defineComponent({
    name: 'ProgramExcludedConsumersModal',
    components: {ProgramExcludedConsumerRow, BaseContainer, BaseModal},
    props: {
        consumers: {
            type: Array<Consumer>,
            required: true
        },
        visible: {
            type: Boolean,
            required: true
        },
        supportProgramId: {
            type: Number,
            required: true
        }
    },
    emits: ['close']
});
</script>
