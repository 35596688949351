import {AxiosInstance} from "axios";

/**
 * The guidance resource.
 *
 * @param {Object} apiClient - The injected client.
 * @param {Object} hdsClient - The injected hds client.
 * @returns {Object} The public object.
 */
export default function (apiClient: AxiosInstance, hdsClient: AxiosInstance) {
    return {
    /**
     * Read guidance.
     *
     * @returns {Promise} Promise object represents the HTTP request.
     */
        read (guidanceId: number, consumerKey: string) {
            return apiClient.get(`guidances/${guidanceId}`, { params: { consumer_key: consumerKey } })
                .then(response => response.data.data);
        },
        /**
     * Get guidance document.
     *
     * @returns {Promise} Promise object represents the HTTP request.
     */
        document (route: string) {
            if (route.includes('hds=1')) {
                return hdsClient
                    .get(route, { responseType: 'blob' })
                    .then(response => response.data);
            }

            return apiClient
                .get(route, { responseType: 'blob' })
                .then(response => response.data);
        }
    };
}
