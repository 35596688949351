<template>
  <div class="flex flex-col gap-3 mb-4">
    <base-product-search-bar
      :disabled="isLoadingRequest || !canRequest"
      @select="handleProductSelection"
    />
    <request-state />
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import BaseProductSearchBar from "@/product/components/BaseProductSearchBar.vue";
import {LegacyProduct} from "@/core/interfaces/Product";
import DisruptionLogisticsRequestService from "@/disruption-logistics/services/DisruptionLogisticsRequestService";
import RequestState from "@/disruption-logistics/components/request-state/RequestState.vue";
import {mapState} from "pinia";
import {useDisruptionLogisticsRequestStore} from "@/stores/disruptionLogisticsRequest.store";

export default defineComponent({
    name: "ProductSearchBar",
    components: {RequestState, BaseProductSearchBar},
    props: {
        replaceRoute: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isLoadingRequest: false,
            requests: {
                subscribed: null,
                remaining: null
            }
        };
    },
    computed: {
        ...mapState(useDisruptionLogisticsRequestStore, [
            'subscribed',
            'remainingRequests'
        ]),
        /**
     * Determine if the user can request for a product
     */
        canRequest(): boolean {
            return this.subscribed || (this.remainingRequests ?? 0) > 0;
        }
    },
    methods: {
        handleProductSelection(product: LegacyProduct) {
            if (!product) {
                return;
            }

            this.isLoadingRequest = true;
            DisruptionLogisticsRequestService.requestDisruptionLogisticsData(
                product,
                this.$router,
                this.replaceRoute
            ).finally(() => {
                this.isLoadingRequest = false;
            });
        }
    }
});
</script>
