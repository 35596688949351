<template>
  <div
    id="group-map"
    class="bg-white rounded-md shadow"
  >
    <div class="p-3">
      <h2 class="text-xl">
        Disponibilité dans les pharmacies partenaires
      </h2>
    </div>
    <disruption-logistics-map
      ref="mapRef"
      :loading-data="loading"
    >
      <info-window
        v-if="infoContent"
        v-model="infoWindowOpened"
        :options="{
          position: {
            lat: infoContent?.position.lat as number,
            lng: infoContent?.position.lng as number
          }}"
      >
        <healthcenter-group-availability-info-window :content="infoContent" />
      </info-window>
      <healthcenter-group-availability-map-marker
        v-for="healthcenter in uniqueHealthCenters"
        :key="healthcenter.id"
        :healthcenter="healthcenter"
        @click="toggleInfoWindow"
      />
    </disruption-logistics-map>
    <div class="p-4 flex flex-col gap-3">
      <healthcenter-group-map-statistics :healthcenters="uniqueHealthCenters" />
      <div class="flex justify-end">
        <button
          class="flex justify-end items-center gap-1"
          @click="$router.push({ name: 'disruption-logistics.healthcenter-groups' })"
        >
          <icon
            name="mdi-cog"
            color="primary"
          />
          <span class="text-primary">
            Gérer mes partenaires
          </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';
import {InfoWindow} from "vue3-google-map";
import DisruptionLogisticsMap from "@/disruption-logistics/components/maps/DisruptionLogisticsMap.vue";
import HealthcenterGroupAvailabilityMapMarker
    from "@/disruption-logistics/components/maps/healthcenter-groups/HealthcenterGroupAvailabilityMapMarker.vue";
import HealthcenterGroupAvailabilityInfoWindow
    from "@/disruption-logistics/components/maps/healthcenter-groups/HealthcenterGroupAvailabilityInfoWindow.vue";
import HealthcenterGroupMapStatistics
    from "@/disruption-logistics/components/maps/healthcenter-groups/HealthcenterGroupMapStatistics.vue";
import Icon from "@/core/components/icon/Icon.vue";
import {
    HealthcenterGroupMember
} from "@/disruption-logistics/interfaces/availability-providers";
import {HealthcenterGroup} from "@/disruption-logistics/interfaces/healthcenter-groups";

export default defineComponent({
    name: "HealthcenterGroupAvailabilityMap",
    components: {
        Icon,
        HealthcenterGroupMapStatistics,
        HealthcenterGroupAvailabilityInfoWindow,
        HealthcenterGroupAvailabilityMapMarker, DisruptionLogisticsMap, InfoWindow
    },
    props: {
        product: {
            type: Object,
            required: true
        },
        data: {
            type: Array as PropType<HealthcenterGroup[] | null>,
            required: false,
            default: () => null
        }
    },
    data() {
        return {
            infoWindowOpened: false,
            infoPosition: null,
            infoContent: undefined as undefined | {
        healthcenter: HealthcenterGroupMember;
        groups: HealthcenterGroup[];
        position: { lat: number; lng: number }
      }
        };
    },
    computed: {
    /**
     * Whether the map is loading.
     */
        loading(): boolean {
            return !this.data;
        },
        /**
     * Get the unique health centers from the groups.
     *
     */
        uniqueHealthCenters(): HealthcenterGroupMember[] {
            if (!this.data) {
                return [];
            }

            const reducedArray = this.data.flatMap(group => group.healthcenters)
                .reduce((acc, healthcenter) => {
                    return {
                        ...acc,
                        [healthcenter.id]: healthcenter
                    };
                }, {});

            return Object.values(reducedArray);
        }
    },
    watch: {
        data: {
            handler() {
                this.infoWindowOpened = false;
                this.infoContent = undefined;
                this.infoPosition = null;
                const bounds = this.uniqueHealthCenters
                    .map(healthcenter => ({
                        lat: healthcenter.address?.latitude,
                        lng: healthcenter.address?.longitude
                    }))
                    .filter(({lat, lng}) => !!lat && !!lng);

                this.$nextTick(() => {
                    if (bounds.length) {
                        (this.$refs.mapRef as InstanceType<typeof DisruptionLogisticsMap>)
                            .fitMapToBounds(bounds as { lat: number; lng: number }[]);
                    }
                });
            },
            immediate: true,
            deep: true
        }
    },
    methods: {
    /**
     * Toggle the info window.
     */
        toggleInfoWindow(healthcenter: HealthcenterGroupMember): void {
            const groups = this.data?.filter(group => group.healthcenters.some(h => h.id === healthcenter.id)) ?? [];

            this.infoContent = {
                healthcenter,
                groups,
                position: {
                    lat: healthcenter.address.latitude as number,
                    lng: healthcenter.address.longitude as number
                }
            };

            this.infoWindowOpened = true;
        }
    }
});
</script>

<style scoped>

</style>
