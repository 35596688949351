<script setup lang="ts">
import BaseCard from "@/core/components/base/BaseCard.vue";
import Offer, {OfferTeaser} from "@/core/interfaces/Offer";
import {offerTeaserApi, secureFileApi} from "@/container";
import {ref, onMounted, watch} from "vue";
import BaseImage from "@/core/components/base/BaseImage.vue";
import BaseInput from "@/core/components/base/BaseInput.vue";
import Icon from "@/core/components/icon/Icon.vue";
import BaseButton from "@/core/components/base/BaseButton.vue";
import BaseFlexSpinner from "@/core/components/base/spinner/BaseFlexSpinner.vue";
import OfferVisualImport from "@/offer/components/OfferVisualImport.vue";
import DeprecatedFileManagerFile from "@/core/interfaces/FileManagerFile";
import ImageFile from "@/core/interfaces/laboratory/ImageFile";
import genericPromotion from "@/assets/images/generic_promotion.png";
import loaderGif from '@/assets/loader.gif';

const props = defineProps<{ offer: Offer }>();
const emit = defineEmits(['on-publish']);

const loading = ref(true);
const imageUrl = ref();
const name = ref<string>(props.offer.name);
const stored = ref(true);
const visible = ref(false);
let timer: NodeJS.Timeout;

onMounted(() => {
    if (props.offer.teaser?.render) {
        getRender(props.offer.teaser.render);
    } else {
        loading.value = false;
    }
});

watch(name, async () => {
    if (timer) {
        clearTimeout(timer);
    }

    timer = setTimeout(async () => {
        stored.value = false;
        await offerTeaserApi().update(props.offer.id, {name: name.value})
            .finally(() => stored.value = true);
    }, 500);
});

/**
 * Get offer image render.
 * @param url
 */
const getRender = (url: string) => {
    loading.value = true;
    secureFileApi().get(url)
        .then(response => {
            const reader = new FileReader();

            reader.readAsDataURL(response);

            reader.onload = () => {
                imageUrl.value = reader.result;
            };
        })
        .finally(() => {
            loading.value = false;
        });
};

/**
 * On publish action.
 * @param status
 */
const onPublish = (status: boolean) => {
    return emit('on-publish', {is_published: status});
};

/**
 * Handle the filesystem image selection.
 *
 * @param {ImageFile|void} imageFile - The image file if one is selected.
 */
const handleFilesystemImageSelected = (imageFile: ImageFile | void): void => {
    loading.value = true;
    visible.value = false;

    if (imageFile) {
        offerTeaserApi().update(
            props.offer.id,
            {
                text: "",
                header: "",
                file_id: imageFile.id
            }
        )
            .then(response => {
                getRender(response.render);
            })
            .finally(() => {
                loading.value = false;
            });
    }
};

/**
 * Handle the teaser editor image selection.
 *
 * @param {ImageFile} imageFile - The selected image file.
 * @param {OfferTeaser} content - The input content.
 */
const handleEditorImageSelected = (imageFile: DeprecatedFileManagerFile, content: OfferTeaser): void => {
    loading.value = true;
    visible.value = false;

    offerTeaserApi().update(
        props.offer.id,
        {
            text: content.text,
            header: content.header,
            file_id: imageFile.id
        }
    )
        .then(response => {
            getRender(response.render);
        })
        .finally(() => {
            loading.value = false;
        });
};

</script>

<template>
  <BaseCard class="my-4 p-4">
    <div class="flex flex-col justify-between">
      <div class="flex flex-row justify-between">
        <div class="flex flex-col flex-1">
          <h2>Publiez cette promotion sur l'app patient</h2>
          <p>
            Elle sera mise en avant au sein de votre vitrine sur l'application Apodis de vos patient !
          </p>
          <div class="flex flex-row">
            <div class="flex flex-col flex-1 justify-center p-4 w-[250px] h-[230px]">
              <BaseFlexSpinner v-if="loading" />
              <div v-else>
                <BaseImage v-if="!!imageUrl" rendered :path="imageUrl" />
                <div v-else class="relative">
                  <img :src="genericPromotion" class="w-[250px] h-[179px]">
                  <span class="absolute font-bold top-12 left-8 text-center text-[35px] w-[150px] text-white text-shadow-md">
                    PROMO
                  </span>
                  <span class="absolute font-bold top-24 left-8 text-center w-[150px] text-white text-shadow-md">
                    {{ name }}
                  </span>
                  <div class="absolute left-14 -mt-5">
                    <BaseButton :primary="!imageUrl" @click="visible = true">
                      Changer le visuel
                    </BaseButton>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex flex-col justify-between flex-2">
              <div class="flex justify-center min-w-96 flex-1">
                <base-input
                  class="mt-4 w-full"
                  :value="name"
                  type="text"
                  label="Nom qui apparaitra dans l'application patient"
                  @input="(value) => name = value"
                />
                <div class="ml-4 mt-11 mb-2 w-10">
                  <icon v-if="stored" name="mdi-check" color="secondary" />
                  <img
                    v-else
                    :src="loaderGif"
                    class="mt-1"
                    alt="Loading"
                    width="20"
                  >
                </div>
              </div>

              <div class="flex justify-end mb-5">
                <BaseButton :primary="!offer.is_published" @click="onPublish(!offer.is_published)">
                  {{ offer.is_published ? "Dépublier" : "Publier" }}
                </BaseButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <OfferVisualImport
      :visible="visible"
      :teaser="offer.teaser"
      @on-close="visible=false"
      @on-import="handleFilesystemImageSelected"
      @on-edit="handleEditorImageSelected"
    />
  </BaseCard>
</template>
