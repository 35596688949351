<template>
  <div class="bg-white shadow overflow-hidden rounded-lg mt-5 self-center">
    <div class="border-t border-gray-200 px-4 py-5 sm:p-0">
      <dl>
        <div class="py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 bg-gray-50">
          <dt class="text-sm font-medium text-gray-500">
            Date d’activation
          </dt>

          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            {{ membership.formatted.created_at }}
          </dd>
        </div>

        <div
          v-if="membership.formatted.expired_at"
          class="py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
        >
          <dt class="text-sm font-medium text-gray-500">
            Date d’expiration
          </dt>

          <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            {{ membership.formatted.expired_at }}
          </dd>
        </div>
      </dl>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
export default defineComponent({
    props: {
        membership: {
            type: Object,
            required: true
        }
    }
});
</script>
