<template>
  <div class="flex gap-6 items-center">
    <img
      :src="mapService.getMarkerImage(isAvailable, members)"
      alt="marker"
      class="w-15 h-15"
    >
    <div class="flex flex-col gap-3 shadow-md bg-white rounded-md max-w-sm">
      <distributor-availability-info-window
        :content="{
          warehouse: warehouse,
          available: isAvailable,
          message
        }"
        :members="members"
      />
    </div>
  </div>
</template>

<script lang="ts">
import DistributorAvailabilityInfoWindow
    from '@/disruption-logistics/components/maps/distributors/DistributorAvailabilityInfoWindow.vue';
import DistributorAvailabilityMapService
    from '@/disruption-logistics/services/DistributorAvailabilityMapService';
import {PropType, defineComponent} from "vue";
import {Warehouse} from "@/core/interfaces/warehouses";
import {HealthcenterGroupMember} from "@/disruption-logistics/interfaces/availability-providers";

export default defineComponent({
    name: 'DistributorAvailabilityMapLegendItem',
    components: { DistributorAvailabilityInfoWindow },
    props: {
        warehouse: {
            type: Object as PropType<Warehouse>,
            required: true
        },
        isAvailable: {
            type: Boolean as PropType<boolean | null>,
            required: false,
            default: null
        },
        message: {
            type: String,
            required: false,
            default: null
        },
        members: {
            type: Array as PropType<HealthcenterGroupMember[]>,
            required: false,
            default: () => []
        }
    },
    computed: {
    /**
     * Get the map service.
     */
        mapService (): typeof DistributorAvailabilityMapService {
            return DistributorAvailabilityMapService;
        }
    }
});
</script>

<style scoped>

</style>
