<template>
  <div>
    <div
      ref="expandableContainer"
      :class="{
        'max-h-28': !isExpanded,
      }"
      class="overflow-hidden flex flex-col w-[90%] relative expandable-text-overflow"
    >
      <slot />
    </div>

    <div
      v-if="needExpand"
      class="flex bg-transparent flex-row justify-end relative"
    >
      <div
        v-if="!isExpanded"
        class="w-full absolute h-28 -top-24 left-0 backdrop-filter"
        :style="bgColor"
      />
      <a
        class="absolute -top-5 text-primary pt-1 px-2 rounded-lg font-bold cursor-pointer hover:underline z-10"
        :style="'backgroundColor: rgb('+red+','+green+', '+blue+');'"
        @click="isExpanded = !isExpanded"
      >Voir {{ isExpanded ? 'moins' : 'plus' }}</a>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from "vue";

interface RGB {
    red: number;
    green: number;
    blue: number;
}

export default defineComponent({
    name: 'ExpandableTextOverflow',
    props: {
        backgroundColor: {
            type: Object as PropType<RGB>,
            default: () => ({red: 238, green: 236, blue: 247})
        }
    },
    data() {
        return {
            isExpanded: false,
            isMounted: false,
            red: this.backgroundColor.red,
            green: this.backgroundColor.green,
            blue: this.backgroundColor.blue
        };
    },
    computed: {
        bgColor() {
            return 'background: linear-gradient(to top, rgb(' + this.red + ',' + this.green + ',' + this.blue +
                '), rgba(' + this.red + ',' + this.green + ',' + this.blue + ', 0));';
        },
        needExpand() {
            if (!this.isMounted) {
                return false;
            }

            const element: any = this.$refs.expandableContainer;

            return element.scrollHeight > element.clientHeight || element.scrollHeight < element.clientHeight;
        }
    },
    mounted() {
        this.isMounted = true;
    }
});
</script>

<style scoped>
.overflow-mask {
    width: 100%;
    height: 20px;
    top: -1.25rem;
    position: absolute;
    left: 0;
    background: linear-gradient(transparent, transparent 1px, white);
}
</style>
