<script setup lang="ts">

</script>

<template>
  <div class="bg-white h-full flex items-center justify-center">
    <img
      src="@/assets/images/vaccination-timeline.png"
      alt="vaccination timeline"
    >
  </div>
</template>

<style scoped>

</style>
