<template>
  <div
    ref="notification"
    class="flex flex-1 justify-end overflow-x-hidden"
  >
    <component
      :is="component"
      v-if="component"
      :item="item"
      @resize="resizeNotificationAfterTick"
    />
  </div>
</template>

<script lang="ts">
import {Component, defineComponent, markRaw} from "vue";
import {
    detectionApi,
    environmentApi, healthcenterApi,
    notificationService
} from '@/container';

import NotificationDetection
    from '@/notification/components/notification-detection/NotificationDetection.vue';
import NotificationBroadcast, {
    BROADCAST_NOTIFICATION_HEIGHT
} from '@/notification/components/notification-broadcast/NotificationBroadcast.vue';
import {mapState, mapWritableState} from "pinia";
import {useExperienceStore} from "@/stores/experience.store";
import {useSystemStore} from "@/stores/system.store";
import {
    BroadcastNotificationProps,
    Detection,
    DetectionNotificationProps,
    DetectionPatient
} from "@/notification/interfaces";
import {UserSettings} from "@/settings/interfaces";
import Consumer from "@/core/interfaces/consumers/Consumer";
import {Program} from "@/program/interfaces/Program";

export default defineComponent({
    data() {
        return {
            item: null as null | DetectionNotificationProps | BroadcastNotificationProps,
            component: null as null | Component,
            hostname: null as string | null
        };
    },
    computed: {
        ...mapState(useExperienceStore, ['events']),
        ...mapWritableState(useSystemStore, ['groupingLogo', 'environment'])
    },
    mounted() {
        window.ipcRenderer.invoke('getHostname').then(hostname => {
            this.hostname = hostname;
        });

        window.ipcRenderer.on('extract-patient', (event, patient: DetectionPatient, settings: UserSettings) => {
            window.ipcRenderer.send('detection-start');
            detectionApi()
                .create(this.hostname, patient, true)
                .then((detection: { customer: {id: number} }) => {
                    const item: DetectionNotificationProps = {
                        patient,
                        customer: null,
                        loyalty_cards: [],
                        settings,
                        certified: false
                    };

                    if (detection && detection.customer) {
                        window.ipcRenderer.send('customer-detected', detection.customer);

                        return detectionApi()
                            .read(detection.customer.id)
                            .then((data: Detection) => {
                                const consumers = data.consumers;
                                const customer = consumers.find((consumer) => consumer.type === 'customer') as Consumer;
                                const beneficiaries = consumers.filter((consumer) => consumer.type === 'beneficiary');

                                item.customer = {...customer, beneficiaries};
                                item.loyalty_cards = data.loyalty_cards;
                                item.certified = data.certified;
                            })
                            .finally(() => {
                                this.item = item;
                                this.component = markRaw(NotificationDetection);
                            });
                    }

                    this.item = item;
                    this.component = markRaw(NotificationDetection);

                    return Promise.resolve(true);
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    window.ipcRenderer.send('detection-completed');
                    this.showNotificationAfterTick();
                });
        });

        window.ipcRenderer.on('reset-patient', (event, patient) => {
            detectionApi().create(this.hostname, patient, false);
        });

        window.ipcRenderer.on('preview-detection', this.preview);
        this.readEnvironment()
            .then(() => this.subscribe());
    },

    methods: {
        /**
         * Subscribe for incoming broadcast notification.
         */
        subscribe(): void {
            if (this.environment?.userId) {
                const channel = 'Illuminate\\Notifications\\Events\\BroadcastNotificationCreated';
                window.ipcRenderer.send('socket:subscribe', `private-user.${this.environment.userId}`);
                window.ipcRenderer.send('socket:listen', channel);
                window.ipcRenderer.on('socket:event-received.' + channel, (event, data: BroadcastNotificationProps) => {
                    this.component = markRaw(NotificationBroadcast);
                    this.item = data;
                    this.showNotificationAfterTick(BROADCAST_NOTIFICATION_HEIGHT);
                });
            }
        },
        /**
         * Read environment information.
         */
        readEnvironment(): Promise<void> {
            return environmentApi()
                .read()
                .then(environment => {
                    this.environment = environment;
                    if (environment?.entity?.grouping?.is_partner) {
                        return healthcenterApi().healthcenterGrouping(environment.entity.id);
                    }
                    return Promise.resolve(null);
                })
                .then(grouping => {
                    if (grouping) {
                        this.groupingLogo = grouping.branding?.logo.renderPath;
                    }
                });
        },
        /**
         * Create a notification preview with fake data.
         *
         * @param event
         * @param settings
         */
        preview(event, settings: UserSettings) {
            const patient = {
                nir: '170000000000000',
                lastName: 'au comptoir',
                firstName: 'Patient',
                birthDate: '01011980'
            };

            const exampleProgram = {
                id: 1,
                name: 'Programme exemple',
                teaser: 'Recommandations du programme'
            } as Program;

            this.component = markRaw(NotificationDetection);

            this.item = {
                patient,
                customer: {
                    id: 0,
                    type: 'customer',
                    nir: '17000000000000000',
                    information: {
                        first_name: 'Patient',
                        last_name: 'au comptoir',
                        birth_date: '01011980',
                        gender: 'male',
                        name: 'Patient au comptoir'
                    },
                    statistics: null,
                    healthcenter: null,
                    key: 'c_0',
                    programs: [
                        exampleProgram,
                        {
                            ...exampleProgram,
                            id: 2
                        },
                        {
                            ...exampleProgram,
                            id: 3
                        }
                    ],
                    beneficiaries: []
                },
                settings,
                loyalty_cards: [],
                certified: false
            };

            this.showNotificationAfterTick();
        },

        /**
         * Show the notification after the next tick.
         */
        async showNotificationAfterTick(height?: number) {
            await this.$nextTick(); // needed for window size calculation (this.$refs.notification.clientHeight)
            const notificationHeight = height || this.$el.scrollHeight;

            notificationService()
                .showNotification(notificationHeight);
        },

        /**
         * Resize the notification window after the next tick.
         */
        async resizeNotificationAfterTick() {
            await this.$nextTick();
            const height = this.$el.scrollHeight;

            window.ipcRenderer.resizeNotificationWindow(height);
        }
    },
});
</script>
