<script setup lang="ts">
import BaseButton from "@/core/components/base/BaseButton.vue";
import {MassCertificationNotificationTemplate} from "@/core/interfaces/MassCertification";
import CommunicationMessageTemplate from "@/communication/components/CommunicationMessageTemplate.vue";

defineProps<{ template: MassCertificationNotificationTemplate }>();
</script>

<template>
  <div class="flex flex-col py-2">
    <p class="font-content-bold">
      {{ template.name }}
    </p>
    <div class="flex flex-row mx-4 my-2 cursor-pointer hover:opacity-80">
      <CommunicationMessageTemplate :template="template" class="flex-2" />
      <div class="flex flex-1 items-center justify-center">
        <BaseButton primary>
          Sélectionner
        </BaseButton>
      </div>
    </div>
  </div>
</template>
