import {AxiosInstance, AxiosResponse} from "axios";
import {OfferTeaser} from "@/core/interfaces/Offer";
import DeprecatedFileManagerFile from "@/core/interfaces/FileManagerFile";

/**
 * The order resource.
 *
 * @param {Object} client - The injected client.
 *
 * @returns {Object} The public object.
 */
export default function (client: AxiosInstance) {
    return {
        /**
         * Get offer teaser images.
         *
         * @returns {Promise} Promise object represents the HTTP request.
         */
        images(): Promise<DeprecatedFileManagerFile[]> {
            return client.get("/imported-offers/teasers/images")
                .then((response: AxiosResponse) => response.data.data);
        },

        /**
         * Read an offer teaser.
         *
         * @param {number} id - The offer teaser id.
         *
         * @return {Promise<Program>}
         */
        read(id: number): Promise<OfferTeaser> {
            return client.get(`/imported-offers/${id}/teaser`)
                .then((response: AxiosResponse) => response.data.data);
        },

        /**
         * Update an offer teaser.
         *
         * @param {number} id - The offer teaser id.
         * @param {OfferTeaser} data - The data to update.
         *
         * @return {Promise<Offer>}
         */
        update(id: number, data: Partial<OfferTeaser> | { file_id?: number }): Promise<OfferTeaser> {
            return client.patch(`/imported-offers/${id}/teaser`, data)
                .then((response: AxiosResponse) => response.data.data);
        }
    };
};
