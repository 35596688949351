<template>
  <div class="flex flex-row bg-gray-200 items-center justify-between px-5 py-2 w-full">
    <div class="flex flex-row items-center">
      <img
        :src="clockwiseImage"
        alt="delay-illustration"
        class="w-16 object-contain"
      >
      <p class="ml-3">
        Vous pouvez ignorer le délai en cliquant sur le bouton suivant
      </p>
    </div>
    <base-button
      class="text-sm font-semibold"
      @click="$emit('onSkipDelay')"
    >
      Passer le délai
    </base-button>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import BaseButton from '@/core/components/base/BaseButton.vue';
import clockwiseImage from '@/assets/images/clockwise.png';

export default defineComponent({
    name: 'PathwayDelayLabel',
    components: {BaseButton},
    emits: ['onSkipDelay'],
    data() {
        return {
            clockwiseImage
        };
    }
});
</script>
