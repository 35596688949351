<template>
  <BaseContainer class="p-6">
    <BaseSection>
      <div class="text-sm">
        Vous pouvez configurer les notifications afin de les adapter à votre poste.<br>
        L’activation/désactivation des notifications se gère depuis l’application
        Apodis PRO > Mon Compte > Gestion des notifications.
      </div>
    </BaseSection>

    <BaseSection>
      <BaseCard>
        <BaseCardBody>
          <h3 class="mb-1 font-bold text-sm">
            Emplacement de la notification
          </h3>

          <p class="mb-4 text-gray-500 text-sm">
            La notification apparaît en bas à droite de votre écran,
            vous pouvez la régler en hauteur.
          </p>

          <div class="flex flex-1 flex-row space-x-2">
            <NotificationLocationRadioButton
              v-model="preference.notification.position"
              :value="'bottom-edge'"
              label="Tout en bas"
            />
            <NotificationLocationRadioButton
              v-model="preference.notification.position"
              :value="'bottom-right'"
              label="En bas à droite"
            />
            <NotificationLocationRadioButton
              v-model="preference.notification.position"
              :value="'middle-right'"
              label="Au milieu à droite"
            />
            <NotificationLocationRadioButton
              v-model="preference.notification.position"
              :value="'top-right'"
              label="En haut à droite"
            />
            <NotificationLocationRadioButton
              v-model="preference.notification.position"
              :value="'top-edge'"
              label="Tout en haut"
            />
          </div>
        </BaseCardBody>
      </BaseCard>

      <BaseCard>
        <BaseCardBody>
          <h3 class="mb-2 font-bold text-sm">
            Temps d’affichage de la notification
          </h3>

          <div class="flex flex-col space-y-2 md:flex-row md:space-y-0">
            <div class="space-y-2">
              <BaseRadio
                v-model="preference.notification.time"
                :value="5000"
                label="5 secondes"
              />
              <BaseRadio
                v-model="preference.notification.time"
                :value="15000"
                label="15 secondes"
              />
            </div>
            <div class="space-y-2 md:ml-10">
              <BaseRadio
                v-model="preference.notification.time"
                :value="30000"
                label="30 secondes"
              />
              <BaseRadio
                v-model="preference.notification.time"
                :value="60000"
                label="1 minute"
              />
            </div>
            <div class="space-y-2 md:ml-10">
              <BaseRadio
                v-model="preference.notification.time"
                :value="0"
                label="Permanent"
              />
            </div>
          </div>
        </BaseCardBody>
      </BaseCard>

      <BaseCard>
        <BaseCardBody>
          <h3 class="mb-2 font-bold text-sm">
            Apparence de la notification
          </h3>
          <div class="flex flex-row space-x-10">
            <NotificationLayoutRadioButton
              v-model="preference.notification.layout"
              :value="'simple'"
              label="Simple : affiche les programmes concernant le patient détecté"
              class="w-1/2"
            />
            <NotificationLayoutRadioButton
              v-model="preference.notification.layout"
              :value="'advanced'"
              label="Avancé : affiche les programmes du patient détecté avec l'action à effectuer"
              class="w-1/2"
            />
          </div>
        </BaseCardBody>
      </BaseCard>

      <BaseCard>
        <BaseCardBody>
          <h3 class="mb-2 font-bold text-sm">
            Afficher la fiche patient au survol de la notification
          </h3>
          <div class="space-y-3">
            <BaseRadio
              v-model="preference.notification.supportProgramHoverFeatureEnabled"
              :value="true"
              label="Oui"
            />
            <BaseRadio
              v-model="preference.notification.supportProgramHoverFeatureEnabled"
              :value="false"
              label="Non"
            />
          </div>
        </BaseCardBody>
      </BaseCard>
    </BaseSection>
  </BaseContainer>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import BaseCard from '@/core/components/base/BaseCard.vue';
import BaseRadio from '@/core/components/base/BaseRadio.vue';
import BaseSection from '@/core/components/base/BaseSection.vue';
import BaseCardBody from '@/core/components/base/BaseCardBody.vue';
import BaseContainer from '@/core/components/base/BaseContainer.vue';
import NotificationLocationRadioButton from '@/settings/components/NotificationLocationRadioButton.vue';
import NotificationLayoutRadioButton from '@/settings/components/NotificationLayoutRadioButton.vue';
import {storageService} from '@/container';

export default defineComponent({
    components: {
        NotificationLayoutRadioButton,
        NotificationLocationRadioButton,
        BaseCard,
        BaseRadio,
        BaseSection,
        BaseCardBody,
        BaseContainer
    },

    data() {
        return {
            preference: {
                notification: {
                    time: null,
                    position: null,
                    layout: null,
                    supportProgramHoverFeatureEnabled: null,
                }
            }
        };
    },

    watch: {
        preference: {
            deep: true,

            handler(value) {
                storageService().store('preference', JSON.stringify(value));

                if (window.ipcRenderer) {
                    window.ipcRenderer
                        .invoke('preference-updated', JSON.stringify(value))
                        .then(() => {
                            this.preview();
                        });
                }
            }
        }
    },

    mounted() {
        this.preference = storageService().loadSettings();
    },

    beforeUnmount() {
        window.ipcRenderer.send('hide-notification');
    },

    methods: {
        preview() {
            window.ipcRenderer.send('preview-detection');
        }
    }
});
</script>
