<template>
  <div class="flex flex-row justify-between">
    <div class="flex-1" />
    <div class="flex flex-row gap-x-4 justify-around py-5">
      <img
        class="w-30"
        src="@/assets/images/download-app-store.png"
        alt="App store"
      >
      <img
        class="w-30"
        src="@/assets/images/download-play-store.png"
        alt="Play store"
      >
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";

export default defineComponent({
    name: 'CustomerOnboardingFooter'
});
</script>
