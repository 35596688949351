<template>
  <div class="flex flex-col">
    <BaseButton
      primary
      @click="click"
    >
      <div
        class="text-small text-center w-full flex flex-row justify-center items-center gap-x-2"
      >
        Envoyer un SMS
      </div>
    </BaseButton>
    <customer-certification-notification-modal
      :modal-visible="isModalVisible"
      :customer="customer"
      @close="close"
    />
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from "vue";
import BaseButton from '@/core/components/base/BaseButton.vue';
import CustomerCertificationNotificationModal
    from '@/customer-certification/views/CustomerCertificationNotificationModal.vue';
import Customer from "@/customer/interfaces/Customer";

export default defineComponent({
    name: 'CustomerCertificationNotificationButton',
    components: { CustomerCertificationNotificationModal, BaseButton },
    props: {
        customer: {
            type: Object as PropType<Customer>,
            required: true
        }
    },
    data: () => {
        return {
            isModalVisible: false,
            isLoading: false
        };
    },
    methods: {
    /**
     * Open the modal.
     */
        click () {
            this.isModalVisible = true;
        },
        /**
     * Close the modal.
     */
        close () {
            this.isModalVisible = false;
        }
    }
});
</script>
