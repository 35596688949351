<script lang="ts" setup>
import BaseButton from '@/core/components/base/BaseButton.vue';
import Customer from "@/customer/interfaces/Customer";
import PrescriptionRenewalNotifySMS from "@/prescription/components/PrescriptionRenewalNotifySMS.vue";
import PrescriptionRenewalNotifyPush from "@/prescription/components/PrescriptionRenewalNotifyPush.vue";
import {onMounted} from "vue";

const props = defineProps<{
    customer: Customer;
    dates: Date[];
}>();

defineEmits(['next-step']);

const customerPhone = defineModel<string | null>("customerPhone", {required: true});
const selectedNotificationChannel = defineModel<string>("selectedNotificationChannel", {required: true});

/** When component is mounted */
onMounted(() => {
    selectedNotificationChannel.value = props.customer.is_certified ? 'push' : 'sms';
});
</script>

<template>
  <div class="flex flex-col gap-y-4">
    <div>
      <h1>Rappels automatiques</h1>
      <p>Le patient recevra un rappel de 3 jours avant chaque date de renouvellement</p>
    </div>
    <div v-if="!customer.onboarded">
      <PrescriptionRenewalNotifySMS v-model:customer-phone="customerPhone" :customer="customer" :dates="dates" />
    </div>
    <div v-else>
      <PrescriptionRenewalNotifyPush v-model:customer-phone="customerPhone" :customer="customer" :dates="dates" />
    </div>
    <div class="flex flex-row justify-end py-4">
      <base-button primary @click="$emit('next-step')">
        Continuer
      </base-button>
    </div>
  </div>
</template>
