import {AxiosInstance} from "axios";
import PrescriptionRenewalPlan from "@/prescription/interfaces/prescription-renewal-plan/PrescriptionRenewalPlan";

export interface PrescriptionRenewalPlanResourceInterface {
    create: (plan: Partial<PrescriptionRenewalPlan>) => Promise<PrescriptionRenewalPlan>;
    update: (plan: PrescriptionRenewalPlan, data: Partial<PrescriptionRenewalPlan>) => Promise<PrescriptionRenewalPlan>;
    delete: (plan: Partial<PrescriptionRenewalPlan>) => Promise<{ is_deleted: boolean }>;
}

export default function (hdsClient: AxiosInstance): PrescriptionRenewalPlanResourceInterface {
    return {
        /**
         * Create a prescription renewal plan.
         */
        async create(plan: Partial<PrescriptionRenewalPlan>): Promise<PrescriptionRenewalPlan> {
            return await hdsClient.post('prescriptions/renewals/plans', {data: plan})
                .then(response => response.data.data);
        },
        /**
         * Update a prescription renewal plan.
         */
        async update(plan: PrescriptionRenewalPlan, data: Partial<PrescriptionRenewalPlan>): Promise<PrescriptionRenewalPlan> {
            return await hdsClient.patch(`prescriptions/renewals/plans/${plan.id}`, {data})
                .then(response => response.data.data);
        },
        /**
         * Delete a prescription renewal plan.
         */
        async delete(plan: Partial<PrescriptionRenewalPlan>): Promise<{ is_deleted: boolean }> {
            return await hdsClient.delete(`prescriptions/renewals/plans/${plan.id}`)
                .then(response => response.data.data);
        },
    };
}
