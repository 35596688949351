<template>
  <form
    novalidate
    @submit.prevent="handleSubmit"
  >
    <div class="flex flex-col gap-4">
      <base-input
        :value="formData.name"
        type="text"
        label="Nom du groupe"
        :errors="errors.name ? [errors.name] : []"
        @input="(value) => formData.name = value"
      />
      <base-input
        :value="formData.description"
        type="textarea"
        label="Description (sera affichée aux membres du groupe)"
        :errors="errors.description ? [errors.description] : []"
        @input="(value) => formData.description = value"
      />
      <base-button
        :disabled="loading"
        type="submit"
        class="font-bold flex self-center"
        primary
      >
        <div
          class="flex items-center"
          :class="{
            'gap-2': loading
          }"
        >
          <span>{{ editMode ? 'Modifier' : 'Créer' }}</span>
          <div>
            <base-spinner
              v-if="loading"
              size="button"
              color="white"
            />
          </div>
        </div>
      </base-button>
    </div>
  </form>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import BaseInput from "@/core/components/base/BaseInput.vue";
import BaseButton from "@/core/components/base/BaseButton.vue";
import BaseSpinner from "@/core/components/base/spinner/BaseSpinner.vue";

export default defineComponent({
    name: "CreateHealthcenterGroupForm",
    components: {BaseSpinner, BaseButton, BaseInput},
    props: {
        name: {
            type: String,
            required: false,
            default: ''
        },
        description: {
            type: String,
            required: false,
            default: ''
        },
        loading: {
            type: Boolean,
            required: false,
            default: false
        },
        editMode: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    emits: ['submit'],
    data () {
        return {
            formData: {
                name: this.name,
                description: this.description
            },
            errors: {
                name: '',
                description: ''
            }
        };
    },
    methods: {
    /**
     * Validate the form and submit.
     */
        handleSubmit () {
            const isValid = this.validateForm();

            if (!isValid) {
                return;
            }

            const data = {
                name: this.formData.name,
                description: this.formData.description
            };

            this.$emit('submit', data);
        },
        /**
     * Validate the form.
     */
        validateForm () {
            this.errors = {
                name: '',
                description: ''
            };

            if (this.nullOrEmpty(this.formData.name)) {
                this.errors.name = 'Le nom du groupe est requis';
            }

            if (this.nullOrEmpty(this.formData.description)) {
                this.errors.description = 'La description du groupe est requise';
            }

            return Object.values(this.errors).every(error => {
                if (Array.isArray(error)) {
                    return error.every(err => this.nullOrEmpty(err));
                }

                return !Array.isArray(error) && this.nullOrEmpty(error);
            });
        },
        /**
     * Check if the value is null or empty.
     */
        nullOrEmpty (value: string|null) {
            return value === null || value.trim() === '';
        }
    }
});
</script>

<style scoped>

</style>
