<template>
  <div class="flex flex-col gap-2 bg-gray-200 rounded-lg px-3 py-5">
    <label class="font-content-bold text-3xl">
      {{ value }}
    </label>
    <label class="text-gray-500 text-sm line-clamp-2">
      {{ label }}
    </label>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

export default defineComponent({
    name: "StockStatisticCard",
    props: {
        value: {
            type: [
                String,
                Number
            ],
            required: false,
            default: null
        },
        label: {
            type: String,
            required: true
        }
    }
});
</script>
