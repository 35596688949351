<template>
  <GmapMarker
    v-if="warehouse.geo_lat && warehouse.geo_lon"
    :options="markerOptions"
    @click="$emit('click', warehouse)"
  >
    <div class="relative">
      <img
        alt="marker-icon"
        :src="markerIcon.marker"
        style="width: 80px; height: 80px"
      >
      <div
        v-if="markerIcon.image"
        class="absolute left-1.5 top-2 bg-white rounded-full flex items-center justify-center"
        style=" width: 46px; height: 46px; left: calc(50% - 23px); top: 5px;"
      >
        <img
          v-if="markerIcon.image"
          :src="markerIcon.image"
          alt="wholesaler"
          class="object-contain"
          style="width: calc(100% - 5px); height: calc(100% - 5px);"
        >
      </div>
    </div>
  </GmapMarker>
</template>

<script lang="ts">
import {CustomMarker as GmapMarker} from "vue3-google-map";
import {PropType, defineComponent} from "vue";
import {Warehouse} from "@/core/interfaces/warehouses";
import DistributorAvailabilityMapService from "@/disruption-logistics/services/DistributorAvailabilityMapService";

export default defineComponent({
    name: 'DistributorAvailabilityMapMarker',
    components: {
        GmapMarker
    },
    props: {
        warehouse: {
            type: Object as PropType<Warehouse>,
            required: true
        },
        isAvailable: {
            type: Boolean as PropType<boolean | null>,
            required: false,
            default: null
        },
        members: {
            type: Array,
            required: false,
            default: () => []
        }
    },
    emits: ['click'],
    computed: {
    /**
     * The marker options.
     */
        markerOptions(): google.maps.CustomMarkerOptions {
            return {
                position: {
                    lat: this.warehouse.geo_lat || 0,
                    lng: this.warehouse.geo_lon || 0
                },
                anchorPoint: 'BOTTOM_CENTER',
                zIndex: this.zIndex
            };
        },
        /**
     * The marker icon.
     */
        markerIcon() {
            return {
                marker: DistributorAvailabilityMapService.getMarkerImage(this.isAvailable, this.members),
                image: DistributorAvailabilityMapService.getWholesalerImage(this.warehouse.wholesaler_id)
            };
        },
        /**
     * The marker zIndex.
     */
        zIndex() {
            if (this.isAvailable === null) {
                return 1;
            }

            if (!this.isAvailable) {
                return 2;
            }

            if (this.isAvailable && !this.members.length) {
                return 3;
            }

            return 4;
        }
    }
});
</script>
