import {defineStore} from "pinia";

export type ToastType = "info" | "success" | "warning" | "error"
export type ToastPayload = { timeout?: number, content: string, onPress?: () => any }

export interface Toast {
    id: number
    content: string
    type: ToastType
    timeout?: number
    onPress?: () => any
}

const defaultTimeout = 5000;

export const useToastStore = defineStore('toast', {
    state: () => {
        return {
            toasts: [] as Toast[]
        };
    },
    actions: {
        showToast(value: Omit<Toast, "id">) {
            const currentToastId = Math.random() * 1000;
            this.toasts.push({...value, id: currentToastId});

            setTimeout(() => {
                this.toasts = this.toasts.filter(toast => toast.id !== currentToastId);
            }, value.timeout ?? defaultTimeout);
        },
        showSuccess(payload: ToastPayload) {
            this.showToast({...payload, type: "success"});
        },
        showError(payload: ToastPayload) {
            this.showToast({...payload, type: "error"});
        },
        showWarning(payload: ToastPayload) {
            this.showToast({...payload, type: "warning"});
        },
        showInfo(payload: ToastPayload) {
            this.showToast({...payload, type: "info"});
        }
    }
});