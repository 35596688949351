<template>
  <div class="flex-shrink-0 flex space-x-2">
    <Discount
      v-if="membership.loyalty_card.point_rewards_count"
      :membership="membership"
      @attributed="$emit('refresh')"
    />

    <Product
      v-if="membership.loyalty_card.product_rewards_count"
      :membership="membership"
      @attributed="$emit('refresh')"
    />

    <Credit
      :rate="membership.loyalty_card.point?.rate || 0"
      @credited="$emit('refresh')"
    />
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import Credit from '@/customer/components/customer-loyalty-membership/CustomerLoyaltyMembershipCredit.vue';
import Product from '@/customer/components/customer-loyalty-membership/CustomerLoyaltyMembershipProduct.vue';
import Discount from '@/customer/components/customer-loyalty-membership/CustomerLoyaltyMembershipDiscount.vue';

export default defineComponent({
    components: {
        Credit,
        Product,
        Discount
    },
    props: {
    /**
     * The membership to display.
     */
        membership: {
            type: Object,
            required: true
        }
    },
    emits: ['refresh']
});
</script>
