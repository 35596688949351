<template>
  <div
    class="border-2 rounded-lg bg-white flex items-center gap-5 p-3 transition-all duration-300 ease-in-out"
    :class="{
      'border-primary': loading || isAvailable || isAvailable === false,
      'border-gray-400': !loading && isAvailable === null
    }"
  >
    <div
      style="width: 50px"
      class="select-none"
    >
      <slot name="icon" />
    </div>
    <div class="flex flex-1 items-center">
      <div class="flex flex-1 flex-col gap-1">
        <p class="font-bold text-primary line-clamp-2">
          {{ loading ? loadingMessage : title }}
        </p>
        <div v-if="!loading">
          <p
            v-for="(message, index) in messages"
            :key="'message_' + index"
          >
            {{ message }}
          </p>
          <slot name="additional-info" />
        </div>
      </div>
      <div class="w-16 h-16 flex items-center justify-center">
        <base-spinner
          v-if="loading"
          size="medium-small"
        />
        <icon
          v-else-if="!isProductUnknown"
          :name="isAvailable ? 'mdi-check' : 'mdi-close'"
          :color="isAvailable ? '#65efca' : 'red'"
          :size="50"
        />
      </div>
    </div>
  </div>
</template>
<script lang="ts">

import Icon from "@/core/components/icon/Icon.vue";
import BaseSpinner from "@/core/components/base/spinner/BaseSpinner.vue";
import {PropType, defineComponent} from "vue";

export default defineComponent({
    name: 'ProductAvailabityCard',
    components: {Icon, BaseSpinner},
    props: {
        title: {
            type: String,
            required: true
        },
        messages: {
            type: Array as PropType<string[] | null>,
            required: false,
            default: () => []
        },
        isAvailable: {
            type: Boolean as PropType<boolean | null>,
            required: false,
            default: null
        },
        loading: {
            type: Boolean,
            required: true
        },
        loadingMessage: {
            type: String,
            required: false,
            default: ''
        },
    },
    computed: {
    /**
     * Determine if the product is unknown by the availability provider.
     *
     * @return {boolean}
     */
        isProductUnknown(): boolean {
            return this.isAvailable === null;
        }
    }
});
</script>
