<template>
  <div class="flex flex-col flex-1 gap-y-4">
    <BaseCard class="p-5">
      <div class="font-heading text-3xl font-bold text-primary mb-2">
        Par numéro de mobile
      </div>
      <div class="flex justify-center my-5">
        <div
          v-if="loading"
          class="flex"
        >
          <v-skeleton-loader
            max-width="500"
            type="button"
          />
        </div>
        <div v-else>
          <CustomerCertificationPhone
            v-if="!customer.certification"
            ref="phoneInput"
            :customer="customer"
            @certified="refresh"
            @conflict="handleConflict"
          />
          <CustomerCertificationStatus
            v-else
            :customer="customer"
          />
        </div>
      </div>
      <ul
        v-if="!conflictingCustomer"
        class="space-y-3"
      >
        <li class="flex flex-row items-center">
          <span
            class="text-5xl text-lightPurple-darkest text-center font-content font-bold w-7"
            :style="{'filter': 'drop-shadow(0 3px 6px rgb(0 0 0 / 0.16))'}"
          >
            1
          </span>
          <span class="text-black font-content ml-2">
            Renseigner le numéro de mobile du patient.
          </span>
        </li>
        <li class="flex flex-row items-center">
          <span
            class="text-5xl text-lightPurple-darkest font-content text-center font-bold w-7"
            :style="{'filter': 'drop-shadow(0 3px 6px rgb(0 0 0 / 0.16))'}"
          >
            2
          </span>
          <span class="text-black font-content ml-2">
            Votre patient télécharge l'application apodis sur son mobile.
          </span>
        </li>
        <li class="flex flex-row items-center">
          <span
            class="text-5xl text-lightPurple-darkest font-content text-center drop-shadow-lg font-bold w-7"
            :style="{'filter': 'drop-shadow(0 3px 6px rgb(0 0 0 / 0.16))'}"
          >
            3
          </span>
          <span class="text-black font-content ml-2">
            Il s'inscrit avec le numéro de mobile renseigné plus haut.
          </span>
        </li>
      </ul>
      <div v-else>
        <p>
          <Icon name="mdi-account-alert" color="red" class="mr-2" />
          Ce numéro correspond à un patient déjà certifié dans votre pharmacie.
        </p>
        <div class="flex flex-row flex-1 justify-around flex-wrap">
          <BaseButton
            primary
            class="mt-3 w-60"
            @click="navigateToConflictingCertification"
          >
            Voir le patient certifié
          </BaseButton>
          <BaseButton
            class="mt-3 w-60"
            @click="reset"
          >
            Certifier un autre numéro
          </BaseButton>
        </div>
      </div>
    </BaseCard>
    <div v-if="!!customer.information.phone" class="flex flex-row justify-between px-5">
      <div class="flex flex-col">
        <span class="font-bold">Ou</span>
        <span>Remettez-lui les instructions mobile</span>
      </div>
      <v-btn
        rounded="xl"
        prepend-icon="mdi-printer"
        :disabled="downloading"
        @click="print('phone')"
      >
        Imprimer
      </v-btn>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from "vue";
import CustomerCertificationStatus from '@/customer-certification/components/CustomerCertificationStatus.vue';
import CustomerCertificationPhone from '@/customer-certification/components/CustomerCertificationPhone.vue';
import BaseCard from '@/core/components/base/BaseCard.vue';
import {customerCertificationApi} from '@/container';
import BaseButton from '@/core/components/base/BaseButton.vue';
import Customer from "@/customer/interfaces/Customer";
import Icon from "@/core/components/icon/Icon.vue";

export default defineComponent({
    name: 'CustomerOnboardingPhoneCard',
    components: {
        Icon,
        BaseButton,
        CustomerCertificationStatus,
        CustomerCertificationPhone,
        BaseCard
    },
    props: {
        customer: {
            type: Object as PropType<Customer>,
            required: true
        },
        refresh: {
            type: Function as PropType<((...args: any[]) => any)>,
            required: true
        },
        downloading: {
            type: Boolean,
            required: true
        },
        print: {
            type: Function as PropType<((type: string) => void)>,
            required: true
        },
    },
    data() {
        return {
            loading: false,
            conflictingCustomer: null as Customer | null
        };
    },
    watch: {
        customer: {
            handler() {
                this.loadPhoneCertification();
            },
            immediate: true
        }
    },
    mounted() {
        if (!this.customer.certification) {
            this.loadPhoneCertification();
        }
    },
    methods: {
        loadPhoneCertification() {
            this.loading = true;
            return customerCertificationApi()
                .certifications(this.customer.id, 'pre_certified')
                .then(certifications => {
                    if (certifications.length > 0) {
                        this.refresh(certifications[0]);
                    }
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        /**
         * Handle certification conflict event.
         *
         * @param {object} customer - The conflicting customer.
         */
        handleConflict(customer: Customer) {
            this.conflictingCustomer = customer;
        },
        /**
         * Navigate to conflicting customer certification.
         */
        navigateToConflictingCertification() {
            if (this.conflictingCustomer) {
                this.$router.push({name: 'customer.certification', params: {customerId: this.conflictingCustomer.id}});
            }
        },
        /**
         * Reset the phone certification interface.
         */
        reset() {
            (this.$refs.phoneInput as any).clear();
            this.conflictingCustomer = null;
        }
    }
});
</script>
