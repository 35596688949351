<template>
  <BaseContainer>
    <div v-if="notifications.length">
      <ul class="mx-5 py-4 space-y-4">
        <li
          v-for="notification in notifications"
          :key="notification.id"
          class="px-4 py-6 shadow sm:rounded-lg sm:px-6 cursor-pointer"
          :class="[ notification.read_at
            ? 'bg-white hover:bg-gray-200'
            : 'bg-blue-100 hover:bg-blue-200'
          ]"
          @click="route(notification)"
        >
          <div class="sm:flex sm:justify-between sm:items-baseline">
            <h3 class="text-gray-900">
              {{ notification.data.title }}
            </h3>

            <p class="mt-1 text-sm text-gray-600 whitespace-nowrap sm:mt-0 sm:ml-3">
              <time datetime="2021-01-28T19:24">
                {{ notification.formatted.created_at }}
              </time>
            </p>
          </div>

          <div class="mt-4 space-y-6 text-sm text-gray-800">
            <p>
              {{ notification.data.description }}
            </p>
          </div>
        </li>
      </ul>

      <v-pagination
        v-model="page"
        :length="pagination.total_pages"
        :total-visible="7"
        circle
      />
    </div>

    <div
      v-else
      class="text-center"
    >
      <svg
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        class="mx-auto h-12 w-12 text-gray-400"
      >
        <path
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
        />
      </svg>

      <h3 class="mt-2 text-sm font-medium text-gray-900">
        Vous n’avez pas encore reçu de notifications
      </h3>

      <div class="mt-6">
        <button
          type="button"
          class="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          @click="$router.back()"
        >
          <svg
            viewBox="0 0 20 20"
            fill="currentColor"
            class="-ml-1 mr-2 h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm.707-10.293a1 1 0 00-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L9.414 11H13a1 1 0 100-2H9.414l1.293-1.293z"
            />
          </svg>

          Retour
        </button>
      </div>
    </div>

    <OrderActionModal
      v-if="orderId"
      :opened="showOrderModal"
      :order-id="orderId"
      @close-modal="closeModal"
      @show="showPatient"
    />

    <div
      v-show="opened"
      class="fixed z-10 inset-0 overflow-y-auto"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div
          class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          aria-hidden="true"
        />

        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >&#8203;</span>

        <div
          class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6"
        >
          <div>
            <div class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-blue-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-7 w-7 text-blue-600"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
            </div>

            <div class="mt-3 text-center sm:mt-5">
              <h3
                id="modal-title"
                class="text-lg leading-6 font-medium text-gray-900"
              >
                Information
              </h3>

              <div class="mt-2">
                <p class="text-sm text-gray-500">
                  Pour interagir avec cette notification, rendez-vous sur l’application Apodis PRO.
                </p>
              </div>
            </div>
          </div>

          <div class="mt-5 sm:mt-6">
            <button
              type="button"
              class="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-primary text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
              @click="opened = false"
            >
              Fermer
            </button>
          </div>
        </div>
      </div>
    </div>
  </BaseContainer>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import { notificationApi } from '@/container';
import BaseContainer from '@/core/components/base/BaseContainer.vue';
import notificationHelper from '@/core/helpers/notificationHelper.js';
import { mapStores } from 'pinia';
import {useSystemStore} from "@/stores/system.store";
import Customer from "@/customer/interfaces/Customer";
import OrderActionModal from "@/order/components/OrderActionModal.vue";

export default defineComponent({
    name: 'NotificationHistory',
    components: {
        BaseContainer,
        OrderActionModal
    },

    data () {
        return {
            page: 1,
            opened: false,
            showOrderModal: false,
            orderId: null as null | number,
            pagination: {} as any,
            notifications: [] as any[]
        };
    },
    computed: {
        ...mapStores(useSystemStore)
    },
    watch: {
        page: {
            immediate: true,

            handler (value) {
                notificationApi().get(value)
                    .then(notifications => {
                        this.notifications = notifications.data;
                        this.pagination = notifications.meta.pagination;
                    });
            }
        },
        '$route.query.orderId': {
            immediate: true,

            handler (value) {
                if (value) {
                    this.setOrder(parseInt(value));
                }
            }
        }
    },

    mounted () {
        notificationApi().markAll()
            .then(() => {
                this.systemStore.$patch({ 'notificationCount': 0 });
            });
    },

    methods: {
        route (notification: any) {
            const route = notificationHelper.generateRoute(notification);

            if (route) {
                this.$router.push(route);
            } else if (notification.type === 'orderCreated') {
                this.setOrder(notification.data.resource_id);
            } else {
                this.opened = true;
            }
        },
        setOrder (orderId: number) {
            this.orderId = orderId;
            this.showOrderModal = true;
        },
        closeModal () {
            this.orderId = null;
            this.showOrderModal = false;
        },
        showPatient (customer: Customer) {
            this.$router.push({
                name: 'customer.home',
                params: {
                    customerId: customer.id
                }
            });
        }
    }
});
</script>
