import {AxiosInstance} from "axios";
import {ShowcaseItem} from "@/core/interfaces/showcase";
import {Extension} from "@/core/interfaces/extension/Extension";

export default function (client: AxiosInstance, hdsClient: AxiosInstance) {
    return {
        /**
         * Get all extensions.
         * @returns {Promise} Promise object represents the HTTP request.
         */
        index(params = {}): Promise<Extension[]> {
            return client
                .get('extensions', {params})
                .then(response => response.data.data)
                .catch(() => []);
        },
        /**
         * Get a single extension.
         *
         * @param id
         */
        read(id: number) {
            return client.get(`extensions/${id}`)
                .then(response => response.data.data);
        },

        /**
         * Get all showcases for a given extension.
         *
         * @param extensionId
         */
        async showcase(extensionId: number): Promise<ShowcaseItem[]> {
            return hdsClient.get(`extensions/${extensionId}/showcase`)
                .then(response => response.data.data);
        },
        /**
         * Enable an extension.
         *
         * @param extensionId
         */
        async enable(extensionId: number): Promise<void> {
            return client.patch(`extensions/${extensionId}/enable`)
                .then(response => response.data.data);
        },

        /**
         * Disable an extension.
         *
         * @param extensionId
         */
        async disable(extensionId: number): Promise<void> {
            return client.patch(`extensions/${extensionId}/disable`)
                .then(response => response.data.data);
        }
    };
}
