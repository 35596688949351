import {interviewApi} from "@/container";

const InterviewAudioService = () => {
    /**
     * The mime type to use when recording interview audio.
     *
     * @type {string}
     */
    const recordMimeType = 'audio/webm;codecs=opus';

    /**
     * The file extension being generated when downloading the audio.
     *
     * @type {string}
     */
    const recordExtension = 'webm';

    /**
     * Retrieve the selected audio input device from local storage.
     */
    const getSelectedAudioInput = () => {
        return localStorage.getItem('selectedAudioInputId');
    };

    /**
     * Set the selected audio input device in local storage.
     *
     * @param audioInputId
     */
    const setSelectedAudioInput = (audioInputId) => {
        localStorage.setItem('selectedAudioInputId', audioInputId);
    };

    /**
     * Download the audio file.
     * First parse it to MP3 and then download it.
     */
    const downloadAudio = async (interviewId: number, audio: Blob, name: string): Promise<void> => {
        return interviewApi()
            .downloadAudio(interviewId, audio)
            .then((blob) => {
                const objectUrl = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = objectUrl;
                a.download = name + ".mp3";
                a.click();

                URL.revokeObjectURL(objectUrl);
            });
    };

    return {
        recordMimeType,
        recordExtension,
        getSelectedAudioInput,
        setSelectedAudioInput,
        downloadAudio
    };
};

export default InterviewAudioService();
