<script setup lang="ts">
import Extension from "@/core/interfaces/extension/Extension";
import {SupportProgramMarketStatistic, SupportProgramMeta, SupportProgramPatient} from "@/program/interfaces/Program";
import ProgramStatisticsCards from "@/program/components/program-statistics/ProgramStatisticsCards.vue";
import DegradedSupportProgramConsumerTable
    from "@/program/components/program-consumers/DegradedSupportProgramConsumerTable.vue";
import SupportProgramConsumerTable from "@/program/components/program-consumers/SupportProgramConsumerTable.vue";

defineEmits(["activate"]);
defineProps<{
    extension: Extension;
    marketStats?: SupportProgramMarketStatistic[];
    consumers?: {
        data: SupportProgramPatient[];
        meta: SupportProgramMeta;
    }
}>();
</script>

<template>
  <div class="flex flex-col p-8 gap-8">
    <div v-if="marketStats?.length" class="bg-white p-4 rounded-md">
      <h2 class="mb-2">
        Potentiel de votre officine
      </h2>
      <ProgramStatisticsCards
        :stats="marketStats"
      />
    </div>
    <div v-if="consumers" class="bg-white p-4 rounded-md">
      <h2>Patients</h2>
      <DegradedSupportProgramConsumerTable
        v-if="consumers.meta.degraded"
        :consumers="consumers.data"
        :meta="consumers.meta"
        :extension="extension"
        @activate="() => $emit('activate')"
      />
      <SupportProgramConsumerTable v-else :meta="consumers.meta" :consumers="consumers.data" />
    </div>
  </div>
</template>

<style scoped>

</style>
