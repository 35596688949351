import {AxiosInstance} from "axios";

/**
 * The loyalty card membership resource.
 * @author Tony Laurent <t.laurent@apodispharma.com>
 * @param {Object} client - The injected client.
 * @returns {Object} The public object.
 */
export default function (client: AxiosInstance) {
    return {
        /**
         * Get all loyalty card memberships.
         * @param {number} customerId - The ID of the customer.
         * @returns {Promise} Promise object represents the HTTP request.
         */
        get (customerId: number) {
            return client
                .get(`/customers/${customerId}/loyalty-card-memberships`)
                .then(response => response.data.data)
                .catch(() => []);
        },

        /**
         * Create a loyalty card membership.
         * @param {number} loyaltyCardId - The ID of the loyalty card.
         * @param {number} customerId - The ID of the customer.
         * @returns {Promise} Promise object represents the HTTP request.
         */
        create (loyaltyCardId: number, customerId: number) {
            return client
                .post(`/customers/${customerId}/loyalty-cards/${loyaltyCardId}`)
                .then(response => response.data.data)
                .catch(() => {});
        },

        /**
         * Read a loyalty card membership.
         * @param {number} customerId - The ID of the customer.
         * @param {number} membershipId - The ID of the membership.
         * @returns {Promise} Promise object represents the HTTP request.
         */
        read (customerId: number, membershipId: number) {
            return client
                .get(`/customers/${customerId}/loyalty-card-memberships/${membershipId}`)
                .then(response => response.data.data)
                .catch(() => {});
        }
    };
}
