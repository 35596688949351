<template>
  <div>
    <h2 class="text-lg">
      Entretiens disponibles
    </h2>
    <div
      v-if="interviews.length"
      class="grid grid-cols-1 md:grid-cols-3 gap-4 mt-2"
    >
      <button
        v-for="interview in interviews"
        :key="interview.id"
        class="bg-white shadow-md py-4 rounded-md flex items-center justify-center"
        @click="startInterview(interview)"
      >
        <icon
          name="file-alt"
          :size="30"
          class="mr-2"
        />
        <span>{{ interview.name }}</span>
      </button>
    </div>
    <div v-else>
      <p>Aucun entretien n'est disponible pour le moment.</p>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import Icon from '@/core/components/icon/Icon.vue';

export default defineComponent({
    name: 'InterviewGrid',
    components: {Icon},
    props: {
        interviews: {
            type: Array<any>,
            required: true
        },
        consumerName: {
            type: String,
            required: true
        },
        consumerKey: {
            type: String,
            required: true
        }
    },
    methods: {
    /**
     * Start a new interview instance.
     *
     * @param {Object} interview The interview.
     */
        startInterview(interview) {
            this.$router.push({
                name: 'interview-instances.record', query: {
                    interviewId: interview.id,
                    consumerName: this.consumerName,
                    consumerKey: this.consumerKey,
                    interviewName: interview.name
                }
            });
        },
    }
});
</script>
