<template>
  <BaseModal
    v-show="opened"
    :title="order ? 'Gestion commande n°' + order.id : 'Gestion commande'"
    class="z-20"
    @close="closeModal"
  >
    <BaseFlexSpinner v-if="isLoading" />
    <handle-order
      v-if="order&&!isLoading"
      :order-id="order.id"
      @order-handled="processedAction(order)"
      @go-to-patient="goToPatient"
    />
  </BaseModal>
</template>

<script lang="ts">

import {defineComponent} from "vue";
import HandleOrder from '@/order/components/HandleOrder.vue';
import BaseModal from '@/core/components/base/BaseModal.vue';
import {orderApi} from '@/container';
import BaseFlexSpinner from '@/core/components/base/spinner/BaseFlexSpinner.vue';
import {useLiveStore} from '@/stores/live.store';
import {mapActions} from 'pinia';
import Patient from "@/customer/interfaces/Patient";

export default defineComponent({
    name: 'ActionModal',
    components: {
        BaseFlexSpinner,
        HandleOrder,
        BaseModal
    },
    props: {
        opened: {
            type: Boolean,
            required: true
        },
        processAction: {
            type: Object,
            default: null
        },
        orderId: {
            type: Number,
            default: null
        }
    },
    emits: ['closeModal', 'show'],
    data() {
        return {
            isLoading: false,
            order: this.processAction as any
        };
    },
    watch: {
        orderId: {
            immediate: true,

            handler() {
                if (this.orderId && !this.processAction?.content) {
                    this.isLoading = true;
                    orderApi().read(this.orderId)
                        .then(response => {
                            this.order = response;
                        })
                        .finally(() => {
                            this.isLoading = false;
                        });
                }
            }
        },
        processAction: {
            handler() {
                this.order = this.processAction;
            }
        }
    },
    methods: {
        ...mapActions(useLiveStore, ['deleteItems']),
        /**
     * Close the modal.
     */
        closeModal() {
            this.$emit('closeModal');
        },
        /**
     * Delete live item once the action associated has been processed.
     *
     * @param action
     */
        processedAction(action: any) {
            this.closeModal();
            this.deleteItems(action);
        },
        /**
     * Show patient details.
     *
     * @param patient
     */
        goToPatient(patient: Patient) {
            this.$emit('show', patient);
        }
    }
});
</script>
