import {RouteRecordRaw} from "vue-router";
import InterviewInstanceLayoutView from "@/interview/views/InterviewInstanceLayoutView.vue";
import InterviewInstanceRecordView from "@/interview/views/InterviewInstanceRecordView.vue";
import InterviewInstanceSurveyView from "@/interview/views/InterviewInstanceSurveyView.vue";
import InterviewInstanceFinishedView from "@/interview/views/InterviewInstanceFinishedView.vue";
import InterviewInstanceTranscriptLoadingView from "@/interview/views/InterviewInstanceTranscriptLoadingView.vue";

const routes: RouteRecordRaw[] = [
    {
        path: '/interview-instances',
        name: 'interview-instances',
        component: InterviewInstanceLayoutView,
        props: (route) => ({...route.params, ...route.query}),
        meta: {
            layout: 'TheBlankLayout',
            transition: 'slide'
        },
        children: [
            {
                path: 'record',
                name: 'interview-instances.record',
                component: InterviewInstanceRecordView
            },
            {
                path: 'transcript-loading',
                name: 'interview-instances.transcript-loading',
                component: InterviewInstanceTranscriptLoadingView
            },
            {
                path: 'survey',
                name: 'interview-instances.survey',
                component: InterviewInstanceSurveyView
            },
            {
                path: 'finished',
                name: 'interview-instances.finished',
                component: InterviewInstanceFinishedView
            }
        ]
    }
];

export default routes;
