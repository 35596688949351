<template>
  <div>
    <div v-if="isLoading">
      <BaseFlexSpinner />
    </div>
    <div v-else>
      <MedicineCabinetStatistics
        v-if="featureSlug === 'medicine-cabinet'"
        :statistics="statistics"
      />
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import BaseFlexSpinner from '@/core/components/base/spinner/BaseFlexSpinner.vue';
import {medicineCabinetApi, programApi} from '@/container';
import MedicineCabinetStatistics from '@/program/components/medicine-cabinet-statistics/MedicineCabinetStatistics.vue';
import {mapState} from "pinia";
import {useSystemStore} from "@/stores/system.store";

export default defineComponent({
    name: 'FeatureStatistics',
    components: {MedicineCabinetStatistics, BaseFlexSpinner},
    props: {
        program: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            statistics: null as any | null,
            featureSlug: null as string | null,
            isLoading: false
        };
    },
    computed: {
        ...mapState(useSystemStore, ["environment"])
    },
    watch: {
        program: {
            handler() {
                this.load();
            },
            immediate: true
        }
    },
    methods: {
    /**
     * Load statistics based on feature program slug.
     *
     * @param slug
     *
     * @return {Promise}
     */
        loadStatistics(slug: string) {
            if (slug === 'medicine-cabinet' && this.environment?.entityId) {
                return medicineCabinetApi().statistics(this.environment.entityId)
                    .then(response => {
                        this.statistics = response;
                    });
            } else {
                return Promise.resolve();
            }
        },
        /**
     * Load data that needs to be displayed on the screen.
     */
        load() {
            this.isLoading = true;
            if (this.program.programmable_type === 'feature') {
                programApi().readFeature(this.program.programmable_id)
                    .then(response => {
                        this.featureSlug = response.slug;
                        return this.loadStatistics(response.slug);
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            } else {
                this.isLoading = false;
            }
        }
    }
});
</script>
