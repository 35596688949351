<template>
  <interview-short-cut-container
    title="Analyse pharmaceutique"
    class="flex flex-1 flex-col"
    :style="'background-color: #ffffff'"
  >
    <drug-interaction-screen
      v-if="consumerKey"
      :consumer-key="consumerKey"
    />
  </interview-short-cut-container>
</template>

<script lang="ts">

import {defineComponent} from 'vue';
import InterviewShortCutContainer
    from '@/interview/components/instance-modal/short-cuts/InterviewShortCutContainer.vue';
import DrugInteractionScreen from '@/drug-interaction/views/DrugInteractionScreen.vue';
import {mapState} from "pinia";
import {useInterviewStore} from "@/stores/interview-store";

export default defineComponent({
    name: 'InterviewDrugInteraction',
    components: {DrugInteractionScreen, InterviewShortCutContainer},
    computed: {
        ...mapState(useInterviewStore, {
            consumerKey: state => state.consumerKey
        })
    }
});
</script>
