import {AxiosInstance} from "axios";
import {StripeCheckout} from "@/stripe/interfaces";
import {BillingPortalSession} from "@/core/interfaces/stripe/BillingPortalSession";

export default function (client: AxiosInstance) {
    return {
        /**
         * Get the stripe checkout session.
         */
        async checkout(priceId: string): Promise<StripeCheckout> {
            return client.post('stripe/checkout', {priceId})
                .then(response => response.data);
        },

        /**
         * Create Stripe Billing Session
         *
         * @return {Promise<BillingPortalSession>}
         */
        async billingPortal(): Promise<BillingPortalSession> {
            return client.get("stripe/billingPortal")
                .then(response => response.data.session);
        }
    };
}
