<template>
  <div class="w-full">
    <div class="flex flex-col md:flex-row">
      <div class="w-full">
        <div class="flex flex-1 flex-row justify-between p-5">
          <markdown
            :content="step.resolved.description"
          />
          <img
            alt="illustration"
            :src="step.image ? step.image.url : pharmacistBubble"
            class="w-32 object-contain hidden sm:block"
          >
        </div>
        <div class="flex flex-row justify-end items-center gap-x-3">
          <pathway-delay-label
            v-if="!ready"
            @on-skip-delay="skipDelayMethod()"
          />
          <div
            v-else
            class="p-5"
          >
            <experienceable-pathway-step-button
              :primary="true"
              class="font-semibold"
              :step="step"
              @click="start"
            >
              {{ step.action }}
            </experienceable-pathway-step-button>
          </div>
        </div>
      </div>
    </div>
    <PathwayStepInsert
      v-if="active"
      :step="step"
    />
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import Markdown from '@/core/components/markdown/Markdown.vue';
import PathwayStepInsert from '@/pathway/components/PathwayStepInsert.vue';
import ExperienceablePathwayStepButton
    from '@/program/components/experienceable-program-button/ExperienceablePathwayStepButton.vue';
import PathwayDelayLabel from '@/pathway/components/PathwayDelayLabel.vue';
import pharmacistBubble from '@/assets/images/pharmacist-bubble.png';

export default defineComponent({
    name: 'PathwayLoopStep',
    components: {
        PathwayDelayLabel, ExperienceablePathwayStepButton, PathwayStepInsert, Markdown
    },
    props: {
        step: {
            type: Object,
            required: true
        },
        consumerKey: {
            type: String,
            required: true
        },
        active: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    emits: ['skipDelay', 'start'],
    data() {
        return {
            pharmacistBubble
        };
    },
    computed: {
        ready() {
            if (!this.step.delay) {
                return true;
            }

            return this.step.elapsed >= this.step.delay;
        }
    },
    methods: {
    /**
     * Emit the start event.
     */
        start() {
            this.$emit('start');
        },
        skipDelayMethod() {
            this.$emit('skipDelay');
        }
    }
});
</script>
