<template>
  <BaseModal
    v-show="isQrCodeModalVisible"
    ref="modal"
    title="Certification par QR code"
    class="w-96 m-auto"
    style="z-index:999"
    @close="isQrCodeModalVisible = false"
  >
    <img
      v-if="qrCode"
      :src="qrCode"
      alt="qr-code"
      class="w-full p-2"
    >
    <div class="flex flex-1 justify-center">
      <BaseButton
        class="mt-3 h-10 w-48 flex justify-center items-center"
        primary
        :disabled="downloading"
        @click="print"
      >
        <div v-if="!downloading">
          Imprimer
        </div>
        <img
          v-else
          :src="loaderButton"
          alt="Loading"
          width="24"
        >
      </BaseButton>
    </div>
  </BaseModal>
</template>

<script lang="ts">
import BaseModal from '@/core/components/base/BaseModal.vue';
import BaseButton from '@/core/components/base/BaseButton.vue';
import { customerCertificationApi, storageService } from '@/container';
import {defineComponent} from "vue";
import loaderButton from '@/assets/loader-button.gif';

export default defineComponent({
    components: {
        BaseButton,
        BaseModal
    },
    props: {
        qrCode: {
            type: String,
            default: () => null
        },
        customer: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            downloading: false,
            isQrCodeModalVisible: false,
            loaderButton
        };
    },
    methods: {
        open () {
            this.isQrCodeModalVisible = true;
        },
        print () {
            this.downloading = true;

            window.ipcRenderer
                .invoke('print', {
                    url: customerCertificationApi().pdfUrl(this.customer.id, 'qrcode'),
                    token: storageService().read('token')
                })
                .finally(() => {
                    this.downloading = false;
                });
        }
    }
});
</script>
