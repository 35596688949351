<template>
  <div class="bg-white shadow-md rounded-lg p-6 cursor-pointer flex gap-3">
    <SubscriptionPlanLogo class="!h-14 self-start" :plan="plan" />
    <div class="flex-1">
      <div class="flex justify-between items-center">
        <div class="flex items-center">
          <h1>{{ plan.name }}</h1>
          <icon name="mdi-chevron-right" color="primary" :size="25" />
        </div>
        <icon v-if="plan.subscribed" name="mdi-check-bold" color="secondary" :size="25" />
      </div>
      <div class="text-sm text-gray-500">
        {{ plan.teaser }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {SubscriptionPlan} from "@/subscription-plan/interfaces";
import Icon from "@/core/components/icon/Icon.vue";
import SubscriptionPlanLogo from "@/subscription-plan/components/SubscriptionPlanLogo.vue";

defineProps<{
    plan: SubscriptionPlan
}>();
</script>
