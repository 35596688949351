<script setup lang="ts">
import {computed} from "vue";
import {DETECTION_HEADER_HEIGHT} from './NotificationDetection.vue';
import Icon from "@/core/components/icon/Icon.vue";
import NotificationDetectionService from "@/notification/service/NotificationDetectionService";
import {DetectionNotificationProps} from "@/notification/interfaces";
import {useSystemStore} from "@/stores/system.store";
import {storeToRefs} from "pinia";

const props = defineProps<{
    isOpen: boolean;
    item: DetectionNotificationProps;
}>();
const systemStore = useSystemStore();
const {groupingLogo} = storeToRefs(systemStore);
defineEmits(['toggle']);

const programsCount = computed(() => {
    const programs = NotificationDetectionService.consumerPrograms(props.item.customer);

    return Object.values(programs).reduce((acc, programs) => acc + programs.length, 0);
});
</script>

<template>
  <div
    class="w-10 py-4 flex flex-col items-center justify-center gap-1 bg-primary-dark to-primary rounded-l-lg"
    :class="{
      'bg-primary-dark': !groupingLogo,
      'bg-white': groupingLogo
    }"
    :style="{ height: DETECTION_HEADER_HEIGHT + 'px'}" @click="() => $emit('toggle')"
  >
    <div>
      <div v-if="!isOpen">
        <img
          v-if="!groupingLogo"
          class="h-5 w-5"
          src="@/assets/images/logos/apodis-white.svg"
          alt="Logo Apodis"
        >
        <img
          v-else
          class="h-5 w-5 object-contain"
          :src="groupingLogo"
          alt="Logo Apodis"
        >
      </div>
    </div>
    <Icon
      :name="isOpen ? 'mdi-chevron-right' : 'mdi-chevron-left'"
      :class="{'text-white': !groupingLogo, 'text-primary': groupingLogo}"
      :size="30"
    />
    <div>
      <div v-if="programsCount && !isOpen" class="w-4 h-4 flex items-center justify-center bg-red-500 rounded-full text-white text-xs">
        <label v-if="programsCount" class="leading-none">
          {{ programsCount }}
        </label>
      </div>
    </div>
  </div>
</template>
