import {AxiosInstance, AxiosResponse} from "axios";
import {LegacySellinOffer, SellinOffersResponse} from "@/core/interfaces/SellinOffer";

/**
 * The disruption resource.
 *
 * @param {Object} client - The injected client.
 * @returns {Object} The public object.
 */
export default function (client: AxiosInstance) {
    return {
        /**
         * Get emergency offers.
         *
         * @param {number} from - The date from to fetch the offers.
         * @param {number} [size=10] - The returned offers count.
         *
         */
        async getDisruptionEmergencyOffers(from = 0, size = 10): Promise<LegacySellinOffer[]> {
            return client.get("/disruptions/emergency-offers", {
                params: {
                    from,
                    size
                }
            })
                .then((response: AxiosResponse<SellinOffersResponse>) => {
                    const {data} = response;
                    return data.data;
                });
        }
    };
}
