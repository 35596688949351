<script setup lang="ts">
import {Backing} from "@/backing/interfaces/backing";

defineProps<{
  backing: Backing
}>();
</script>

<template>
  <v-stepper class="!shadow-none">
    <v-stepper-header class="!shadow-none overflow-hidden">
      <template v-for="(step,index) in backing.steps" :key="index">
        <template v-if="! step.optional">
          <div
            :class="{
              'min-w-auto px-2 text-center rounded': step.label.length >=3,
              'min-w-6 rounded-full text-center': step.label.length <3,
              'bg-secondary text-primary': !!step.completed_at,
              'bg-slate-200': !step.completed_at
            }"
          >
            {{ step.label }}
          </div>
          <v-divider v-if="index != Object.keys(backing.steps).length - 1" class="border-primary mx-2 w-8" />
        </template>
      </template>
    </v-stepper-header>
  </v-stepper>
</template>
