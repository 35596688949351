<script setup lang="ts">
import arrowSms from '@/assets/icons/arrow-sms.svg';
import logoApodisSante from '@/assets/icons/logo-apodis-sante.svg';

defineProps<{ selectionEnabled: boolean }>();
defineEmits(['onPress', 'onCancel', 'onConfirm']);
</script>

<template>
  <div class="relative bg-orange-light rounded-lg shadow p-4 mx-4 mb-8 cursor-pointer">
    <div class="flex flex-1 flex-row space-between">
      <p class="flex flex-1 text-primary font-bold">
        {{
          selectionEnabled
            ? "Sélectionnez les patients à qui vous souhaitez envoyer un SMS. " +
              "Les patients sans numéro de téléphone valide (06/07) ont été retirés de la liste."
            : "Équipez un maximum de patients concernés avec notre fonction d'envoi SMS"
        }}
      </p>
      <img :src="arrowSms" alt="sms">
    </div>
    <div v-if="selectionEnabled" class="absolute flex flex-row right-10 -bottom-4">
      <div
        class="bg-primary p-2 px-4 rounded-md shadow"
        @click="$emit('onConfirm')"
      >
        <p class="text-white">
          Valider
        </p>
      </div>
      <div
        class="bg-white p-2 px-4 ml-2 rounded-md shadow"
        @click="$emit('onCancel')"
      >
        <p class="text-primary">
          Annuler
        </p>
      </div>
    </div>
    <div
      v-else
      class="absolute bg-white flex flex-row p-2 rounded-md shadow right-10 -bottom-4"
      @click="$emit('onPress')"
    >
      <img :src="logoApodisSante" alt="apodisLogo" class="h-6">
      <p class="ml-2 text-primary">
        Envoyer des SMS
      </p>
    </div>
  </div>
</template>
