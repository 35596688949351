<template>
  <div class="flex flex-wrap flex-row gap-2 justify-center">
    <survey-answer-button
      v-for="answer in question.answers"
      :key="answer.id"
      :style="{'background-color': answer?.color}"
      @click="onPress(answer.value)"
    >
      <span
        class="font-content"
        :style="{'color': answer?.color ? 'white' : 'black'}"
      >
        {{ answer.label }}
      </span>
    </survey-answer-button>
  </div>
</template>

<script lang="ts">
import SurveyAnswerButton from '@/survey/components/SurveyAnswerButton.vue';
import {defineComponent} from "vue";

export default defineComponent({
    name: 'SurveyAnswerSingleChoice',
    components: {SurveyAnswerButton},
    props: {
        question: {
            type: Object,
            required: true
        },
        inline: {
            type: Boolean,
            default: false
        }
    },
    emits: ['onPress'],
    methods: {
    /**
     * Emit an onPress event when an answer is pressed.
     *
     * @param {number} id The pressed answer id.
     */
        onPress(id: number) {
            this.$emit('onPress', id);
        }
    }
});
</script>
