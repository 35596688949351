import {QuickFilterCategory} from "@/core/interfaces/search-bar";
import {PRODUCT_SEARCH_QUERY_PARAM_KEYS} from "@/core/interfaces/search-bar/ISearchBarAutoComplete";

/**
 * The available product quick filters items.
 *
 * @type {QuickFilterCategory[]}
 */
const categories: QuickFilterCategory[] = [
    {
        name: "TVA",
        filters: [
            {
                label: "2.1%",
                value: 5,
                queryParam: PRODUCT_SEARCH_QUERY_PARAM_KEYS.VAT_TYPES
            },
            {
                label: "5.5%",
                value: 3,
                queryParam: PRODUCT_SEARCH_QUERY_PARAM_KEYS.VAT_TYPES
            },
            {
                label: "10%",
                value: 8,
                queryParam: PRODUCT_SEARCH_QUERY_PARAM_KEYS.VAT_TYPES
            },
            {
                label: "20%",
                value: 9,
                queryParam: PRODUCT_SEARCH_QUERY_PARAM_KEYS.VAT_TYPES
            }
        ]
    },
    {
        name: "Génériques",
        filters: [
            {
                label: "Princeps",
                value: 1,
                queryParam: PRODUCT_SEARCH_QUERY_PARAM_KEYS.PRODUCT_TYPES
            },
            {
                label: "Génériques",
                value: 2,
                queryParam: PRODUCT_SEARCH_QUERY_PARAM_KEYS.PRODUCT_TYPES
            }
        ]
    },
    {
        name: "Remboursement",
        filters: [
            {
                label: "LPP",
                value: 4,
                queryParam: PRODUCT_SEARCH_QUERY_PARAM_KEYS.REFUND_TYPES
            },
            {
                label: "Remboursé",
                value: [1, 2, 3, 8],
                queryParam: PRODUCT_SEARCH_QUERY_PARAM_KEYS.REFUND_TYPES
            },
            {
                label: "Non remboursé",
                value: 0,
                queryParam: PRODUCT_SEARCH_QUERY_PARAM_KEYS.REFUND_TYPES
            }
        ]
    },
    {
        name: "Tranches de prix",
        isExperimental: true,
        filters: [
            {
                label: "0€ à 0,82€",
                value: 1,
                queryParam: PRODUCT_SEARCH_QUERY_PARAM_KEYS.HEALTHCENTER_MARGIN_TYPES
            },
            {
                label: "0,83€ à 1,91€",
                value: 2,
                queryParam: PRODUCT_SEARCH_QUERY_PARAM_KEYS.HEALTHCENTER_MARGIN_TYPES
            },
            {
                label: "1,92€ à 22,90€",
                value: 3,
                queryParam: PRODUCT_SEARCH_QUERY_PARAM_KEYS.HEALTHCENTER_MARGIN_TYPES
            },
            {
                label: "21,91€ à 150€",
                value: 4,
                queryParam: PRODUCT_SEARCH_QUERY_PARAM_KEYS.HEALTHCENTER_MARGIN_TYPES
            },
            {
                label: "150,01€ à 1500€",
                value: 5,
                queryParam: PRODUCT_SEARCH_QUERY_PARAM_KEYS.HEALTHCENTER_MARGIN_TYPES
            }
        ]
    }
];

export default categories;
