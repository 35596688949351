<template>
  <div class="half-width">
    <div
      v-if="customer.beneficiaries && customer.beneficiaries.length"
      class="flex flex-1 flex-col items-start justify-end"
      :class="isActive ? 'bg-primary text-white': 'bg-white text-primary'"
    >
      <div
        class="px-4 py-0.5 bg-pink-lighter font-bold"
      >
        {{ customer.beneficiaries.length }} Ayant-Droit{{ customer.beneficiaries.length > 1 ? 's' : '' }}
      </div>
      <div class="flex flex-row w-full px-3 pb-2">
        <div class="flex flex-1 flex-row items-center space-x-4 py-1 overflow-x-auto w-64">
          <router-link
            v-for="(beneficiary, index) in customer.beneficiaries"
            :key="index"
            :to="{ name: 'customer.beneficiary', params: { id: beneficiary.id } }"
            active-class="font-semibold"
          >
            <CustomerHeaderBeneficiary :beneficiary="beneficiary" />
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import CustomerHeaderBeneficiary from '@/customer/components/customer-header/CustomerHeaderBeneficiary.vue';

export default defineComponent({
    name: 'CustomerHeaderBeneficiaries',
    components: { CustomerHeaderBeneficiary },
    props: {
        customer: {
            type: Object,
            required: true
        }
    },

    computed: {
        isActive () {
            return this.$route.name === 'customer.beneficiary';
        }
    },

    methods: {
        refresh () {
            this.$router.go(0);
        }
    }
});
</script>

<style scoped>
.half-width {
  max-width: 50%;
  width: 50%;
  display: flex;
  flex: 1 0 0;

}

.scroll-container {
  @apply flex flex-row flex-grow flex-row border-red-700 border-2;
  overflow-x: scroll;
}
</style>
