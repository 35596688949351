<template>
  <label class="font-medium text-sm flex items-center">
    <input
      type="radio"
      style="border: 1px solid !important"
      :value="value"
      :checked="value === modelValue"
      @change="$emit('update:modelValue', value)"
    >

    <span class="ml-3">
      {{ label }}
    </span>
  </label>
</template>

<script lang="ts">
import {defineComponent} from "vue";

export default defineComponent({
    props: {
        label: {
            type: String,
            required: true
        },

        value: {
            type: [
                String,
                Number,
                Boolean
            ],

            required: true
        },

        modelValue: {
            type: null,
            required: true
        }
    },
    emits: ['update:modelValue']
});
</script>
