<template>
  <form
    class="note__container bg-white pt-2 px-4 flex items-center gap-3 relative"
    @submit.prevent="handleSubmit"
  >
    <span class="text-4xl mr-2">
      ✍️
    </span>
    <div class="flex-1">
      <textarea
        ref="noteInput"
        :placeholder="'Écrivez vos notes ici. Elles seront accessibles pour les futurs entretiens.\nTapez Entrée ou cliquez sur + pour enregistrer.'"
        class="bg-gray-200 w-full h-full rounded-md pr-14"
        style="resize: none"
        @keydown="handleKeyDown"
      />
    </div>
    <button
      type="submit"
      class="absolute right-8 bg-white w-12 h-12 rounded-full shadow-2xl flex items-center justify-center"
    >
      <icon
        v-if="!loading"
        name="mdi-plus"
        :size="30"
      />
      <base-spinner
        v-else
        size="button"
      />
    </button>
  </form>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import Icon from '@/core/components/icon/Icon.vue';
import BaseSpinner from '@/core/components/base/spinner/BaseSpinner.vue';
import {mapActions} from "pinia";
import {useInterviewStore} from "@/stores/interview-store";

export default defineComponent({
    name: 'InterviewConsumerNoteInput',
    components: {BaseSpinner, Icon},
    props: {
        interviewInstance: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            loading: false
        };
    },
    methods: {
        ...mapActions(useInterviewStore, ['addNote']),
        /**
         * If the user presses Enter without holding Shift, submit the form
         *
         * @param event
         */
        handleKeyDown(event) {
            if (event.key === 'Enter' && !event.shiftKey) {
                event.preventDefault();
                this.handleSubmit();
            }
        },
        /**
         * Handle form submission
         */
        handleSubmit() {
            const text = (this.$refs as any).noteInput.value.trim();

            if (text) {
                this.loading = true;
                this.addNote(text)
                    .finally(() => {
                        (this.$refs as any).noteInput.value = '';
                        this.loading = false;
                    });
            }
        }
    }
});
</script>

<style scoped>
.note__container {
    box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.3);
}
</style>
