<template>
  <div v-if="isLoading">
    <BaseSpinner />
  </div>
  <div
    v-else
    class="mx-6"
  >
    <div v-if="!!animations?.length">
      <BaseCard
        v-for="animation in animations"
        :key="animation.id"
        class="relative overflow-visible my-5"
      >
        <BaseCardHeader>
          <div class="flex justify-between">
            <p>{{ animation.name }}</p>
            <p class="text-sm">
              Du {{ dateHelper.shortFormat(animation.start_date) }} au {{ dateHelper.shortFormat(animation.end_date) }}
            </p>
          </div>
        </BaseCardHeader>
        <BaseCardBody>
          <div class="flex justify-between align-center">
            <div>
              <p>{{ animation.description }}</p>
              <p class="text-primary">
                x{{ animation.coefficient }} appliqué aux points crédités.
              </p>
            </div>
            <BaseButton
              v-if="animation.status !== 'incoming'"
              primary
              class="justify-center"
              @click="onPress(animation)"
            >
              Voir les ventes
            </BaseButton>
          </div>
        </BaseCardBody>
      </BaseCard>
    </div>
    <div
      v-else
      class="flex align-center justify-center"
    >
      <p>Aucune animations pour cette carte de fidélité, rendez-vous sur Apodis Pro pour en créer</p>
    </div>
  </div>
</template>

<script lang="ts">

import {defineComponent} from "vue";
import BaseSpinner from '@/core/components/base/spinner/BaseSpinner.vue';
import { loyaltyCardApi } from '@/container';
import BaseCard from '@/core/components/base/BaseCard.vue';
import dateHelper from '@/core/helpers/dateHelper';
import BaseCardBody from '@/core/components/base/BaseCardBody.vue';
import BaseCardHeader from '@/core/components/base/BaseCardHeader.vue';
import BaseButton from '@/core/components/base/BaseButton.vue';
import {mapState} from "pinia";
import {useSystemStore} from "@/stores/system.store";

export default defineComponent({
    components: { BaseButton, BaseCardHeader, BaseCardBody, BaseCard, BaseSpinner },
    data () {
        return {
            isLoading: true,
            animations: null as any[] | null
        };
    },
    computed: {
        ...mapState(useSystemStore, ['environment']),
        dateHelper () {
            return dateHelper;
        }
    },
    watch: {
        '$route.params.loyaltyCardId': {
            immediate: true,

            handler (value) {
                this.readAnimations(value);
            }
        }
    },
    methods: {
    /**
     * Read the animations of loyalty card.
     *
     * @param id - The loyalty card id.
     * @return {Promise<Array>}
     */
        readAnimations (id: number) {
            if (this.environment?.entityId) {
                this.isLoading = true;
                return loyaltyCardApi()
                    .animations(this.environment.entityId, id)
                    .then(response => {
                        this.animations = response;
                    })
                    .finally(() => {
                        this.isLoading = false;
                    });
            }
        },
        /**
     * Action when see sales button is pressed.
     *
     * @param animation
     */
        onPress (animation) {
            this.$router.push({
                name: 'program.detail.animations.transactions',
                params: { animationId: animation.id }
            });
        }
    }
});

</script>
