import {defineStore} from "pinia";
import Customer from "../customer/interfaces/Customer";
import {LoyaltyCardMembership} from "@/loyalty-card/interfaces/hds/LoyaltyCard";
import {customerApi, customerProgramApi, drugInteractionApi, loyaltyCardMembershipApi, programApi} from "@/container";
import {VidalAllergies, VidalPathologies} from "@/customer/interfaces/Patient";
import Beneficiary from "@/customer/interfaces/Beneficiary";
import CustomerInformation from "@/core/interfaces/CustomerInformation";
import {SupportProgram, SupportProgramPathway} from "@/program/interfaces/Program";

/**
 * The Patient store that stores the customer snapshot and loyalty card memberships.
 */
export const usePatientStore = defineStore('patient', {
    state: () => ({
        customer: null as Customer | null,
        memberships: null as LoyaltyCardMembership[] | null,
        loading: false as boolean | number
    }),
    actions: {
        reset() {
            this.customer = null;
            this.memberships = null;
        },
        async loadPathway(supportProgramId: number, consumerKey: string, skipDelay = false) {
            return programApi()
                .pathway(supportProgramId, consumerKey, skipDelay)
                .then((pathway: SupportProgramPathway) => {
                    const index = this.customer?.programs.map(p => p.programmable_id)
                        .indexOf(supportProgramId);

                    (this.customer!.programs[index as number].programmable as SupportProgram).pathway = pathway;
                });
        },
        async loadMemberships(customerId: number) {
            return await loyaltyCardMembershipApi()
                .get(customerId)
                .then((memberships: LoyaltyCardMembership[]) => this.memberships = memberships)
                .catch();
        },
        async patchCustomer(
            customerId: number,
            information: Partial<CustomerInformation>,
            allergies?: Omit<VidalAllergies[], 'id'>,
            pathologies?: Omit<VidalPathologies[], 'id'>) {

            if (Object.keys(information).length > 0) {
                await customerApi().patch(customerId, information);
            }

            if (allergies && pathologies) {
                drugInteractionApi().storeProfile(customerId, allergies, pathologies);
            }

            this.load(customerId, true).finally();
        },
        async load(customerId: number, refresh: boolean = false): Promise<void> {
            if (this.loading === customerId) {
                return;
            }

            if (this.customer?.id === customerId && !refresh) {
                return;
            }

            this.reset();
            let customer: Customer;
            this.loading = refresh ? false : customerId;

            return await customerApi()
                .read(customerId)
                .then(customerResponse => {
                    customer = {...customerResponse};
                })
                .then(() => customerProgramApi().snapshot('c_' + customerId))
                .then(programs => {
                    customer.programs = programs;
                    return Promise.all(customer.beneficiaries.map((beneficiary: Beneficiary) => {
                        return customerProgramApi().index('b_' + beneficiary.id);
                    }));
                })
                .then(programs => {
                    customer.beneficiaries.forEach((beneficiary: Beneficiary, index: number) => {
                        beneficiary.programs = programs[index];
                    });
                })
                .then(() => {
                    this.customer = customer;
                })
                .finally(() => this.loading = false);
        },
        /**
         * Refreshes the customer snapshot.
         */
        async refresh() {
            if (!this.customer) {
                return Promise.reject();
            }

            await this.load(this.customer.id, true);
        }
    }
});
