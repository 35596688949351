<script setup lang="ts">
import {useRoute} from "vue-router";
import {ref, onMounted} from "vue";
import {offerApi} from "@/container";
import Offer from "@/core/interfaces/Offer";
import OfferHeader from "@/offer/components/OfferHeader.vue";
import BaseFlexSpinner from "@/core/components/base/spinner/BaseFlexSpinner.vue";
import OfferAdvantages from "@/offer/components/OfferAdvantages.vue";
import OfferProducts from "@/offer/components/OfferProducts.vue";
import HeaderBackButton from "@/core/components/header-back-button/HeaderBackButton.vue";
import Icon from "@/core/components/icon/Icon.vue";

const route = useRoute();

const offer = ref<Offer>();
const loading = ref<boolean>(true);

onMounted(() => {
    loadOffer(Number(route.params.offerId));
});

const loadOffer = (id: number) => {
    loading.value = true;
    offerApi().read(id)
        .then(response => {
            offer.value = response;
        })
        .finally(() => loading.value = false);
};

const updateOffer = (params: any) => {
    if (offer.value) {
        loading.value = true;
        return offerApi().update(offer.value.id, params)
            .then(response => {
                offer.value = response;
            })
            .finally(() => loading.value = false);
    }
};
</script>

<template>
  <div>
    <HeaderBackButton title="Promotions" class="bg-white pl-4" />
    <div v-if="loading">
      <BaseFlexSpinner />
    </div>
    <div v-else class="m-4">
      <div v-if="offer">
        <h1>{{ offer.name }}</h1>
        <div class="flex flex-row items-center">
          <icon name="mdi-calendar" class="mr-2" />
          <p>
            Du {{ $filters.date(offer.start_at, 'short') }} au {{ $filters.date(offer.end_at, 'short') }}
          </p>
        </div>
        <OfferAdvantages :offer="offer" />
        <OfferHeader :offer="offer" @on-publish="updateOffer" />
        <OfferProducts :offer="offer" />
      </div>
    </div>
  </div>
</template>
