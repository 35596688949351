<template>
  <create-healthcenter-group-form
    :name="group.name"
    :description="group.description"
    edit-mode
    :loading="loading"
    @submit="handleSubmit"
  />
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';
import CreateHealthcenterGroupForm
    from "@/disruption-logistics/components/healthcenter-groups/CreateHealthcenterGroupForm.vue";
import {HealthcenterGroup} from "@/disruption-logistics/interfaces/healthcenter-groups";
import {disruptionLogisticsApi} from "@/container";

export default defineComponent({
    name: "HealthcenterGroupEditForm",
    components: {CreateHealthcenterGroupForm},
    props: {
        group: {
            type: Object as PropType<HealthcenterGroup>,
            required: true
        }
    },
    emits: ['saved'],
    data () {
        return {
            loading: false
        };
    },
    methods: {
        handleSubmit (data: {name: string, description: string}) {
            this.loading = true;

            disruptionLogisticsApi()
                .editHealthcenterGroup(this.group.id, data)
                .then((group: HealthcenterGroup) => {
                    this.$emit('saved', group);
                })
                .finally(() => {
                    this.loading = false;
                });
        }
    }
});
</script>

<style scoped>

</style>
