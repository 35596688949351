<template>
  <div>
    <div v-if="prescriptions.length">
      <PrescriptionCard
        v-for="prescription in prescriptions"
        :key="prescription.key"
        :prescription="prescription"
      />
    </div>
    <div
      v-else
      class="p-8"
    >
      {{ noPrescriptionMessage }}
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from "vue";
import {Prescription} from "@/prescription/interfaces/Prescription";
import PrescriptionCard from "@/prescription/components/PrescriptionCard.vue";

export default defineComponent({
    name: 'PrescriptionList',
    components: {PrescriptionCard},
    props: {
        prescriptions: {
            type: Array as PropType<Prescription[]>,
            required: true
        },
        noPrescriptionMessage: {
            type: String,
            default: 'Aucune ordonnance disponible'
        }
    }
});
</script>
