<template>
  <div class="pw-96 h-fit p-4 rounded-lg bg-gradient-to-r from-darkPurple-default to-darkBlue-default">
    <div v-if="healthcenter === null">
      <h3 class="text-lg font-extrabold text-white">
        Sélectionnez votre pharmacie sur la carte.
      </h3>
    </div>
    <div
      v-else
      class="flex flex-col items-start"
    >
      <div class="-mt-10 -ml-10 -mb-6 flex rounded-full bg-white w-16 h-16 justify-center items-center">
        <img
          alt="Healthcenter placemark"
          :src="healthcenterPlacemarkSelected"
        >
      </div>
      <div class="ml-8 mb-4 text-base text-white">
        Votre pharmacie :
      </div>
      <h3 class="mb-2 text-lg font-extrabold text-white">
        {{ healthcenter.name }}
      </h3>
      <div class="text-base text-white">
        {{ healthcenter.geo.city.address1 }}<br>
        {{ healthcenter.geo.city.postal_code }}, {{ healthcenter.geo.city.name }}
      </div>
      <div class="text-sm text-gray-200">
        CIP: {{ healthcenter.cip }}
      </div>
      <v-skeleton-loader
        :loading="loading"
        class="flex flex-col self-stretch !bg-transparent"
        type="card"
      >
        <div
          v-if="owned"
          class="text-sm text-gray-200 self-start"
        >
          Cette pharmacie est déjà connectée
        </div>
        <div
          v-else
          class="flex self-end"
        >
          <button
            :disabled="!canConfirm"
            type="button"
            class="justify-center text-lg text-primary-dark rounded-full py-3 px-7 bg-secondary mt-5"
            @click="validate"
          >
            Je confirme
          </button>
        </div>
      </v-skeleton-loader>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import {healthcenterSearchApi} from '@/container';
import healthcenterPlacemarkSelected from '@/assets/icons/healthcenter_placemark_selected.svg';

export default defineComponent({
    name: 'RegisterHealthcenterView',

    props: {
        healthcenter: {
            type: Object,
            default: null,
            required: false
        }
    },
    emits: ['validate'],

    data() {
        return {
            owned: false,
            canConfirm: true,
            loading: false,
            healthcenterPlacemarkSelected
        };
    },

    watch: {
        healthcenter: function (newVal) {
            this.updateOwned(newVal.id);
        }
    },

    mounted() {
        if (this.healthcenter) {
            this.updateOwned(this.healthcenter.id);
        }
    },

    methods: {
        validate() {
            this.canConfirm = false;
            this.$emit('validate', this.healthcenter);
        },

        updateOwned(healthCenterId) {
            this.owned = false;
            this.loading = true;
            healthcenterSearchApi()
                .isOwned(healthCenterId)
                .then(ownedStatus => {
                    this.owned = ownedStatus.owned;
                })
                .finally(() => {
                    this.loading = false;
                });
        }
    }
});
</script>

<style scoped>
button:disabled {
  background: rgb(208, 208, 208);
  color: rgb(165, 165, 165);
}
</style>
