<template>
  <div>
    <v-autocomplete
      class="shadow healthcenter-search-bar"
      :items="healthcenters"
      item-title="name"
      item-value="id"
      menu-icon=""
      :loading="loading"
      variant="solo"
      color="primary"
      density="comfortable"
      placeholder="Rechercher une pharmacie par son nom, adresse, code postal, ville…"
      no-data-text="Aucune pharmacie trouvée"
      solo
      no-filter
      hide-no-data
      hide-details
      persistent-placeholder
      return-object
      @update:search="search = $event"
      @update:model-value="emitSelection"
    />
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import {healthcenterSearchApi} from "@/container";
import {Healthcenter} from "@/core/interfaces/healthcenter/Healthcenter";

export default defineComponent({
    name: "HealthcenterSearchBar",
    emits: ['select'],
    data () {
        return {
            search: null,
            select: null as null | { id: number, name: string },
            healthcenters: [],
            loading: false,
            timer: null as null | ReturnType<typeof setTimeout>
        };
    },
    watch: {
        search (value) {
            if (this.timer) {
                clearTimeout(this.timer);
            }

            this.timer = setTimeout(() => {
                if (!value || !value.length || value === this.select?.name) {
                    return;
                }

                this.loading = true;
                healthcenterSearchApi()
                    .read({ query: value, resource: true })
                    .then((healthcenters) => {
                        this.healthcenters = healthcenters.data.map(healthcenter => {
                            let name = healthcenter.name + ' - ' + healthcenter.cip;

                            if (healthcenter.address?.formatted?.trim()?.length) {
                                name += ' - ' + healthcenter.address.formatted;
                            }

                            return {
                                id: healthcenter.id,
                                name
                            };
                        });
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }, 300);
        }

    },
    methods: {
    /**
     * Emit the selected product to parent component.
     */
        emitSelection (selection: Healthcenter|null) {
            if(!selection) {
                return;
            }

            this.$emit('select', selection);
        }
    }
});
</script>

<style scoped>

</style>
