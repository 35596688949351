<template>
  <Base64Image
    :base64="base64"
    :alt="`${attachment.title}`"
    class="object-contain"
  />
</template>

<script lang="ts">
import Base64Image from '@/core/components/base-64-image/Base64Image.vue';
import {defineComponent} from "vue";

export default defineComponent({
    name: 'AttachmentThumbnail',
    components: { Base64Image },
    props: {
        base64: {
            type: String,
            required: true
        },
        attachment: {
            type: Object,
            required: true
        }
    }
});
</script>
