<template>
  <button class="flex flex-row items-center border-r border-primary pl-2 pr-4 pt-2">
    <div class="relative">
      <UserIcon
        class="rounded-full border mr-2"
        :class="isActive ? 'bg-primary text-white border-white' : 'bg-white text-primary border-primary'"
        :size="5"
      />
      <div
        v-if="beneficiary.programs.length > 0"
        class="dot"
      />
    </div>
    {{ beneficiary.name }}
    <div class="text-md whitespace-nowrap">
      {{ beneficiary.information.name }}
    </div>
  </button>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import UserIcon from '@/core/components/icons/User.vue';

export default defineComponent({
    name: 'CustomerHeaderBeneficiary',
    components: { UserIcon },
    props: {
        beneficiary: {
            type: Object,
            required: true
        }
    },
    computed: {
        isActive () {
            return this.$route.name === 'customer.beneficiary';
        }
    }
});
</script>

<style scoped>
.dot {
  @apply rounded-full bg-orange-default w-3 absolute h-3;
  top: -4px;
  right: 3px;
}
</style>
