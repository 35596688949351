/**
 * Format a number.
 *
 * @param value - The value.
 * @param decimals - The number of decimal.
 * default to 2.
 *
 * @return The formatted number.
 */
export const numberFormat = (value: (string | number), decimals = 2) => {
    if (Number.isNaN(value)) {
        return value;
    }

    const numberValue = Number(value);
    return numberValue.toLocaleString('fr-FR', {
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals
    });
};
