<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :class="`fill-current h-${size} w-${size}`"
    viewBox="0 0 44.775 39.179"
  >
    <path
      id="Icon_awesome-sms"
      data-name="Icon awesome-sms"
      d="M22.388,2.25C10.022,2.25,0,10.392,0,20.44A16.145,16.145,0,0,0,4.985,31.87C3.892,36.278.236,40.2.192,40.248a.7.7,0,0,0-.131.761.685.685,0,0,0,.638.42,20,20,0,0,0,12.3-4.5,26.671,26.671,0,0,0,9.392,1.7c12.366,0,22.388-8.142,22.388-18.19S34.753,2.25,22.388,2.25ZM11.211,26.037H10.144a.7.7,0,0,1-.7-.7v-1.4a.7.7,0,0,1,.7-.7H11.22c.525,0,.91-.306.91-.577a.463.463,0,0,0-.184-.332l-1.915-1.644a3.233,3.233,0,0,1-1.163-2.457,3.556,3.556,0,0,1,3.708-3.376h1.067a.7.7,0,0,1,.7.7v1.4a.7.7,0,0,1-.7.7H12.567c-.525,0-.91.306-.91.577a.463.463,0,0,0,.184.332L13.756,20.2a3.233,3.233,0,0,1,1.163,2.457A3.55,3.55,0,0,1,11.211,26.037Zm16.773-.7a.7.7,0,0,1-.7.7h-1.4a.7.7,0,0,1-.7-.7V19.373l-2.169,4.88a.7.7,0,0,1-1.251,0l-2.178-4.88v5.964a.7.7,0,0,1-.7.7h-1.4a.7.7,0,0,1-.7-.7v-9.1a1.4,1.4,0,0,1,1.4-1.4h1.4a1.393,1.393,0,0,1,1.251.77l1.548,3.1,1.548-3.1a1.4,1.4,0,0,1,1.251-.77h1.4a1.4,1.4,0,0,1,1.4,1.4Zm4.224.7H31.133a.7.7,0,0,1-.7-.7v-1.4a.7.7,0,0,1,.7-.7h1.076c.525,0,.91-.306.91-.577a.463.463,0,0,0-.184-.332l-1.915-1.644a3.233,3.233,0,0,1-1.163-2.457,3.556,3.556,0,0,1,3.708-3.376h1.067a.7.7,0,0,1,.7.7v1.4a.7.7,0,0,1-.7.7H33.555c-.525,0-.91.306-.91.577a.463.463,0,0,0,.184.332L34.745,20.2a3.233,3.233,0,0,1,1.163,2.457A3.542,3.542,0,0,1,32.209,26.037Z"
      transform="translate(0 -2.25)"
      fill="#fff"
    />
  </svg>
</template>

<script>
export default {
    props: {
        size: {
            type: Number,
            default: 24
        }
    }
};
</script>
