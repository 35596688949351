<template>
  <div
    class="w-full flex flex-wrap justify-center items-center md:columns-2"
  >
    <div
      v-if="isLoading"
      class="h-screen flex items-center"
    >
      <BaseSpinner />
    </div>
    <div
      v-if="hasError"
      class="h-screen flex items-center"
    >
      <p>Les videos n'ont pas pu être chargées, veuillez réessayer plus tard.</p>
    </div>
    <div
      class="w-full flex flex-wrap justify-center items-center md:columns-2"
    >
      <div
        v-for="(item, index) in youtubeTutorials"
        :key="index"
        class="mt-12 flex flex-col items-center"
      >
        <p class="font-bold text-xl text-center text-purple-900 max-w-lg h-16">
          {{ (index + 1) }}. {{ item.snippet.title }}
        </p>
        <iframe
          :src="'https://www.youtube.com/embed/' + item.snippet.resourceId.videoId + '?rel=0&autoplay=0&showinfo=0&controls=0'"
          width="600"
          height="315"
          frameborder="0"
          class="m-2"
        />
      </div>
      <div
        v-for="(item, index) in youtubeWebinars"
        :key="index + youtubeTutorials.length"
        class="mt-12 flex flex-col items-center"
      >
        <p class="font-bold text-xl text-center text-purple-900 max-w-lg h-16">
          {{ ((index + youtubeTutorials.length) + 1) }}. {{ item.snippet.title }}
        </p>
        <iframe
          :src="'https://www.youtube.com/embed/' + item.snippet.resourceId.videoId + '?rel=0&autoplay=0&showinfo=0&controls=0'"
          width="600"
          height="315"
          frameborder="0"
          class="m-2"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import axios from 'axios';
import BaseSpinner from '@/core/components/base/spinner/BaseSpinner.vue';

export default defineComponent({
    name: "Tutorial",
    components: {
        BaseSpinner
    },
    data () {
        return {
            isLoading: true,
            hasError: false,
            youtubeTutorials: [] as any[],
            youtubeWebinars: [] as any[]
        };
    },
    mounted () {
        const tutosPlaylistId = 'PLsBHFpvnM0qIIFxMb9zQfxUyDwDxla2zU';
        const webinarPlaylistId = 'PLsBHFpvnM0qIvzdq_zS-rtUn5dVK7Iy5i';
        const youtubeApiKey = import.meta.env.VITE_YOUTUBE_API_KEY;
        axios.get('https://youtube.googleapis.com/youtube/v3/playlistItems?part=snippet&playlistId=' + tutosPlaylistId + '&key=' + youtubeApiKey + '&maxResults=1337')
            .then(response => {
                this.youtubeTutorials = response.data.items;

                return axios.get('https://youtube.googleapis.com/youtube/v3/playlistItems?part=snippet&playlistId=' + webinarPlaylistId + '&key=' + youtubeApiKey+ '&maxResults=1337');
            })
            .then(response => {
                this.youtubeWebinars = response.data.items;
            })
            .catch(() => {
                this.hasError = true;
            })
            .finally(() => {
                this.isLoading = false;
            });
    }
});
</script>
