<template>
  <div>
    <fade>
      <div
        v-if="componentConfig"
        class="fixed inset-0 bg-gray-200 z-50 bg-opacity-60 cursor-pointer"
        @click="close"
      />
    </fade>
    <slide-right-to-left>
      <div
        v-if="componentConfig"
        class="fixed right-0 top-0 h-full bg-gradient-to-r from-darkPurple-default to-darkBlue-default shadow-md cursor-default flex flex-col overflow-y-scroll pt-3"
        style="z-index: 55; transition: width 0.5s ease, transform 0.5s ease;"
        :style="{
          width: expanded ? '100%' : componentConfig.minWidth
        }"
      >
        <div class="flex justify-between p-3 items-center">
          <button
            class="mt-2"
            @click="handleExpandClick()"
          >
            <icon
              :name="expanded ? 'mdi-arrow-collapse-right' : 'mdi-arrow-collapse-left'"
              color="white"
              :size="25"
            />
          </button>
          <button
            class="font-bold text-white"
            @click="close"
          >
            Fermer
          </button>
        </div>
        <div
          v-if="componentConfig"
          class="flex-1"
        >
          <component :is="componentConfig.component" />
        </div>
      </div>
    </slide-right-to-left>
  </div>
</template>

<script lang="ts" setup>
import {ref} from "vue";
import Fade from '@/core/transitions/Fade.vue';
import SlideRightToLeft from '@/core/transitions/SlideRightToLeft.vue';
import Icon from '@/core/components/icon/Icon.vue';

const props = defineProps<{
    componentConfig?: {
        component?: any;
        minWidth: string;
    }
}>();

const emits = defineEmits(['close']);

const expanded = ref(false);

/**
 * Handle expand click.
 *
 * If the min width is 100%, close completely the drawer.
 */
const handleExpandClick = () => {
    if (props.componentConfig?.minWidth === "100%") {
        expanded.value = false;
        emits('close');
    } else {
        expanded.value = !expanded.value;
    }
};

/**
 * Close the drawer.
 */
const close = () => {
    expanded.value = false;
    emits('close');
};
</script>
