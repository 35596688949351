import {authenticationService, hostApi, santeSecureApi, usersApi} from '@/container';
import {defineStore} from "pinia";

export const useWebsiteStore = defineStore('website', {
    state: () => ({
        santeSecure: null as any,
        hosts: [] as any[],
        mobiles: [] as any[]
    }),
    actions: {
        setSanteSecure(value: any) {
            this.santeSecure = value;
        },
        setHosts(values: any[]) {
            this.hosts = values;
        },
        setMobiles(values: any[]) {
            this.mobiles = values;
        },
        fetchData() {
            if (authenticationService().authenticated) {
                santeSecureApi()
                    .getState()
                    .then((state) => {
                        this.setSanteSecure(state.healthcenter);
                    });

                hostApi()
                    .get()
                    .then((hosts) => {
                        this.setHosts(hosts);
                    });

                usersApi()
                    .getUserMobiles()
                    .then((response) => {
                        this.setMobiles(response.mobiles.sort(
                            (a, b) => b.updatedAt.localeCompare(a.updatedAt)
                        ));
                    });
            }
        }
    }
});
