<template>
  <v-icon
    :icon="props.name || props.icon"
    :color="props.color"
    :size="props.size"
  />
</template>

<script setup lang="ts">
const props = defineProps({
    name: {
        type: String,
        required: false,
        default: null
    },
    icon: {
        type: String,
        required: false,
        default: 'mdi-check-circle'
    },
    color: {
        type: String,
        default: "#000"
    },
    size: {
        type: Number,
        default: 20
    }
});
</script>
