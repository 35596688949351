import {AxiosInstance} from "axios";
import {Program} from "@/program/interfaces/Program";

export default function (client: AxiosInstance) {
    return {
        /**
         * Get programs for a given consumer.
         *
         * @param consumerKey The consumer key.
         *
         * @return {*}
         */
        async index(consumerKey: string): Promise<Program[]> {
            const response = await client.get(`consumers/${consumerKey}/programs`);

            return response.data.data;
        },

        /**
         * Get programs snapshot for a given consumer.
         *
         * @param consumerKey The consumer key.
         *
         * @return {*}
         */
        async snapshot(consumerKey: string): Promise<Program[]> {
            const response = await client.get(`consumers/${consumerKey}/snapshot`);

            return response.data.data;
        }
    };
}
