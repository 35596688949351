/**
 * The drug interaction resource.
 *
 * @param {Object} client - The injected client.
 * @param {Object} hdsClient - The injected hds client.
 * @returns {Object} The public object.
 */
export default function (client, hdsClient) {
    return {
        /**
         * Get drug interaction link based on product cips.
         * @param productCips
         * @param customerId
         */
        read(productCips, customerId) {
            return hdsClient.get(`vidal/customers/${customerId}`, {params: {ids: productCips}})
                .then(response => response.data.data);
        },
        /**
         * Register vidal pathologies and allergies for a customer.
         * @param customerId
         * @param allergies
         * @param pathologies
         */
        storeProfile(customerId, allergies, pathologies) {
            const data = {allergies, pathologies};
            return hdsClient.post(`vidal/customers/${customerId}/profile`, {data})
                .then(response => response.data.data);
        },
        /**
         * Get vidal pathologies based on search query.
         * @param search
         */
        pathologies(search) {
            return client.get('vidal/pathologies', {params: {search}})
                .then(response => response.data.data);
        },
        /**
         * Get vidal allergies based on search query.
         * @param search
         */
        allergies(search) {
            return client.get('vidal/allergies', {params: {search}})
                .then(response => response.data.data);
        }
    };
}
