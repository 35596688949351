<template>
  <div>
    <base-flex-spinner v-if="loading" />
    <div
      v-else
      class="mx-6"
    >
      <base-card
        v-for="order in orders"
        :key="order.id"
        class="relative overflow-visible my-5 cursor-pointer"
      >
        <base-card-body>
          <div
            class="flex flex-row"
            @click="setOrderId(order.id)"
          >
            <div class="flex justify-between align-center ml-4">
              <div>
                <p>{{ order.customer.information.name }}</p>
                <p>{{ order.content_type === 'cart' ? 'Click & Collect' : 'Ordonnance' }}</p>
                <v-chip
                  class="mt-2"
                  :color="OrderService.statusColor(order)"
                  small
                >
                  <p class="text-sm">
                    {{ OrderService.statusLabel(order) }}
                  </p>
                </v-chip>
              </div>
            </div>
          </div>
        </base-card-body>
      </base-card>
      <div class="text-center mb-4">
        <v-pagination
          v-model="page"
          :length="totalPage"
          :total-visible="7"
          circle
        />
      </div>
      <action-modal
        v-if="orderId"
        :order-id="orderId"
        :opened="showModal"
        @close-modal="closeModal"
        @show="show"
      />
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import { orderApi } from '@/container';
import ActionModal from '@/order/components/OrderActionModal.vue';
import BaseCard from '@/core/components/base/BaseCard.vue';
import BaseCardBody from '@/core/components/base/BaseCardBody.vue';
import OrderService from '@/order/services/OrderService';
import Order from "@/order/interfaces/Order";
import BaseFlexSpinner from "@/core/components/base/spinner/BaseFlexSpinner.vue";

export default defineComponent({
    name: 'OrdersScreen',
    components: { BaseCardBody, BaseCard, BaseFlexSpinner, ActionModal },
    data () {
        return {
            orders: [] as Order[],
            loading: false,
            showModal: false,
            orderId: null as number | null,
            page: 1,
            totalPage: 1
        };
    },
    computed: {
        OrderService () {
            return OrderService;
        }
    },
    watch: {
        page: {
            immediate: true,

            handler () {
                this.loading = true;
                this.loadOrders()
                    .finally(() => {
                        this.loading = false;
                    });
            }
        }
    },
    methods: {
    /**
     * Load the orders.
     *
     * @return {Promise<unknown>}
     */
        loadOrders () {
            return orderApi().index({ page: this.page })
                .then((response) => {
                    this.orders = response.data;
                    this.totalPage = response.meta.last_page;
                });
        },
        /**
     * Close the modal and reset order id.
     */
        closeModal () {
            this.showModal = false;
            if (this.orderId) {
                orderApi().read(this.orderId)
                    .then(response => {
                        const index = this.orders.findIndex(order => order.id === this.orderId);

                        if (index !== -1) {
                            this.orders[index] = response;
                        }
                    })
                    .finally(() => {
                        this.orderId = null;
                    });
            }
        },
        /**
     * Open the modal and set order id.
     *
     * @param orderId
     */
        setOrderId (orderId) {
            this.orderId = orderId;
            this.showModal = true;
        },

        /**
     * Show customer page.
     *
     * @param customer
     */
        show (customer) {
            this.$router.push({
                name: 'customer.home',
                params: {
                    customerId: customer.id
                }
            });
        }
    }
});
</script>
