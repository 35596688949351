<template>
  <div class="flex flex-1 flex-row p-4 px-6 rounded-lg bg-white">
    <div class="flex flex-1 items-end">
      <img src="@/assets/icons/metro-bubbles.svg" class="h-14" alt="Bubbles">
      <span class="mb-2 ml-2 font-semibold text-lg">
        Engagez la conversation...
      </span>
    </div>
    <div class="flex flex-1 rounded-lg bg-pink-lighter">
      <div class="relative flex flex-1">
        <div class="absolute top-0 left-2">
          <icon name="mdi-format-quote-open" :size="40" />
        </div>
        <div class="flex flex-1 mx-10">
          <p class="text-sm px-4 py-4">
            {{ program.user_communication }}
          </p>
        </div>
        <div class="absolute bottom-0 right-2">
          <icon name="mdi-format-quote-close" :size="40" />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import Icon from "@/core/components/icon/Icon.vue";
import {Program} from "@/program/interfaces/Program";

defineProps<{
    program: Program;
}>();
</script>
