import {AxiosInstance} from "axios";
import Order from "@/order/interfaces/Order";
import {MetaData} from "@/core/interfaces/api";

/**
 * The order resource.
 *
 * @param {Object} client - The injected client.
 *
 * @returns {Object} The public object.
 */
export default function (client: AxiosInstance) {
    return {
        /**
         * Index orders.
         *
         * @param params Request params.
         *
         * @returns {Promise} Promise object represents the HTTP request.
         */
        async index(params: unknown): Promise<{data: Order[], meta: MetaData}> {
            return client
                .get('/orders', {params})
                .then(response => response.data);
        },
        /**
         * Read an order.
         *
         * @param id Order id.
         *
         * @return {Promise}
         */
        read(id: number) {
            return client.get(`orders/${id}`)
                .then(response => response.data.data);
        },
        /**
         * Edit order.
         *
         * @param id Order id.
         * @param data Data to patched the order with.
         *
         * @return {Promise}
         */
        patch(id: number, data: unknown) {
            return client.patch(`orders/${id}`, {data})
                .then(response => response.data);
        },
        /**
         * Index of order scoped by customerId.
         * @param customerId
         * @param params
         *
         * @return {*}
         */
        patientIndex(customerId: number, params: unknown) {
            return client
                .get(`/customers/${customerId}/orders`, {params})
                .then(response => response.data);
        }
    };
}
