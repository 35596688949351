<script setup lang="ts">
import {SubscriptionPlan} from "@/subscription-plan/interfaces";
import MarkdownView from "@/core/components/markdown/Markdown.vue";
import EmbedVideo from "@/core/components/video/EmbedVideo.vue";
import ExpandableTextOverflow from "@/core/components/expandable-text-overflow/ExpandableTextOverflow.vue";
import SubscriptionPlanSlider from "@/subscription-plan/components/SubscriptionPlanSlider.vue";
import {computed} from "vue";

const {plan} = defineProps<{
    plan: SubscriptionPlan
}>();

/**
 * Check if there is extensions previews to display.
 */
const hasExtensionsPreview = computed(() => {
    if (plan.extensions?.length) {
        return !!plan.extensions.find(extension => !!extension.previews?.length);
    }

    return false;
});

</script>

<template>
  <div class="shadow-[3px_3px_0_0_#B3A4EA] bg-white rounded-lg p-4 flex flex-row space-between">
    <expandable-text-overflow :background-color="{red: 255, green: 255, blue: 255}" class="basis-full">
      <MarkdownView :content="plan.description" inherit-styles class="markdown basis-2/3" />
    </expandable-text-overflow>
    <div v-if="plan.video_url || hasExtensionsPreview" class="w-64 pl-4">
      <embed-video
        v-if="!!plan.video_url && !plan.extensions?.length"
        :url="plan.video_url"
        class="rounded-lg h-56"
      />
      <SubscriptionPlanSlider v-else :plan="plan" class="w-24" />
    </div>
  </div>
</template>

<style scoped>
:deep(.markdown *) {
    @apply text-primary;
}
</style>
