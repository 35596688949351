import {storageService} from "@/container";
import {InternalAxiosRequestConfig} from "axios";

export default {
    success(config: InternalAxiosRequestConfig) {
        const token = storageService().read('token');

        config.headers = config.headers || {};
        config.headers.Authorization = `Bearer ${token}`;

        return config;
    }
};
