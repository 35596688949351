<template>
  <base-card>
    <base-card-body class="flex flex-col gap-3">
      <h2 class="text-lg">
        {{ healthcenterCount }} {{ $filters.pluralize('pharmacie', healthcenterCount) }}
        {{ $filters.pluralize('partenaire', healthcenterCount) }}
      </h2>
      <healthcenter-group-healthcenter-row
        v-for="healthcenter in healthcenters"
        :key="healthcenter.id"
        :healthcenter="healthcenter"
        :owner-access-id="ownerAccessId"
        :group-id="groupId"
        @member-deleted="(healthcenterId) => $emit('member-deleted', healthcenterId)"
      />
    </base-card-body>
  </base-card>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';
import {HealthcenterGroupMember} from "@/disruption-logistics/interfaces/healthcenter-groups";
import BaseCard from "@/core/components/base/BaseCard.vue";
import BaseCardBody from "@/core/components/base/BaseCardBody.vue";
import HealthcenterGroupHealthcenterRow
    from "@/disruption-logistics/components/healthcenter-groups/HealthcenterGroupHealthcenterRow.vue";

export default defineComponent({
    name: "HealthcenterGroupHealthcenters",
    components: {HealthcenterGroupHealthcenterRow, BaseCardBody, BaseCard},
    props: {
    /**
     * The owner access id of the group.
     */
        ownerAccessId: {
            type: Number,
            required: true
        },
        /**
     * The healthcenter group id.
     */
        groupId: {
            type: Number,
            required: true
        },
        /**
     * The healthcenter members.
     */
        healthcenters: {
            type: Array as PropType<HealthcenterGroupMember[]>,
            required: true
        }
    },
    emits: ['member-deleted'],
    computed: {
    /**
     * Get the healthcenter count.
     */
        healthcenterCount() {
            return this.healthcenters.length;
        }
    }
});
</script>

<style scoped>

</style>
