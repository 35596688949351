<script setup lang="ts">
import SearchBar from "@/core/components/search-bar/SearchBar.vue";
import {ref} from "vue";
import {drugInteractionApi} from "@/container";
import Icon from "@/core/components/icon/Icon.vue";

export interface VidalPathology {
    id: string;
    title: string;
}

const props = defineProps({
    patientPathologies: {
        type: Array<VidalPathology>,
        default: []
    }
});
const emit = defineEmits(['update']);
const pathologiesLoading = ref(false);
const pathologiesSearchQuery = ref('');
const pathologies = ref<VidalPathology[]>();
const selectedPathologies = ref<VidalPathology[]>(props.patientPathologies);
let timer: NodeJS.Timeout;

/**
 * Search vidal pathologies.
 * @param query
 */
const search = (query: string) => {
    pathologiesSearchQuery.value = query;
    if (timer) {
        clearTimeout(timer);
    }

    if (query.length === 0 || query.length < 3) {
        pathologies.value = undefined;
    } else {
        timer = setTimeout(async () => {
            pathologiesLoading.value = true;
            await drugInteractionApi().pathologies(query)
                .then(response => {
                    pathologies.value = response;
                })
                .finally(() => pathologiesLoading.value = false);
        }, 500);
    }
};

/**
 * Update selected pathologies.
 *
 * @param pathology
 */
const updatePathologies = (pathology: VidalPathology) => {
    const pathologyExists = selectedPathologies.value.find(selected => selected.id === pathology.id);

    if (!pathologyExists) {
        selectedPathologies.value.push(pathology);
    } else {
        const indexToRemove = selectedPathologies.value.indexOf(pathologyExists);
        if (indexToRemove !== -1) {
            selectedPathologies.value.splice(indexToRemove, 1);
        }
    }

    pathologiesSearchQuery.value = '';
    pathologies.value = undefined;
    emit('update', selectedPathologies);
};
</script>

<template>
  <div class="pl-0 pr-3 pt-4">
    <div class="flex flex-row items-center space-between">
      <div class="flex flex-col min-w-24">
        <p>
          Pathologies
        </p>
        <span class="text-sm text-primary">
          {{
            selectedPathologies.length > 0
              ? `${selectedPathologies.length} ${$filters.pluralize('séléctionné', selectedPathologies.length)}`
              : ""
          }}
        </span>
      </div>
      <div class="flex flex-1 ml-4 relative">
        <search-bar
          :is-loading="false"
          :value="pathologiesSearchQuery"
          class="flex-1"
          placeholder="Rechercher des pathologies..."
          @input="search"
          @blur="pathologies = []"
        />
        <div
          v-if="pathologiesLoading"
          class="flex flex-1 absolute top-11 bg-white border-2 border-primary/20 rounded-lg flex-col w-full z-10 px-2 py-4 mt-1"
        >
          <span>Recherche en cours...</span>
        </div>
        <div
          v-else-if="!!pathologies"
          class="flex flex-1 absolute top-11 max-h-[320px] overflow-y-scroll bg-white border-2 border-primary/20 rounded-lg flex-col w-full z-10 px-2 py-4 mt-1"
        >
          <div v-if="pathologies.length > 0" class="flex flex-col">
            <span
              v-for="pathology in pathologies"
              :key="pathology.id"
              class="my-1 p-2 rounded-md hover:shadow-md hover:cursor-pointer"
              @click="updatePathologies(pathology)"
            >
              {{ pathology.title }}
            </span>
          </div>
          <div v-else-if="!pathologies.length">
            <span class="my-1 p-2 rounded-md">Aucun résultat</span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div>
    <div
      v-if="selectedPathologies.length"
      class="flex flex-row flex-wrap mt-4"
    >
      <span
        v-for="pathology in selectedPathologies"
        :key="pathology.id"
        class="py-2 px-4 m-1 rounded-xl bg-primary/30 hover:shadow-md hover:cursor-pointer"
        @click="updatePathologies(pathology)"
      >
        {{ pathology.title }}
        <icon name="mdi-close" />
      </span>
    </div>
  </div>
</template>
