<template>
  <div class="flex flex-col">
    <h1>
      Vos groupes
    </h1>
    <div class="flex flex-col gap-6 mt-2">
      <p class="text-primary">
        En connectant d’autres pharmacies à votre réseau, vous bénéficiez de nombreux avantages pour gérer les ruptures
        plus efficacement :
      </p>
      <p>
        - 📦 <strong>Accès aux stocks des membres</strong> <br>
        Consultez les disponibilités de produits chez les pharmacies de votre groupe en temps réel
      </p>
      <p>
        - 🚚 <strong>Disponibilité chez les grossistes des partenaires</strong> <br>
        Apodis Rupture vous alerte si un membre de votre groupe peut commander le produit recherché auprès de son grossiste.
      </p>
    </div>
    <div class="gap-8 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 mt-8">
      <base-card
        v-for="group in groups"
        :key="group.id"
      >
        <router-link
          :to="{
            name: 'disruption-logistics.healthcenter-group',
            params: { healthcenterGroupId: group.id},
            query: {name: group.name}
          }"
        >
          <base-card-body class="h-full flex items-center">
            <div class="flex flex-1 items-center gap-8 justify-between">
              <div class="flex flex-col gap-3">
                <h3 class="font-bold text-lg line-clamp-1">
                  {{ group.name }}
                </h3>
                <span class="text-sm flex items-center gap-1">
                  <icon
                    v-if="isOwner(group)"
                    name="mdi-cog"
                  />
                  {{ group.member_count }} {{ $filters.pluralize('membre', group.member_count) }}
                </span>
              </div>
              <chevron-right-icon
                :size="20"
                class="text-primary"
              />
            </div>
          </base-card-body>
        </router-link>
      </base-card>
      <router-link :to="{name: 'disruption-logistics.healthcenter-group-create'}">
        <div class="flex flex-col items-center justify-center text-primary">
          <v-icon
            :size="80"
            color="primary"
          >
            mdi-plus
          </v-icon>
          <label class="font-bold">Créer un groupe</label>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';
import {HealthcenterGroup} from "@/disruption-logistics/interfaces/healthcenter-groups";
import HealthcenterGroupService from "@/disruption-logistics/services/HealthcenterGroupService";
import BaseCard from "@/core/components/base/BaseCard.vue";
import BaseCardBody from "@/core/components/base/BaseCardBody.vue";
import Icon from "@/core/components/icon/Icon.vue";
import ChevronRightIcon from "@/core/components/icons/ChevronRightIcon.vue";

export default defineComponent({
    name: "HealthcenterGroupGrid",
    components: {ChevronRightIcon, Icon, BaseCardBody, BaseCard},
    props: {
        groups: {
            type: Array as PropType<HealthcenterGroup[]>,
            required: false,
            default: () => []
        }
    },
    methods: {
    /**
     * Check if the current user is the owner of the group.
     */
        isOwner (group: HealthcenterGroup): boolean {
            return HealthcenterGroupService.isOwner(group.creator_access_id);
        }
    }
});
</script>

<style scoped>

</style>
