<template>
  <div>
    <div class="bg-gradient-to-r from-darkPurple-default to-darkBlue-default p-4">
      <div class="flex flex-row items-center">
        <img
          class="ml-3 mr-6 hidden sm:block cursor-pointer"
          :style="{'objectFit': 'scale-down', 'width': '50px'}"
          :src="apodisWhiteLogo"
          @click="home()"
        >
        <div class="text-white text-xl font-extrabold flex flex-col">
          <div class="flex flex-col flex-wrap sm:flex-row">
            <div
              class="text-white mr-4 cursor-pointer"
              @click="back()"
            >
              Retour
            </div>
          </div>
        </div>
      </div>
    </div>

    <router-view />
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import apodisWhiteLogo from '@/assets/images/logos/apodis-white.svg';
export default defineComponent({
    data() {
        return {
            apodisWhiteLogo
        };
    },
    methods: {
        back () {
            this.$router.go(-1);
        },
        home () {
            this.$router.push({
                name: 'onboarding'
            });
        }
    }
});
</script>
