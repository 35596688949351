<template>
  <div class="h-auto w-full">
    <div
      v-if="Object.keys(consumerPrograms).length > 0"
      class="h-auto w-full pb-2"
    >
      <h2 class="text-sm mx-3 mt-3">
        Programmes
      </h2>
      <div
        ref="programs"
        class="w-full"
      >
        <div
          v-for="consumerKey in Object.keys(consumerPrograms)"
          :key="`program-${consumerKey}`"
        >
          <p
            v-if="!!getConsumer(consumerKey) && (getConsumer(consumerKey) as Consumer).type === 'beneficiary' "
            class="text-sm flex line-clamp-1 mx-3 mt-3"
          >
            {{ getConsumer(consumerKey)?.information.name }}
          </p>
          <div v-if="item.customer" :class="layoutClass">
            <a
              v-for="(program, index) in consumerPrograms[consumerKey].slice(0, maximumProgramsToDisplayPerConsumer)"
              :key="`program-${program.id}-consumer-${consumerKey}-${index}`"
              style="cursor: pointer!important"
              class="cursor-pointer w-full"
              @click="showProgram(consumerKey, program)"
            >
              <component
                :is="layoutComponent"
                v-if="getConsumer(consumerKey)"
                class="mb-2 cursor-pointer"
                :customer="item.customer"
                :program="program"
                :consumer="getConsumer(consumerKey) as Consumer"
                @resize="$emit('resize')"
                @mouseenter="notificationHovered(program, consumerKey)"
              />
            </a>
            <div
              v-if="remainingConsumerPrograms(consumerKey) > 0"
              class="flex self-end justify-end"
            >
              <span
                class="text-sm mx-3 hover:underline cursor-pointer"
                @click.stop="showCustomer()"
              >
                + {{ remainingConsumerPrograms(consumerKey) }} autre{{
                  remainingConsumerPrograms(consumerKey) > 1 ? "s" : ""
                }} programme{{ remainingConsumerPrograms(consumerKey) > 1 ? "s" : "" }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <p v-else class="text-sm mx-3 mt-5 mb-3">
      Ce patient n'est ciblé par aucun programme.
    </p>
  </div>
</template>

<script lang="ts" setup>
import {computed} from "vue";
import NotificationProgramLogoAdvanced
    from '@/program/components/program-logo/NotificationProgramLogoAdvanced.vue';
import NotificationProgramLogoSimple
    from '@/program/components/program-logo/NotificationProgramLogoSimple.vue';
import {pathwayApi} from '@/container';
import {Program} from "@/program/interfaces/Program";
import NotificationDetectionService from "@/notification/service/NotificationDetectionService";
import {UserSettings} from "@/settings/interfaces";
import {DetectionNotificationProps, DetectionPatient} from "@/notification/interfaces";
import Consumer from "@/core/interfaces/consumers/Consumer";

const props = defineProps<{
    item: {
        patient: DetectionPatient;
        customer: DetectionNotificationProps["customer"];
        settings: UserSettings;
    }
}>();

defineEmits(['resize']);
const maximumProgramsToDisplayPerConsumer = 3;

/**
 * The notification consumers.
 */
const consumers = computed(() => NotificationDetectionService.consumers(props.item.customer));

/**
 * The consumer programs.
 */
const consumerPrograms = computed(() => {
    return NotificationDetectionService.consumerPrograms(props.item.customer);
});

/**
 * The layout class to use.
 */
const layoutClass = computed(() => 'detection-programs-' + props.item.settings.notification.layout);

/**
 * The layout component to use.
 */
const layoutComponent = computed(() => props.item.settings.notification.layout === 'advanced'
    ? NotificationProgramLogoAdvanced
    : NotificationProgramLogoSimple
);

/**
 * Get the consumer by key.
 */
const getConsumer = (key: string): Consumer|undefined => {
    return consumers.value.find(consumer => consumer.key === key);
};

/**
 * Show the customer.
 */
const showCustomer = () => {
    if (props.item.customer) {
        window.ipcRenderer.send('to-main-window', 'show-customer', props.item.customer.id);
    }
};

/**
 * Show the program.
 */
const showProgram = (consumerKey: string, program: any) => {
    if (!props.item.customer) {
        return;
    }

    if (program.programmable_type === 'discount') {
        window.ipcRenderer.send(
            'navigate',
            `/brand/${program.organization.entity_id}/offer/discount/${program.programmable_id}`,
            true
        );
    }

    if (program.programmable_type === 'support') {
        if (program.next_step) {
            pathwayApi().track('notification-click', program.next_step.pathway_id, consumerKey);
        }

        window.ipcRenderer.sendToMainWindow('show-support-program', {
            customerId: props.item.customer.id,
            programId: program.id,
            consumerKey
        });
    }

    if (program.programmable_type === 'feature') {
        window.ipcRenderer.send(
            'navigate',
            `/customer/${props.item.customer.id}/feature-programs/${program.id}/c_${props.item.customer.id}`,
            true
        );
    }
};

/**
 * Get the remaining consumer programs.
 */
const remainingConsumerPrograms = (consumerKey: string) => {
    const remaining = consumerPrograms.value[consumerKey].length - maximumProgramsToDisplayPerConsumer;

    if (remaining < 0) {
        return 0;
    }

    return remaining;
};

/**
 * If support program is hovered,
 * notify the electron main process to display the consumer program page on the main window.
 */
const notificationHovered = (program: Program, consumerKey: string) => {
    if (program.programmable_type === "support" && props.item.customer) {
        window.ipcRenderer.supportProgramNotificationHovered(props.item.customer.id, program.id, consumerKey);
    }
};
</script>
