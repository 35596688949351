<template>
  <div class="bg-pink-lighter pl-6 pt-3 pb-3">
    <div v-if="displayMergedPrograms">
      <div v-for="(program, index) in mergePrograms()" :key="index">
        <ConsumerProgram
          v-if="program?.length"
          :consumer="program[0].consumer"
          :programs="program"
          :customer="customer"
        />
      </div>
    </div>
    <ConsumerProgram v-else :consumer="consumer" :programs="programs" :customer="customer" />
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from "vue";
import {Program} from "@/program/interfaces/Program";
import Consumer from "@/core/interfaces/consumers/Consumer";
import Beneficiary from "@/customer/interfaces/Beneficiary";
import Customer from "@/customer/interfaces/Customer";
import ConsumerProgram from "@/consumer/components/consumer-programs/ConsumerProgram.vue";

interface ProgramWithConsumer extends Program {
    consumer: Consumer | Beneficiary | Customer;
}

export default defineComponent({
    components: {ConsumerProgram},
    props: {
        /**
         * The consumer that the programs belong to
         */
        consumer: {
            type: Object as PropType<Consumer | Beneficiary | Customer>,
            required: true
        },
        /**
         * The customer that the consumer belongs to
         */
        customer: {
            type: Object as PropType<Customer>,
            required: true
        },
        /**
         * The consumers that the programs belong to, in order to display merged programs
         */
        consumers: {
            type: Array<Consumer | Beneficiary | Customer>,
            required: false,
            default: []
        },
        /**
         * Whether to display merged programs
         */
        displayMergedPrograms: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    computed: {
        /**
         * Get the programs to display.
         */
        programs(): ProgramWithConsumer[] {
            let _programs: ProgramWithConsumer[];

            _programs = this.consumer.programs.map((program: Program) => ({
                ...program,
                consumer: this.consumer
            }) ?? []);

            return _programs.filter(program => program.programmable_type !== 'feature');
        }
    },
    methods: {
        /**
         * Merge the programs of the consumers in one array.
         */
        mergePrograms(): Array<ProgramWithConsumer[]> {
            return (this.consumers ?? []).map(_consumer => _consumer.programs.map(_program => ({
                ..._program,
                consumer: _consumer
            })).filter(program => program.programmable_type !== 'feature') ?? []);
        }
    }
});
</script>
