<template>
  <div class="flex flex-wrap flex-row" :class="[density == 'compact' ? 'justify-start gap-3' : 'justify-center gap-6']">
    <div
      v-for="answer in question.answers"
      :key="answer.value"
    >
      <div class="grid justify-items-center">
        <SurveyImage
          v-if="answer?.image"
          :survey-id="surveyId"
          :question-id="question.id"
          :answer-id="answer.value"
          class="w-32 h-32"
        />
      </div>
      <div
        class="cursor-pointer rounded-md border border-gray-300 shadow-md"
        :class="[density === 'compact' ? 'py-1 px-3' : 'p-4']"
        :style="{'background-color': isValueSelected(answer.value) ? (answer?.color || '#3513e2') : 'white'}"
        @click="editable && $emit('change', answer.value)"
      >
        <span
          class="font-content"
          :style="{'color': isValueSelected(answer.value) ? 'white' : 'black'}"
        >
          {{ answer.label }}
        </span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import SurveyImage from '@/survey/components/SurveyImage.vue';

export default defineComponent({
    name: 'SurveyBuiltChoices',
    components: {SurveyImage},
    props: {
        surveyId: {
            type: Number,
            required: true
        },
        question: {
            type: Object,
            required: true
        },
        editable: {
            type: Boolean,
            default: false
        },
        density: {
            type: String,
            default: 'normal'
        },
        value: {
            type: [Array],
            default: null
        }
    },
    emits: ['change'],
    methods: {
    /**
     * If the value has been an user answer.
     *
     * @param value
     *
     * @return {boolean}
     */
        isValueSelected(value) {
            if (!this.value?.length) {
                return false;
            }

            return this.value.includes(value);
        }
    }
});
</script>
