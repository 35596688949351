<script setup lang="ts">
import {InterviewInstance} from "@/interview/interfaces/interview";
import {BackingInterviewStep} from "@/backing/interfaces/backing";
import BaseCardBody from "@/core/components/base/BaseCardBody.vue";
import Icon from "@/core/components/icon/Icon.vue";
import BaseCard from "@/core/components/base/BaseCard.vue";
import {useRouter} from "vue-router";

const props = defineProps<{
    data: {
        instance: Omit<InterviewInstance, "interview">,
        step: Omit<BackingInterviewStep, "interview_instances">
    },
    backingId: number,
    consumerKey: string,
    consumerName: string
}>();

const router = useRouter();

/**
 * Redirect to the interview instance view.
 * The interview instance layout is responsible of navigating to the correct view depending the instance state.
 */
const handleClick = () => {
    router.push({
        name: 'interview-instances',
        query: {
            backingId: props.backingId,
            consumerKey: props.consumerKey,
            interviewId: props.data.step.resource_id,
            interviewInstanceId: props.data.instance.id,
            interviewName: props.data.step.name
        }
    });
};
</script>

<template>
  <BaseCard class="cursor-pointer" @click="handleClick">
    <BaseCardBody>
      <div class="min-w-48 flex flex-row">
        <div>
          <icon
            :size="20"
            :name="data.instance.ended_at ? 'mdi-chat' : 'mdi-chat-outline'"
            class="mr-1"
            :class="{
              'text-secondary': data.instance.ended_at,
              'text-primary': !data.instance.ended_at
            }"
          />
        </div>
        <span>{{ data.step.name }}</span>
      </div>
      <div
        class="text-sm text-end"
      >
        <span v-if="data.instance.ended_at">
          Fait le {{ $filters.date(data.instance.ended_at, 'medium') }}
        </span>
        <span v-else class="text-primary">
          Finaliser l'entretien ({{ $filters.date(data.instance.started_at, 'medium') }})
        </span>
      </div>
    </BaseCardBody>
  </BaseCard>
</template>

<style scoped>

</style>
