<script setup lang="ts">
import {useSubscriptionPlanStore} from "@/stores/subscription-plan.store";
import {storeToRefs} from "pinia";
import BaseFlexSpinner from "@/core/components/base/spinner/BaseFlexSpinner.vue";
import SubscriptionPlanLogo from "@/subscription-plan/components/SubscriptionPlanLogo.vue";
import {SubscriptionPlan} from "@/subscription-plan/interfaces";
import StripePriceService from "@/stripe/services/stripePriceService";

const subscriptionPlanStore = useSubscriptionPlanStore();

const {loading, individualPlans} = storeToRefs(subscriptionPlanStore);

const formattedLowestPrice = (plan: SubscriptionPlan) => {
    const lowestPrice = StripePriceService.lowestPrice(plan.prices);

    if (!lowestPrice) {
        return null;
    }

    return StripePriceService.formatPrice(lowestPrice);
};
</script>

<template>
  <div class="flex flex-col">
    <div class="text-center">
      <h2>Packs Individuels</h2>
      <p>Choisissez les packs qui vous plaisent...</p>
    </div>
    <div v-if="loading">
      <base-flex-spinner />
    </div>
    <div v-else class="flex flex-col gap-3">
      <router-link
        v-for="plan in (individualPlans ?? [])" :key="plan.id"
        class="bg-white p-2 rounded-md"
        :to="{name: 'subscription-plan', params: {planId: plan.id}}"
      >
        <div class="flex gap-2">
          <SubscriptionPlanLogo :plan="plan" />
          <div class="w-full">
            <div class="flex items-center gap-2">
              <p class="flex-1 font-semibold">
                {{ plan.name }}
              </p>
              <p v-if="formattedLowestPrice(plan)" class="text-sm text-primary font-semibold">
                {{ formattedLowestPrice(plan) }}
              </p>
            </div>
            <p>{{ plan.teaser }}</p>
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<style scoped>

</style>
