<template>
  <BaseContainer>
    <div v-if="program">
      <ProgramDescription
        class="mb-5"
        :program="program"
      />
      <FeatureStatistics
        v-if="program.programmable_type === 'feature'"
        :program="program"
      />
      <BrandOfferDiscount
        v-if="program.programmable_type==='discount'"
        :discount-id="program.programmable_id"
      />
      <SupportProgramDetailStatistics
        v-if="program.programmable_type === 'support'"
        :program="program"
      />
      <LoyaltyCardStatistics
        v-if="program.programmable_type === 'loyalty-card'"
        class="mx-5"
        :programmable-id="program.programmable_id"
      />
    </div>
  </BaseContainer>
</template>

<script lang="ts">
import {defineComponent, PropType} from "vue";
import BrandOfferDiscount from '@/brand/components/BrandOfferDiscount.vue';
import SupportProgramDetailStatistics
    from '@/program/components/support-program/SupportProgramDetailStatistics.vue';
import BaseContainer from '@/core/components/base/BaseContainer.vue';
import ProgramDescription from '@/program/components/program-description/ProgramDescription.vue';
import LoyaltyCardStatistics from '@/program/components/loyalty-card-statistics/LoyaltyCardStatistics.vue';
import FeatureStatistics from '@/program/components/feature-statistics/FeatureStatistics.vue';
import {Program} from "@/program/interfaces/Program";

export default defineComponent({
    name: 'ProgramDetailContent',
    components: {
        FeatureStatistics,
        ProgramDescription,
        BrandOfferDiscount,
        BaseContainer,
        SupportProgramDetailStatistics,
        LoyaltyCardStatistics
    },
    props: {
        program: {
            type: Object as PropType<Program>,
            required: true
        }
    }
});
</script>
