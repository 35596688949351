<script setup lang="ts">
import {useCreditStore} from "@/stores/credit.store";
import {useCommunicationStore} from "@/communication/stores/communication.store";
import {storeToRefs} from "pinia";

const {mode} = storeToRefs(useCommunicationStore());
const {credit} = useCreditStore();

</script>

<template>
  <div class="flex flex-row">
    <div
      class="flex flex-col mx-2 items-center py-2 px-4 cursor-pointer"
      :class="{'text-primary bg-white font-bold rounded-xl': mode === 'notification'}"
      @click="mode = 'notification'"
    >
      <p>Mode Notification</p>
      <p class="text-sm text-gray-500">
        Gratuit !
      </p>
    </div>
    <div
      class="flex flex-col items-center py-2 px-4 cursor-pointer"
      :class="{'text-primary bg-white font-bold rounded-xl': mode === 'sms'}"
      @click="mode = 'sms'"
    >
      <p>Mode SMS</p>
      <p class="text-sm text-gray-500">
        {{ credit?.balance || 0 }} {{ $filters.pluralize('crédit', credit?.balance || 0) }}
      </p>
    </div>
  </div>
</template>

<style scoped>

</style>