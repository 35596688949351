<template>
  <div class="flex flex-col w-full p-3 gap-y-2">
    <div class="flex flex-1 flex-row justify-between">
      <markdown
        :content="description"
        class="text-right"
        inherit-styles
      />
    </div>
    <v-divider class="border-opacity-75" />
    <div v-if="!step.steppable.prescriptions.length" class="p-5">
      Aucune ordonnance liée au programme.
    </div>
    <div
      v-if="step.steppable.show_prescriptions"
      class="flex flex-col flex-1 gap-y-5"
    >
      <ProgramPrescriptionCard
        v-for="prescription in step.steppable.prescriptions"
        :key="prescription.document_key"
        :consumer-key="consumerKey"
        :prescription="prescription"
      />
    </div>
    <PathwayStepInsert
      v-if="active"
      :step="step"
    />
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from "vue";
import Markdown from '@/core/components/markdown/Markdown.vue';
import PathwayStepInsert from '@/pathway/components/PathwayStepInsert.vue';
import {SupportProgramPathwayStep} from "@/program/interfaces/Program";
import ProgramPrescriptionCard from "@/prescription/components/ProgramPrescriptionCard.vue";

export default defineComponent({
    name: 'PathwayAssistedSaleStep',
    components: {ProgramPrescriptionCard, PathwayStepInsert, Markdown},
    props: {
        step: {
            type: Object as PropType<SupportProgramPathwayStep>,
            required: true
        },
        consumerKey: {
            type: String,
            required: true
        },
        active: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    computed: {
        description() {
            return this.step.resolved.description;
        },
        action() {
            return this.step.resolved.action;
        }
    }
});
</script>
