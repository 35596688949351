<template>
  <div class="flex justify-center">
    <p class="p-4 rounded-lg bg-primary-lightest">
      {{ renderDate() }}
    </p>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import dateHelper from '@/core/helpers/dateHelper';

export default defineComponent({
    name: 'SurveyBuiltDate',
    props: {
        question: {
            type: Object,
            required: true
        },
        editable: {
            type: Boolean,
            default: false
        },
        density: {
            type: String,
            default: 'normal'
        },
        value: {
            type: Array<any>,
            default: null
        }
    },
    computed: {
        dateHelper () {
            return dateHelper;
        }
    },
    methods: {
    /**
     * Display date with format DD/MM/YYYY.
     *
     * @return {string}
     */
        renderDate () {
            if (this.value?.length) {
                return dateHelper.shortFormat(this.value[0]);
            }

            return '';
        }
    }
});
</script>
