<template>
  <div class="p-5">
    <p
      class="font-weight-bold p-3 rounded-md shadow"
      :style="{'background-color': row.data[0].category.color}"
    >
      {{ row.title }}
    </p>
    <CustomerMedicineCabinetItem
      v-for="item in row.data"
      :key="`${row.title}-${item.id}`"
      :item="item"
    />
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import CustomerMedicineCabinetItem from '@/customer/components/customer-medicine-cabinet/CustomerMedicineCabinetItem.vue';

export default defineComponent({
    name: 'CustomerMedicineCabinetRow',
    components: { CustomerMedicineCabinetItem },
    props: {
        row: {
            type: Object,
            required: true
        }
    }
});
</script>
