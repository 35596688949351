export interface ShowcaseItem {
    /** The showcase item type. */
    type: ShowcaseType;
    /** The showcase item value. */
    value: unknown;
    /** The showcase item label. */
    label?: string;
}

export enum ShowcaseType {
    CONSUMER_COUNT = 'consumer_count',
    POTENTIAL = 'potential',
    FIRST_THREE_CONSUMERS = 'first_three_consumers',
}
