import {consumerProductApi, interviewApi, surveyApi} from '@/container';
import {acceptHMRUpdate, defineStore} from "pinia";
import {ConsumerProduct} from "@/product/interfaces/Product";
import {Interview, InterviewInstance, Transcript, Utterance} from "@/interview/interfaces/interview";
import {PrefilledSurveyAnswer, Survey} from "@/core/interfaces/survey/Survey";
import {ProgramAttachment} from "@/program/interfaces/Program";
import {usePatientStore} from "@/stores/patient.store";
import ConsumerKeyService from "@/consumer/services/ConsumerKeyService";

export const useInterviewStore = defineStore('interview', {
    state: () => ({
        audio: null as Blob | null,
        interview: null as Interview | null,
        interviewInstance: null as InterviewInstance | null,
        attachments: null as ProgramAttachment[] | null,
        surveys: null as Survey[] | null,
        products: null as ConsumerProduct[] | null,
        recording: false,
        transcript: null as Transcript | null,
        prefilledSurveyAnswers: [] as PrefilledSurveyAnswer[],
        noteCount: null as number | null,
        loading: {
            customer: false,
            interview: false,
            attachments: false,
            surveys: false,
            transcript: false,
            products: false,
            prefilledSurveyAnswers: false,
            interviewInstance: false,
        },
        consumerKey: null as string | null,
        backingId: null as number | null
    }),
    getters: {
        /**
         * Get the attachments for the healthcenter.
         *
         * @returns {Array}
         */
        professionalAttachments(state): ProgramAttachment[] | null {
            if (state.attachments) {
                return state.attachments.filter((attachment) => {
                    return attachment.entity_types.map((entity) => entity.slug).includes('healthcenter');
                });
            }

            return state.attachments;
        },
        /**
         * Get the attachments for the patient.
         *
         * @returns {Array}
         */
        patientAttachments(state): ProgramAttachment[] | null {
            if (state.attachments) {
                return state.attachments.filter((attachment) => {
                    return attachment.entity_types.map((entity) => entity.slug).includes('patient');
                });
            }
            return state.attachments;
        },
        /**
         * Get the consumer name from previously initialized patient store.
         */
        consumerName(): string | null {
            const patientStore = usePatientStore();

            return patientStore.customer?.information?.name || "";
        }
    },
    actions: {
        setRecording(value: boolean): void {
            this.recording = value;
        },
        /**
         * Initialize the interview by reading the interview and fetching the attachments.
         *
         * @param {number} interviewId
         * @param {string} consumerKey
         * @param {number} [interviewInstanceId] Optional interview instance ID, if the interview is already started.
         * @param {number} backingId The optional backing ID attached with interview
         *
         * @returns {Promise<void>}
         */
        async initialize(interviewId: number, consumerKey: string, interviewInstanceId?: number, backingId?: number): Promise<void[]> {
            this.$reset();
            this.consumerKey = consumerKey;
            this.loading.interview = true;
            this.loading.attachments = true;
            this.loading.surveys = true;
            this.loading.products = true;
            this.backingId = backingId || null;

            if (interviewInstanceId) {
                this.loading.interviewInstance = true;
            }

            this.fetchProducts().finally(() => {
                this.loading.products = false;
            });

            return await Promise.all([
                this.initPatientStore(consumerKey),
                this.readInterview(interviewId),
                this.fetchAttachments(interviewId),
                this.fetchSurveys(),
                this.getNoteCount(),
                interviewInstanceId ? this.readInterviewInstance(interviewId, interviewInstanceId) : Promise.resolve()
            ]).finally(() => {
                this.loading.interview = false;
                this.loading.attachments = false;
                this.loading.surveys = false;
            });
        },
        /**
         * Read an interview by its ID.
         *
         * @param {Number} interviewId
         *
         * @returns {Promise<void>}
         */
        async readInterview(interviewId: number): Promise<void> {
            return await interviewApi()
                .read(interviewId)
                .then((interview: Interview) => {
                    this.interview = interview;
                });
        },
        /**
         * Start the interview instance.
         */
        async startInterviewInstance(): Promise<InterviewInstance> {
            await interviewApi()
                .createInstance((this.interview?.id as number), (this.consumerKey as string), this.backingId)
                .then((instance) => {
                    this.interviewInstance = instance;
                });

            return (this.interviewInstance as InterviewInstance);
        },
        /**
         * Fetch relevant attachments for an interview.
         *
         * @param {Number} interviewId
         *
         * @returns {Promise<void>}
         */
        async fetchAttachments(interviewId: number): Promise<void> {
            this.attachments = await interviewApi()
                .attachments(interviewId, this.consumerKey)
                .then((attachments: ProgramAttachment[]) => {
                    return attachments.filter((attachment: ProgramAttachment) => attachment.relevant);
                });

        },
        /**
         * Fetch surveys.
         *
         * @returns {Promise<void>}
         */
        async fetchSurveys(): Promise<void> {
            return await surveyApi()
                .index()
                .then(surveys => {
                    this.surveys = surveys;
                });
        },
        /**
         * Fetch products.
         */
        fetchProducts() {
            return consumerProductApi().index(this.consumerKey)
                .then(response => {
                    this.products = response;
                });
        },
        /**
         * Get the number of notes for the consumer.
         *
         * @returns {Promise<void>}
         */
        async getNoteCount(): Promise<void> {
            return await interviewApi().notes((this.consumerKey as string), 1, 1)
                .then((data: { meta: { total: number }, data: [] }) => {
                    this.noteCount = data.meta.total;
                });
        },
        /**
         * Read the transcript for an interview.
         *
         * @param instanceId The interview instance ID.
         * @param {Blob} audio The audio blob.
         *
         * @returns {Promise<void>}
         */
        async readTranscript(instanceId: number, audio: Blob): Promise<Transcript> {
            this.loading.transcript = true;

            try {
                const transcript = await interviewApi().transcript(instanceId, audio);

                transcript.text = transcript.utterances.map((utterance: Utterance) => utterance.text).join(' ');

                this.transcript = transcript;

                return transcript;
            } finally {
                this.loading.transcript = false;
            }
        },
        /**
         * Get the prefilled interview survey answers from the transcript + stored data.
         *
         * @param {Number} interviewId The interview ID.
         * @param {String} transcriptId The transcript ID.
         * @param {String} transcriptText The transcript text.
         *
         * @returns {Promise<void>}
         */
        async prefillInterviewSurvey(
            interviewId: number,
            transcriptId: string | null = null,
            transcriptText: string | null = null
        ): Promise<void> {
            if (!this.consumerKey) {
                return;
            }

            this.loading.prefilledSurveyAnswers = true;
            const transcript = transcriptText ? {transcriptId, transcriptText} : null;

            this.prefilledSurveyAnswers = await interviewApi()
                .prefillInterviewSurvey(interviewId, this.consumerKey, transcript)
                .finally(() => {
                    this.loading.prefilledSurveyAnswers = false;
                });
        },
        /**
         * Add a note to the interview.
         *
         * @param {string} content
         * @returns {Promise<void>}
         */
        async addNote(content: string): Promise<void> {
            return await interviewApi()
                .storeNote((this.interviewInstance?.id as number), content)
                .then(() => interviewApi().notes((this.consumerKey as string), 1, 1)
                    .then((data: { meta: { total: number }, data: [] }) => {
                        this.noteCount = data.meta.total;
                    })
                );
        },

        /**
         * Read the interview instance.
         *
         * @param interviewId
         * @param interviewInstanceId
         */
        async readInterviewInstance(interviewId: number, interviewInstanceId: number): Promise<void> {
            this.loading.interviewInstance = true;
            return await interviewApi().readInstance(interviewId, interviewInstanceId)
                .then(async (instance) => {
                    this.interviewInstance = instance;

                    if (instance.has_archive) {
                        const archive = await interviewApi().readArchive(instance.id);

                        if (archive.transcript) {
                            this.transcript = {
                                utterances: archive.transcript.split("\n").map((text: string) => ({text})),
                                text: archive.transcript
                            };
                        }
                    }
                })
                .finally(() => {
                    this.loading.interviewInstance = false;
                });
        },

        /**
         * Run the prefill survey job.
         *
         * If an audio is available in store, use it to read the transcript before running the prefill survey job.
         */
        async runPrefillSurveyJob(): Promise<void> {
            const instanceId = (this.interviewInstance?.id as number);
            const interviewId = (this.interview?.id as number);
            const consumerKey = (this.consumerKey as string);

            if (this.audio) {
                return this.readTranscript(instanceId, this.audio)
                    .then((_transcript) => {
                        return interviewApi()
                            .runPrefillSurveyJob(
                                interviewId,
                                instanceId,
                                consumerKey,
                                _transcript
                            );
                    });
            } else {
                return interviewApi()
                    .runPrefillSurveyJob(
                        interviewId,
                        instanceId,
                        consumerKey,
                        this.transcript
                    );
            }
        },

        /**
         * Init the patient store.
         *
         * @param {string} consumerKey The consumer key to init the patient store with.
         */
        async initPatientStore(consumerKey: string) {
            const patientStore = usePatientStore();

            this.loading.customer = true;
            const customerId = ConsumerKeyService.customerIdFromKey(consumerKey);

            if (!customerId) {
                throw new Error("Consumer key is invalid");
            }


            await patientStore.load(customerId);
            this.loading.customer = false;
        }
    }
});

//https://pinia.vuejs.org/cookbook/hot-module-replacement.html#HMR-Hot-Module-Replacement-
if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useInterviewStore, import.meta.hot));
}
