<script setup lang="ts">
import {computed, inject, onMounted, ref} from "vue";
import {useInterviewStore} from "@/stores/interview-store";
import dayjs from "dayjs";
import BaseContainer from "@/core/components/base/BaseContainer.vue";
import Icon from "@/core/components/icon/Icon.vue";
import BaseCardBody from "@/core/components/base/BaseCardBody.vue";
import BaseSpinner from "@/core/components/base/spinner/BaseSpinner.vue";
import InterviewSurveyReport from "@/interview/components/instance-modal/InterviewSurveyReport.vue";
import BaseButton from "@/core/components/base/BaseButton.vue";
import BaseCard from "@/core/components/base/BaseCard.vue";
import {surveyApi, interviewApi, surveyAnswerInstanceApi} from "@/container";
import {Survey} from "@/core/interfaces/survey/Survey";
import {storeToRefs} from "pinia";
import {InterviewArchive} from "@/interview/interfaces/interview";
import {DotLottieVue} from "@lottiefiles/dotlottie-vue";
import InterviewAudioService from "@/interview/services/InterviewAudioService";
import CustomerCertification from "@/customer/components/customer-certification/CustomerCertification.vue";
import ProgramAppointment from "@/program/components/program-appointment/ProgramAppointment.vue";
import {usePatientStore} from "@/stores/patient.store";

const interviewStore = useInterviewStore();
const {customer} = usePatientStore();
const {interviewInstance, audio, interview} = storeToRefs(interviewStore);
const survey = ref<Survey | null>(null);
const archive = ref<null | InterviewArchive>(null);
const loading = ref(true);
const tab = ref<'survey' | 'notes' | 'transcript'>('survey');
const downloadingAudio = ref(false);
const downloadingSurveyInstance = ref(false);

/**
 * On mount, read the survey instance and the archive attached to the interview.
 */
onMounted(() => {
    if (!interviewInstance.value?.id) {
        return;
    }

    surveyApi()
        .builtAnsweredInstance(interviewInstance.value.survey_answer_instance_id)
        .then((response) => {
            survey.value = response.survey;

            if (interviewInstance.value) {
                return interviewApi().readArchive(interviewInstance.value.id);
            }

            return Promise.reject();
        })
        .then((response) => {
            archive.value = response;
        })
        .finally(() => {
            loading.value = false;
        });
});

/**
 * Get program linked to the interview.
 */
const program = computed(() => {
    return customer?.programs.find(program => {
        if (program.backings?.length) {
            return program.backings.find(backing => {
                return backing.interviews.find(backingInterview => {
                    if (interview.value) {
                        return backingInterview.id === interview.value.id;
                    }

                    return false;
                });
            });
        }
    });
});

/**
 * Compute the audio file name.
 */
const fileName = computed(() => {
    const name = interviewStore.consumerName?.replace(/\s/g, '_') ?? '';
    const date = dayjs().format("DD_MM_YYYY");

    return `${interviewStore.interview?.name}_${name}_${date}`;
});

/**
 * Download the audio if exists.
 */
const downloadAudio = () => {
    if (!audio.value || !interview.value) {
        return;
    }

    downloadingAudio.value = true;
    InterviewAudioService.downloadAudio(
        interview.value.id,
        audio.value,
        fileName.value,
    )
        .finally(() => {
            downloadingAudio.value = false;
        });
};

/**
 * Download the survey instance as PDF.
 */
const downloadSurveyInstance = async (): Promise<void> => {
    if (!interviewInstance.value?.survey_answer_instance_id) {
        return Promise.resolve();
    }

    downloadingSurveyInstance.value = true;
    return surveyAnswerInstanceApi()
        .export(interviewInstance.value?.survey_answer_instance_id)
        .then(data => {
            const objectUrl = URL.createObjectURL(data);
            const a = document.createElement('a');
            a.href = objectUrl;
            a.download = fileName.value + ".pdf";
            a.click();

            URL.revokeObjectURL(objectUrl);
        })
        .finally(() => {
            downloadingSurveyInstance.value = false;
        });
};

/**
 * Close function injected from InterviewInstanceLayoutView
 */
const close = inject('close') as () => void;
</script>

<template>
  <BaseContainer class="gap-y-4 p-4">
    <h1 class="text-center text-3xl">
      Entretien terminé !
    </h1>
    <div class="flex flex-row items-center bg-gray-100 rounded-lg">
      <div class="w-24 overflow-hidden">
        <dot-lottie-vue class="w-52 relative -left-14" src="animations/documents-completed.json" autoplay />
      </div>
      <div class="flex-1 text-primary pr-24 pl-4">
        N'oubliez pas d'ajouter le document récapitulatif de l'entretien sur le <strong>DMP</strong> du patient.
      </div>
      <div class="flex items-center justify-center w-64">
        <base-button class="max-w-72 font-bold" primary @click="close">
          Retour au patient
        </base-button>
      </div>
    </div>
    <BaseCard>
      <BaseCardBody>
        <div class="flex flex-row items-center gap-x-6">
          <div class="flex-1">
            <h1 class="text-gray-dark">
              Téléchargements
            </h1>
            <p>Votre entretien a bien été enregistré</p>
          </div>
          <div class="">
            <base-button v-if="audio" :loading="downloadingAudio" class="font-bold" @click="downloadAudio">
              <div class="flex">
                <icon
                  name="mdi-volume-high"
                  :color="'#000000'"
                  :size="20"
                  class="mr-2"
                />
                Télécharger l'audio
              </div>
            </base-button>
          </div>
          <div>
            <base-button class="font-bold" :loading="downloadingSurveyInstance" @click="downloadSurveyInstance">
              <div class="flex">
                <icon
                  name="mdi-file-document-outline"
                  :color="'#000000'"
                  :size="20"
                  class="mr-2"
                />
                Télécharger le PDF
              </div>
            </base-button>
          </div>
        </div>
      </BaseCardBody>
    </BaseCard>
    <div class="flex flex-row">
      <ProgramAppointment
        v-if="program?.bookable"
        :bookable="program.bookable"
        :programmable-id="program.programmable_id"
        :programmable-type="program.programmable_type"
        class="w-[50%]"
      />
      <CustomerCertification
        v-if="program && customer && program?.onboarding_communication"
        :customer="customer"
        :program-id="program.id"
        :communication-text="program.onboarding_communication"
        class="pl-3 w-[50%]"
      />
    </div>
    <div v-if="!loading" class="flex flex-col gap-y-5 mt-3">
      <div class="flex gap-x-6">
        <BaseButton @click="tab = 'survey'">
          Entretien
        </BaseButton>
        <BaseButton @click="tab = 'notes'">
          Notes
        </BaseButton>
        <BaseButton @click="tab = 'transcript'">
          Transcript
        </BaseButton>
      </div>
      <BaseCard>
        <BaseCardBody>
          <InterviewSurveyReport v-if="survey && tab === 'survey'" :survey="survey" />
          <div v-if="tab === 'notes'" class="px-3 py-2">
            <p v-if="archive?.notes" class="text-sm" v-html="archive.notes.replace(/\n/g, '<br>')" />
            <p v-else class="text-sm text-gray-dark">
              Aucunes notes prises pendant cet entretien.
            </p>
          </div>
          <div v-if="tab === 'transcript'" class="px-3 py-2">
            <p v-if="archive?.transcript" class="text-sm" v-html="archive.transcript.replace(/\n/g, '<br>')" />
            <p v-else class="text-sm text-gray-dark">
              Aucun transcript disponible
            </p>
          </div>
        </BaseCardBody>
      </BaseCard>
      <div class="flex items-center justify-center">
        <base-button class="max-w-72 font-bold" primary @click="close">
          Retour au patient
        </base-button>
      </div>
    </div>
    <div v-else class="flex flex-1 items-center justify-center">
      <BaseSpinner size="small" />
    </div>
  </BaseContainer>
</template>

<style scoped>

</style>
