/**
 * The Pro Sante Connect resource.
 * @author Mathieu Bolard <m.bolard@apodispharma.com>
 * @param {Object} client - The injected client.
 * @returns {Object} The public object.
 */
export default function (client) {
    return {
    /**
     * Get auth token.
     * @param {string} code - The e-cps auth code.
     * @param {string} redirectUri - The redirect uri (should be authorized by pro sante connect).
     * @returns {Promise} Promise object represents the HTTP request.
     */
        getToken (code, redirectUri) {
            return client
                .get('/psc/token', { params: { code, redirect_uri: redirectUri } })
                .then(response => response.data);
        },

        /**
     * Authenticate user with e-cps (get apodis auth token).
     * @param {string} psctoken - The e-cps token.
     * @returns {Promise} Promise object represents the HTTP request.
     */
        authenticate (psctoken) {
            return client
                .post('/psc/authenticate', { psctoken })
                .then(response => response.data);
        },

        /**
     * Get user info.
     * @param {string} token - The auth token.
     * @returns {Promise} Promise object represents the HTTP request.
     */
        getUserInfo (psctoken) {
            return client
                .get('/psc/userinfo', { params: { psctoken } })
                .then(response => response.data);
        },

        /**
     * Link CPS to current authenticated user access.
     * @param {string} code - The e-cps auth code.
     * @param {string} redirectUri - The redirect uri (should be authorized by pro sante connect).
     * @returns {Promise} Promise object represents the HTTP request.
     */
        linkCPS (code, redirectUri) {
            return client
                .put('/psc/linkcps', { code, redirect_uri: redirectUri })
                .then(response => response.data);
        }
    };
}
