<script setup lang="ts">
import SurveyAnswerButton from "@/survey/components/SurveyAnswerButton.vue";
import {SurveyQuestion, SurveyQuestionAnswer} from "@/core/interfaces/survey/Survey";
import {ref} from "vue";

interface SurveyAnswerMultipleChoiceProps {
    question: SurveyQuestion;
    inline?: boolean;
}

const {question} = defineProps<SurveyAnswerMultipleChoiceProps>();

const emits = defineEmits(['onPress']);

const localQuestion = ref(question);
const multipleAnswers = ref<SurveyQuestionAnswer[]>([]);
const isMultipleChoice = true;

/**
 * Tell if this question was previously answered by the user.
 *
 * @param {SurveyQuestionAnswer} answer
 * @return {boolean}
 */
const previouslyAnswered = (answer: SurveyQuestionAnswer): boolean => {
    if (localQuestion.value.user_answer) {
        return !!localQuestion.value.user_answer.value.find(item => {
            return item.toString() === answer.value.toString();
        });
    }

    return false;
};

/**
 * On answer press.
 * @param answer
 */
const onAnswerPress = (answer: SurveyQuestionAnswer) => {
    const answerAsString = answer.value.toString();
    if (localQuestion.value.user_answer === null) {
        localQuestion.value.user_answer = {value: [answerAsString]};
    } else {
        const answerIndex = localQuestion.value.user_answer.value.findIndex(
            previousAnswer => previousAnswer === answer.toString()
        );
        if (answerIndex === -1) {
            localQuestion.value.user_answer.value.push(answerAsString);
        } else {
            localQuestion.value.user_answer.value.splice(answerIndex, 1);
        }
    }
    const answerIndex = multipleAnswers.value.findIndex(previousAnswer => previousAnswer === answer);
    if (answerIndex === -1) {
        multipleAnswers.value.push(answer);
    } else {
        multipleAnswers.value.splice(answerIndex, 1);
    }

    emits('onPress', multipleAnswers.value.map(answer => answer.value), isMultipleChoice);
};

</script>

<template>
  <div class="flex flex-wrap flex-row gap-2 justify-center">
    <survey-answer-button
      v-for="answer in localQuestion.answers"
      :key="answer.value.toString()"
      :style="{'background-color': answer?.color}"
      :class="[{'bg-primary': previouslyAnswered(answer)}]"
      @click="onAnswerPress(answer)"
    >
      <span
        class="font-content"
        :style="{'color': answer?.color ? 'white' : 'black'}"
        :class="[{'text-white': previouslyAnswered(answer)}]"
      >
        {{ answer.label }}
      </span>
    </survey-answer-button>
  </div>
</template>
