<template>
  <div class="flex flex-col">
    <div class="flex-1 max-w-48">
      <program-teaser-text :program="program" />
    </div>
    <div>
      <program-teaser-action :program="program" />
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import ProgramTeaserText from '@/program/components/program-teaser/ProgramTeaserText.vue';
import ProgramTeaserAction from '@/program/components/program-teaser/ProgramTeaserAction.vue';

export default defineComponent({
    components: {ProgramTeaserAction, ProgramTeaserText},
    props: {
        program: {
            type: Object,
            required: true
        }
    }
});
</script>
