import {StripePrice} from "@/stripe/interfaces";
import {stripeApi} from "@/container";

const StripeCheckoutService = () => {
    return {
        /**
         * Launch a checkout session.
         */
        async checkout(price: StripePrice) {
            return stripeApi()
                .checkout(price.stripe_id || price.id)
                .then((response) => {
                    window.ipcRenderer.stripeCheckout(response.session.url);
                });
        }
    };
};

export default StripeCheckoutService();
