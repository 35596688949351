<template>
  <div class="w-full">
    <div class="flex flex-col">
      <div v-if="active && Array.isArray(step.steppable.verdict)">
        <PathwayGuidanceVerdict
          v-for="verdict in step.steppable.verdict"
          :key="verdict.id"
          class="p-4 mr-8"
          :guidance="step.steppable"
          :verdict="verdict"
        />
      </div>
      <PathwayGuidanceVerdict
        v-else
        class="p-4 mr-8"
        :guidance="step.steppable"
        :verdict="step.steppable.verdict"
      />
      <div class="flex flex-col flex-1 w-full">
        <div class="flex flex-1 flex-row justify-between p-5">
          <markdown
            :content="description"
            class="text-left"
            inherit-styles
          />
        </div>
        <div class="flex flex-row justify-end p-5">
          <BaseButton
            :primary="true"
            class="text-sm font-semibold"
            @click="$emit('start', step)"
          >
            {{ action }}
          </BaseButton>
        </div>
      </div>
    </div>
    <PathwayStepInsert
      v-if="active"
      :step="step"
    />
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import Markdown from '@/core/components/markdown/Markdown.vue';
import BaseButton from '@/core/components/base/BaseButton.vue';
import PathwayGuidanceVerdict from '@/pathway/components/PathwayGuidanceVerdict.vue';
import PathwayStepInsert from '@/pathway/components/PathwayStepInsert.vue';

export default defineComponent({
    name: 'PathwayGuidanceStep',
    components: {PathwayStepInsert, PathwayGuidanceVerdict, BaseButton, Markdown},
    props: {
        step: {
            type: Object,
            required: true
        },
        consumerKey: {
            type: String,
            required: true
        },
        active: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    emits: ['start'],
    computed: {
        description() {
            return this.step.resolved.description;
        },
        action() {
            return this.step.resolved.action;
        }
    }
});
</script>
