import {DetectionNotificationProps} from "@/notification/interfaces";
import Consumer from "@/core/interfaces/consumers/Consumer";

const NotificationDetectionService = () => {
    return {
        /**
         * Group the customer and his beneficiaries into one array.
         *
         * @param customer
         */
        consumers: (customer: DetectionNotificationProps["customer"]): Consumer[] => {
            if (!customer) {
                return [];
            }

            return [
                customer,
                ...(customer.beneficiaries ?? [])
            ];
        },

        /**
         * Determine if the program should not be hidden in the notification.
         */
        programNotHidden: (program: any): boolean => {
            return !(program.feedbacks?.filter(feedback => feedback.hide_notification).length > 0);
        },

        /**
         * Get the consumer programs that should be displayed in the notification.
         */
        consumerPrograms(customer): Record<string, any> {
            const consumers = this.consumers(customer);

            return consumers.reduce((acc, consumer) => {
                if (consumer.programs?.length > 0) {
                    acc[consumer.key] = consumer.programs.filter(program => this.programNotHidden(program));
                }

                return acc;
            }, {});
        }
    };
};

export default NotificationDetectionService();
