<template>
  <div class="flex flex-row w-full">
    <CustomerHeaderInsured :customer="customer" />
    <CustomerHeaderBeneficiaries
      v-if="customer.beneficiaries.length"
      :customer="customer"
    />
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from "vue";
import CustomerHeaderInsured from '@/customer/components/customer-header/CustomerHeaderInsured.vue';
import CustomerHeaderBeneficiaries from '@/customer/components/customer-header/CustomerHeaderBeneficiaries.vue';
import Customer from "@/customer/interfaces/Customer";

export default defineComponent({
    name: 'CustomerHeader',
    components: {
        CustomerHeaderBeneficiaries,
        CustomerHeaderInsured
    },
    props: {
        customer: {
            type: Object as PropType<Customer>,
            required: true
        }
    }
});
</script>
