import {AxiosInstance} from "axios";

/**
 * The pathway resource.
 *
 * @param {Object} client - The injected client.
 * @returns {Object} The public object.
 */
export default function (client: AxiosInstance) {
    return {
        /**
         * Store a pathway consumer step.
         *
         * @param stepId The pathway step id.
         * @param consumerKey The consumer key.
         * @param consumerSteppableId The consumer steppable id.
         * @param consumerSteppableType The consumer steppable type.
         * @param completed
         *
         * @returns The stored consumer step.
         */
        storeConsumerStep(
            stepId: number,
            consumerKey: string,
            consumerSteppableId: null | number = null,
            consumerSteppableType: null | string = null,
            completed = true
        ) {
            return client
                .post('pathway-consumer-steps', {
                    consumer_key: consumerKey,
                    pathway_step_id: stepId,
                    consumer_steppable_id: consumerSteppableId,
                    consumer_steppable_type: consumerSteppableType,
                    completed
                })
                .then(response => response.data.data);
        },
        /**
         * Track a pathway custom event.
         *
         * @param name The event name.
         * @param pathwayId  The pathway id.
         * @param consumerKey The consumer key.
         * @param data The event data.
         * @returns {*}
         */
        track(name: string, pathwayId: number, consumerKey: null|string = null, data = null) {
            const params: { name: string, consumer_key: string | null, pathway_id: number, data?: any } = {
                name,
                consumer_key: consumerKey,
                pathway_id: pathwayId
            };

            if (data) {
                params.data = data;
            }

            return client
                .post('pathway-events', params)
                .catch(() => Promise.resolve());
        }
    };
}
