<script setup lang="ts">
import BaseCard from "@/core/components/base/BaseCard.vue";
import BaseFlexSpinner from "@/core/components/base/spinner/BaseFlexSpinner.vue";
import {MassCertificationCustomer} from "@/core/interfaces/MassCertification";
import {ref} from "vue";
import CommunicationMessageCustomerRow from "@/communication/components/CommunicationMessageCustomerRow.vue";
import {useCreditStore} from "@/stores/credit.store";
import BaseButton from "@/core/components/base/BaseButton.vue";
import {useCommunicationStore} from "@/communication/stores/communication.store";
import Icon from "@/core/components/icon/Icon.vue";

const {customers} = defineProps<{ isLoading?: boolean; customers: MassCertificationCustomer[] }>();
const emits = defineEmits(['selected']);
const communicationStore = useCommunicationStore();

const selectedCustomers = ref<MassCertificationCustomer[]>([]);
const {credit} = useCreditStore();

/**
 * Select/unselect all customers.
 */
const toggleSelection = (allCustomers: MassCertificationCustomer[]) => {
    if (selectedCustomers.value.length === allCustomers.length) {
        selectedCustomers.value = [];
    } else {
        selectedCustomers.value = [...allCustomers];
    }
};

const onSelected = () => {
    communicationStore.customers = selectedCustomers.value;
    emits('selected');
};
</script>

<template>
  <BaseCard class="p-4 mt-4">
    <h2>Envoyer un SMS</h2>
    <div class="flex items-center gap-2">
      <div>Vous avez {{ credit?.balance || 0 }} crédits sms.</div>
      <RouterLink to="communication/message/store">
        <BaseButton primary density="compact">
          <Icon name="mdi-star" class="text-white mr-1" />
          Acheter des crédits
        </BaseButton>
      </RouterLink>
    </div>
    <BaseFlexSpinner v-if="isLoading" class="my-4" />
    <div v-else>
      <div v-if="customers.length > 0" class="mt-4">
        <v-checkbox
          :model-value="selectedCustomers.length === customers.length"
          density="comfortable"
          color="primary"
          class="bg-gray-100 h-16 pt-1 text-primary"
          @click="toggleSelection(customers)"
        >
          <template #label>
            <span class="pl-4">Tout sélectionner</span>
          </template>
        </v-checkbox>
      </div>
      <div
        v-for="customer in customers"
        :key="customer.id"
      >
        <v-checkbox
          v-model="selectedCustomers"
          :value="customer"
          density="comfortable"
          color="primary"
          class="border-b-2 h-24"
        >
          <template #label>
            <CommunicationMessageCustomerRow :customer="customer" />
          </template>
        </v-checkbox>
      </div>
    </div>
  </BaseCard>
  <BaseButton
    v-if="selectedCustomers.length > 0"
    primary
    class="!fixed bottom-10 right-10"
    @click="onSelected"
  >
    Continuer
  </BaseButton>
</template>

<style scoped>
:deep(.v-selection-control .v-label) {
    flex: 1;
    opacity: 1;
}
</style>
