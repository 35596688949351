<template>
  <div
    id="distributor-map"
    class="flex flex-col-reverse lg:flex-row gap-4"
  >
    <div
      v-if="legendOpen"
      class="flex items-center"
    >
      <distributor-availability-map-legend />
    </div>
    <div class="bg-white rounded-md shadow flex-1">
      <div class="p-3 flex flex-col gap-3">
        <div>
          <h2 class="text-xl">
            Disponibilité dans les agences grossistes
          </h2>
          <button
            class="text-sm text-primary"
            @click="legendOpen = !legendOpen"
          >
            {{ legendOpen ? 'Cacher' : 'Voir' }} la légende
          </button>
        </div>
        <distributor-map-statistics
          :availabilities="successData"
        />
      </div>
      <disruption-logistics-map
        ref="mapRef"
        :loading-data="loading"
      >
        <info-window
          v-if="infoContent"
          :v-model="infoWindowOpened"
          :options="{
            position: {
              lat: infoContent?.position.lat as number,
              lng: infoContent?.position.lng as number
            }
          }"
        >
          <distributor-availability-info-window
            :content="infoContent"
            :members="warehouseMembers(infoContent.warehouse)"
          />
        </info-window>
        <distributor-availability-map-marker
          v-for="warehouse in warehouses"
          :key="warehouse.id"
          :warehouse="warehouse"
          :is-available="isAvailable(warehouse)"
          :members="warehouseMembers(warehouse)"
          @click="toggleInfoWindow($event)"
        />
      </disruption-logistics-map>
    </div>
  </div>
</template>

<script lang="ts">
import {InfoWindow} from "vue3-google-map";
import DisruptionLogisticsMap from '@/disruption-logistics/components/maps/DisruptionLogisticsMap.vue';
import DistributorAvailabilityMapMarker
    from '@/disruption-logistics/components/maps/distributors/DistributorAvailabilityMapMarker.vue';
import DistributorAvailabilityInfoWindow
    from '@/disruption-logistics/components/maps/distributors/DistributorAvailabilityInfoWindow.vue';
import DistributorMapStatistics
    from '@/disruption-logistics/components/maps/distributors/DistributorMapStatistics.vue';
import DistributorAvailabilityMapLegend
    from '@/disruption-logistics/components/maps/distributors/DistributorAvailabilityMapLegend.vue';
import {PropType, defineComponent} from "vue";
import {Warehouse} from '@/core/interfaces/warehouses';
import {
    DistributorProductAvailability,
    HealthcenterGroupDistributorDetails, HealthcenterGroupMember
} from "@/disruption-logistics/interfaces/availability-providers";

export default defineComponent({
    name: 'DistributorAvailabilityMap',
    components: {
        DistributorAvailabilityMapLegend,
        DistributorMapStatistics,
        DistributorAvailabilityInfoWindow,
        DistributorAvailabilityMapMarker,
        DisruptionLogisticsMap,
        InfoWindow
    },
    props: {
        product: {
            type: Object,
            required: true
        },
        data: {
            type: Array as PropType<HealthcenterGroupDistributorDetails[] | null>,
            required: false,
            default: () => null
        }
    },
    data() {
        return {
            infoWindowOpened: false,
            infoContent: undefined as undefined | {
        warehouse: Warehouse,
        available: boolean | null,
        message: string | null,
        position: {
          lat: number,
          lng: number
        }
      },
            legendOpen: false
        };
    },

    computed: {
        loading(): boolean {
            return !this.data;
        },

        /**
     * The warehouses.
     *
     * @return {Warehouse[]}
     */
        warehouses(): Warehouse[] {
            if (!this.data) {
                return [];
            }

            return this.data.filter(datum => datum.success)
                .map(datum => datum.warehouse);
        },
        /**
     * Get the data for the success availability.
     *
     * @return {Array}
     */
        successData(): HealthcenterGroupDistributorDetails[] {
            if (!this.data) {
                return [];
            }

            return this.data.filter(datum => datum.success);
        }
    },
    watch: {
        warehouses: {
            handler(warehouses) {
                if (!warehouses?.length) {
                    return;
                }

                this.$nextTick(() => {
                    const bounds = warehouses.map(warehouse => ({
                        lat: warehouse.geo_lat,
                        lng: warehouse.geo_lon
                    }));

                    try {
                        (this.$refs.mapRef as InstanceType<typeof DisruptionLogisticsMap>).fitMapToBounds(bounds, 0);
                    } catch (e) {
                        console.error("ERRORR", e);
                    }
                });
            }
            ,
            deep: true
        }
    },
    methods: {
    /**
     * Get the availability for a warehouse.
     */
        warehouseAvailability(warehouse: Warehouse): DistributorProductAvailability[] | undefined {
            if (!this.data) {
                return undefined;
            }

            return this.data?.find(
                availability => availability.warehouse?.id === warehouse.id
            )?.products;
        },
        /**
     * Determine if the product is available in the warehouse.
     * It return null, if we doesn't have the availability for a warehouse
     *
     * @return {boolean|null}
     */
        isAvailable(warehouse: Warehouse): boolean | null {
            const availabilities = this.warehouseAvailability(warehouse);

            if (!availabilities?.length) {
                return null;
            }

            return availabilities.some(product => product.isAvailable);
        },
        /**
     * Toggle warehouse info window.
     */
        toggleInfoWindow(warehouse: Warehouse) {
            const available = this.isAvailable(warehouse);
            const availabilities = this.warehouseAvailability(warehouse);
            let message: string | null = null;

            if (availabilities?.length) {
                message = availabilities[0].message?.additif;
            }

            this.infoContent = {
                warehouse: warehouse,
                available,
                message,
                position: {
                    lat: warehouse.geo_lat,
                    lng: warehouse.geo_lon
                }
            };

            this.infoWindowOpened = true;
        },
        /**
     * Get the members for a warehouse.
     *
     * @param {Object} warehouse
     */
        warehouseMembers(warehouse: Warehouse): HealthcenterGroupMember[] {
            if (!this.data) {
                return [];
            }

            return this.data?.filter(datum => datum.warehouse.id === warehouse.id)
                .map(datum => datum.members)
                .flat();
        }
    }
});
</script>
