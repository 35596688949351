<template>
  <interview-container class="ml-4">
    <h2 class="text-black">
      Déroulé de l'entretien
    </h2>
    <p>
      Aidez-vous des ressources disponibles dans le panel de droite 👉 et suivez les consignes ci-dessous 👇
    </p>
    <div class="flex flex-col gap-5 mt-3">
      <div
        v-for="guideline in guidelines"
        :key="guideline.id"
        class="flex items-start"
      >
        <input
          type="checkbox"
          class="w-6 h-6 mr-3 text-blue-600 bg-gray-100 border-gray-300 rounded mt-1 focus:ring-0 focus:ring-primary"
          :name="guideline.id"
        >
        <label :for="guideline.id">{{ guideline.name }}
          <v-tooltip
            v-if="guideline.description?.length"
            :text="guideline.description"
            location="bottom"
          >
            <template #activator="{ props }">
              <icon v-bind="props" class="h-6 w-6 cursor-pointer" color="primary" name="mdi-information" />
            </template>
          </v-tooltip>
        </label>
      </div>
    </div>
  </interview-container>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import InterviewContainer from '@/interview/components/instance-modal/InterviewContainer.vue';
import Icon from "@/core/components/icon/Icon.vue";

export default defineComponent({
    name: 'InterviewGuidelines',
    components: {Icon, InterviewContainer},
    props: {
        guidelines: {
            type: Array<any>,
            required: true
        }
    }
});
</script>
