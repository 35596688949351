<template>
  <div :key="componentKey">
    <div class="px-4 pt-4">
      <div class="flex flex-row justify-between items-center">
        <h1>{{ title }}</h1>
        <div>
          <button
            :disabled="currentSlide === 0 || allSlidesVisible()"
            @click="previous()"
          >
            <img
              v-if="currentSlide > 0 && !allSlidesVisible()"
              :src="arrowLeft"
            >
          </button>
          <button
            :disabled="currentSlide === lastSlide || allSlidesVisible()"
            @click="next()"
          >
            <img
              v-if="hasSlides() && !allSlidesVisible()"
              :src="arrowRight"
            >
          </button>
        </div>
      </div>
    </div>
    <ul
      v-if="hasSlides()"
      :id="'slider'"
      class="flex flex-row overflow-hidden pl-5 pb-6"
    >
      <li
        v-for="(slide, index) in slides.data"
        :id="'slide-' + index"
        :key="index"
        class="mr-6 px-1"
      >
        <component
          :is="slides.component"
          v-bind="{[slides.prop]: slide}"
        />
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import domHelper from '@/core/helpers/domHelper';
import BaseSpinner from '@/core/components/base/spinner/BaseSpinner.vue';
import arrowRight from '@/assets/icons/arrow-right.svg';
import arrowLeft from '@/assets/icons/arrow-left.svg';

/**
 * @component SliderComponent
 * @description A reusable slider component for displaying a series of slides with navigation.
 *
 * @example
 * <slider-component :slides="slideData" title="My Slider" />
 *
 * where slideData is an object:
 * {
 *   data: Array, // The array of slide data
 *   component: Component, // The Vue component to render for each slide
 *   prop: String // The name of the prop to pass the slide data to in the component
 * }
 */
export default defineComponent({
    name: 'SliderComponent',
    components: {BaseSpinner},
    props: {
    /**
     * The slides data object containing the slides to display.
     * @type {Object}
     * @property {Array} data - The array of slide data.
     * @property {Component} component - The Vue component to render for each slide.
     * @property {String} prop - The name of the prop to pass the slide data to in the component.
     */
        slides: {
            type: Object,
            required: true
        },
        /**
     * The title of the slider.
     * @type {String}²
     */
        title: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            currentSlide: 0 as number,
            componentKey: 0,
            arrowRight,
            arrowLeft
        };
    },
    computed: {
    /**
     * Computes the index of the last slide.
     * @returns {number} The index of the last slide or 0 if there are no slides.
     */
        lastSlide(): number {
            return this.hasSlides() ? this.slides.data.length - 1 : 0;
        }
    },
    created() {
        window.addEventListener('resize', this.update);
    },
    mounted() {
        this.update();
    },
    unmounted() {
        window.removeEventListener('resize', this.update);
    },
    methods: {
    /**
     * Advances to the next slide if possible.
     */
        next() {
            if (this.allSlidesVisible() || this.currentSlide === this.lastSlide) {
                return;
            }
            this.currentSlide = Math.min(this.currentSlide + 1, this.lastSlide);
            this.scrollTo(this.currentSlide);
        },
        /**
     * Moves to the previous slide if possible.
     */
        previous() {
            if (this.currentSlide <= 0) {
                return;
            }
            this.currentSlide--;
            this.scrollTo(this.currentSlide);
        },
        /**
     * Scrolls to a specific slide.
     * @param {number} slide - The index of the slide to scroll to.
     */
        scrollTo(slide: number) {
            domHelper.scrollIntoView('slide-' + slide, {
                behavior: 'smooth',
                block: 'nearest',
                inline: 'start'
            });
        },
        /**
     * Checks if there are any slides to display.
     * @returns {boolean} True if there are slides, false otherwise.
     */
        hasSlides(): boolean {
            return !!this.slides && !!this.slides.data && this.slides.data.length > 0;
        },
        /**
     * Checks if all slides are currently visible in the viewport.
     * @returns {boolean} True if all slides are visible, false otherwise.
     */
        allSlidesVisible(): boolean {
            const slider = document.getElementById('slider');
            const lastSlide = document.getElementById('slide-' + this.lastSlide);

            if (!slider || !lastSlide) {
                return false;
            }

            return lastSlide.offsetLeft + lastSlide.offsetWidth <= slider.offsetLeft + slider.offsetWidth;
        },
        /**
     * Updates the component key to force a re-render.
     * This is useful when the viewport size changes.
     */
        update() {
            this.componentKey = (this.componentKey + 1) % 1001;
        }
    }
});
</script>
