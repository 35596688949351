<template>
  <div class="relative flex flex-col">
    <BaseCard class="bg-orange pb-4">
      <BaseCardBody>
        <div class="flex col gap-10">
          <span class="w-3/4 text-primary font-content">
            {{ customer.information.name }} n'a pas l'application Apodis pour les patients.
            <br>
            Afin de gérer la relation avec votre patientèle et bénéficier pleinement de nos services, cliquez sur
            <span class="font-content font-bold">Connecter {{ customer.information.first_name }}.</span>
          </span>
          <div class="flex-col">
            <img
              class="absolute w-36 z-5 -mt-10"
              src="@/assets/images/pharmacist.png"
              alt="Pharmacien"
            >
          </div>
        </div>
      </BaseCardBody>
    </BaseCard>
    <div class="flex flex-1 w-full items-end justify-end">
      <div class="absolute z-1 -bottom-4 right-5">
        <CustomerPhoneOnboardingDialog :customer="customer" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from "vue";
import BaseCard from '@/core/components/base/BaseCard.vue';
import BaseCardBody from '@/core/components/base/BaseCardBody.vue';
import loaderGif from '@/assets/loader.gif';
import CustomerPhoneOnboardingDialog from "@/customer-certification/components/CustomerPhoneOnboardingDialog.vue";
import Customer from "@/customer/interfaces/Customer";

export default defineComponent({
    components: {
        CustomerPhoneOnboardingDialog,
        BaseCardBody,
        BaseCard
    },
    props: {
        customer: {
            type: Object as PropType<Customer>,
            required: true
        }
    },
    data() {
        return {
            downloading: false,
            loaderGif
        };
    },
});
</script>
