<template>
  <v-stepper
    v-model="stepIndex"
    class="w-full"
    editable
    color="primary"
  >
    <v-stepper-header>
      <v-stepper-item
        :value="1"
        title="Apodis Secure"
        color="primary"
        edit-icon=""
        :complete="isApodisSecureCompleted"
      />
      <v-stepper-item
        :value="2"
        title="Apodis Pro"
        color="primary"
        edit-icon=""
        :complete="isApodisProCompleted"
      />
      <v-stepper-item
        :value="3"
        title="Apodis Connect"
        color="primary"
        edit-icon=""
        :complete="isApodisConnectCompleted"
      />
    </v-stepper-header>
    <v-stepper-window>
      <v-stepper-window-item :value="1">
        <apodis-secure-step
          :lgo-name="lgoName"
          :lgo-install="lgoInstall"
          @download-apodis-secure="downloadApodisSecure"
          @open-guide="$emit('openGuide', santeSecureGuideId)"
        />
      </v-stepper-window-item>
      <v-stepper-window-item :value="2">
        <apodis-pro-step
          @open-app-store="openAppStore"
          @open-play-store="openPlayStore"
          @open-guide="$emit('openGuide', apodisProGuideId)"
        />
      </v-stepper-window-item>
      <v-stepper-window-item :value="3">
        <apodis-connect-step
          @download-apodis-connect="downloadApodisConnect"
          @open-guide="openGuide(apodisConnectGuideId)"
        />
      </v-stepper-window-item>
    </v-stepper-window>
  </v-stepper>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import ApodisSecureStep from '@/onboarding/components/installation-process/ApodisSecureStep.vue';
import ApodisConnectStep from '@/onboarding/components/installation-process/ApodisConnectStep.vue';
import ApodisProStep from '@/onboarding/components/installation-process/ApodisProStep.vue';
import {contentfulService} from '@/container';
import {mapState} from "pinia";
import {useWebsiteStore} from "@/stores/website.store";

export default defineComponent({
    name: 'InstallationProcessTab',
    components: {ApodisProStep, ApodisConnectStep, ApodisSecureStep},
    props: {
        accessPoint: {
            type: Object,
            required: false,
            default: null
        }
    },
    emits: ['openGuide'],
    data: () => ({
        santeSecureGuideId: contentfulService().getSanteSecureGuideId(),
        apodisProGuideId: contentfulService().getProGuideId(),
        apodisConnectGuideId: contentfulService().getConnectGuideId(),
        stepIndex: 1
    }),
    computed: {
        ...mapState(useWebsiteStore, ['santeSecure', 'hosts', 'mobiles']),
        isApodisSecureCompleted() {
            return this.santeSecure && !!this.santeSecure.lastLgoPing;
        },
        isApodisConnectCompleted() {
            return this.hosts && this.hosts.length > 0;
        },
        isApodisProCompleted() {
            return this.mobiles && this.mobiles.length > 0;
        },
        currentStepIndex() {
            if (!this.isApodisSecureCompleted) {
                return 1;
            }

            if (!this.isApodisProCompleted) {
                return 2;
            }

            if (!this.isApodisConnectCompleted) {
                return 3;
            }

            return 3;
        },
        lgoName: function () {
            const lgo = this.accessPoint && this.accessPoint.entity.lgo;
            switch (lgo) {
            case 1:
                return 'Actipharm (MSI 2000)';
            case 2:
                return 'Alliadis / SmartRX';
            case 3:
                return 'Axiopharm';
            case 4:
                return 'Caduciel';
            case 5:
                return 'CIP Primoris';
            case 6:
                return 'CIP-GS (Pharmagest)';
            case 7:
                return 'Crystal (Infosoft)';
            case 8:
                return 'Data conseil (Alliadis)';
            case 9:
                return 'Esculape';
            case 10:
                return 'LEO Isipharm';
            case 11:
                return 'LGPI (Pharmagest)';
            case 12:
                return 'Logipharm';
            case 13:
                return 'Magic Vente';
            case 14:
                return 'Opus (PG Informatique)';
            case 15:
                return 'Pharmaland';
            case 16:
                return 'Pharmavitale Cepi';
            case 17:
                return 'Primoris (Pharmagest)';
            case 18:
                return 'Périphar (Aspline)';
            case 19:
                return 'Vindilis';
            case 20:
                return 'Visiosoft (Visiopharm)';
            case 21:
                return 'Winpharma (Everys)';
            default:
                return '';
            }
        },
        lgoInstall: function () {
            const lgo = this.accessPoint && this.accessPoint.entity.lgo;
            switch (lgo) {
            case 11: // LGPI
            case 21: // Winpharma
                return 'client';
            case 10: // LEO
            case 16: // Pharmavitale
                return 'server';
            case 2: // SmartRX
            case 15: // Pharmaland
            default:
                return 'support';
            }
        }
    },
    watch: {
        currentStepIndex: {
            immediate: true,
            handler(value) {
                this.stepIndex = value;
            }
        }
    },
    methods: {
        downloadApodisSecure() {
            const link = import.meta.env.VITE_APODISSECURE_EXE_URL;
            window.open(link);
        },
        downloadApodisConnect() {
            const link = import.meta.env.VITE_CONNECT_EXE_URL;
            window.open(link);
        },
        openAppStore() {
            const link = import.meta.env.VITE_PRO_APPSTORE_URL;
            window.open(link);
        },

        openPlayStore() {
            const link = import.meta.env.VITE_PRO_PLAYSTORE_URL;
            window.open(link);
        },
        openGuide(guideId) {
            this.$emit('openGuide', guideId);
        }
    }
});
</script>

<style scoped>
.v-stepper {
    border-radius: 8px;
    box-shadow: none !important;
}

:deep(.v-stepper-window) {
    margin: 0;
}
</style>
