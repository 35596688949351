<template>
  <interview-short-cut-container title="Pour le patient">
    <program-attachment-grid
      v-if="patientAttachments&&interview"
      :attachments="patientAttachments"
      :attachable-id="interview.id"
      attachable-type="interviews"
    />
  </interview-short-cut-container>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import ProgramAttachmentGrid from '@/program/components/program-attachment/ProgramAttachmentGrid.vue';
import InterviewShortCutContainer
    from '@/interview/components/instance-modal/short-cuts/InterviewShortCutContainer.vue';
import {mapState} from "pinia";
import {useInterviewStore} from "@/stores/interview-store";

export default defineComponent({
    name: 'InterviewPatientAttachments',
    components: {InterviewShortCutContainer, ProgramAttachmentGrid},
    computed: {
        ...mapState(useInterviewStore, ['interview', 'patientAttachments'])
    }
});
</script>
