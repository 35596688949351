<template>
  <button
    class="flex card shadow-md p-4 bg-white gap-4 rounded-md items-center"
    @click="$router.push({name: 'disruption-logistics.wholesaler-warehouse-healthcenter'})"
  >
    <v-icon
      size="40"
      color="black"
    >
      mdi-warehouse
    </v-icon>
    <span class="flex flex-col justify-start text-start w-full">
      <span class="text-lg">
        Gérer mes agences
      </span>
      <span v-if="loading">
        <v-skeleton-loader
          class="loader-warehouse-card w-full max-w-lg top-1"
          height="20"
          type="text"
        />
      </span>
      <span
        v-else-if="!firstWarehouse || !secondWarehouse"
        class="text-primary"
      >
        Pensez à sélectionner vos agences 😇
      </span>
      <span
        v-else
        class="text-primary"
      >
        #1 {{ firstWarehouse.name }}, #2 {{ secondWarehouse.name }}
      </span>
    </span>
  </button>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import {wholesalerApi} from "@/container.js";

import {WholesalerWarehouseHealthcenter} from "@/disruption-logistics/interfaces/wholesalers";

export default defineComponent({
    name: 'HealthcenterWarehousesCard',
    data() {
        return {
            healthcenterWarehouses: null as null | WholesalerWarehouseHealthcenter[],
            loading: false
        };
    },
    computed: {
    /**
     * Get the healthcenter warehouse rank 1.
     */
        firstWarehouse(): WholesalerWarehouseHealthcenter | undefined {
            if (!this.healthcenterWarehouses) {
                return undefined;
            }

            return this.healthcenterWarehouses.find(warehouse => warehouse.rank === 1);
        },
        /**
     * Get the healthcenter warehouse rank 2.
     */
        secondWarehouse(): WholesalerWarehouseHealthcenter | undefined {
            if (!this.healthcenterWarehouses) {
                return undefined;
            }

            return this.healthcenterWarehouses.find(warehouse => warehouse.rank === 2);
        }
    },
    mounted() {
        this.loading = true;
        wholesalerApi()
            .getCurrentHealthcenterWarehouses()
            .then(healthcenterWarehouses => {
                this.healthcenterWarehouses = healthcenterWarehouses;
            })
            .finally(() => {
                this.loading = false;
            });
    }
});
</script>

<style>
.loader-warehouse-card > .v-skeleton-loader__text {
  margin: 0;
}
</style>
