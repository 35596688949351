<script setup lang="ts">
import {MassCertificationCustomer} from "@/core/interfaces/MassCertification";
import CommunicationCustomerBaseInformation from "@/communication/components/CommunicationCustomerBaseInformation.vue";

defineProps<{ customer: MassCertificationCustomer }>();
</script>

<template>
  <div class="flex flex-1 flex-row items-center p-4">
    <CommunicationCustomerBaseInformation :customer="customer" is-notification />
    <div class="flex flex-0.5 basis-1/6 items-center mx-4">
      <p v-if="customer.visits">
        {{ customer.visits }} <span> {{ $filters.pluralize("Visite", customer.visits) }}</span>
      </p>
    </div>
    <div class="flex flex-1">
      <div v-if="customer.lastVisit" class="flex flex-col">
        <span>Dernière visite</span>
        <span class="font-bold text-sm"> {{ $filters.date(customer.lastVisit, 'short') }}</span>
      </div>
    </div>
    <div class="flex flex-2 lg:flex-1">
      <div v-if="customer.lastMessageDate" class="flex flex-col">
        <span>Dernière notification envoyée</span>
        <span class="font-bold text-sm">{{ $filters.date(customer.lastMessageDate, 'short') }}</span>
      </div>
    </div>
  </div>
</template>
