/**
 * Currencyze.
 *
 * @param {number} number - The number to currencyze.
 * @param {number} decimal - The number of decimal.
 *
 * @returns {string}
 */
export const currencyze = (number?: number | string): string => {
    if (number !== undefined) {
        if (typeof number !== "number") {
            number = parseFloat(number);
        }

        if (!isNaN(number)) {
            const price = number.toLocaleString("fr-FR", {style: "currency", currency: "EUR"});

            return spaceOut(price);
        }
    }

    return "N/A";
};

/**
 * Return a number string with space between thousands.
 */
const spaceOut = (number: number | string) => {
    const parts = number.toString().split(".");
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");

    return parts.join(".");
};
