<template>
  <div
    class="w-full pl-5"
    @mouseenter="onHoverStart"
    @mouseleave="onHoverEnd"
  >
    <div
      class="shadow-custom cursor-pointer rounded-md flex-row flex items-center relative pl-7 pr-2 h-auto w-full py-5 z-10"
    >
      <img
        v-if="program.logo"
        :src="`${program.logo.url}&h=400`"
        :alt="program.name"
        class="absolute"
        style="height:50px; width:50px; left:-25px; border-radius: 10px;"
      >
      <div
        v-else
        class="items-center justify-center flex bg-gray-200 absolute"
        style="height:50px; width:50px; left:-25px; border-radius: 10px;"
      >
        {{ program.id }}
      </div>
      <div class="text-sm mx-1 flex-1">
        <program-teaser-text :program="program" />
      </div>
      <div class="mx-1">
        <chevron-right-icon class="h-4 w-4 text-gray-400" />
      </div>
      <div class="absolute right-2 -bottom-3.5 drop-shadow-md z-10 max-w-full teaser-action">
        <program-teaser-action
          :clamp="true"
          :program="program"
        />
      </div>
    </div>
    <notification-program-feedback
      v-if="isSupportProgram && isHovered"
      :consumer-key="consumer.key"
      :program="program"
    />
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import {pathwayApi} from '@/container';
import ProgramTeaserText from '@/program/components/program-teaser/ProgramTeaserText.vue';
import ProgramTeaserAction from '@/program/components/program-teaser/ProgramTeaserAction.vue';
import ChevronRightIcon from '@/core/components/icons/ChevronRightIcon.vue';
import NotificationProgramFeedback
    from "@/notification/components/notification-detection/programs/NotificationProgramFeedback.vue";

export default defineComponent({
    name: 'NotificationProgramLogoAdvanced',
    components: {
        NotificationProgramFeedback,
        ChevronRightIcon,
        ProgramTeaserAction,
        ProgramTeaserText
    },
    props: {
        program: {
            type: Object,
            required: true
        },
        customer: {
            type: Object,
            required: true
        },
        consumer: {
            type: Object,
            required: true
        }
    },
    emits: ['resize'],
    data() {
        return {
            isHovered: false,
            timeout: null as NodeJS.Timeout | null
        };
    },
    computed: {
    /**
     * Determine if the program is a support program.
     *
     * @return {boolean}
     */
        isSupportProgram() {
            return this.program.programmable_type === 'support';
        }
    },
    mounted() {
        if (this.program.next_step) {
            pathwayApi().track('notification-show', this.program.next_step.pathway_id, this.consumer.key);
        }
    },
    methods: {
    /**
     * On hover start, set a timeout to display the exclusion toggler.
     */
        onHoverStart() {
            this.timeout = setTimeout(() => {
                this.isHovered = true;
                this.$emit('resize');
            }, 200);
        },
        /**
     * On hover end, clear the timeout if overed is not true otherwise and set the hovered state to true.
     */
        onHoverEnd() {
            if (!this.isHovered && this.timeout) {
                clearTimeout(this.timeout);
            } else {
                this.isHovered = false;
                this.$emit('resize');
            }
        }
    }
});
</script>

<style scoped>
:deep(.teaser-action button) {
    @apply line-clamp-1;
}
</style>
