<template>
  <div
    class="overflow-hidden shadow rounded-lg"
    :class="props.color"
  >
    <slot />
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
    color: {
        type: String,
        default: "bg-white"
    }
});
</script>