<template>
  <div class="h-screen overflow-hidden bg-primary-lightest flex flex-row">
    <BaseFlexSpinner v-if="loading" />
    <div v-else class="min-h-0 flex-1 flex overflow-hidden">
      <main class="min-w-0 flex-1 lg:flex">
        <section
          aria-labelledby="primary-heading"
          class="min-w-0 flex-1 h-full flex flex-col overflow-auto lg:order-last"
        >
          <router-view />
        </section>
      </main>
    </div>
    <TheSanteSecureMenu v-if="!loading" />
    <TheHubspotChat />
    <TheHubspotAppointment />
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import {
    environmentApi,
    authenticationService
} from '@/container';

import TheHubspotChat from '@/onboarding/components/hubspot/TheHubspotChat.vue';
import TheHubspotAppointment from '@/onboarding/components/hubspot/TheHubspotAppointment.vue';
import TheSanteSecureMenu from "@/core/components/navigation-bar/TheSanteSecureMenu.vue";
import {mapActions, mapWritableState} from "pinia";
import {useWebsiteStore} from "@/stores/website.store";
import {useSystemStore} from "@/stores/system.store";
import BaseFlexSpinner from "@/core/components/base/spinner/BaseFlexSpinner.vue";

export default defineComponent({
    components: {
        BaseFlexSpinner,
        TheSanteSecureMenu,
        TheHubspotChat,
        TheHubspotAppointment
    },
    emits: ['hook:beforeDestroy'],
    data() {
        return {
            loading: false,
        };
    },
    computed: {
        ...mapWritableState(useSystemStore, ['environment'])
    },
    mounted() {
        const intervalId = setInterval(() => {
            this.fetchData();
        }, 1000 * 60);

        this.$emit('hook:beforeDestroy', () => {
            clearInterval(intervalId);
        });

        if (!authenticationService().authenticated) {
            this.$router.push({
                name: 'login'
            });
        } else if ((authenticationService().access && (authenticationService().access.cps === '' || authenticationService().access.cps === null))) {
            this.$router.push({
                name: 'linkcps'
            });
        } else {
            this.fetchData();

            this.loading = true;
            environmentApi()
                .read()
                .then(environment => {
                    this.environment = environment;
                })
                .finally(() => {
                    this.loading = false;
                });
        }
    },
    methods: {
        ...mapActions(useWebsiteStore, ['fetchData'])
    }
});
</script>
