<template>
  <BaseContainer>
    <BaseFlexSpinner v-if="isLoading" />
    <div
      v-else-if="program"
      class="flex flex-1 flex-col"
    >
      <div class="bg-white shadow-md z-10 mb-2">
        <program-header :program="program" class="p-3" />
        <nav
          v-if="!!program"
          class="flex border-collapse space-x-2"
          aria-label="Tabs"
        >
          <router-link
            :to="{ name: 'program.detail.content' }"
            active-class="nav-item-active"
            class="nav-item"
          >
            Programme
          </router-link>
          <router-link
            :to="{ name: 'program.detail.consumers'}"
            active-class="nav-item-active"
            class="nav-item"
          >
            Patients concernés
          </router-link>
          <router-link
            v-if="program.attachments_count && program.attachments_count > 0"
            :to="{ name: 'program.detail.attachments'}"
            active-class="nav-item-active"
            class="nav-item"
          >
            Informations et ressources
          </router-link>
          <router-link
            v-if="program.programmable_type === 'support'"
            :to="{ name: 'program.detail.pathway' }"
            active-class="nav-item-active"
            class="nav-item"
          >
            Parcours de soin
          </router-link>
          <router-link
            v-if="program.programmable_type === 'loyalty-card'"
            :to="{ name: 'program.detail.animations', params: {loyaltyCardId: program.programmable_id} }"
            active-class="nav-item-active"
            class="nav-item"
          >
            Animations
          </router-link>
        </nav>
      </div>
      <router-view :key="$route.path" :program="program" />
    </div>
  </BaseContainer>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import {programApi} from '@/container';
import BaseContainer from '@/core/components/base/BaseContainer.vue';
import BaseFlexSpinner from '@/core/components/base/spinner/BaseFlexSpinner.vue';
import type {Program} from "@/program/interfaces/Program";
import ProgramHeader from "@/program/components/program-header/ProgramHeader.vue";

export default defineComponent({
    name: 'ProgramDetails',
    components: {
        ProgramHeader,
        BaseFlexSpinner,
        BaseContainer
    },
    data() {
        return {
            isLoading: true,
            program: null as Program | null
        };
    },
    watch: {
        '$route.params.programId': {
            immediate: true,

            handler(value) {
                this.read(value);
            }
        }
    },
    methods: {
        read(id: number) {
            this.isLoading = true;

            programApi()
                .read(id)
                .then(program => {
                    this.program = program;
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },
        refresh() {
            this.$router.go(0);
        }
    }
});
</script>

<style scoped>
.nav-item {
    @apply border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300 whitespace-nowrap py-2 px-3 border-b-2 font-medium
}

.nav-item-active {
    @apply border-primary text-primary
}
</style>
