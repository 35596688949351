export default function (hdsClient) {
    return {
    /**
     * Index carts for the consumer.
     *
     * @param {String} consumerKey
     * @param {Object} afterKey
     *
     * @returns {Promise<Object>}
     */
        index (consumerKey, afterKey = null) {
            const params: {limit: number, after_key?: string} = {
                limit: 5
            };

            if (afterKey) {
                params.after_key = JSON.stringify(afterKey);
            }

            return hdsClient.get(`/consumers/${consumerKey}/carts`, { params })
                .then(response => response.data);
        }
    };
}
