<script setup lang="ts">
import BaseFlexSpinner from "@/core/components/base/spinner/BaseFlexSpinner.vue";
import {ref, onMounted} from "vue";
import {healthcenterApi} from "@/container";
import {useSystemStore} from "@/stores/system.store";
import {Healthcenter} from "@/core/interfaces/healthcenter/Healthcenter";
import HealthcenterInformation from "@/healthcenter/components/HealthcenterInformation.vue";
import {useToastStore} from "@/stores/toast.store";

const loading = ref(true);
const healthcenter = ref<Healthcenter | null>(null);
const {environment} = useSystemStore();
const {showSuccess} = useToastStore();

/**
 * On mount read healthcenter information.
 */
onMounted(() => {
    if (environment?.entityId) {
        healthcenterApi().read(environment.entityId)
            .then(response => {
                healthcenter.value = response;
            })
            .finally(() => loading.value = false);
    }
});

/**
 * Update healthcenter information.
 *
 * @param formData
 */
const onInformationUpdate = (formData: { name?: string; url?: string; contacts?: any[] }) => {
    if (environment?.entityId) {
        healthcenterApi().update(environment.entityId, formData)
            .then(response => {
                healthcenter.value = response.healthcenter;
                showSuccess({content: "Les informations de la pharmacie ont bien été mises à jour."});
            });
    }
};

</script>

<template>
  <BaseFlexSpinner v-if="loading" />
  <div v-else class="p-4">
    <HealthcenterInformation v-if="healthcenter" :healthcenter="healthcenter" @update="onInformationUpdate" />
  </div>
</template>
