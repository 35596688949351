import {FileManagerFile} from "../FileManagerFile";

export enum QuestionType {
    SINGLE_CHOICE = "single_choice",
    MULTIPLE_CHOICE = "multiple_choice",
    YES_NO = "yes_no",
    TEXT_INPUT = 'text_input',
    DATE = 'date'
}

export interface SurveyIndex {
    /** Survey id. */
    id: number;
    /** Survey slug. */
    slug: string;
    /** Survey name. */
    name: string;
    /** Survey emoji. */
    emoji?: string;
    /** Survey theme. */
    theme?: string;
    /** Survey description. */
    description: string;
    /** Survey file image. */
    file: FileManagerFile | null;
}

export interface Survey extends SurveyIndex {
    /** Survey sections. */
    sections?: SurveySection[];
    /** Survey creation date. */
    created_at: string;
    /** Survey last update date. */
    updated_at: string;
}

export interface SurveySection {
    /** Survey section id. */
    id: number;
    /** Survey section title id. */
    title: string;
    /** Survey section questions. */
    questions?: SurveyQuestion[];
    /** Survey section creation date. */
    created_at: string;
    /** Survey section last update date. */
    updated_at: string;
}

export interface SurveyQuestion {
    /** Survey question id. */
    id: number;
    /** Survey question title. */
    title: string;
    /** Survey question description. */
    description: string;
    /** Survey question type. */
    type: SurveyQuestionType;
    /** List of all the possible answers of the survey question. */
    answers?: SurveyQuestionAnswer[];
    /** Previous answer to the question registered in the current instance (null if there is none). */
    user_answer: {
        value: string[];
    } | null;
    /** Is optional ? */
    optional: boolean;
    /** Survey question creation date. */
    created_at: string;
    /** Survey question last update date. */
    updated_at: string;
}

export interface SurveyQuestionType {
    /** Survey question type id. */
    id: number;
    /** Survey question type name. */
    name: string;
    /** Survey question type slug. */
    slug: QuestionType;
    /** Survey question type strategy. */
    strategy: string;
}

export interface SurveyQuestionAnswer {
    /** Survey question answer value. */
    value: (number | boolean);
    /** Content of the survey question answer. */
    label: string;
    /** Color of the answer. */
    color?: string;
    /** Illustration path of the answer. */
    image?: string;
}

export interface PrefilledSurveyAnswer {
    /** Survey question id. */
    question_id: number;
    /** Survey answer unserialized value. */
    answer: Array<number | boolean | string>;
    /** Prefill reason. */
    reason: string;
}

export interface SurveyVerdict {
    /** Survey verdict title. */
    title: string;
    /** Survey verdict score. */
    score: number;
    /**
     *  Value used to evaluate the score (for example in the case of a survey, that has the purpose to find if you are
     *  late for a vaccine injection, true would mean you need to do it, false that it's done).
     */
    verdict: boolean;
    /** Survey verdict description. */
    description: string;
    /** Survey verdict marketing information. */
    marketingInformation?: string;
    /** The survey belonging to the instance of the verdict. */
    survey?: SurveyIndex;
    /** Options specific to this survey verdict. */
    options?: {
        isScoreEssential: boolean;
    };
}

export interface SurveyAnswerInstance {
    /** If if the survey answer instance. */
    id: number;
    /** Consumer key of the person who is providing the answers. */
    consumer_key: string;
    /** Type of the person who is filling the answers. */
    filler_type: string;
    /** Id of the person who is filling the answers. */
    filler_id: number;
    /** The survey associated with the answer instance. */
    survey: Survey;
    /** The survey verdict of the survey answer instance. */
    verdict?: SurveyVerdict;
    /** Survey answer instance creation date. */
    created_at: string;
}
