import {AUTO_COMPLETE_SECTION, AutoCompleteSection} from "@/core/interfaces/search-bar";
import {
    AUTO_COMPLETE_REQUEST_TYPE,
    PRODUCT_SEARCH_QUERY_PARAM_KEYS
} from "@/core/interfaces/search-bar/ISearchBarAutoComplete";

/**
 * The auto complete sections.
 *
 * @type {Record<string, AutoCompleteSection>}
 */
const sections: Record<AUTO_COMPLETE_SECTION, AutoCompleteSection> = {
    [AUTO_COMPLETE_SECTION.CATEGORY]: {
        name: "Catégories",
        color: "#E2C4FF",
        requestType: AUTO_COMPLETE_REQUEST_TYPE.CATEGORY,
        position: 3,
        queryParam: PRODUCT_SEARCH_QUERY_PARAM_KEYS.CATEGORIES
    },
    [AUTO_COMPLETE_SECTION.PRODUCT]: {
        name: "Produits",
        color: "#C4E8FB",
        requestType: AUTO_COMPLETE_REQUEST_TYPE.PRODUCT,
        position: 1,
        queryParam: PRODUCT_SEARCH_QUERY_PARAM_KEYS.PRODUCTS
    },
    [AUTO_COMPLETE_SECTION.LABORATORY]: {
        name: "Laboratoires",
        color: "#ADD3FF",
        requestType: AUTO_COMPLETE_REQUEST_TYPE.LABORATORY,
        position: 2,
        queryParam: PRODUCT_SEARCH_QUERY_PARAM_KEYS.LABORATORIES
    },
    [AUTO_COMPLETE_SECTION.GENERIC]: {
        name: "Groupe générique",
        color: "#D6BFD6",
        requestType: AUTO_COMPLETE_REQUEST_TYPE.GENERIC,
        position: 4,
        queryParam: PRODUCT_SEARCH_QUERY_PARAM_KEYS.GENERICS_GROUP
    },
    [AUTO_COMPLETE_SECTION.RANGES]: {
        name: "Gammes",
        color: "#C2B3FA",
        requestType: AUTO_COMPLETE_REQUEST_TYPE.RANGE,
        position: 3,
        queryParam: PRODUCT_SEARCH_QUERY_PARAM_KEYS.RANGES
    },
    [AUTO_COMPLETE_SECTION.PHARMAS]: {
        name: "Pharmacies",
        color: "#4BAF87",
        requestType: AUTO_COMPLETE_REQUEST_TYPE.HEALTHCENTER,
        position: 5,
        queryParam: PRODUCT_SEARCH_QUERY_PARAM_KEYS.HEALTHCENTERS,
        isGeoFilter: true
    },
    [AUTO_COMPLETE_SECTION.REGION]: {
        name: "Regions",
        color: "#E2ADA2",
        requestType: AUTO_COMPLETE_REQUEST_TYPE.REGION,
        position: 6,
        queryParam: PRODUCT_SEARCH_QUERY_PARAM_KEYS.REGIONS,
        isGeoFilter: true
    },
    [AUTO_COMPLETE_SECTION.DEPARTMENT]: {
        name: "Départements",
        color: "#E2ADA2",
        requestType: AUTO_COMPLETE_REQUEST_TYPE.DEPARTMENT,
        position: 7,
        queryParam: PRODUCT_SEARCH_QUERY_PARAM_KEYS.DEPARTMENTS,
        isGeoFilter: true
    },
    [AUTO_COMPLETE_SECTION.CITY]: {
        name: "Villes",
        color: "#E2ADA2",
        requestType: AUTO_COMPLETE_REQUEST_TYPE.CITY,
        position: 8,
        queryParam: PRODUCT_SEARCH_QUERY_PARAM_KEYS.CITIES,
        isGeoFilter: true
    },
    [AUTO_COMPLETE_SECTION.UGA]: {
        name: "UGA",
        color: "#E2ADA2",
        requestType: AUTO_COMPLETE_REQUEST_TYPE.UGA,
        position: 9,
        queryParam: PRODUCT_SEARCH_QUERY_PARAM_KEYS.UGAS,
        isGeoFilter: true
    },
    [AUTO_COMPLETE_SECTION.SEGMENT_PRODUCT]: {
        name: "Segments produit",
        color: "#E2ADA2",
        requestType: AUTO_COMPLETE_REQUEST_TYPE.SEGMENT_PRODUCT,
        position: 10,
        queryParam: PRODUCT_SEARCH_QUERY_PARAM_KEYS.SEGMENT_PRODUCTS,
        isGeoFilter: false
    }
};

/**
 * The default auto complete result size.
 *
 * @type {number}
 */
export const DEFAULT_AUTO_COMPLETE_RESULT_SIZE: number = 5;

export default sections;
