import {AxiosInstance} from "axios";

/**
 * The loyalty card membership credit resource.
 * @author Tony Laurent <t.laurent@apodispharma.com>
 * @param {Object} client - The injected client.
 * @returns {Object} The public object.
 */
export default function (client: AxiosInstance) {
    return {
    /**
     * Create a loyalty card membership credit.
     * @param {number} customerId - The ID of the customer.
     * @param {number} membershipId - The ID of the membership.
     * @param {Object} data -
     * @returns {Promise} Promise object represents the HTTP request.
     */
        create (customerId: number, membershipId: number, data: unknown) {
            return client
                .post(`customers/${customerId}/loyalty-card-memberships/${membershipId}/credits`, {
                    data
                })
                .then(response => response.data.data);
        }
    };
}
