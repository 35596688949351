<template>
  <iframe
    allowfullscreen
    frameborder="0"
    class="w-full h-96"
    title="YouTube video player"
    :src="`https://www.youtube.com/embed/${reference}`"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
  />
</template>

<script lang="ts">
import {defineComponent} from "vue";
export default defineComponent({
    props: {
        reference: {
            type: String,
            required: true
        }
    }
});
</script>
