<template>
  <div class="flex flex-1 flex-col">
    <h2 class="text-white">
      {{ label }}
    </h2>
    <p class="text-sm">
      {{ value || '_' }}
    </p>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
export default defineComponent({
    name: 'CustomerInformationBlock',
    props: {
        label: {
            type: String,
            required: true
        },
        value: {
            type: String,
            required: false,
            default: '_'
        }
    }
});
</script>
