<script setup lang="ts">
import {computed} from "vue";
import {Extension, ExtensionType} from "@/core/interfaces/extension/Extension";
import Logo from "@/core/components/logo/BaseLogo.vue";

const props = defineProps<{
    extension: Extension;
}>();

const isLoyaltyCard = computed(() => {
    return props.extension.extendable_type === ExtensionType.PROGRAM_BUNDLE
    && props.extension.sub_type === 'loyalty-card';
});
</script>

<template>
  <Logo
    :logo="extension.logo" :alt="extension.name" :class="{
      '!aspect-[16/9]': isLoyaltyCard,
    }"
  >
    <template #fallback>
      <div class="flex-1 items-center justify-center flex bg-white">
        <h1>{{ extension.name[0] ?? "E" }}</h1>
      </div>
    </template>
  </Logo>
</template>
