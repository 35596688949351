/**
 * The healthcenters search resource.
 * @author Mathieu Bolard <m.bolard@apodispharma.com>
 * @param {Object} client - The injected client.
 * @returns {Object} The public object.
 */
export default function (client) {
    return {
    /**
     * Search healthcenters.
     * @param {boolean} postalCode - The postal code to search.
     * @returns {Promise} Promise object represents the HTTP request.
     */
        read (query = {}) {
            return client
                .post('/healthcenters/search', query)
                .then(response => response.data);
        },

        /**
     * Is the healthcenters already taken.
     * @param {string} id - The healthcenter id.
     * @returns {Promise} Promise object represents the HTTP request.
     */
        isOwned (id) {
            return client
                .get(`/access/healthcenter/${id}/owned`)
                .then(response => response.data);
        }
    };
}
