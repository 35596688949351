import {AxiosInstance} from "axios";

/**
 * The sponsorship resource.
 */
export default function (client: AxiosInstance) {
    return {
        /**
         * Invite a user to the sponsorship.
         */
        invite(email: string): Promise<void> {
            return client.post('sponsorships/invite', {email});
        }
    };
}
