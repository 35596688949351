<template>
  <div
    class="
      max-w-lg
      m-auto
      inline-block
      align-middle
      px-4
      sm:px-6
      lg:px-8
    "
  >
    <div class="text-2xl text-white text-center my-8">
      Entrez votre nouveau mot de passe
    </div>
    <BaseAlert
      v-if="error"
      type="error"
      title="Erreur"
    >
      {{ error }}
    </BaseAlert>
    <RegisterInput
      v-model="password"
      type="password"
      placeholder="Mot de passe"
    />
    <RegisterInput
      v-model="passwordConfirmation"
      type="password"
      placeholder="Confirmez votre mot de passe"
    />
    <div
      class="mt-2 text-xs text-gray-300"
    >
      {{ passwordInfo }}
    </div>
    <button
      type="button"
      :disabled="!submitEnabled"
      :class="{ 'opacity-25 cursor-not-allowed': !submitEnabled }"
      class="flex justify-center mx-auto py-3 px-7 mt-8 text-xl text-primary-dark rounded-full bg-secondary disabled:opacity-25"
      @click="changePassword"
    >
      Changer mon mot de passe
    </button>
  </div>
</template>

<script lang="ts" setup>
import {computed, onMounted, ref} from "vue";
import passwordPolicyHelper from "@/core/helpers/passwordPolicyHelper";
import BaseAlert from "@/core/components/base/BaseAlert.vue";
import RegisterInput from "@/register/views/RegisterInput.vue";
import {usersApi} from "@/container";
import {useRoute, useRouter} from "vue-router";

const router = useRouter();
const route = useRoute();

const error = ref<string | null>(null);
const code = ref<string | null>(null);
const password = ref<string>();
const passwordConfirmation = ref<string>();

onMounted(() => {
    code.value = (route.params.code as string);
    usersApi()
        .verifyForgotPasswordCode(code.value)
        .then((response) => {
            if (response.error) {
                error.value = 'Code de réinitialisation non trouvé. Ce lien de réinitialisation est probablement expiré.';
            }
        });
});

/**
 * Know if submit is enabled.
 */
const submitEnabled = computed(() => {
    return (
        error.value === null &&
        code.value !== null &&
        password.value === passwordConfirmation.value &&
        passwordPolicyHelper.isValid(password.value)
    );
});

/**
 * Get password information.
 */
const passwordInfo = computed(() => {
    if (passwordPolicyHelper.isValid(password.value)) {
        return 'Votre mot de passe est sécurisé 👍';
    } else {
        var info = 'Votre mot de passe doit contenir';
        if (!passwordPolicyHelper.hasMinimumLength(password.value)) {
            info +=
                ' au moins ' +
                String(passwordPolicyHelper.minLength) +
                ' caractères,';
        }
        if (!passwordPolicyHelper.hasNumber(password.value)) {
            info += ' un nombre,';
        }
        if (!passwordPolicyHelper.hasLowercase(password.value)) {
            info += ' un caractère minuscule,';
        }
        if (!passwordPolicyHelper.hasUppercase(password.value)) {
            info += ' un caractère majuscule,';
        }
        if (!passwordPolicyHelper.hasSpecial(password.value)) {
            info += ' un caractère spécial,';
        }
        return info.replace(/.$/, '.');
    }
});

/**
 * Change password.
 */
const changePassword = () => {
    usersApi()
        .changeForgotPassword(code.value, password.value)
        .then(response => {
            if (response.status === 'success') {
                router.push({name: 'login'});
            }
        });
};
</script>
