<template>
  <div class="flex flex-1 flex-row">
    <ProgramLogo
      :program="program"
      :size="55"
    />
    <div class="flex flex-1 flex-col justify-center pl-3">
      <div class="text-content">
        <div class="flex flex-row text-primary text-sm">
          <router-link :to="{ name: 'program.detail.content', params: { programId: program.id } }">
            Accéder au programme
          </router-link>
        </div>
      </div>
      <h2 class="text-black">
        {{ program.name }}
      </h2>
    </div>
    <div class="flex max-w-[50%] items-end text-sm pr-2">
      <span v-if="program.programmable?.pathway?.next_step?.resolved?.action">
        {{ lead }}
      </span>
      <span v-else>
        {{ program.teaser || "" }}
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from "vue";
import ProgramLogo from '@/program/components/program-logo/ProgramLogo.vue';
import {Program} from "@/program/interfaces/Program";

export default defineComponent({
    components: {ProgramLogo,},

    props: {
        program: {
            type: Object as PropType<Program>,
            required: true
        },
    },
    computed: {
        lead() {
            return this.step?.resolved.lead || this.step?.lead || "";
        },
        step() {
            return this.program?.programmable?.pathway?.next_step;
        },
    }
});
</script>
