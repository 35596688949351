import {acceptHMRUpdate, defineStore} from "pinia";
import {interviewApi, orderApi} from "@/container";
import LiveItemMapperService from "../core/services/LiveItemMapperService";
import {HomeInterviewInstanceItem, HomeItem, HomeOrderItem, OrderCreatedBroadcastEvent} from "@/home/interfaces";

interface LiveStore {
    orders: HomeOrderItem[];
    interviewInstances: HomeInterviewInstanceItem[];
}

export const useLiveStore = defineStore('live', {
    state: (): LiveStore => ({
        orders: [],
        interviewInstances: []
    }),
    actions: {
        /**
         * Add a new order to the list of orders.
         *
         * @param item
         */
        newBroadcastOrder(item: OrderCreatedBroadcastEvent) {
            if (!this.orders.find(previousItem => previousItem.data.id === item.id)) {
                this.orders.unshift(LiveItemMapperService().newOrder(item));
            }
        },
        deleteItems(item: any) {
            const index = this.orders.findIndex((currentItem) => currentItem.data.id === item.id);
            if (index > -1) {
                this.orders.splice(index, 1);
            }
        },
        /**
         * Load all the live items.
         */
        async loadItems() {
            return Promise.all([
                this.loadOrders(),
                this.loadPendingInterviewInstances()
            ]);
        },
        /**
         * Load all the pending orders.
         */
        async loadOrders() {
            return orderApi().index({status: 'pending'})
                .then(orders => {
                    this.orders = orders.data?.map((order) => LiveItemMapperService().newOrder(order)) ?? [];
                });
        },
        /**
         * Load all the pending interview instances.
         */
        async loadPendingInterviewInstances() {
            return interviewApi().indexInstances({partial: true})
                .then(instances => {
                    this.interviewInstances = instances?.map((instance) => LiveItemMapperService().newInterviewInstance(instance)) ?? [];
                });
        }
    },
    getters: {
        items(state): HomeItem[] {
            return [...state.orders, ...state.interviewInstances];
        }
    }
});

//https://pinia.vuejs.org/cookbook/hot-module-replacement.html#HMR-Hot-Module-Replacement-
if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useLiveStore, import.meta.hot));
}
