<template>
  <svg
    id="Calque_1"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 30 30"
    style="enable-background:new 0 0 30 30;"
    xml:space="preserve"
  >
    <path
      class="st0"
      fill="#3513e2"
      d="M4.9,4.8h6.9l0.9-0.7c1.4-1.1,3.3-1.1,4.6,0l1,0.7H25v21.8H4.9V4.8z"
    />
    <path
      class="st1"
      fill="#FFFFFF"
      stroke="#3513E2"
      d="M25,27.7H5c-0.6,0-1.2-0.5-1.2-1.2V5c0-0.6,0.5-1.2,1.1-1.2c0,0,0,0,0.1,0h6.7c0.6-1.3,1.9-2.1,3.3-2.1l0,0
	c1.4,0,2.7,0.8,3.3,2.1H25c0.7,0,1.2,0.6,1.1,1.2c0,0,0,0,0,0v21.5C26.1,27.2,25.6,27.7,25,27.7z M5.7,25.8h18.5V5.6h-6.6
	c-0.5,0-0.8-0.3-1-0.8c-0.2-0.8-0.9-1.3-1.7-1.3l0,0c-0.8,0-1.5,0.6-1.7,1.3c-0.1,0.4-0.5,0.7-0.9,0.7H5.7V25.8z M14.9,23.8h-4.8
	c-0.5,0.1-1-0.3-1-0.8c-0.1-0.5,0.3-1,0.8-1c0.1,0,0.1,0,0.2,0h4.8c0.5-0.1,1,0.3,1,0.8c0.1,0.5-0.3,1-0.8,1
	C15,23.8,15,23.8,14.9,23.8z M20.1,20.1h-9.9c-0.5,0.1-1-0.3-1-0.8c-0.1-0.5,0.3-1,0.8-1c0.1,0,0.1,0,0.2,0h9.9c0.5-0.1,1,0.3,1,0.8
	c0.1,0.5-0.3,1-0.8,1C20.2,20.1,20.2,20.1,20.1,20.1z M20.1,16.5h-9.9c-0.5,0-0.9-0.4-0.9-0.9c0-0.5,0.4-0.9,0.9-0.9c0,0,0,0,0,0
	h9.9c0.5,0,0.9,0.4,0.9,0.9S20.6,16.5,20.1,16.5C20.1,16.5,20.1,16.5,20.1,16.5z M18.5,12.1h-7.1c-0.5,0-0.9-0.4-0.9-0.9
	c0-0.2,0-0.3,0.1-0.5c0.4-0.8,1.1-1.4,1.8-1.9l0.1-0.1l0,0V8.4C12.6,7,13.6,6,14.9,6s2.4,1.1,2.4,2.4l0,0l0,0.4
	c0.8,0.5,1.5,1.1,1.9,1.9c0.2,0.3,0.2,0.6,0,0.9C19.2,11.9,18.9,12.1,18.5,12.1L18.5,12.1z M13.8,10.2h2.5l-0.1-0.1
	c-0.4-0.2-0.6-0.6-0.6-1V8.3c0-0.4-0.2-0.6-0.5-0.6s-0.6,0.2-0.6,0.6v0.8c0,0.4-0.2,0.8-0.6,1L13.8,10.2z M13,8.5L13,8.5z M17.1,8.5
	L17.1,8.5z"
    />
  </svg>
</template>

<script>
export default {
    name: 'Prescription',
    props: {
        size: {
            type: Number,
            default: 24
        }
    }
};
</script>
