<template>
  <div class="flex flex-col lg:flex-row flex-wrap gap-3 mb-5">
    <support-program-pathway-card
      v-if="supportProgram.pathway"
      :pathway-step-count="supportProgram.pathway.steps_count"
      class="flex flex-2"
      @click="redirectToPathway"
    />
    <div class="flex flex-row flex-wrap flex-1 gap-3">
      <statistic-card
        :value="supportProgram.statistics.excluded.intersection + supportProgram.statistics.included.intersection"
        :label="'Patients concernés dans ma pharmacie'"
        class="flex flex-1"
        :style="{
          'flexBasis': '400px'
        }"
        @click="redirectToPatients"
      />
      <statistic-card
        v-if="!!program.attachments_count"
        :label="'Ressources et informations à lire'"
        class="flex flex-1"
        :style="{
          'flexBasis': '400px'
        }"
        @click="redirectToAttachments"
      >
        <template #icon>
          <Book
            :size="40"
            class="fill-primary"
          />
        </template>
      </statistic-card>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import StatisticCard from '@/program/components/statistic-card/StatisticCard.vue';
import SupportProgramPathwayCard from '@/program/components/support-program/SupportProgramPathwayCard.vue';
import Book from '@/core/components/icons/Book.vue';

export default defineComponent({
    name: 'SupportProgramTabShortcuts',
    components: {
        Book,
        SupportProgramPathwayCard,
        StatisticCard
    },
    props: {
        program: {
            type: Object,
            required: true
        },
        supportProgram: {
            type: Object,
            required: true
        }
    },
    methods: {
    /**
     * Redirect to program patients list
     */
        redirectToPatients() {
            this.$router.push({
                name: 'program.detail.consumers',
                params: {
                    programId: this.program.id,
                },
                query: {
                    programmableId: this.program.programmable_id,
                    programType: this.program.programmable_type
                }
            });
        },
        /**
     * Redirect to program attachments list
     */
        redirectToAttachments() {
            if (this.program.attachments_count) {
                this.$router.push({
                    name: 'program.detail.attachments',
                    params: {
                        programId: this.program.id
                    }
                });
            }
        },
        /**
     * Redirect to program pathway
     */
        redirectToPathway() {
            this.$router.push({
                name: 'program.detail.pathway',
                params: {
                    supportProgramId: this.program.programmable_id
                }
            });
        }
    }
});
</script>
