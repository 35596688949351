import OrderSummary from "./OrderSummary";
import Customer from "../../customer/interfaces/Customer";
import Beneficiary from "../../customer/interfaces/Beneficiary";
import Prescriptor from "../../prescriptor/interfaces/Prescriptor";
import PrescriptionRenewal from "../../prescription/interfaces/prescription-renewal/PrescriptionRenewal";
import Cart from "./Cart";
import Product from "../../product/interfaces/Product";

/**
 * Enum for order content type.
 *
 * @enum OrderContentType
 */
export enum OrderContentType {
    RENEWAL = "renewal",
    CART = "cart",
    NEW_PRESCRIPTION = "new_prescription"
}

/**
 * Enum for order status.
 */
export enum OrderStatusType {
    DELIVERED = "delivered",
    AVAILABLE = "available",
    ACCEPTED = "accepted",
    REJECTED = "rejected",
    PENDING = "pending"
}

/**
 * Interface for orders
 *
 */
interface Order {
    /** The order status. **/
    status: OrderStatusType;
    /** The date it was accepted. **/
    accepted_at: string;
    /** The date it available. **/
    available_at: string;
    /** The content type. **/
    content_type: OrderContentType;
    /** The date it was created. **/
    created_at: string;
    /** The customer who made the order. **/
    customer: Customer,
    /** The beneficiary object if any. **/
    beneficiary?: Beneficiary;
    /** The date it delivered. **/
    delivered_at: string;
    /** The date it was rejected. **/
    rejected_at: string;
    /** The order summary. **/
    summary?: OrderSummary;
    /** The id. **/
    id: number;
    /** The prescriptor. **/
    prescriptor?: Prescriptor;
    /** The order polymorphic content. **/
    content: PrescriptionRenewal | Cart;
    /** The optional user comment. **/
    user_comment?: string;
    /** The optional operator comment. **/
    operator_comment?: string;
    /** The optional operator code. **/
    operator_code?: string;
}

/**
 * Order item interface.
 *
 * @property {Product} product - Ordered product.
 * @property {number} quantity - Ordered quantity.
 * @property {number} total_price - Total price.
 * @property {boolean} [in_stock] - If product is in stock.
 */
export interface OrderItem {
    product: Product;
    quantity: number;
    total_price?: number;
    in_stock?: boolean;
}

export default Order;

