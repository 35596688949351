<template>
  <div
    class="flex justify-between gap-3"
  >
    <p class="truncate">
      {{ invitation.email }}
    </p>
    <p
      class="text-red-500 hover:underline cursor-pointer text-center"
      @click="$emit('cancel-invitation', invitation)"
    >
      Annuler l'invitation
    </p>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

export default defineComponent({
    name: "HealthcenterGroupSentInvitationRow",
    props: {
        invitation: {
            type: Object,
            required: true
        }
    },
    emits: ['cancel-invitation']
});
</script>

<style scoped>

</style>
