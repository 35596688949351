<script setup lang="ts">
import {useToastStore} from "@/stores/toast.store";

const {toasts} = useToastStore();
</script>

<template>
  <div class="absolute right-2 top-12 z-50">
    <transition-group appear name="list">
      <div
        v-for="(toast, index) in toasts"
        :key="index"
        class="flex-row items-center p-4 m-2 select-none bg-white"
        :class="{
          'shadow-[5px_5px_0_0_#3513e2]': toast.type === 'info',
          'shadow-[5px_5px_0_0_#F19495]': toast.type === 'error' || toast.type === 'warning',
          'shadow-[5px_5px_0_0_#65efca]': toast.type === 'success',
        }"
      >
        <div v-html="toast.content" />
      </div>
    </transition-group>
  </div>
</template>

<style scoped>
/* List animation */
.list-move {
    transition: transform 0.5s ease-in;
}

.list-enter-active,
.list-leave-active {
    transition: all 0.5s ease;
}

.list-enter-from,
.list-leave-to {
    opacity: 0;
    transform: translateX(30px);
}
</style>
