<template>
  <base-card>
    <base-card-body class="flex flex-col gap-2">
      <h1>
        Invitez une pharmacie
      </h1>
      <p class="text-primary">
        Les pharmacies qui rejoindront votre groupe <strong>gagneront 1 mois gratuit</strong>, et vous aussi !
      </p>
      <form
        class="flex flex-col gap-4"
        @submit.prevent="sendInvitation"
      >
        <healthcenter-search-bar @select="handleHealthcenterSelected" />
        <div class="flex flex-1 items-center gap-x-6 gap-y-2 flex-wrap">
          <label>Email de la pharmacie à inviter</label>
          <input
            v-model="email"
            type="email"
            class="flex flex-1 bg-gray-200 border border-gray-300 p-2 rounded-md focus:outline-none focus:ring-primary focus:border-primary"
            placeholder="Adresse email de la pharmacie"
            required
          >
          <button
            type="submit"
            class="text-primary font-bold p-2"
            :disabled="loading"
          >
            <span v-if="!loading">Envoyer</span>
            <span v-if="loading">
              <base-spinner
                size="button"
              />
            </span>
          </button>
        </div>
      </form>
    </base-card-body>
  </base-card>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import {Healthcenter} from "@/core/interfaces/healthcenter/Healthcenter";
import {disruptionLogisticsApi} from "@/container";
import {useToastStore} from "@/stores/toast.store";
import BaseCard from "@/core/components/base/BaseCard.vue";
import BaseCardBody from "@/core/components/base/BaseCardBody.vue";
import BaseSpinner from "@/core/components/base/spinner/BaseSpinner.vue";
import HealthcenterSearchBar from "@/healthcenter/components/HealthcenterSearchBar.vue";

export default defineComponent({
    name: "InviteHealthcenterCard",
    components: {HealthcenterSearchBar, BaseSpinner, BaseCardBody, BaseCard},
    props: {
        healthcenterGroupId: {
            type: Number,
            required: true
        }
    },
    emits: ['invitationSent'],
    data() {
        return {
            loading: false,
            email: null as null | string,
            healthcenter: undefined as undefined | Healthcenter
        };
    },
    methods: {
    /**
     * Send the invitation.
     */
        sendInvitation() {
            if (this.loading || !this.email || !this.healthcenter) {
                return;
            }

            this.loading = true;
            return disruptionLogisticsApi()
                .createInvitation(this.healthcenterGroupId, this.email, this.healthcenter.id)
                .then((invitation) => {
                    const toast = useToastStore();

                    toast.showSuccess({content: 'Invitation envoyée'});
                    this.$emit('invitationSent', invitation);
                    this.email = null;
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        handleHealthcenterSelected(healthcenter: Healthcenter) {
            this.healthcenter = healthcenter;
        }
    }
});
</script>

<style scoped>

</style>
