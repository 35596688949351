<template>
  <div class="flex flex-row justify-space-between items-center text-primary font-bold mt-5">
    <p class="w-60 flex">
      Coût: {{ notification.credits }} {{ pluralize('crédit', notification.credits) }}
    </p>
    <p class="w-60 justify-end flex">
      Restera : {{ credit }}
    </p>
    <base-button
      v-if="notification"
      primary
      @click="onSend()"
    >
      <div class="flex flex-row gap-x-2">
        Envoyer au {{ phone }}
        <Sms :size="5" />
      </div>
    </base-button>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from "vue";
import BaseButton from '@/core/components/base/BaseButton.vue';
import Sms from '@/core/components/icons/Sms.vue';
import {MassCertificationNotificationTemplate} from "@/core/interfaces/MassCertification";
import {pluralize} from "@/core/filters/Pluralize";
import Customer from "@/customer/interfaces/Customer";

export default defineComponent({
    name: 'CertificationNotificationFooter',
    components: {Sms, BaseButton},
    props: {
        /**
         * The organization credit balance
         */
        balance: {
            type: Number as PropType<number>,
            required: true
        },
        /**
         * The certification object.
         */
        customer: {
            type: Object as PropType<Customer>,
            required: true
        },
        /**
         * The message object.
         */
        notification: {
            type: Object as PropType<MassCertificationNotificationTemplate>,
            required: true
        },
        /**
         * On send callback.
         */
        onSend: {
            type: Function,
            required: true
        }
    },
    computed: {
        cost(): number {
            return this.notification.credits;
        },
        phone() {
            return this.customer.certification?.phone || this.customer.pending_certification?.phone;
        },
        credit() {
            return this.balance && this.$filters.numberFormat((this.balance - this.cost), 0) + ' '
                + this.$filters.pluralize('crédit', this.balance - this.cost);
        }
    },
    methods: {pluralize}
});
</script>
