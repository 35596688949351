<template>
  <div class="w-full bg-lightPurple-lightest px-5 py-2">
    <expandable-text-overflow>
      <div class="font-content">
        <markdown :content="program.description" />
      </div>
    </expandable-text-overflow>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import ExpandableTextOverflow from '@/core/components/expandable-text-overflow/ExpandableTextOverflow.vue';
import Markdown from '../../../core/components/markdown/Markdown.vue';
export default defineComponent({
    name: 'ProgramDescription',
    components: { Markdown, ExpandableTextOverflow },
    props: {
        program: {
            type: Object,
            required: true
        }
    }
});
</script>
