import axios from 'axios';
import requestInterceptor from '@/core/http/interceptors/requestInterceptor';
import responseInterceptor from "@/core/http/interceptors/responseInterceptor";

const apiClient = axios.create({
    baseURL: import.meta.env.VITE_API_URL
});

apiClient.interceptors.request.use(
    requestInterceptor.success
);

apiClient.interceptors.response.use(
    responseInterceptor.success,
    responseInterceptor.failure
);

export default apiClient;
