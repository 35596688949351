<script setup lang="ts">

import {LaboratoryOrder} from "@/core/interfaces/disruption/LaboratoryOrder";
import {computed} from "vue";

const {order} = defineProps<{ order: LaboratoryOrder }>();

const totalHT = computed(() => order.lines.reduce((sum, element) => sum + element.totalDiscountedWithoutVat, 0));
const totalTTC = computed(() => order.lines.reduce((sum, element) => sum + element.totalDiscountedWithVat, 0));
const totalRemisesHT = computed(() => {
    return order.lines.reduce((sum, element) => {
        return sum + (element.priceWithoutVat * element.quantity) - element.totalDiscountedWithoutVat;
    }, 0);
});
</script>

<template>
  <div>
    <p>
      Commandé le {{ $filters.date(order.createdAt, 'dayOnly') }}
    </p>
    <div v-for="line in order.lines" :key="line.id" class="py-4">
      <h2>{{ line.productName }}</h2>
      <div class="flex flex-row justify-between  pr-4">
        <p>{{ line.quantity }}x {{ line.priceWithoutVat }} € HT</p>
        <div class="flex flex-row">
          <p v-if="line.discountPercentage > 0" class="text-red line-through">
            {{ (line.priceWithoutVat * line.quantity).toFixed(2) }} €
          </p>
          <p v-if="line.discountPercentage > 0" class="text-green px-4">
            -{{ line.discountPercentage }}%
          </p>
          <p>{{ line.totalDiscountedWithoutVat }} € HT</p>
        </div>
      </div>
      <div class="flex flex-row justify-between pr-4">
        <p>TVA {{ line.vatRate }}% :</p>
        <p class="pr-5">
          {{ (line.totalDiscountedWithVat - line.totalDiscountedWithoutVat).toFixed(2) }} €
        </p>
      </div>
      <div class="flex flex-row justify-between">
        <h2 class="text-black">
          TOTAL
        </h2>
        <h2 class="text-black">
          {{ line.totalDiscountedWithVat }} € TTC
        </h2>
      </div>
    </div>
    <hr>
    <div class="py-2">
      <p>Cumul des remises :</p>
      <p>{{ totalRemisesHT.toFixed(2) }} € soit {{ ((totalRemisesHT / totalHT) * 100).toFixed(1) }}% du Total HT</p>
    </div>
    <div>
      <h2 class="text-black">
        Total HT : {{ totalHT.toFixed(2) }} €
      </h2>
      <h2 class="text-black">
        Total TVA : {{ (totalTTC - totalHT).toFixed(2) }} €
      </h2>
      <h1>Total TTC : {{ totalTTC.toFixed(2) }} €</h1>
    </div>
    <p class="text-center text-sm text-gray-500 pt-4">
      {{
        order.shippingDate !== null
          ? "Commande envoyée le " + order.shippingDate
          : "Une question concernant votre commande ? Veuillez contacter notre service client : help@apodis-sante.com"
      }}
    </p>
  </div>
</template>

<style scoped>

</style>