<script setup lang="ts">
import SubscriptionPlanCard from "@/subscription-plan/components/SubscriptionPlanCard.vue";
import {SubscriptionPlan} from "@/subscription-plan/interfaces";

defineProps<{
    plans: SubscriptionPlan[]
}>();
</script>

<template>
  <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
    <router-link
      v-for="plan in plans" :key="plan.id"
      :to="{name: 'subscription-plan', params: { planId: plan.id }}"
    >
      <SubscriptionPlanCard :plan="plan" />
    </router-link>
  </div>
</template>
