<template>
  <div class="flex justify-between text-white gradient py-5 px-3">
    <span class="font-bold text-xl">
      {{ consumerName }} - {{ interviewName }}
    </span>
    <button @click="$emit('close')">
      <span>Fermer</span>
    </button>
  </div>
</template>

<script lang="ts" setup>
defineProps<{
    consumerName: string | null;
    interviewName: string;
}>();

defineEmits(['close']);
</script>
