/**
 * Service for working with consumer keys.
 */
const ConsumerKeyService = () => {

    /**
     * Get the customer ID from a consumer key.
     */
    const customerIdFromKey = (key: string): number | null => {
        const parts = key.split("_");

        if (parts.length !== 2) {
            return null;
        }

        if (parts[0] !== 'c' || isNaN(parseInt(parts[1]))) {
            return null;
        }

        return parseInt(parts[1]);
    };

    /** Tell if the provided key is a beneficiary key. */
    const isBeneficiary = (key: string): boolean => {
        const parts = key.split("_");

        if (parts.length === 2) {
            return parts[0] === 'b';
        }

        return false;
    };

    return {
        customerIdFromKey,
        isBeneficiary
    };
};

export default ConsumerKeyService();
