/**
 * The notification service.
 * @author Tony Laurent <t.laurent@apodispharma.com>
 * @returns {Object} The public object.
 */
export default function () {
    return {
        /**
         * Create a notification.
         * @param {string} title - The title of the notification.
         * @param {string} body - The body of the notification.
         * @returns {Object} The notification instance.
         */
        create(title, body) {
            return new Notification(title, {body});
        },

        /**
         * Display the notification window.
         *
         * @param {number} height - The window height
         *
         * @returns {void}
         */
        showNotification(height: number | undefined = undefined) {
            window.ipcRenderer.send('show-notification', {
                height
            });
        },
    };
}
