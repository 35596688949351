import dateHelper from "@/core/helpers/dateHelper";
import {pluralize as _pluralize} from "@/core/filters/Pluralize";
import {phoneFormat as _phoneFormat} from "@/core/filters/Phone";
import {numberFormat as _numberFormat} from "@/core/filters/NumberFormat";
import {currencyze as _currencyze} from "@/core/filters/Currencyze";

export default {
    date(value: string | Date, formatName?: string, withoutSuffix?: boolean) {
        switch (formatName) {
        case 'short':
            return dateHelper.shortFormat(value);
        case 'dayOnly':
            return dateHelper.dayOnlyLongFormat(value);
        case 'relative':
            return dateHelper.relativeFormat(value,  withoutSuffix);
        default:
            return dateHelper.mediumFormat(value);
        }
    },
    pluralize(value: string, count: number) {
        return _pluralize(value, count);
    },
    phoneFormat(value: string) {
        return _phoneFormat(value);
    },
    numberFormat(value: (string | number), decimals: number) {
        return _numberFormat(value, decimals);
    },
    currencyze(number?: number) {
        return _currencyze(number);
    }
};
