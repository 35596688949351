<template>
  <product-availabity-card
    title="Vente directe"
    loading-message="Interrogation du dépositaire"
    :loading="data.loading"
    :is-available="data.availability"
  >
    <template #icon>
      <icon
        name="mdi-truck"
        :size="40"
        color="black"
      />
    </template>
    <template #additional-info>
      <div
        v-if="!data.loading"
        class="text-sm space-y-2"
      >
        <p v-if="data.details?.laboratory?.name">
          {{ data.details.laboratory.name }}
        </p>
        <p v-if="data.details?.laboratory?.phone">
          <v-icon
            color="black"
            size="20"
          >
            mdi-phone
          </v-icon>
          {{ $filters.phoneFormat(data.details.laboratory.phone) }}
        </p>
        <p v-if="data.details?.laboratory?.email">
          <v-icon
            color="black"
            size="20"
          >
            mdi-email
          </v-icon>
          {{ data.details.laboratory.email }}
        </p>
        <p v-if="data.availability === null">
          Dépositaire non joignable
        </p>
      </div>
    </template>
  </product-availabity-card>
</template>

<script lang="ts">
import {defineComponent, PropType} from 'vue';
import ProductAvailabityCard
    from "@/disruption-logistics/components/product-availability-card/ProductAvailabilityCard.vue";
import Icon from "@/core/components/icon/Icon.vue";
import {
    AvailabilityState
} from "@/disruption-logistics/components/product-availability-card/ProductAvailabilityCardGrid.vue";
import {DepositaryDetails} from "@/disruption-logistics/interfaces/availability-providers";

export default defineComponent({
    name: "DirectProductAvailabilityCard",
    components: {Icon, ProductAvailabityCard},
    props: {
        data: {
            type: Object as PropType<AvailabilityState<DepositaryDetails>>,
            required: true
        }
    }
});
</script>

<style scoped>

</style>
