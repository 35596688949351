<script setup lang="ts">
import SecureImageSlider from "@/core/components/secure-image/SecureImageSlider.vue";
import SecureImage from "@/core/components/secure-image/SecureImage.vue";

defineProps({
    cart: {
        type: Object,
        required: true
    }
}
);
</script>

<template>
  <div class="flex flex-row pb-3">
    <div
      class="flex flex-col p-4 bg-white rounded-lg border-r-4 border-b-4 border-b-primary/30 border-r-primary/30"
      :class="{
        'w-1/2 h-fit': !!cart.prescription_urls?.length,
        'w-full': !cart.prescription_urls?.length
      }"
    >
      <p class="font-semibold text-lg pb-2">
        Délivrés le {{ $filters.date(cart.date, 'dayOnly') }}
      </p>
      <div class="flex flex-col gap-3 flex-1">
        <div
          v-for="(product, index) in cart.products"
          :key="index"
        >
          <div class="flex">
            <p class="text-sm">
              <span class="font-bold">{{ product.quantity }}x</span> {{ product.name }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="cart.prescription_urls?.length"
      class="flex flex-1"
    >
      <secure-image
        v-if="cart.prescription_urls?.length === 1"
        :urls="cart.prescription_urls[0]"
        :size="72"
        :ratio="0.7"
      />
      <secure-image-slider
        v-else
        :images="cart.prescription_urls"
        :size="72"
        :ratio="0.7"
      />
    </div>
  </div>
</template>

<style scoped>

</style>
