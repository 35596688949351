<template>
  <div class="p-5 bg-white rounded-md flex flex-col border-2 border-primary">
    <slot />
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
export default defineComponent({
    name: 'InterviewContainer'
});
</script>
