<template>
  <div
    v-if="step.insert_title || step.insert_content"
    style="min-height: 140px; background-color: rgba(255, 215, 164, 0.2);"
  >
    <div

      class="rounded-lg py-8 px-8 flex flex-row"
      :class="step.attachment ? 'mb-16' : ''"
      style="min-height: 80px;"
    >
      <div class="flex-1 text-justify text-sm">
        <div
          v-if="step.insert_title"
          class="font-bold mb-3"
        >
          {{ step.insert_title }}
        </div>
        <div>
          {{ step.insert_content }}
        </div>
      </div>
      <div class="relative w-28 ml-6 hidden md:block">
        <img
          v-if="!step.attachment"
          alt="illustration"
          :src="step.image ? step.image.url : pharmacistBubble"
          class="w-32 absolute object-contain hidden sm:block"
          style="top: -15px; right: 0;"
        >
        <div v-else>
          <div
            class="rounded-lg bg-orange-default absolute"
            style="width: 275px; height: 60px; position:absolute; right: 5px; bottom:-55px;"
          >
            <div class="flex flex-row justify-center align-center pl-4 h-full">
              <div class="flex-1 text-sm font-weight-bold">
                {{ step.attachment.title }}
              </div>
              <ProgramAttachmentCompactThumbnail
                :attachment="step.attachment"
                class="w-16 h-24 absolute"
                style="right: 15px; bottom: 0;"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import ProgramAttachmentCompactThumbnail
    from '@/program/components/program-attachment/ProgramAttachmentCompactThumbnail.vue';
import {defineComponent} from "vue";
import pharmacistBubble from '@/assets/images/pharmacist-bubble.png';

export default defineComponent({
    name: 'PathwayStepInsert',
    components: { ProgramAttachmentCompactThumbnail },
    props: {
        step: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            pharmacistBubble
        };
    }
});
</script>
