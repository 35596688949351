<template>
  <base-container>
    <header-back-button title="Pharmacies partenaires" />
    <base-flex-spinner v-if="loading" />
    <div
      v-else
      class="p-6 flex flex-col gap-3"
    >
      <received-healthcenter-group-invitations-card
        v-if="invitations?.length"
        :invitations="invitations"
        @invitation-handled="invitationHandled"
      />
      <healthcenter-group-grid
        :groups="groups"
      />
    </div>
  </base-container>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import BaseContainer from "@/core/components/base/BaseContainer.vue";
import HeaderBackButton from "@/core/components/header-back-button/HeaderBackButton.vue";
import BaseFlexSpinner from "@/core/components/base/spinner/BaseFlexSpinner.vue";
import {disruptionLogisticsApi} from "@/container";
import {HealthcenterGroup, HealthcenterGroupInvitation} from "@/disruption-logistics/interfaces/healthcenter-groups";
import ReceivedHealthcenterGroupInvitationsCard
    from "@/disruption-logistics/components/healthcenter-groups/ReceivedHealthcenterGroupInvitationsCard.vue";
import HealthcenterGroupGrid from "@/disruption-logistics/components/healthcenter-groups/HealthcenterGroupGrid.vue";

export default defineComponent({
    name: "HealthcenterGroupsView",
    components: {
        HealthcenterGroupGrid,
        ReceivedHealthcenterGroupInvitationsCard, BaseFlexSpinner, HeaderBackButton, BaseContainer},
    data () {
        return {
            loading: false,
            invitations: undefined as undefined | HealthcenterGroupInvitation[],
            groups: undefined as undefined | HealthcenterGroup[]
        };
    },
    /**
   * On mount, register store modules.
   */
    mounted () {
        this.loading = true;

        Promise.all([
            this.getPendingInvitations(),
            this.getHealthcenterGroups()
        ])
            .finally(() => {
                this.loading = false;
            });
    },
    methods: {
    /**
     * Get the pending invitations.
     */
        async getPendingInvitations (): Promise<void> {
            return disruptionLogisticsApi()
                .receivedHealthcenterGroupInvitations('pending')
                .then((invitations) => {
                    this.invitations = invitations;
                });
        },
        /**
     * Remove the handled invitation from the state.
     */
        invitationHandled (invitation: HealthcenterGroupInvitation, accepted: boolean): void {
            this.invitations = this.invitations?.filter((i) => i.id !== invitation.id);

            if (accepted) {
                this.loading = true;
                this.getHealthcenterGroups()
                    .finally(() => {
                        this.loading = false;
                    });
            }
        },
        /**
     * Get the healthcenter groups.
     */
        async getHealthcenterGroups (): Promise<void> {
            return disruptionLogisticsApi()
                .healthcenterGroups()
                .then((groups) => {
                    this.groups = groups;
                });
        }
    }
});
</script>
