<template>
  <div v-if="release">
    <glowing-container
      v-if="isRecent"
    >
      <div
        class="px-3 py-0.5 hover:opacity-50 cursor-pointer"
        @click="onPress"
      >
        <span class="text-primary text-sm">
          Voir les nouveautés 🚀 ({{ `v${release ? release.fields.version : version}` }})
        </span>
      </div>
    </glowing-container>
    <div
      v-else
      class="border border-gray-400 px-3 py-0.5 rounded-lg hover:opacity-50 cursor-pointer"
      @click="onPress"
    >
      <span>{{ `Version ${release ? release.fields.version : version}` }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import { version } from '../../../../package.json';
import GlowingContainer from '@/core/components/glowing-container/GlowingContainer.vue';
import { contentfulService } from '@/container';
import dayjs from 'dayjs';

export default defineComponent({
    name: 'VersionButton',
    components: { GlowingContainer },
    data () {
        return {
            version: version,
            release: null as any
        };
    },
    computed: {
        isRecent () {
            if (this.release) {
                return dayjs(this.release.fields.releaseDate).isAfter(dayjs().subtract(7, 'day'));
            }

            return false;
        }
    },
    mounted () {
        this.getLatestRelease();
    },
    methods: {
        onPress () {
            this.$router.push({ name: 'patch-notes' });
        },
        getLatestRelease () {
            contentfulService().latestRelease()
                .then((response: any) => {
                    this.release = response[0];
                });
        }
    }
});
</script>
