<template>
  <base-flex-spinner v-if="loading" class="mt-8" />
  <div v-else>
    <TheBackButton title="Plan de renouvellement" class="bg-white" />
    <div class="flex gap-x-4 px-6 py-2">
      <div class="flex-1">
        <prescription-visual
          v-if="prescription"
          :prescription="prescription"
        />
      </div>
      <div class="flex flex-col flex-1 gap-y-2">
        <h1>Diffusez le plan au patient</h1>
        <BaseCard>
          <BaseCardBody>
            <ul class="flex flex-col gap-y-2 list-disc">
              <li>
                <div>
                  <span class="font-bold">Date de fin de validité</span> :
                  le {{ dateHelper.abbreviatedMonthFormat(prescription!.renewal_plan.end_at) }}
                </div>
              </li>
              <li>
                <div>
                  <span class="font-bold">Dates de renouvellement</span> :
                  {{
                    prescription!.renewal_plan.renewal_dates.map(date => dateHelper.shortFormat(date.renew_at)).join(', ')
                  }}
                </div>
              </li>
              <li>
                <div>
                  <span class="font-bold">Plan de renouvellement traité par</span> :<br>
                  {{ prescription!.renewal_plan.operator_label }}
                </div>
              </li>
              <li>
                <div>
                  <span class="font-bold">Rappel automatique</span> :
                  {{
                    prescription!.renewal_plan.customer_notification_channel === 'push' ? 'Notification mobile' : 'Par SMS'
                  }}
                </div>
              </li>
            </ul>
          </BaseCardBody>
        </BaseCard>
        <div class="flex gap-x-4 justify-end">
          <BaseButton @click="cancelPlan">
            <div class="flex-1 justify-center">
              Annuler le plan
            </div>
          </BaseButton>
          <BaseButton primary @click="editPlan">
            <div class="flex-1 justify-center">
              Modifier
            </div>
            <Icon name="mdi-pencil" color="white" class="ml-2" />
          </BaseButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import PrescriptionVisual from "@/prescription/components/PrescriptionVisual.vue";
import BaseFlexSpinner from "@/core/components/base/spinner/BaseFlexSpinner.vue";
import {onMounted, ref} from "vue";
import {useRoute, useRouter} from "vue-router";
import {prescriptionApi, prescriptionRenewalPlanApi} from "@/container";
import TheBackButton from "@/core/components/navigation-bar/TheBackButton.vue";
import BaseCard from "@/core/components/base/BaseCard.vue";
import BaseCardBody from "@/core/components/base/BaseCardBody.vue";
import {Prescription} from "@/prescription/interfaces/Prescription";
import dateHelper from "@/core/helpers/dateHelper";
import BaseButton from "@/core/components/base/BaseButton.vue";
import Icon from "@/core/components/icon/Icon.vue";
import {useToastStore} from "@/stores/toast.store";

const prescription = ref<Prescription>();
const loading = ref(true);

const route = useRoute();
const router = useRouter();
const {showError} = useToastStore();

// fetch the user information when params change
onMounted(async () => {
    loading.value = true;
    const customerId: number = parseInt(route.query.customerId as string);

    await prescriptionApi().read(customerId, route.params.prescriptionId as string)
        .then(response => {
            prescription.value = response;
        })
        .finally(() => {
            loading.value = false;
        });
});

/**
 * Edit the plan.
 */
const editPlan = () => {
    router.push({
        name: 'patient-administration.renewal.edit',
        params: {prescriptionId: prescription.value!.id},
        query: {
            customerId: prescription.value!.customer.id
        }
    });
};

/**
 * Cancel the plan.
 */
const cancelPlan = async () => {
    await prescriptionRenewalPlanApi().delete(prescription.value!.renewal_plan)
        .then(() => {
            router.go(-1);
        })
        .catch(() => {
            showError({content: 'Une erreur est survenue lors de l\'annulation du plan de renouvellement.'});
        });
};
</script>

<style scoped>
li {
    list-style-type: disc;
    @apply text-primary text-2xl ml-6;
}

li div {
    @apply text-gray-800 text-base relative -top-1;
}
</style>
