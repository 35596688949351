<template>
  <div
    class="bg-white rounded-md px-3 py-2 cursor-pointer shadow"
    @click="startSurvey"
  >
    <span
      v-if="survey.emoji"
      class="mr-2"
    >{{ survey.emoji }}</span> <span>{{ survey.theme ?? survey.name }}</span>
  </div>
</template>

<script lang="ts">
import {defineComponent, inject} from "vue";

export default defineComponent({
    name: 'SurveyCard',
    props: {
        survey: {
            type: Object,
            required: true
        },
        consumerKey: {
            type: String,
            required: true
        }
    },
    emits: ['surveyCompleted'],
    setup() {
        const openSurveyModal = inject<any>('openSurveyModal');

        return {
            openSurveyModal
        };
    },
    methods: {
        /**
         * Start a new survey instance.
         */
        startSurvey() {
            this.openSurveyModal(
                this.survey.id,
                this.consumerKey,
                null,
                null,
                'survey',
                {
                    showVerdict: true,
                    onClose: this.emitSurveyCompleted,
                    consent: this.survey.consent
                }
            );
        },
        /**
         * Emit the survey completed event.
         */
        emitSurveyCompleted() {
            this.$emit('surveyCompleted');
        }
    }
});
</script>

<style scoped>
.survey-card-container:hover img {
    transition: ease 0.8s;
    transform: scale(1.1);
}
</style>
