<template>
  <BaseContainer>
    <BaseFlexSpinner v-if="isLoading" />
    <div v-else class="px-5 pt-3 pb-24">
      <CommunicationBanner
        v-if="programType === 'support' && hasMessageTemplates"
        :selection-enabled="enableSelection"
        @on-press="onBannerPress"
        @on-confirm="confirmSelection"
        @on-cancel="enableSelection = false"
      />
      <BaseCard>
        <div class="p-5">
          <h1 class="text-gray-dark">
            Patients
          </h1>
          <div v-if="disabledConsumerSettings.length > 0">
            <p
              class="text-sm underline cursor-pointer mt-1"
              @click="disabledConsumersModalVisible = true"
            >
              {{ disabledConsumerSettings.length }}
              <span v-if="disabledConsumerSettings.length > 1">patients ont été retirés du programme</span>
              <span v-else>patient a été retiré du programme</span>
            </p>
            <program-excluded-consumers-modal
              v-if="resourceId"
              :visible="disabledConsumersModalVisible"
              :consumers="disabledConsumers"
              :support-program-id="resourceId"
              @close="settingsModalClose"
            />
          </div>
        </div>
        <div
          v-if="meta && !meta.progress"
          class="flex flex-col bg-slate-100 px-5 py-3 gap-3"
        >
          <div class="flex justify-between">
            <h3 class="flex-1">
              Filtres
            </h3>
            <div class="min-w-72 max-w-96 w-full">
              <input
                v-if="programType !== 'loyalty-card'"
                v-model="consumerFilter"
                type="search"
                class="w-full h-8 text-sm border-gray-100 rounded-md shadow-md focus:ring-indigo-500 focus:border-indigo-500 placeholder-gray-300"
                placeholder="Rechercher un patient par son nom ..."
              >
            </div>
          </div>
          <div class="flex">
            <div class="flex flex-1 gap-5">
              <button
                class="text-sm font-semibold"
                :class="!groupFilter ? 'text-primary': ''"
                @click="setGroupFilter(null)"
              >
                Tous les groupes
              </button>
              <button
                v-for="(item) in Object.entries(meta.included)"
                :key="item[0]"
                class="text-sm font-semibold"
                :class="groupFilter === item[0] ? 'text-primary': ''"
                @click="setGroupFilter(item[0])"
              >
                {{ item[1] }}
              </button>
            </div>
            <div>
              <BaseSelect
                v-model:modelValue="exclusionFilter"
                default-value=""
                trigger-class="border-none h-6 bg-transparent flex justify-end"
                placeholder="Tous les statuts"
                :options="[
                  {value: 'all', label: 'Tous les statuts'},
                  ...Object.keys(meta.excluded).map((key) => ({value: key, label: meta.excluded[key]})),
                  ...Object.keys(meta.not_excluded).map((key) => ({value: key, label: meta.not_excluded[key]}))
                ]"
              />
            </div>
          </div>
        </div>
        <component
          :is="consumersTable"
          :consumers="displayedConsumers"
          :resource-id="resourceId"
          :meta="meta"
          :enable-selection="enableSelection"
          @click="showCustomer"
        />
      </BaseCard>
    </div>
  </BaseContainer>
</template>

<script lang="ts">
import {defineComponent, PropType} from "vue";
import BaseContainer from '@/core/components/base/BaseContainer.vue';
import {instantDiscountVoucherApi, programApi, medicineCabinetApi, massCertificationApi} from '@/container';
import BaseCard from '@/core/components/base/BaseCard.vue';
import BaseFlexSpinner from '@/core/components/base/spinner/BaseFlexSpinner.vue';
import programHelper from '@/core/helpers/programHelper';
import LoyaltyCardConsumersTable from '@/program/components/program-consumers/LoyaltyCardConsumersTable.vue';
import SupportProgramGroupConsumersTable from '@/program/components/program-consumers/SupportProgramConsumerTable.vue';
import MedicineCabinetConsumersTable from '@/program/components/program-consumers/MedicineCabinetConsumersTable.vue';
import InstantDiscountVoucherConsumersTable
    from '@/program/components/program-consumers/InstantDiscountVoucherConsumersTable.vue';
import ProgramExcludedConsumersModal from '@/program/components/program-consumers/ProgramExcludedConsumersModal.vue';
import {mapActions, mapState, mapWritableState} from "pinia";
import {useSystemStore} from "@/stores/system.store";
import {Program} from "@/program/interfaces/Program";
import CommunicationBanner from "@/communication/components/CommunicationBanner.vue";
import {useCommunicationStore} from "@/communication/stores/communication.store";
import BaseButton from "@/core/components/base/BaseButton.vue";
import {useToastStore} from "@/stores/toast.store";
import BaseSelect from "@/core/components/base/BaseSelect.vue";
import ConsumerKeyService from "@/consumer/services/ConsumerKeyService";

export default defineComponent({
    name: 'ProgramConsumers',
    components: {
        BaseSelect,
        BaseButton,
        CommunicationBanner,
        ProgramExcludedConsumersModal,
        BaseFlexSpinner,
        BaseCard,
        BaseContainer,
    },
    props: {
        program: {
            type: Object as PropType<Program>,
            required: true
        }
    },
    data() {
        return {
            isLoading: false,
            consumers: undefined as any[] | undefined,
            consumerFilter: null as string | null,
            groupFilter: null as string | null,
            exclusionFilter: null as string | null,
            meta: null as any,
            programType: '' as string,
            resourceId: null as number | null,
            disabledConsumerSettings: [] as any[],
            disabledConsumersModalVisible: false,
            hasMessageTemplates: false,
            enableSelection: false
        };
    },
    computed: {
        ...mapState(useSystemStore, ['environment']),
        ...mapState(useCommunicationStore, ['programCustomers']),
        ...mapWritableState(useCommunicationStore, ['customers', 'mode', 'programId']),
        displayedConsumers() {
            if (this.consumers === undefined) return [];
            if (this.consumers.length > 0 && this.consumers[0].groups) {
                return this.consumers
                    .filter(consumer =>
                        !this.consumerFilter || consumer.name.toLowerCase().includes(this.consumerFilter.toLowerCase()))
                    .filter(consumer => {
                        const inclusionGroups = consumer.groups.included.map(group => group.slug);

                        return !this.groupFilter || (!!this.groupFilter && inclusionGroups.includes(this.groupFilter));
                    })
                    .filter(consumer => {
                        if (!this.exclusionFilter || this.exclusionFilter == 'all') {
                            return true;
                        }

                        const exclusionGroups = consumer.groups.excluded.map(group => group.slug);
                        const filter = this.exclusionFilter.match(/(not:)?(.*)/i);
                        const includes = exclusionGroups.includes(filter![2]);

                        return filter![1] === 'not:' ? !includes : includes;
                    });
            } else {
                return this.consumers
                    .filter(consumer => {
                        let name = consumer.name;
                        if (this.programType === 'feature') {
                            name = consumer.information.name;
                        }
                        return !this.consumerFilter || name.toLowerCase().includes(this.consumerFilter.toLowerCase());
                    });
            }
        },
        consumersTable() {
            if (this.programType === 'support') {
                return SupportProgramGroupConsumersTable;
            } else if (this.programType === 'feature') {
                return MedicineCabinetConsumersTable;
            } else if (this.programType === 'loyalty-card') {
                return LoyaltyCardConsumersTable;
            } else {
                return InstantDiscountVoucherConsumersTable;
            }
        },
        disabledConsumers() {
            return this.disabledConsumerSettings.map((setting) => setting.consumer);
        }
    },
    watch: {
        'program': {
            immediate: true,
            deep: true,
            handler(value: Program) {
                this.programType = value.programmable_type;
                this.resourceId = value.programmable_id;
                if (value.programmable_type !== 'loyalty-card') {
                    this.isLoading = true;
                    this.loadConsumers(value.programmable_id, value.programmable_type)
                        .then(() => {
                            const programId = this.$route.params.programId as any;
                            return massCertificationApi().messageTemplates({program_id: programId, type: "sms"});
                        })
                        .then(messages => this.hasMessageTemplates = messages.length > 0)
                        .finally(() => {
                            this.isLoading = false;
                        });
                }
            }
        }
    },
    methods: {
        ...mapActions(useToastStore, ['showInfo']),
        /**
         * Load the program consumers, depending on the program type.
         *
         * @param id
         * @param type
         * @returns {Promise<Awaited<void>[]>|Promise<void>}
         */
        loadConsumers(id: number, type: string) {
            switch (type) {
                case 'support':
                    return Promise.all([
                        this.loadSupportProgramConsumers(id),
                        this.loadExcludedConsumers(id)
                    ]);
                case 'discount':
                    return instantDiscountVoucherApi().customers(id)
                        .then(response => {
                            this.consumers = response;
                        });
                case 'feature':
                    return this.environment?.entityId
                        ? medicineCabinetApi().consumersStatistics(this.environment.entityId)
                            .then(response => {
                                this.consumers = response;
                            })
                        : Promise.resolve();
                case 'loyalty-card':
                default:
                    return Promise.resolve();
            }
        },
        /**
         * Load the excluded consumers of a support program.
         *
         * @param id
         * @returns {Promise<void>}
         */
        loadExcludedConsumers(id: number) {
            if (this.programType === 'support') {
                return programApi()
                    .supportProgramConsumerSettings(id, {disabled: true})
                    .then((data) => {
                        this.disabledConsumerSettings = data;
                    });
            }
        },
        /**
         * Load the consumers of a support program.
         *
         * @param {Number} id - The support program id.
         * @returns {Promise<void>}
         */
        loadSupportProgramConsumers(id) {
            return programApi().consumers(id)
                .then(response => {
                    this.consumers = response.data;
                    this.meta = response.meta;
                });
        },
        /**
         * Set the program group filter.
         *
         * @param slug
         */
        setGroupFilter(slug: string | null) {
            this.groupFilter = slug;
        },
        /**
         * Show the customer details.
         *
         * @param patient
         */
        showCustomer(patient) {
            let route: any | null = null;
            const program: Partial<Program> = {};

            if (this.programType === 'support') {
                if (ConsumerKeyService.isBeneficiary(patient.key)) {
                    patient.id = patient.customer_id;
                } else {
                    patient.id = patient.key.slice(2);
                }
            } else if (this.programType === 'discount') {
                patient.id = patient.customer_key.slice(2);
            } else if (this.programType !== 'feature') {
                patient.id = patient.customer.id;
            }

            program.id = this.program.id;
            program.programmable_type = this.program.programmable_type;
            program.programmable_id = this.program.programmable_id;

            if (program.programmable_type === 'support' || program.programmable_type === 'feature') {
                route = {
                    name: 'customer.home',
                    params: {
                        customerId: patient.id
                    }
                };
            } else {
                route = programHelper.userProgramRoute(program, patient);
            }

            if (route) {
                this.$router.push(route);
            }
        },
        /**
         * On settings modal close, set the visible state to false and refresh the settings.
         */
        settingsModalClose() {
            this.disabledConsumersModalVisible = false;

            if (this.programType === 'support' && this.resourceId) {
                this.loadExcludedConsumers(this.resourceId);
            }
        },
        /**
         * On communication banner press.
         */
        onBannerPress() {
            if (this.programType === 'support') {
                this.enableSelection = true;
            }
        },
        confirmSelection() {
            if (this.consumers) {
                if (this.programCustomers.length > 0) {
                    const filteredCustomers = this.consumers.filter(consumer => {
                        return this.programCustomers.indexOf(consumer.key) > -1;
                    });

                    this.customers = filteredCustomers.map(customer => {
                        return {
                            id: Number(customer.key.replace("c_", "")),
                            name: customer.name,
                            phone: customer.phone,
                            birthdate: customer.birthdate
                        };
                    });

                    this.programId = this.$route.params.programId as any;
                    this.mode = 'sms';
                    this.$router.push({name: 'patient-administration.communication.message'});
                } else {
                    this.showInfo({content: 'Vous devez sélectionner au moins un patient pour continuer'});
                }
            }
        }
    }
});
</script>
