<template>
  <div class="flex flex-col w-72 mt-6">
    <h2 class="text-lg self-end pr-5">
      Accès rapides
    </h2>
    <div class="flex flex-col gap-3 mt-5">
      <div
        v-for="(shortcut, index) in shortcuts"
        :key="index"
        class="flex items-center justify-between py-2 pl-4 pr-8 rounded-l-full shadow-md cursor-pointer transition-all duration-300 ease-in-out bg-white hover:bg-lightPurple"
        @click="select(index)"
      >
        {{ shortcut.title }}
        <img v-if="shortcut.logo" :src="shortcut.logo" :alt="shortcut.title" class="max-w-14">
        <div
          v-if="shortcut.count && !!shortcut.count()"
          class="ml-2"
        >
          <span class="font-bold">{{ shortcut.count() }}</span>
        </div>
      </div>
    </div>
    <interview-short-cut-drawer-element
      :component-config="component"
      @close="component = null"
    />
  </div>
</template>

<script lang="ts">
import {defineComponent, markRaw} from "vue";
import InterviewProfessionalAttachments
    from '@/interview/components/instance-modal/short-cuts/InterviewProfessionalAttachments.vue';
import InterviewShortCutDrawerElement from '@/interview/components/instance-modal/InterviewShortCutDrawerElement.vue';
import InterviewPatientAttachments
    from '@/interview/components/instance-modal/short-cuts/InterviewPatientAttachments.vue';
import InterviewDrugInteraction from '@/interview/components/instance-modal/short-cuts/InterviewDrugInteraction.vue';
import InterviewTranscript from '@/interview/components/instance-modal/InterviewTranscript.vue';
import InterviewSurveys from '@/interview/components/instance-modal/short-cuts/InterviewSurveys.vue';
import InterviewConsumerCarts from '@/interview/components/instance-modal/short-cuts/InterviewConsumerCarts.vue';
import InterviewConsumerNotes from '@/interview/components/instance-modal/short-cuts/InterviewConsumerNotes.vue';
import {mapState} from "pinia";
import {useInterviewStore} from "@/stores/interview-store";
import Vidal from "@/assets/images/logos/logo-vidal.png";
import InterviewVaccinationTimeline
    from "@/interview/components/instance-modal/short-cuts/InterviewVaccinationTimeline.vue";

export default defineComponent({
    name: 'InterviewShortCuts',
    components: {InterviewShortCutDrawerElement},
    data() {
        return {
            selected: null as any,
            component: null as any,
            initialShortcut: [
                {
                    title: 'Bibliographie',
                    component: markRaw(InterviewProfessionalAttachments),
                    count: () => this.professionalAttachments?.length || 0,
                    minWidth: '50%'
                },
                {
                    title: 'Ressources patient',
                    component: markRaw(InterviewPatientAttachments),
                    count: () => this.patientAttachments?.length || 0,
                    minWidth: '50%'
                },
                {
                    title: 'Questionnaires',
                    component: markRaw(InterviewSurveys),
                    count: () => this.surveys?.length || 0
                },
                {
                    title: 'Analyse pharma.',
                    component: markRaw(InterviewDrugInteraction),
                    minWidth: '100%',
                    logo: Vidal
                },
                {
                    title: 'Notes internes',
                    count: () => this.noteCount,
                    component: markRaw(InterviewConsumerNotes)
                },
                {
                    title: 'Historique délivrances',
                    component: markRaw(InterviewConsumerCarts),
                    minWidth: '100%'
                },
                {
                    title: "Suivi vaccinal",
                    component: markRaw(InterviewVaccinationTimeline),
                    minWidth: 'auto'
                }
            ]
        };
    },
    computed: {
        ...mapState(useInterviewStore, ['loading', 'transcript', 'surveys', 'professionalAttachments', 'patientAttachments', 'noteCount']),
        /**
     * The shortcuts.
     *
     * @returns {Array}
     */
        shortcuts() {
            const _shortcuts: any[] = [...this.initialShortcut];

            if (this.loading.transcript || this.transcript) {
                _shortcuts.push({
                    title: `Transcript${this.loading.transcript ? ' ...' : ''}`,
                    component: InterviewTranscript,
                    minWidth: '400px'
                });
            }

            return _shortcuts;
        }
    },
    methods: {
        select(index: number) {
            this.component = {
                component: this.shortcuts[index].component ?? null,
                minWidth: this.shortcuts[index].minWidth ?? '300px'
            };
        }
    }
});
</script>
