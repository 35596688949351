<script setup lang="ts">
import Icon from "@/core/components/icon/Icon.vue";

defineProps({
    title: {
        type: String,
        required: true
    }
});
</script>

<template>
  <div class="flex flex-row items-center py-3 px-4">
    <button class="flex items-center bg-primary rounded-full p-2 mr-4 cursor-pointer" @click="$router.go(-1)">
      <Icon name="mdi-arrow-left" color="white" class="h-6 w-6" />
    </button>
    <h1>{{ title }}</h1>
  </div>
</template>
