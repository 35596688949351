<script setup lang="ts">
import ConsumerProgramCard from "@/consumer/components/consumer-programs/ConsumerProgramCard.vue";
import Consumer from "@/core/interfaces/consumers/Consumer";
import {Program} from "@/program/interfaces/Program";
import Customer from "@/customer/interfaces/Customer";
import Beneficiary from "@/customer/interfaces/Beneficiary";

defineProps<{consumer: Consumer | Beneficiary | Customer, programs: Program[], customer: Customer}>();

</script>

<template>
  <h1 class="mb-4 text-gray-dark">
    Parlez-en à {{ consumer.information.first_name }}
  </h1>
  <div>
    <div class="flex flex-1 flex-row space-y-2 overflow-x-auto pb-3">
      <div
        v-if="programs.length > 0"
        class="flex flex-1 space-x-5"
      >
        <ConsumerProgramCard
          v-for="program in programs"
          :key="`program-${program.id}-${consumer.id}`"
          :program="program"
          :consumer="consumer"
          :customer="customer"
        />
      </div>
      <div
        v-else
        class="text-sm ml-6"
      >
        Aucune suggestion pour le moment.
      </div>
    </div>
  </div>
</template>
