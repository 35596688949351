<template>
  <img
    class="object-contain"
    :src="fullPath"
    :alt="text"
  >
</template>

<script lang="ts">
import {defineComponent} from "vue";

export default defineComponent({
    props: {
        path: {
            type: String,
            default: null,
            required: false
        },

        text: {
            type: String,
            default: null,
            required: false
        },

        rendered: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        mode () {
            return process.env.NODE_ENV;
        },

        fullPath () {
            return this.rendered
                ? this.path
                : `${import.meta.env.VITE_API_URL}/images/${this.path}`;
        }
    }
});
</script>
