<template>
  <div
    class="bg-white rounded-md shadow p-3 flex flex-col gap-6"
  >
    <h2 class="text-xl">
      Statistiques
    </h2>
    <template v-if="!loading && data">
      <div
        v-if="!pharmaMLsStatisticsEmpty"
        class="flex flex-col lg:flex-row gap-6 justify-between"
      >
        <pharma-ml-statistics-card
          :loading="loading"
          :data="data.pharma_ml.last_seven_day_statistics"
          label="Sur les 7 derniers jours"
          class="flex-1"
        />
        <pharma-ml-statistics-card
          :data="data.pharma_ml.last_thirty_day_statistics"
          label="Sur les 30 derniers jours"
          class="flex-1"
        />
      </div>
      <div class="flex flex-col gap-6 flex-wrap lg:flex-row justify-between lg:items-center">
        <stock-statistic-card
          :value="data.stock.average_stock_quantity.toFixed(0)+'u'"
          label="Stock moyen dans les pharmacies"
          class="flex-1"
        />
        <stock-statistic-card
          :value="data.stock.average_stock_available_days.toFixed(1)"
          label="Stock journalier moyen dans les pharmacies"
          class="flex-1"
        />
        <stock-statistic-card
          :value="data.stock.max_stock_quantity"
          label="Stock maximum dans les pharmacies"
          class="flex-1"
        />
      </div>
      <wholesalers-availability-line-chart
        v-if="!wholesalerAvailabilityEmpty"
        :data="data.pharma_ml.rate_histogram"
      />
      <div
        v-if="rateByWholesalerEmpty && wholesalerAvailabilityEmpty && pharmaMLsStatisticsEmpty"
        class="text-center flex flex-col items-center"
      >
        <dot-lottie-vue
          src="animations/empty-transactions.json"
          style="height: 300px;"
          :loop="false"
          :autoplay="true"
        />
        <p>Aucune donnée grossiste disponible.</p>
      </div>
    </template>
    <base-flex-spinner
      v-else
      class="flex justify-center items-center"
    />
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import {disruptionLogisticsApi} from "@/container";
import {ProductAvailabilityStatistics} from "@/disruption-logistics/interfaces/availability-providers";
import StockStatisticCard from "@/disruption-logistics/components/availability-statistics/StockStatisticCard.vue";
import WholesalersAvailabilityLineChart
    from "@/disruption-logistics/components/availability-statistics/WholesalersAvailabilityLineChart.vue";
import BaseFlexSpinner from "@/core/components/base/spinner/BaseFlexSpinner.vue";
import PharmaMlStatisticsCard
    from "@/disruption-logistics/components/availability-statistics/PharmaMlStatisticsCard.vue";
import {DotLottieVue} from "@lottiefiles/dotlottie-vue";

export default defineComponent({
    name: "ProductAvailabilityStatisticsCard",
    components: {
        DotLottieVue,
        PharmaMlStatisticsCard, BaseFlexSpinner, WholesalersAvailabilityLineChart, StockStatisticCard
    },
    props: {
        productId: {
            type: Number,
            required: true
        },
        requestToken: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            data: null as null | ProductAvailabilityStatistics,
            loading: false
        };
    },
    computed: {
    /**
     * Determine if the pharma ML statistics are empty.
     */
        pharmaMLsStatisticsEmpty(): boolean {
            if (this.loading || !this.data) {
                return false;
            }

            return !this.data.pharma_ml.last_seven_day_statistics.doc_count &&
          !this.data.pharma_ml.last_thirty_day_statistics.doc_count;
        },
        /**
     * Determine if the wholesalers availability is empty.
     *
     * @return {boolean}
     */
        wholesalerAvailabilityEmpty(): boolean {
            if (this.loading || !this.data) {
                return false;
            }

            return this.data.pharma_ml.rate_histogram.every(item => !item.rate);
        },
        /**
     * Determine if the rate by wholesaler is empty.
     */
        rateByWholesalerEmpty(): boolean {
            if (this.loading || !this.data) {
                return false;
            }

            return this.data.pharma_ml.wholesalers_histogram.length === 0;
        }
    },
    watch: {
        productId: {
            immediate: true,
            handler(value) {
                this.loading = true;
                this.loadStatistics(value)
                    .then(data => {
                        this.data = data;
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }
        }
    },
    methods: {
    /**
     * Load the availability statistics.
     */
        async loadStatistics(productId: number): Promise<ProductAvailabilityStatistics> {
            return disruptionLogisticsApi()
                .productAvailabilityStatistics(productId, this.requestToken);
        }
    }
});
</script>

<style scoped>

</style>
