<template>
  <experience-button
    :event-name="eventName"
    :button-style="buttonStyle"
    :primary="primary"
    @click="handleClick"
  >
    <slot />
  </experience-button>
</template>

<script setup lang="ts">
import ExperienceButton from '@/experience/components/ExperienceButton.vue';
import {Props as ButtonProps} from '@/core/components/base/BaseButton.vue';
import {computed} from "vue";

interface Props extends ButtonProps {
    step?: any;
    buttonStyle?: string;
}

const {step, buttonStyle} = defineProps<Props>();

const emits = defineEmits(['click']);

/**
 * The event name to be used for the experience button.
 *
 * @return {null|string}
 */
const eventName = computed(() => {
    if (step) {
        return `pathway_step.${step.id}.completed`;
    }

    return '';
});
const handleClick = (event: Event) => {
    emits('click', event);
};
</script>
