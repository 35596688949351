<template>
  <interview-glowing-container class="w-full flex flex-1">
    <div
      v-if="loadingTranscript"
      class="flex flex-col items-center justify-center h-full gap-6 text-center"
    >
      <base-spinner />
      <p>Votre entretien est en cours de transcription ! Ça ne devrait prendre qu'une minute. Merci de patienter !</p>
    </div>
    <div
      v-else-if="transcript"
      class="flex flex-col w-full"
    >
      <interview-transcript-utterances :utterances="transcript.utterances" />
    </div>
    <div v-else>
      Une erreur s'est produite lors de la transcription de l'entretien. Veuillez réessayer plus tard.
    </div>
  </interview-glowing-container>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import InterviewTranscriptUtterances from '@/interview/components/instance-modal/InterviewTranscriptUtterances.vue';
import InterviewGlowingContainer from '@/interview/components/instance-modal/InterviewGlowingContainer.vue';
import BaseSpinner from '@/core/components/base/spinner/BaseSpinner.vue';
import {mapState} from "pinia";
import {useInterviewStore} from "@/stores/interview-store";

export default defineComponent({
    name: 'InterviewTranscript',
    components: {
        BaseSpinner,
        InterviewGlowingContainer,
        InterviewTranscriptUtterances,
    },
    computed: {
        ...mapState(useInterviewStore,{
            loadingTranscript: state => state.loading.transcript,
            transcript: state => state.transcript
        })
    }
});
</script>
