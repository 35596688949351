<template>
  <div class="flex flex-col flex-1">
    <div class="text-sm">
      {{ data.label }}
    </div>
    <div>
      <Pie
        :data="chartData"
        :options="chartOptions"
      />
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import { Pie } from 'vue-chartjs';
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement } from 'chart.js';

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement);

export default defineComponent({
    name: 'ProgramBinomialPieChartStatisticsCard',
    components: {
        Pie
    },
    props: {
        data: {
            type: Object,
            required: true
        }
    },
    computed: {
        chartData () {
            return {
                labels: this.data.value.map(item => item.name),
                datasets: [{
                    backgroundColor: [
                        '#41b8a6',
                        '#409488',
                        '#E46651',
                        '#b95342',
                        '#7741b8',
                        '#5d3696',
                        '#e451d0',
                        '#cb50b7',
                        '#c9a779',
                        '#9f754c',
                        '#3e80c2',
                        '#4e7ba4'
                    ],
                    data: this.data.value.map(item => item.count)
                }]
            };
        },
        chartOptions (): any {
            return {
                responsive: true,
                elements:
          {
              arc: {
                  borderWidth: 0
              }
          },
                plugins: {
                    legend: {
                        position: 'right',
                        labels: {
                            padding: 4,
                            font: {
                                size: 9
                            },
                            boxWidth: 8
                        }
                    }
                }
            };
        }
    },
});
</script>
