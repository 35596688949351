<template>
  <div v-if="loading">
    <base-flex-spinner />
  </div>
  <div v-else>
    <div v-if="order">
      <order-details
        v-if="step === 'details'"
        :order="order"
        @handle-order="handleOrder"
        @on-patient-press="onPatientPress"
      />
      <order-operator
        v-if="step === 'operator'"
        :order="order"
        :loading="editingOrder"
        @update-operator-code="updateOperatorCode"
        @back="setStep('details')"
      />
      <order-date
        v-if="step === 'date'"
        :order="order"
        @validate="validateDate"
        @back="setStep('operator')"
      />
      <order-comment
        v-if="step === 'comment'"
        :order="order"
        :loading="editingOrder"
        @validate="validateOrder"
        @back="setStep('date')"
      />
      <rejected-order-comment
        v-if="step === 'reject-comment'"
        :loading="editingOrder"
        @validate="rejectOrder"
        @back="setStep('operator')"
      />
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import OrderOperator from '@/order/components/OrderOperator.vue';
import OrderDetails from '@/order/components/OrderDetails.vue';
import OrderDate from '@/order/components/OrderDate.vue';
import OrderComment from '@/order/components/OrderComment.vue';
import dayjs from 'dayjs';
import {orderApi} from '@/container';
import RejectedOrderComment from '@/order/components/RejectedOrderComment.vue';
import Order from "@/order/interfaces/Order";
import Customer from "@/customer/interfaces/Customer";
import BaseFlexSpinner from "@/core/components/base/spinner/BaseFlexSpinner.vue";

export default defineComponent({
    name: 'HandleOrder',
    components: {BaseFlexSpinner, RejectedOrderComment, OrderDetails, OrderOperator, OrderDate, OrderComment},
    props: {
        orderId: {
            type: Number,
            required: true
        }
    },
    emits: ['goToPatient', 'orderHandled'],
    data() {
        return {
            show: false,
            step: 'details',
            editingOrder: false,
            isRefused: false,
            loading: false,
            order: null as Order | null
        };
    },
    mounted() {
        orderApi().read(this.orderId)
            .then(response => {
                this.order = response;
            })
            .finally(() => this.loading = false);
    },
    methods: {
        /**
         * Update step value.
         *
         * @param step
         */
        setStep(step: string) {
            this.step = step;
        },
        /**
         * Action on patient press.
         *
         * @param patient
         */
        onPatientPress(patient: Customer) {
            this.$emit('goToPatient', patient);
        },
        /**
         * Handle what happens when user accept or refuse an order.
         *
         * @param accept
         */
        handleOrder(accept: boolean) {
            if (!accept) {
                this.isRefused = true;
            }
            this.step = 'operator';
        },
        /**
         * Update order with the operator code of the person in charge of it.
         *
         * @param code
         */
        updateOperatorCode(code: string) {
            if (this.order) {
                this.order.operator_code = code;
                if (!this.isRefused) {
                    this.step = 'date';
                } else {
                    this.step = 'reject-comment';
                }
            }
        },
        /**
         * Add available date to the order.
         *
         * @param date
         */
        validateDate(date: string) {
            if (this.order) {
                this.step = 'comment';
                this.order.available_at = date;
            }
        },
        /**
         * Validate the end of the handling process of the order.
         *
         * @param comment
         */
        validateOrder(comment: string) {
            if (this.order) {
                this.editingOrder = true;
                if (comment) {
                    this.order.operator_comment = comment;
                }
                orderApi().patch(this.order.id, this.order)
                    .then(() => this.$emit('orderHandled'))
                    .finally(() => this.editingOrder = false);
            }
        },
        /**
         * Reject an order.
         */
        rejectOrder(comment: string) {
            if (this.order) {
                this.editingOrder = true;
                const SQLFormat = 'YYYY-MM-DD HH:mm:ss';
                const data = {
                    rejected_at: dayjs().format(SQLFormat),
                    operator_code: this.order.operator_code,
                    operator_comment: comment
                };

                orderApi().patch(this.order.id, data)
                    .then(() => this.$emit('orderHandled'))
                    .finally(() => this.editingOrder = false);
            }
        }
    }
});
</script>
