<template>
  <BaseModal
    v-if="guide"
    :title="guide.title"
    @close="$emit('close')"
  >
    <Guide
      :v-if="guide"
      :guide="guide"
    />
    <template #footer>
      <BaseButton @click="$emit('close')">
        Fermer
      </BaseButton>
    </template>
  </BaseModal>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import BaseModal from '@/core/components/base/BaseModal.vue';
import BaseButton from '@/core/components/base/BaseButton.vue';
import Guide from '@/onboarding/components/guide/Guide.vue';

export default defineComponent({
    name: 'GuideModal',
    components: {
        Guide,
        BaseModal,
        BaseButton
    },
    props: {
        guide: {
            type: Object,
            required: false,
            default: undefined
        }
    },
    emits: ['close'],
});
</script>
