<script setup lang="ts">
import type {PropType} from "vue";
import {computed} from "vue";
import Customer from "@/customer/interfaces/Customer";
import dayjs from "dayjs";
import Icon from "@/core/components/icon/Icon.vue";

const props = defineProps({
    customer: {
        type: Object as PropType<Customer>,
        required: true
    },
    profile: {
        type: Object as any | null,
        default: null
    },
    editable: {
        type: Boolean,
        default: true
    }
});
defineEmits(['edit-profile']);

const customerAge = computed(() => {
    const birthdate = dayjs(props.customer.information.birth_date);

    return Math.floor(dayjs().diff(birthdate, 'year'));
});

const breastFeedingStatus = computed(() => {
    if (!props.profile?.breastFeeding) {
        return null;
    }

    switch (props.profile.breastFeeding) {
    case 'MORE_THAN_ONE_MONTH':
        return "Allaitement: plus d'un mois";
    case 'LESS_THAN_ONE_MONTH':
        return "Allaitement: moins d'un mois";
    case 'ALL':
        return 'Allaitement';
    default:
        return null;
    }
});
const hepaticStatus = computed(() => {
    if (!props.profile?.hepaticStatus) {
        return null;
    }

    switch (props.profile.breastFeeding) {
    case 'MODERATE':
        return "Statut hépatique : modéré";
    case 'SEVERE':
        return "Statut hépatique: sévère";
    default:
        return null;
    }
});
</script>

<template>
  <div class="flex flex-col text-primary">
    <div class="flex flex-row flew-wrap">
      <div class="inline-block">
        {{ customer.information.name }}, {{ customer.information.gender === 1 ? 'Homme' : 'Femme' }}, {{ customerAge }}
        ans
      </div>
      <div v-if="editable" class="inline-block">
        <div v-if="profile?.weight" class="inline-block">
          , {{ profile.weight }} kg
        </div>
        <div v-if="profile?.height" class="inline-block">
          , {{ profile.height }} cm
        </div>
        <div v-if="profile?.amenorrhoea" class="inline-block">
          , {{ profile.amenorrhoea }} semaines d'amenorrhée
        </div>
        <div v-if="breastFeedingStatus" class="inline-block">
          , {{ breastFeedingStatus }}
        </div>
        <div v-if="breastFeedingStatus && profile?.breastFeedingDate" class="inline-block">
          depuis le {{ $filters.date(profile?.breastFeedingDate, 'dayOnly') }}
        </div>
        <div v-if="hepaticStatus" class="inline-block">
          , {{ hepaticStatus }}
        </div>
        <div v-if="profile?.dfg" class="inline-block">
          , DFG : {{ profile.dfg }} mL/min
        </div>
      </div>
      <div v-if="editable && !profile?.allergies?.length" class="inline-block">
        , aucune allergie
      </div>
      <div v-if="editable && !profile?.pathologies?.length" class="inline-block">
        , aucune pathologie
      </div>
      <icon
        v-if="editable"
        name="mdi-pencil"
        color="primary"
        class="pl-2 cursor-pointer"
        @click="$emit('edit-profile')"
      />
    </div>
    <div
      v-if="editable"
      class="flex flex-col mt-2"
    >
      <p v-if="profile?.allergies">
        <span
          v-for="(allergy, index) in profile.allergies"
          :key="allergy.id"
        >
          <span
            v-if="index === 0"
            class="font-bold"
          >
            Allergies :
          </span>
          <span v-else>, </span>{{ allergy.title }}
        </span>
      </p>
      <p v-if="profile?.pathologies">
        <span
          v-for="(pathology, index) in profile.pathologies"
          :key="pathology.id"
        >
          <span
            v-if="index === 0"
            class="font-bold"
          >
            Pathologies :
          </span>
          <span v-else>, </span>{{ pathology.title }}
        </span>
      </p>
    </div>
  </div>
</template>
