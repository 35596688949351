<template>
  <transition name="fade">
    <slot />
  </transition>
</template>

<script lang="ts">
import {defineComponent} from "vue";
export default defineComponent({
    name: 'FadeTransition'
});
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}
</style>
