<template>
  <div class="flex gap-2">
    <icon
      v-if="iconName"
      color="primary"
      :name="iconName"
    />
    <span class="font-bold text-primary">{{ item.value }}</span>
    <span class="line-clamp-1 text-primary">{{ item.label }}</span>
  </div>
</template>

<script setup lang="ts">
import {computed} from 'vue';
import {ShowcaseItem, ShowcaseType} from "@/core/interfaces/showcase";
import Icon from "@/core/components/icon/Icon.vue";

const props = defineProps<{
    item: ShowcaseItem
}>();

const iconName = computed(() => {
    switch (props.item.type) {
    case ShowcaseType.CONSUMER_COUNT:
        return 'mdi-account-group';
    case ShowcaseType.POTENTIAL:
        return 'mdi-finance';
    default:
        return null;
    }
});
</script>
