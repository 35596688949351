<template>
  <div class="flex flex-col">
    <label class="text-sm self-end">
      {{ advice.sub_title ?? "&nbsp;" }}
    </label>
    <BaseCard class="container">
      <div class="flex">
        <div class="image">
          <img
            :src="advice.image"
            :alt="advice.name"
            width="150"
          >
          <span v-if="advice.price">{{ advice.price }}€</span>
        </div>
        <div
          class="flex flex-1 flex-col font-content px-5 py-3 text-content text-sm"
          :style="{'background-color': advice.background_color}"
        >
          <div class="flex justify-between mb-2">
            <h3>
              {{ advice.name }}
            </h3>
            <div
              v-if="!!purchasedAt"
              class="text-tiny text-sans rounded-2xl bg-white px-2 flex flex-col justify-center"
            >
              {{ purchasedAt }}
            </div>
          </div>
          <div class="flex-1 text-sm pb-2">
            <div
              class="advice-content prose max-w-full"
              v-html="content"
            />
          </div>
          <div class="flex justify-end  text-end text-xs">
            {{ advice.description }}
          </div>
        </div>
      </div>
      <support-program-advice-images-carousel
        v-if="advice.images.length"
        :images="advice.images"
        :color="advice.color"
        @item-click="carouselModalVisible = true"
      />
    </BaseCard>
    <base-modal
      v-if="carouselModalVisible"
      @close="carouselModalVisible = false"
    >
      <support-program-advice-images-carousel
        :images="advice.images"
        :color="advice.color"
        height="500"
      />
    </base-modal>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from "vue";
import BaseCard from '@/core/components/base/BaseCard.vue';
import dateHelper from '@/core/helpers/dateHelper';
import {marked} from "marked";
import {ProductAdvice} from "@/program/interfaces/Program";
import SupportProgramAdviceImagesCarousel
    from "@/program/components/support-program/SupportProgramAdviceImagesCarousel.vue";
import BaseModal from "@/core/components/base/BaseModal.vue";

export default defineComponent({
    name: 'SupportProgramAdvice',
    components: {BaseModal, SupportProgramAdviceImagesCarousel, BaseCard},
    props: {
        advice: {
            type: Object as PropType<ProductAdvice & { description: string }>,
            required: true
        }
    },
    data() {
        return {
            carouselModalVisible: false
        };
    },
    computed: {
        purchasedAt() {
            return this.advice.purchased_at ? 'Acheté le ' + dateHelper.shortFormat(this.advice.purchased_at) : null;
        },
        /**
         * Parse the markdown content to HTML.
         */
        content() {
            const renderer = new marked.Renderer();

            return marked(this.advice.advices, {renderer});
        }
    }
});
</script>

<style scoped>
.container {
    width: 600px;
    padding-bottom: 16px;
    padding-horizontal: 16px;
}

.image {
    width: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

:deep(.advice-content li strong) {
    color: v-bind('advice.color');
}

:deep(.advice-content li::marker) {
    color: black;
}
</style>
