<script setup lang="ts">
import BaseCard from "@/core/components/base/BaseCard.vue";
import {Healthcenter} from "@/core/interfaces/healthcenter/Healthcenter";
import {ref, computed} from "vue";
import Icon from "@/core/components/icon/Icon.vue";

const {healthcenter} = defineProps<{ healthcenter: Healthcenter }>();
const emits = defineEmits(['update']);
const phoneRules = [
    value => (value && value.length <= 10) || 'Maximum 10 caractères',
    value => /[0-9]{10}/.test(value) || 'Numéro de téléphone non valide'
];

const updatedHealthcenter = ref<Healthcenter>({...healthcenter});

/**
 * Get contacts information.
 */
const mapContacts = (healthcenter: Healthcenter) => {
    const contact = healthcenter.contacts?.find(contact => contact.type?.slug === "booking");
    const facebook = healthcenter.contacts?.find(contact => contact.type?.slug === "facebook");
    const phone = healthcenter.contacts?.find(contact => contact.type?.slug === "phone");

    return {
        booking: contact?.value ?? "",
        facebook: facebook?.value ?? "",
        phone: phone?.value.replace(/\s/g, "") ?? ""
    };
};

const contacts = ref(mapContacts({...healthcenter}));

const isValid = computed(() => {
    for (let i = 0; i < phoneRules.length; i++) {
        let rule = phoneRules[i];
        if (rule(contacts.value.phone) !== true) {
            return false;
        }
    }
    return true;
});

/**
 * Check if healthcenter contact has been changed.
 */
const healthcenterContactChanged = (): boolean => {
    const contact = healthcenter.contacts?.find(contact => contact.type?.slug === "booking");
    const facebook = healthcenter.contacts?.find(contact => contact.type?.slug === "facebook");
    const phone = healthcenter.contacts?.find(contact => contact.type?.slug === "phone");

    return contacts.value.phone !== (phone?.value.replace(/\s/g, "") ?? "")
        || contacts.value.facebook !== (facebook ?? "")
        || contacts.value.booking !== (contact ?? "");
};

/**
 * Update information.
 */
const updateInformation = () => {
    let formData: { name?: string; url?: string; contacts?: any[] } = {};

    if (healthcenter.name !== updatedHealthcenter.value.name) {
        formData.name = updatedHealthcenter.value.name;
    }
    if (healthcenter.url !== updatedHealthcenter.value.url) {
        formData.url = updatedHealthcenter.value.url;
    }
    if (healthcenterContactChanged()) {

        formData.contacts = [];

        if (contacts.value.booking) {
            formData.contacts.push({slug: 'booking', value: contacts.value.booking});
        }

        if (contacts.value.facebook) {
            formData.contacts.push({slug: 'facebook', value: contacts.value.facebook});
        }

        if (contacts.value.phone) {
            formData.contacts.push({slug: 'phone', value: contacts.value.phone});
        }
    }

    emits('update', formData);
};
</script>

<template>
  <BaseCard class="relative p-4">
    <h2>Informations générales</h2>
    <v-text-field
      v-model="updatedHealthcenter.name"
      hide-details="auto"
      label="Nom"
      class="bg-white py-2"
    />
    <v-text-field
      v-model="updatedHealthcenter.url"
      hide-details="auto"
      label="Site internet"
      type="url"
      class="bg-white py-2"
    />
    <v-text-field
      v-model="contacts.booking"
      hide-details="auto"
      label="Rendez-vous"
      type="url"
      class="bg-white py-2"
    />
    <v-text-field
      ref="phoneInput"
      v-model="contacts.phone"
      :rules="phoneRules"
      hide-details="auto"
      validate-on="blur"
      label="Numéro de téléphone"
      type="tel"
      class="bg-white py-2"
      @update:error="console.log"
    />
    <v-text-field
      v-model="contacts.facebook"
      hide-details="auto"
      label="Facebook"
      type="url"
      class="bg-white py-2"
    />
    <div
      v-if="isValid"
      class="absolute bottom-5 right-5 flex flex-1 justify-center p-4 rounded-full cursor-pointer bg-primary"
      @click="updateInformation"
    >
      <Icon name="mdi-content-save" color="white" />
    </div>
  </BaseCard>
</template>
