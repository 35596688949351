<template>
  <icon
    name="mdi-account-group"
    :size="40"
    color="black"
  />
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import Icon from "@/core/components/icon/Icon.vue";

export default defineComponent({
    name: "HealthcenterGroupIcon",
    components: {Icon}
});
</script>

<style scoped>

</style>
