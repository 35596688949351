import {AxiosInstance} from "axios";

export default function (apiClient: AxiosInstance) {
    return {

        /**
     * Index attachments for a given attachable.
     *
     * @param {'programs'|'program-categories'} attachableType - The attachable type.
     * @param {number} attachableId - The attachable id.
     * @param {Object} params - The query params.
     *
     * @return {Promise<Array>}
     */
        attachmentIndex (attachableType: string, attachableId: number, params = {}) {
            return apiClient.get(`${attachableType}/${attachableId}/attachments`, { params })
                .then(response => response.data.data);
        },

        /**
     * Get the thumbnail render for a given attachment.
     *
     * @param {'programs'|'program-categories'} attachableType - The attachable type.
     * @param {number} attachableId - The attachable id.
     * @param {number} attachmentId - The attachment id.
     *
     * @return {Promise<Object>}
     */
        renderThumbnail (attachableType: string, attachableId: number, attachmentId: number) {
            return apiClient.get(`${attachableType}/${attachableId}/attachments/${attachmentId}/thumbnail`, { responseType: 'arraybuffer' })
                .then(response => response.data);
        },

        /**
     * Get the render for a given attachment.
     *
     * @param attachableType
     * @param attachableId
     * @param attachmentId
     * @return {Promise<Object>}
     */
        renderAttachment (attachableType: string, attachableId: number, attachmentId: number) {
            return apiClient.get(`${attachableType}/${attachableId}/attachments/${attachmentId}/render`, { responseType: 'blob' })
                .then(response => response.data);
        },

        /**
     * Index attachments for a given program.
     *
     * @param {Number} programId - The program id.
     * @param {Object} params - The query params.
     * @return {Promise<Array>}
     */
        programIndex (programId: number, params = {}) {
            return apiClient.get(`programs/${programId}/attachments`, { params })
                .then(response => response.data.data);
        },

        /**
     * Render the thumbnail for a given attachment.
     *
     * @param {number|string} programId - The program id.
     * @param {number} attachmentId - The attachment id
     *
     * @return {Promise<Object>}
     */
        renderProgramThumbnail (programId: number, attachmentId: number) {
            return apiClient.get(`programs/${programId}/attachments/${attachmentId}/thumbnail`, { responseType: 'arraybuffer' })
                .then(response => response.data);
        },

        /**
     * Render the attachment for a given attachment.
     *
     * @param {string|number} programId - The program id.
     * @param {number} attachmentId - The program id.
     *
     * @return {Promise<Object>}
     */
        renderProgramAttachment (programId: number, attachmentId: number) {
            return apiClient.get(`programs/${programId}/attachments/${attachmentId}/render`, { responseType: 'blob' })
                .then(response => response.data);
        }
    };
}
