<template>
  <div v-if="isLoading">
    <base-flex-spinner />
  </div>
  <div
    v-else
    class="mx-6"
  >
    <support-program-pathway-timeline
      v-if="pathway"
      :pathway="pathway"
    />
    <div v-else>
      <p>Aucun parcours de soin n'existe pour ce programme.</p>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent, PropType} from "vue";
import {programApi} from '@/container';
import SupportProgramPathwayTimeline
    from '@/program/components/support-program-pathway-timeline/SupportProgramPathwayTimeline.vue';
import BaseFlexSpinner from "@/core/components/base/spinner/BaseFlexSpinner.vue";
import {Program} from "@/program/interfaces/Program";

export default defineComponent({
    name: 'SupportProgramPathwayDetail',
    components: {BaseFlexSpinner, SupportProgramPathwayTimeline},
    props: {
        program: {
            type: Object as PropType<Program>,
            required: true
        }
    },
    data() {
        return {
            isLoading: true,
            pathway: null
        };
    },
    watch: {
        'program': {
            immediate: true,

            handler(value: Program) {
                this.readPathway(value.programmable_id);
            }
        }
    },
    methods: {
        /**
         * Read the support program pathway.
         *
         * @param id - The support program id.
         * @return {Promise<void>}
         */
        readPathway(id: number) {
            this.isLoading = true;
            return programApi()
                .pathway(id)
                .then(pathway => {
                    this.pathway = pathway;
                })
                .finally(() => {
                    this.isLoading = false;
                });
        }
    }
});
</script>
