import {AxiosInstance} from "axios";

/**
 * Resource for survey answered instances.
 *
 * @param client
 * @return {Object}
 */
export default function (client: AxiosInstance) {
    return {
    /**
     * Get the answered instances.
     *
     * @param params
     * @return {*}
     */
        answeredInstance (params: unknown) {
            return client.get('/surveys/answered-instances', { params })
                .then(response => response.data);
        },
        /**
     * Get the survey answer instance export.
     *
     * @param instanceID
     * @returns {Promise<Blob>}
     */
        export (instanceID: number): Promise<Blob> {
            return client.get(`survey-answer-instances/${instanceID}/export`, { responseType: 'blob' })
                .then(response => response.data);
        }
    };
}
