<template>
  <base-modal
    :title="title"
    @close="cancel"
  >
    <div class="flex flex-col gap-3">
      <p>{{ message }}</p>
      <div class="flex gap-5 self-center">
        <base-button
          :primary="false"
          :disabled="isLoading"
          class="font-bold"
          @click="cancel"
        >
          Annuler
        </base-button>
        <base-button
          primary
          :disabled="isLoading"
          @click="confirm"
        >
          <base-spinner
            v-if="isLoading"
            class="mx-auto"
            size="button"
            color="white"
          />
          <span
            v-else
            class="font-bold"
          >
            Confirmer
          </span>
        </base-button>
      </div>
    </div>
  </base-modal>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import BaseButton from "@/core/components/base/BaseButton.vue";
import BaseSpinner from "@/core/components/base/spinner/BaseSpinner.vue";
import BaseModal from "@/core/components/base/BaseModal.vue";

export default defineComponent({
    name: "ConfirmationModal",
    components: {BaseModal, BaseSpinner, BaseButton},
    emits: ['close'],
    data() {
        return {
            title: '',
            message: '',
            isLoading: false,
            successCallback: undefined as undefined | (() => never | Promise<never>)
        };
    },
    methods: {
    /**
     * Show the modal.
     *
     * @param {String} title The title.
     * @param {String} message The message.
     * @param {() => any | Promise<any>} success The success callback.
     */
        show(title: string, message: string, success: () => never | Promise<never>) {
            this.title = title;
            this.message = message;
            this.successCallback = success;
        },
        /**
     * Handle the modal confirmation.
     */
        confirm() {
            if (!this.successCallback) {
                return;
            }

            const callback = this.successCallback();

            if (callback instanceof Promise) {
                this.handlePromiseCallback(callback);
            } else {
                this.$emit('close');
            }
        },
        /**
     * Handle the confirmation of type Promise.
     *
     * @param {Promise} callback The callback.
     */
        handlePromiseCallback(callback: Promise<never>) {
            this.isLoading = true;
            callback
                .then(() => {
                    this.isLoading = false;
                    this.$emit('close');
                })
                .catch(() => {
                    this.isLoading = false;
                });
        },
        /**
     * Cancel the modal.
     */
        cancel() {
            this.$emit('close');
        }
    }
});
</script>

<style scoped>

</style>
