<template>
  <base-container>
    <header-back-button :title="group?.name ?? $route.query.name as string ?? 'Détails du groupe'" />
    <div
      v-if="!loading"
      class="flex flex-1 flex-col p-6 gap-6"
    >
      <template v-if="group">
        <div class="flex gap-3">
          <p
            v-if="!editMode"
            class="text-center text-primary flex-1"
          >
            {{ group.description }}
          </p>
          <div
            v-if="editMode"
            class="flex-1"
          />
          <base-button
            v-if="isOwner"
            class="flex items-center gap-2 bg-white self-start"
            @click="editMode = !editMode"
          >
            <v-icon
              :size="20"
              color="black"
            >
              mdi-pencil
            </v-icon>
            <span>
              Modifier le groupe
            </span>
          </base-button>
        </div>
        <healthcenter-group-edit-form
          v-if="editMode"
          :group="group"
          @saved="handleGroupUpdated"
        />
        <invite-healthcenter-card
          v-if="isOwner"
          :healthcenter-group-id="group.id"
          @invitation-sent="handleInvitationSent"
        />
        <healthcenter-group-sent-invitation-card
          v-if="!!pendingInvitations?.length && isOwner"
          :invitations="pendingInvitations"
          @cancel-invitation="deleteInvitation"
        />
        <healthcenter-group-healthcenters
          :owner-access-id="group.creator_access_id"
          :healthcenters="group.healthcenters"
          :group-id="group.id"
          @member-deleted="handleMemberDeleted"
        />
        <base-button
          v-if="isOwner"
          class="bg-red-500 hover:bg-red-300 group"
          @click="handleDeleteGroup"
        >
          <span class="text-white font-bold group-hover:text-black">Supprimer le groupe</span>
        </base-button>
      </template>
      <div v-else>
        <p class="text-center">
          Une erreur s'est produite lors du chargement des données.
        </p>
      </div>
    </div>
    <base-flex-spinner v-else />
  </base-container>
</template>

<script lang="ts">
import {defineComponent, inject} from 'vue';
import {HealthcenterGroup, HealthcenterGroupInvitation} from "@/disruption-logistics/interfaces/healthcenter-groups";
import HealthcenterGroupService from "@/disruption-logistics/services/HealthcenterGroupService";
import {disruptionLogisticsApi} from "@/container";
import {useToastStore} from "@/stores/toast.store";
import BaseContainer from "@/core/components/base/BaseContainer.vue";
import HeaderBackButton from "@/core/components/header-back-button/HeaderBackButton.vue";
import BaseButton from "@/core/components/base/BaseButton.vue";
import BaseFlexSpinner from "@/core/components/base/spinner/BaseFlexSpinner.vue";
import HealthcenterGroupEditForm
    from "@/disruption-logistics/components/healthcenter-groups/HealthcenterGroupEditForm.vue";
import InviteHealthcenterCard from "@/disruption-logistics/components/healthcenter-groups/InviteHealthcenterCard.vue";
import HealthcenterGroupSentInvitationCard
    from "@/disruption-logistics/components/healthcenter-groups/HealthcenterGroupSentInvitationCard.vue";
import HealthcenterGroupHealthcenters
    from "@/disruption-logistics/components/healthcenter-groups/HealthcenterGroupHealthcenters.vue";

export default defineComponent({
    name: "HealthcenterGroupDetailsView",
    components: {
        HealthcenterGroupHealthcenters,
        HealthcenterGroupSentInvitationCard,
        InviteHealthcenterCard,
        HealthcenterGroupEditForm, BaseFlexSpinner, BaseButton, HeaderBackButton, BaseContainer
    },
    inject: ['openConfirmationModal'],
    setup() {
        const openConfirmationModal = inject<any>('openConfirmationModal');

        return {
            openConfirmationModal
        };
    },
    data() {
        return {
            group: undefined as undefined | HealthcenterGroup,
            pendingInvitations: undefined as undefined | HealthcenterGroupInvitation[],
            loading: true,
            editMode: false
        };
    },
    computed: {
    /**
     * Check if the user is the owner of the group.
     */
        isOwner() {
            if (!this.group) {
                return false;
            }

            return HealthcenterGroupService.isOwner(this.group.creator_access_id);
        }
    },
    watch: {
        '$route.params.healthcenterGroupId': {
            handler: function (value) {
                this.loading = true;
                this.load(value)
                    .finally(() => {
                        this.loading = false;
                    });
            },
            immediate: true
        }
    },
    methods: {
    /**
     * Load the page data.
     */
        async load(groupId: number): Promise<void> {
            return this.readGroup(groupId)
                .then(() => {
                    if (this.isOwner) {
                        return this.invitationsSent(groupId);
                    }

                    return Promise.resolve();
                });
        },
        /**
     * Read a healthcenter group.
     *
     * @param {Number} groupId - The healthcenter group id
     */
        async readGroup(groupId: number) {
            return disruptionLogisticsApi()
                .readHealthcenterGroup(groupId)
                .then(group => {
                    this.group = group;
                });
        },
        /**
     * Get the invitations sent by the healthcenter group.
     */
        async invitationsSent(groupId: number): Promise<void> {
            return disruptionLogisticsApi()
                .sentHealthcenterGroupInvitations(groupId, 'pending')
                .then(invitations => {
                    this.pendingInvitations = invitations;
                });
        },
        /**
     * Delete an invitation.
     *
     * @param {Object} invitation - The invitation to delete
     */
        async deleteInvitation(invitation: HealthcenterGroupInvitation) {
            return disruptionLogisticsApi()
                .deleteHealthcenterGroupInvitation(this.group?.id, invitation.id)
                .then(() => {
                    this.pendingInvitations = this.pendingInvitations?.filter(i => i.id !== invitation.id);
                });
        },
        /**
     * Add the sent invitation to the pending invitations.
     */
        handleInvitationSent(invitation: HealthcenterGroupInvitation) {
            const pendingIds = this.pendingInvitations?.map(_invitation => _invitation.id);

            if (!pendingIds?.includes(invitation.id)) {
                this.pendingInvitations?.push(invitation);
            }
        },
        /**
     * Remove the healthcenter from the group, and update the member count var.
     */
        handleMemberDeleted(healthcenterId: number) {
            if (!this.group) {
                return;
            }

            this.group.healthcenters = this.group.healthcenters.filter(healthcenter => healthcenter.id !== healthcenterId);
            this.group.member_count -= 1;
        },

        /**
     * Ask for confirmation before deleting the group.
     */
        handleDeleteGroup() {
            this.openConfirmationModal(
                'Suppression du groupe',
                'Êtes-vous sûr de vouloir supprimer ce groupe ? Les membres ne pourront plus accéder aux informations du groupe ni aux données partagées.',
                this.deleteGroup
            );
        },

        /**
     * Delete the group.
     */
        async deleteGroup(): Promise<void> {
            if (!this.group) {
                return Promise.resolve();
            }

            return disruptionLogisticsApi()
                .deleteHealthcenterGroup(this.group.id)
                .then(() => {
                    const toast = useToastStore();
                    toast.showSuccess({content: 'Le groupe a bien été supprimé'});
                    this.$router.go(-1);
                });
        },

        /**
     * Handle the group updated event.
     *
     * @param name
     * @param description
     */
        handleGroupUpdated({name, description}: { name: string, description: string }) {
            if (!this.group) {
                return;
            }

            this.group.name = name;
            this.group.description = description;

            this.editMode = false;
            const toast = useToastStore();
            toast.showSuccess({content: 'Le groupe a bien été mis à jour'});
        }
    }
});
</script>

<style scoped>

</style>
