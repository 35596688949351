<script setup lang="ts">
import {BackingStep} from "@/backing/interfaces/backing";
import Icon from "@/core/components/icon/Icon.vue";

defineProps<{
  step: BackingStep
}>();
</script>

<template>
  <div>
    <icon name="mdi-check-bold" :size="20" class="text-secondary mr-1" />
    <span class="text-sm">
      {{ step.name }}: {{ step.completed_at ? $filters.date(step.completed_at, 'short') : '' }}
    </span>
  </div>
</template>
