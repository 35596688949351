import {AxiosInstance} from "axios";

/**
 * The document resource.
 *
 * @param {Object} client - The injected client.
 * @returns {Object} The public object.
 */
export default function (client: AxiosInstance) {
    return {
        get (url: string) {
            url = url.replace(import.meta.env.VITE_HDS_URL, '');

            return client.get(url, { responseType: 'blob', validateStatus:null })
                .then(response => {
                    if (response.status !== 200) {
                        return Promise.reject(response);
                    }

                    return response.data;
                });
        }
    };
}
