<script setup lang="ts">
import {ref, type PropType} from "vue";
import Customer from "@/customer/interfaces/Customer";
import dayjs from "dayjs";
import BaseButton from "@/core/components/base/BaseButton.vue";
import DrugInteractionAllergies, {VidalAllergy} from "@/drug-interaction/components/DrugInteractionAllergies.vue";
import DrugInteractionPathologies, {VidalPathology} from "@/drug-interaction/components/DrugInteractionPathologies.vue";

const props = defineProps({
    customer: {
        type: Object as PropType<Customer>,
        required: true
    },
    profile: {
        type: Object as any | null,
        default: null
    },
});
const emit = defineEmits(['on-finish']);

const weight = ref(props?.profile?.weight || 0);
const height = ref(props?.profile?.height || 0);
const amenorrhoea = ref(props?.profile?.amenorrhoea || 0);
const breastFeeding = ref(props?.profile?.breastFeeding || 'NONE');
const hepatic = ref(props?.profile?.hepatic || 'NONE');
const dfg = ref(props?.profile?.dfg || 0);
const breastFeedingDate = ref(props?.profile?.breastFeedingDate || dayjs().format('YYYY-MM-DD'));
const pathologies = ref<VidalPathology[]>(props?.profile?.pathologies || []);
const allergies = ref<VidalAllergy[]>(props?.profile?.allergies || []);

/**
 * Save profile.
 */
const saveProfile = () => {
    const profile = {weight, height, amenorrhoea, hepatic, dfg, breastFeeding, breastFeedingDate, pathologies, allergies};
    emit('on-finish', profile);
};
</script>

<template>
  <div class="mt-2 py-2 px-4 flex flex-col border-2 rounded-lg border-primary/50 flex-wrap">
    <div class="form-row">
      <div class="row">
        <p>Poids</p>
        <input
          v-model="weight"
          class="form-input max-w-24"
          type="number"
          min="0"
        >
      </div>
      <div class="row">
        <p>Taille</p>
        <input
          v-model="height"
          class="form-input max-w-24"
          type="number"
          min="0"
        >
      </div>
    </div>
    <div class="form-row">
      <div class="row">
        <p class="max-w-32">
          Semaines d'aménorrhée
        </p>
        <input
          v-model="amenorrhoea"
          class="form-input max-w-24"
          type="number"
          min="0"
        >
      </div>
      <div class="row">
        <p>Allaitement</p>
        <select
          v-model="breastFeeding"
          name="breastfeeding"
          class="form-input min-w-40"
        >
          <option value="NONE">
            Aucune
          </option>
          <option value="LESS_THAN_ONE_MONTH">
            Moins d'1 mois
          </option>
          <option value="MORE_THAN_ONE_MONTH">
            Plus d'1 mois
          </option>
          <option value="ALL">
            Allaitement
          </option>
        </select>
      </div>
      <div class="row">
        <p class="max-w-32">
          Date de début de l'allaitement
        </p>
        <input
          v-model="breastFeedingDate"
          class="form-input max-w-36"
          type="date"
        >
      </div>
    </div>
    <div class="form-row">
      <div class="row">
        <p>Insuffisance hépatitque</p>
        <select
          v-model="hepatic"
          name="hepatic"
          class="form-input max-w-36"
        >
          <option value="NONE">
            Aucune
          </option>
          <option value="MODERATE">
            Modéré
          </option>
          <option value="SEVERE">
            Sévère
          </option>
        </select>
      </div>
      <div class="row">
        <p>DFG ml/min</p>
        <input
          v-model="dfg"
          class="form-input max-w-24"
          type="number"
        >
      </div>
    </div>
    <drug-interaction-allergies
      :patient-allergies="allergies"
      @update="(newAllergies) => allergies = newAllergies"
    />
    <drug-interaction-pathologies
      :patient-pathologies="pathologies"
      @update="(newPathologies) => pathologies = newPathologies"
    />
    <div class="flex flex-1 py-4 items-center justify-center">
      <base-button
        primary
        @click="saveProfile()"
      >
        Terminer
      </base-button>
    </div>
  </div>
</template>

<style lang="postcss" scoped>
.form-input {
  @apply shadow-md focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-r-4 border-solid border-0 border-b-4 border-b-primary/30 border-r-primary/30 rounded-md mx-4
}

.form-row {
  @apply flex flex-wrap items-center py-2
}

.row {
  @apply flex flex-row items-center py-2 pr-5
}
</style>
