<template>
  <div
    class="flex flex-col cursor-pointer"
    @click="navigateToBuiltInstance"
  >
    <div class="text-sm">
      {{ formatHelper.readableDate(instance.created_at) }}
    </div>
    <base-card class="flex flex-1">
      <base-card-body>
        <div class="flex items-start">
          <img
            alt="Survey"
            width="30"
            src="@/assets/icons/survey.svg"
            class="cursor-pointer mr-5"
          >
          <div>
            <strong>{{ instance.survey.name }}</strong>
            <div
              v-if="instance.verdict"
              class="flex flex-row items-start mt-2"
            >
              <img
                alt="Verdict"
                src="@/assets/images/verdict-chevrons.png"
                class="mr-3 w-8 object-contain mt-1"
              >
              <p class="text-sm">
                {{ instance.verdict.title }}
              </p>
            </div>
          </div>
        </div>
      </base-card-body>
    </base-card>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import BaseCard from '@/core/components/base/BaseCard.vue';
import formatHelper from '@/core/helpers/formatHelper';
import BaseCardBody from '@/core/components/base/BaseCardBody.vue';

export default defineComponent({
    name: 'CustomerSurveyCard',
    components: { BaseCardBody, BaseCard },
    props: {
        instance: {
            type: Object,
            required: true
        }
    },
    computed: {
        formatHelper () {
            return formatHelper;
        }
    },
    methods: {
        navigateToBuiltInstance () {
            this.$router.push({
                name: 'customer.survey',
                params: { instanceId: this.instance.id, verdict: this.instance.verdict }
            });
        }
    }
});
</script>
