<template>
  <div>
    <div
      v-for="section in survey.sections"
      :key="`section-${section.id}`"
    >
      <h2 class="text-base mb-4">
        {{ section.title }}
      </h2>
      <base-container>
        <div
          v-for="question in section.questions"
          :id="`question-${question.id}`"
          :key="question.id"
          style="scroll-margin: 90px"
        >
          <div class="flex flex-col text-sm mb-1">
            <p>
              {{ question.title }}
            </p>
          </div>
          <survey-built-answer
            :question="question"
            :survey-id="survey.id"
            :editable="false"
            :density="'compact'"
            :value="question.user_answer.value"
          />
          <v-divider class="mt-4" />
        </div>
      </base-container>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import SurveyBuiltAnswer from '@/survey/components/SurveyBuiltAnswer.vue';
import BaseContainer from '@/core/components/base/BaseContainer.vue';

export default defineComponent({
    name: 'InterviewSurveyReport',
    components: {BaseContainer, SurveyBuiltAnswer},
    props: {
        survey: {
            type: Object,
            required: true
        }
    },
});
</script>
