<template>
  <div class="w-full pointer-events-auto rounded-l-3xl" @click="click()">
    <div class="flex items-start">
      <router-view />
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
export default defineComponent({
    methods: {
        close () {
            window.ipcRenderer.send('hide-notification');
        },
        /**
         * Notify the main process that the window was clicked.
         */
        click() {
            window.ipcRenderer.click();
        }
    }
});
</script>
