<template>
  <div class="flex flex-col relative bg-gray-100">
    <div class="container__titles flex flex-row bg-lightPurple-lightest p-6 items-center justify-between">
      <div>
        <p class="text-3xl xl:text-6xl font-extrabold text-primary text-shadow-xl">
          <slot name="title" />
        </p>
        <slot name="subtitle" />
      </div>
      <div class="hidden md:block">
        <slot name="image" />
      </div>
    </div>
    <div class="container__content p-6 flex flex-col md:flex-row items-center bg-white flex-wrap gap-y-2">
      <div class="flex flex-col flex-1 mr-6">
        <slot name="content" />
      </div>
      <slot name="download" />
    </div>
    <div class="flex flex-row pl-2">
      <div
        class="bg-orange-default cursor-pointer bg-opacity-30 relative flex self-start p-2 text-primary font-bold text-sm px-6 flex-row items-center"
        style="border-bottom-left-radius: 10px; border-bottom-right-radius: 10px;"
        @click="$emit('openGuide')"
      >
        <v-icon
          class="w-6 h-6 mr-2"
          color="primary"
        >
          mdi-help-circle-outline
        </v-icon>
        <p>Suivre le guide →</p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from 'vue';

export default defineComponent({
    name: 'InstallationStepLayout',
    emits: ['openGuide']
});
</script>

<style scoped>
.container__content {
    @apply rounded-b-lg
}
</style>
