<script setup lang="ts">
import {ref, watch, computed} from "vue";
import {programApi} from "@/container";
import {SupportProgramSettings} from "@/program/interfaces/Program";

const props = withDefaults(defineProps<{
        supportProgramId: number,
        labelDirection?: "left" | "right",
        color?: string,
    }>(),
{labelDirection: "right", color: "primary"});

const isLoading = ref(true);
const settings = ref<SupportProgramSettings | null>(null);

/**
 * Load the support program settings.
 */
const loadSettings = async () => {
    isLoading.value = true;
    settings.value = await programApi().supportProgramSettings(props.supportProgramId);
    isLoading.value = false;
};

watch(() => props.supportProgramId, () => {
    loadSettings();
}, {immediate: true});

const notificationEnabled = computed(() => {
    return settings.value?.counter_notifications !== "none";
});

/**
 * Update the model value.
 */
const handleModelValueChange = async (value: boolean | null) => {
    if (!settings.value) {
        return;
    }

    settings.value.counter_notifications = value ? "all" : "none";
    isLoading.value = true;
    settings.value = await programApi().updateSupportProgramSettings(settings.value.id, settings.value);
    isLoading.value = false;
};
</script>

<template>
  <v-switch
    class="flex-row-reverse"
    :model-value="notificationEnabled"
    :label="labelDirection === 'left' ? undefined : notificationEnabled ? 'Notifications activées' : 'Notifications désactivées'"
    :color="color"
    :disabled="isLoading"
    :loading="isLoading"
    @update:model-value="handleModelValueChange"
  >
    <template v-if="labelDirection === 'left'" #prepend>
      <v-label>
        {{
          notificationEnabled
            ? 'Notifications activées'
            : 'Notifications désactivées'
        }}
      </v-label>
    </template>
  </v-switch>
</template>
