<template>
  <div class="flex flex-row items-end gap-1 flex-wrap">
    <base-button
      v-bind="$attrs"
      :class="experienceEvent ? 'shiny' : ''"
      :style="buttonStyle"
      :primary="primary"
      @click="handleClick"
    >
      <slot />
    </base-button>
    <div
      v-if="experienceEvent"
      class="flex items-end grow"
    >
      <img
        alt="coin"
        src="@/assets/images/apodis-coin.png"
        class="w-4 aspect-square"
      >
      <span class="text-sm font-semibold leading-none text-primary">
        +{{ experienceEvent.value }}
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
import BaseButton, {Props as ButtonProps} from '@/core/components/base/BaseButton.vue';
import {useExperienceStore} from "@/stores/experience.store";
import {computed} from "vue";

interface Props extends ButtonProps {
    buttonStyle?: string;
    eventName: string;
}

const {eventName, buttonStyle} = defineProps<Props>();

const emits = defineEmits(['click']);
const {eventByName} = useExperienceStore();

defineOptions({
    inheritAttrs: false
});

/**
 * The experience event extracted from the store.
 *
 * @return {Object | undefined}
 */
const experienceEvent = computed(() => {
    return eventByName(eventName);
});

const handleClick = (event: Event) => {
    emits('click', event);
};
</script>

<style scoped>

.shiny {
    border: none !important;
    overflow: hidden;
}

.shiny:before {
    position: absolute;
    content: '';
    display: inline-block;
    top: -180px;
    left: 0;
    width: 30px;
    height: 100%;
    background-color: #fff;
    animation: shiny-btn1 3s ease-in-out infinite;
}

.shiny:hover {
    opacity: .7;
}

.shiny:active {
    box-shadow: 4px 4px 6px 0 rgba(255, 255, 255, .3),
    -4px -4px 6px 0 rgba(116, 125, 136, .2),
    inset -4px -4px 6px 0 rgba(255, 255, 255, .2),
    inset 4px 4px 6px 0 rgba(0, 0, 0, .2);
}

@-webkit-keyframes shiny-btn1 {
    0% {
        -webkit-transform: scale(0) rotate(45deg);
        opacity: 0;
    }
    80% {
        -webkit-transform: scale(0) rotate(45deg);
        opacity: 0.5;
    }
    81% {
        -webkit-transform: scale(4) rotate(45deg);
        opacity: 1;
    }
    100% {
        -webkit-transform: scale(50) rotate(45deg);
        opacity: 0;
    }
}
</style>
