<template>
  <div class="flex flex-col">
    <div
      class="relative items-center"
      :style="{
        minHeight: '200px',
        'background': `linear-gradient(260deg, rgba(255,255,255,0.5) 0%, ${category.color} 100%)`
      }"
    >
      <div class="flex px-4 items-center gap-6 z-30 justify-center flex-col xl:flex-row">
        <div class="flex-row flex gap-6 z-20">
          <img
            alt="category illustration"
            :src="category.illustration_url"
            class="w-24 object-contain"
          >
          <section class="p-4">
            <p>{{ category.description }}</p>
          </section>
        </div>
        <div class="flex flex-row gap-x-6 z-20 mb-6 xl:mb-0">
          <div class="p-4 rounded-md bg-white shadow-xl flex flex-row items-center">
            <span class="text-xl text-primary font-bold mr-2">{{ category.consumer_count }}</span>
            <span>Patients</span>
            <chevron-right-icon
              :size="15"
              class="text-primary ml-4"
            />
          </div>
          <div class="p-4 rounded-md bg-white shadow-xl flex flex-row items-center">
            <span class="text-xl text-primary font-bold mr-2">{{ category.programs.length }}</span>
            <span>Programmes</span>
            <chevron-right-icon
              :size="15"
              class="text-primary ml-4"
            />
          </div>
        </div>
      </div>
      <pathocurve
        style="transform: scaleX(-1)"
        class="absolute bottom-0 h-full left-0  transform rotate-z-180"
        :style="`color: ${darkenColor(category.color, 0.03)};`"
      />
    </div>
    <program-attachment-grid
      v-if="attachments"
      class="m-4 mt-8"
      :attachments="attachments"
      :attachable-id="category.id"
      :tint-color="category.color"
      attachable-type="program-categories"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Pathocurve from '@/assets/images/icons-vue/pathocurve.vue';
import ChevronRightIcon from '@/core/components/icons/ChevronRightIcon.vue';
import { programAttachmentApi } from '@/container';
import ProgramAttachmentGrid from '@/program/components/program-attachment/ProgramAttachmentGrid.vue';
import colorHelper from '@/core/helpers/colorHelper';

/**
 * The program bundle program category resource component.
 */
export default defineComponent({
    name: 'ProgramBundleProgramCategoryResource',
    components: { ChevronRightIcon, Pathocurve, ProgramAttachmentGrid },
    props: {
    /**
     * The category to get the attachments from.
     */
        category: {
            type: Object,
            required: true
        }
    },
    data () {
        return {
            attachments: null
        };
    },
    /**
   * Fetch the attachments for the category.
   */
    mounted () {
        programAttachmentApi()
            .attachmentIndex('program-categories', this.category.id)
            .then(attachments => {
                this.attachments = attachments;
            });
    },
    methods: {
    /**
     * Darken a color.
     *
     * @param {string} color - The color to darken.
     * @param {number} amount - The amount to darken the color.
     *
     * @return {string}
     */
        darkenColor (color, amount = 0.1) {
            return colorHelper.darkenColor(color, amount);
        }
    }
});
</script>
