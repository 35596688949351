import {useSystemStore} from "@/stores/system.store";

const HealthcenterGroupService = () => {
    /**
     * Check if the given environment is the owner of the group.
     */
    const isOwner = (ownerAccessId: number) => {
        const store = useSystemStore();
        const environment = store.$state.environment;

        if (!environment) {
            return false;
        }

        return ownerAccessId === environment.id;
    };

    /**
     * Determine if the given healthcenter ID the same as the authenticated healthcenter.
     */
    const healthcenterIsAuth = (healthcenterId: number) => {
        const store = useSystemStore();
        const authHealthcenterId = store.$state.environment?.entityId;

        if (!healthcenterId || !authHealthcenterId) {
            return false;
        }

        return healthcenterId === authHealthcenterId;
    };

    return {
        isOwner,
        healthcenterIsAuth
    };
};

export default HealthcenterGroupService();
