<template>
  <installation-step-layout @open-guide="$emit('openGuide')">
    <template #title>
      Installer Apodis Pro
    </template>
    <template #subtitle>
      Pilotez votre officine
    </template>
    <template #image>
      <img
        :src="gamificationLevel5"
        alt="image gamification"
        class="h-24 xl:h-32 transform rotate-12 drop-shadow-xl"
      >
    </template>
    <template #content>
      <p class="text-primary">
        Mettez la data au profit de vos objectifs<br>
        <strong>Disponible sur mobile et tablette</strong>
      </p>
    </template>
    <template #download>
      <div class="flex flex-row flex-wrap justify-center flew-wrap gap-2 my-4 mx-4">
        <div>
          <img
            :src="proAppQrCode"
            class="w-24 object-contain mr-8"
            alt="app store qr code"
          >
          <label class="text-sm text-center text-primary font-bold">Scannez-moi</label>
        </div>
        <button @click="$emit('openAppStore')">
          <img
            alt="download app store"
            :src="downloadAppStoreIcon"
          >
        </button>
        <button @click="$emit('openPlayStore')">
          <img
            alt="download playstore"
            :src="downloadPlayStoreIcon"
          >
        </button>
      </div>
    </template>
  </installation-step-layout>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import InstallationStepLayout from '@/onboarding/components/installation-process/InstallationStepLayout.vue';
import downloadPlayStoreIcon from '@/assets/icons/download-playstore.svg';
import downloadAppStoreIcon from '@/assets/icons/download-appstore.svg';
import proAppQrCode from '@/assets/images/pro-app-qr-code.png';
import gamificationLevel5 from '@/assets/images/gamification-level-5.png';

export default defineComponent({
    name: 'ApodisProStep',
    components: {InstallationStepLayout},
    emits: ['openGuide', 'openPlayStore', 'openAppStore'],
    data() {
        return {
            downloadPlayStoreIcon,
            downloadAppStoreIcon,
            proAppQrCode,
            gamificationLevel5
        };
    }
});
</script>
