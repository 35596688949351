<template>
  <CustomerCertificationQrCode
    v-if="!hasValidCertification"
    :customer="localCustomer"
    :simple="true"
  />
  <img
    v-else
    alt="Certifié"
    class="rounded-xl bg-white p-2 cursor-pointer"
    src="@/assets/icons/certified.svg"
    @click="redirectToOnboarding"
  >
</template>

<script lang="ts" setup>
import Customer from "@/customer/interfaces/Customer";
import {computed, onMounted, onUnmounted, ref} from "vue";
import {useRouter} from "vue-router";
import CustomerCertificationQrCode from "@/customer-certification/components/CustomerCertificationQrCode.vue";

const router = useRouter();

const props = defineProps<{
    customer: Customer;
}>();

const localCustomer = ref(props.customer);
const hasValidCertification = computed(() => !!localCustomer.value?.certification?.validated_at);

let unsubscribeEvent: (() => void) | null = null;

/**
 * On mount, subscribe to customer channel and listen for certification updates.
 */
onMounted(() => {
    window.ipcRenderer.socketSubscribe(`private-customers.${props.customer.id}`);
    unsubscribeEvent = window.ipcRenderer.socketListenEvent('certification.updated', (event, {data}) => {
        refresh(data);
    });
});

/**
 * On unmount, unsubscribe from customer channel.
 */
onUnmounted(() => {
    if(unsubscribeEvent) {
        unsubscribeEvent();
    }
    window.ipcRenderer.socketUnsubscribe(`private-customers.${props.customer.id}`);
});

/**
 * Refresh the certification.
 *
 * @param certification
 */
const refresh = (certification: any) => {
    localCustomer.value.certification = certification;
};

/**
 * Redirect to onboarding.
 */
const redirectToOnboarding = () => {
    router.push({ name: 'customer.certification' });
};
</script>
