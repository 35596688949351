<template>
  <div>
    <button
      class="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      @click="open()"
    >
      {{ title }}
    </button>

    <BaseModal
      v-show="opened"
      :title="title"
      @close="opened = false"
    >
      <div v-if="attributable">
        <BaseInput
          :value="amount"
          type="number"
          label="Nombre de points"
          :errors="error.amount"
          @input="(value) => amount = value?.toString()"
        />
      </div>

      <div v-else>
        <div class="bg-yellow-50 border-l-4 border-yellow-400 p-4">
          <div class="flex">
            <div class="flex-shrink-0">
              <svg
                class="h-5 w-5 text-yellow-400"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z"
                  clip-rule="evenodd"
                />
              </svg>
            </div>
            <div class="ml-3">
              <p class="text-sm text-yellow-700">
                La carte du membre ne permet pas d’obtenir la récompense.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="mt-2 border-b border-gray-200">
        <dl class="sm:divide-y sm:divide-gray-200">
          <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
            <dt class="text-sm font-medium text-gray-500">
              Avantage
            </dt>

            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {{ discount }}€
            </dd>
          </div>

          <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
            <dt class="text-sm font-medium text-gray-500">
              Avantages cumulés
            </dt>

            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {{ membership.formatted.point_balance }}
            </dd>
          </div>

          <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
            <dt class="text-sm font-medium text-gray-500">
              Récompenses
            </dt>

            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <ul
                v-for="objective in objectives"
                :key="objective.id"
              >
                <li>
                  {{ objective.reward.teaser }}
                </li>
              </ul>
            </dd>
          </div>

          <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
            <dt class="text-sm font-medium text-gray-500">
              Solde
            </dt>

            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {{ balance }} points
            </dd>
          </div>
        </dl>
      </div>

      <div
        v-show="attributed"
        class="flex mt-4"
      >
        <div class="flex-shrink-0">
          <svg
            aria-hidden="true"
            viewBox="0 0 20 20"
            fill="currentColor"
            class="h-5 w-5 text-green-400"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
            />
          </svg>
        </div>

        <div class="ml-1">
          <h3 class="text-sm font-medium text-green-800">
            Récompense attribuée
          </h3>
        </div>
      </div>

      <template #footer>
        <BaseButton @click="close">
          Fermer
        </BaseButton>

        <BaseButton
          primary
          :disabled="!attributable"
          @click="submit"
        >
          Valider
        </BaseButton>
      </template>
    </BaseModal>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import {
    loyaltyCardMembershipRewardApi
} from '@/container';

import BaseModal from '@/core/components/base/BaseModal.vue';
import BaseInput from '@/core/components/base/BaseInput.vue';
import BaseButton from '@/core/components/base/BaseButton.vue';

export default defineComponent({
    components: {
        BaseModal,
        BaseInput,
        BaseButton
    },

    props: {
        membership: {
            type: Object,
            required: true
        }
    },
    emits: ['attributed'],

    data() {
        return {
            opened: false,
            error: {} as any,
            amount: 0 as any,
            attributed: false,
            title: 'Attribuer une réduction'
        };
    },

    computed: {
        discount() {
            return ((this.membership.loyalty_card.point?.rate || 0) * this.amount).toFixed(2);
        },

        balance() {
            return this.membership.point_balance - this.amount;
        },

        objectives() {
            return this.membership.loyalty_card.objectives.filter(
                (objective: any) => objective.reward.type.slug === 'point'
            );
        },

        attributable() {
            return this.membership.point_balance > 0;
        }
    },

    methods: {
        open() {
            this.opened = true;
        },

        close() {
            this.opened = false;
        },
        submit() {
            this.error = {};
            this.attributed = false;
            const regexpZeros = new RegExp(/^0*/);
            if (this.amount.match(regexpZeros).length > 0) {
                this.amount = this.amount.slice(this.amount.match(regexpZeros)[0].length);
            }

            loyaltyCardMembershipRewardApi()
                .create(
                    this.$route.params.customerId as any,
                    this.$route.params.membershipId as any,
                    {
                        type: 'discount',
                        amount: this.amount
                    }
                )
                .then(() => {
                    this.amount = 0;
                    this.attributed = true;

                    this.$emit('attributed');
                })
                .catch(error => {
                    error.response.data.amount[0] = (error.response.data.amount[0]).replace('amount', 'montant');
                    this.error = error.response.data;
                });
        }
    }
});
</script>
