<template>
  <BaseCard class="overflow-visible">
    <BaseCardBody class="flex flex-col space-y-2 relative overflow-visible">
      <h3 class="text-sm">
        {{ document.name }}
      </h3>
      <img
        v-if="thumbnail"
        :src="thumbnail"
        alt="Vignette"
        class="h-48 object-contain"
      >
      <BaseButton
        class="relative top-10"
        :primary="true"
        @click="downloadAndPrint()"
      >
        Imprimer
        <v-icon
          color="white"
          class="ml-2"
        >
          mdi-printer
        </v-icon>
      </BaseButton>
    </BaseCardBody>
  </BaseCard>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import BaseCard from '@/core/components/base/BaseCard.vue';
import BaseCardBody from '@/core/components/base/BaseCardBody.vue';
import {documentApi} from '@/container';
import BaseButton from '@/core/components/base/BaseButton.vue';

export default defineComponent({
    name: 'PathwayDocumentCard',
    components: {BaseButton, BaseCardBody, BaseCard},
    props: {
        document: {
            type: Object,
            required: true
        },
        consumerKey: {
            type: String,
            required: true
        }
    },
    data: () => ({
        thumbnail: null as string | null
    }),
    watch: {
        'document'() {
            documentApi()
                .thumbnail(this.document.id)
                .then(qrcode => {
                    const reader = new FileReader();

                    reader.readAsDataURL(qrcode);

                    reader.onload = () => {
                        this.thumbnail = reader.result as string;
                    };
                });
        }
    },
    methods: {
        downloadAndPrint() {
            return documentApi()
                .document(this.document.id, this.consumerKey)
                .then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response], {type: response.type}));
                    const printWindow = window.open(url);
                    printWindow?.print();
                });
        }
    }
});
</script>
