import {LegacyProduct} from "@/core/interfaces/Product";
import {Router} from "vue-router";
import {disruptionLogisticsApi} from "@/container";
import {useDisruptionLogisticsRequestStore} from "@/stores/disruptionLogisticsRequest.store";

const DisruptionLogisticsRequestService = () => {

    /**
     * Get the request token and redirect to the product availability page.
     *
     * @param product
     * @param router
     * @param [replaceRoute=false]
     */
    const requestDisruptionLogisticsData = async (product: LegacyProduct, router: Router, replaceRoute = false) => {
        return disruptionLogisticsApi()
            .createRequest(product.id)
            .then((response) => {
                const disruptionLogisticsRequestStore = useDisruptionLogisticsRequestStore();
                disruptionLogisticsRequestStore.decreaseRemainingRequests();

                const params = {
                    name: 'disruption-logistics.product-availability',
                    params: {
                        productId: product.id
                    },
                    query: {
                        productName: product.name,
                        requestToken: response.token,
                        productCode: product.code
                    }
                };

                replaceRoute ? router.replace(params) : router.push(params);
            });
    };

    return {
        requestDisruptionLogisticsData
    };
};

export default DisruptionLogisticsRequestService();
