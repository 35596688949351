import axios from 'axios';
import requestInterceptor from '@/core/http/interceptors/requestInterceptor';
import responseInterceptor from "@/core/http/interceptors/responseInterceptor";

const hdsClient = axios.create({
    baseURL: import.meta.env.VITE_HDS_URL
});

hdsClient.interceptors.request.use(
    requestInterceptor.success
);

hdsClient.interceptors.response.use(
    responseInterceptor.success,
    responseInterceptor.failure
);

export default hdsClient;
