import {AxiosResponse} from "axios";

export default function (hdsClient) {
    return {
    /**
     * Get the hospital patient files for a given consumer key.
     *
     * @returns {Promise} Promise object represents the HTTP request.
     */
        index (consumerKey: string) {
            return hdsClient
                .get(`consumers/${consumerKey}/hospital-patient-files`)
                .then((response: AxiosResponse) => response.data.data);
        },

        /**
     * Get the hospital patient file for a given id.
     *
     * @return {Promise<Blob>}
     */
        download (file: any) {
            return hdsClient
                .get(`hospital-patient-files/${file.id}`, { responseType: 'blob' })
                .then((response: AxiosResponse) => response.data);
        }
    };
}
