<template>
  <div>
    <div
      v-for="section in survey.sections"
      :key="`section-${section.id}`"
    >
      <h2 class="text-lg mb-4">
        {{ section.title }}
      </h2>
      <base-container class="gap-y-1">
        <div
          v-for="question in section.questions"
          :id="`question-${question.id}`"
          :key="question.id"
          style="scroll-margin: 90px"
        >
          <div class="flex flex-col">
            <p>
              {{ question.title }}
            </p>
            <p
              v-if="!!errors[question.id]"
              class="text-sm text-red-500"
            >
              {{ errors[question.id] }}
            </p>
          </div>
          <label
            v-if="question.description?.length"
            class="text-sm mt-1"
          >
            {{ question.description }}
          </label>
          <survey-built-answer
            :question="question"
            :survey-id="survey.id"
            :editable="true"
            :value="getAnswerValue(question.id)"
            class="mt-1"
            @change="(change) => onChange(change, question.id)"
          />
          <p
            v-if="getReasonForQuestion(question.id)"
            class="mt-1 italic text-sm flex items-center"
          >
            <icon
              name="info-circle"
              class="mr-2"
            />
            {{ getReasonForQuestion(question.id) }}
          </p>
          <v-divider class="mt-4" />
        </div>
      </base-container>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import SurveyBuiltAnswer from '@/survey/components/SurveyBuiltAnswer.vue';
import BaseContainer from '@/core/components/base/BaseContainer.vue';
import Icon from '@/core/components/icon/Icon.vue';
import {mapState} from "pinia";
import {useInterviewStore} from "@/stores/interview-store";

export default defineComponent({
    name: 'InterviewSurveyBuilt',
    components: {Icon, BaseContainer, SurveyBuiltAnswer},
    props: {
        survey: {
            type: Object,
            required: true
        },
        answers: {
            type: Object,
            default: null
        },
        errors: {
            type: Object,
            default: null
        }
    },
    emits: ['change'],
    computed: {
        ...mapState(useInterviewStore, {
            llmAnswerReasons: state => state.prefilledSurveyAnswers?.reduce((acc, answer) => {
                acc[answer.question_id] = answer.reason ?? null;

                return acc;
            }, {})
        })
    },
    methods: {
    /**
     * Update the answer value.
     *
     * @param value
     * @param questionId
     */
        onChange(value, questionId) {
            this.$emit('change', {value, questionId});
        },
        /**
     * Get the answer value for a question.
     *
     * @param {Number} questionId The question id.
     *
     * @return {Array}
     */
        getAnswerValue(questionId) {
            return this.answers[questionId] || [];
        },
        /**
     * Get the LLM answer reason for a question.
     *
     * @param {Number} questionId The question id.
     *
     * @returns {string|null}
     */
        getReasonForQuestion(questionId) {
            if (!this.llmAnswerReasons) {
                return null;
            }

            return this.llmAnswerReasons[questionId] ?? null;
        }
    }
});
</script>
