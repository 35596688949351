/**
 * Pluralize a string
 *
 * @param value - The string to pluralize
 * @param count - The count to determine if the string should be pluralized
 *
 * @return The pluralized string
 */
export const pluralize = (value: string, count: number) => {
    if (count > 1) {
        return `${value}s`;
    }

    return value;
};
