<script setup lang="ts">
import CommunicationNotificationCard from "@/communication/components/CommunicationNotificationCard.vue";
import CommunicationSwitch from "@/communication/components/CommunicationSwitch.vue";
import {onMounted, ref, watch} from "vue";
import CommunicationMessageCard from "@/communication/components/CommunicationMessageCard.vue";
import {massCertificationApi} from "@/container";
import SearchBarService from "@/core/services/SearchBarService";
import {MassCertificationCustomer, MassCertificationCustomerApiResponse} from "@/core/interfaces/MassCertification";
import {MassCertificationMetaData} from "@/core/http/resources/MassCertificationResource";
import categories from "@/core/factories/search-bar/CustomerQuickFilters";
import {CustomerWithSelected} from "@/communication/types";
import {useRouter} from "vue-router";
import {useCommunicationStore} from "@/communication/stores/communication.store";
import {storeToRefs} from "pinia";
import CommunicationFiltersModal from "@/communication/components/CommunicationFiltersModal.vue";
import {useCreditStore} from "@/stores/credit.store";

const {mode, selectedFilters} = storeToRefs(useCommunicationStore());
const communicationStore = useCommunicationStore();
const creditStore = useCreditStore();
const customers = ref<MassCertificationCustomer[]>([]);
const meta = ref<MassCertificationMetaData>();
const loading = ref<boolean>(false);
const router = useRouter();

const updateMode = (isNotification: boolean) => {
    mode.value = isNotification ? 'notification' : 'sms';
};

onMounted(() => {
    communicationStore.$reset();
    creditStore.refresh();
    search();
});

watch(mode, () => {
    selectedFilters.value = [];
    search();
});

watch(selectedFilters, () => {
    if (selectedFilters.value.length !== 0) {
        search();
    }
});

const search = () => {
    loading.value = true;
    const defaultParams = mode.value === 'notification'
        ? {query: {onboarded: [true]}, useDefaultFilters: false}
        : {};
    return massCertificationApi().customers({
        page: 1,
        limit: 100,
        ...defaultParams,
        query: {...defaultParams.query, ...SearchBarService.transformToQueryParams(selectedFilters.value)},
    })
        .then((response) => {
            meta.value = response.meta;
            customers.value = mapApiResponse(response.data);

            if (!selectedFilters.value.length) {
                fillFiltersFromMeta();
            }
        })
        .finally(() => loading.value = false);
};

/**
 * Fill the filters from the meta.
 */
const fillFiltersFromMeta = () => {
    if (!meta.value?.query) {
        return;
    }

    selectedFilters.value = SearchBarService.transformToSearchItems(meta.value.query, categories);
};

/**
 * Map the customers sent by the server for mass certification flow.
 *
 * @param {MassCertificationCustomerApiResponse[]} customers
 * @return {MassCertificationCustomer[]}
 */
const mapApiResponse = (customers: MassCertificationCustomerApiResponse[]): MassCertificationCustomer[] => {
    return customers.map(transformApiResponse);
};

/**
 * Transform customer api response to mass certification customer.
 *
 * @param {CustomerWithSelected} customer
 * @return {MassCertificationCustomer}
 */
const transformApiResponse = (customer: CustomerWithSelected): MassCertificationCustomer => {
    return {
        id: customer.id,
        lastVisit: customer.last_visit,
        visits: customer.visits,
        name: customer.information?.name,
        lastMessageDate: customer?.last_notification,
        phone: customer.information.phone,
        birthDate: customer.information.birth_date
    };
};

const onSelected = () => {
    router.push({
        name: 'patient-administration.communication.message',
    });
};
</script>

<template>
  <div class="p-4">
    <div class="flex flex-row justify-between items-center">
      <h1>Communiquez auprès de vos patients</h1>
      <CommunicationSwitch @on-notification-change="updateMode" />
    </div>
    <CommunicationFiltersModal />
    <CommunicationNotificationCard
      v-if="mode === 'notification'"
      :is-loading="loading"
      :customers="customers"
      @selected="onSelected"
    />
    <CommunicationMessageCard v-else :is-loading="loading" :customers="customers" @selected="onSelected" />
  </div>
</template>
