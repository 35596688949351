<script setup lang="ts">
import noProductImage from '@/assets/images/no-product-image.png';
import BaseImage from "@/core/components/base/BaseImage.vue";
import LaboratoryProduct from "@/core/interfaces/laboratory/LaboratoryProduct";

const props = withDefaults(defineProps<{ product: LaboratoryProduct; size: number }>(), {size: 48});
/**
 * Get the product image uri from a LaboratoryProduct.
 *
 * @returns string | undefined
 */
const getLaboratoryProductImage = () => {
    const path = props.product?.photoPath;

    if ((!!path && path?.length !== 0)) {
        return `${import.meta.env.VITE_API_URL}/images/${path}`;
    }

    return noProductImage;
};
</script>

<template>
  <BaseImage
    rendered
    :class="`w-${size} h-${size} rounded-lg`"
    :text="product.name"
    :path="getLaboratoryProductImage()"
  />
</template>

<style scoped>

</style>