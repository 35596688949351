<script setup lang="ts">
import {useExtensionStore} from "@/stores/extension.store";
import {SubscriptionPlan} from "@/subscription-plan/interfaces";
import ExtensionGrid from "@/program/components/program-grid/ExtensionGrid.vue";
import {computed} from "vue";
import {storeToRefs} from "pinia";
import Extension from "@/core/interfaces/extension/Extension";

const {enabledExtensionsBySubscriptionPlan} = useExtensionStore();
const {extensions} = storeToRefs(useExtensionStore());

defineEmits<{
    (e: 'extensionClick', extension: Extension): void;
}>();
const props = defineProps<{
    /** The subscription plan to show. */
    plan: SubscriptionPlan,
    /** Whether only enabled extensions should be displayed. */
    enabled: boolean
}>();

const displayedExtensions = computed(() => {
    if(props.enabled) {
        return enabledExtensionsBySubscriptionPlan(props.plan.id) ?? [];
    }

    return extensions.value?.filter(extension => {
        return extension.subscription_plan_id === props.plan.id;
    }) ?? [];
});

</script>

<template>
  <ExtensionGrid
    :title="plan.name"
    :extensions="displayedExtensions"
    @extension-click="(extension) => $emit('extensionClick', extension)"
  />
</template>
