<template>
  <div class="relative">
    <div class="relative rounded-md shadow-sm">
      <input
        v-model="keyword"
        type="search"
        class="block bg-white w-full pr-10 pl-5 py-3 border-gray-100 rounded-lg shadow-md focus:ring-indigo-500 focus:border-indigo-500 placeholder-gray-300"
        placeholder="Rechercher, activer un patient…"
        @input="search"
      >

      <div class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
        <svg
          v-if="!isLoading"
          xmlns="http://www.w3.org/2000/svg"
          class="h-7 w-7 text-primary"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
          />
        </svg>
        <BaseSpinner
          v-else
          size="small"
        />
      </div>
    </div>

    <div
      v-if="results.length"
      class="absolute shadow bg-white top-full z-20 w-full left-0 rounded max-h-72 overflow-y-auto"
    >
      <div
        v-if="isLoading"
        class="flex flex-col w-full"
      >
        <div class="cursor-pointer w-full border-gray-100 rounded-t border-b hover:bg-teal-100">
          <div class="flex w-full items-center p-2 pl-2 border-transparent border-l-2 relative hover:border-teal-100">
            <div class="w-full items-center flex">
              Recherche en cours…
            </div>
          </div>
        </div>
      </div>

      <div v-else>
        <div
          v-for="(result, index) in results"
          :key="index"
          class="grid grid-cols-3 mx-auto text-sm p-2 border-b-1 border-red-500 hover:bg-purple-200 cursor-pointer"
          @click="show(result)"
        >
          <div class="pl-2 text-purple-700">
            {{ result.information.name }}
          </div>

          <div class="text-center">
            Assuré
          </div>

          <div class="text-center">
            {{ result.information?.birth_date }}
          </div>

          <div class="col-span-3">
            <div
              v-for="(beneficiary, indexBeneficiary) in result.beneficiaries"
              :key="indexBeneficiary"
            >
              <span class="ml-5 text-sm">
                {{ beneficiary.information?.name }} - Ayant droit - {{ beneficiary.information?.formatted_birth_date }}
              </span>
            </div>
          </div>

          <hr class="col-span-3 w-full">
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {ref, watch} from "vue";
import BaseSpinner from '@/core/components/base/spinner/BaseSpinner.vue';
import {customerApi} from '@/container';
import Customer from "@/customer/interfaces/Customer";
import debounce from 'lodash.debounce';
import {useRouter} from "vue-router";

const props = defineProps({
    input: {
        type: String,
        required: false,
        default: ''
    }
});

const results = ref<Customer[]>([]);
const keyword= ref<string | undefined>('');
const isLoading = ref<boolean>(false);

const router = useRouter();

watch(() => props.input, (value) => {
    keyword.value = value;
    search();
});

watch(keyword, (value) => {
    if (value?.length === 0) {
        results.value = [];
    }
});

const search = debounce(()  =>{
    if (keyword?.value?.length && keyword?.value?.length > 3) {
        isLoading.value = true;

        return customerApi().search(keyword.value)
            .then(customers => {
                results.value = customers;
                isLoading.value = false;
            })
            .catch(console.log);
    }
}, 500);

const  show = (customer: Customer) => {
    router.push({
        name: 'customer.home',
        params: {
            customerId: customer.id
        }
    });
};
</script>
