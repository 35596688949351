
import {AxiosInstance, AxiosResponse} from "axios";

/**
 * The instant discount voucher resource.
 * @author Tony Laurent <t.laurent@apodispharma.com>
 * @param {Object} client - The injected client.
 * @param {Object} hdsClient - The injected client.
 * @returns {Object} The public object.
 */
export default function (client: AxiosInstance, hdsClient: AxiosInstance) {
    return {
    /**
     * Get all instant discount vouchers.
     * @returns {Promise} Promise object represents the HTTP request.
     */
        get () {
            return client
                .get('/instant-discount-vouchers/current', {
                    params: {
                        include: 'image'
                    }
                })
                .then((response: AxiosResponse) => response.data.data);
        },

        /**
     * Read a instant discount voucher.
     * @param {number} id - The ID of the instant discount voucher.
     * @returns {Promise} Promise object represents the HTTP request.
     */
        read (id: number) {
            return client
                .get(`/instant-discount-vouchers/${id}`, {
                    params: {
                        include: 'image,rewards,productsTrigger'
                    }
                })
                .then((response: AxiosResponse) => response.data.data);
        },

        customers (id: number) {
            return hdsClient.get(`/instant-discount-vouchers/${id}/customers`)
                .then((response: AxiosResponse) => response.data);
        }
    };
}
