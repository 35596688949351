<template>
  <div
    class="h-full flex flex-1 justify-center align-center"
  >
    <BaseSpinner v-bind="$attrs" />
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import BaseSpinner from '@/core/components/base/spinner/BaseSpinner.vue';

/**
 * The base spinner.
 */
export default defineComponent({
    name: 'BaseFlexSpinner',
    components: { BaseSpinner }
});
</script>
