<template>
  <base-container class="p-3 gap-3">
    <h1>Retours de disponibilité</h1>
    <base-flex-spinner v-if="loading" />
    <div
      v-else-if="availabilitiesByDay"
      class="flex flex-col gap-4"
    >
      <product-available-again-card
        v-for="date in sortedDates()"
        :key="date"
        :date="new Date(date)"
        :availabilities-by-source="availabilitiesByDay[date]"
      />
    </div>
    <p
      v-else
      class="text-center"
    >
      Aucune donnée à afficher
    </p>
  </base-container>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import {disruptionLogisticsApi} from "@/container";
import {ProductAvailability} from "@/disruption-logistics/interfaces";
import BaseContainer from "@/core/components/base/BaseContainer.vue";
import BaseFlexSpinner from "@/core/components/base/spinner/BaseFlexSpinner.vue";
import ProductAvailableAgainCard
    from "@/disruption-logistics/components/product-availability-card/ProductAvailableAgainCard.vue";

interface ProductAvailabilityByDayAndSource {
    /** The availabilities grouped by day and source. */
    [key: string]: ProductAvailabilityBySource
}

export interface ProductAvailabilityBySource {
    /** The availabilities grouped by source . */
    [key: string]: ProductAvailability[]
}

export default defineComponent({
    name: "ProductAvailableAgainView",
    components: {ProductAvailableAgainCard, BaseFlexSpinner, BaseContainer},
    data() {
        return {
            availabilitiesByDay: null as null | ProductAvailabilityByDayAndSource,
            loading: true
        };
    },
    mounted() {
        this.loading = true;

        disruptionLogisticsApi()
            .productsAvailableAgain()
            .then((data) => {
                this.availabilitiesByDay = this.groupByDayAndSource(data);
            })
            .finally(() => {
                this.loading = false;
            });
    },
    methods: {
        /**
         * Group the availabilities by day and source.
         *
         * @param availabilities
         * @return {Record<string, Object>}
         */
        groupByDayAndSource(availabilities: ProductAvailability[]): ProductAvailabilityByDayAndSource {
            const groups = availabilities.reduce((acc, availability) => {
                availability.history.forEach((history) => {

                    const date = new Date(history.updated_at).toDateString();

                    if (!acc[date]) {
                        acc[date] = [];
                    }

                    acc[date].push(availability);
                });

                return acc;
            }, {});

            Object.keys(groups).forEach((date) => {
                groups[date] = groups[date].reduce((acc, availability) => {
                    const source = availability.source?.name || '';

                    if (!acc[source]) {
                        acc[source] = [];
                    }

                    acc[source].push(availability);

                    return acc;
                }, {});
            });

            return groups;
        },
        /**
         * Sort keys by date.
         * */
        sortedDates(): string[] {
            return Object.keys(this.availabilitiesByDay || {}).sort((a, b) => {
                return new Date(b).getTime() - new Date(a).getTime();
            });
        }
    }
});
</script>

<style scoped>

</style>
