<template>
  <div>
    <HomeLiveItem
      :time-ago="formatHelper.readableDate(item.created_at)"
      bg-color-class="bg-primary bg-opacity-50"
      @click="show = true"
    >
      <template #image>
        <img
          alt="cart-bubble"
          :src="cartBubble"
        >
      </template>
      <p class="text-sm font-bold">
        {{ name }}
      </p>
      <p class="text-sm">
        {{ ("summary" in item && !item.summary?.in_stock) ? "🚨 " : "" }}
        Commande à traiter
      </p>
    </HomeLiveItem>
    <OrderActionModal
      v-if="show"
      :process-action="item"
      :order-id="item.id"
      :opened="show"
      @close-modal="close"
      @show="showCustomer"
    />
  </div>
</template>

<script lang="ts" setup>
import {computed, ref} from "vue";
import formatHelper from '@/core/helpers/formatHelper';
import cartBubble from '@/assets/icons/cart-bubble.svg';
import {HomeOrderItem} from "@/home/interfaces";
import HomeLiveItem from "@/home/components/HomeLiveItem.vue";
import OrderActionModal from "@/order/components/OrderActionModal.vue";
import {useRouter} from "vue-router";
import Customer from "@/customer/interfaces/Customer";

const router = useRouter();

const props = defineProps<{
    item: HomeOrderItem['data']
}>();
const show = ref(false);


const name = computed(() => {
    if ("name" in props.item.customer) {
        return props.item.customer.name;
    }

    return `${props.item.customer.information?.first_name} ${props.item.customer.information?.last_name}`;
});

/**
 * Close the modal.
 */
const close = () => {
    show.value = false;
};

/**
 * Show the patient.
 *
 * @param customer
 */
const showCustomer = (customer: Customer) => {
    close();
    router.push({
        name: 'customer.home',
        params: {
            customerId: customer.id
        }
    });
};
</script>
