export default {
    /**
     * Detect if running in Electron.
     * @returns {boolean} process is running in Electron.
     */
    isElectron() {
        // Main process
        if (typeof process !== 'undefined' && typeof process.versions === 'object' && !!process.versions.electron) {
            return true;
        }

        // Detect the user agent when the `nodeIntegration` option is set to false
        if (typeof navigator === 'object' && typeof navigator.userAgent === 'string' && navigator.userAgent.indexOf('Electron') >= 0) {
            return true;
        }

        return false;
    },

    /**
     * Determine if the application is running in development mode.
     *
     * @return {boolean}
     */
    isDevelopment() {
        return process.env.NODE_ENV === 'development';
    }
};
