<script setup lang="ts">
import HeaderBackButton from "@/core/components/header-back-button/HeaderBackButton.vue";
import BaseCard from "@/core/components/base/BaseCard.vue";
import {onMounted, ref} from "vue";
import {MassCertificationNotificationTemplate} from "@/core/interfaces/MassCertification";
import {massCertificationApi} from "@/container";
import {useCommunicationStore} from "@/communication/stores/communication.store";
import {useRouter} from "vue-router";
import CommunicationMessageTemplateSelection
    from "@/communication/components/CommunicationMessageTemplateSelection.vue";
import BaseFlexSpinner from "@/core/components/base/spinner/BaseFlexSpinner.vue";

const {mode, programId} = useCommunicationStore();
const templates = ref<MassCertificationNotificationTemplate[]>([]);
const loading = ref(false);
const communicationStore = useCommunicationStore();
const router = useRouter();

onMounted(() => {
    loading.value = true;
    massCertificationApi().messageTemplates({program_id: programId, type: mode})
        .then(response => templates.value = response)
        .finally(() => loading.value = false);

});

/**
 * On message selected.
 *
 * @param template
 */
const onSelected = (template: MassCertificationNotificationTemplate) => {
    communicationStore.template = template;
    router.push({
        name: 'patient-administration.communication.summary'
    });
};
</script>

<template>
  <div>
    <HeaderBackButton :title="'Communication par '+ mode" />
    <BaseCard class="m-4 p-4">
      <h2>Sélectionner le message</h2>
      <BaseFlexSpinner v-if="loading" />
      <div v-else>
        <CommunicationMessageTemplateSelection
          v-for="template in templates"
          :key="template.id"
          :template="template"
          @click="onSelected(template)"
        />
      </div>
    </BaseCard>
  </div>
</template>
