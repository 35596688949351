import {AxiosInstance} from "axios";

/**
 * The survey bundle resource.
 *
 * @param {Object} client - The injected client.
 * @returns {Object} The public object.
 */
export default function (client: AxiosInstance) {
    return {
    /**
     * Create a new survey bundle instance for a consumer.
     *
     * @param surveyBundleId The survey bundle id used for created the survey instance.
     * @param consumerKey The consumer key.
     *
     * @returns {Promise}
     */
        createInstance (surveyBundleId: number, consumerKey: string) {
            return client.post(`/survey-bundles/${surveyBundleId}/instances`, { consumer_key: consumerKey })
                .then(response => response.data.data);
        },

        /**
     * Read next question.
     *
     * @param surveyBundleId The survey bundle id.
     * @param instanceId The survey instance id.
     *
     * @returns {Promise}
     */
        next (surveyBundleId: number, instanceId: number) {
            return client.get(`/survey-bundles/${surveyBundleId}/instances/${instanceId}/next`)
                .then(response => response);
        },

        /**
     * Answer a question of a survey bundle instance.
     *
     * @param surveyBundleId The survey bundle id.
     * @param instanceId The survey instance id.
     * @param answers The answers to the current question in the instance (must be an array).
     *
     * @returns {Promise}
     */
        answer (surveyBundleId: number, instanceId: number, answers: unknown) {
            return client.post(`/survey-bundles/${surveyBundleId}/instances/${instanceId}/answers`, { value: answers })
                .then(response => response);
        },

        /**
     * Get the verdict of a survey bundle instance.
     *
     * @param surveyBundleId Survey bundle id.
     * @param instanceId Instance id.
     *
     * @returns {Promise}
     */
        verdict (surveyBundleId: number, instanceId: number) {
            return client.get(`/survey-bundles/${surveyBundleId}/instances/${instanceId}/verdict`)
                .then(response => response.data);
        }
    };
}
