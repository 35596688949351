import {useSystemStore} from "@/stores/system.store";

const SponsorshipService = () => {
    return {
        /**
         * Get the coupon code for the current environment
         */
        couponCode(): string {
            const systemStore = useSystemStore();

            return systemStore.$state.environment?.entity.cip ?? '';
        }
    };
};

export default SponsorshipService();
