import {AxiosInstance, AxiosResponse} from "axios";
import {LegacyProduct} from "@/core/interfaces/Product";
import LaboratoryProduct from "@/core/interfaces/laboratory/LaboratoryProduct";

export default function (apiClient: AxiosInstance) {
    return {
        /**
         * Search for a product.
         *
         * @param searchTerm - The search term.
         * @param [size=20] - The number of results to return.
         */
        async search(searchTerm: string, size = 20): Promise<LegacyProduct[]> {
            const data = {
                keywords: searchTerm,
                size
            };

            return apiClient.post('products/search', data)
                .then(response => response.data.products);
        },
        /**
         * Get product presentation.
         *
         * @param id
         */
        getPresentation(id: number): Promise<LaboratoryProduct> {
            const params = {include: "price"};
            return apiClient.get(`product/presentation/detail/${id}`, {params})
                .then((response: AxiosResponse) => response.data.presentation);
        }
    };
}
