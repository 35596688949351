<template>
  <div class="relative flex">
    <BaseSpinner
      v-if="isLoading"
      class="flex"
      color="white"
      size="medium-small"
    />
    <div
      v-else
      class="flex w-full h-full"
    >
      <div
        v-if="!!base64Thumbnail"
      >
        <AttachmentThumbnail
          :base64="base64Thumbnail"
          :attachment="attachment"
          :class="[{
            'cursor-pointer': !!$attrs.click
          }]"
          @click="$emit('click')"
        />
      </div>
      <div
        v-else
        class="flex w-full h-full blue-grey rounded-lg"
      />
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import {programAttachmentApi} from '@/container';
import BaseSpinner from '@/core/components/base/spinner/BaseSpinner.vue';
import AttachmentThumbnail from '@/program/components/program-attachment/thumbnails/AttachmentThumbnail.vue';
import ImageService from "@/core/services/ImageService";

export default defineComponent({
    name: 'ProgramAttachmentCompactThumbnail',
    components: {AttachmentThumbnail, BaseSpinner},
    props: {
        attachment: {
            type: Object,
            required: true
        }
    },
    emits: ['click'],
    data: function () {
        return {
            base64Thumbnail: null as null | string,
            isLoading: true,
            thumbnailComponent: null
        };
    },
    mounted() {
        programAttachmentApi()
            .renderThumbnail('programs', Number(this.$route.params.programId), this.attachment.id)
            .then(response => {
                this.base64Thumbnail = ImageService().arrayBufferToBase64(response);
            })
            .catch(() => {
                this.base64Thumbnail = null;
            })
            .finally(() => {
                this.isLoading = false;
            });
    }
});
</script>
