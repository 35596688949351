<template>
  <form class="w-full flex flex-row">
    <div class="border-b-2 border-lightPurple-darkest pb-1 mb-1">
      <input
        v-model="value"
        class="w-36 font-heading p-3 pb-0 pl-0 mr-0 pr-0 text-sm text-gray-800 bg-white"
        :placeholder="placeholder"
        @input="formatInput"
      >
    </div>
    <button
      class="flex items-center justify-center w-22 text-sm px-2 rounded-lg text-primary font-heading font-bold uppercase overflow-hidden"
      :disabled="loading || !canSubmit"
      @click="onSubmit"
    >
      <img
        v-if="loading"
        :src="loaderGif"
        alt="Loading"
        width="24"
      >
      <div v-if="!loading">
        {{ action }}
      </div>
    </button>
  </form>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import formatHelper from '@/core/helpers/formatHelper';
import loaderGif from '@/assets/loader.gif';

export default defineComponent({
    props: {
        placeholder: {
            type: String,
            default: '...'
        },
        action: {
            type: String,
            default: 'OK'
        },
        format: {
            type: String,
            default: 'text'
        },
        loading: {
            type: Boolean,
            default: false
        },
        input: {
            type: String,
            default: ''
        }
    },
    emits: ['submit'],
    data () {
        return {
            value: this.input,
            canSubmit: false,
            loaderGif
        };
    },
    watch: {
        input: {
            immediate: true,

            handler (value) {
                this.value = value;

                this.validate();
            }
        }
    },
    methods: {
        formatInput () {
            switch (this.format) {
            case 'phone':
                this.value = formatHelper.readablePhone(this.value);
                break;
            default:
                return this.value;
            }

            this.validate();
        },
        onSubmit () {
            this.$emit('submit', this.value);
        },
        validate () {
            const phoneLengthWithSpaces = 14;

            this.canSubmit = this.value?.length === phoneLengthWithSpaces;
        }
    }
});
</script>
