import {AxiosResponse, AxiosInstance} from "axios";

/**
 * The hubspot resource.
 * @author Mathieu Bolard <m.bolard@apodispharma.com>
 * @param {Object} client - The injected client.
 * @returns {Object} The public object.
 */
export default function (client: AxiosInstance) {
    return {
    /**
     * Create a support ticket.
     *
     * @param {string} content - The ticket content.
     *
     * @returns {Promise} Promise object represents the HTTP request.
     */
        createTicket (content: string) {
            return client.post('/hubspot', { data: { content } })
                .then((response: AxiosResponse) => response.data.data);
        }
    };
}
