import {Interview, InterviewArchive, InterviewInstance, Transcript} from "@/interview/interfaces/interview";
import {ProgramAttachment} from "@/program/interfaces/Program";
import {PrefilledSurveyAnswer} from "@/core/interfaces/survey/Survey";
import {AxiosInstance, AxiosResponse} from "axios";

interface InterviewInstanceIndexRequest {
    partial?: boolean;
}

export default function (apiClient: AxiosInstance, hdsClient: AxiosInstance) {
    return {
        /** Get all interviews.
         *
         * @returns {Promise<Object>}
         */
        index() {
            return apiClient.get('interviews')
                .then(response => response.data.data);
        },

        /** Get an interview by its id.
         *
         * @param {Number} interviewId The interview id.
         *
         * @returns {Promise<Interview>}
         */
        read(interviewId): Promise<Interview> {
            return apiClient.get(`interviews/${interviewId}`)
                .then(response => response.data.data);
        },
        /**
         * Create a new interview instance.
         *
         * @param {Number} interviewId The interview ID.
         * @param {String} consumerKey The consumer key for which the interview instance is being created.
         * @param {number} backingId The backing ID attached to the interview.
         */
        createInstance(interviewId: number, consumerKey: string, backingId: number | null) {
            return apiClient.post(`interviews/${interviewId}/instances`, {
                consumer_key: consumerKey,
                backing_id: backingId
            })
                .then(response => response.data.data);
        },
        /**
         * Update an interview instance.
         *
         * @param {Number} interviewId The interview ID.
         * @param instanceId The interview instance ID.
         * @param {{
         *   survey_answers: Array<{
         *     question_id: Number,
         *     answer: Array
         *   }>
         * }} params The interview instance parameters.
         *
         * @returns {Promise<Object>}
         */
        updateInstance(interviewId: number, instanceId: number, params: object) {
            return apiClient.patch(`interviews/${interviewId}/instances/${instanceId}`, params)
                .then(response => response.data.data);
        },
        /**
         * Get the resources for an interview.
         *
         * @param {Number} interviewId The interview ID.
         * @param consumerKey The consumer key.
         */
        async attachments(interviewId: number, consumerKey?: string | null): Promise<ProgramAttachment[]> {
            const query = consumerKey ? `?consumer_key=${consumerKey}` : '';

            return (await apiClient.get(`interviews/${interviewId}/attachments${query}`))?.data?.data || [];
        },

        /**
         * Get the interview questions.
         *
         * @param instanceId The interview instance ID.
         * @param {Blob} audio The interview ID.
         *
         * @returns {Promise<Object>}
         */
        async transcript(instanceId: number, audio: Blob): Promise<Transcript> {
            const formData = new FormData();
            formData.append('audio', audio);

            return hdsClient.post(`interview-instances/${instanceId}/transcript`, formData)
                .then((response: AxiosResponse) => response.data.data);
        },
        /**
         * Get the interview survey answers from the transcript filled with LLM service.
         *
         * @param {Number} interviewId - The interview ID.
         * @param consumerKey
         * @param transcript
         *
         * @returns {Promise<Object>}
         */
        async prefillInterviewSurvey(
            interviewId: number,
            consumerKey: string,
            transcript: { transcriptId: string | null, transcriptText: string } | null
        ): Promise<PrefilledSurveyAnswer[]> {
            let data: { transcript_id?: string, transcript?: string, consumer_key: string } = {
                consumer_key: consumerKey
            };

            if (transcript) {
                data = {
                    ...data,
                    transcript_id: transcript.transcriptId ?? undefined,
                    transcript: transcript.transcriptText
                };
            }

            return await apiClient.post(`interviews/${interviewId}/survey/prefill`, {data})
                .then((response: AxiosResponse) => response.data.data);
        },

        /**
         * Prefill the interview instance survey asynchronously.
         *
         * @param {number} interviewId The interview ID that the survey belongs to
         * @param {number} instanceId The interview instance ID that must be prefilled
         * @param {string} consumerKey The consumer key for which the survey is being prefilled
         * @param {Transcript|null} transcript The transcript to be used for prefilling the survey
         */
        async runPrefillSurveyJob(
            interviewId: number,
            instanceId: number,
            consumerKey: string,
            transcript: Transcript | null
        ): Promise<void> {
            let data: { transcript_id?: string, transcript?: string, consumer_key: string } = {
                consumer_key: consumerKey
            };

            if (transcript) {
                data = {
                    ...data,
                    transcript_id: transcript.transcript_id ?? undefined,
                    transcript: transcript.text
                };
            }

            return await apiClient.post(`interviews/${interviewId}/instances/${instanceId}/survey/prefill`, {data})
                .then((response: AxiosResponse) => response.data.data);
        },

        /**
         * Get the interview instances for a consumer.
         * @param consumerKey
         *
         * @returns {Promise<Object>}
         */
        indexConsumerInstances(consumerKey) {
            return apiClient.get(`consumers/${consumerKey}/interview-instances`)
                .then(response => response.data.data);
        },

        /**
         * Create a note for the consumer and the interview.
         *
         * @param instanceId The interview instance ID
         * @param {String} notes The content of the note
         *
         * @returns {Promise<Object>}
         */
        storeNote(instanceId: number, notes: string) {
            return hdsClient.patch(`interview-instances/${instanceId}/archives`, {notes, append: true})
                .then(response => response.data.data);
        },

        /**
         * Get the notes for the consumer.
         *
         * @param {String} consumerKey The consumer key
         * @param {Number} page The page number
         * @param {Number} limit The limit of notes per page
         *
         * @returns {Promise<Object>}
         */
        notes(consumerKey: string, page = 1, limit = 10) {
            const params = {
                page,
                limit
            };

            return hdsClient.get(`consumers/${consumerKey}/interview-notes`, {params})
                .then(response => response.data);
        },
        /**
         * Read the archives of the interview instance.
         * @param instanceId The interview instance ID.
         */
        async readArchive(instanceId: number): Promise<InterviewArchive> {
            return hdsClient.get(`interview-instances/${instanceId}/archive`)
                .then(response => response.data.data);
        },
        /**
         * Download the given audio as MP3.
         */
        async downloadAudio(interviewId: number, audio: Blob): Promise<Blob> {
            const formData = new FormData();
            formData.append('audio', audio);

            return hdsClient.post(`interviews/${interviewId}/audio/download`, formData, {responseType: 'blob'})
                .then((response: AxiosResponse) => response.data);
        },

        /**
         * Read an interview instance.
         */
        async readInstance(interviewId: number, interviewInstanceId: number): Promise<InterviewInstance> {
            return apiClient.get(`interviews/${interviewId}/instances/${interviewInstanceId}`)
                .then(response => response.data.data);
        },

        /**
         * Index interview instances.
         */
        async indexInstances(params: InterviewInstanceIndexRequest = {}): Promise<InterviewInstance[]> {
            return apiClient.get('interview-instances', {params})
                .then(response => response.data.data);
        }
    };
}
