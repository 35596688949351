import {AxiosInstance} from "axios";

/**
 * The loyalty card membership transaction resource.
 * @author Tony Laurent <t.laurent@apodispharma.com>
 * @param {Object} client - The injected client.
 * @returns {Object} The public object.
 */
export default function (client: AxiosInstance) {
    return {
        /**
         * Get all loyalty card membership transactions.
         * @param {number} customerId - The ID of the customer.
         * @param {number} membershipId - The ID of the membership.
         * @param {string} type - The transactions type.
         * @returns {Promise} Promise object represents the HTTP request.
         */
        get (customerId: number, membershipId: number, type?: string|null) {
            return client
                .get(`/customers/${customerId}/loyalty-card-memberships/${membershipId}/transactions`, {
                    params: {
                        type
                    }
                })
                .then(response => response.data)
                .catch(() => []);
        }
    };
}
