<script setup lang="ts">
import EmbedVideo from "@/core/components/video/EmbedVideo.vue";
import BaseModal from "@/core/components/base/BaseModal.vue";
import {ref} from "vue";
import videoThumbnail from "@/assets/images/thumbnail_video_default.png";
import Extension from "@/core/interfaces/extension/Extension";

const {extension} = defineProps<{ extension: Extension }>();
const modalVisible = ref(false);

</script>

<template>
  <v-carousel
    show-arrows="hover"
    cycle
    :interval="4000"
    :height="100"
    hide-delimiters class="cursor-pointer"
    @click="modalVisible = true"
  >
    <v-carousel-item v-if="extension.video_url" key="0" :src="videoThumbnail" class="cursor-pointer" />
    <div v-for="preview in extension.previews" :key="preview.id">
      <v-carousel-item
        v-if="preview?.url"
        :key="preview.id"
        :src="preview.url"
        cover
      />
    </div>
  </v-carousel>
  <base-modal v-if="modalVisible" :title="extension.name" @close="modalVisible = false">
    <v-carousel :interval="4000" :height="500" hide-delimiters>
      <v-carousel-item v-if="extension.video_url" key="0">
        <embed-video class="w-full" :url="extension.video_url" />
      </v-carousel-item>
      <div v-for="preview in extension.previews" :key="preview.id">
        <v-carousel-item
          v-if="preview?.url"
          :key="preview.id"
          :src="preview.url"
          cover
        />
      </div>
    </v-carousel>
  </base-modal>
</template>

<style scoped>
:deep(.v-btn--icon) {
    width: 25px;
    height: 25px;
}
</style>
