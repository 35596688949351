import Extension from "@/core/interfaces/extension/Extension";
import {ExtensionType, ProgramExtension} from "@/core/interfaces/extension/Extension";
import {ProgramType} from "@/program/interfaces/Program";

/**
 * Service for extensions.
 */
const ExtensionService = () => {
    return {
        /**
         * Determine the next action to be taken for an extension based on its current state.
         */
        getNextAction(extension: Extension) {
            if (extension.enabled) {
                return {
                    action: 'disable'
                };
            }

            if (
                (extension.stripe_id || extension.subscription_plan)
                && !extension.subscribed
                && !extension.enabled
            ) {
                return {
                    action: 'buy',
                    prices: extension.subscription_plan
                        ? extension.subscription_plan.prices
                        : extension.prices
                };
            }

            return {
                action: 'enable'
            };
        },

        /**
         * Determine if a given extension is a program.
         *
         * @param extension
         */
        isProgramExtension(extension: Extension): extension is ProgramExtension {
            return extension.extendable_type === ExtensionType.PROGRAM_BUNDLE;
        },

        /**
         * Determine if the given extension is of type support program.
         * @param extension
         */
        isSupportProgramExtension(extension: Extension): extension is ProgramExtension {
            return this.isProgramExtension(extension) && extension.sub_type === ProgramType.SUPPORT;
        }
    };
};

export default ExtensionService();
