<template>
  <li class="flex flex-row items-center">
    <Icon
      :name="icon"
      :color="'icon-color'"
    />
    <div class="text-sm pl-2">
      {{ label }}
    </div>
  </li>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import Icon from '@/core/components/icon/Icon.vue';

export default defineComponent({
    components: { Icon },
    props: {
        label: {
            type: String,
            default: ''
        },
        icon: {
            type: String,
            default: 'check'
        },
        'icon-color': {
            type: String,
            default: undefined
        }
    }
});
</script>
