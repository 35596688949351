import LaboratoryProduct from "@/core/interfaces/laboratory/LaboratoryProduct";
import {SellinOfferProduct} from "@/core/interfaces/SellinOfferProduct";

export default {
    /**
     * Returns the product cip13, ean13 or cip7
     * @returns string
     */
    getSellinOfferProductCode(product: SellinOfferProduct | LaboratoryProduct, withLabel = false): string {
        const empty = "-";
        if (withLabel) {
            if (product.code && product.code !== "") {
                return "CIP: " + product.code;
            }
            if (product.id_13 !== null && product.id_13 !== 0) {
                return "CIP: " + product.id_13;
            }
            if (product.ean_13 !== null) {
                return "EAN: " + product.ean_13;
            }
            if (product.id_7 !== null) {
                return "CIP: " + product.id_7;
            }
            return empty;
        } else {
            return product.code ||
                product.id_13 ||
                product.cip13 ||
                product.ean_13 ||
                product.ean13 ||
                product.id_7 ||
                product.cip7 ||
                empty;
        }
    }
};
