<template>
  <div
    class="flex w-full"
    style="height: 600px;"
  >
    <google-map
      ref="mapRef"
      class="flex w-full"
      :center="center"
      :class="{
        'filter grayscale': loadingData
      }"
    >
      <slot />
    </google-map>
  </div>
</template>

<script lang="ts">
import {defineComponent, defineExpose} from 'vue';
import GoogleMap from "@/core/components/google-map/GoogleMap.vue";
import {mapState} from "pinia";
import {useSystemStore} from "@/stores/system.store";

export default defineComponent({
    name: "DisruptionLogisticsMap",
    components: {GoogleMap},
    props: {
        loadingData: {
            type: Boolean,
            default: false
        }
    },
    setup() {
        defineExpose(['fitMapToBounds']);
    },
    computed: {
        center() {
            if (this.entityAddress && this.entityAddress.latitude && this.entityAddress.longitude) {
                return {
                    lat: this.entityAddress.latitude,
                    lng: this.entityAddress.longitude
                };
            } else {
                return {lat: 48.856613, lng: 2.352222};
            }
        },
        ...mapState(useSystemStore, ['environment']),

        entityAddress() {
            return this.environment?.entity.address;
        }
    },
    methods: {
        fitMapToBounds(bounds: { lat: number; lng: number }[], padding: number | null = null) {
            const mapRef = this.$refs.mapRef as InstanceType<typeof GoogleMap>;

            mapRef.fitBounds(bounds, padding);
        }
    }
});
</script>
