<template>
  <div class="m-5 p-5 border-l-2 border-primary">
    <p>{{ question.title }}</p>
    <p class="text-sm mt-2">
      {{ question?.description ?? "" }}
    </p>
    <SurveyImage
      v-if="question?.image"
      :survey-id="surveyId"
      :question-id="question.id"
    />
    <SurveyBuiltAnswer
      :question="question"
      :survey-id="surveyId"
      class="my-6"
      :editable="false"
      :value="question?.user_answer?.value"
    />
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import SurveyBuiltAnswer from '@/survey/components/SurveyBuiltAnswer.vue';
import SurveyImage from '@/survey/components/SurveyImage.vue';

export default defineComponent({
    name: 'SurveyBuiltQuestion',
    components: { SurveyImage, SurveyBuiltAnswer },
    props: {
        surveyId: {
            type: Number,
            required: true
        },
        question: {
            type: Object,
            required: true
        }
    }
});
</script>
