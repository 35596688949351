<template>
  <div
    :class="sizeClass + ' mx-auto'"
    :style="{aspectRatio: ratio}"
  >
    <div
      v-if="loading"
      :class="sizeClass + ' h-full flex items-center justify-center'"
    >
      <base-spinner :size="spinnerSize" />
    </div>
    <div
      v-else
      class="grid justify-items-center"
    >
      <div
        v-if="imageUrl"
        class="relative"
      >
        <img
          :src="(imageUrl as string | undefined)"
          alt="ordonnance"
          :class="sizeClass + ' cursor-pointer object-contain'"
          @click="download()"
        >
        <div
          v-if="printButton"
          class="absolute bottom-8 right-8"
        >
          <base-button
            primary
            class="rounded-3xl"
            style="padding:10px !important"
            @click="download()"
          >
            <v-icon color="white">
              mdi-printer
            </v-icon>
          </base-button>
        </div>
      </div>
      <prescription
        v-else
        class="max-h-28"
      />
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "vue";
import {secureFileApi, storageService} from '@/container';
import BaseSpinner from '@/core/components/base/spinner/BaseSpinner.vue';
import Prescription from '@/core/components/icons/Prescription.vue';
import BaseButton from '@/core/components/base/BaseButton.vue';
import FileUrl from "@/core/interfaces/FileUrl";

export default defineComponent({
    name: 'SecureImage',
    components: {BaseSpinner, BaseButton, Prescription},
    props: {
        urls: {
            type: Array<FileUrl>,
            required: true
        },
        size: {
            type: Number,
            default: 84
        },
        printButton: {
            type: Boolean,
            default: false
        },
        ratio: {
            type: Number,
            default: null
        }
    },
    data() {
        return {
            imageUrl: null as string | ArrayBuffer | null,
            images: [] as (string | ArrayBuffer | null)[],
            pdfUrl: null as string | null,
            loading: true,
            showSlider: false
        };
    },
    computed: {
        /**
         * The tailwind size class.
         *
         * @returns {string}
         */
        sizeClass() {
            return `w-${this.size}`;
        },
        /**
         * The image spinner size depending the size of the image.
         *
         * @returns {string}
         */
        spinnerSize() {
            return this.size >= 84 ? 'medium' : 'medium-small';
        }
    },
    mounted() {
        if (this.urls.length) {
            const url = this.urls[0].thumbnail.large;
            this.pdfUrl = this.urls[0].pdf || null;
            this.getImage(url);

            if (this.urls.length > 1) {
                this.getImages(this.urls);
            }
        }
    },
    methods: {
        /**
         * Get image url.
         *
         * @param url
         */
        getImage(url: string) {
            secureFileApi().get(url)
                .then(response => {
                    const reader = new FileReader();

                    reader.readAsDataURL(response);

                    reader.onload = () => {
                        this.imageUrl = reader.result;
                    };
                })
                .finally(() => {
                    this.loading = false;
                });
        },
        /**
         * Get images.
         *
         * @param urls
         */
        getImages(urls: FileUrl[]) {
            urls.map(url => {
                secureFileApi()
                    .get(url.thumbnail.large)
                    .then(response => {
                        const reader = new FileReader();

                        reader.readAsDataURL(response);

                        reader.onload = () => {
                            this.images.push(reader.result);
                        };
                    });
            });
        },
        /**
         * Show pdf of image if there is one
         */
        download() {
            if (this.pdfUrl) {
                this.print(this.pdfUrl);
            } else {
                this.printImages();
            }
        },
        /**
         * Print a pdf.
         *
         * @param url
         */
        print(url: string) {
            window.ipcRenderer
                .invoke('print', {
                    url: url,
                    token: storageService().read('token')
                });
        },
        /**
         * Print images by putting them in html and then print it.
         */
        printImages() {
            const printWindow = window.open(
                '',
                'modal',
                'width=' + screen.availWidth + ',height=' + screen.availHeight
            );
            if (printWindow) {
                printWindow.document.write('<html lang="fr"><head><title>Visuel</title></head>');
                printWindow.document.write('<body>');

                if (this.images.length) {
                    this.images.map(image => {
                        printWindow.document.write('<img src="' + image + '" alt="Ordonnance"/>');
                    });
                } else {
                    printWindow.document.write('<img src="' + this.imageUrl + '" alt="Ordonnance"/>');
                }

                printWindow.document.write('</body></html>');
                printWindow.document.close();
                printWindow.print();
            }
        }
    }
});

</script>
