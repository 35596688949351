import {Credit} from "@/core/interfaces/Credit";
import {AxiosInstance, AxiosResponse} from "axios";
import {StripePrice} from "@/stripe/interfaces";

interface CreditResourceInterface {
    /** Get the SMS credit balance for the current organization. */
    read(): Promise<Credit>;
    /** Get the prices for the SMS credit packages. */
    prices(): Promise<StripePrice[]>;
}

/**
 * The credit resource.
 * @param {AxiosInstance} client - The injected client.
 */
export default function (client: AxiosInstance): CreditResourceInterface {
    return {
        /**
         * Get the SMS credit balance for the current organization.
         */
        async read(): Promise<Credit> {
            return await client.get("organization/credit")
                .then((response: AxiosResponse) => response.data.data);
        },
        /**
         * Get the prices for the SMS credit packages.
         */
        async prices(): Promise<StripePrice[]> {
            return await client.get("credit/prices")
                .then((response: AxiosResponse) => response.data.data.data);
        }
    };
};
